import { StringFormatted, Formatted } from '@/modules/trade/types';

export enum CashMarketFiltersTypes {
  Date = 'Date',
  Type = 'Type'
}

export enum CashMarketFiltersDateValues {
  Today = 'Today',
  Historical = 'Historical'
}

export interface ICashMarketFilters {
  [CashMarketFiltersTypes.Date]: {
    selected: CashMarketFiltersDateValues;
    available: Formatted<CashMarketFiltersDateValues>[];
  };
  [CashMarketFiltersTypes.Type]: {
    selected: string | null;
    available: StringFormatted[];
  };
}
