import { render, staticRenderFns } from "./ProductsToInvestHeader.vue?vue&type=template&id=e807dff6&scoped=true&"
import script from "./ProductsToInvestHeader.vue?vue&type=script&lang=ts&"
export * from "./ProductsToInvestHeader.vue?vue&type=script&lang=ts&"
import style0 from "./ProductsToInvestHeader.vue?vue&type=style&index=0&id=e807dff6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e807dff6",
  null
  
)

export default component.exports