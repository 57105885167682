import store from '@/store';
import { WarrenSession } from '@/modules/common/services/session';
import {
  Document,
  DocumentStatus
} from '@warrenbrasil/pension-types/dist/next';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import {
  DocumentAcceptRequest,
  DocumentRejectRequest,
  DocumentModalSteps,
  DocumentStoreState
} from '@/modules/pension/types';
import { DocumentAPI } from '@/modules/pension/services/document';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { Download } from '@/modules/pension/helpers/download';

type keys = 'fetchDocuments' | 'acceptDocument' | 'rejectDocument';

type PayloadErrors = {
  key: keys;
  errors: string[] | false;
};

type PayloadLoading = {
  key: keys;
  loading: boolean;
};

@Module({
  dynamic: true,
  namespaced: true,
  name: 'PensionDocumentModule',
  store
})
export class ModuleDefinition extends VuexModule {
  // Module state
  public data: DocumentStoreState = {
    documents: [],
    documentAccepted: null,
    documentRejected: null,
    documentSelected: null,
    acceptData: {
      terms: false,
      documentPDF: false,
      password: ''
    },
    rejectData: {
      reason: ''
    },
    modal: {
      open: false,
      step: DocumentModalSteps.DocumentList
    },
    loading: {
      fetchDocuments: false,
      acceptDocument: false,
      rejectDocument: false
    },
    errors: {
      acceptDocument: false,
      fetchDocuments: false,
      rejectDocument: false
    }
  };

  @Mutation
  public SET_ERRORS(payload: PayloadErrors) {
    this.data.errors[payload.key] = payload.errors;
  }

  @Mutation
  public SET_LOADING(payload: PayloadLoading) {
    this.data.loading[payload.key] = payload.loading;
  }

  @Mutation
  public SET_DOCUMENTS(documents: Document[]) {
    this.data.documents = [...documents];
  }

  public get shouldShowModalBackButton() {
    const { documents } = this.data;
    const { step } = this.data.modal;

    const isDetailsAndHasList =
      DocumentModalSteps.DocumentDetails === step && documents.length > 1;

    return (
      [DocumentModalSteps.MFA, DocumentModalSteps.Terms].includes(step) ||
      isDetailsAndHasList
    );
  }

  get modalStepsInfo() {
    const { step } = this.data.modal;

    return {
      current: step,
      is: {
        details: step === DocumentModalSteps.DocumentDetails,
        list: step === DocumentModalSteps.DocumentList,
        mfa: step === DocumentModalSteps.MFA,
        terms: step === DocumentModalSteps.Terms,
        success: step === DocumentModalSteps.Success,
        rejection: step === DocumentModalSteps.Rejection
      }
    };
  }

  @Action
  public clearErrors() {
    this.SET_ERRORS({ key: 'fetchDocuments', errors: false });
    this.SET_ERRORS({ key: 'acceptDocument', errors: false });
    this.SET_ERRORS({ key: 'rejectDocument', errors: false });
  }

  @Action
  public clearAcceptErrors() {
    this.SET_ERRORS({ key: 'acceptDocument', errors: false });
  }

  @Action
  public clearRejectErrors() {
    this.SET_ERRORS({ key: 'rejectDocument', errors: false });
  }

  @Action
  public clearAcceptData() {
    this.data.acceptData = {
      terms: false,
      documentPDF: false,
      password: ''
    };
  }

  @Action
  public clearRejectData() {
    this.data.rejectData = {
      reason: ''
    };
  }

  @Action
  public async selectDocument(document: Document) {
    this.data.documentSelected = { ...document };
    this.modalNavigateTo(DocumentModalSteps.DocumentDetails);
  }

  @Action
  public setAcceptedDocument(document: Document) {
    this.data.documentAccepted = { ...document };
  }

  @Action
  public setRejectedDocument(document: Document) {
    this.data.documentRejected = { ...document };
  }

  @Action
  public async fetchDocuments() {
    try {
      this.SET_ERRORS({ key: 'fetchDocuments', errors: false });
      this.SET_LOADING({ key: 'fetchDocuments', loading: true });

      const payload = {
        customerId: WarrenSession.get(StorageKeys.CustomerId),
        status: DocumentStatus.WaitingAccept
      };

      const api = new DocumentAPI();
      const documents = await api.listDocuments(payload);

      this.SET_DOCUMENTS(documents);
    } catch (e) {
      if (e instanceof Error) {
        this.SET_ERRORS({ key: 'fetchDocuments', errors: [e.message] });
      }
    } finally {
      this.SET_LOADING({ key: 'fetchDocuments', loading: false });
    }
  }

  @Action
  public async acceptDocument() {
    try {
      this.SET_ERRORS({ key: 'acceptDocument', errors: false });
      this.SET_LOADING({ key: 'acceptDocument', loading: true });

      const { documentSelected: document, acceptData } = this.data;

      if (!document || !document.id) {
        throw new Error('document_not_selected');
      }

      if (
        !acceptData.terms ||
        !acceptData.password ||
        !acceptData.documentPDF
      ) {
        throw new Error('document_not_accepted_by_user');
      }

      const payload: DocumentAcceptRequest = {
        customerId: document.customerId,
        documentId: document.id,
        password: acceptData.password,
        acceptedTerms: acceptData.terms,
        acceptedDocumentPDF: acceptData.documentPDF
      };

      const api = new DocumentAPI();
      await api.acceptDocument(payload);

      this.setAcceptedDocument(document);
      this.clearAcceptData();
      this.fetchDocuments();
      this.modalNavigateTo(DocumentModalSteps.Success);
    } catch (error) {
      let message = '';
      const apiError = error as { errors: Error[] };

      if (error instanceof Error) {
        message = error.message;
      }

      if (apiError.errors && Array.isArray(apiError.errors)) {
        message = apiError.errors[0].message;
      }

      this.SET_ERRORS({ key: 'acceptDocument', errors: [message] });
    } finally {
      this.SET_LOADING({ key: 'acceptDocument', loading: false });
    }
  }

  @Action
  public async rejectDocument() {
    try {
      this.SET_ERRORS({ key: 'rejectDocument', errors: false });
      this.SET_LOADING({ key: 'rejectDocument', loading: true });

      const { documentSelected: document } = this.data;

      if (!document || !document.id) {
        throw new Error('document_not_selected');
      }

      const payload: DocumentRejectRequest = {
        customerId: document.customerId,
        documentId: document.id,
        reason: 'Cliente decidiu não seguir com o contrato.'
      };

      const api = new DocumentAPI();
      await api.rejectDocument(payload);

      this.setRejectedDocument(document);
      this.clearRejectData();
      this.fetchDocuments();
      this.modalNavigateTo(DocumentModalSteps.Rejection);
    } catch (error) {
      let message = '';
      const apiError = error as { errors: Error[] };

      if (error instanceof Error) {
        message = error.message;
      }

      if (apiError.errors && Array.isArray(apiError.errors)) {
        message = apiError.errors[0].message;
      }

      this.SET_ERRORS({ key: 'rejectDocument', errors: [message] });
    } finally {
      this.SET_LOADING({ key: 'rejectDocument', loading: false });
    }
  }

  @Action
  public async openDocumentPdf() {
    const document = this.data.documentSelected;

    if (!document || !document.base64) {
      return;
    }

    const { base64, id, type } = document;
    const fileName = `DOC_${type.toUpperCase()}_${id}`;

    Download.base64PDF(base64, fileName);
  }

  @Action
  public modalNavigateBack() {
    const { documents } = this.data;
    const currentStep = this.data.modal.step;
    let newStep = currentStep;

    switch (currentStep) {
      case DocumentModalSteps.DocumentDetails:
        if (documents.length > 1) {
          newStep = DocumentModalSteps.DocumentList;
        }
        break;

      case DocumentModalSteps.MFA:
      case DocumentModalSteps.Terms:
        newStep = DocumentModalSteps.DocumentDetails;
        break;
    }

    this.modalNavigateTo(newStep);
  }

  @Action
  public modalNavigateTo(step: DocumentModalSteps) {
    if (
      this.data.modal.step === DocumentModalSteps.MFA &&
      step !== DocumentModalSteps.MFA
    ) {
      this.data.acceptData.password = '';
    }

    this.data.modal.step = step;
  }

  @Action
  public modalOpen() {
    this.data.modal.open = true;

    const { documents } = this.data;

    if (documents.length === 1) {
      this.data.documentSelected = documents[0];
      this.modalNavigateTo(DocumentModalSteps.DocumentDetails);
    } else {
      this.data.documentSelected = null;
      this.modalNavigateTo(DocumentModalSteps.DocumentList);
    }
  }

  @Action
  public modalClose() {
    this.data.modal.open = false;
    this.data.documentSelected = null;
    this.data.documentAccepted = null;
    this.clearErrors();
  }
}
