
import { Component, Vue, Emit } from 'vue-property-decorator';
import { Carousel, Slide } from 'vue-carousel';
import { SkeletonLoader } from '@warrenbrasil/nebraska-web';
import CardNews from '../CardNews/CardNews.vue';
import { NewsAPI, IFeedItem } from '@/modules/home/services/news';

const newsApi = new NewsAPI();

@Component({
  components: {
    Carousel,
    Slide,
    CardNews,
    SkeletonLoader
  }
})
export default class OverviewInbox extends Vue {
  public showSuggestionModal = false;

  private newsFeed = [] as IFeedItem[];
  private isLoading = false;

  async created() {
    await this.loadData();
  }

  public async loadData() {
    try {
      this.isLoading = true;
      const response = await newsApi.getHomeNews();
      this.newsFeed = response.feed || [];
    } finally {
      this.isLoading = false;
    }
  }

  @Emit('viewNews')
  public viewNewsFeed(news: IFeedItem): IFeedItem {
    return news;
  }

  @Emit('removeNews')
  public removeNewsFeed(indexFeed: number, feed: IFeedItem) {
    const animationDuration = 500;

    setTimeout(() => {
      this.newsFeed = this.newsFeed.filter((_, index) => index !== indexFeed);
    }, animationDuration);

    return feed;
  }

  private get showNewsCard() {
    return this.newsFeed.length > 0;
  }

  private get hasEmptyMessage() {
    return this.$slots['empty-message'];
  }
}
