
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Modal,
  HeadingDisplay,
  ButtonContainer,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';
import { Portal } from '@/modules/common/components/Portal';

import { useDashStore } from '@/modules/common/store/DashModule';

@Component({
  components: { Modal, HeadingDisplay, ButtonContainer, ButtonPrimary, Portal }
})
export default class TutorialModal extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly isOpen!: boolean;

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly subtitle!: string;

  @Prop({ type: String, required: true })
  readonly buttonLabel!: string;

  @Prop({ type: Object, required: true })
  readonly src!: { dark: string; light: string };

  readonly dashStore = useDashStore();

  get imageSrc() {
    return this.dashStore.isDarkMode ? this.src.dark : this.src.light;
  }
}
