
import { Component, Vue } from 'vue-property-decorator';

import { ContentDisplayLoader } from '@/modules/trade/components/ContentDisplayLoader';
import { SkeletonLoader } from '@warrenbrasil/nebraska-web';

@Component({
  components: { ContentDisplayLoader, SkeletonLoader }
})
export default class TaxCalculatorLoader extends Vue {}
