
import { Component, Vue } from 'vue-property-decorator';
import { CoverSheet } from './components/CoverSheet';
import { UIModule } from '@/modules/common/store/UIModule';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { WarrenSession } from '@/modules/common/services/session';
import { StorageKeys } from '@/modules/common/services/local-storage';

@Component({
  components: { CoverSheet }
})
export default class WelcomeCurtain extends Vue {
  private hasShown = false;

  created() {
    this.hasShown = WarrenSession.get(StorageKeys.SessionWelcome);
    UIModule.setIsOpenWelcomeCurtain(!this.hasShown);
  }

  private get showWelcomeSession() {
    return UIModule.getIsOpenWelcomeCurtain;
  }

  private get welcomeMessage() {
    const name = CustomerModule.getCustomer?.name;
    return `Olá, ${name}`;
  }

  private handleOnHideWelcomeMessage() {
    WarrenSession.set(StorageKeys.SessionWelcome, 'true', true);
    UIModule.setIsOpenWelcomeCurtain(false);
  }
}
