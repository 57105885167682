
import { InputText } from '@warrenbrasil/nebraska-web';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { phoneMask } from '@/modules/common/helpers/masks';

@Component({ components: { InputText } })
export default class PhoneInput extends Vue {
  @Prop({ default: '', type: String }) private value!: string;
  @Prop({ default: '', type: String }) private label!: string;

  private inputValue = '';
  private mask = '(##) #####-####';

  private handleInput() {
    this.$emit('masked-input', this.inputValue);
    this.$emit('input', this.inputValue.replace(/\D/g, ''));
  }

  created() {
    this.inputValue = phoneMask(this.value);
  }
}
