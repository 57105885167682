
import { Component, Vue, Prop } from 'vue-property-decorator';

import { SkeletonLoader } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    SkeletonLoader
  }
})
export default class ContentDisplayLoader extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly hasButton!: boolean;
}
