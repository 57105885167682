import { datadogLogs } from '@datadog/browser-logs';
import { isRequestError } from '@warrenbrasil/web-http';

import { isBFFError, isStringRecord } from '@/modules/common/helpers/bff';

export const logError = (error: unknown, name: string) => {
  if (isRequestError(error)) {
    datadogLogs.logger.error(error.type, {
      name,
      error: { stack: error.stack }
    });
  } else if (isBFFError(error)) {
    datadogLogs.logger.error('bff-error', { name });
  } else if (isStringRecord(error)) {
    const message =
      typeof error?.message === 'string' ? error.message : 'unknown-error';
    datadogLogs.logger.error(message, {
      name,
      error: { stack: error.stack }
    });
  } else {
    datadogLogs.logger.error('unknown-error', {
      name
    });
  }
};
