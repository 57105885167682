
import { Component, Vue } from 'vue-property-decorator';

import { ConclusionStep, ButtonPrimary } from '@warrenbrasil/nebraska-web';
import { goToNextRoute } from '@/modules/common/store/RegulatoryUpdateRoutesOrchestrator';
import { trackContinue, trackView } from './track-events';

@Component({
  components: { ButtonPrimary, ConclusionStep }
})
export default class RegulatoryUpdateSuccess extends Vue {
  public goToNextRoute() {
    trackContinue();
    goToNextRoute();
  }

  mounted() {
    trackView();
  }
}
