export interface Formatted<T, G = string> {
  value: T;
  formatted: G;
}

export type NumberFormatted = Formatted<number>;

export type StringFormatted = Formatted<string>;

export interface DateFormatted {
  short: string;
  long: string;
}

export type ChipFormatted = Formatted<ChipType>;

export interface FilterData<T = string> {
  selected: T;
  available: Formatted<T>[];
}

export interface InstrumentImage {
  small: string;
  medium: string;
  large: string;
}

export interface InstrumentTypeFormatted {
  value: InstrumentType;
  formatted: string;
}

export enum InstrumentType {
  Stock = 'Stock',
  FII = 'FII',
  ETF = 'ETF',
  BDR = 'BDR',
  Crypto = 'Crypto',
  BrazilianAssets = 'brazilianAssets',
  GlobalAssets = 'globalAssets',
  RealStateAssets = 'realStateAssets',
  All = 'All'
}

export interface PaginationData {
  page: number;
  itemsPerPage: number;
  totalPages: number;
  hasMore: boolean;
}

export interface BFFErrorResponse {
  error: {
    code: number;
    message: {
      value: string;
      formatted: string;
    };
  };
}

export enum ChipType {
  positive = 'positive',
  negative = 'negative',
  alert = 'alert',
  information = 'information'
}

export enum ETradeNegotiationStatus {
  ActivateMfa = 'ACTIVATE_MFA',
  UpdateToRegulation = 'UPDATE_TO_REGULATION',
  MarketClosed = 'MARKET_CLOSED',
  NoneTradeAccount = 'NONE_TRADE_ACCOUNT',
  TradeUnavailable = 'TRADE_UNAVAILABLE',
  None = 'NONE'
}

export enum ETradeNegotiationButtonState {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Action = 'ACTION'
}

export enum ETradeMarketStatus {
  MarketClosedDate = 'MARKET_CLOSED_DATE',
  MarketClosedHour = 'MARKET_CLOSED_HOUR',
  MarketClosedSpHoliday = 'MARKET_CLOSED_SP_HOLIDAY',
  MarketUnavailable = 'MARKET_UNAVAILABLE',
  MarketAvailable = 'MARKET_AVAILABLE'
}

export interface TradeMarketAvailable {
  isMarketAvailable?: boolean | null;
  marketStatus?: TradeMarketStatus | null;
  description?: string | null;
  innerDescription?: string | null;
  negotiationAvailable?: TradeNegotiationAvailable | null;
}

export interface TradeNegotiationAvailable {
  buy?: TradeNegotiationAvailableDetail | null;
  sell?: TradeNegotiationAvailableDetail | null;
}

export interface TradeMarketStatus {
  value?: ETradeMarketStatus | null;
  formatted?: string | null;
}

export interface TradeNegotiationAvailableDetail {
  state?: ETradeNegotiationButtonState | null;
  action?: ETradeNegotiationStatus | null;
  button?: string | null;
  message?: string | null;
}

export interface TradeAccountProjectedBalancesOperations {
  description: string;
  operationDate: DateFormatted;
  value: NumberFormatted;
  composition: string;
}

export interface TradeAccountProjectedBalances {
  value: NumberFormatted;
  date: StringFormatted;
  label: string;
  operations?: Array<TradeAccountProjectedBalancesOperations | null> | null;
}
