
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Heading, Row } from '@warrenbrasil/nebraska-web';
import { BulletAsset } from '@/modules/common/components/BulletAsset';

@Component({
  components: {
    BulletAsset,
    Heading,
    Row
  }
})
export default class HeadingProductListItem extends Vue {
  @Prop({ type: String, required: true })
  private name!: string;

  @Prop({ type: String, required: true })
  private percentage!: string;

  @Prop({ type: String, required: true })
  private color!: string;
}
