
import { Component, Prop, Provide } from 'vue-property-decorator';

import WalletProvider from './WalletProvider.vue';
import { IWalletEditProvider } from './types/IWalletEditProvider';
import {
  IDataConfirmEdit,
  ISimulationResponse,
  IEditPortfolioResponse
} from '@/modules/wallet-builder/src/services/types/models/IBffInvestments';
import ProviderError from './ProviderError.vue';

@Component({ components: { ProviderError } })
export default class WalletEditProvider
  extends WalletProvider
  implements IWalletEditProvider
{
  @Prop({ type: String })
  public portfolioId!: string;

  public simulationData: ISimulationResponse = {};

  @Provide('resetWallet')
  public async resetWallet() {
    if (!this.allocations) return;

    try {
      await this.bff.resetPortfolio(this.portfolioId);
    } catch {
      this.setError('', true, this.resetWallet);
      return false;
    } finally {
      this.getLastState(this.portfolioId);
    }
  }

  public removeProductFromWalletSimulator() {}

  @Provide('isPartner')
  public isPartner = false;

  @Provide('confirmEditPortfolio')
  public confirmEditPortfolio(allocation: IDataConfirmEdit) {
    return this.bff.editWallet(
      allocation.portfolioId
    ) as Promise<IEditPortfolioResponse>;
  }

  async created() {
    await this.resetWallet();
    this.isPartner = !!this.$route.query.isPartner;
  }
}
