
import { Component, Vue, Emit } from 'vue-property-decorator';
import { HelperView } from '@warrenbrasil/nebraska-web';

@Component({
  components: { HelperView }
})
export default class StatementError extends Vue {
  @Emit('reload')
  reload() {}

  @Emit('load-view')
  loadView() {}

  mounted() {
    this.loadView();
  }
}
