
import { Component, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { NavBar, RoundedIcon } from '@warrenbrasil/nebraska-web';

@Component({
  name: 'InvestedPortfoliosNavbar',
  components: { NavBar, RoundedIcon }
})
export default class InvestedPortfoliosNavbar extends Vue {
  private get NebraskaColors() {
    return NebraskaColors;
  }

  private emitCloseEvent() {
    this.$emit('on-close');
  }
}
