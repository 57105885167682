
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { BaseText, BaseIcon } from '@warrenbrasil/nebraska-web';
import { dateViewer } from '@/modules/common/helpers/date';

@Component({
  name: 'ChartFooter',
  components: { BaseText, BaseIcon }
})
export default class ChartFooter extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: String, required: true })
  readonly startDate!: string;

  @Prop({ type: String, required: true })
  readonly endDate!: string;

  @Prop({ type: String, required: false })
  readonly footerText?: string;

  @Prop({ type: Boolean, default: false })
  readonly showFooterTextAsButton!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isProcessing!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: String, default: 'DD/MMM' })
  readonly dateFormat!: string;

  get formattedStartDate() {
    return dateViewer(this.startDate, this.dateFormat);
  }

  get formattedEndDate() {
    return dateViewer(this.endDate, this.dateFormat);
  }

  get computedClassName() {
    return this.isProcessing ? 'chart-footer__button__spin' : '';
  }

  get isFooterButtonDisabled() {
    return this.isProcessing || this.disabled;
  }

  handleFooterButtonClick() {
    if (this.isFooterButtonDisabled) return;
    this.emitFooterButtonClick();
  }

  @Emit('footer-button-click')
  emitFooterButtonClick() {}
}
