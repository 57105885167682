
import { Component, Vue } from 'vue-property-decorator';

import { InvestmentTransactionNavbar } from '@/modules/transaction/views/InvestmentTransaction/components/InvestmentTransactionNavbar';
import {
  RoundedIcon,
  Heading,
  ActionableHelperView,
  SpinnerLoader
} from '@warrenbrasil/nebraska-web';
import { CardsTypeInvestment } from './components/CardsTypeInvestment';
import { ECameFromPath } from '@/modules/transaction/views/InvestmentTransaction/types';
import { namespace } from 'vuex-class';
import {
  userCanPerform,
  Operation,
  isImpersonate
} from '@/modules/common/services/partner';
import {
  trackBackEvent,
  trackInvestmentByPortfolio,
  trackInvestmentByProduct,
  trackLoadEditedPortfolioInvestment
} from './track-events';
import { IGetTransactionOptions } from '@/modules/wealth/services/withdraw';

const InvestmentsModule = namespace('InvestmentsModule');
const CustomerModule = namespace('CustomerModule');

enum InvestmentType {
  InvestmentByPortfolio = 'INVESTMENT_BY_PORTFOLIO',
  InvestmentByProduct = 'INVESTMENT_BY_PRODUCT'
}

@Component({
  name: 'InvestmentTypeSelection',
  components: {
    InvestmentTransactionNavbar,
    RoundedIcon,
    Heading,
    CardsTypeInvestment,
    ActionableHelperView,
    SpinnerLoader
  }
})
export default class InvestmentTypeSelection extends Vue {
  @InvestmentsModule.Getter('transactionType')
  private transactionType!: IGetTransactionOptions;

  @InvestmentsModule.Getter('errorTypeTransactions')
  public readonly errorTypeTransactions!: boolean;

  @InvestmentsModule.Action('getPortfolioInfos')
  private getPorfolioInfosAction!: Function;

  @CustomerModule.Getter('getCustomerId')
  private customerId!: string;

  private portfolioId = '';
  public isLoading = false;

  async beforeMount() {
    this.isLoading = true;
    this.portfolioId = this.$route.params.id;
    trackLoadEditedPortfolioInvestment();
    if (!this.canDepositByProduct) {
      this.redirectToNewTransaction();
    }
    await this.getTransactionType();
    this.isLoading = false;
  }

  public async getTransactionType() {
    await this.getPorfolioInfos();
    this.validateOneInvestmentOption();
  }

  private async getPorfolioInfos() {
    if (this.$route.query.isWalletAction) {
      await this.getPorfolioInfosAction(this.portfolioId, this.customerId);
    }
  }

  private validateOneInvestmentOption() {
    if (this.investmentTypeData?.length === 1) {
      this.redirectButton(this.investmentTypeData[0].id, false);
    }
  }

  get investmentTypeData() {
    return this.transactionType ? this.transactionType.investment : [];
  }

  get canDepositByProduct() {
    if (!isImpersonate()) {
      return true;
    }
    return userCanPerform(Operation.DepositByProduct);
  }

  BackEvent() {
    if (this.$route.query.isWalletAction) {
      this.$router.push('/v3/wealth');
    } else {
      this.$router.back();
    }

    trackBackEvent();
  }

  redirectButton(typeInvestmentsId: string, isTraking = true) {
    if (typeInvestmentsId === InvestmentType.InvestmentByPortfolio) {
      if (isTraking) {
        trackInvestmentByPortfolio();
      }
      this.redirectToNewTransaction();
    } else if (typeInvestmentsId === InvestmentType.InvestmentByProduct) {
      if (isTraking) {
        trackInvestmentByProduct();
      }
      this.redirectToNewTransactionProduct();
    }
  }

  getIcon(typeInvestmentsId: string) {
    if (typeInvestmentsId === InvestmentType.InvestmentByPortfolio) {
      return 'ic_wallet';
    } else if (typeInvestmentsId === InvestmentType.InvestmentByProduct) {
      return 'ic_products';
    }
  }

  redirectToNewTransaction() {
    this.$router.push({
      name: 'investment-transaction',
      params: { portfolio: this.portfolioId },
      query: { from: ECameFromPath.WealthPortfolio }
    });
  }

  redirectToNewTransactionProduct() {
    this.$router.push({
      name: 'wealthProductTransaction',
      params: { id: this.portfolioId }
    });
  }
}
