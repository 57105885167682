
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  Modal,
  InputText,
  ButtonContainer,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Modal,
    InputText,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary
  }
})
export default class RenamePortfolioModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: String, required: true })
  readonly portfolioName!: string;

  @Emit('close')
  emitClose() {}

  @Emit('change-name')
  emitChangeName() {
    return this.portfolioRenamed;
  }

  portfolioRenamed = '';

  mounted() {
    this.portfolioRenamed = this.portfolioName;
  }
}
