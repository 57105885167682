import { GtmTracker } from '@/modules/common/services/analytics';

export const trackEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'regulatory_update_announcement',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackView() {
  trackEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackStart() {
  trackEvent({
    event_type: 'click',
    event_name: 'start'
  });
}
