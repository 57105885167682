
import { Component, Vue } from 'vue-property-decorator';
import { Notification } from '@/modules/common/components/Notification';
import { logout } from '@/modules/common/services/http/account';
import { WarrenSession } from '@/modules/common/services/session';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { isImpersonate } from '@/modules/common/services/partner';
import { LoaderIndicator } from '@warrenbrasil/nebraska-web';

type PreserveTokens = StorageKeys[] | string[];

@Component({
  components: {
    LoaderIndicator,
    Notification
  }
})
export default class SignOutView extends Vue {
  private preserveTokens: PreserveTokens = [];
  private redirectToPath = '';
  private toView = '';
  private close = false;

  private created() {
    this.close = Boolean(this.$route.query.close) || false;
    this.toView = (this.$route.query.toView as string) || '';
    this.redirectToPath = (this.$route.query.redirectToPath as string) || '';
    this.setPreserveTokens();
    this.doLogout();
  }

  private setPreserveTokens() {
    const preserveTokens = this.$route.query.preserveTokens;

    if (Array.isArray(preserveTokens)) {
      this.preserveTokens = preserveTokens as PreserveTokens;
    } else if (
      typeof preserveTokens === 'string' &&
      preserveTokens.length > 0
    ) {
      this.preserveTokens = preserveTokens.split(',');
    }

    if (!preserveTokens) {
      this.preserveTokens = [];
    }
  }

  private async doLogout() {
    try {
      await logout();
    } catch (e) {
      this.$notify({
        group: 'wrn-signout',
        type: NotificationTypes.error,
        text: e.message
      });
    } finally {
      WarrenSession.clean(this.preserveTokens);
      window.zE('messenger', 'logoutUser');
      this.next();
    }
  }

  private next() {
    if (this.close) {
      window.close();
    } else if (this.redirectToPath) {
      window.location.assign(this.redirectToPath);
    } else if (this.toView) {
      this.$router.push({ name: this.toView });
    } else {
      this.$router.push({ name: 'signin' });
    }
  }

  get isImpersonate() {
    return isImpersonate();
  }
}
