
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { Row, BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { TradeAccountProjectedBalances } from '@/modules/trade/types';

@Component({
  components: {
    BackButton,
    Row,
    BaseText
  }
})
export default class CompositionSummary extends Vue {
  @Prop({ type: Object, required: true })
  private liquidateData!: TradeAccountProjectedBalances;

  readonly NebraskaColors = NebraskaColors;

  private get liquidateDate() {
    return this.liquidateData.date?.formatted;
  }

  private get liquidateTotal() {
    return this.liquidateData.value?.formatted;
  }

  @Emit('set-liquidate')
  public emitSetLiquidate() {}
}
