import { httpClient } from '@/modules/common/services/http/http-client';

import {
  IAvailableBrokerageNotesListResponse,
  IDailyBrokerageNoteBody,
  IYearlyBrokerageNoteBody
} from './types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/trade`
});

export function getAvailableBrokerageNoteList(): Promise<IAvailableBrokerageNotesListResponse> {
  return http.get('/v2/getAvailableBrokerageNotesDownloadList');
}

export function getDailyBrokerageNote(
  path: string
): Promise<IDailyBrokerageNoteBody> {
  return http.get('/v1/downloadBrokerageNote', {
    params: { path }
  });
}

export function getYearlyBrokerageNote(
  year: string
): Promise<IYearlyBrokerageNoteBody> {
  return http.get('/v1/brokerageNotesZip', {
    params: { year }
  });
}
