
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { BaseIcon, NavBar, BaseModal } from '@warrenbrasil/nebraska-web';
import { ModalFooter } from '@/modules/common/components/__deprecated__/Modal';
import { ITutorialStep } from './types';

@Component({
  components: {
    BaseModal,
    NavBar,
    ModalFooter,
    BaseIcon
  }
})
export default class TutorialModal extends Vue {
  @Prop({ type: Array, required: true })
  private steps!: ITutorialStep[];

  @Prop({ type: Boolean, required: true })
  private isOpen!: boolean;

  @Prop({ type: String, default: 'Tutorial' })
  private title!: string;

  @Prop({ type: String, default: '300px' })
  private height!: string;

  @Prop({ type: Boolean, default: false })
  public hasDivider!: boolean;

  @Prop({ type: Boolean, default: false })
  public oneStep!: boolean;

  private step = 0;

  private NebraskaColors = NebraskaColors;

  private get hasActionButtonListener() {
    return !!this.$listeners['action-button'];
  }

  @Emit('close')
  private closeModal() {
    return this;
  }

  private nextStep() {
    if (this.step < this.steps.length - 1) {
      this.step = this.step + 1;
      this.$emit('updateTutorialStep', this.step);
    } else {
      return this.closeModal();
    }
  }

  private updateStep(toStep: number) {
    let stepUpdate;
    if (toStep > this.steps.length - 1) {
      stepUpdate = this.steps.length - 1;
    } else if (toStep < 0) {
      stepUpdate = 0;
    } else {
      stepUpdate = toStep;
    }
    this.step = stepUpdate;
    this.$emit('updateTutorialStep', stepUpdate);
  }

  private get customBtnLabel(): string | undefined {
    return this.steps[this.step] && this.steps[this.step].label;
  }

  private get defaultBtnLabel(): string {
    return this.step < this.steps.length - 1 ? 'Próximo' : 'Concluir';
  }

  private get imageHeight() {
    return {
      height: this.height
    };
  }

  public handleOnClick() {
    this.hasActionButtonListener
      ? this.$emit('action-button')
      : this.nextStep();
  }
}
