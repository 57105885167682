
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Row, InitialAvatarIcon } from '@warrenbrasil/nebraska-web';

import {
  AccountStatus,
  HomeBrokerStatus,
  TradeHomeBrokerStatus
} from '@/modules/trade/services/account';
import { RequestStatus } from '@/modules/trade/store/trade';
import { MFAModules } from '@/modules/authentication/views/SecurityView/components/MultiFactorAuthentication';
import { isImpersonate } from '@/modules/common/services/partner';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { trackHomeBrokerClick } from '@/modules/trade/views/TradeView/trackers';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    Row,
    InitialAvatarIcon
  }
})
export default class HomeBrokerButton extends Vue {
  @Prop({ type: Object, required: false })
  readonly homeBrokerStatus?: HomeBrokerStatus;

  @Prop({ type: Object, required: false })
  readonly tradeStatus?: AccountStatus;

  @Emit('open-request-home-broker-modal')
  emitOpenRequestHomeBrokerModal() {}

  @Emit('open-home-broker')
  emitOpenHomeBroker() {}

  @tradePositionsModule.State('accountStatusRequestStatus')
  readonly accountStatusRequestStatus!: RequestStatus;

  private isImpersonate = isImpersonate();

  get computedIconClass() {
    return {
      home_broker_button__icon: true,
      'home_broker_button__icon--disabled': this.isButtonDisabled
    };
  }

  get isMFAEnabled() {
    return this.tradeStatus?.enabledMFA;
  }

  get hasAccountStatusRequestFailed() {
    return this.accountStatusRequestStatus === RequestStatus.Error;
  }

  get buttonTitle() {
    return this.homeBrokerStatus?.buttonText || 'Home Broker';
  }

  get buttonDescription() {
    if (this.updatedAccountRegulation) {
      return 'Atualize seu cadastro para acessar o Home Broker';
    } else if (this.hasAccountStatusRequestFailed) {
      return 'Serviço indisponível no momento';
    } else if (!this.isMFAEnabled) {
      return 'Cadastre a verificação em duas etapas';
    }
    return '';
  }

  get updatedAccountRegulation() {
    return this.tradeStatus && !this.tradeStatus.enabledBuy;
  }

  get isButtonDisabled() {
    if (
      this.hasAccountStatusRequestFailed ||
      this.isImpersonate ||
      this.updatedAccountRegulation
    ) {
      return true;
    }
    return false;
  }

  handleButtonClick() {
    if (this.isButtonDisabled) return;
    else if (!this.isMFAEnabled) {
      trackHomeBrokerClick(
        'Ativar Home Broker - Cadastre a verificação em duas etapas'
      );
      return this.$router.push({
        name: 'security',
        query: { module: MFAModules.TRADE }
      });
    }

    switch (this.homeBrokerStatus?.status) {
      case TradeHomeBrokerStatus.Requested:
      case TradeHomeBrokerStatus.Disabled:
        return this.emitOpenRequestHomeBrokerModal();
      case TradeHomeBrokerStatus.Enabled:
        return this.emitOpenHomeBroker();
      default:
        this.$notify({
          group: 'wrn-trade',
          text: 'Algo deu errado, tente novamente mais tarde, se persistir entre em contato com o suporte.',
          type: NotificationTypes.error
        });
    }
  }
}
