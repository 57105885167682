
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as d3 from 'd3';
import { percentageChartTruncate } from '@/modules/common/helpers/percentage';
import { Heading, BaseText } from '@warrenbrasil/nebraska-web';
import { AllocationTypeColor, getColorByType } from './color-by-type';
import { AllocationType } from '@/types/models/Portfolio';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ChartData } from '@/types/models/DonutChart/ChartData';
import { Money } from '@/modules/common/components/Money';

interface DisplayValueFormat {
  value?: string;
  divider?: string;
  decimal?: string;
  text?: string;
}

@Component({
  components: { Heading, BaseText, Money },
  filters: { percentageChartTruncate }
})
export default class DonutChart extends Vue {
  /** List of data with label and value */
  @Prop({ type: Array, default: () => [] })
  private data!: ChartData[];

  /** Radius */
  @Prop({ type: Number, default: 150 })
  private radius!: number;

  /** Thickness */
  @Prop({ type: Number, default: 12 })
  private thickness!: number;

  /** If is reloading chart */
  @Prop({ type: Boolean, default: false })
  private isLoading!: boolean;

  /* Footer Disclaimer Text */
  @Prop({
    type: String,
    default:
      'Os produtos podem mudar com o tempo para melhorar o seu rendimento.'
  })
  private footerDisclaimerText!: string;

  /** Flag to change UI to disable mode  */
  @Prop({ type: Boolean, default: false })
  private isDisabled!: boolean;

  /** Header level used in the component */
  @Prop({ type: Number, default: 1 })
  private headingLevel!: number;

  @Prop({ type: Boolean, default: false })
  private showZeroValues!: boolean;

  @Prop({ type: Boolean, default: true })
  private hasLabel!: boolean;

  @Prop({ type: String, default: 'Sem produto' })
  private label!: string;

  @Prop({ type: Boolean, default: true })
  private formatDecimal!: boolean;

  @Prop({ type: String, default: '' })
  private ariaLabel!: string;

  @Prop({ type: Boolean, default: false })
  private showTotalAllocation!: boolean;

  @Prop({ type: String, default: '' })
  private totalAllocation!: string;

  private SVG?: d3.Selection<SVGGElement, {}, null, undefined>;

  private mounted() {
    // @ts-ignore
    this.SVG = d3
      .select(this.$el.firstElementChild)
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .append('g')
      .attr('transform', `translate(${this.radius}, ${this.radius})`);

    this.draw();
  }

  private updated() {
    if (this.SVG) {
      this.SVG.selectAll('.arc').remove();
      this.SVG.selectAll('text').remove();
    }

    this.draw();
  }

  get NebraskaColors() {
    return NebraskaColors;
  }

  private get width() {
    return this.radius * 2;
  }

  private get height() {
    return this.radius * 2;
  }

  private get colors() {
    const colors = Object.values(AllocationTypeColor);
    const types = Object.values(AllocationType).map(type => type.toLowerCase());

    return d3.scaleOrdinal().domain(types).range(colors);
  }

  private get computedData() {
    let filteredData = this.data;

    if (!this.showZeroValues) {
      filteredData = filteredData.filter(item => item.value > 0);
    }

    if (!filteredData.length) {
      return this.defaultData.map(item => {
        return {
          ...item,
          displayData: this.getDisplayItemValue(item)
        };
      });
    }

    return filteredData.map(item => {
      return {
        ...item,
        displayData: this.getDisplayItemValue(item)
      };
    });
  }

  private get drawArc() {
    return d3
      .arc<d3.PieArcDatum<ChartData>>()
      .startAngle((arc: d3.PieArcDatum<ChartData>) => {
        return arc.data.customStartAngle
          ? arc.data.customStartAngle
          : arc.startAngle;
      })
      .endAngle((arc: d3.PieArcDatum<ChartData>) => {
        return arc.data.customEndAngle ? arc.data.customEndAngle : arc.endAngle;
      })
      .innerRadius(this.radius - this.thickness - 1)
      .outerRadius(this.radius - 1);
  }

  private fillColor({ data }: d3.PieArcDatum<ChartData>) {
    if (this.isDisabled) {
      return NebraskaColors.textDisabled;
    } else if (data.color) {
      return data.color;
    }
    const label = String(data.label).toLowerCase();

    return this.colors(label) as boolean;
  }

  private draw() {
    if (this.SVG) {
      const data = this.pie(this.computedData);
      this.SVG.selectAll('.arc')
        .data(data)
        .enter()
        .append('path')
        .attr('class', (name, i) => `arc arc-${i}`)
        .attr('d', this.drawArc)
        .attr('fill', this.fillColor);
    }
  }

  private pie(data: ChartData[]): Array<d3.PieArcDatum<ChartData>> {
    const numericValue = ({ value, label }: ChartData) => {
      return this.isDisabled && label === data[0].label ? 1 : value;
    };

    return d3.pie<ChartData>().value(numericValue).sort(null)(data);
  }

  private getTextColorStyle({ label, color }: ChartData) {
    return color
      ? {
          color: this.isDisabled ? NebraskaColors.textDisabled : color
        }
      : {
          color: this.isDisabled
            ? NebraskaColors.textDisabled
            : getColorByType(label as AllocationType).colorHex
        };
  }

  private get reloadingClass() {
    return {
      reloading: this.isLoading
    };
  }

  private get defaultData() {
    return [
      {
        label: this.label,
        value: 1,
        displayValue: '0%',
        color: NebraskaColors.textDisabled
      }
    ];
  }

  private getDisplayItemValue(item: ChartData) {
    if (item.displayValue) {
      return {
        value: item.displayValue.charAt(0),
        decimal: item.displayValue.charAt(1),
        text: item.displayValue,
        divider: ''
      } as DisplayValueFormat;
    }
    if (this.formatDecimal) {
      const formattedNumber: string[] = percentageChartTruncate(
        item.value
      ).split(',');

      return {
        value: formattedNumber[0] ? formattedNumber[0] : '',
        divider: ',',
        decimal: formattedNumber[1] ? formattedNumber[1].replace(/\s/g, '') : ''
      } as DisplayValueFormat;
    }
    return { text: percentageChartTruncate(item.value) } as DisplayValueFormat;
  }
}
