export enum ErrorMessages {
  invalidId = 'error_invalid_customer_id',
  invalidRequest = 'invalid_customer_for_request'
}

export enum BrokerType {
  generic = 'generic',
  instability = 'instability'
}

export enum BrokerButtonLabel {
  tryAgain = 'tentar novamente',
  returnToHome = 'voltar para a home'
}
