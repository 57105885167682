
import { Component, Vue, Prop } from 'vue-property-decorator';

import { SkeletonLoader } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    SkeletonLoader
  }
})
export default class HeadingDisplayLoader extends Vue {
  @Prop({ type: Boolean, default: true })
  readonly hasHead!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasTitle!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasSubTitle!: boolean;

  @Prop({ type: String, default: 'medium' })
  readonly size!: string;

  get computedClasses() {
    return {
      'heading-display-loader': true,
      'heading-display-loader--extra-small': this.size === 'extra-small',
      'heading-display-loader--small': this.size === 'small',
      'heading-display-loader--medium': this.size === 'medium',
      'heading-display-loader--large': this.size === 'large'
    };
  }
}
