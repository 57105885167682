export const data = {
  section: {
    title: 'Bolsa'
  },
  error: {
    title: 'Bolsa',
    subtitle: 'Não foi possível carregar o seu patrimônio neste momento',
    description: 'Aguarde alguns minutos e tente novamente.',
    buttonLabel: 'Tentar novamente'
  }
};
