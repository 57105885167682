
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Tag } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Tag
  }
})
export default class TransactionBadge extends Vue {
  @Prop({ type: String })
  private status!: string;

  private get tagProps() {
    const successStatus = ['accepted', 'approved', 'filled', 'executed'];
    const errorStatus = ['canceled', 'cancelled', 'rejected', 'expired'];
    const alertStatus = [
      'pending',
      'working',
      'processing',
      'partially_filled',
      'placing',
      'inactive'
    ];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const infoStatus = ['scheduled'];

    const lowerCaseStatus = this.status.toLowerCase();

    if (successStatus.includes(lowerCaseStatus)) {
      return { success: true };
    } else if (errorStatus.includes(lowerCaseStatus)) {
      return { error: true };
    } else if (alertStatus.includes(lowerCaseStatus)) {
      return { alert: true };
    } else {
      return { info: true };
    }
  }
}
