import { GtmTracker } from '@/modules/common/services/analytics';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'change_password',
  digital_experience: 'investor',
  experience_section: 'menu'
});

export function trackViewLoad() {
  trackEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackSaveClick() {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'confirm',
    event_target: 'Trocar senha'
  });
}

export function trackForgotPasswordClick() {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'forgot_password'
  });
}
