
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  IHelpCenterArticle,
  IHelpCenterSection
} from '@/modules/help-center/services/help-center';

import { Row, Heading } from '@warrenbrasil/nebraska-web';

@Component({
  name: 'HelpCenterContentSection',
  components: { Heading, Row }
})
export default class HelpCenterContentSection extends Vue {
  @Prop({ type: Object as PropType<IHelpCenterSection>, required: true })
  readonly section!: IHelpCenterSection;

  get hasMoreArticlesToShow() {
    const { articles, totalArticles } = this.section;
    return articles?.length! < totalArticles!;
  }

  openSection(section: IHelpCenterSection) {
    this.$router.push({
      name: 'help-center-section',
      params: { id: String(section.id) }
    });
  }

  openArticle(article: IHelpCenterArticle) {
    this.$router.push({
      name: 'help-center-article',
      params: { articleId: String(article.id) }
    });
  }
}
