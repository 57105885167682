import { MoneyCell } from '@/modules/common/components/MoneyCell';
import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';
import { OrderStatusCell } from '@/modules/trade/views/Orders/components/OrdersTable/components/OrderStatusCell';
import { PortfolioOrder } from '@/modules/wealth/services/portfolio';

export const columns = [
  {
    align: 'left',
    component: InstrumentNameCell,
    getter: (order: PortfolioOrder) => ({
      title: order.title,
      symbol: order.instrument.symbol,
      name: order.instrument.name,
      type: order.instrument.type?.value
    }),
    label: 'Descrição'
  },
  {
    align: 'right',
    component: MoneyCell,
    getter: (order: PortfolioOrder) => ({
      text: order.price.formatted,
      description: order.instrument.quantity?.formatted
    }),
    label: 'Preço / Quantidade'
  },
  {
    align: 'right',
    component: MoneyCell,
    getter: (order: PortfolioOrder) => ({
      text: order.grossValue.formatted,
      description: order.date.formatted,
      enableToggleMoneyOnDescription: false
    }),
    label: 'Valor / Lançamento'
  },
  {
    align: 'right',
    component: OrderStatusCell,
    getter: (order: PortfolioOrder) => ({
      status: order.status.value,
      label: order.status.formatted
    }),
    label: 'Status'
  }
];
