import Router from 'vue-router';
import { NavigationError } from '@/types/navigation/navigation-error';

const hasPreviousRoute = (
  error: NavigationError | Error
): error is NavigationError => 'route' in error;

export const handleNavigationError = (router: Router) => {
  return (error: NavigationError | Error) => {
    console.error(error);

    const previousRoute = hasPreviousRoute(error) ? error.route : '/v3/home';
    router.push({ name: 'navigation-error', query: { previousRoute } });
  };
};
