export enum RLPSteps {
  Information = 'Information',
  CancellationForm = 'CancellationForm'
}

export enum RLPPageStatus {
  Default = 'default',
  Error = 'error',
  Loading = 'loading'
}
