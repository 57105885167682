
import { Component, Vue, InjectReactive } from 'vue-property-decorator';

import { OrdersList } from '@/modules/trade/components/OrdersList';

import {
  trackOrderClick,
  trackViewAllOrdersClick
} from '@/modules/trade/views/Instrument/trackers';
import {
  getOrdersSummary,
  OrderSummary
} from '@/modules/trade/services/orders';
import { OrderFiltersQueryParamsKeys } from '@/modules/trade/views/Orders/types';

@Component({
  components: {
    OrdersList
  }
})
export default class OrdersSection extends Vue {
  orders: OrderSummary[] | null = null;
  error = false;
  loading = true;
  currentSelectedOrder: OrderSummary | null = null;
  isOrderModalOpen = false;

  @InjectReactive('ticker')
  readonly symbol!: string;

  created() {
    this.fetchOrders();
  }

  get ordersPageHref() {
    return this.$router.resolve({
      name: 'orders',
      query: {
        [OrderFiltersQueryParamsKeys.Instrument]: this.symbol
      }
    }).href;
  }

  async handleOnRetry() {
    await this.fetchOrders();
  }

  handleOnViewMoreClick() {
    trackViewAllOrdersClick();
  }

  handleOnOrderClick(order: OrderSummary) {
    trackOrderClick(order.description);
  }

  private async fetchOrders() {
    try {
      this.loading = true;
      this.error = false;

      const data = await getOrdersSummary({
        itemsPerPage: 3,
        page: 1,
        symbol: this.symbol
      });

      this.orders = data.orders;
    } catch {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }
}
