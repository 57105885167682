export const investmentSummaryCaption =
  'Produtos que estiverem fora do horário de movimentação do mercado serão agendados. Garanta que o valor do investimento esteja disponível na sua Conta Digital no próximo dia útil para que estas operações sejam realizadas.';

export const redemptionSummaryCaption =
  'A solicitação de resgate é baseada no valor bruto. Isso significa que o total líquido poderá ser alterado devido à variação das cotas no dia em que a operação for concluída, considerando impostos e taxas.';

export const investmentPortfolioCaption =
  'O valor total do investimento será distribuído entre os produtos da carteira de forma balanceada após o aceite da sugestão. Você poderá visualizar os valores e o status individuais na lista de movimentações.';

export const redemptionPortfolioCaption =
  'O valor total será resgatado dos produtos da carteira de forma balanceada após o aceite da sugestão. Você poderá visualizar os valores, data de liquidação e o status individuais de cada produto na lista de movimentações.';
