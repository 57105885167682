
import { Component, Vue } from 'vue-property-decorator';
import { BaseRoundedButton } from '@/modules/common/components/__deprecated__/BaseRoundedButton';

@Component({
  extends: BaseRoundedButton
})
export default class CloseButton extends Vue {
  private icon = 'ic_cancel';

  private alt = 'Fechar';
}
