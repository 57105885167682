
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InitialAvatarIcon } from '@warrenbrasil/nebraska-web';
import { EDirection } from '@/modules/transaction/services/unified-account/enums';

@Component({
  components: { InitialAvatarIcon }
})
export default class StatementAvatar extends Vue {
  @Prop({ type: String, required: true })
  readonly iconName!: string;

  @Prop({ type: String, required: true })
  readonly type!: EDirection;

  @Prop({ type: String, default: 'extra-small' })
  readonly size!: string;

  get computedClass() {
    return `initial-avatar-icon__${this.type}`;
  }
}
