const BREAKPOINTS = {
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1200
};

let bodyTop = 0;

export const isDesktop = () => window && window.innerWidth > BREAKPOINTS.md;
export const isMobile = () => window && window.innerWidth <= BREAKPOINTS.sm;

export const enableDarkMode = () => {
  document.documentElement.classList.add('is-dark-mode-enabled');
  localStorage.setItem('warren.darkMode', 'enabled');
};

export const disableDarkMode = () => {
  document.documentElement.classList.remove('is-dark-mode-enabled');
  localStorage.setItem('warren.darkMode', 'disabled');
};

export const switchDarkMode = () => {
  const darkMode = localStorage.getItem('warren.darkMode') || 'disabled';

  if (darkMode === 'disabled') {
    enableDarkMode();
  } else {
    disableDarkMode();
  }
};

export const isLocalDarkMode = () => {
  const darkMode = localStorage.getItem('warren.darkMode') || 'disabled';
  return darkMode === 'enabled';
};

const bodyTopSetter = (top = 0) =>
  document.documentElement.style.setProperty('--bodyTop', top + 'px');

export const toggleBodyScroll = () => {
  const { body, documentElement } = document;
  const scrollIsBlocked = body.classList.contains('body-scroll--hidden');

  if (scrollIsBlocked) {
    body.classList.remove('body-scroll--hidden');
    documentElement.scrollTo(0, bodyTop);
    bodyTopSetter();
  } else {
    bodyTop = documentElement.scrollTop;
    bodyTopSetter(-bodyTop);
    body.classList.add('body-scroll--hidden');
  }
};

export const removeBodyScroll = () => {
  const { body, documentElement } = document;
  if (!document.body.classList.contains('body-scroll--hidden')) {
    bodyTop = documentElement.scrollTop;
    bodyTopSetter(-bodyTop);
    body.classList.add('body-scroll--hidden');
  }
};

export const addBodyScroll = () => {
  document.body.classList.remove('body-scroll--hidden');
  bodyTop = 0;
};

export const addBodyScrollWithScrollTo = () => {
  const { documentElement } = document;
  if (document.body.classList.contains('body-scroll--hidden')) {
    document.body.classList.remove('body-scroll--hidden');
    documentElement.scrollTo(0, bodyTop);
    bodyTopSetter();
    bodyTop = 0;
  }
};

export const initTheme = (): void => {
  if (isLocalDarkMode()) {
    enableDarkMode();
  } else {
    disableDarkMode();
  }
};

export const copyToClipboard = async (value: string) => {
  try {
    await navigator.clipboard.writeText(value);
  } catch (err) {
    throw new Error(`copyToClipboard: unable to copy correctly: ${err}`);
  }
};
