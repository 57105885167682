import { IRootState } from '@/store';
import { ICustomer } from '@/types/models';
import { Plugin, Store, MutationPayload } from 'vuex';
import DatadogRum from './datadog-rum';

type MapAction = Record<string, Function>;

export function setUserDatadogRum(): Plugin<IRootState> {
  return (store: Store<IRootState>) => {
    store.subscribe((mutation: MutationPayload, state: IRootState) => {
      const isRumEnabled = process.env.VUE_APP_ENABLE_RUM === 'true';
      if (!isRumEnabled) return;

      const map: MapAction = {
        'CustomerModule/setCustomer': () =>
          // @ts-ignore
          DatadogRum.setUser(state.CustomerModule.customer as ICustomer),
        'CustomerModule/resetCustomer': () => DatadogRum.removeUser()
      };
      const handleDatadogUser = map[mutation.type];
      handleDatadogUser && handleDatadogUser();
    });
  };
}
