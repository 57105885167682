import {
  PensionPaymentFrequency,
  PensionPaymentType,
  PensionTaxType,
  PortabilityTransferType
} from '@warrenbrasil/pension-types/dist/next';

type PensionEnums =
  | PensionTaxType
  | PensionPaymentType
  | PensionPaymentFrequency
  | PortabilityTransferType;

export class PensionLabels {
  static fromEnum(enumValue: PensionEnums) {
    const mapper = {
      [PensionTaxType.Regressive]: 'Regressivo',
      [PensionTaxType.Progressive]: 'Progressivo',
      [PensionPaymentType.BankAccountDebit]: 'Débito automático',
      [PensionPaymentType.BankSlip]: 'Boleto bancário',
      [PensionPaymentFrequency.Monthly]: 'Mensal',
      [PensionPaymentFrequency.Sporadic]: 'Esporádico',
      [PortabilityTransferType.Total]: 'Total',
      [PortabilityTransferType.Partial]: 'Parcial'
    };

    return mapper[enumValue] || 'N/A';
  }
}
