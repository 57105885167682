export const accountIncomeTaxDescription = {
  description:
    'Seu dinheiro rende 100% do CDI na Conta Remunerada. Você paga uma taxa sobre os rendimentos, calculada diariamente (valor da taxa, dividida pelo CDI anual, multiplicada pelo rendimento).',
  rulesIntro: 'A taxa varia de acordo com o valor investido na Warren:'
};

export const accountIncomeTaxRules = [
  {
    description: 'Até R$ 100 mil aplicados, a taxa é de 0,9% a.a.'
  },
  {
    description: 'De R$ 100 mil a R$ 1 milhão aplicados, a taxa é de 0,8% a.a'
  },
  {
    description:
      'De R$ 1 milhão a R$ 10 milhões aplicados, a taxa é de 0,7% a.a'
  }
];
export const accountIncomeTaxDigital = {
  title: 'Na Conta Digital:',
  descriptionText:
    'A cobrança refere-se à taxa calculada e provisionada sobre seus rendimentos até o dia 30 de Junho de 2023. Após esta data, não há mais cobrança de taxa para manutenção do dinheiro na Conta Digital.'
};

export const tradeHelperTextWithBrokerageFee =
  'Valor referente a corretagem dos serviços utilizados em bolsa. Para mais detalhes, verifique as suas notas de negociação.';

export const tradeHelperTextWithoutBrokerageFee =
  'Investir e acompanhar seus investimentos na Bolsa de Valores tem custo zero de corretagem.';
