import highcharts from 'highcharts';

export function easeInOutQuart(x: number): number {
  return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
}

export const animationsOptions = {
  mainSeriesInitialRender: {
    easing: easeInOutQuart,
    duration: 2000
  },
  mainSeriesTransition: {
    easing: easeInOutQuart,
    duration: 1000
  },
  benchmarkSeriesInitialRender: {
    easing: easeInOutQuart,
    duration: 2000,
    defer: 300
  },
  benchmarkSeriesTransition: {
    easing: easeInOutQuart,
    duration: 1000,
    defer: 150
  }
};

// Estamos usando um easing no gradient: https://larsenwork.com/easing-gradients/
export function getGradientWithEasing(color: string) {
  return [
    [0, highcharts.color(color).setOpacity(0.6).get('rgba')],
    [0.008, highcharts.color(color).setOpacity(0.54).get('rgba')],
    [0.031, highcharts.color(color).setOpacity(0.481).get('rgba')],
    [0.068, highcharts.color(color).setOpacity(0.422).get('rgba')],
    [0.118, highcharts.color(color).setOpacity(0.366).get('rgba')],
    [0.177, highcharts.color(color).setOpacity(0.311).get('rgba')],
    [0.245, highcharts.color(color).setOpacity(0.259).get('rgba')],
    [0.321, highcharts.color(color).setOpacity(0.21).get('rgba')],
    [0.403, highcharts.color(color).setOpacity(0.166).get('rgba')],
    [0.488, highcharts.color(color).setOpacity(0.125).get('rgba')],
    [0.576, highcharts.color(color).setOpacity(0.089).get('rgba')],
    [0.665, highcharts.color(color).setOpacity(0.058).get('rgba')],
    [0.754, highcharts.color(color).setOpacity(0.034).get('rgba')],
    [0.84, highcharts.color(color).setOpacity(0.015).get('rgba')],
    [0.923, highcharts.color(color).setOpacity(0.004).get('rgba')],
    [1, highcharts.color(color).setOpacity(0).get('rgba')]
  ];
}
