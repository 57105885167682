import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';

import { IAllocationItem } from '@/modules/catalog/services/variable-income';

export const columns = [
  {
    label: 'Ativo',
    component: InstrumentNameCell,
    getter: (item: IAllocationItem) => ({
      symbol: item.symbol,
      name: item.name || '-',
      size: 'small',
      hasEllipsis: true
    })
  },
  {
    label: 'Rentabilidade',
    align: 'right',
    getter: (item: IAllocationItem) => item.profitability
  },
  {
    label: 'Peso na carteira',
    align: 'right',
    getter: (item: IAllocationItem) => item.weight
  }
];
