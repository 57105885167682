
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';

@Component({ name: 'Feature' })
export default class Feature extends Vue {
  // FeatureFlag name
  @Prop({
    type: String as PropType<FeatureFlags>,
    validator(name: string) {
      const validFeatures = Object.values(FeatureFlags);
      return validFeatures.includes(name as FeatureFlags);
    },
    required: true
  })
  private name!: FeatureFlags;

  // Container element tag
  @Prop({ type: String, default: 'div' })
  private as!: string;

  private get isFeatureEnabled() {
    const targetFeature = this.name;
    return FeatureFlagsModule.isEnabled(targetFeature);
  }
}
