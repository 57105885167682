import { httpClient } from '@/modules/common/services/http/http-client';
import {
  BalancesResponse,
  IExperienceResponse,
  IUpdateCustomerExperienceResponse,
  HomeStatementsResponse,
  CashSummaryResponse,
  INotifications,
  NewsResponse
} from './types';

const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/banking/v1/home`
});

export function getNews(customerId: string): Promise<NewsResponse> {
  return http.get('/getNews', { params: { customerId } });
}

export function getCustomerExperience(
  brandId: string | undefined
): Promise<IExperienceResponse> {
  return http.get('/getCustomerExperience', { params: { brandId } });
}

export function updateCustomerExperience(
  name: string
): Promise<IUpdateCustomerExperienceResponse> {
  return http.put('/updateCustomerExperience', { name });
}

export function getStatements(): Promise<HomeStatementsResponse> {
  return http.get('/statements');
}

export function getBalances(): Promise<BalancesResponse> {
  return http.get('/balances');
}

export function getCashSummary(): Promise<CashSummaryResponse> {
  return http.get('/cashSection');
}

export function getNotifications(): Promise<INotifications> {
  return http.get('/notifications');
}
