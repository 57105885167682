
import { Component, Vue } from 'vue-property-decorator';

import {
  NavBar,
  BaseIcon,
  ButtonSecondary,
  ButtonPrimary,
  BaseText,
  BrandImage,
  EBrandImageSchemas,
  EBrandImageLogos
} from '@warrenbrasil/nebraska-web';
import { Dropdown } from '@/modules/products/components/Dropdown';
import { DropdownItem } from '@/modules/products/components/DropdownItem';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { MobileMenu } from '@/modules/products/components/MobileMenu';
import { GtmTracker } from '@/modules/common/services/analytics';

@Component({
  components: {
    BaseIcon,
    BaseText,
    ButtonPrimary,
    ButtonSecondary,
    Dropdown,
    DropdownItem,
    MobileMenu,
    NavBar,
    BrandImage
  }
})
export default class ProductsMenu extends Vue {
  private readonly NebraskaColors = NebraskaColors;
  readonly EBrandImageLogos = EBrandImageLogos;
  readonly EBrandImageSchemas = EBrandImageSchemas;

  private isOpenMobile = false;

  private get trackMenuEvent() {
    return GtmTracker.trackerWithDefaultProps({
      label: 'Produtos',
      category: 'Navigation Desktop'
    });
  }

  private setMenuMobile() {
    this.isOpenMobile = !this.isOpenMobile;
  }

  private openChat() {
    window.openChat();
  }
}
