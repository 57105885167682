
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  InitialAvatarIcon,
  BaseText,
  HelperView
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: { InitialAvatarIcon, BaseText, HelperView }
})
export default class OrderFeedback extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly isError!: boolean;

  @Prop({ type: String, required: false, default: '' })
  readonly title!: string;

  @Emit('click')
  handleClick() {}

  readonly NebraskaColors = NebraskaColors;
}
