import { object, string } from 'yup';
import { getString } from '@/modules/common/helpers/resources';

const MESSAGES = {
  EMAIL_REQUIRED: getString('common_field_email_error_required'),
  EMAIL_INVALID: getString('common_field_email_error_invalid')
};

export const changeEmailSchema = object({
  newEmail: string()
    .email(MESSAGES.EMAIL_INVALID)
    .required(MESSAGES.EMAIL_REQUIRED)
});
