import { GtmTracker } from '@/modules/common/services/analytics';
import {
  TrackingSubscriptionPopUpActions,
  TrackSubscriptionPageActions,
  TrackingCancelReserveModalActions
} from './enums';

interface TrackSubscritionEventProps {
  instrument_type: string;
  instrument_name: string;
  instrument_symbol: string;
}

interface TrackSubscritionEventReserveProps extends TrackSubscritionEventProps {
  subscrition_action: TrackSubscriptionPageActions;
}

interface TrackSubscritionEventCancelReserveProps
  extends TrackSubscritionEventProps {
  subscrition_action: TrackingCancelReserveModalActions;
}

const trackSubscriptionEvent = GtmTracker.trackerWithDefaultProps({
  digital_experience: 'investor',
  experience_section: 'broker'
});

export function trackSubscriptionPopUp(
  event_name: TrackingSubscriptionPopUpActions
) {
  trackSubscriptionEvent({
    page_screen: 'home_broker',
    event_type: 'click',
    page_screen_section: 'subscription_popup',
    event_name
  });
}

export function trackLoadSubscription() {
  trackSubscriptionEvent({
    page_screen: 'asset_subscription_page',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackHelpSubscriptionPage() {
  trackSubscriptionEvent({
    page_screen: 'asset_subscription_page',
    event_type: 'click',
    page_screen_section: 'header',
    event_name: 'view_faq'
  });
}

export function trackReserveSubscriptionPageActions({
  instrument_type,
  instrument_name,
  instrument_symbol,
  subscrition_action
}: TrackSubscritionEventReserveProps) {
  trackSubscriptionEvent({
    page_screen: 'asset_subscription_page',
    event_type: 'click',
    page_screen_section: 'subscription',
    event_name: subscrition_action,
    instrument_type,
    instrument_name,
    instrument_symbol
  });
}

export function trackCancelReserveModal({
  instrument_type,
  instrument_name,
  instrument_symbol,
  subscrition_action
}: TrackSubscritionEventCancelReserveProps) {
  trackSubscriptionEvent({
    page_screen: 'asset_subscription_page',
    event_type: 'click',
    page_screen_section: 'cancel_reservation',
    event_name: subscrition_action,
    instrument_type,
    instrument_name,
    instrument_symbol
  });
}
