
import { namespace } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';

import { ErrorHandler } from '@/modules/common/components/ErrorHandler';
import { Row, RoundedIcon, SkeletonLoader } from '@warrenbrasil/nebraska-web';

import { TradeMarketAvailable } from '@/modules/trade/types';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RequestStatus } from '@/modules/trade/store/trade';
import { trackMarketStatusRetryClick } from '@/modules/trade/views/TradeView/trackers';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    Row,
    ErrorHandler,
    RoundedIcon,
    SkeletonLoader
  }
})
export default class TradeStatus extends Vue {
  @Prop({ type: Object, required: false })
  readonly status?: TradeMarketAvailable;

  @tradePositionsModule.State('accountStatusRequestStatus')
  readonly accountStatusRequestStatus!: RequestStatus;

  @tradePositionsModule.Action('fetchStatus')
  readonly fetchStatus!: Function;

  readonly RequestStatus = RequestStatus;

  get NebraskaColors() {
    return NebraskaColors;
  }

  get marketStatusFormatted() {
    return this.status?.marketStatus?.formatted;
  }

  get statusDescription() {
    return this.status?.description;
  }

  get setMarketIcon() {
    const marketType = String(this.status?.marketStatus?.value);
    if (marketType === 'MARKET_UNAVAILABLE') {
      return 'ic_alert';
    }
    if (marketType === 'MARKET_HOLIDAY') {
      return 'ic_calendar_2';
    }

    return 'ic_watch_warning';
  }

  async handleTradeStatusRetryClick() {
    trackMarketStatusRetryClick();
    await this.fetchStatus();
  }
}
