import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import {
  CSAT,
  FeatureSurvey,
  IgetCsatResponse,
  getCSAT
} from '@/modules/common/services/http/feature-survey';
import { StorageKeys } from '@/modules/common/services/local-storage';

function getFeatureSurveyFromLocalStorage(): FeatureSurvey[] {
  const data = localStorage.getItem(StorageKeys.FeatureSurvey);
  return data ? JSON.parse(data) : [];
}

export interface FeatureSurveyModuleType {
  addFeatureSurvey: (item: FeatureSurvey) => void;
  reset: () => void;
  remove: (item: FeatureSurvey) => void;
  save: (data: FeatureSurvey[]) => void;
  setIsAnswered: (item: FeatureSurvey) => void;
  exists: boolean;
  first: FeatureSurvey;
  list: FeatureSurvey[];
  getCSAT: (name: CSAT) => void;
  local?: IgetCsatResponse;
}

@Module({
  namespaced: true,
  name: 'FeatureSurveyModule'
})
export class FeatureSurveyStoreModule
  extends VuexModule
  implements FeatureSurveyModuleType
{
  data: FeatureSurvey[] = getFeatureSurveyFromLocalStorage();
  local?: IgetCsatResponse;

  public get list(): FeatureSurvey[] {
    return this.data.filter(item => !item.answered);
  }

  public get exists(): boolean {
    return this.list.length > 0;
  }

  public get first(): FeatureSurvey {
    const [first] = this.list;
    return first;
  }

  @Mutation
  save(value: FeatureSurvey[]) {
    localStorage.setItem(StorageKeys.FeatureSurvey, JSON.stringify(value));
    this.data = value;
  }

  @Action
  public async addFeatureSurvey(item: FeatureSurvey) {
    const hasItem = this.data.find(row => row.name === item.name);
    if (!hasItem) {
      try {
        const { getCsat: csat }: IgetCsatResponse = await getCSAT(item.name);
        if (csat) {
          const newCsat = {
            ...item,
            formId: csat.formId
          };
          this.context.commit('save', [...this.data, newCsat]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  @Action
  public async reset() {
    this.context.commit('save', []);
  }

  @Action
  public async remove(item: FeatureSurvey) {
    const data = this.data.filter(row => row.name !== item.name);
    this.context.commit('save', data);
  }

  @Action
  public async setIsAnswered(item: FeatureSurvey) {
    const data = this.data;
    const foundIndex = data.findIndex(row => row.name === item.name);
    data.splice(foundIndex, 1, { ...item, answered: true });
    this.context.commit('save', data);
  }

  @Action({ rawError: true })
  public async getCSAT(name: CSAT) {
    return getCSAT(name);
  }
}
