
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { Heading, BaseText, Row } from '@warrenbrasil/nebraska-web';
import { IWalletDetails } from '@/modules/transaction/views/InvestmentTransaction/types';

@Component({
  name: 'AccountWalletDetails',
  components: { Heading, BaseText, Row }
})
export default class AccountWalletDetails extends Vue {
  @Prop({ type: Object, required: true })
  private walletDetails!: IWalletDetails;

  private NebraskaColors = NebraskaColors;
}
