import { GtmTracker } from '@/modules/common/services/analytics';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  category: 'PEX'
});

export function trackBackEvent() {
  trackEvent({
    action: 'Clique fechar',
    label: 'Investir por produto'
  });
}

export function trackHasNoBalance() {
  trackEvent({
    action: 'Aviso saldo insuficiente',
    label: 'Investir por produto'
  });
}

export function trackCancelButton() {
  trackEvent({
    action: 'Clique fechar',
    label: 'Investir por produto - Erro'
  });
}

export function trackTryAgainButton() {
  trackEvent({
    action: 'Clique tentar novamente',
    label: 'Investir por produto - Erro'
  });
}

export function trackEventSuccess() {
  trackEvent({
    label: 'Investir por produto',
    action: 'Clique investir - Sucesso'
  });
}

export function trackEventError() {
  trackEvent({
    label: 'Investir por produto',
    action: 'Clique investir - Erro'
  });
}

const trackEventAmplitude = GtmTracker.trackerWithDefaultProps({
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackAmplitudeInvestmentButton(valueInvestment: string) {
  trackEventAmplitude({
    page_screen: 'portfolio_investment_flow_instrument_investment',
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'invest_money',
    event_target: valueInvestment
  });
}
