export enum OrderStatus {
  Placing = 'Placing',
  Inactive = 'Inactive',
  Working = 'Working',
  Rejected = 'Rejected',
  Filled = 'Filled',
  PartiallyFilled = 'Partially_filled',
  Cancelled = 'Cancelled',
  Expired = 'Expired'
}

export enum OrderTypes {
  Inplit = 'Inplit',
  Split = 'Split',
  Limit = 'Order',
  Portability = 'AssetTransfer'
}
