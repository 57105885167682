import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'disconected_device',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackDisconnectDevicesFeedbackLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackCloseClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'closer',
    page_screen_section: 'header'
  });
}

export function trackBackToLogin() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'back_to_login',
    page_screen_section: 'body',
    event_targer: 'Voltar para o login'
  });
}
