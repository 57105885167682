
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { BaseText, ModalButton, Modal } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    BaseText,
    Modal,
    ModalButton
  }
})
export default class ConfirmationModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly showModal!: boolean;

  readonly title = 'Confirmar logout';
  readonly text = 'Ao confirmar, você será deslogado da plataforma';

  @Emit('submit')
  submit() {}

  @Emit('close')
  close() {}
}
