
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Checkbox extends Vue {
  /** Value informing that checkbox is either checked or not */
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private value!: boolean;
  @Prop({ type: String, required: false }) private name?: string;
  @Prop({ type: Boolean, default: false }) private required!: boolean;
  @Prop({ type: String, required: false }) private id?: string;
}
