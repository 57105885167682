import { BaseHttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';
import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IPortfolioDistributionResponse,
  INewAddProductResponse,
  IProduct,
  IUpdateProductResponse,
  IRemoveProductResponse,
  IDataConfirmEdit,
  IWalletEditConfirmResponse,
  IInstrumentDetailsResponse,
  IResetPortfolioResponse,
  IGetProductsPayload,
  IEditPortfolioResponse,
  IGetLastState,
  ICreateWallet,
  IProductsRemoveParam
} from './types/models/IBffInvestments';
import {
  IFilterType,
  IGetInstrumentFilterPayload
} from './types/models/IFilters';

export const httpClientWithDefaultOptions = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getInstrumentsFilters(
  payload: IGetInstrumentFilterPayload
): Promise<IFilterType> {
  return httpClientWithDefaultOptions.get('/v1/getInstrumentFilters', {
    params: {
      ...payload
    }
  });
}

export function getProducts(payload: IGetProductsPayload) {
  const { filters, ...params } = payload;
  let url = '/v2/getProducts';

  if (filters) {
    url += '?' + filters;
  }

  return httpClientWithDefaultOptions.get(url, {
    params: {
      ...params
    }
  });
}

export class BffInvestments extends BaseHttpClient {
  constructor() {
    super(undefined, process.env.VUE_APP_BFF_INVESTMENTS_URL);
  }

  public getPortfolioDistribution(
    portfolioId: string
  ): Promise<IPortfolioDistributionResponse> {
    return this.get<IPortfolioDistributionResponse>(
      `/v1/getPortfolioDistribution/${portfolioId}`
    );
  }

  public getLastState(portfolioId?: string): Promise<IGetLastState> {
    let queryString = '';
    if (portfolioId) {
      queryString = `?portfolioId=${portfolioId}`;
    }
    return this.get(`/v2/products/getLastState${queryString}`);
  }

  public getInstrumentDetails(
    apiIds: string
  ): Promise<IInstrumentDetailsResponse> {
    return this.get<IInstrumentDetailsResponse>(
      `/v1/getInstrumentDetails/?apiIds[]=${apiIds}`
    );
  }

  public addProductToWallet(
    productId: string
  ): Promise<INewAddProductResponse> {
    return this.put(`/v2/products/${productId}/addToWallet`);
  }

  public termSignature(instrumentIds: string[]) {
    return this.post('/v1/termSignature', {
      instrumentIds
    });
  }

  public riskSignature(instrumentIds: string[]) {
    return this.post('/v1/riskSignature', {
      instrumentIds
    });
  }

  public updateProduct(product: IProduct): Promise<IUpdateProductResponse> {
    return this.put<IUpdateProductResponse>(`/v1/updateProduct`, product);
  }

  public confirmEditPortfolio(
    allocation: IDataConfirmEdit
  ): Promise<IWalletEditConfirmResponse> {
    return this.put<IWalletEditConfirmResponse>(
      `/v1/editPortfolio`,
      allocation
    );
  }

  public resetPortfolio(portfolioId = ''): Promise<IResetPortfolioResponse> {
    return this.put<IResetPortfolioResponse>(
      `/v1/resetPortfolio/${portfolioId}`
    );
  }

  public removeProducts({
    productIds
  }: IProductsRemoveParam): Promise<IRemoveProductResponse> {
    return this.delete<IRemoveProductResponse>('/v2/products/removeToWallet', {
      data: {
        productIds
      }
    });
  }

  public createWallet(
    name: string,
    isTemplate = false
  ): Promise<ICreateWallet> {
    return this.post('/v2/home/portfolios/advanced', {
      name,
      isTemplate
    });
  }

  public editWallet(portfolioId = ''): Promise<IEditPortfolioResponse> {
    return this.put<IEditPortfolioResponse>(`/v2/portfolios/${portfolioId}`);
  }
}
