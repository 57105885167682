
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { BulletAsset } from '@/modules/common/components/BulletAsset';
import { AllocationChart } from './components/AllocationChart';
import { Money } from '@/modules/common/components/MoneyV2';
import { PieChartData, SelectAssetType } from './types';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { CategoryColor } from './enums';
import {
  BaseText,
  Row,
  SkeletonLoader,
  Modal,
  HeadingDisplay
} from '@warrenbrasil/nebraska-web';
import { HeadingDisplayLoader } from '@/modules/trade/components/HeadingDisplayLoader';
import {
  PositionsChart,
  AssetType,
  Instrument,
  InstrumentHistorical
} from '@/modules/trade/services/instruments';
import { NumberFormatted } from '@/modules/trade/types';

@Component({
  components: {
    BaseText,
    SkeletonLoader,
    AllocationChart,
    BulletAsset,
    Row,
    Money,
    Modal,
    HeadingDisplay,
    HeadingDisplayLoader
  }
})
export default class AllocationChartModal extends Vue {
  private selectedAsset: AssetType = {} as AssetType;
  public CategoryColor = CategoryColor;

  @Prop({ type: Object, required: false })
  readonly chartData?: PositionsChart;

  @Prop({ type: Boolean, required: true })
  readonly loading!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean;

  @Prop({ type: Array, required: false })
  readonly instruments?: Instrument[] | InstrumentHistorical[];

  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: Object, required: false })
  readonly updatedAt?: NumberFormatted;

  @Emit('close')
  public emitClose() {}

  get titleChart() {
    const totalSelected = this.selectedAsset?.assetType?.formatted;
    return totalSelected ? `Total em ${totalSelected}` : 'Total alocado';
  }

  get titlePercentage() {
    const totalAlocated = this.chartData?.allocatedTotal?.formatted || '-';
    return this.selectedAsset?.percentage?.formatted ?? totalAlocated;
  }

  get titleTotalValue() {
    const activesAlocatedQuantity = this.chartData?.amountAssetsTotal || '-';
    return (
      this.selectedAsset?.allocatedTotal?.formatted ??
      `em ${activesAlocatedQuantity} ativos`
    );
  }

  get NebraskaColors() {
    return NebraskaColors;
  }

  get hasChartData() {
    return (
      this.chartData?.amountAssetsTotal && this.chartData?.amountAssetsTotal > 0
    );
  }

  get seriesDataPieChart(): Array<PieChartData> {
    const pieChartData = (this.chartData?.assetTypes || []).map(assetType => {
      const categoryValue = assetType.assetType?.value;
      const categoryName = assetType.assetType?.formatted;
      const categoryNumber = assetType.allocatedTotal.value;
      const categoryColor = assetType.color;
      return {
        name: categoryName,
        color:
          categoryColor ||
          this.getChartCategoryColor(categoryValue as CategoryColor),
        category: categoryValue as CategoryColor,
        y: categoryNumber,
        z: 92.9
      };
    });
    if (!this.hasChartData) return [];
    return pieChartData;
  }

  get chartDataAssetTypes() {
    return this.chartData?.assetTypes;
  }

  public selectAssetType({ assetType }: SelectAssetType) {
    this.selectedAsset = assetType;
  }

  getChartCategoryColor(categoryColor: CategoryColor | string): NebraskaColors {
    switch (categoryColor) {
      case CategoryColor.Stock:
        return NebraskaColors.assetFixedIncome;
      case CategoryColor.Fii:
        return NebraskaColors.assetStocks;
      case CategoryColor.Etf:
        return NebraskaColors.benchmarkCdi;
      case CategoryColor.Bdr:
        return NebraskaColors.assetOthers;
      case CategoryColor.Others:
        return NebraskaColors.benchmarkIpca;

      default:
        return NebraskaColors.assetFixedIncome;
    }
  }
}
