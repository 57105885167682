import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'contact_warren',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackTalkToWarrenLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackSendEmailClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'send_email',
    page_screen_section: 'body',
    event_target: 'meajuda@warren.com.br.'
  });
}

export function trackOpenChatClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'talk_chat',
    page_screen_section: 'body',
    event_target: 'Acessar chat.'
  });
}

export function trackGoFaqClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'go_faq',
    page_screen_section: 'body',
    event_target: 'Ir para FAQ.'
  });
}

export function trackGoBackClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'back',
    page_screen_section: 'header'
  });
}
