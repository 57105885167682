
import { Component, Vue } from 'vue-property-decorator';
import { BaseIcon, BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { getString } from '@/modules/common/helpers/resources';

@Component({ components: { BaseIcon, BaseText } })
export default class RegulationUpdateInfo extends Vue {
  private disclaimer = getString('account_update_cvm_disclaimer');
  private iconColor = NebraskaColors.statusInfo;
}
