
import { Vue, Component, Emit } from 'vue-property-decorator';
import { InputText } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    InputText
  }
})
export default class InstrumentsSearch extends Vue {
  public inputValue = '';

  @Emit('clear-search-input')
  clearInput() {
    this.inputValue = '';
  }
}
