
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModalFooter } from '@/modules/common/components/__deprecated__/Modal';
import { CloseButton } from '@/modules/common/components/buttons';
import {
  NavBar,
  BankAccount,
  Heading,
  BaseText,
  BaseModal
} from '@warrenbrasil/nebraska-web';
import { IBankAccount } from '@/modules/transaction/views/InvestmentTransaction/types';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    BankAccount,
    ModalFooter,
    CloseButton,
    NavBar,
    Heading,
    BaseText,
    BaseModal
  }
})
export default class BankDetailsModal extends Vue {
  @Prop({ type: Boolean, required: true })
  private isOpen!: boolean;

  @Prop({ type: String, required: true })
  private investmentAccountId!: string;

  @Prop({ type: Object, required: true })
  private bankAccount!: IBankAccount;

  @Prop({ type: Boolean, default: true })
  private hasMoneyInAccount!: boolean;

  private NebraskaColors = NebraskaColors;

  private emitCloseEvent() {
    this.$emit('close');
  }
}
