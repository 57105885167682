import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=3e28d98e&scoped=true&"
import script from "./Modal.vue?vue&type=script&lang=ts&"
export * from "./Modal.vue?vue&type=script&lang=ts&"
import style0 from "./Modal.vue?vue&type=style&index=0&id=3e28d98e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e28d98e",
  null
  
)

export default component.exports