
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Heading,
  DataTable,
  Pagination,
  SkeletonLoader,
  DataTableProps
} from '@warrenbrasil/nebraska-web';
import { OrderFeedback } from '@/modules/wealth/views/WealthOrders/components/OrderFeedback';
import { OrderFilters } from '@/modules/wealth/views/WealthOrders/components/OrderFilters';

import { getOrderList, IOrders } from '@/modules/wealth/services/wealth-orders';
import {
  IFiltersValue,
  IOrdersPagination
} from '@/modules/wealth/views/WealthOrders/types';
import { columns } from '@/modules/wealth/views/WealthOrders/helpers/tableHelpers';
import { OrderModal } from '@/modules/wealth/views/WealthPortfolioV2/components/Orders/components/OrderModal';

@Component({
  components: {
    Heading,
    DataTable,
    OrderFilters,
    Pagination,
    OrderFeedback,
    SkeletonLoader,
    OrderModal,
    DataTableProps
  }
})
export default class OrderList extends Vue {
  @Prop({ type: String, default: '' })
  readonly portfolioId!: string;

  orders: IOrders[] | null = null;
  portfolioName = '';
  transactionId? = '';
  shouldGetName = true;
  isOrderModalOpen = false;
  hasAppliedFilters = false;
  isLoading = false;
  hasError = false;
  isEmpty = false;
  pagination: IOrdersPagination | null = null;
  filters = {
    pageIndex: 1,
    portfolioApiId: ''
  };

  created() {
    this.filters = {
      ...this.filters,
      portfolioApiId: this.portfolioId
    };
  }

  get status() {
    if (this.isLoading) return DataTableProps.DataTableStatus.Loading;
    if (this.hasError) return DataTableProps.DataTableStatus.Error;
    return DataTableProps.DataTableStatus.Default;
  }

  get title() {
    return this.isWallet && this.portfolioName
      ? `Ordens da Carteira "${this.portfolioName}"`
      : 'Ordens de Carteira';
  }

  get isWallet() {
    return Boolean(this.portfolioId);
  }

  get showPagination() {
    return (
      this.pagination &&
      !this.isLoading &&
      !this.hasError &&
      this.pagination.totalPages > 1
    );
  }

  get isDisabled() {
    return this.isLoading || this.hasError;
  }

  get columns() {
    return columns;
  }

  async getOrderList() {
    this.isLoading = true;
    this.hasError = false;
    try {
      const data = await getOrderList(this.filters);
      this.updatePortfolioName(data.orders[0]?.portfolioName || '');
      this.pagination = {
        page: data.pageIndex,
        pageSize: data.pageSize,
        totalPages: data.totalPages
      };
      this.isEmpty = data.orders.length === 0;
      this.orders = data.orders || [];
    } catch (e) {
      console.error(e);
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  private updatePortfolioName(name: string) {
    this.portfolioName = this.shouldGetName ? name : this.portfolioName;
    this.shouldGetName = false;
  }

  changePage(value: number) {
    this.filters = { ...this.filters, pageIndex: value };
    this.getOrderList();
  }

  handleClick(row: IOrders) {
    this.transactionId = row.apiId;
    this.toggleOrderModal();
  }

  goBack() {
    this.$router.back();
  }

  handleFiltersChange(data: {
    hasAppliedFilters: boolean;
    filters: IFiltersValue;
  }) {
    this.isEmpty = false;
    this.hasError = false;
    this.filters = { ...this.filters, ...data.filters, pageIndex: 1 };
    this.hasAppliedFilters = data.hasAppliedFilters;

    this.getOrderList();
  }

  toggleOrderModal() {
    this.isOrderModalOpen = !this.isOrderModalOpen;
  }

  mounted() {
    this.getOrderList();
  }
}
