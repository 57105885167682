
import { Vue, Prop, Component, Emit } from 'vue-property-decorator';

import { FilterSelect } from '@warrenbrasil/nebraska-web';
import { FilterSelectButton } from '@/modules/common/components/FilterSelectButton';
import { FiltersModal } from './components/FiltersModal';
import {
  IFilterCategory,
  IFilterOptions
} from '../../services/types/models/IFilters';

@Component({
  components: {
    FilterSelect,
    FilterSelectButton,
    FiltersModal
  }
})
export default class Filters extends Vue {
  isOpenFiltersModal = false;

  @Prop({ type: Array, required: true })
  public filters!: Array<IFilterCategory>;

  @Prop({ type: Number, required: true })
  public numberAppliedFilters!: number;

  getFilterOptions(filters?: IFilterOptions[]) {
    return filters?.map(filter => ({
      label: filter.formatted,
      value: filter.value
    }));
  }

  @Emit('clear-filters')
  clearFilters() {}

  @Emit('apply-quick-filters')
  applyOrderQuickFilter() {
    this.filters[0].applied = true;
    this.$forceUpdate();
    return this.filters[0];
  }

  @Emit('reload-filters')
  reloadFilters() {
    this.toggleFiltersModal();
  }

  @Emit('open-filter-modal')
  toggleFiltersModal() {
    this.isOpenFiltersModal = !this.isOpenFiltersModal;
    return this.isOpenFiltersModal;
  }

  @Emit('apply-filters')
  public applyFilters() {}
}
