
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  Modal,
  BaseText,
  InputText,
  InputMoney,
  ToggleSwitch,
  ButtonContainer,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { IReminderModalProps } from './types';

@Component({
  components: {
    Modal,
    BaseText,
    InputText,
    InputMoney,
    ToggleSwitch,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary
  }
})
export default class InvestReminderModal extends Vue {
  @Prop({ type: Object, required: true })
  readonly data!: IReminderModalProps;

  @Emit('close')
  emitClose() {}

  @Emit('save')
  emitSave() {
    return {
      ...this.data,
      investReminderDay: this.investReminderDay,
      investReminder: this.investReminder,
      monthlyInvestment: this.monthlyInvestment
    };
  }

  @Watch('investReminderDay')
  private handleChangeReminderDay() {
    this.isValidInvestReminderDay();
  }

  @Watch('monthlyInvestment')
  private handleChangeMonthlyInvestment() {
    this.isValidMonthlyInvestment();
  }

  investReminderDay!: string;
  monthlyInvestment!: number;
  investReminder = false;

  investReminderDayStatus = {
    status: 'default',
    helperText: ''
  };

  monthlyInvestmentStatus = {
    status: 'default',
    helperText: ''
  };

  get toggleTitle() {
    return this.investReminder ? 'Habilitado' : 'Desativado';
  }

  get isValid() {
    return this.isValidInvestReminderDay() && this.isValidMonthlyInvestment();
  }

  private isValidInvestReminderDay() {
    const investReminderDay = parseInt(this.investReminderDay);

    if (
      investReminderDay === 0 ||
      investReminderDay > 28 ||
      isNaN(investReminderDay)
    ) {
      this.investReminderDayStatus = {
        status: 'error',
        helperText: 'O dia do lembrete deve ser entre 1 e 28.'
      };
      return false;
    }

    this.investReminderDayStatus = {
      status: 'default',
      helperText: ''
    };
    return true;
  }

  private isValidMonthlyInvestment() {
    if (this.monthlyInvestment === 0 && this.investReminder) {
      this.monthlyInvestmentStatus = {
        status: 'error',
        helperText: 'O valor não pode ser R$0,00.'
      };
      return false;
    }
    this.monthlyInvestmentStatus = {
      status: 'default',
      helperText: ''
    };
    return true;
  }

  created() {
    this.investReminderDay = this.data.investReminderDay;
    this.investReminder = this.data.investReminder;
    this.monthlyInvestment = this.data.monthlyInvestment;
  }
}
