import { PluginObject } from 'vue';
import { useDatadogLogs } from '@warrenbrasil/web-observability/logs';

export const datadogLogsPlugin: PluginObject<{}> = {
  install() {
    const appVersion =
      process.env.VUE_APP_WEB_PLATFORM_VERSION ||
      process.env.VUE_APP_COMMIT_SHA ||
      '?';
    const parsedEnvironment = process.env.VUE_APP_ENVIRONMENT || 'localhost';
    const datadogTokenFromEnv = process.env.VUE_APP_DATADOG_TOKEN!;

    useDatadogLogs({
      clientToken: datadogTokenFromEnv,
      service: 'web-platform',
      forwardErrorsToLogs: true,
      env: parsedEnvironment,
      version: appVersion
    });
  }
};
