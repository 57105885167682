import { Document } from '@warrenbrasil/pension-types/dist/next';
import { BaseHttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';
import {
  APIResponse,
  APIResponseStatus,
  DocumentListFilters,
  DocumentAcceptRequest,
  DocumentRejectRequest
} from '@/modules/pension/types';

export class DocumentAPI extends BaseHttpClient {
  constructor() {
    super(undefined, process.env.VUE_APP_PENSION_API_URL);
  }

  public async listDocuments(filters: DocumentListFilters) {
    const params = new URLSearchParams(
      filters as unknown as Record<string, string>
    ).toString();
    const response = await this.get<APIResponse<Document[]>>(
      `/v2/documents?${params}`
    );

    if (
      !response ||
      !response.status ||
      response.status === APIResponseStatus.Error
    ) {
      throw new Error(
        `Error fetching Pension documents: ${JSON.stringify(response.errors!)}`
      );
    }

    return response.data || [];
  }

  public async acceptDocument(payload: DocumentAcceptRequest) {
    const response = await this.post<APIResponse<Document>>(
      `/v2/documents/accept`,
      payload
    );

    if (
      !response ||
      !response.status ||
      response.status === APIResponseStatus.Error
    ) {
      throw new Error(
        `Error accepting Pension document: ${JSON.stringify(response.errors!)}`
      );
    }

    return response.data || null;
  }

  public async rejectDocument(payload: DocumentRejectRequest) {
    const response = await this.post<APIResponse<Document>>(
      `/v2/documents/reject`,
      payload
    );

    if (
      !response ||
      !response.status ||
      response.status === APIResponseStatus.Error
    ) {
      throw new Error(
        `Error rejecting Pension document: ${JSON.stringify(response.errors!)}`
      );
    }

    return response.data || null;
  }
}
