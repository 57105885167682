import moment from 'moment';
import { WootricActions } from './types';
import {
  getWootricData,
  WootricResponse
} from '@/modules/common/services/http/account';

export class WootricService {
  public init() {
    this.fetchWootricData();
  }

  private async fetchWootricData() {
    try {
      const wootricData = await getWootricData();

      if (!wootricData.enableNPSSurvey) return;

      this.setWootricSettings(wootricData);
      this.run();
    } catch (err) {
      console.error('[Wootric] Could not fetch data: ', err);
    }
  }

  private setWootricSettings(wootricData: WootricResponse) {
    window.wootricSettings = {
      email: wootricData.email,
      external_id: wootricData.apiId,
      created_at: parseInt(
        moment(wootricData.accountCreatedAt).format('X'),
        10
      ),
      account_token: process.env.VUE_APP_WOOTRIC || '',
      properties: {
        apiId: wootricData.apiId,
        name: wootricData.name,
        profession: wootricData.profession,
        age: wootricData.age,
        birthDate: wootricData.birthDate,
        ageRange: wootricData.ageRange,
        sex: wootricData.sex,
        state: wootricData.state,
        totalBalance: wootricData.totalBalance,
        totalBalanceRange: wootricData.totalBalanceRange,
        patrimony: wootricData.patrimony,
        patrimonyRange: wootricData.patrimonyRange,
        computedProfileType: wootricData.computedProfileType
      }
    };
  }

  private run() {
    if (window.wootricSettings && window.wootric) {
      window.wootric(WootricActions.Run);
    }
  }
}

export const wootricService = new WootricService();
