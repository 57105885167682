import { httpClient } from '@/modules/common/services/http/http-client';

import {
  IMarginAllocation,
  IMarginAllocationPreview
} from './margin-allocation-types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/trading`
});

export function getMarginAllocation(): Promise<IMarginAllocation> {
  return http.get(`/margin-allocation`);
}

export function getMarginAllocationPreview(
  accountId: string
): Promise<IMarginAllocationPreview> {
  return http.get(`/margin-allocation/preview/${accountId}`);
}

export function updateLimits(params: {
  marginCapital: number;
  dailyLoss: number;
  account: string;
}): Promise<IMarginAllocation> {
  return http.post(`/margin-allocation`, params);
}

export function updateTraderShield(accountId: string): Promise<{
  isTraderShieldEnabled: boolean;
}> {
  return http.put(`/margin-allocation/traderShield/${accountId}`);
}

export function updatePreferences(accountId: string): Promise<void> {
  return http.put(`/margin-allocation/savePreferences/${accountId}`);
}
