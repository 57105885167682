
import {
  Component,
  Vue,
  Prop,
  Emit,
  InjectReactive
} from 'vue-property-decorator';
import {
  TrackingCancelReserveModalActions,
  trackCancelReserveModal
} from '@/modules/trade/views/Subscription/tracker';
import {
  NavBar,
  BaseModal,
  ModalButton,
  BaseText
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    NavBar,
    BaseText,
    BaseModal,
    ModalButton
  }
})
export default class CancelSubscriptionModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @InjectReactive()
  readonly instrumentType!: string;

  @InjectReactive()
  readonly instrumentName!: string;

  @Emit('close')
  public emitClose() {
    const ticker = this.$route.query?.ticker as string;
    trackCancelReserveModal({
      instrument_type: this.instrumentType,
      instrument_name: this.instrumentName,
      instrument_symbol: ticker,
      subscrition_action: TrackingCancelReserveModalActions.keep_reservation
    });
  }

  @Emit('cancel-subscription')
  public emitCancelSubscription() {
    const ticker = this.$route.query?.ticker as string;
    trackCancelReserveModal({
      instrument_type: this.instrumentType,
      instrument_name: this.instrumentName,
      instrument_symbol: ticker,
      subscrition_action:
        TrackingCancelReserveModalActions.confirm_cancel_reservation
    });
  }
}
