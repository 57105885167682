import 'blob-polyfill';

interface ErrorMessage {
  value: string;
  formatted: string;
}

export interface ErrorResult {
  error: {
    code: number;
    message: string | ErrorMessage;
  };
}

export const convertBlobToJSON = async (blob: Blob): Promise<ErrorResult> => {
  const errorResponse = await blob.text();
  return JSON.parse(errorResponse);
};
