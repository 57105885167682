
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { OrderStatusFilter } from './components/OrderStatusFilter';
import { OrderTypeFilter } from './components/OrderTypeFilter';
import { InstrumentFilter } from '@/modules/trade/components/InstrumentFilter';
import { DateFilter } from './components/DateFilter';
import { SkeletonLoader } from '@warrenbrasil/nebraska-web';

import {
  InstrumentFilter as IInstrumentFilter,
  getCustomerOrdersInstruments
} from '@/modules/trade/services/orders';
import { OrderFiltersKeys, Filters } from '@/modules/trade/views/Orders/types';
import { InstrumentFilterStatus } from '@/modules/trade/components/InstrumentFilter/types';
import { logError } from '@/modules/trade/helpers/logs';
import * as trackOrdersEvents from '@/modules/trade/views/Orders/trackers';

@Component({
  components: {
    DateFilter,
    OrderTypeFilter,
    OrderStatusFilter,
    SkeletonLoader,
    InstrumentFilter
  }
})
export default class OrdersFilters extends Vue {
  @Prop({ type: Object, required: true })
  readonly filters!: Filters;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: Boolean, required: true })
  readonly hasFilterSelected!: boolean;

  instrumentsFilterStatus = InstrumentFilterStatus.Loading;
  instruments: IInstrumentFilter[] | null = null;
  resultLabel = 'Meus ativos';
  abortSignal: AbortController | null = null;

  readonly OrderFiltersKeys = OrderFiltersKeys;

  @Emit('on-filter-selection')
  emitOnFilterSelection(_filterKey: OrderFiltersKeys, _value: string) {}

  @Emit('on-clear-filters')
  emitOnClearFilters() {}

  get cleanFiltersButtonLabel() {
    const filtersKeys = Object.values(OrderFiltersKeys);

    const filtersWithSelectedValue = filtersKeys.filter(
      filterKey => this.filters[filterKey].value !== null
    );

    return filtersWithSelectedValue.length > 1
      ? 'Limpar filtros'
      : 'Limpar filtro';
  }

  get hasFilters() {
    const filtersKeys = Object.values(OrderFiltersKeys);

    return filtersKeys.some(filterKey => {
      return !!this.filters[filterKey].options?.length;
    });
  }

  handleInstrumentSelection(value: string) {
    trackOrdersEvents.trackInstrumentSelectionApplyClick();
    this.emitOnFilterSelection(OrderFiltersKeys.Instrument, value);
  }

  handleCloseSelectionModal() {
    trackOrdersEvents.trackInstrumentSelectionClose();
  }

  handleCancelSelectionModal() {
    trackOrdersEvents.trackInstrumentSelectionCancelClick();
  }

  async handleOnRetry() {
    trackOrdersEvents.trackInstrumentSelectionErrorRetryClick();
    await this.handleOnSearch();
  }

  async handleInstrumentSelectionModalOpen() {
    trackOrdersEvents.trackInstrumentSelectionModalLoad();
    await this.handleOnSearch();
  }

  async handleOnSearch(text = '', page = 1) {
    if (this.abortSignal) {
      this.abortSignal.abort();
    }

    try {
      const isFirstPage = page === 1;
      if (isFirstPage)
        this.instrumentsFilterStatus = InstrumentFilterStatus.Loading;

      this.abortSignal = new AbortController();

      const { items } = await getCustomerOrdersInstruments(
        {
          query: text,
          page,
          itemsPerPage: 10
        },
        this.abortSignal.signal
      );

      this.instruments = isFirstPage
        ? items
        : [...(this.instruments || []), ...items];

      this.resultLabel = text ? `Resultados para "${text}"` : 'Meus ativos';

      this.instrumentsFilterStatus = InstrumentFilterStatus.Default;
    } catch (error) {
      this.instrumentsFilterStatus = InstrumentFilterStatus.Error;
      trackOrdersEvents.trackInstrumentSelectionErrorLoad();
      logError(error, 'request_customer_orders_instruments_error');
    }
  }
}
