
import { Component, Emit, Vue } from 'vue-property-decorator';
import {
  Modal,
  ButtonContainer,
  ButtonPrimary,
  ButtonSecondary,
  HeadingDisplay,
  SingleInput
} from '@warrenbrasil/nebraska-web';
import {
  goToPreviousRoute,
  goToNextRoute
} from '@/modules/common/store/RegulatoryUpdateRoutesOrchestrator';
import { verifyPassword } from '../../services';
import { InputStatusType } from '../../types';
import {
  trackCancel,
  trackContinue,
  trackError,
  trackView
} from './track-events';

@Component({
  components: {
    Modal,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary,
    HeadingDisplay,
    SingleInput
  }
})
export default class RegulatoryUpdateVerifyPassword extends Vue {
  @Emit()
  private setIsLoading(_isLoading: boolean) {}

  public password = '';

  private isPasswordInvalid = false;

  mounted() {
    this.setIsLoading(false);
    trackView();
  }

  public close() {
    trackCancel();
    goToPreviousRoute();
  }

  public get helperText() {
    return this.isPasswordInvalid ? 'Senha inválida' : '';
  }

  public get inputStatus() {
    return this.isPasswordInvalid
      ? InputStatusType.Error
      : InputStatusType.Default;
  }

  public inputBlur() {
    this.isPasswordInvalid = false;
  }

  private get cameFromRoute(): string {
    return this.$route.query.cameFrom as string;
  }

  public async sendVerifyPassword() {
    try {
      await verifyPassword(this.password);

      trackContinue();

      if (this.cameFromRoute) {
        this.$router.push({ name: this.cameFromRoute });
        return;
      }

      goToNextRoute();
    } catch {
      this.isPasswordInvalid = true;
      trackError('Senha inválida');
    }
  }
}
