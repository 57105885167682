import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'access_without_phone',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackDisconnectDevicesLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackDisconnectAllClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'disconnect_all',
    page_screen_section: 'body',
    event_target: 'Desconectar de tudo'
  });
}

export function trackTalkToWarrenClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'contact_warren',
    page_screen_section: 'body',
    event_target: 'Falar com a Warren'
  });
}

export function trackGoBackClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'back',
    page_screen_section: 'head'
  });
}
