
import { Component, Vue } from 'vue-property-decorator';

import { ContentDisplayLoader } from '@/modules/trade/components/ContentDisplayLoader';
import { HeadingDisplayLoader } from '@/modules/trade/components/HeadingDisplayLoader';
import { ListDisplayLoader } from '@/modules/trade/components/ListDisplayLoader';

@Component({
  components: {
    ContentDisplayLoader,
    HeadingDisplayLoader,
    ListDisplayLoader
  }
})
export default class OrderLoading extends Vue {}
