
import { Component, Vue } from 'vue-property-decorator';

import {
  NavBar,
  BaseIcon,
  IconButton,
  HelperViewStatus
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    NavBar,
    BaseIcon,
    IconButton,
    HelperViewStatus
  }
})
export default class NotFoundView extends Vue {
  readonly NebraskaColors = NebraskaColors;

  onClickBack() {
    this.$router.back();
  }

  redirectToHome() {
    this.$router.push('home');
  }
}
