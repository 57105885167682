import { render, staticRenderFns } from "./SubscriptionDetails.vue?vue&type=template&id=058bfa58&scoped=true&"
import script from "./SubscriptionDetails.vue?vue&type=script&lang=ts&"
export * from "./SubscriptionDetails.vue?vue&type=script&lang=ts&"
import style0 from "./SubscriptionDetails.vue?vue&type=style&index=0&id=058bfa58&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058bfa58",
  null
  
)

export default component.exports