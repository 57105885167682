import { GtmTracker } from '@/modules/common/services/analytics';
import { Categories } from '@/types/models/Analytics';

const { trackerWithDefaultProps } = GtmTracker;

const trackEvent = GtmTracker.trackerWithDefaultProps({
  category: Categories.PEX
});

const trackAmplitudeEvent = trackerWithDefaultProps({
  page_screen: 'portfolio_investment_flow_success',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackLoadSuccess(messageSuccess: string, isScheduled: boolean) {
  if (isScheduled) {
    trackAmplitudeEvent({
      page_screen: 'portfolio_investment_scheduling_success',
      event_type: 'load',
      event_name: 'view'
    });
    return;
  }
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view',
    event_target: messageSuccess
  });
}

export function trackCloseButton(isScheduled: boolean) {
  if (isScheduled) {
    trackAmplitudeEvent({
      page_screen: 'portfolio_investment_flow_success',
      event_type: 'click',
      event_name: 'go_to_my_portfolio_page_screen'
    });
    return;
  }
  trackEvent({
    label: 'Investir por produto - Sucesso',
    action: 'Clique fechar'
  });
}

export function trackInvestOtherWallet(isScheduled: boolean) {
  if (isScheduled) {
    trackAmplitudeEvent({
      page_screen: 'portfolio_investment_flow_success',
      event_type: 'click',
      event_name: 'start_another_investment_flow'
    });
    return;
  }
  trackEvent({
    action: 'Clique investir em outra carteira',
    label: 'Investir por produto - Sucesso'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'start_another_investment_flow'
  });
}

export function trackBackToWealth(isScheduled: boolean) {
  if (isScheduled) {
    trackAmplitudeEvent({
      page_screen: 'portfolio_investment_flow_success',
      event_type: 'click',
      event_name: 'go_to_my_portfolio_page_screen'
    });
    return;
  }
  trackEvent({
    action: 'Clique voltar para carteira',
    label: 'Investir por produto - Sucesso'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'go_to_my_portfolio_page_screen'
  });
}
