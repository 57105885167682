import { httpClient } from '@/modules/common/services/http/http-client';
import {
  PortabilityDetails,
  PortabilityRequestsResponse,
  PortabilityRequestConclusionResponse,
  PortabilityRequestStatus
} from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getPortabilityDetails(
  portabilityId: string
): Promise<PortabilityDetails> {
  return httpClientWithDefaultOptions.get('/getPortabilityDetails', {
    params: {
      portabilityId
    }
  });
}

export function getPortabilityRequests(
  itemsPerPage: number,
  page: number
): Promise<PortabilityRequestsResponse> {
  return httpClientWithDefaultOptions.get('/getPortabilityRequests', {
    params: {
      itemsPerPage,
      page
    }
  });
}

export function updatePortabilityRequest(
  status: PortabilityRequestStatus,
  portabilityId: string
): Promise<PortabilityRequestConclusionResponse> {
  return httpClientWithDefaultOptions.post('/portabilityRequest', undefined, {
    params: {
      status,
      id: portabilityId
    }
  });
}
