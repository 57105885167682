import { GtmTracker } from '@/modules/common/services/analytics';
import { Categories } from '@/types/models/Analytics';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  category: Categories.Home
});

/**
 * @deprecated utilizar novos trackings via Amplitude
 */
export const trackDepositByAction = (action: string) =>
  trackEvent({
    action: `Clique em: "${action}"`,
    label: 'Deposit'
  });

/**
 * @deprecated utilizar novos trackings via Amplitude
 */
export const trackHomeByAction = (action: string) =>
  trackEvent({
    action: `Clique em: "${action}"`,
    label: 'Home'
  });

/**
 * @deprecated utilizar novos trackings via Amplitude
 */
export const trackHero = () => {
  trackEvent({
    action: 'Clique em: "Datalhes - Patrimônio e Rendimento"',
    label: 'Hero'
  });
};

/**
 * @deprecated utilizar novos trackings via Amplitude
 */
export const trackHeroInvestButton = () =>
  trackEvent({
    action: 'Clique no botão Investir',
    label: 'Account'
  });

/**
 * @deprecated utilizar novos trackings via Amplitude
 */
export const trackNewsByAction = (label: string) =>
  trackEvent({
    action: label,
    label: 'News'
  });

/**
 * @deprecated utilizar novos trackings via Amplitude
 */
export const trackAccountByAction = (action: string) =>
  trackEvent({
    action: `Clique no botão ${action}`,
    label: 'Account'
  });
