import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

export interface UIModuleType {
  getIsOpenLeftSideBar: boolean;
  getIsOpenRightSideBar: boolean;
  getNavBarRef: Vue | null;
  getIsTransparentNavBar: boolean;
  getIsOpenWelcomeCurtain: boolean;
  setIsOpenWelcomeCurtain: (isOpened: boolean) => void;
  setIsTransparentNavBar: (isTransparent: boolean) => void;
  setNavBarRef: (navBarRef: Vue) => void;
  openLeftSideBar: () => void;
  openRightSideBar: () => void;
  closeLeftSideBar: () => void;
  closeRightSideBar: () => void;
}

@Module({
  namespaced: true,
  name: 'UIModule'
})
export class UIStoreModule extends VuexModule implements UIModuleType {
  private isOpenLeftSideBar = false;
  private isOpenRightSideBar = false;
  private isOpenWelcomeCurtain = false;
  private isTransparentNavBar = false;
  private navBarRef: Vue | null = null;

  public get getIsOpenLeftSideBar() {
    return this.isOpenLeftSideBar;
  }

  public get getIsOpenRightSideBar() {
    return this.isOpenRightSideBar;
  }

  public get getNavBarRef() {
    return this.navBarRef;
  }

  public get getIsTransparentNavBar() {
    return this.isTransparentNavBar;
  }

  public get getIsOpenWelcomeCurtain() {
    return this.isOpenWelcomeCurtain;
  }

  @Mutation
  public setIsOpenWelcomeCurtain(isOpen: boolean) {
    this.isOpenWelcomeCurtain = isOpen;
  }

  @Mutation
  public setIsTransparentNavBar(isTransparent: boolean) {
    this.isTransparentNavBar = isTransparent;
  }

  @Mutation
  public setNavBarRef(navBarRef: Vue) {
    this.navBarRef = navBarRef;
  }

  @Mutation
  public openLeftSideBar() {
    this.isOpenLeftSideBar = true;
  }

  @Mutation
  public openRightSideBar() {
    this.isOpenRightSideBar = true;
  }

  @Mutation
  public closeLeftSideBar() {
    this.isOpenLeftSideBar = false;
  }

  @Mutation
  public closeRightSideBar() {
    this.isOpenRightSideBar = false;
  }
}
