
import { Component, Prop, Vue } from 'vue-property-decorator';
import { moneyFormat } from '@/modules/common/helpers/currency';
import {
  Table,
  TableCell,
  TableHeaderCell,
  Heading
} from '@warrenbrasil/nebraska-web';

type Aliquot = {
  value: number;
  grossValue: number;
  netValue: number;
  quotes: number;
};

@Component({
  components: {
    TableCell,
    TableHeaderCell,
    Table,
    Heading
  }
})
export default class PensionContractAliquotsTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly aliquots!: Aliquot[];

  @Prop({ type: Number, default: null })
  readonly totalQuotes!: number | null;

  @Prop({ type: Number, default: null })
  readonly grossWorth!: number | null;

  get sortedAliquots() {
    const compare = (a: Aliquot, b: Aliquot) => {
      if (a.value > b.value) {
        return -1;
      }
      return a.value < b.value ? 1 : 0;
    };
    return this.aliquots.sort(compare);
  }

  format(value: number) {
    return moneyFormat(value);
  }
}
