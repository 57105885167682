
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Heading } from '@warrenbrasil/nebraska-web';
import { DownloadButton } from '../DownloadButton';

@Component({
  components: {
    Heading,
    DownloadButton
  }
})
export default class DownloadDocument extends Vue {
  @Prop({ type: Array, required: true })
  public list!: string;

  @Prop({ type: String, required: false })
  public title!: string;

  trackDownloadButton(computedTitle: string) {
    this.$emit('track-download-button', computedTitle);
  }
}
