
import { Component, Vue } from 'vue-property-decorator';
import { NavBar, IconButton } from '@warrenbrasil/nebraska-web';
import { useDashStore } from '@/modules/common/store/DashModule';
import { FadeTransition } from '@/modules/common/components/FadeTransition';
import { StatementList } from './components/StatementList';
import { Balances } from './components/Balances';
import { ShowMoneyToggleButton } from '@/modules/common/components/buttons/ShowMoneyToggleButton';
import * as trackEvents from './track-events';

@Component({
  components: {
    NavBar,
    IconButton,
    FadeTransition,
    StatementList,
    Balances,
    ShowMoneyToggleButton
  }
})
export default class TransactionsView extends Vue {
  private readonly dashModule = useDashStore();

  created() {
    trackEvents.trackTransactionsLoad();
  }

  handleClick() {
    this.goBack();
  }

  toggleClick() {
    trackEvents.trackHideEquityClick();
  }

  goBack() {
    this.$router.back();
  }
}
