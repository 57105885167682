import { render, staticRenderFns } from "./PerformanceChart.vue?vue&type=template&id=13f1a5ce&scoped=true&"
import script from "./PerformanceChart.vue?vue&type=script&setup=true&lang=ts&"
export * from "./PerformanceChart.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./PerformanceChart.vue?vue&type=style&index=0&id=13f1a5ce&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13f1a5ce",
  null
  
)

export default component.exports