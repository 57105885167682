import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IInvestmentDetails,
  IInvestmentAmount,
  IRiskSignature,
  IInvestmentReview,
  ITermSignature,
  IInvestmentConclusion
} from './product-investment-types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/instruments`
});

export const httpBffInvestments = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getInvestmentDetails(
  productId: string,
  instrumentType: string
): Promise<IInvestmentDetails> {
  return http.get(`/v1/investments/instrument/${productId}/info`, {
    params: { instrumentType }
  });
}

export function getInvestmentAmount(
  productId: string,
  amount: number,
  instrumentType: string
): Promise<IInvestmentAmount> {
  return http.post(`/v1/investments/instrument/amount`, {
    amount,
    productId,
    instrumentType
  });
}

export function getInvestmentReview(
  productId: string,
  amount: number,
  instrumentType: string
): Promise<IInvestmentReview> {
  return http.get(`/v1/investments/instrument/${productId}/review`, {
    params: { amount, instrumentType }
  });
}

export function makeInvestment(
  productId: string,
  amount: number
): Promise<IInvestmentConclusion> {
  return http.post(`/v1/investments/instrument`, {
    productId,
    amount
  });
}

export function getPendingRiskSignature(
  productId: string,
  instrumentType: string
): Promise<IRiskSignature> {
  return http.get(`/v1/risks/pending-signatures`, {
    params: { productIds: [productId], instrumentType }
  });
}

export function getPendingTermSignature(
  productId: string,
  instrumentType: string
): Promise<ITermSignature> {
  return http.get(`/v1/terms/pending-signatures`, {
    params: { productIds: [productId], instrumentType }
  });
}

export function riskSignature(instrumentId: string): Promise<void> {
  return httpBffInvestments.post('/v1/riskSignature', {
    instrumentIds: [instrumentId]
  });
}

export function termsSignature(instrumentId: string): Promise<void> {
  return httpBffInvestments.post('/v1/termSignature', {
    instrumentIds: [instrumentId]
  });
}
