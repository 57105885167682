
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { BaseText, Heading, Row } from '@warrenbrasil/nebraska-web';

import { IInstrumentAbout } from './types';

@Component({
  components: {
    BaseText,
    Heading,
    Row
  }
})
export default class InstrumentAbout extends Vue {
  @Prop({ type: Object, required: true })
  public about!: IInstrumentAbout;

  public NebraskaColors = NebraskaColors;

  public get textColor() {
    return NebraskaColors.textSecondary;
  }
}
