
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import {
  Modal,
  HeadingDisplay,
  ContentDisplay,
  ListDisplay,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { Portal } from '@/modules/common/components/Portal';

import {
  IVoucherResponse,
  IVoucherRequest,
  IStatementTransaction
} from '@/modules/transaction/services/unified-account';

@Component({
  components: {
    Modal,
    HeadingDisplay,
    ContentDisplay,
    ListDisplay,
    ButtonSecondary,
    Portal
  }
})
export default class ReceiptModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: Object, required: false, default: () => ({}) })
  readonly transaction!: IStatementTransaction;

  @Prop({ type: Object, required: false })
  readonly filters?: IVoucherRequest;

  @Prop({ type: Boolean, default: null })
  readonly isUpcomingTransfer?: boolean;

  @Emit('close')
  close() {}

  modalData: Reactive<IVoucherResponse> = null;
  isLoading = false;
  hasError = false;

  goToPage() {
    window.open(this.modalData?.link);
  }
}
