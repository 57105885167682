export enum EObjectiveSteps {
  NAME,
  PERIOD,
  PROFILE,
  RISK,
  PREVIEW,
  TEMPLATE,
  CONCLUSION
}

export interface IFundsData {
  objectiveName: string;
  period: { formatted: string; value: string };
  profile?: { value: string; formatted: string };
  risk: { id: string; value: number; formatted: string };
  type: string;
}
