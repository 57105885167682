import { NumberFormatted, StringFormatted } from './bff';

export interface TradePosition {
  symbol: string;
  quantity: number;
  description: string;
  type: StringFormatted;
  avgPrice: NumberFormatted;
  currentValue: NumberFormatted;
  price?: NumberFormatted;
  direction: StringFormatted;
  profitPrice: NumberFormatted;
  profitPercentage: NumberFormatted;
  btc: string | null;
  updatedAt: StringFormatted;
  totalEarnings: NumberFormatted;
  dailyPercentualChange: NumberFormatted;
  date?: string;
  lotSize?: number;
}

export interface TradeInstrumentPositionPrice {
  symbol: string;
  quantity: number;
  description: string;
  type: StringFormatted;
  avgPrice: NumberFormatted;
  currentValue: NumberFormatted;
  price?: NumberFormatted;
  profitPrice: NumberFormatted;
  profitPercentage: NumberFormatted;
  btc: string | null;
  updatedAt: StringFormatted;
  totalEarnings: NumberFormatted;
  dailyPercentualChange: NumberFormatted;
  date?: string;
}

export enum RiskGrade {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export enum InvestorType {
  Common = 'COMMON',
  Qualified = 'QUALIFIED',
  Professional = 'PROFESSIONAL',
  None = 'NONE'
}

export enum InstrumentClass {
  Fixedincome = 'FIXEDINCOME',
  Variableincome = 'VARIABLEINCOME',
  Others = 'OTHERS'
}

export enum ETradeDirection {
  Buy = 'Buy',
  Sell = 'Sell'
}

export enum ETradeOrderStatus {
  Placing = 'Placing',
  Inactive = 'Inactive',
  Working = 'Working',
  Rejected = 'Rejected',
  Filled = 'Filled',
  PartiallyFilled = 'Partially_filled',
  Cancelled = 'Cancelled',
  Expired = 'Expired'
}

export interface TradeOrderStatusMessage {
  title: string;
  label: string;
  description: string;
}
export interface IntFormatableField {
  value?: number | null;
  formatted?: string | null;
}

export interface InstrumentDetail {
  boxName?: string | null;
  emitterName?: string | null;
  strategy?: string | null;
  administrationTax?: number | null;
  subType?: number | null;
  video?: InstrumentDetailContent[] | null;
  documents?: InstrumentDetailContent[] | null;
  apiId?: string | null;
  boxApiId?: string | null;
  lastTwelveMonthsInterestRate?: number | null;
  isIndexBased?: boolean | null;
  isIndexName?: string | null;
  minimumFinancialMovement?: number | null;
  minimumInitialInvestment?: number | null;
  riskRate?: number | null;
  isAvailable?: boolean | null;
  minPercentage?: number | null;
  availabilityReason?: string | null;
  expiresIn?: Date | null;
  liquidityInfo?: string | null;
  daysToLiquidation?: number | null;
  daysToExpiration?: number | null;
  daysToGracePeriod?: number | null;
  symbol?: string | null;
  description?: string | null;
  name?: string | null;
  riskScore?: number | null;
  investorType?: InvestorType | null;
  fixedPercentualRate?: number | null;
  yearlyEstimatedPercentualRate?: number | null;
  riskGrade?: RiskGrade | null;
  type?: InstrumentClass | null;
  liquidity?: IntFormatableField | null;
  profitability?: string | null;
  category?: string | null;
  subCategory?: string | null;
}

export interface InstrumentDetailContent {
  title?: string | null;
  description?: string | null;
  url?: string | null;
}
