import { EMFALoginSteps } from './types';
import { MultiFactorLogin } from './components/MultiFactorLogin';
import { DisconnectDevices } from './components/DisconnectDevices';
import { DisconnectDevicesFeedback } from './components/DisconnectDevicesFeedback';
import { TalkToWarren } from './components/TalkToWarren';

export const MFALoginComponents = {
  [EMFALoginSteps.MULTI_FACTOR_LOGIN]: MultiFactorLogin,
  [EMFALoginSteps.DISCONNECT_DEVICES]: DisconnectDevices,
  [EMFALoginSteps.DISCONNECT_DEVICES_FEEDBACK]: DisconnectDevicesFeedback,
  [EMFALoginSteps.TALK_TO_WARREN]: TalkToWarren
};
