export enum Categories {
  Account = 'account',
  Cash = 'Conta Digital',
  ClientDetails = 'B2B_client_details',
  Home = 'Home',
  Indication = 'Indication',
  LeadDetails = 'B2B_lead_details',
  MenuShortcut = 'B2B_menu_shortcut',
  Overview = 'Visao Geral',
  PartnerDash = 'B2B_partner_dash',
  PortfolioTemplate = 'B2B_carteiras_modelo',
  Suitability = 'B2B_suitability',
  Trade = 'Trade',
  Wallet = 'B2B_carteira',
  WalletBuilder = 'PEX - Carteira Editada',
  Wealth = 'Wealth',
  WealthWalletCreated = 'Wealth - Carteira Avançada',
  PEX = 'PEX'
}
