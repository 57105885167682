export enum RLPStatusColors {
  positive = 'positive',
  neutral = 'neutral'
}

export interface RLPPageSummary {
  header: {
    head: string;
    title: string;
    subtitle: string;
    status: {
      icon: string;
      color: RLPStatusColors;
      label: string;
    };
  };
  howItWorks: {
    title: string;
    description: string[];
  };
  benefits: {
    title: string;
    description: string;
    icon: string;
  }[];
  cancellationSection: {
    title: string;
    descritption: string;
    buttonLabel: string;
  };
  activationSection: {
    buttonLabel: string;
  };
}

export interface RLPPageFormCancellationOption {
  value: string;
  label: string;
  shouldShowTextField: boolean;
}

export interface RLPPageForm {
  cancellation: {
    head: string;
    title: string;
    subtitle: string;
    options: RLPPageFormCancellationOption[];
  } | null;
}

export enum RLPStatus {
  PendingActivation = 'PendingActivation',
  Active = 'Active',
  PendingInactivation = 'PendingInactivation',
  Inactive = 'Inactive'
}

export interface RLPPageData {
  status: RLPStatus;
  summary: RLPPageSummary;
  form: RLPPageForm;
}

export enum UpdateRLPStatusValues {
  enable = 'enable',
  disable = 'disable'
}

export interface UpdateRLPStatusResponse {
  status: RLPStatus;
  message: string;
}
