import { httpClient } from '@/modules/common/services/http/http-client';
import { TransferWithdrawalReceipt } from './transfer-bff-types';

const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getInternalTransfer(
  transactionId: string
): Promise<TransferWithdrawalReceipt> {
  return http.get(`/v1/getTransferWithdrawalReceipt/${transactionId}`);
}
