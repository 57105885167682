
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BaseText, Heading } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    BaseText,
    Heading
  }
})
export default class ModalContent extends Vue {
  @Prop({ type: String, required: true })
  readonly imageSrc!: string;

  @Prop({ type: String, required: true })
  readonly imageAlt!: string;

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly description!: string;

  readonly NebraskaColors = NebraskaColors;
}
