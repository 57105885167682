import { render, staticRenderFns } from "./SuccessCurtain.vue?vue&type=template&id=483c5735&scoped=true&"
import script from "./SuccessCurtain.vue?vue&type=script&lang=ts&"
export * from "./SuccessCurtain.vue?vue&type=script&lang=ts&"
import style0 from "./SuccessCurtain.vue?vue&type=style&index=0&id=483c5735&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483c5735",
  null
  
)

export default component.exports