
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { BaseText, BaseIcon } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: { BaseIcon, BaseText }
})
export default class Tooltip extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: String })
  public text!: string;

  @Prop({ type: String, default: NebraskaColors.progressBackground })
  public color!: string;

  @Prop({ type: String, default: 'ic_help_circle', required: false })
  public icon?: string;

  @Prop({ type: String, default: '14', required: false })
  public iconWidth?: string;

  @Prop({ type: String, default: '14', required: false })
  public iconHeight?: string;

  @Prop({ type: String, default: 'top' })
  public side?: string;

  private hovered = false;

  @Watch('hovered')
  @Emit()
  private change() {
    return this.hovered;
  }
}
