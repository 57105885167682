
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IBankAccount } from '@/types/models';

import { CheckboxRadio } from '@/modules/account/views/UpdateUserAccount/components/CheckboxRadio';
import { CPFInput } from './components/CPFInput';
import { InputLabelField } from '@/modules/account/components/InputLabelField';
import {
  InputSelect,
  Link,
  BaseText,
  ButtonSecondary,
  Heading
} from '@warrenbrasil/nebraska-web';
import { Tooltip } from '@/modules/common/components/Tooltip';
import { BankData } from './components/BankData';

import { getString, getEnum } from '@/modules/common/helpers/resources';

type BankAccount = Partial<IBankAccount>;

function getBankTemplate(): BankAccount {
  return {
    bank: undefined,
    agency: '',
    number: '',
    digit: '',
    type: 'current',
    hasSecondHolder: false,
    firstHolderCPF: '',
    firstHolderName: '',
    _id: ''
  };
}

const otherBank = {
  id: 'selectBank',
  icon: require('@/assets/imgs/icons/ic_3dots_black.svg')
};

@Component({
  components: {
    CheckboxRadio,
    CPFInput,
    InputLabelField,
    InputSelect,
    Tooltip,
    BankData,
    Link,
    BaseText,
    ButtonSecondary,
    Heading
  }
})
export default class BankAccountForm extends Vue {
  @Prop({ default: () => [], required: false })
  private bankAccounts!: BankAccount[];

  private getString = getString;

  private bankCodes: { [key: string]: string } = getEnum('Bank');
  private quickSelectableBanks = [
    { id: '001', icon: require('@/assets/imgs/banks/bancodobrasil.svg') },
    { id: '260', icon: require('@/assets/imgs/banks/nubank.svg') },
    { id: '237', icon: require('@/assets/imgs/banks/bradesco.svg') },
    { id: '104', icon: require('@/assets/imgs/banks/caixa.svg') },
    { id: '341', icon: require('@/assets/imgs/banks/itau.svg') },
    { id: '077', icon: require('@/assets/imgs/banks/inter.svg') },
    { id: '033', icon: require('@/assets/imgs/banks/santander.svg') },
    otherBank
  ];

  private removedBanks: string[] = [];

  @Emit('validate-field')
  validateValues() {
    const isValuesValid = this.valuesValidation();
    return { field: 'bankData', isValid: isValuesValid };
  }

  private valuesValidation() {
    const verificated: boolean[] = [];
    this.bankAccounts.forEach(data => {
      verificated.push(data.agency !== '0000');
      verificated.push(data.number !== '');
      verificated.push(data.digit !== '');
    });

    return verificated.every(bool => bool === true);
  }

  private mounted() {
    if (!this.bankAccounts.length) this.addNewBank();
  }

  private isSelectedBank(id: string, selectedId?: string) {
    if (id !== otherBank.id) return selectedId === id;
    return !!selectedId;
  }

  private onlyCurrentType(bank: BankAccount) {
    const nubankCode = '260';
    const interCode = '077';
    const onlyHasCurrent = bank.bank === nubankCode || bank.bank === interCode;
    if (onlyHasCurrent) bank.type = 'current';
    return onlyHasCurrent;
  }

  private addNewBank() {
    this.bankAccounts.push(getBankTemplate());
  }

  private get bankOptions() {
    return Object.keys(this.bankCodes)
      .sort()
      .map(bankCode => ({
        value: bankCode,
        label: `${bankCode} - ${this.bankCodes[bankCode]}`
      }));
  }

  private removeBank(bank: BankAccount, index: number) {
    const id = bank._id;
    if (id) {
      this.removedBanks.push(id);
      this.$parent?.$emit('removeBanks', this.removedBanks);
    }
    this.bankAccounts.splice(index, 1);
  }

  private handleBankData({ agency, number, digit }: any, index: number) {
    this.bankAccounts[index].agency = agency;
    this.bankAccounts[index].number = number;
    this.bankAccounts[index].digit = digit;
    this.validateValues();
  }
}
