import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { FeatureFlagService } from '@/modules/common/services/http/feature-flag';

function getFeaturesFromLocalStorage(): FeatureFlags[] {
  const features = localStorage.getItem(StorageKeys.Features);
  return features ? JSON.parse(features) : [];
}

@Module({
  namespaced: true,
  name: 'FeatureFlagsModule'
})
export class FeatureFlagsStoreModule extends VuexModule {
  private features: FeatureFlags[] = getFeaturesFromLocalStorage();

  public get featureFlags(): FeatureFlags[] {
    return this.features;
  }

  public get isEnabled() {
    return (feature: FeatureFlags) => this.features.includes(feature);
  }

  @Mutation
  public setFeatureFlags(value: FeatureFlags[]) {
    localStorage.setItem(StorageKeys.Features, JSON.stringify(value));
    this.features = value;
  }

  @Action({ rawError: true })
  public async fetchFeatureFlags() {
    const features = await FeatureFlagService.getFeatures<FeatureFlags>();
    this.context.commit('setFeatureFlags', features);
  }
}
