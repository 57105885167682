
import { Component, Vue, Prop } from 'vue-property-decorator';
import { BaseIcon, BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    BaseIcon,
    BaseText
  }
})
export default class Dropdown extends Vue {
  @Prop({ type: String, required: true })
  private title!: string;

  private readonly NebraskaColors = NebraskaColors;
}
