import { GtmTracker } from '@/modules/common/services/analytics';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'home_overview',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackGoToRegulatoryUpdate() {
  trackEvent({
    event_type: 'click',
    event_name: 'go_to_regulatory_update'
  });
}
