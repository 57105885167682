
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InitialAvatarIcon } from '@warrenbrasil/nebraska-web';

@Component({
  components: { InitialAvatarIcon }
})
export default class OrdersAvatarCell extends Vue {
  @Prop({ type: String, required: true })
  public iconName!: string;
}
