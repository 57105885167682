
import { Component, Vue } from 'vue-property-decorator';
import { SkeletonLoader, Heading } from '@warrenbrasil/nebraska-web';
import { NavBar } from '@/modules/common/components/__deprecated__/NavBar';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import {
  getArticle,
  IHelpCenterArticle
} from '@/modules/help-center/services/help-center';
import { isImpersonate } from '@/modules/common/services/partner';

@Component({
  components: {
    SkeletonLoader,
    BackButton,
    Heading,
    NavBar
  }
})
export default class HelpCenterView extends Vue {
  isLoading = true;
  isImpersonate = false;
  article: IHelpCenterArticle | null = null;

  private async mounted() {
    this.isImpersonate = isImpersonate();
    const { article } = await getArticle(this.$route.params.articleId);
    this.article = article;
    this.isLoading = false;
  }
}
