
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Typeform } from '@/modules/common/components/Typeform';
import { ICustomer } from '@/types/models';
import { namespace } from 'vuex-class';
import { getBrand } from '@/modules/common/services/local-storage';
import { WealthProductsRedeemCSATType } from '.';
import {
  getCustomerExperience,
  IExperience,
  IExperienceData,
  updateCustomerExperience
} from '@/modules/wealth/services/investment';
import { FeatureFlags } from '@/types/models/FeatureFlags';

const customerModule = namespace('CustomerModule');

@Component({
  components: { Typeform }
})
export default class WealthProductsRedeemCSAT extends Vue {
  public showCsat = false;
  public idCsat = '';
  private nameExperience = FeatureFlags.WALLET_WITHDRAW_BY_PRODUCT;

  @Prop({ type: Object, required: true })
  readonly redeemData!: WealthProductsRedeemCSATType;

  @customerModule.Getter('getCustomer')
  readonly customer?: ICustomer;

  private async created() {
    await this.getCustomerExperience();
  }

  private async getCustomerExperience() {
    try {
      const { getExperiences } = await getCustomerExperience(
        this.customer?._id!
      );
      const csatExperience = this.findExperience(getExperiences);

      if (csatExperience) {
        this.idCsat = csatExperience.experience.formId;
        this.showCsat = true;
      }
    } catch {
      this.showCsat = false;
    }
  }

  private findExperience(
    getExperiences: IExperienceData
  ): IExperience | undefined {
    return getExperiences.data.find(
      item => item.experience.name === this.nameExperience
    );
  }

  public get hiddenFields() {
    return {
      transaction_id: this.$route.query.transactionId,
      products_count: this.redeemData.products_count,
      withdraw_gross_value: this.redeemData.withdraw_gross_value,
      withdraw_net_value: this.redeemData.withdraw_net_value,
      customer_api_id: this.customer?._id ?? '',
      partner_id: this.customer?.partnerInfos?.customer ?? '',
      brand_id: getBrand()?._id ?? ''
    };
  }

  public async updateExperience() {
    try {
      await updateCustomerExperience({
        name: this.nameExperience,
        customerId: this.customer?._id || ''
      });
    } catch {
      this.showCsat = false;
    }
  }
}
