
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Heading,
  BaseText,
  Row,
  MessageFoundation
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { IResumeCardItem } from './resume-card-types';

@Component({
  components: {
    Heading,
    BaseText,
    Row,
    MessageFoundation
  }
})
export default class ResumeCard extends Vue {
  @Prop({ type: Array, required: true })
  public readonly items!: IResumeCardItem[];

  @Prop({ type: String, required: false })
  public readonly statusMessage?: string;

  public readonly NebraskaColors = NebraskaColors;
}
