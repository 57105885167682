
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BulletAsset extends Vue {
  @Prop({ type: String, default: NebraskaColors.textPrimary })
  private color!: string;

  public get computedStyle() {
    return { backgroundColor: this.color };
  }
}
