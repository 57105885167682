
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';

import { ButtonIconContextual } from '@warrenbrasil/nebraska-web';

@Component({
  components: { ButtonIconContextual }
})
export default class BackButton extends Vue {
  @Prop({ type: String, default: 'EA0030' })
  readonly icon!: string;

  @Emit('click')
  emitClick() {}

  get hasClickListener() {
    return this.$listeners.click;
  }

  handleClick() {
    if (this.hasClickListener) {
      this.emitClick();
    } else {
      this.$router.back();
    }
  }
}
