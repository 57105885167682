
import { Component, Vue, Prop } from 'vue-property-decorator';

import { SkeletonLoader, Pagination } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Pagination,
    SkeletonLoader
  }
})
export default class PaginationComponent extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: Number, required: true })
  readonly totalPages!: number;

  @Prop({ type: Number, required: true })
  readonly currentPage!: number;

  handlePageChange(page: number) {
    this.$emit('page-changed', page);
  }
}
