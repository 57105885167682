import { httpClient } from '@/modules/common/services/http/http-client';

import { IPortfolioTerms, IPortfolioSignatureParams } from './types';

export const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getPortfolioTerm(
  portfolioId: string,
  isVirtual: boolean
): Promise<IPortfolioTerms> {
  return http.get(`/v1/portfolios/${portfolioId}/riskTerm`, {
    params: { isVirtual }
  });
}

export function portfolioTermSignature(
  portfolioId: string,
  params: IPortfolioSignatureParams
): Promise<void> {
  return http.post(`/v1/portfolios/${portfolioId}/riskTerm/signature`, params);
}
