import { RouteConfig } from 'vue-router';
import PensionInstrumentDetailsView from '@/modules/pension/views/Instrument/PensionInstrumentDetailsView.vue';

export const pensionRoutes: RouteConfig[] = [
  {
    name: 'pensionInstrumentDetails',
    path: '/pension/instrument/:productApiId',
    component: PensionInstrumentDetailsView,
    meta: {
      context: 'pension'
    }
  }
];
