
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Instrument } from '@/modules/trade/services/search';
import {
  trackInstrumentClick,
  trackWatchlistButtonClick
} from '@/modules/trade/views/SearchListView/trackers';

import {
  HelperView,
  Select,
  RoundedIcon,
  IFilterOption
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { InstrumentList } from '@/modules/trade/components/InstrumentList';

@Component({
  components: {
    InstrumentList,
    HelperView,
    RoundedIcon,
    Select
  }
})
export default class InstrumentsListContainer extends Vue {
  private NebraskaColors = NebraskaColors;

  @Prop({ type: Function, required: true })
  private onFilterSelect!: (filter: IFilterOption) => {};

  @Prop({ type: Object, required: true })
  private selectedFilter!: IFilterOption;

  @Prop({ type: Array, required: true })
  private filters!: IFilterOption[];

  @Prop({ type: Array, required: true })
  private instruments!: Instrument[];

  @Prop({ type: String, required: true })
  private searchValue!: string;

  private onRowClick(instrument: Instrument) {
    trackInstrumentClick(instrument.symbol, instrument.description);

    this.$router.push({
      name: 'tradeInstrument',
      params: { ticker: instrument.symbol }
    });
  }

  handleWatchlistClick(instrument: Instrument) {
    trackWatchlistButtonClick(instrument.symbol, instrument.description);
  }
}
