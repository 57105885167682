import { ClientType } from '@/types/models/Customer';

export interface IFirstPortfolioAgeResponse {
  value: number;
}

export interface ISavingFeesResponse {
  savingFeesAmount: number;
}

export interface ICostsByYear {
  taxesByYear: ICostsByMonth[];
  clientType: ClientType;
  total: number;
}

export interface BrokerageFee {
  total: number;
}

export interface ICostsByMonth {
  month: number;
  administration: number;
  management?: number;
  brokerageFee?: BrokerageFee;
  cashback: number;
}

export interface ICostsByMonthResponse {
  month: number;
  administration: number;
  management?: number;
  brokerageFee?: BrokerageFee;
  cashback?: ICashbackDetailed[];
}

export interface ICashbackDetailed {
  instrumentDetailApiId: string;
  value: number;
  name: string;
}

export interface ICashbackResponse {
  cashbackAmount: number;
}

export enum FeeTypes {
  Administration = 'administration',
  Management = 'management'
}

export interface IFeePercentage {
  type: FeeTypes;
  value: number;
}
