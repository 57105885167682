
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Heading } from '@warrenbrasil/nebraska-web';
import InvestmentSectionLoader from './components/InvestmentSectionLoader.vue';

@Component({
  components: { Heading, InvestmentSectionLoader }
})
export default class InvestmentSection extends Vue {
  @Prop({ type: String, required: true })
  private title!: string;

  @Prop({ type: Boolean, required: true })
  private isLoading!: boolean;
}
