import { HttpClient } from '@warrenbrasil/web-http';
import { httpClient } from '@/modules/common/services/http/http-client';
import { PositionDetail, IStatementFilter, InvestmentSummary } from './types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}`
});

export function OverviewService(httpClient: HttpClient) {
  function getInvestmentSummary(): Promise<InvestmentSummary> {
    return httpClient.get<InvestmentSummary>(
      '/api/v3/finances/investment-summary/'
    );
  }

  function getFinancesReport(): Promise<BlobPart> {
    return httpClient.get<BlobPart>('/api/v2/finances/report', {
      responseType: 'blob'
    });
  }

  function getPosition(productApiId: string): Promise<PositionDetail> {
    return httpClient.get<PositionDetail>(
      `/api/v3/finances/positions/${productApiId}`
    );
  }

  function getAvailableDatesFromStatementDownload(
    currentMonth: boolean
  ): Promise<IStatementFilter[]> {
    return http.get(
      `/investments/v1/customers/reports/getAvailableDates?currentMonth=${currentMonth}`
    );
  }

  function getFixedIncomeStatementDownload(
    customerId: string,
    date: string
  ): Promise<BlobPart> {
    return http.get(
      `/investments/v1/customers/${customerId}/reports/fixed-income?date=${date}`,
      {
        responseType: 'blob'
      }
    );
  }

  return {
    getInvestmentSummary,
    getFinancesReport,
    getPosition,
    getAvailableDatesFromStatementDownload,
    getFixedIncomeStatementDownload
  };
}

export const OverviewAPI = OverviewService(httpClient);
