
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  ReviewStep,
  BaseText,
  ButtonIconContextual,
  ContentDisplay,
  HeadingDisplay,
  ListDisplay,
  ButtonContainer,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';
import { ScheduleModal } from './components/ScheduleModal';
import {
  ENewTransferSteps,
  ETransferType
} from '@/modules/transaction/views/NewTransfer/helpers/types';
import { currencyToNumber } from '@/modules/common/helpers/currency';
import {
  IBankAccount,
  createWithdrawal,
  scheduleWithdrawal
} from '@/modules/transaction/services/new-transfer';
import { Notification } from '@/modules/common/components/Notification';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { isBFFError } from '@/modules/common/helpers/bff';
import * as trackEvents from '@/modules/transaction/views/NewTransfer/track-events';

@Component({
  components: {
    Notification,
    ReviewStep,
    BaseText,
    ButtonIconContextual,
    ContentDisplay,
    HeadingDisplay,
    ListDisplay,
    ButtonContainer,
    ButtonPrimary,
    ScheduleModal
  }
})
export default class NewTransferReview extends Vue {
  @Prop({ type: String, required: true })
  readonly amountValue!: string;

  @Prop({ type: Object, required: true })
  readonly selectedBankAccount!: IBankAccount;

  @Emit('change-step')
  emitChangeStep(
    _step: ENewTransferSteps,
    _transferType?: ETransferType,
    _scheduleMessage?: string
  ) {
    // Muda para o step emitido
  }

  isLoading = false;
  isScheduleModalOpen = false;
  scheduleModalDescription = '';

  closeScheduleModal() {
    this.isScheduleModalOpen = false;
  }

  goBackStep() {
    this.emitChangeStep(ENewTransferSteps.BANK_SELECTION);
  }

  editAmountValue() {
    this.emitChangeStep(ENewTransferSteps.AMOUNT_SELECTION);
  }

  handleTransfer() {
    trackEvents.trackCreateNewTransfer();
    this.createWithdraw();
  }

  async createWithdraw() {
    this.isLoading = true;
    try {
      await createWithdrawal(
        this.selectedBankAccount.bankAccountId,
        currencyToNumber(this.amountValue)
      );

      this.emitChangeStep(ENewTransferSteps.CONCLUSION);
    } catch (e) {
      console.error(e);
      let errorMessage =
        'Não foi possível realizar sua transferência. Tente novamente.';
      if (isBFFError(e)) {
        if (e.error.message.value === 'OutsideOfTransferWindowError') {
          this.scheduleModalDescription = e.error.message.formatted;
          this.isScheduleModalOpen = true;
          trackEvents.trackScheduleTransferPopupLoad();
          return;
        }
        errorMessage = e.error.message.formatted;
      }
      trackEvents.trackTransferErrorLoad();
      this.notificationToast(errorMessage, NotificationTypes.error);
    } finally {
      this.isLoading = false;
    }
  }

  async scheduleWithdraw() {
    this.isLoading = true;
    try {
      const data = await scheduleWithdrawal(
        this.selectedBankAccount.bankAccountId,
        currencyToNumber(this.amountValue)
      );
      const scheduleMessage = data.scheduleWithdrawal.scheduleMessage;
      this.emitChangeStep(
        ENewTransferSteps.CONCLUSION,
        ETransferType.SCHEDULE,
        scheduleMessage
      );
    } catch (e) {
      console.error(e);
      this.isScheduleModalOpen = false;
      let errorMessage =
        'Não foi possível realizar sua transferência. Tente novamente.';
      if (isBFFError(e)) {
        errorMessage = e.error.message.formatted;
      }
      trackEvents.trackTransferErrorLoad();
      this.notificationToast(errorMessage, NotificationTypes.error);
    } finally {
      this.isLoading = false;
    }
  }

  private notificationToast(message: string, type: NotificationTypes) {
    this.$notify({
      group: 'wrn-notifications',
      text: message,
      type
    });
  }
}
