
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { ButtonIconContextual } from '@warrenbrasil/nebraska-web';

import { NotificationTypes } from '@/modules/common/services/http/notification';

import {
  addToWatchlist,
  deleteFromWatchlist
} from '@/modules/trade/services/instruments';

@Component({
  components: {
    ButtonIconContextual
  }
})
export default class WatchListButton extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly onWatchList!: boolean;

  @Prop({ type: String, required: true })
  readonly ticker!: string;

  @Emit('click')
  public emitOnClick() {
    return this.ticker;
  }

  private isAdded = false;
  private isUpdating = false;

  created() {
    this.isAdded = this.onWatchList;
  }

  get computedAriaLabel() {
    return this.isAdded
      ? `Remove ${this.ticker} dos favoritos`
      : `Adiciona ${this.ticker} aos favoritos`;
  }

  get buttonIcon() {
    return this.isAdded ? 'AB0032' : 'AB0030';
  }

  updateWatchList() {
    return this.isAdded
      ? deleteFromWatchlist(this.ticker)
      : addToWatchlist(this.ticker);
  }

  async toggleWatchList() {
    if (this.isUpdating) return;
    this.emitOnClick();

    try {
      this.isUpdating = true;
      await this.updateWatchList();

      const text = this.isAdded
        ? 'REMOVIDO DOS FAVORITOS'
        : 'ADICIONADO AOS FAVORITOS';
      const type = this.isAdded
        ? NotificationTypes.primary
        : NotificationTypes.success;
      this.isAdded = !this.isAdded;

      this.$notify({ group: 'wrn-trade', text, type });
    } catch {
      this.$notify({
        group: 'wrn-trade',
        text: 'Não foi possível realizar essa operação',
        type: NotificationTypes.error
      });
    } finally {
      this.isUpdating = false;
    }
  }
}
