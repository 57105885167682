
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InputText } from '@warrenbrasil/nebraska-web';
import { formatPhone } from '@/modules/common/helpers/phone';
import { phoneFormProperties } from './helpers';
import { PhoneInput } from '../PhoneInput';

@Component({
  components: { InputText, PhoneInput }
})
export default class PhoneForm extends Vue {
  @Prop({ required: true }) private user!: any;

  private isDisabledPhoneInput = true;
  private phoneForm = phoneFormProperties;
  private formatPhone = formatPhone;

  private created() {
    this.setDisabledPhoneInput();
  }

  private setDisabledPhoneInput(): void {
    this.isDisabledPhoneInput = Boolean(this.user.personalProfile.mobile);
  }

  public trimSkypeMaxLength(inputValue: string) {
    this.user.personalProfile.skypeContact = inputValue.substring(0, 100);
  }
}
