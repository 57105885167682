
import { PropType } from 'vue';
import { namespace } from 'vuex-class';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ICustomer } from '@/types/models';
import { IIncidentStatus } from '@/modules/help-center/services/incident-status';
import {
  trackCloseAlert,
  trackEnterStatusPage,
  trackHasIncident
} from '../../help-center-tracker';
import { ContextMessage } from '@warrenbrasil/nebraska-web';

const customerModule = namespace('CustomerModule');

@Component({
  name: 'HelpCenterIncidentAlert',
  components: { ContextMessage }
})
export default class extends Vue {
  @Prop({ type: Object as PropType<IIncidentStatus>, required: true })
  readonly incident!: IIncidentStatus;

  @customerModule.Getter('getCustomer')
  private customer?: ICustomer;

  @Emit('onClose')
  handleClose() {
    trackCloseAlert();
  }

  handleNavigateTrack() {
    trackEnterStatusPage();
  }

  get hasOnlyOneIncident() {
    if (this.incident.services?.length === 1) return true;
    else return false;
  }

  get hasMoreThanOneIncident() {
    if (this.incident.services?.length! > 1) return true;
    else return false;
  }

  get serviceNameWithInstability() {
    const noIncidentExists = this.incident.services?.length === 0;
    if (noIncidentExists) return '';

    const [serviceName] = this.incident.services!;
    return serviceName;
  }

  get statusPageURL() {
    return 'https://status.warren.com.br';
  }

  private get isCustomerLoading() {
    return !this.customer;
  }

  @Watch('customer', { immediate: true })
  private updateCustomer() {
    !this.isCustomerLoading && trackHasIncident();
  }
}
