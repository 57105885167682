import { render, staticRenderFns } from "./AllocationChartLegend.vue?vue&type=template&id=fcd30ace&scoped=true&"
import script from "./AllocationChartLegend.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AllocationChartLegend.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AllocationChartLegend.vue?vue&type=style&index=0&id=fcd30ace&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcd30ace",
  null
  
)

export default component.exports