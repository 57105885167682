
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { ChipStatus, Row } from '@warrenbrasil/nebraska-web';
import { Money } from '@/modules/common/components/MoneyV2';

import { OrderSummary } from '@/modules/trade/services/orders';

@Component({
  components: {
    ChipStatus,
    Row,
    Money
  }
})
export default class OrderRow extends Vue {
  @Prop({ type: Object, required: true })
  readonly order!: OrderSummary;

  @Emit('on-row-click')
  emitOnRowClick() {
    return this.order;
  }

  handleOnClick() {
    if (this.hasDetails) this.emitOnRowClick();
  }

  get computedAriaLabel() {
    return `Ver detalhes da ordem de ${this.order.description}`;
  }

  get hasDetails() {
    return this.order.clickable;
  }

  get computedRowClass() {
    return this.hasDetails ? '' : 'order__row--spacing';
  }
}
