
import { Component, Vue } from 'vue-property-decorator';
import { Heading, BaseText } from '@warrenbrasil/nebraska-web';
import { IStep, Stepper } from '@/modules/wealth/components/Stepper';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { format } from 'date-fns';

@Component({
  components: {
    Heading,
    BaseText,
    Stepper
  }
})
export default class StatusInformationCard extends Vue {
  public readonly steps: IStep[] = [
    {
      title: 'Solicitação',
      description: format(new Date(), `'Hoje às' HH:mm`),
      active: true
    },
    { title: 'Cotização' },
    { title: 'Liquidação' }
  ];

  public readonly NebraskaColors = NebraskaColors;
}
