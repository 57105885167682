
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  Modal,
  ButtonContainer,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Modal,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary
  }
})
export default class DeletePortfolioModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isNotAllowed!: boolean;

  @Emit('close')
  emitClose() {}

  @Emit('delete')
  private emitDelete() {}

  get title() {
    return this.isNotAllowed
      ? 'A carteira não pode ser deletada enquanto houver dinheiro ou transações em aberto'
      : 'Essa ação não pode ser desfeita. Você quer mesmo deletar?';
  }

  get buttonPrimaryLabel() {
    return this.isNotAllowed ? 'Entendi' : 'Deletar';
  }

  buttonPrimaryClick() {
    this.isNotAllowed ? this.emitClose() : this.emitDelete();
  }
}
