import { Route, RouteConfig } from 'vue-router';

import ProductsContainer from '@/modules/products/views/ProductsContainer/ProductsContainer.vue';
import ProductsDetailsContainer from '@/modules/products/views/ProductsDetailsContainer/ProductsDetailsContainer.vue';
import GlobalAllocation from '@/modules/products/views/GlobalAllocation/GlobalAllocationView.vue';

export const productsRoutes: RouteConfig[] = [
  {
    name: 'products',
    path: '/products',
    meta: { requiresAuth: false },
    component: ProductsContainer,
    props: ({ query }: Route) => ({
      instrumentId: query.instrumentId || ''
    })
  },
  {
    name: 'productsAllocation',
    path: '/products/allocation',
    alias: '/overview/products-allocation',
    meta: { scroll: false },
    component: GlobalAllocation
  },
  {
    name: 'product',
    path: '/product/:apiId',
    meta: { requiresAuth: false },
    redirect: { name: 'products-details' }
  },
  {
    name: 'products-details',
    path: '/products/:apiId',
    meta: { requiresAuth: false },
    component: ProductsDetailsContainer,
    props: ({ params }: Route) => ({
      apiId: params.apiId || ''
    })
  }
];
