import { httpClient } from '@/modules/common/services/http/http-client';
import {
  ISubscriptionDetails,
  ISubscriptionDeleteResponse,
  ISubscription
} from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getSubscriptionDetails(
  ticker: string
): Promise<ISubscriptionDetails> {
  return httpClientWithDefaultOptions.get('/ordersSubscriptionDetails', {
    params: {
      ticker
    }
  });
}

export function getSubscriptionReceiptDetails(
  symbol: string
): Promise<ISubscriptionDetails> {
  return httpClientWithDefaultOptions.get('/subscriptionReceiptDetails', {
    params: {
      symbol
    }
  });
}

export function createOrderSubscription(
  symbol: string,
  quantity: number,
  unsubscribedRight: boolean
): Promise<{}> {
  return httpClientWithDefaultOptions.post('/createOrderSubscription', {
    symbol,
    quantity,
    unsubscribedRight
  });
}

export function deleteSubscription(
  ticker: string,
  unsubscribedRight: boolean
): Promise<ISubscriptionDeleteResponse> {
  return httpClientWithDefaultOptions.delete(
    `/deleteOrderSubscription/${ticker}`,
    {
      params: {
        unsubscribedRight
      }
    }
  );
}

export function getSubscriptions(): Promise<ISubscription[]> {
  return httpClientWithDefaultOptions.get('/ordersSubscription');
}

export function getFirstAccessModalSubscriptionStatus(): Promise<boolean> {
  return httpClientWithDefaultOptions.get('/checkFirstSubscritionAccess');
}

export function updateSubscription(
  symbol: string,
  quantity: number,
  unsubscribedRight: boolean
): Promise<ISubscriptionDetails> {
  return httpClientWithDefaultOptions.put('/updateOrderSubscription', {
    symbol,
    quantity,
    unsubscribedRight
  });
}
