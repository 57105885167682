import { trackAmplitudeEvent } from '@/modules/home/helpers/track-amplitude-event';
import { trackHomeByAction } from '@/modules/home/helpers/tracking';

export function trackIncomeHistoryLink() {
  trackHomeByAction('Histórico de rendimentos');
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'more',
    event_name: 'click_income_history',
    event_target: 'Histórico de Rendimentos'
  });
}

export function trackHelpLink() {
  trackHomeByAction('Me ajuda');
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'more',
    event_name: 'click_help',
    event_target: 'Me ajuda'
  });
}
