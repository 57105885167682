export interface Action {
  label: string;
  icon: string;
  handler: Function;
  disabled?: boolean;
  primary?: boolean;
}

export enum ActionType {
  Invest = 'Invest',
  Deposit = 'Depositar',
  Transfer = 'Transferir',
  Pay = 'Pagar'
}
