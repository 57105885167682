
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { Row, BaseText, ChipStatus } from '@warrenbrasil/nebraska-web';
import { StatementAvatar } from '@/modules/transaction/components/StatementAvatar';
import { Money } from '@/modules/common/components/Money';

import { useDashStore } from '@/modules/common/store/DashModule';
import { IOrders } from '@/modules/wealth/services/wealth-orders';

@Component({
  components: {
    BaseText,
    Row,
    StatementAvatar,
    Money,
    ChipStatus
  }
})
export default class StatementRow extends Vue {
  @Prop({ type: Object })
  readonly statement!: IOrders;

  private readonly dashModule = useDashStore();

  @Emit('on-statement-click')
  emitOnStatementClick() {
    return this.statement;
  }

  get computedStatus() {
    if (this.statement.status === 'Cancelado') return 'negative';
    else if (this.statement.status === 'Concluído') return 'positive';
    return 'information';
  }
}
