export interface IIncidentStatus {
  services?: string[];
  status: EStatusTypes;
  page: string;
}

export const enum EStatusTypes {
  Operational = 'operational',
  Incident = 'incident'
}
