
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { INotification } from '@/modules/common/services/http/notification';
import { RouteMixin } from '@/modules/common/mixins/route';
import { dateViewer as date } from '@/modules/common/helpers/date';

const UIModule = namespace('UIModule');

@Component({
  filters: { date }
})
export default class NotificationItem extends Mixins(RouteMixin) {
  @Prop({ type: Object })
  public notification!: INotification;

  @UIModule.Mutation('closeLeftSideBar')
  private close!: () => void;

  private get computedClasses() {
    return [
      'text-p-1',
      {
        notification__highlight: !this.notification.isRead,
        notification__clickable: !!this.notification.url
      }
    ];
  }

  public onClick() {
    if (this.notification.url) {
      window.location.href = this.notification.url;
      this.close();
    }
  }
}
