
import { UserTemplate } from '@/modules/account/components/UserTemplate';
import { ForgotPassword } from './components/ForgotPassword';
import { ConfirmationModal } from './components/ConfirmationModal';
import { Component, Vue } from 'vue-property-decorator';
import { InputPassword, LoaderIndicator } from '@warrenbrasil/nebraska-web';
import { ValidationError } from 'yup';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { getString } from '@/modules/common/helpers/resources';
import {
  updatePassword,
  IUpdatePasswordRequest
} from '@/modules/account/services/account';
import { logout } from '@/modules/common/services/http/account';
import { EStatusType } from '@/modules/account/types';
import {
  currentPasswordSchema,
  newPasswordSchema,
  newPasswordConfirmationSchema
} from './helpers/password-validator';
import * as trackEvents from './track-events';

@Component({
  components: {
    ConfirmationModal,
    UserTemplate,
    InputPassword,
    ForgotPassword,
    LoaderIndicator
  }
})
export default class ChangePasswordView extends Vue {
  readonly title = getString('dash_menu_my_account_change_password');
  private currentPasswordValue = '';
  private newPasswordValue = '';
  private newPasswordConfirmationValue = '';

  private displayConfirmationModal = false;
  private isLoading = false;

  private currentPassword = {
    name: 'old-password',
    label: getString('common_field_oldpassword_label'),
    error: '',
    status: this.currentPasswordValue ? EStatusType.Success : EStatusType.Error
  };

  private newPassword = {
    name: 'new-password',
    label: getString('common_field_newpassword_label'),
    error: '',
    status: this.newPasswordValue ? EStatusType.Success : EStatusType.Error
  };

  private newPasswordConfirmation = {
    name: 'confirm-password',
    label: getString('common_field_confirmpassword_label'),
    error: '',
    status: this.newPasswordConfirmationValue
      ? EStatusType.Success
      : EStatusType.Error
  };

  get isButtonDisabled(): boolean {
    const status = [
      this.currentPassword.status,
      this.newPassword.status,
      this.newPasswordConfirmation.status
    ];
    return status.some(stat => stat === EStatusType.Error);
  }

  mounted() {
    trackEvents.trackViewLoad();
  }

  async submit() {
    trackEvents.trackSaveClick();

    this.onCloseModal();
    this.setLoading(true);
    const data: IUpdatePasswordRequest = {
      currentPassword: this.currentPasswordValue,
      newPassword: this.newPasswordValue
    };

    try {
      await updatePassword(data);
      await logout();
      this.redirectToLogin();
    } catch {
      this.notificationToast(
        'Erro ao alterar a senha',
        NotificationTypes.error
      );
    } finally {
      this.setLoading(false);
    }
  }

  private openConfirmationModal() {
    this.setShowConfirmationModal(true);
  }

  private redirectToLogin() {
    this.$router.push({ name: 'signin' });
  }

  private notificationToast(message: string, type: NotificationTypes) {
    this.$notify({
      group: 'user-template-notifications',
      text: message,
      type
    });
  }

  private async handleCurrentPassword(value: string) {
    try {
      await currentPasswordSchema.validate({ password: value });
      this.setCurrentPasswordStatus('', EStatusType.Success);
    } catch (error) {
      if (error instanceof ValidationError) {
        this.setCurrentPasswordStatus(error.errors[0], EStatusType.Error);
      }
    }
  }

  private async handleNewPassword(value: string) {
    try {
      await newPasswordSchema.validate({ newPassword: value });
      this.setNewPasswordStatus('', EStatusType.Success);
      this.checkPassword();
    } catch (error) {
      if (error instanceof ValidationError) {
        this.setNewPasswordStatus(error.errors[0], EStatusType.Error);
      }
    }
  }

  private async handleNewPasswordConfirmation(value: string) {
    try {
      await newPasswordConfirmationSchema.validate({
        newPasswordConfirmation: value
      });
      this.setNewPasswordConfirmationStatus('', EStatusType.Success);
      this.checkPassword();
    } catch (error) {
      if (error instanceof ValidationError) {
        this.setNewPasswordConfirmationStatus(
          error.errors[0],
          EStatusType.Error
        );
      }
    }
  }

  private checkPassword() {
    const textToSet: [string, EStatusType] =
      this.newPasswordConfirmationValue !== this.newPasswordValue
        ? [
            getString('common_field_confirmpassword_error_match'),
            EStatusType.Error
          ]
        : ['', EStatusType.Success];

    this.setNewPasswordConfirmationStatus(...textToSet);
  }

  private setCurrentPasswordStatus(error: string, status: EStatusType) {
    this.currentPassword.error = error;
    this.currentPassword.status = status;
  }

  private setNewPasswordStatus(error: string, status: EStatusType) {
    this.newPassword.error = error;
    this.newPassword.status = status;
  }

  private setNewPasswordConfirmationStatus(error: string, status: EStatusType) {
    this.newPasswordConfirmation.status = status;
    this.newPasswordConfirmation.error = error;
  }

  private onCloseModal() {
    this.setShowConfirmationModal(false);
  }

  private setShowConfirmationModal(isOpen: boolean) {
    this.displayConfirmationModal = isOpen;
  }

  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  onForgotPasswordClick() {
    trackEvents.trackForgotPasswordClick();
  }
}
