
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import {
  ContentDisplay,
  HeadingDisplay,
  ListDisplay
} from '@warrenbrasil/nebraska-web';
import { IInvestmentSuggestion } from '@/modules/wealth/services/investment-suggestions';
@Component({
  components: {
    ContentDisplay,
    HeadingDisplay,
    ListDisplay
  }
})
export default class SugestionCard extends Vue {
  @Prop({ type: Object, required: true })
  public suggestion!: IInvestmentSuggestion;

  @Emit('suggestion-click')
  emitSuggestionClick() {
    return {
      transactionId: this.suggestion.transactionId,
      portfolioId: this.suggestion.portfolioApiId
    };
  }

  get showExpireDate() {
    const { expiredAt } = this.suggestion;
    return expiredAt && expiredAt.formatted && expiredAt.formatted !== '';
  }

  handleClick() {
    this.emitSuggestionClick();
  }
}
