
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  IconButton,
  ButtonPrimary,
  ButtonSecondary,
  BaseModal,
  NavBar,
  HelperView,
  Footer,
  Row
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { modalErrorTypes } from './types';
import { trackErrorClickOk } from '../../track-events';

@Component({
  components: {
    IconButton,
    ButtonPrimary,
    ButtonSecondary,
    NavBar,
    HelperView,
    BaseModal,
    Footer,
    Row
  }
})
export default class SuggestedTransactionModal extends Vue {
  @Prop({ type: Boolean, required: true })
  private isOpen!: boolean;

  @Prop({ type: Number, required: false })
  private type!: modalErrorTypes;

  @Prop({ type: String, required: false })
  private scheduleMessage?: string;

  @Prop({ type: Boolean, default: false })
  public loading!: boolean;

  private NebraskaColors = NebraskaColors;
  private errors = [
    {
      navBarTitle: 'indisponibilidade',
      title: 'Indisponibilidade temporária',
      content:
        'Esta carteira está indisponível para investimentos no momento. Por favor, tente novamente mais tarde!'
    },
    {
      navBarTitle: 'agendamento',
      title: 'Agendamento de investimento',
      content:
        'O horário disponível para movimentações de mercado é até às 13h. Ao prosseguir, o seu investimento será agendado para o próximo dia útil a partir das 7h da manhã.'
    },
    {
      navBarTitle: 'Erro de depósito',
      title: 'Erro de depósito',
      content:
        'Tivemos um problema para processar o depósito na sua carteira. Por favor, entre em contato com o seu especialista ou com o nosso time de atendimento através do e-mail meajuda@warren.com.br informando o código ”EDTBX”.'
    }
  ];

  get navBarTitle() {
    return this.errors[this.type]?.navBarTitle;
  }

  get title() {
    return this.errors[this.type]?.title;
  }

  get content() {
    if (this.type === modalErrorTypes.Scheduled) {
      return this.scheduleMessage;
    }

    return this.errors[this.type]?.content;
  }

  get isScheduled() {
    return this.type === modalErrorTypes.Scheduled;
  }

  private emitCloseEvent() {
    this.$emit('close');
  }

  public emitInvestEvent() {
    this.$emit('invest');
  }

  private redirectToWealthHome() {
    this.$router.push({ name: 'wealth' });
    trackErrorClickOk();
  }
}
