import { httpClient } from '@/modules/common/services/http/http-client';
import { IInvestmentDetails, IWithdrawDetails } from './variable-income-types';

export const investmentsHttpClient = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getInvestmentDetails(
  portfolioId: string
): Promise<IInvestmentDetails> {
  return investmentsHttpClient.get(
    `/v1/portfolio/variableIncome/${portfolioId}/investment`
  );
}

export function createInvestment(
  portfolioApiId: string,
  amount: number
): Promise<IInvestmentDetails> {
  return investmentsHttpClient.post(`/v1/portfolio/variableIncome/investment`, {
    portfolioApiId,
    amount
  });
}

export function getWithdrawDetails(
  portfolioId: string
): Promise<IWithdrawDetails> {
  return investmentsHttpClient.get(
    `/v1/portfolio/variableIncome/${portfolioId}/withdraw`
  );
}

export function createWithdraw(
  portfolioApiId: string,
  amount: number
): Promise<IWithdrawDetails> {
  return investmentsHttpClient.post(`/v1/portfolio/variableIncome/withdraw`, {
    amount,
    portfolioApiId
  });
}
