import { IFilterOption } from '@warrenbrasil/nebraska-web';

export interface IOption extends Omit<IFilterOption, 'icon'> {
  active?: boolean;
}

export interface IChartFiltersProps {
  filters?: IOption[];
  benchmarkSelectOptions?: IOption[];
  chartSelectOptions?: IOption[];
  isLoading?: boolean;
}

export enum ChartDateFilters {
  Default = 'DEFAULT',
  OneDay = 'ONE_DAY',
  SevenDays = 'SEVEN_DAYS',
  ThirtyDays = 'THIRTY_DAYS',
  SixMonths = 'SIX_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
  Month = 'MONTH',
  Year = 'YEAR'
}
