
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { BaseText, Heading, Row } from '@warrenbrasil/nebraska-web';
import { HandleSection } from '@/modules/trade/components/HandleSection';
import { OrderRow } from '@/modules/trade/components/OrderRow';
import { OrderModal } from '@/modules/trade/components/OrderModal';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { OrderSummary } from '@/modules/trade/services/orders';

@Component({
  components: {
    BaseText,
    HandleSection,
    OrderModal,
    OrderRow,
    Heading,
    Row
  }
})
export default class OrdersList extends Vue {
  @Prop({ type: Array, required: false })
  readonly orders?: OrderSummary[];

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean;

  @Prop({ type: String, required: true })
  readonly viewMoreHref!: string;

  @Emit('on-retry')
  emitOnRetry() {}

  @Emit('on-view-more-click')
  emitOnViewMoreClick() {}

  @Emit('on-order-click')
  emitOnOrderClick(_order: OrderSummary) {}

  currentSelectedOrder: OrderSummary | null = null;
  isOrderModalOpen = false;

  readonly NebraskaColors = NebraskaColors;

  openOrderModal(order: OrderSummary) {
    this.emitOnOrderClick(order);
    this.currentSelectedOrder = order;
    this.isOrderModalOpen = true;
  }

  closeOrderModal() {
    this.isOrderModalOpen = false;
  }

  get hasOrders() {
    return this.orders && this.orders?.length > 0;
  }
}
