
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import {
  Modal,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { FilterCategoryList } from '../FilterCategoryList';
import { IFilterCategory } from '../../../../services/types/models/IFilters';

@Component({
  components: {
    FilterCategoryList,
    Modal,
    ButtonPrimary,
    ButtonSecondary
  }
})
export default class FiltersModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: Array, default: () => [] })
  public filters!: IFilterCategory[];

  beforeUpdate() {
    for (const filterOption of this.filters) {
      if (filterOption.filters && filterOption.selectedOption) {
        filterOption.modalSelectedOption = filterOption.selectedOption;
      } else if (filterOption.subCategories) {
        for (const subCategoriesOption of filterOption.subCategories) {
          if (subCategoriesOption.selectedOption)
            subCategoriesOption.modalSelectedOption =
              subCategoriesOption.selectedOption;
        }
      }
    }
  }

  @Emit('apply-filters')
  public applyFilters() {
    this.onClose();
  }

  @Emit('clear-filters')
  public clearFiltersAndCloseModal() {
    for (const filterOption of this.filters) {
      if (filterOption.filters) {
        filterOption.applied = false;
        filterOption.quickSelectedOption = '';
        delete filterOption.selectedOption;
        delete filterOption.modalSelectedOption;
      } else if (filterOption.subCategories) {
        for (const subCategoriesOption of filterOption.subCategories) {
          subCategoriesOption.applied = false;
          delete subCategoriesOption.selectedOption;
          delete subCategoriesOption.modalSelectedOption;
        }
      }
    }
    this.onClose();
  }

  @Emit('on-close')
  onClose() {}
}
