
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  HelperView,
  RoundedIcon,
  ButtonPrimary,
  BaseText
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { SubscriptionOrderContainer } from '../SubscriptionOrderContainer';
import { StatusSubscription, SubscriptionActionStatus } from '../../types';
import { getStatusMapping } from './statusMapping';

@Component({
  components: {
    HelperView,
    RoundedIcon,
    ButtonPrimary,
    SubscriptionOrderContainer,
    BaseText
  }
})
export default class SubscriptionStatus extends Vue {
  @Prop({ type: String, required: true })
  readonly type!: SubscriptionActionStatus;

  @Prop({ type: String, required: true })
  readonly closingDate!: string;

  private statusMapping: StatusSubscription | null = null;

  created() {
    this.statusMapping = getStatusMapping(this.closingDate);
  }

  get NebraskaColors() {
    return NebraskaColors;
  }

  get title() {
    return this.statusMapping?.[this.type]?.title || '';
  }

  get description() {
    return this.statusMapping?.[this.type]?.description || '';
  }

  get titlePage() {
    return this.statusMapping?.[this.type]?.titlePage || '';
  }

  get iconColor() {
    return this.statusMapping?.[this.type]?.iconColor || '';
  }

  get textIconColor() {
    return this.statusMapping?.[this.type]?.textIconColor || '';
  }

  get iconName() {
    return this.statusMapping?.[this.type]?.iconName || '';
  }

  public emitFinishSubscription() {
    return this.$router.replace('/trade');
  }
}
