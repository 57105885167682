
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Heading,
  BaseText,
  HelperViewStatus
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { WealthStatementsList } from './components/WealthStatementsList';
import { WealthStatementLoader } from './components/WealthStatementLoader';

import { getOrderList, IOrders } from '@/modules/wealth/services/wealth-orders';

@Component({
  components: {
    Heading,
    BaseText,
    HelperViewStatus,
    WealthStatementLoader,
    WealthStatementsList
  }
})
export default class WealthStatement extends Vue {
  @Prop({ type: String, default: '' })
  readonly portfolioId!: string;

  readonly NebraskaColors = NebraskaColors;
  statements: IOrders[] = [];
  hasError = false;
  isLoading = true;
  isEmpty = false;

  filters = {
    pageIndex: 1,
    pageSize: 3,
    portfolioApiId: ''
  };

  get hasStatements() {
    return this.statements.length > 0;
  }

  mounted() {
    this.filters = {
      ...this.filters,
      portfolioApiId: this.portfolioId
    };
    this.getOrderList();
  }

  async getOrderList() {
    this.isLoading = true;
    this.hasError = false;
    try {
      const { orders } = await getOrderList(this.filters);
      this.statements = orders;
    } catch (e) {
      this.hasError = true;
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
