
import { Component, Prop, Vue } from 'vue-property-decorator';

import { BaseText } from '@warrenbrasil/nebraska-web';

import { SuggestedInstrument } from '@/modules/trade/services/search';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    BaseText
  }
})
export default class DropdownOptionItem extends Vue {
  @Prop({ type: Object, required: true })
  private data!: SuggestedInstrument;

  private NebraskaColors = NebraskaColors;

  private get hasImage() {
    return Boolean(this.data.image);
  }

  private handleClick() {
    this.$emit('option-click', this.data);
  }
}
