
import { Component, Emit, Vue } from 'vue-property-decorator';

import { HelperViewStatus } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    HelperViewStatus
  }
})
export default class OrderError extends Vue {
  @Emit('on-retry')
  emitOnRetry() {}
}
