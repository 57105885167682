
import { Component, Vue, Ref } from 'vue-property-decorator';

import gsap from 'gsap';

@Component({})
export default class ModalStepTransition extends Vue {
  @Ref('main')
  public main!: HTMLElement;

  getComponentSizes(el: HTMLElement) {
    const elCloned = el.cloneNode(true) as HTMLElement;
    elCloned.style.visibility = 'hidden';
    this.main.appendChild(elCloned);
    const sizes = elCloned?.getBoundingClientRect();

    elCloned.remove();

    return sizes;
  }

  isMobile() {
    return window.innerWidth <= 480;
  }

  handleEnter(el: HTMLElement, done: Function) {
    const sizes = this.getComponentSizes(el);
    const isMobile = this.isMobile();
    const to = {
      duration: 0.6,
      opacity: 1,
      onComplete: () => {
        this.main.style.overflow = 'visible';
        this.main.style.height = 'auto';

        done();
      }
    } as gsap.TweenVars;

    if (!isMobile) {
      to.height = sizes.height + 'px';
    }

    this.main.style.overflow = 'hidden';
    gsap.to(this.main, to);
  }

  handleLeave(_el: HTMLElement, done: Function) {
    gsap.fromTo(
      this.main,
      {
        opacity: 1
      },
      {
        duration: 0.6,
        opacity: 0,
        onComplete: () => {
          const sizes = this.main.getBoundingClientRect();
          this.main.style.height = sizes.height + 'px';
          done();
        }
      }
    );
  }
}
