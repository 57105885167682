
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Heading, Row } from '@warrenbrasil/nebraska-web';
import { BulletAsset } from '@/modules/common/components/BulletAsset';
import { ProductAllocationCard } from '@/modules/common/components/cards/ProductAllocationCard';
import { ProductDetails } from '@/modules/products/components/ProductDetails';
import {
  IAllocationProducts,
  IProducts
} from '@/modules/products/services/investments';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
@Component({
  components: {
    BulletAsset,
    Heading,
    Row,
    ProductDetails,
    ProductAllocationCard
  }
})
export default class GlobalAllocationProductList extends Vue {
  readonly NebraskaColors = NebraskaColors;
  @Prop({ type: Object, required: true })
  readonly product!: IAllocationProducts;

  productModalOpen = false;
  selectedInstrument = { apiId: '' };
  get products() {
    return this.product.products;
  }

  isClickable(item: IProducts) {
    return !!item.id;
  }

  openProductDetailsModal(item: IProducts) {
    this.productModalOpen = true;
    this.selectedInstrument.apiId = item.id;
  }

  closeProductDetailsModal() {
    this.productModalOpen = false;
    this.selectedInstrument.apiId = '';
  }
}
