
import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';

import { ICustomer } from '@/types/models';
import { trackChatContact, trackEmailContact } from '../../help-center-tracker';

const customerModule = namespace('CustomerModule');

@Component({ name: 'HelpCenterFooter' })
export default class HelpCenterFooter extends Vue {
  @customerModule.Getter('getCustomer')
  private customer?: ICustomer;

  get isCustomerLoading() {
    return !this.customer;
  }

  handleChatClick() {
    this.openZendeskChat();
    trackChatContact();
  }

  handleEmailClick() {
    this.openEmailClient();
    trackEmailContact();
  }

  openZendeskChat() {
    window.openChat();
  }

  openEmailClient() {
    window.location.href = 'mailto:meajuda@warren.com.br';
  }
}
