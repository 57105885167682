import {
  RiskProfile,
  ETradeTypeInstrument
} from '@/modules/trade/services/instruments/types';

export interface RiskTermsConfirmation {
  answer: RiskTermsAnswers;
}

export interface RiskTermsModalProps {
  symbol: string;
  riskProfile: RiskProfile;
  instrumentType: ETradeTypeInstrument;
  orderType: string;
}

export enum RiskTermsAnswers {
  REDO_SUITABILITY = 'REDO_SUITABILITY',
  ACCEPT_RISK_TERMS = 'ACCEPT_RISK_TERMS',
  CLOSE_RISK_TERMS = 'CLOSE_RISK_TERMS'
}
