import { defineStore } from 'pinia';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { ILastRedeemByPortfolio } from './redeem-types';

function getLastRedeemFromLocalStorage(): ILastRedeemByPortfolio | undefined {
  const data = localStorage.getItem(StorageKeys.LastRedeemWithdrawInformation);
  return data ? JSON.parse(data) : undefined;
}

export const useLastReedemStore = defineStore('RedeemWithdrawPortfolio', {
  state: (): ILastRedeemByPortfolio => ({
    amount: getLastRedeemFromLocalStorage()?.amount,
    wealthSuggestedSummary:
      getLastRedeemFromLocalStorage()?.wealthSuggestedSummary
  }),
  actions: {
    saveLastRedeemInformation(data: ILastRedeemByPortfolio) {
      localStorage.setItem(
        StorageKeys.LastRedeemWithdrawInformation,
        JSON.stringify(data)
      );
      this.amount = data.amount;
      this.wealthSuggestedSummary = data.wealthSuggestedSummary;
    },
    clearRedeemInformation() {
      localStorage.removeItem(StorageKeys.LastRedeemWithdrawInformation);
      this.amount = undefined;
      this.wealthSuggestedSummary = undefined;
    }
  }
});
