
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  NavBar,
  IconButton,
  Heading,
  BaseText,
  ButtonBoxDecide
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  ILoginData,
  disconnectDevices
} from '@/modules/authentication/services/mfa';
import { Notification } from '@/modules/common/components/Notification';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { DISCONNECT_DEVICES_ERROR } from '../../../MultiFactorAuthentication/helpers/text-constants/text-constants';
import * as trackEvents from './track-events';

@Component({
  components: {
    NavBar,
    IconButton,
    Heading,
    BaseText,
    ButtonBoxDecide,
    Notification
  }
})
export default class DisconnectDevices extends Vue {
  @Prop({ type: Object, required: true })
  private loginData!: ILoginData;

  private NebraskaColors = NebraskaColors;

  public isLoading = false;

  public disconnectDevicesData = {
    label: 'Desconectar de tudo',
    icon: 'BC0030',
    secondaryText:
      'Esta opção desconecta sua conta em todos os celulares e navegadores conectados e logados.'
  };

  public talkToWarrenData = {
    label: 'Falar com a Warren',
    icon: 'ED0070',
    secondaryText:
      'Se tiver dúvidas, você pode consultar as perguntas frequentes ou falar com nosso atendimento.'
  };

  @Emit('next-step')
  public goToNextStep(step?: string) {
    return step;
  }

  @Emit('previous-step')
  public goToPreviousStep() {
    trackEvents.trackGoBackClick();
  }

  mounted() {
    trackEvents.trackDisconnectDevicesLoad();
  }

  private async disconnectDevices() {
    trackEvents.trackDisconnectAllClick();
    this.isLoading = true;
    try {
      const data = {
        email: this.loginData.email,
        password: this.loginData.password
      };
      await disconnectDevices(data);
      this.goToNextStep();
    } catch {
      this.notificationToast(DISCONNECT_DEVICES_ERROR, NotificationTypes.error);
    } finally {
      this.isLoading = false;
    }
  }

  public talkToWarren() {
    trackEvents.trackTalkToWarrenClick();
    this.goToNextStep('talkWarren');
  }

  private notificationToast(message: string, type: NotificationTypes) {
    this.$notify({
      group: 'wrn-notifications',
      text: message,
      type
    });
  }
}
