
import { Component, Vue } from 'vue-property-decorator';

import { WarrenSession } from '@/modules/common/services/session';

import {
  ContextTypes,
  NebraskaColors
} from '@warrenbrasil/nebraska-tokens-web';
import {
  NavBar,
  ButtonPrimary,
  ContextColorProvider,
  BrandImage,
  EBrandImageSchemas,
  EBrandImageLogos,
  InitialAvatarIcon,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

import { CustomerModule } from '@/modules/common/store/CustomerModule';
import {
  verifyEmail,
  getThemeByParam
} from '@/modules/common/services/http/account';
import { setNebraskaScheme } from '@/modules/common/services/theme';

@Component({
  components: {
    LoaderIndicator,
    NavBar,
    ButtonPrimary,
    ContextColorProvider,
    BrandImage,
    InitialAvatarIcon
  }
})
export default class EmailVerifiedView extends Vue {
  private isLoading = true;
  private isVerified = false;
  private needNextStep = false;
  private isUserLogged = WarrenSession.get('warren.accounts.accessToken');
  private nextEmail = '';
  private NebraskaColors = NebraskaColors;
  private isWhiteLabel = false;
  private brandDomain = '';
  private context = ContextTypes.BRAND;

  readonly EBrandImageLogos = EBrandImageLogos;
  readonly EBrandImageSchemas = EBrandImageSchemas;

  private async mounted() {
    await this.setTheme();
    this.verifyEmail();
  }

  private async setTheme() {
    if (!this.isWarrenBrand()) {
      this.isWhiteLabel = true;

      try {
        const theme = await getThemeByParam('domain', this.brandDomain);
        setNebraskaScheme(theme);
      } catch {
        this.context = ContextTypes.NEUTRAL;
      }
    }
  }

  private isWarrenBrand() {
    this.brandDomain = (this.$route.query.brand as string) ?? 'warrenbrasil';
    return this.brandDomain === 'warrenbrasil';
  }

  private verifyEmail() {
    const routeQuery = this.$route.query;
    const verificationToken = this.$route.params.hash;

    verifyEmail({
      hash: verificationToken,
      step: String(routeQuery.step)
    }).then(
      response => {
        if (response.nextEmail) {
          this.needNextStep = true;
          this.nextEmail = response.nextEmail;
        } else if (response.accessToken) {
          WarrenSession.set(
            'warren.accounts.accessToken',
            response.accessToken
          );

          if (response.user) {
            this.isUserLogged = true;

            CustomerModule.setCustomer = response.user;
          }
        }

        this.isLoading = false;
        this.isVerified = true;
      },
      _error => {
        this.isLoading = false;
      }
    );
  }

  private redirectUser() {
    if (this.isUserLogged) {
      this.$router.push({ name: '/home' });
    } else if (this.brandDomain) {
      this.$router.push({
        path: '/signin',
        query: { brand: this.brandDomain }
      });
    } else {
      this.$router.push({ path: '/signin' });
    }
  }

  get redirectButtonLabel() {
    return this.isUserLogged ? 'Ir para Conta' : 'Ir para Login';
  }
}
