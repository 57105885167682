import { NavigationGuard } from 'vue-router';

export const beforeEnterHome: NavigationGuard = (_to, _from, next) => {
  const { from } = _to.query;

  if (from === 'suggestion-transaction') {
    next({ name: 'investmentSuggestions' });
    return;
  }

  next();
};
