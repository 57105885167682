/*
 ** Manter esse arquivo organizado por ordem alfabética
 */
export enum FeatureFlags {
  CS_CHAT = 'CS_CHAT',
  ENHANCE_TRANSACTIONS_BBB = 'ENHANCE_TRANSACTIONS_BBB',
  MFA_ENABLE_PROCESS = 'MFA-ENABLE-PROCESS',
  PARTNER_CREATE_INVESTOR_ACCOUNT = 'PARTNER_CREATE_INVESTOR_ACCOUNT',
  TRADE_3_0 = 'TRADE_3_0',
  WARREN_HOME_BROKER = 'WARREN_HOME_BROKER',
  PARTNER_TYPEFORM = 'PARTNER_TYPEFORM',
  DISABLE_WARREN_BUILDER = 'DISABLE_WARREN_BUILDER',
  STATEMENT_DOWNLOAD_B2C = 'br.com.warren.banking-cash.statement-download-b2c',
  PLATFORM_MAINTENANCE = 'br.com.warren.foundation.platform-maintenance',
  TUTORIAL_DEPOSIT_TRANSACTION = 'br.com.warren.b2b-omega.tutorial-deposit-transaction',
  FEATURE_SURVEY = 'br.com.warren.ip-tribe-pex.csat',
  TRADE_REQUEST_TRADEMAP = 'br.com.warren.trade.request-trademap',
  TRADE_ALLOCATION_SUBSCRIBES = 'br.com.warren.trade.subscription-listing',
  ENABLE_FACE_MATCH = 'br.com.warren.banking-security.enable-ocr-facematch',
  ENABLE_MFA_BY_PUSH = 'br.com.warren.banking-authentication.enable-mfa-by-push',
  TRADE_EVENTS_PROVISIONED = 'br.com.warren.trade.enable-provisioned-events',
  WALLET_WITHDRAW_BY_PRODUCT = 'br.com.warren.pro.redeem-by-products',
  TRADE_ENABLE_NEW_BROKER_CLIENT_FLOW = 'br.com.warren.trade.enable-new-broker-client-flow',
  CASH_CR_RATE_COSTS = 'br.com.warren.banking.cr-rate-costs',
  FIXED_INCOME_REFERENCE_VALUE = 'br.com.warren.ip-myi.fixed-reference-value',
  ACCOUNT_PROCESSING_WARNING = 'br.com.warren.banking.enable-account-processing-warning',
  WITHDRAW_WITH_TEMPLATES = 'br.com.warren.banking-payments.withdraw-with-templates',
  ENABLE_BROKERAGE_FEE_ON_COSTS_PAGE = 'br.com.warren.broker.enable-brokerage-fee-on-costs-page',
  ENABLE_BROKERAGE_FEE = 'br.com.warren.broker.enable-brokerage-fee',
  REGULATORY_UPDATE = 'br.com.warren.client-onboarding.regulatory-update',
  ENABLE_HISTORICAL_OPTIONS_LIST = 'br.com.warren.product-picking.enable-historical-options-list',
  ENABLE_TRANSIT_AMOUNT = 'br.com.warren.wealth-portfolios.enable-transit-amount',
  ENABLE_STATEMENTS_AND_NOTES_ON_INDEX_PORTFOLIO = 'br.com.warren.product-picking.enable-notes-and-statement-page-on-index-portfolio',
  SHOW_RLP_PAGE = 'br.com.warren.product-picking.show-retail-liquidity-provider-page',
  ENABLE_NEW_FEES_CHARGE = 'br.com.warren.fees-service.enable-new-fees-charge',
  ENABLE_NEW_FEES_VIEW = 'br.com.warren.fees-service.enable-new-fees-view-with-cashback',
  ENABLE_BLOCK_PLATAFORM_ON_MOBILE_DEVICES = 'br.com.warren.experience.block-web-platform-on-mobile',
  ENABLE_PRODUCT_SHELF = 'br.com.warren.experience.enable-product-shelf',
  ENABLE_PRODUCT_SHELF_INVESTMENT_WITHDRAW = 'br.com.warren.experience.enable-product-shelf-investment-withdraw'
}
