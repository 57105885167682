
import { Vue, Prop, Emit, Component, Watch } from 'vue-property-decorator';
import { Overlay } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  toggleBodyScroll,
  addBodyScroll
} from '@/modules/common/helpers/browser';

type Position = 'top' | 'right' | 'bottom' | 'left';

@Component({
  components: {
    Overlay
  }
})
export default class SideSheet extends Vue {
  @Prop({ type: Boolean, default: true })
  public hasOverlay!: boolean;

  @Prop({ type: Boolean, default: false })
  public isOpen!: boolean;

  @Prop({ type: String, default: 'top' })
  public position!: Position;

  @Prop({ type: Boolean, default: false })
  public hasContentBackground?: boolean;

  private NebraskaColors = NebraskaColors;

  @Watch('isOpen')
  private toggleScroll() {
    toggleBodyScroll();
  }

  @Emit()
  private close() {
    return false;
  }

  private get computedClass(): string[] {
    const classes = [];

    classes.push(this.position);

    return classes;
  }

  destroyed() {
    addBodyScroll();
  }
}
