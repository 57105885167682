
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModalFooterWallet } from '@/modules/wallet-builder/src/components/Modals/ModalFooter';

import {
  NavBar,
  ActionableHelperView,
  BaseModal,
  RoundedIcon
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    BaseModal,
    ModalFooterWallet,
    NavBar,
    ActionableHelperView,
    RoundedIcon
  }
})
export default class ProviderError extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public isOpen!: boolean;

  @Prop({ type: String, required: false, default: 'Tente novamente.' })
  public errorMessage!: string;

  @Prop({ type: Boolean, required: false, default: false })
  public isServerError!: boolean;

  @Prop({ required: false, type: Function, default: () => {} })
  private onRetryFunction!: Function;

  public NebraskaColors = NebraskaColors;

  private close() {
    this.$emit('button-clicked');
  }

  private onRetry() {
    this.onRetryFunction();
    this.close();
  }
}
