import { toFixedWithoutRounding } from '../number';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

export function formatCurrency(money: number, showSignal?: boolean) {
  if (!Number.isFinite(money) || isNaN(money)) money = 0;

  const signal = showSignal && money > 0 ? '+' : '';
  const fixedValue = toFixedWithoutRounding(money);
  const parsedValue = parseMoney(fixedValue);

  return `${signal}${parsedValue}`;
}

export function formatCurrencyWithoutRounding(money: number | string) {
  const separatedByDecimal = String(money).split('.');
  const integerNumbers = separatedByDecimal[0];
  const decimalNumbers = separatedByDecimal[1];
  const parsedInteger = parseMoney(integerNumbers);

  if (decimalNumbers) return `R$ ${parsedInteger},${decimalNumbers}`;
  return `R$ ${parsedInteger}`;
}

export function parseMoney(value: string) {
  return value
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1_')
    .replace(/\./g, ',')
    .replace(/_/g, '.');
}

export function moneyViewer(money: number, showMoney = true) {
  return showMoney ? moneyFormat(money, false) : '💰 💰 💰';
}

export function moneyFormat(value: number, showPositiveSign = false): string {
  let signalChar = '';
  const fixedValue = Number(toFixedWithoutRounding(value));
  const formattedValue = formatCurrency(fixedValue).replace('-', '');

  if (Math.sign(fixedValue) === -1) {
    signalChar = '-';
  } else if (Math.sign(fixedValue) === 1 && showPositiveSign) {
    signalChar = '+';
  }

  return `${signalChar}R$ ${formattedValue}`;
}

export function currencyToNumber(currencyString: string): number {
  const parsedNumber = currencyString
    .replace('R$', '')
    .replace(/\./g, '')
    .replace(',', '.')
    .trim();

  return Number(parsedNumber);
}

export function getCurrencyTextColor(
  value?: number | null,
  defaultColor = NebraskaColors.textPrimary
) {
  if (!value) return defaultColor;
  else if (value > 0) return NebraskaColors.monetaryPositive;
  else if (value < 0) return NebraskaColors.monetaryNegative;
}
