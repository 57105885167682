
import { Vue, Component } from 'vue-property-decorator';
import { ICustomer } from '@/types/models';
import { getBrand } from '@/modules/common/services/local-storage';
import {
  getCustomerExperience,
  updateCustomerExperience
} from '@/modules/home/services/bff';
import { namespace } from 'vuex-class';
import { Typeform } from '@/modules/common/components/Typeform';

const customerModule = namespace('CustomerModule');

@Component({
  components: { Typeform }
})
export default class HomeCES extends Vue {
  @customerModule.Getter('getCustomer')
  private customer?: ICustomer;

  private brand = getBrand();

  public idCES = '';
  public showCES = false;
  private nameExperience = 'br.com.warren.banking-payments-home-ces';

  created() {
    const homeExperience: string = JSON.parse(
      localStorage.getItem('warren.home.experience') || ''
    );
    if (homeExperience) {
      this.getCustomerExperience();
    }
  }

  private async getCustomerExperience() {
    try {
      const experiences = await getCustomerExperience(this.brand?._id);

      const CESExperience = experiences.data.find(
        item => item.experience.name === this.nameExperience
      );

      if (CESExperience) {
        this.idCES = CESExperience.experience.formId;
        this.showCES = true;
      }
      localStorage.setItem('warren.home.experience', 'false');
    } catch {
      this.showCES = false;
    }
  }

  public get hiddenFields() {
    return {
      partner_id: this.customer?.partnerInfos?.customer ?? '',
      brand_id: this.brand?._id ?? '',
      brand_name: this.brand?.name ?? '',
      partner_name: this.customer?.partnerInfos?.companyName ?? '',
      customer_api_id: this.customer?._id ?? '',
      customer_first_name: this.customer?.name.split(' ')[0] ?? '',
      platform: 'web',
      platform_version: '1.0'
    };
  }

  public async typeFormUpdate() {
    try {
      await updateCustomerExperience(this.nameExperience);
    } finally {
      this.showCES = false;
    }
  }
}
