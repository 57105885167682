
import { Component, Prop, Vue } from 'vue-property-decorator';
import { moneyViewer } from '../../helpers';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { Grid, Row, ButtonIconContextual } from '@warrenbrasil/nebraska-web';

import { BuilderMatchMedia } from '../BuilderMatchMedia';

import TagRiskLevel from '../TagRiskLevel/TagRiskLevel.vue';
import { IInstrument } from '../../services/types/models/IBffInvestments';

@Component({
  components: {
    Row,
    Grid,
    TagRiskLevel,
    BuilderMatchMedia,
    ButtonIconContextual
  },
  filters: {
    money: moneyViewer
  }
})
export default class CardInstrument extends Vue {
  @Prop({ type: Object, required: true })
  public instrument!: IInstrument;

  @Prop({ type: Boolean, required: false, default: false })
  public allowAddButton!: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  public addProduct?: boolean;

  @Prop({ type: String, required: false, default: true })
  public productInLoadingState!: string;

  private NebraskaColors = NebraskaColors;

  private isDesktop = false;

  get liquidityFormatted() {
    return this.instrument.liquidity?.formatted;
  }

  private onSelect() {
    this.$emit('handle-instrument-click', this.instrument);
  }

  private addProductEvent(instrument: IInstrument) {
    if (instrument.isAdded) {
      return;
    }

    this.$emit('handle-instrument-add-clicked', instrument);
  }

  private get benchmarkFormatted() {
    return this.instrument.profitability ?? '-';
  }
}
