import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

export const getStatusMapping = (closingDate: string) => ({
  Register: {
    title: 'Sua reserva foi confirmada',
    titlePage: 'Subscrição',
    iconColor: NebraskaColors.statusInfoBackground,
    textIconColor: NebraskaColors.statusInfo,
    iconName: 'ic_progress_clock',
    description: `A compra será realizada se houver saldo na data limite e a reserva pode ser alterada até o dia ${closingDate}`
  },
  Update: {
    title: 'Sua reserva foi alterada',
    titlePage: 'Subscrição',
    iconColor: NebraskaColors.statusInfoBackground,
    textIconColor: NebraskaColors.statusInfo,
    iconName: 'ic_progress_clock',
    description: `A compra será realizada se houver saldo na data limite e a reserva pode ser alterada até o dia ${closingDate}`
  },
  Cancel: {
    title: 'Sua reserva foi cancelada',
    titlePage: 'Subscrição',
    iconColor: NebraskaColors.statusPositiveBackground,
    textIconColor: NebraskaColors.statusPositive,
    iconName: 'ic_check_simple',
    description: `Você pode fazer uma nova reserva até o dia ${closingDate}`
  },
  ErrorRegister: {
    title: 'Erro',
    titlePage: 'Subscrição',
    iconColor: NebraskaColors.statusNegativeBackground,
    textIconColor: NebraskaColors.statusNegative,
    iconName: 'ic_progress_clock',
    description: 'Não foi possível fazer a reserva. Tente novamente mais tarde.'
  },
  ErrorUpdate: {
    title: 'Erro',
    titlePage: 'Subscrição',
    iconColor: NebraskaColors.statusNegativeBackground,
    textIconColor: NebraskaColors.statusNegative,
    iconName: 'ic_progress_clock',
    description:
      'Não foi possível alterar a reserva. Tente novamente mais tarde.'
  },
  ErrorCancel: {
    title: 'Erro',
    titlePage: 'Subscrição',
    iconColor: NebraskaColors.statusNegativeBackground,
    textIconColor: NebraskaColors.statusNegative,
    iconName: 'ic_progress_clock',
    description:
      'Não foi possível cancelar a reserva. Tente novamente mais tarde.'
  }
});
