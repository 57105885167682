
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CardMovement } from '@/modules/common/mixins/card-movement';

@Component({
  mixins: [CardMovement]
})
export default class BaseCard extends Vue {
  @Prop({ type: String })
  public background!: string;

  private get hasBackground(): boolean {
    return !!this.background || !!this.$slots.background;
  }

  private get computedBackground() {
    if (!this.background) return null;
    return { backgroundImage: `url(${this.background})` };
  }
}
