
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  NavBar,
  ButtonIconFoundation as ButtonIcon
} from '@warrenbrasil/nebraska-web';
import { PartnerCard } from './components/PartnerCard';
import { SuggestionsSection } from './components/SuggestionsSection';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { IGetInvestmentSuggestionsResponse } from '@/modules/wealth/services/investment-suggestions';

@Component({
  components: {
    ButtonIcon,
    NavBar,
    PartnerCard,
    SuggestionsSection
  }
})
export default class SuggestionView extends Vue {
  @Prop({ type: Object, required: true })
  private investmentSuggestions!: IGetInvestmentSuggestionsResponse;

  @Emit('suggestion-click')
  emitSuggestionClick() {}

  get closeButtonColors() {
    return {
      background: NebraskaColors.elementSecondary,
      content: NebraskaColors.elementPrimary,
      alphas: {
        default: 0,
        hover: 0.08,
        active: 0.08
      }
    };
  }

  get partnerName() {
    return this.investmentSuggestions.partner?.name || '';
  }

  get suggestions() {
    return this.investmentSuggestions.suggestions.data;
  }

  goBack() {
    this.$router.back();
  }
}
