import { httpClient } from '@/modules/common/services/http/http-client';
import { IHelpCenterSection, IHelpCenterArticle } from './types';

const baseUrl = '/api/v1/account/support/';

export function getSections() {
  return httpClient.get<{ sections: IHelpCenterSection[] }>(
    `${baseUrl}sections`
  );
}

export function getSection(id: string) {
  return httpClient.get<{ section: IHelpCenterSection }>(
    `${baseUrl}sections/${id}`
  );
}

export function getArticle(id: string) {
  return httpClient.get<{ article: IHelpCenterArticle }>(
    `${baseUrl}articles/${id}`
  );
}

export function searchArticles(term: string) {
  return httpClient.get<{ articles: IHelpCenterArticle[] }>(
    `${baseUrl}articles/search`,
    {
      params: {
        term
      }
    }
  );
}
