import { GtmTracker } from '@/modules/common/services/analytics';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'portfolio_instrument_withdrawal_review',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackViewLoad() {
  trackEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackRedeemSummaryGrossValue(value: number) {
  trackEvent({
    event_type: 'load',
    event_name: 'requested_value',
    withdrawal_value: value
  });
}

export function trackRedeemSummaryNetValue(value: number) {
  trackEvent({
    event_type: 'load',
    event_name: 'estimated_value',
    withdrawal_estimated_value: value
  });
}

export function trackConfirmRedeem() {
  trackEvent({
    event_type: 'click',
    event_name: 'confirm_withdrawal_request'
  });
}

export function trackErrorView() {
  trackEvent({
    page_screen: 'portfolio_instrument_withdrawal_request_error',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackErrorHandler() {
  trackEvent({
    page_screen: 'portfolio_instrument_withdrawal_request_error',
    event_type: 'click',
    event_name: 'back_to_withdrawal_request'
  });
}
