
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Notification } from '@/modules/common/components/Notification';
import { initTheme } from '@/modules/common/helpers/browser';
import { hasAccessToken } from '@/modules/common/services/access-token';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { AppContainer } from '@/modules/common/components/AppContainer';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import {
  ActionableHelperView,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';
import {
  GtmTracker,
  updateAppContextProps
} from '@/modules/common/services/analytics';
import { isImpersonate } from '@/modules/common/services/partner';
import { wootricService } from '@/plugins/wootric';
import { zendeskAuth, zendeskEvents } from '@/plugins/zendesk';
import { getThemeByParam } from '@/modules/common/services/http/account';
import { setCustomTheme } from '@/modules/common/services/local-storage';
import { setNebraskaScheme } from '@/modules/common/services/theme';

@Component({
  components: {
    Notification,
    ActionableHelperView,
    LoaderIndicator,
    AppContainer
  }
})
export default class App extends Vue {
  isLoading = false;
  error = false;

  created() {
    initTheme();
    this.loadApp();
  }

  get isAuthenticated() {
    return CustomerModule.hasCustomer && hasAccessToken();
  }

  private get customer() {
    return CustomerModule.getCustomer;
  }

  @Watch('isAuthenticated')
  authenticationWatcher(isAuthenticated: boolean) {
    if (!isAuthenticated) return;
    zendeskAuth();
    zendeskEvents();
    updateAppContextProps();
    this.trackUser();
    this.loadWootric();
  }

  private async loadApp() {
    this.isLoading = true;
    this.error = false;

    try {
      await Promise.all([this.loadCustomer(), this.loadFeatureFlags()]);
    } catch {
      this.error = true;
    } finally {
      this.isLoading = false;
    }
  }

  private async loadCustomer() {
    if (!hasAccessToken()) return;
    try {
      await CustomerModule.fetchCustomer();
      await this.loadTheme();
    } catch (error) {
      /*
        If this error occurs, we will redirect the user to login page.
        So we need to ignore it when mounting the App.
      */
      // @ts-ignore
      if (error?.suggestedAction === 'discardAccessToken') return;
      throw error;
    }
  }

  private loadTheme = async () => {
    if (!this.customer) return;
    const theme = await getThemeByParam('email', this.customer.email);
    setNebraskaScheme(theme);
    setCustomTheme(theme);
  };

  private loadFeatureFlags() {
    return FeatureFlagsModule.fetchFeatureFlags();
  }

  private loadWootric() {
    if (!isImpersonate()) {
      wootricService.init();
    }
  }

  private trackUser() {
    GtmTracker.trackEvent({
      customerSegmentation: this.customer!.segmentation,
      action: '',
      category: '',
      label: ''
    });
  }
}
