
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import {
  FilterSelect,
  ButtonSecondary,
  Link
} from '@warrenbrasil/nebraska-web';
import { ExportModal } from './components/ExportModal';
import { IFilters, IFiltersValue } from '../../types';
import { parseFilterOptions } from './helpers';
import * as trackEvents from '@/modules/transaction/views/Transactions/track-events';

@Component({
  components: { FilterSelect, ButtonSecondary, Link, ExportModal }
})
export default class StatementFilters extends Vue {
  @Prop({ type: Object, required: true })
  readonly filters!: IFilters;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isFetchingDownload!: boolean;

  @Emit('change')
  private emitChange() {
    return {
      filters: this.filtersValue,
      hasAppliedFilters: this.hasAppliedFilters
    };
  }

  @Emit('statement-download')
  emitStatementDownload(filters: IFiltersValue) {
    return filters;
  }

  public isModalOpen = false;

  public filtersValue: IFiltersValue = {
    period: '',
    movementType: '',
    module: ''
  };

  get datePeriodsOptions() {
    return parseFilterOptions(this.filters.datePeriods);
  }

  get typesOptions() {
    return parseFilterOptions(this.filters.types);
  }

  get modulesOptions() {
    return parseFilterOptions(this.filters.modules);
  }

  get hasAppliedFilters() {
    return Object.values(this.filtersValue).some(value => !!value);
  }

  private updateFiltersValue(filters: IFiltersValue) {
    this.filtersValue = filters;
  }

  clearFilters() {
    this.filtersValue = {
      period: '',
      movementType: '',
      module: ''
    };

    this.emitChange();
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  handleChange(value: string, filter: string) {
    if (value.toUpperCase() === 'ALL') {
      switch (filter) {
        case 'type':
          this.updateFiltersValue({ ...this.filtersValue, movementType: '' });
          break;
        case 'period':
          this.updateFiltersValue({ ...this.filtersValue, period: '' });
          break;
        case 'module':
          this.updateFiltersValue({ ...this.filtersValue, module: '' });
          break;
      }
    }

    this.emitTrackEvents(value, filter);
    this.emitChange();
  }

  emitTrackEvents(value: string, filter: string) {
    switch (filter) {
      case 'type':
        trackEvents.trackSelectDirectionClick(value);
        break;
      case 'period':
        trackEvents.trackSelectPeriodClick(value);
        break;
      case 'module':
        trackEvents.trackSelectTypeClick(value);
        break;
    }
  }
}
