import { render, staticRenderFns } from "./TradingNotes.vue?vue&type=template&id=3bed7972&scoped=true&"
import script from "./TradingNotes.vue?vue&type=script&lang=ts&setup=true&"
export * from "./TradingNotes.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./TradingNotes.vue?vue&type=style&index=0&id=3bed7972&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bed7972",
  null
  
)

export default component.exports