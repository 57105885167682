
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Heading,
  FilterSelect,
  SkeletonLoader,
  BaseText
} from '@warrenbrasil/nebraska-web';
import { trackYearSelection } from '@/modules/cash/views/Costs/track-events';
import { YearOption } from '@/modules/cash/views/Costs/types';

@Component({
  name: 'CostsSection',
  components: {
    Heading,
    FilterSelect,
    SkeletonLoader,
    BaseText
  }
})
export default class CostsSection extends Vue {
  @Prop({ type: String, required: true })
  public name!: string;

  @Prop({ type: Array })
  public years?: YearOption[];

  @Prop({ type: Number })
  public yearSelected?: number;

  @Prop({ type: Boolean, default: true })
  public showSelect!: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  public isLoading!: boolean;

  public setYearOption(year: number) {
    trackYearSelection(year);
    this.$emit('year-select', year);
  }

  public get currentYearSelected() {
    if (!this.years) return {};
    return (
      this.years.find(year => year.value === this.yearSelected) || this.years[0]
    ).label;
  }
}
