import { GtmTracker } from '@/modules/common/services/analytics';
import { Categories } from '@/types/models/Analytics';

const { trackEvent, trackerWithDefaultProps } = GtmTracker;

const trackAmplitudeEvent = trackerWithDefaultProps({
  page_screen: 'home_portfolio',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

const trackOnboardingAmplitudeEvent = trackerWithDefaultProps({
  page_screen: 'home_portfolio',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackLoadPortfolios() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackBlockVisibility() {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'portfolios',
    event_name: 'block_visibility'
  });
}

export function trackChangeDashboardPeriod(event_target = '') {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'portfolios',
    event_name: 'change_dashboard_period',
    event_target
  });
}

export function trackChangeDashboardType(event_target = '') {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'portfolios',
    event_name: 'change_dashboard_type',
    event_target
  });
}

export function trackCreateNewPortfolio() {
  trackEvent({
    action: 'Criar nova carteira',
    label: 'Dashboard',
    category: Categories.Wealth
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: 'create_portfolio'
  });
}

export function trackGoToInvestmentFlow() {
  trackEvent({
    action: 'Clique',
    label: 'Investir',
    category: Categories.Wealth
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: 'invest'
  });
}

export function trackWealthWithdraw() {
  trackEvent({
    action: 'Clique',
    label: 'Resgatar',
    category: Categories.Wealth
  });
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'withdraw'
  });
}

export function trackDashStatusButtonClick(dashStatusAction?: string | null) {
  if (!dashStatusAction) return;

  type TrackEventType = {
    start_register: string;
    verify_email: string;
    start_suitability: string;
  };

  const dashActionsEvents: TrackEventType = {
    start_register: 'insert_data_register',
    verify_email: 'resend_email',
    start_suitability: 'discover_profile'
  };

  const eventAction = dashStatusAction as keyof TrackEventType;

  trackOnboardingAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: dashActionsEvents[eventAction]
  });
}
