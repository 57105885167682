var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('MatchMedia',{attrs:{"device":"desktop"},scopedSlots:_vm._u([{key:"default",fn:function({ matches: isDesktop }){return [_c('div',{class:[
      'product-to-withdraw__content',
      {
        'product-to-withdraw__content-grid': isDesktop,
        'product-to-withdraw__content-responsive': !isDesktop
      }
    ],attrs:{"aria-disabled":!_vm.product.available.value,"data-testid":`product-to-withdraw-row-${_vm.product.id}`}},[_c('div',{staticClass:"product-to-withdraw__info-name"},[_c(isDesktop ? 'BaseText' : 'Heading',{tag:"component",staticClass:"product-to-withdraw__title",attrs:{"size":"md","level":3}},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),_c('BaseText',{staticClass:"product-to-withdraw__error-message",attrs:{"as":"p","size":"xs"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1),_c('ProductToWithdrawCol',{attrs:{"available":true,"label-mobile":"Valor Disponível","value":_vm.totalGrossWorthAvailable}}),_c('ProductToWithdrawCol',{attrs:{"available":!!_vm.product.available.value,"label-mobile":"Mínimo para resgate","value":_vm.minimumFinancialMovement}}),_c('ProductToWithdrawCol',{attrs:{"available":!!_vm.product.available.value,"label-mobile":"Mínimo de permanência","value":_vm.minimumBalance}}),_c('ProductToWithdrawCol',{attrs:{"available":!!_vm.product.available.value,"label-mobile":"Resgate","value":_vm.daysToRedeem}}),(_vm.product.available.value)?_c('InputMoney',{staticClass:"product-to-withdraw__content-input",attrs:{"placeholder":"R$ 0,00","value":_vm.inputValue},on:{"input":_vm.debouncedUpdateValue}}):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }