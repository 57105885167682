
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

import { Modal, ModalButton, BaseText } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    BaseText,
    Modal,
    ModalButton
  }
})
export default class BlockedBalanceModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Emit('close')
  closeBlockedBalanceModal() {}
}
