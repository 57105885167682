import { getString } from '@/modules/common/helpers/resources';

export const addressFormProperties = {
  zipcode: {
    _id: 'zipcode',
    path: 'zipcode',
    label: getString('common_field_address_zipcode_label')
  },
  street: {
    _id: 'street',
    path: 'street',
    label: getString('common_field_address_street_label')
  },
  number: {
    _id: 'number',
    path: 'number',
    label: getString('common_field_address_number_label')
  },
  complement: {
    _id: 'complement',
    path: 'complement',
    label: getString('common_field_address_complement_label')
  },
  neighborhood: {
    _id: 'neighborhood',
    path: 'neighborhood',
    label: getString('common_field_address_neighborhood_label')
  },
  city: {
    _id: 'city',
    path: 'city',
    label: getString('common_field_address_city_label')
  },
  state: {
    _id: 'state',
    path: 'state',
    label: getString('common_field_address_state_label')
  }
};

export const addressFormText = {
  declarationText:
    'Declaro para fins de comprovação de residência, que resido no endereço acima, bem como, responsabilizo-me civil, administrativa e criminalmente pela veracidade das informações declaradas.',
  linkWebsiteZipCode: 'http://www.buscacep.correios.com.br/sistemas/buscacep/',
  searchText: 'Consultar nos correios'
};

export const zipCodeError = {
  message: 'error_invalid_zipcode'
};
