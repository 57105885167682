
import { Component, Vue, Prop } from 'vue-property-decorator';
import { isImpersonate } from '@/modules/common/services/partner';

@Component({})
export default class TwoColumnsContainer extends Vue {
  @Prop({ type: Boolean, default: true })
  public hasPadding?: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasMargin!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly singleScroll!: boolean;

  private isImpersonate = isImpersonate();

  public get computedPaddingClass() {
    const className = 'template-content';
    if (!this.hasPadding) {
      return `${className}--nopadding`;
    }

    return className;
  }
}
