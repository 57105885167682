
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { Row, BaseText } from '@warrenbrasil/nebraska-web';

import { Balances } from '@/modules/trade/services/account';
import { trackAvailableBalanceLiquidate } from '@/modules/trade/views/TradeView/trackers';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    Row,
    BackButton,
    BaseText
  }
})
export default class AvailableInvest extends Vue {
  @Prop({ type: Object, required: true })
  readonly balances!: Balances;

  readonly NebraskaColors = NebraskaColors;

  @Emit('set-liquidate')
  emitSetLiquidate() {
    trackAvailableBalanceLiquidate();
  }

  @Emit('set-panel')
  emitSetPanel() {}

  public get availableBalanceToInvest() {
    return this.balances.availableBalanceToInvest?.formatted;
  }

  public get todayBalance() {
    return this.balances.todayBalance?.formatted;
  }

  public get toLiquidateBalance() {
    return this.balances.toLiquidateBalance?.formatted;
  }

  public get withdrawalBalance() {
    return this.balances.withdrawalBalance?.formatted;
  }
}
