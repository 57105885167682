import { StatementType } from '@/modules/investments/types/statementType';
import { OverviewAPI } from '@/modules/common/services/http/overview';
import {
  getConsolidatedStatement,
  getConsolidatedExtractAvailableDates,
  getAllocationReport
} from '@/modules/investments/services/investments';
import { convertBlobToJSON } from '@/modules/common/helpers/blob-converter/blob-converter';
import {
  trackConsolidatedExtractDownloadError,
  trackFixedIncomeStatementDownloadError
} from './track-events';

export interface PageInformations {
  header: string;
  fileName: string;
  title: string;
  subtitle: string;
  getStatement: Function;
  getFilters: Function;
  getErrorMessage: Function;
  getDefaultErrorMessage: Function;
}

interface ErrorMessage {
  value: string;
  formatted: string;
}

export interface TaxInformError {
  value: string;
  title: string;
  details: string;
}

interface ErrorResult {
  error: {
    code: number;
    message: string | ErrorMessage | TaxInformError;
  };
}

export enum ErrorType {
  neutral = 'neutral',
  brand = 'brand',
  info = 'information',
  alert = 'alert',
  positive = 'positive',
  negative = 'negative'
}

enum ErrorIcon {
  noContent = 'ED0030'
}

const errorMessages = {
  genericMessage: {
    title: 'Houve um problema para carregar suas informações',
    subTitle: 'Aguarde um momento e tente novamente',
    type: ErrorType.alert,
    buttonLabel: 'Tentar novamente'
  },
  consolidatedStatementMessages: {
    noContent: {
      title: '',
      subTitle: 'Você ainda não possui extratos disponíveis',
      icon: ErrorIcon.noContent,
      type: ErrorType.neutral
    },
    noStatement: {
      title: 'Você não possui extrato consolidado no mês selecionado.',
      subTitle: 'Em breve, o documento será disponibilizado para download',
      type: ErrorType.info,
      buttonLabel: 'Voltar'
    }
  },
  fixedIncomeStatementMessages: {
    noContent: {
      title: '',
      subTitle: 'Você ainda não possui extratos de renda fixa disponíveis',
      icon: ErrorIcon.noContent,
      type: ErrorType.neutral
    },
    noStatement: {
      title: 'Você não possui produtos de renda fixa no mês selecionado',
      subTitle: 'Em breve, o documento será disponibilizado para download',
      type: ErrorType.info,
      buttonLabel: 'Voltar'
    }
  },

  investmentReportMessages: {
    noContent: {
      title: '',
      subTitle: 'Você ainda não possui relatórios disponíveis',
      icon: ErrorIcon.noContent,
      type: ErrorType.neutral
    },
    noStatement: {
      title: 'Você não possui relatório no mês selecionado.',
      subTitle: 'Em breve, o documento será disponibilizado para download',
      type: ErrorType.info,
      buttonLabel: 'Voltar'
    }
  }
};

const setErrorMessage = (error: ErrorResult, callback: Function) => {
  const { genericMessage } = errorMessages;
  const apiMessage = error.error.message as TaxInformError;
  const errorMessage = {
    title: apiMessage.title,
    subTitle: apiMessage.details,
    type: ErrorType.alert,
    buttonLabel: 'Voltar'
  };
  if (error.error.code !== 400 || !errorMessage.title) {
    errorMessage.title = genericMessage.title;
    errorMessage.subTitle = genericMessage.subTitle;
  }

  if (apiMessage.title === genericMessage.title) {
    errorMessage.buttonLabel = 'Tentar novamente';
  }
  callback(errorMessage.title);

  return errorMessage;
};

const consolidatedStatement: PageInformations = {
  header: 'Extratos consolidados',
  fileName: 'extrato-consolidado-detalhado',
  title: 'Extrato consolidado',
  subtitle:
    'Visualize seus extratos referentes ao total investido na plataforma.',
  getStatement: (
    _customerId: string,
    _date: string,
    month: number,
    year: number
  ) => {
    return getConsolidatedStatement(month, year);
  },
  getFilters: () => {
    return getConsolidatedExtractAvailableDates();
  },
  getErrorMessage: async (error: Blob) => {
    const { genericMessage, consolidatedStatementMessages } = errorMessages;
    const parse = await convertBlobToJSON(error);
    if (parse.error?.code === 404) {
      trackConsolidatedExtractDownloadError(
        consolidatedStatementMessages.noStatement.title
      );
      return consolidatedStatementMessages.noStatement;
    }
    trackConsolidatedExtractDownloadError(genericMessage.title);
    return genericMessage;
  },
  getDefaultErrorMessage: async () => {
    return errorMessages.consolidatedStatementMessages.noContent;
  }
};

const fixedIncomeStatement: PageInformations = {
  header: 'Extratos de renda fixa',
  fileName: 'extrato-renda-fixa-detalhado',
  title: 'Extrato de renda fixa',
  subtitle:
    'Visualize extratos referentes aos seus investimentos em renda fixa.',
  getStatement: (customerId: string, date: string) => {
    return OverviewAPI.getFixedIncomeStatementDownload(customerId, date);
  },
  getFilters: () => {
    return OverviewAPI.getAvailableDatesFromStatementDownload(true);
  },
  getErrorMessage: async (error: Blob) => {
    const parse = (await convertBlobToJSON(error)) as ErrorResult;
    return setErrorMessage(parse, trackFixedIncomeStatementDownloadError);
  },
  getDefaultErrorMessage: async (error: ErrorResult) => {
    return error.error.code === 404
      ? errorMessages.fixedIncomeStatementMessages.noContent
      : errorMessages.genericMessage;
  }
};

const investmentReport: PageInformations = {
  header: 'Relatório de investimentos',
  fileName: 'relatório-investimentos',
  title: 'Relatório de investimentos',
  subtitle: '',
  getStatement: (
    _customerId: string,
    _date: string,
    month: number,
    year: number
  ) => {
    return getAllocationReport(month, year);
  },
  getFilters: () => {
    return getConsolidatedExtractAvailableDates();
  },
  getErrorMessage: async (error: Blob) => {
    const { genericMessage, investmentReportMessages } = errorMessages;
    const parse = await convertBlobToJSON(error);
    if (parse.error?.code === 404) {
      return investmentReportMessages.noStatement;
    }
    return genericMessage;
  },
  getDefaultErrorMessage: async () => {
    return errorMessages.investmentReportMessages.noContent;
  }
};

const statementTypes = new Map<StatementType, PageInformations>([
  [StatementType.ConsolidatedStatement, consolidatedStatement],
  [StatementType.FixedIncomeStatement, fixedIncomeStatement],
  [StatementType.InvestmentReport, investmentReport]
]);

export const getStatementInformations = (statementType: StatementType) => {
  const statement = statementTypes.get(statementType);

  return statement || consolidatedStatement;
};
