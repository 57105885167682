
import { Component, Vue } from 'vue-property-decorator';
import {
  SpinnerLoader,
  NavBar,
  HelperViewStatus,
  ButtonIconContextual
} from '@warrenbrasil/nebraska-web';
import { Notification } from '@/modules/common/components/Notification';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import {
  goToPreviousRoute,
  goToNextRoute
} from '@/modules/common/store/RegulatoryUpdateRoutesOrchestrator';
import { WarrenModules } from '@/types/navigation';
import RegulatoryUpdateCloseModal from '../../components/RegulatoryUpdateCloseModal/RegulatoryUpdateCloseModal.vue';
import { EventKnownProperties } from '@warrenbrasil/web-analytics';

export enum RegulatoryUpdatePage {
  Announcement = 'regulatoryUpdateAnnouncement',
  PersonalData = 'regulatoryUpdatePersonalData',
  Address = 'regulatoryUpdateAddress',
  InvestmentProfile = 'regulatoryUpdateInvestmentProfile',
  CallOrchestrator = 'ochestrator'
}

const regulatoryUpdateFlow = [
  RegulatoryUpdatePage.Announcement,
  RegulatoryUpdatePage.CallOrchestrator,
  RegulatoryUpdatePage.PersonalData,
  RegulatoryUpdatePage.Address,
  RegulatoryUpdatePage.InvestmentProfile,
  RegulatoryUpdatePage.CallOrchestrator
];

@Component({
  components: {
    SpinnerLoader,
    RegulatoryUpdateCloseModal,
    NavBar,
    ButtonIconContextual,
    HelperViewStatus,
    Notification
  }
})
export default class RegulatoryUpdateTemplate extends Vue {
  public isLoading = true;

  public hasError = false;

  public shouldConfirmClose = true;

  public isModalOpen = false;

  public notificationGroup = 'wrn-notification-regulatory-update';

  private currentTracker: (remainingProps: EventKnownProperties) => void =
    () => {};

  public get showContent() {
    return !this.isLoading && !this.hasError;
  }

  public setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  public setHasError(hasError: boolean) {
    this.hasError = hasError;
  }

  public removeConfirmClose() {
    this.shouldConfirmClose = false;
  }

  public toggleModalOpen() {
    this.isModalOpen = !this.isModalOpen;
  }

  public exit() {
    this.currentTracker({
      event_type: 'click',
      event_name: 'exit'
    });

    const redirectTo =
      (this.$route.query.cameFrom as WarrenModules) || WarrenModules.Home;
    this.$router.push({ name: redirectTo });
  }

  public close() {
    if (this.shouldConfirmClose) {
      this.toggleModalOpen();
      return;
    }

    this.exit();
  }

  public back() {
    this.currentTracker({
      event_type: 'click',
      event_name: 'back'
    });

    const previousPageIndex =
      regulatoryUpdateFlow.indexOf(this.$route.name as RegulatoryUpdatePage) -
      1;
    const previousPage = regulatoryUpdateFlow[previousPageIndex];

    if (previousPage === RegulatoryUpdatePage.CallOrchestrator) {
      goToPreviousRoute();
      return;
    }

    this.$router.back();
  }

  public goToNextPage() {
    const nextPageIndex =
      regulatoryUpdateFlow.indexOf(this.$route.name as RegulatoryUpdatePage) +
      1;

    const nextPage = regulatoryUpdateFlow[nextPageIndex];

    if (nextPage === RegulatoryUpdatePage.CallOrchestrator) {
      goToNextRoute();
      return;
    }

    this.$router.push({ name: nextPage });
  }

  public get isFirstPage() {
    return (
      regulatoryUpdateFlow.indexOf(this.$route.name as RegulatoryUpdatePage) ===
      0
    );
  }

  public notifyError(message?: string) {
    this.$notify({
      group: this.notificationGroup,
      text:
        message ||
        'Houve um problema ao salvar seus dados. Aguarde alguns instantes e tente novamente.',
      type: NotificationTypes.error
    });
  }

  public changeTracker(
    newTracker: (remainingProps: EventKnownProperties) => void
  ) {
    this.currentTracker = newTracker;
  }
}
