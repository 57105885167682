import * as yup from 'yup';

export const ERROR_MESSAGE = {
  ZIPCODE_REQUIRED: 'CEP é obrigatório',
  STREET_REQUIRED: 'Endereço é obrigatório',
  NUMBER_REQUIRED: 'Número é obrigatório',
  NEIGHBOR_REQUIRED: 'Bairro é obrigatório',
  CITY_REQUIRED: 'Cidade é obrigatória',
  STATE_REQUIRED: 'Estado é obrigatório'
};

export const schema = yup.object().shape({
  zipcode: yup.string().required(ERROR_MESSAGE.ZIPCODE_REQUIRED),
  street: yup.string().required(ERROR_MESSAGE.STREET_REQUIRED),
  number: yup.string().required(ERROR_MESSAGE.NUMBER_REQUIRED),
  neighborhood: yup.string().required(ERROR_MESSAGE.NEIGHBOR_REQUIRED),
  city: yup.string().required(ERROR_MESSAGE.CITY_REQUIRED),
  state: yup.string().required(ERROR_MESSAGE.STATE_REQUIRED)
});
