import { InvestmentStep } from '../components/InvestmentStep';
import { ReviewStep } from '../components/ReviewStep';
import { InvestmentCompletedStep } from '../components/InvestmentCompletedStep';

export enum EProductInvestmentSteps {
  INVESTMENT,
  REVIEW,
  CONCLUSION
}

export const ProductInvestmentComponents = {
  [EProductInvestmentSteps.INVESTMENT]: InvestmentStep,
  [EProductInvestmentSteps.REVIEW]: ReviewStep,
  [EProductInvestmentSteps.CONCLUSION]: InvestmentCompletedStep
};
