import { GtmTracker } from '@/modules/common/services/analytics';
import { Categories } from '@/types/models/Analytics';

const { trackerWithDefaultProps } = GtmTracker;

const trackEvent = GtmTracker.trackerWithDefaultProps({
  category: Categories.PEX
});

const trackAmplitudeEvent = trackerWithDefaultProps({
  page_screen: 'portfolio_investment_flow_investment_type',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackLoadEditedPortfolioInvestment() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackBackEvent() {
  trackEvent({
    action: 'Clique sair tela investir',
    label: 'Investir'
  });
}

export function trackInvestmentByPortfolio() {
  trackEvent({
    action: 'Clique warren investe',
    label: 'Investir'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'select_standard_investment'
  });
}

export function trackInvestmentByProduct() {
  trackEvent({
    action: 'Clique investir por produto',
    label: 'Investir'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'select_instrument_investment'
  });
}
