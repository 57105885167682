
import { Component, Vue } from 'vue-property-decorator';
import {
  TutorialModal,
  ITutorialStep
} from '@/modules/common/components/TutorialModal';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

@Component({
  components: { TutorialModal }
})
export default class InvestmentTransactionTutorial extends Vue {
  private showTutorialModal = false;

  private created() {
    if (this.shouldShowTutorialModal()) {
      this.showTutorialModal = true;
      this.setTutorialAlreadyShown();
    }
  }

  private shouldShowTutorialModal() {
    const isTutorialFFEnabled = FeatureFlagsModule.isEnabled(
      FeatureFlags.TUTORIAL_DEPOSIT_TRANSACTION
    );
    const isEnhanceTransactionsFFEnabled = FeatureFlagsModule.isEnabled(
      FeatureFlags.ENHANCE_TRANSACTIONS_BBB
    );
    const userVisualizedTutorial = !!localStorage.getItem(
      StorageKeys.HasShowedInvestmentViewTutorial
    );

    return (
      isTutorialFFEnabled &&
      isEnhanceTransactionsFFEnabled &&
      !userVisualizedTutorial
    );
  }

  private get tutorialSteps(): ITutorialStep[] {
    return [
      {
        title: 'Nova forma de investir em carteiras',
        description:
          'Realize seus investimentos de uma forma ainda mais prática',
        img: '/assets/investment/tutorial-step-01.png'
      },
      {
        title: 'Selecione a carteira em que deseja investir',
        description:
          'Na sua lista de carteiras criadas, escolha a carteira que irá receber o investimento',
        img: '/assets/investment/tutorial-step-02.gif'
      },
      {
        title: 'Utilize o saldo disponível na sua Conta Digital',
        description: 'Invista utilizando o saldo presente na sua conta digital',
        img: '/assets/investment/tutorial-step-03.gif'
      }
    ];
  }

  private setTutorialAlreadyShown() {
    localStorage.setItem(StorageKeys.HasShowedInvestmentViewTutorial, 'true');
  }

  private handleCloseTutorial() {
    this.showTutorialModal = false;
  }

  private handleCloseModal() {
    this.handleCloseTutorial();
  }
}
