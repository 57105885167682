import { getString } from '@/modules/common/helpers/resources';

export const phoneFormProperties = {
  disabledPhone: {
    _id: 'disabled-phone',
    path: 'disabled-phone',
    label: getString('common_field_mobile_label')
  },
  enabledPhone: {
    _id: 'phone',
    path: 'phone',
    label: getString('common_field_mobile_label')
  },
  skypeContact: {
    _id: 'skypeContact',
    path: 'phone',
    label: 'Skype'
  }
};
