
import { Component, Vue } from 'vue-property-decorator';
import { isRequestError } from '@warrenbrasil/web-http';
import Big from 'big.js';

import { BackButton } from '@/modules/common/components/buttons/BackButton';
import {
  ContextColorProvider,
  NavBar,
  Heading
} from '@warrenbrasil/nebraska-web';
import { OrdersTable } from './components/OrdersTable';
import { Pagination } from '@/modules/common/components/Pagination';
import { OrderModal } from '@/modules/trade/components/OrderModal';
import { OrdersFilters } from './components/OrdersFilters';

import {
  getOrders,
  Order,
  GetOrdersParams,
  OrdersFilters as IOrdersFilters,
  FilterFormatted
} from '@/modules/trade/services/orders';
import { NumberFormatted } from '@/modules/trade/types';
import {
  FetchingOrdersStatus,
  OrderFiltersKeys,
  OrderFiltersQueryParamsKeys,
  Filters
} from './types';
import { moneyFormat } from '@/modules/common/helpers/currency';
import * as trackOrdersEvents from './trackers';
import { logError } from '@/modules/trade/helpers/logs';

@Component({
  components: {
    BackButton,
    ContextColorProvider,
    Heading,
    NavBar,
    OrderModal,
    OrdersFilters,
    OrdersTable,
    Pagination
  }
})
export default class OrdersView extends Vue {
  isOrderModalOpen = false;
  currentOrder: Order | null = null;
  orders: Order[] = [];
  currentSelectedPage = 1;
  totalPages = 1;
  itemsPerPage = 10;
  status = FetchingOrdersStatus.Loading;
  FetchingOrdersStatus = FetchingOrdersStatus;

  abortSignal: AbortController | null = null;

  filters: Filters = {
    [OrderFiltersKeys.Status]: {
      options: [],
      value: null
    },
    [OrderFiltersKeys.Type]: {
      options: [],
      value: null
    },
    [OrderFiltersKeys.Date]: {
      options: [],
      value: null
    },
    [OrderFiltersKeys.Instrument]: {
      value: null
    }
  };

  readonly queryParamsKeysMap = {
    [OrderFiltersKeys.Instrument]: OrderFiltersQueryParamsKeys.Instrument,
    [OrderFiltersKeys.Date]: OrderFiltersQueryParamsKeys.Date,
    [OrderFiltersKeys.Status]: OrderFiltersQueryParamsKeys.Status,
    [OrderFiltersKeys.Type]: OrderFiltersQueryParamsKeys.Type
  };

  created() {
    trackOrdersEvents.trackOrdersViewLoad();
    this.setFiltersFromURL();
    this.fetchOrders();
  }

  get hasOrders() {
    return this.orders.length > 0;
  }

  get hasFilters() {
    const filtersKeys = Object.values(OrderFiltersKeys);

    return filtersKeys.some(filterKey => {
      return !!this.filters[filterKey].options?.length;
    });
  }

  get hasFilterSelected() {
    const filtersKeys = Object.values(OrderFiltersKeys);

    return filtersKeys.some(
      filterKey => this.filters[filterKey].value !== null
    );
  }

  get isFiltersSectionLoading() {
    return !this.hasFilters && this.status === FetchingOrdersStatus.Loading;
  }

  handleChangeAveragePrice(orderId: string, averagePrice: NumberFormatted) {
    const orderIndex = this.orders.findIndex(order => order.id === orderId);

    if (orderIndex !== -1) {
      const order = this.orders[orderIndex];
      const quantity = new Big(order.quantity.value);
      const averagePriceValue = new Big(averagePrice.value);
      const newMovementTotalPrice = quantity.mul(averagePriceValue).toNumber();

      order.pricePerShare = averagePrice;
      order.movementTotalPrice = {
        value: newMovementTotalPrice,
        formatted: moneyFormat(newMovementTotalPrice)
      };
    }
  }

  closeOrderModal() {
    this.isOrderModalOpen = false;
  }

  onBackClick() {
    trackOrdersEvents.trackOrdersViewBackClick();
    this.$router.back();
  }

  setFiltersFromURL() {
    const filtersKeys = Object.values(OrderFiltersKeys);

    filtersKeys.forEach(filterKey => {
      const queryParamKey = this.queryParamsKeysMap[filterKey];
      const value = this.$route.query[queryParamKey];

      if (typeof value === 'string') {
        this.setFilterValue(filterKey, value);
      }
    });
  }

  setFilterIntoURL(filterKey: OrderFiltersKeys, value?: string | null) {
    const query = { ...this.$route.query };
    const queryParamKey = this.queryParamsKeysMap[filterKey];

    if (!value) {
      delete query[queryParamKey];
    } else {
      query[queryParamKey] = value;
    }

    this.$router.replace({
      path: this.$route.path,
      query: { ...query }
    });
  }

  handleOnSelectPage(selectedPage: number) {
    this.currentSelectedPage = selectedPage;
    this.fetchOrders();
  }

  openOrderDetailsModal(order: Order) {
    this.currentOrder = order;
    this.isOrderModalOpen = true;
  }

  setFilterValue(filterKey: OrderFiltersKeys, value: string) {
    const currValue = value === 'All' ? null : value;
    this.filters[filterKey].value = currValue;
    this.setFilterIntoURL(filterKey, currValue);
  }

  trackFilterSelection(filterKey: OrderFiltersKeys) {
    let filterOptions: FilterFormatted[] = [];
    let filterLabel = '';

    if (filterKey === OrderFiltersKeys.Instrument) {
      filterLabel = this.filters[filterKey].value || 'Todos ativos';
    } else {
      filterOptions = this.filters[filterKey].options || [];
      filterLabel =
        filterOptions?.find(
          option => option.value === this.filters[filterKey].value
        )?.formatted || '';
    }

    trackOrdersEvents.trackSelectOrdersTableFilter(filterLabel, filterOptions);
  }

  handleFilterSelection(filterKey: OrderFiltersKeys, value: string) {
    this.setFilterValue(filterKey, value);
    this.trackFilterSelection(filterKey);
    this.currentSelectedPage = 1;
    this.fetchOrders();
  }

  async handleClearFilters() {
    this.setDefaultFiltersValues();
    this.currentSelectedPage = 1;
    await this.fetchOrders();
  }

  setDefaultFiltersValues() {
    const filtersKeys = Object.values(OrderFiltersKeys);

    filtersKeys.forEach(filterKey => {
      this.setFilterValue(filterKey, 'All');
    });
  }

  private async fetchOrders() {
    try {
      this.status = FetchingOrdersStatus.Loading;
      if (this.abortSignal) this.abortSignal.abort();
      this.abortSignal = new AbortController();

      const params = {
        itemsPerPage: this.itemsPerPage,
        page: this.currentSelectedPage,
        symbol: this.filters.instrument.value || null,
        date: this.filters.dateFilter.value || null,
        status: this.filters.status.value || null,
        type: this.filters.type.value || null
      } as GetOrdersParams;

      const data = await getOrders(params, this.abortSignal.signal);

      this.currentSelectedPage = data.pagination.page;
      this.totalPages = data.pagination.totalPages;
      this.itemsPerPage = data.pagination.itemsPerPage;

      this.setFilters(data.filters);

      this.orders = data.orders;

      this.status = FetchingOrdersStatus.Default;
    } catch (e) {
      if (isRequestError(e) && e.type === 'canceled') return;
      logError(e, 'request_orders_view_error');
      trackOrdersEvents.trackOrdersViewRequestError();

      this.status = FetchingOrdersStatus.Error;
      this.orders = [];
    }
  }

  setFilters(filters: IOrdersFilters) {
    this.filters = {
      [OrderFiltersKeys.Status]: {
        options: filters.status?.available || [],
        value: filters.status?.selected?.value || null
      },
      [OrderFiltersKeys.Type]: {
        options: filters.type?.available || [],
        value: filters.type?.selected?.value || null
      },
      [OrderFiltersKeys.Date]: {
        options: filters.dateFilter?.available || [],
        value: filters.dateFilter?.selected?.value || null
      },
      [OrderFiltersKeys.Instrument]: {
        value: filters.instruments?.selected || null
      }
    };
  }
}
