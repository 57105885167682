
import { Component, Emit, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  ButtonPrimary,
  Footer,
  HelperViewContextual
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Footer,
    HelperViewContextual,
    ButtonPrimary
  }
})
export default class MFAViewActivation extends Vue {
  private NebraskaColors = NebraskaColors;

  readonly title = 'Segurança extra na sua conta.';
  readonly description =
    'Ative a verificação em duas etapas em apenas um passo.';

  readonly buttonLabel = 'Continuar';

  @Emit()
  private nextStep() {}
}
