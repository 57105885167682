
import { Vue, Component, ProvideReactive } from 'vue-property-decorator';
import { SubscriptionActionStatus } from './components/SubscriptionSidebar/types';
import {
  ContextColorProvider,
  BaseText,
  ActionableHelperView,
  NavBar,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { TwoColumnsContainer } from '@/modules/common/components/TwoColumnsContainer';
import { SubscriptionSidebar } from './components/SubscriptionSidebar';
import { SubscriptionDetails } from './components/SubscriptionDetails';
import { CancelSubscriptionModal } from './components/CancelSubscriptionModal';
import {
  EventSubscriptionType,
  SubscribeStatus
} from '../../services/subscription/enums';
import {
  deleteSubscription,
  getSubscriptionDetails,
  getSubscriptionReceiptDetails,
  ISubscriptionDetails
} from '@/modules/trade/services/subscription';
import { trackLoadSubscription } from './tracker';

enum SidebarTypes {
  SubscriptionOrder,
  SubscriptionConfirm,
  SubscriptionStatus
}

@Component({
  components: {
    CancelSubscriptionModal,
    ContextColorProvider,
    SubscriptionSidebar,
    SubscriptionDetails,
    ActionableHelperView,
    TwoColumnsContainer,
    BackButton,
    LoaderIndicator,
    BaseText,
    NavBar
  }
})
export default class SubscriptionView extends Vue {
  public subscriptionDetails: ISubscriptionDetails | null = null;
  public error = false;
  public loading = true;
  public isCancelModalOpen = false;
  private sidebarStep = SidebarTypes.SubscriptionOrder;
  public subscriptionStatusType = SubscriptionActionStatus.Register;

  public async fetchDetails() {
    this.loading = true;
    this.error = false;

    const ticker = this.$route.query?.ticker as string;
    const isSubscriptionReceipt = this.$route.query?.isReceipt === 'true';

    try {
      if (isSubscriptionReceipt) {
        this.subscriptionDetails = await getSubscriptionReceiptDetails(ticker);
      } else {
        this.subscriptionDetails = await getSubscriptionDetails(ticker);
      }
    } catch {
      this.error = true;
    } finally {
      this.loading = false;
    }

    trackLoadSubscription();
  }

  public setSidebarStep(step: SidebarTypes) {
    this.sidebarStep = step;
  }

  public setSubscriptionStatusType(type: SubscriptionActionStatus) {
    this.subscriptionStatusType = type;
  }

  created() {
    this.fetchDetails();
  }

  @ProvideReactive()
  get subscriptionStatus() {
    return this.subscriptionDetails?.status;
  }

  @ProvideReactive()
  get subscriptionStatusMessage() {
    return this.subscriptionDetails?.statusMessage;
  }

  @ProvideReactive()
  get instrumentName() {
    return this.subscriptionDetails?.instrumentName;
  }

  @ProvideReactive()
  get instrumentType() {
    return this.subscriptionDetails?.instrumentType;
  }

  get isActiveSubscribed() {
    const statusActiveSubscribed = [
      SubscribeStatus.subscribed,
      SubscribeStatus.unsubscribed
    ];
    return statusActiveSubscribed.includes(
      this.subscriptionStatus as SubscribeStatus
    );
  }

  get instrumentValue() {
    return this.subscriptionDetails?.subscriptionPrice;
  }

  get actualPriceFormatted() {
    return this.subscriptionDetails?.actualPrice?.formatted;
  }

  get descriptionPage() {
    return this.subscriptionDetails?.description;
  }

  get isEditingSubscriptionOrder() {
    return !this.subscriptionDetails?.isNewRequest;
  }

  get liquidationDate() {
    return this.subscriptionDetails?.liquidationDate;
  }

  get closingDate() {
    return this.subscriptionDetails?.closingDate;
  }

  get unconfirmedRequestedQuantity() {
    const requestedQuantity = (this.subscriptionDetails
      ?.unconfirmedRequestedQuantity ??
      this.subscriptionDetails?.subscribedQuantity) as number;

    return requestedQuantity.toString();
  }

  public async cancelSubscription() {
    this.isCancelModalOpen = false;
    this.loading = true;
    this.error = false;

    const ticker = this.$route.query?.ticker as string;
    const unsubscribedRight =
      this.subscriptionDetails?.eventType ===
      EventSubscriptionType.LeftOversSubscription;

    deleteSubscription(ticker, unsubscribedRight)
      .then(() => {
        this.setSubscriptionStatusType(SubscriptionActionStatus.Cancel);
      })
      .catch(() => {
        this.setSubscriptionStatusType(SubscriptionActionStatus.ErrorCancel);
        this.error = true;
      })
      .finally(() => {
        this.loading = false;
        this.setSidebarStep(SidebarTypes.SubscriptionStatus);
      });
  }

  public openCancelSubscriptionModal() {
    this.isCancelModalOpen = true;
  }

  public closeCancelSubscriptionModal() {
    this.isCancelModalOpen = false;
  }
}
