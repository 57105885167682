
import { Component, Prop, Watch } from 'vue-property-decorator';

import BaseProvider from './BaseProvider';
import { BffInvestments } from '@/modules/wallet-builder/src/services/bff-investments';
import { IInstrumentDetails } from '@/modules/wallet-builder/src/services/types/models/IBffInvestments';

@Component
export default class ProductsDetailsProvider extends BaseProvider<IInstrumentDetails> {
  @Prop({ type: String })
  public apiId?: string;

  public bff = new BffInvestments();

  public created() {
    this.fetch();
  }

  @Watch('apiId')
  public async fetch() {
    if (!this.apiId) return;
    this.setIsLoading(true);
    this.resetErrors();
    try {
      const { instrumentDetails } = await this.bff.getInstrumentDetails(
        this.apiId
      );
      const instrument = instrumentDetails?.data.find(
        item => item?.apiId === this.apiId
      ) as IInstrumentDetails;
      if (instrument) {
        this.setItem(instrument);
        this.$emit('handle-success', instrument);
      }
    } catch (e) {
      this.setError(e as Error);
    } finally {
      this.setIsLoading(false);
    }
  }

  public get instrument(): IInstrumentDetails | null {
    return this.getItem();
  }
}
