
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import {
  Heading,
  BaseIcon,
  BaseText,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

enum ErrorHandlerStatus {
  WARNING = 'warning'
}

@Component({
  name: 'ErrorHandler',
  components: { Heading, BaseIcon, BaseText, ButtonSecondary }
})
export default class ErrorHandler extends Vue {
  public readonly NebraskaColors = NebraskaColors;

  @Prop({ required: true, type: String })
  public readonly title!: string;

  @Prop({ required: false, type: String })
  public readonly description?: string;

  @Prop({ required: false, type: String, default: ErrorHandlerStatus.WARNING })
  public readonly status?: ErrorHandlerStatus;

  @Prop({ default: 'Tentar novamente', type: String })
  public readonly label!: string;

  @Prop({ default: true, type: Boolean })
  public readonly showButton!: boolean;

  @Prop({ type: String, default: 'ic_attention' })
  public readonly icon!: string;

  @Prop({ type: Boolean, default: true })
  public readonly hasBorder!: boolean;

  @Emit('retry')
  public retry() {}

  get statusClasses(): string[] {
    return [
      'error-handler__status-icon',
      `error-handler__status--${this.status}`
    ];
  }
}
