import { SuggestionsView } from '../components/SuggestionsView';
import { SuggestionReview } from '../components/SuggestionReview';
import { SuggestionsError } from '../components/SuggestionsError';
import { SuggestionConclusion } from '../components/SuggestionConclusion';

export enum EInvestmentSuggestionsSteps {
  VIEW,
  REVIEW,
  ERROR,
  CONCLUSION
}

export const InvestmentSuggestionsComponents = {
  [EInvestmentSuggestionsSteps.VIEW]: SuggestionsView,
  [EInvestmentSuggestionsSteps.REVIEW]: SuggestionReview,
  [EInvestmentSuggestionsSteps.ERROR]: SuggestionsError,
  [EInvestmentSuggestionsSteps.CONCLUSION]: SuggestionConclusion
};

export const ErrorTypes = {
  INSUFICIENT_BALANCE: 'InsufficientBalance',
  INCONPATIBLE_INVESTOR_TYPE: 'IncompatibleInvestorType',
  INVALID_SCHEDULE_DATE: 'InvalidScheduleDate'
};

export enum EButtonLabel {
  REMOVE_SUGGESTION = 'Remover sugestão',
  BACK = 'Voltar',
  GENERIC = 'Tentar novamente'
}
