import { TransferData } from '@/modules/transaction/services/transfer';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { IBFFNumberResponse } from '@/modules/wealth/services/investment';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { IPortfolio } from '@/types/models/Portfolio';
import {
  BffWithdraw,
  IGetTransactionOptions
} from '@/modules/wealth/services/withdraw';
export interface IPortfolioInvestmentSummary {
  portfolioName: string;
  portfolioApiId: string;
  products: { name: string; amount: IBFFNumberResponse }[];
  total: IBFFNumberResponse;
}

export interface IPortfolioSaveLastInvestment {
  transfer?: TransferData;
}

function getTransactionTypeFromLocalStorage(): IGetTransactionOptions {
  const data = localStorage.getItem(StorageKeys.TypeTransactions);
  return data ? JSON.parse(data) : [];
}
function getInvestmenSuccessSumaryFromLocalStorage(): IPortfolioInvestmentSummary {
  const data = localStorage.getItem(StorageKeys.InvestmentResultSummary);
  return data ? JSON.parse(data) : {};
}

function getLastInvestmentPortfolioFromLocalStorage(): IPortfolioSaveLastInvestment {
  const data = localStorage.getItem(StorageKeys.LastInvestmentPortfolio);
  return data ? JSON.parse(data) : {};
}

const bffWithdraw = new BffWithdraw();
@Module({
  namespaced: true,
  name: 'InvestmentsModule'
})
export class InvestmentsStoreModule extends VuexModule {
  transactionTypeValue?: IGetTransactionOptions =
    getTransactionTypeFromLocalStorage();

  investmentCompletedSummaryValue: IPortfolioInvestmentSummary =
    getInvestmenSuccessSumaryFromLocalStorage();

  errorTypeTransactionsValue = false;
  lastInvestmentPortfolioValue: IPortfolioSaveLastInvestment =
    getLastInvestmentPortfolioFromLocalStorage();

  public get transactionType(): IGetTransactionOptions | object {
    return this.transactionTypeValue ?? {};
  }

  public get investmentCompletedSummary():
    | IPortfolioInvestmentSummary
    | object {
    return this.investmentCompletedSummaryValue ?? {};
  }

  public get errorTypeTransactions(): boolean {
    return this.errorTypeTransactionsValue;
  }

  public get lastInvestmentPortfolio(): IPortfolio | object {
    return this.lastInvestmentPortfolioValue ?? {};
  }

  @Mutation
  saveTransactionType(value: IGetTransactionOptions) {
    localStorage.setItem(StorageKeys.TypeTransactions, JSON.stringify(value));
    this.transactionTypeValue = value;
  }

  @Mutation
  saveInvestmentCompletedSummary(value: IPortfolioInvestmentSummary) {
    localStorage.setItem(
      StorageKeys.InvestmentResultSummary,
      JSON.stringify(value)
    );
    this.investmentCompletedSummaryValue = value;
  }

  @Mutation
  setErrorTransactionType(value: boolean) {
    this.errorTypeTransactionsValue = value;
  }

  @Action
  public async getPortfolioTransactionOptions(portfolio: {
    id: string;
    customer: string;
  }) {
    try {
      const typeTransactions = await bffWithdraw.getPortfolioTransactionOptions(
        portfolio.id,
        portfolio.customer
      );
      this.context.commit('saveTransactionType', typeTransactions.options);
      this.context.commit('setErrorTransactionType', false);
      return typeTransactions;
    } catch (error) {
      this.context.commit('setErrorTransactionType', true);
    }
  }

  @Action
  public async getPortfolioInfos(portfolioId: string, customerId: string) {
    await this.getPortfolioTransactionOptions({
      id: portfolioId,
      customer: customerId
    });
  }

  @Mutation
  saveLastInvestmentPortfolio(value: IPortfolioSaveLastInvestment) {
    localStorage.setItem(
      StorageKeys.LastInvestmentPortfolio,
      JSON.stringify(value)
    );
    this.lastInvestmentPortfolioValue = value;
  }
}
