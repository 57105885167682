
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { getAddress, saveAddress } from '../../services';
import { RegulatoryUpdateAddressSummary } from '../../components/RegulatoryUpdateAddressSummary';
import { RegulatoryUpdateAddNewAddress } from '../../components/RegulatoryUpdateAddNewAddress';
import { parseApiErrorToScreenError } from '../../helpers/parse-api-error-to-screen-error';
import { getStateList } from '@/modules/account/services/resources';
import { State, Address } from '@/modules/account/services/resources/types';
import { YupValidationErrors } from '@/modules/common/helpers/yup-validation';
import {
  trackChangeAddress,
  trackConfirmAddress,
  trackContinue,
  trackView
} from './track-events';
import {
  trackEvent,
  trackError
} from '../RegulatoryUpdatePersonalData/track-events';
import { EventKnownProperties } from '@warrenbrasil/web-analytics';

@Component({
  components: { RegulatoryUpdateAddressSummary, RegulatoryUpdateAddNewAddress }
})
export default class RegulatoryUpdateAddress extends Vue {
  @Prop({ type: Boolean, required: true })
  public showContent!: boolean;

  @Emit()
  private setIsLoading(_isLoading: boolean) {}

  @Emit()
  private setHasError(_hasError: boolean) {}

  @Emit()
  public goToNextPage() {}

  @Emit()
  private notifyError(_message?: string) {}

  @Emit()
  private changeTracker(
    _newTracker: (remainingProps: EventKnownProperties) => void
  ) {}

  public address: Address | null = null;

  public isButtonLoading = false;

  public isAddNewAddress = false;

  public statesList: State[] = [];

  public errors: YupValidationErrors = {};

  mounted() {
    this.fetchAddress();
    this.changeTracker(trackEvent);
  }

  private async fetchAddress() {
    this.setIsLoading(true);
    this.setHasError(false);

    try {
      this.address = await getAddress();
      trackView();
    } catch (_e) {
      this.setHasError(true);
    }

    this.setIsLoading(false);
  }

  public async saveAddressAndGoToNextPage(newAddress?: Address) {
    this.isButtonLoading = true;
    try {
      await saveAddress(newAddress || this.address!);
      if (this.isAddNewAddress) {
        trackConfirmAddress();
      } else {
        trackContinue();
      }

      this.goToNextPage();
    } catch (e: any) {
      if (e?.error?.message?.value === 'InvalidInput') {
        this.errors = parseApiErrorToScreenError(e.error.locations);
      } else if (e?.error?.message?.value === 'ExpiredPassword') {
        this.$router.push({
          name: 'regulatoryUpdateVerifyPassword',
          query: { cameFrom: 'regulatoryUpdateAddress' }
        });
      } else {
        trackError(e?.error?.message?.formatted);
        this.notifyError(e?.error?.message?.formatted);
      }
    }
    this.isButtonLoading = false;

    Object.values(this.errors).forEach((v: string[]) => trackError(v[0]));
  }

  private async fetchStates() {
    this.setIsLoading(true);
    this.setHasError(false);

    try {
      this.statesList = await getStateList();
    } catch (_e) {
      this.setHasError(true);
    }

    this.setIsLoading(false);
  }

  public async goToAddNewAddress() {
    await this.fetchStates();
    trackChangeAddress();
    this.isAddNewAddress = true;
  }

  public removeError(key: string) {
    delete this.errors[key];
  }

  public setErrors(errors: YupValidationErrors) {
    this.errors = errors;
  }
}
