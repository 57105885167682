
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { Row, BaseText, BaseIcon } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { SidebarTypes } from '@/modules/trade/views/TradeView/components/TradeSidebar';
import { trackOpenBlockedBalanceModal } from '@/modules/trade/views/TradeView/trackers';

import { NumberFormatted } from '@/modules/trade/types/bff';

@Component({
  components: {
    Row,
    BackButton,
    BaseText,
    BaseIcon
  }
})
export default class BlockedBalance extends Vue {
  @Prop({ type: Object, required: false, default: null })
  readonly blockedBalance!: NumberFormatted | null;

  @Prop({ type: Function, required: true })
  readonly setPanel!: () => {};

  readonly sidebarTypes = SidebarTypes;
  readonly NebraskaColors = NebraskaColors;

  public get blockedBalanceFormatted() {
    return this.blockedBalance?.formatted;
  }

  @Emit('handle-blocked-balance-modal')
  handleClick() {
    trackOpenBlockedBalanceModal();
  }
}
