import RecaptchaDisabled from './RecaptchaDisabled.vue';
import RecaptchaEnterprise from './RecaptchaEnterprise.vue';
import RecaptchaV2 from './RecaptchaV2.vue';

function getRecaptchaComponent() {
  if (process.env.VUE_APP_ENABLE_RECAPTCHA === 'false') {
    return RecaptchaDisabled;
  }

  if (process.env.VUE_APP_RECAPTCHA_ENTERPRISE === 'true') {
    return RecaptchaEnterprise;
  }

  return RecaptchaV2;
}

export interface IRecaptcha {
  onReset: () => void;
  onChallenge: (action?: string) => void;
}

export { default as RecaptchaActions } from './RecaptchaActions';
export const Recaptcha = getRecaptchaComponent();
