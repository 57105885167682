
import { Component, Vue, Prop } from 'vue-property-decorator';

import { useDashStore } from '../../store/DashModule';
import {
  NebraskaSizes,
  NebraskaColors
} from '@warrenbrasil/nebraska-tokens-web';

@Component
export default class Money extends Vue {
  @Prop({ type: String, default: '40%' })
  readonly width!: string;

  @Prop({ type: String, default: NebraskaSizes.Radius.x100 })
  readonly borderRadius!: string;

  private readonly dashModule = useDashStore();

  private get showMoney() {
    return this.dashModule?.showMoney;
  }

  get placeholderStyle() {
    return this.showMoney
      ? {}
      : {
          width: this.width,
          borderRadius: this.borderRadius,
          backgroundColor: NebraskaColors.elementDisabled
        };
  }

  get computedClass() {
    return {
      money__placeholder: this.showMoney
    };
  }
}
