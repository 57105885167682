import { render, staticRenderFns } from "./OrdersFilters.vue?vue&type=template&id=bcf9ce68&scoped=true&"
import script from "./OrdersFilters.vue?vue&type=script&setup=true&lang=ts&"
export * from "./OrdersFilters.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./OrdersFilters.vue?vue&type=style&index=0&id=bcf9ce68&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcf9ce68",
  null
  
)

export default component.exports