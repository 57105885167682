import { BFFErrorResponse } from '@/modules/trade/types';
import { BFFFormattedErrorResponse } from '@/modules/wealth/types';
import { isObject, isNumber, isString } from 'lodash-es';
import Joi from 'joi';

export function isStringRecord(
  value: unknown
): value is Record<string, unknown> {
  return isObject(value);
}

export function isBFFError(exception: unknown): exception is BFFErrorResponse {
  return (
    isStringRecord(exception) &&
    isStringRecord(exception.error) &&
    (isString(exception.error.code) || isNumber(exception.error.code)) &&
    isStringRecord(exception.error.message) &&
    isString(exception.error.message.formatted) &&
    (isString(exception.error.message.value) ||
      isNumber(exception.error.message.value))
  );
}

const bffFormattedSchema = Joi.object({
  error: Joi.object({
    code: Joi.number(),
    message: Joi.object({
      value: Joi.string(),
      formatted: Joi.string()
    }),
    formattedMessage: Joi.object({
      title: Joi.string(),
      description: Joi.string()
    })
  })
});

export function isBFFFormattedError(
  exception: unknown
): exception is BFFFormattedErrorResponse {
  const { error } = bffFormattedSchema.validate(exception);

  return Boolean(!error);
}
