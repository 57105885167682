import { RouteConfig } from 'vue-router';

import { beforeEnterTradeSubscription } from '@/modules/trade/routes/navigation-guards/subscription';
import SearchView from '@/modules/trade/views/SearchView/SearchView.vue';
import SearchListView from '@/modules/trade/views/SearchListView/SearchListView.vue';
import SearchSectionDetailView from '@/modules/trade/views/SearchSectionDetailView/SearchSectionDetailView.vue';
import SearchHighLightView from '@/modules/trade/views/SearchHighLightView/SearchHighLightView.vue';
import TradePositionsView from '@/modules/trade/views/TradePositionsView/TradePositionsView.vue';
import TradeView from '@/modules/trade/views/TradeView/TradeView.vue';
import SubscriptionView from '@/modules/trade/views/Subscription/SubscriptionView.vue';
import TradeInstrumentView from '@/modules/trade/views/Instrument/TradeInstrumentView.vue';
import OrdersView from '@/modules/trade/views/Orders/OrdersView.vue';
import CustomOperationDetailsView from '@/modules/trade/views/CustomOperationDetailsView/CustomOperationDetailsView.vue';
import PortabilityRequestDetails from '@/modules/trade/views/PortabilityRequestDetails/PortabilityRequestDetails.vue';
import PortabilityRequests from '@/modules/trade/views/PortabilityRequests/PortabilityRequests.vue';
import RetailLiquidityProvider from '@/modules/trade/views/RetailLiquidityProvider/RetailLiquidityProvider.vue';
import ProfitSubscription from '@/modules/trade/views/ProfitSubscription/ProfitSubscriptionView.vue';
import ProfitUnsubscribeView from '@/modules/trade/views/ProfitSubscription/ProfitUnsubscribeView.vue';
import MarginAllocation from '@/modules/trade/views/MarginAllocation/MarginAllocation.vue';

export const tradeRoutes: RouteConfig[] = [
  {
    name: 'trade',
    path: '/trade',
    component: TradeView,
    meta: {
      isHomePage: true,
      context: 'trade'
    }
  },
  {
    name: 'tradeInstrument',
    path: '/trade/instrument/:ticker',
    component: TradeInstrumentView,
    meta: {
      context: 'trade'
    }
  },
  {
    name: 'tradeSearch',
    path: '/trade/search',
    component: SearchView,
    meta: {
      context: 'trade'
    },
    alias: ['/trade/newSearch']
  },
  {
    name: 'tradeSearchList',
    path: '/trade/search/:ticker',
    component: SearchListView,
    meta: {
      context: 'trade'
    },
    alias: ['/trade/newSearchList/:ticker']
  },
  {
    name: 'tradeSectionDetail',
    path: '/trade/search/section/:id',
    component: SearchSectionDetailView,
    alias: ['/trade/sectionDetail/:id']
  },
  {
    name: 'tradeHighLightDetail',
    path: '/trade/search/highlight/:id',
    component: SearchHighLightView,
    meta: {
      context: 'trade'
    },
    alias: ['/trade/high-light-detail/:id']
  },
  {
    name: 'tradePositions',
    path: '/trade/positions',
    component: TradePositionsView,
    meta: {
      context: 'trade'
    }
  },
  {
    name: 'subscription',
    path: '/trade/subscription',
    component: SubscriptionView,
    beforeEnter: beforeEnterTradeSubscription,
    meta: {
      context: 'trade'
    }
  },
  {
    name: 'orders',
    path: '/trade/orders',
    component: OrdersView,
    meta: {
      context: 'trade'
    }
  },
  {
    name: 'customOperationDetails',
    path: '/trade/custom-operations/:id',
    component: CustomOperationDetailsView,
    meta: {
      context: 'trade'
    }
  },
  {
    name: 'portabilityRequestDetails',
    path: '/trade/portability/:id',
    component: PortabilityRequestDetails,
    meta: {
      context: 'trade'
    }
  },
  {
    name: 'portabilityRequests',
    path: '/trade/portability',
    component: PortabilityRequests,
    meta: {
      context: 'trade'
    }
  },
  {
    name: 'retailLiquidityProvider',
    path: '/trade/retail-liquidity-provider',
    component: RetailLiquidityProvider,
    meta: {
      context: 'trade'
    }
  },
  {
    name: 'profitSubscription',
    path: '/trade/profit-subscription',
    component: ProfitSubscription,
    meta: {
      context: 'trade',
      isPreview: false
    }
  },
  {
    name: 'profitPreview',
    path: '/trade/profit-subscription/:profit',
    component: ProfitSubscription,
    meta: {
      context: 'trade',
      isPreview: true
    }
  },
  {
    name: 'profitCancellation',
    path: '/trade/profit-unsubscribe',
    component: ProfitUnsubscribeView,
    meta: {
      context: 'trade'
    }
  },
  {
    name: 'marginAllocation',
    path: '/trade/margin-allocation',
    component: MarginAllocation,
    meta: {
      context: 'trade'
    }
  }
];
