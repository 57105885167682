import { StorageKeys } from '@/modules/common/services/local-storage';
import { WarrenSession } from '@/modules/common/services/session';
import { InstantLoginResponse } from '@/modules/common/services/http/account';

const setLoggedUser = (
  { user, brand }: InstantLoginResponse,
  accessToken?: string
) => {
  const customerId = user?._id;
  if (!customerId) {
    throw new Error('cannot set logged user');
  }

  WarrenSession.clean([StorageKeys.Features]);

  if (user.clientType.whitelabel) {
    WarrenSession.set(StorageKeys.WhitelabelTheme, 'whitelabel');
  }

  if (brand) {
    WarrenSession.set(StorageKeys.Brand, JSON.stringify(brand));
  }

  if (accessToken) WarrenSession.set(StorageKeys.AccessToken, accessToken);
  WarrenSession.set(StorageKeys.CustomerId, customerId);
};

export { setLoggedUser };
