
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { HeadingProductListItem } from '../HeadingProductListItem';
import { ProductAllocationCard } from '@/modules/common/components/cards/ProductAllocationCard';
import { trackDetailInstrument } from './track-events';
import {
  IProductAllocation,
  IProduct
} from '@/modules/wealth/services/investment';

@Component({
  components: { HeadingProductListItem, ProductAllocationCard }
})
export default class PortfolioProductsList extends Vue {
  @Prop({ type: Array, required: true })
  readonly allocations!: IProductAllocation[];

  @Emit('product-click')
  private emitProductClick(productApiId: string) {
    return productApiId;
  }

  isClickable(item: IProduct) {
    return !!item.instrumentDetailApiId;
  }

  handleProductClick(product: IProduct) {
    this.emitProductClick(product.instrumentDetailApiId);
    trackDetailInstrument(true, product.rootClassName, product.name);
  }
}
