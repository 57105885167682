
import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({})
export default class DataLabel extends Vue {
  @Prop({ type: String })
  protected label!: string;

  @Prop({ type: String })
  protected value!: string;
}
