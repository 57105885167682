
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Row, Heading, BaseText } from '@warrenbrasil/nebraska-web';
import { HandleSection } from '@/modules/trade/components/HandleSection';
import { InstrumentRow } from '@/modules/trade/components/InstrumentRow';

import {
  getWatchlist,
  InstrumentWithWatchlist
} from '@/modules/trade/services/instruments';
import {
  trackInstrumentWatchlistClick,
  trackViewAllWatchlist,
  trackWatchlistRequestError,
  trackWatchlistRetryClick
} from '@/modules/trade/views/TradeView/trackers';
import { logError } from '@/modules/trade/helpers/logs';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    Heading,
    BaseText,
    HandleSection,
    InstrumentRow,
    Row
  }
})
export default class WatchList extends Vue {
  @tradePositionsModule.State('userHasBrokerAccount')
  readonly userHasBrokerAccount!: boolean;

  @Prop({ type: Function })
  readonly setPanel!: () => {};

  @Prop({ type: Function, required: true })
  readonly setWatchlistSubscription!: () => {};

  @tradePositionsModule.Getter('getWatchlist')
  private watchlist!: InstrumentWithWatchlist[];

  @tradePositionsModule.Mutation('setWatchlist')
  private setWatchlist!: Function;

  error = false;
  hasMore = false;
  loading = false;

  created() {
    this.fetchWatchlistAndSubscribe();
  }

  async handleOnRetryClick() {
    trackWatchlistRetryClick();
    await this.fetchWatchlistAndSubscribe();
  }

  async fetchWatchlistAndSubscribe() {
    try {
      this.loading = true;
      this.error = false;

      const data = await getWatchlist({
        itemsPerPage: 5,
        page: 1
      });

      this.hasMore = data.pagination?.totalPages > data.pagination?.page;
      this.setWatchlist(data.items || []);
    } catch (e) {
      logError(e, 'request_watchlist_error');
      trackWatchlistRequestError();
      this.error = true;
    } finally {
      this.loading = false;
      if (this.userHasBrokerAccount) await this.setWatchlistSubscription();
    }
  }

  onInstrumentClick(instrument: InstrumentWithWatchlist) {
    trackInstrumentWatchlistClick(instrument.symbol, instrument.name || '');
    this.$router.push({
      name: 'tradeInstrument',
      params: { ticker: instrument.symbol }
    });
  }

  handleViewAllClick() {
    trackViewAllWatchlist();
    this.setPanel();
  }
}
