
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { RoundedIcon, NavBar } from '@warrenbrasil/nebraska-web';
import { NotificationTypes } from '@/modules/common/services/http/notification';

@Component({
  components: {
    RoundedIcon,
    NavBar
  }
})
export default class Notification extends Vue {
  private NebraskaColors = NebraskaColors;

  @Prop({ type: String, required: true })
  public group!: string;

  @Prop({ type: String, default: 'top center' })
  public position!: string;

  @Prop({ type: String, default: '100%' })
  public width!: string;

  private getColors(type: NotificationTypes) {
    switch (type) {
      case NotificationTypes.error:
        return {
          color: NebraskaColors.statusNegative,
          background: NebraskaColors.statusNegativeBackground
        };
      case NotificationTypes.success:
        return {
          color: NebraskaColors.statusPositive,
          background: NebraskaColors.statusPositiveBackground
        };
      case NotificationTypes.info:
        return {
          color: NebraskaColors.statusInfo,
          background: NebraskaColors.statusInfoBackground
        };
      case NotificationTypes.alert:
        return {
          color: NebraskaColors.statusAlert,
          background: NebraskaColors.statusAlertBackground
        };
      case NotificationTypes.primary:
      default:
        return {
          color: NebraskaColors.textNegative,
          background: NebraskaColors.neutralPrimary
        };
    }
  }

  private getTextColor(type: NotificationTypes) {
    return this.getColors(type).color;
  }

  private getBackgroundColor(type: NotificationTypes) {
    return this.getColors(type).background;
  }
}
