var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.HandleSection,{staticClass:"positions-summary",attrs:{"error":_setup.error,"loading":_setup.loading,"section-title":"Meus ativos","error-title":"Não foi possível carregar os seus ativos","refresh":_setup.fetchPositionsSummary}},[_c(_setup.Heading,{staticClass:"positions-summary__title",attrs:{"level":3}},[_vm._v("Meus ativos")]),(_setup.hasPositions)?[_vm._l((_setup.positionsSummary),function(position){return _c('div',{key:position.description.value},[(position.error)?_c(_setup.HeadingDisplay,{staticClass:"positions-summary__item--error",attrs:{"title":position.error.title,"subtitle":position.error.subtitle,"size":"extra-small"}}):_c('RouterLink',{attrs:{"to":{
          name: 'tradePositions',
          query: {
            group: position.description.value
          }
        },"aria-label":`Ver detalhes ${position.description.formatted}`}},[_c(_setup.Row,{staticClass:"positions-summary__row",attrs:{"has-interaction":"","chevron":""},scopedSlots:_vm._u([{key:"top-left",fn:function(){return [_c('p',{staticClass:"positions-summary__row__description",attrs:{"title":position.description.formatted}},[_vm._v(" "+_vm._s(position.description.formatted)+" ")])]},proxy:true},{key:"bottom-left",fn:function(){return [_c('p',{staticClass:"positions-summary__row__quantity",attrs:{"title":position.content.quantity.formatted}},[_vm._v(" "+_vm._s(position.content.quantity.formatted)+" ")])]},proxy:true},{key:"top-right",fn:function(){return [_c(_setup.Money,{attrs:{"width":"100%"}},[_c('p',{staticClass:"positions-summary__row__balance"},[_vm._v(" "+_vm._s(position.content.balance.formatted)+" ")])])]},proxy:true}],null,true)})],1)],1)}),(_setup.shouldRenderShowDetailsButton)?_c('RouterLink',{attrs:{"to":{
        name: 'tradePositions'
      },"aria-label":"Ver detalhes das posições"}},[_c(_setup.Row,{attrs:{"has-interaction":"","chevron":""},scopedSlots:_vm._u([{key:"top-left",fn:function(){return [_c('p',{staticClass:"positions-summary__text--bold"},[_vm._v("Ver detalhes")])]},proxy:true}],null,false,919147029)})],1):_vm._e()]:[_vm._t("empty-state",function(){return [_c(_setup.BaseText,{staticClass:"positions-summary__empty-text",attrs:{"color":_setup.NebraskaColors.textSecondary,"size":"lg"}},[_vm._v(" Você ainda não possui ativos. ")])]})],_c(_setup.Row,{staticClass:"positions-summary__row",attrs:{"left-title":"Ver minhas ordens","has-interaction":"","chevron":""},on:{"click":_setup.goToOrders},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c(_setup.InitialAvatarIcon,{attrs:{"icon":"EA0090","size":"extra-small"}})]},proxy:true}])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }