
import { Component, Vue } from 'vue-property-decorator';
import {
  NavBar,
  ButtonIconContextual,
  SpinnerLoader,
  HelperViewStatus,
  BaseText,
  ContextColorProvider
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { TwoColumnsContainer } from '@/modules/common/components/TwoColumnsContainer';
import { DonutChart } from '@/modules/common/components/charts/DonutChart';
import { GlobalAllocationProductList } from './components/GlobalAllocationProductList';
import {
  getAllocationsByProduct,
  IAllocationsByProductResponse
} from '@/modules/products/services/investments';
import { useDashStore } from '@/modules/common/store/DashModule';
import { ShowMoneyToggleButton } from '@/modules/common/components/buttons/ShowMoneyToggleButton';

@Component({
  components: {
    ContextColorProvider,
    NavBar,
    ButtonIconContextual,
    SpinnerLoader,
    HelperViewStatus,
    TwoColumnsContainer,
    DonutChart,
    BaseText,
    GlobalAllocationProductList,
    ShowMoneyToggleButton
  }
})
export default class GlobalAllocationView extends Vue {
  readonly NebraskaColors = NebraskaColors;
  readonly dashModule = useDashStore();
  productAllocations: IAllocationsByProductResponse | null = null;
  isLoading = false;
  hasError = false;

  get productsAllocationChartData() {
    return this.allocationProducts.map(product => ({
      label: product.investmentClassType.formatted,
      value: product.allocPercentage.value,
      color: product.typeColor
    }));
  }

  get allocationProducts() {
    return this.productAllocations?.products ?? [];
  }

  get updatedAt() {
    return this.productAllocations?.balances.lastModifiedAtDate.formatted ?? '';
  }

  get totalAllocation() {
    return this.productAllocations?.balances.totalBalance.formatted ?? '';
  }

  async created() {
    await this.getAllocationsByProduct();
  }

  async getAllocationsByProduct() {
    this.hasError = false;
    this.isLoading = true;
    try {
      this.productAllocations = await getAllocationsByProduct();
    } catch (e) {
      console.error(e);
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  onClickBack() {
    this.$router.back();
  }
}
