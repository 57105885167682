
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Row } from '@warrenbrasil/nebraska-web';

import { ISubscription } from '@/modules/trade/services/subscription';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    Row
  }
})
export default class SubscriptionList extends Vue {
  @tradePositionsModule.Getter('getSubscriptionList')
  readonly subscriptionList!: ISubscription[];
}
