import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import {
  IFilterCategories,
  EFilterCategoriesTypes,
  IFilterType
} from '../../services/types/models/IFilters';

interface setFilterCategory {
  filterType: IFilterType;
  categoryType: EFilterCategoriesTypes;
}

@Module({
  namespaced: true
})
export class FilterModule extends VuexModule {
  filtersList: IFilterCategories = {};

  searchingProducts = false;

  get isSearchingProducts() {
    return this.searchingProducts;
  }

  get filters() {
    return this.filtersList;
  }

  @Action({ rawError: true })
  public addFilterType({ filterType, categoryType }: setFilterCategory) {
    this.context.commit('addFilter', { filterType, categoryType });
  }

  @Action
  public setSearchingProducts(state: boolean) {
    this.context.commit('setSearching', state);
  }

  @Mutation
  addFilter({ filterType, categoryType }: setFilterCategory) {
    this.filtersList[categoryType] = filterType;
  }

  @Mutation
  setSearching(state: boolean) {
    this.searchingProducts = state;
  }
}
