
import { Component, Vue, Prop } from 'vue-property-decorator';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  BaseText,
  ButtonIconContextual,
  ListDisplay
} from '@warrenbrasil/nebraska-web';
import { OrderType } from '../../types';
import { useDashStore } from '@/modules/common/store/DashModule';
import { NumberFormatted } from '@/modules/trade/types';

@Component({
  components: {
    BaseText,
    ButtonIconContextual,
    ListDisplay
  }
})
export default class AvailableBalance extends Vue {
  private readonly dashModule = useDashStore();

  @Prop({ type: Object, required: true })
  readonly balanceAvailable?: NumberFormatted;

  @Prop({ type: Boolean, default: false })
  readonly isRow!: boolean;

  @Prop({ type: String, default: '6' })
  readonly titleLevel!: string;

  @Prop({ type: String, default: '3' })
  readonly balanceLevel!: string;

  @Prop({ type: Number, default: 0 })
  readonly quantityAvailable!: number;

  @Prop({ type: Number, required: true })
  readonly orderType!: OrderType;

  readonly OrderTypes = OrderType;
  readonly NebraskaColors = NebraskaColors;

  get isValidSell() {
    return this.orderType === OrderType.Sell && this.quantityAvailable > 0;
  }

  get computedBalanceValue() {
    if (this.isValidSell) {
      return `${this.quantityAvailable} ativos`;
    } else if (this.orderType === OrderType.Buy) {
      return this.balanceAvailable?.formatted || '-';
    }

    return '';
  }

  get shouldShowMoney() {
    return this.dashModule.showMoney;
  }

  get hasNotAvailableBalance() {
    return !this.balanceAvailable?.value || this.balanceAvailable?.value <= 0;
  }

  get getDirectionOfRow() {
    const className = 'transaction_unrealized_balance';
    if (this.isRow) {
      return `${className}-row`;
    } else {
      return `${className}-column`;
    }
  }

  get getBalanceColor() {
    if (this.isRow) {
      return NebraskaColors.textSecondary;
    } else {
      return NebraskaColors.backgroundOverlay;
    }
  }

  get getTitleFont() {
    return [`text-p-${this.titleLevel}`, 'available-balance__text'];
  }

  get getBalanceFont() {
    return `text-p-${this.balanceLevel}`;
  }
}
