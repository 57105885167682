
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Section extends Vue {
  @Prop({ type: String, required: true })
  private title!: string;

  @Prop({ type: String, required: false })
  private subtitle?: string;

  @Prop({ type: String, default: '3' })
  private size!: string;

  private get computedFontTitle() {
    return `text-h-${this.size}`;
  }
}
