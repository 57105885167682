import { render, staticRenderFns } from "./BlockedBalance.vue?vue&type=template&id=793a22b0&scoped=true&"
import script from "./BlockedBalance.vue?vue&type=script&lang=ts&"
export * from "./BlockedBalance.vue?vue&type=script&lang=ts&"
import style0 from "./BlockedBalance.vue?vue&type=style&index=0&id=793a22b0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793a22b0",
  null
  
)

export default component.exports