
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import {
  ButtonPrimary,
  Heading,
  BaseText,
  ButtonIconPrimary
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { moneyViewer as money } from '@/modules/common/helpers/currency';
import { MatchMedia } from '@/modules/common/components/MatchMedia';

@Component({
  name: 'Footer',
  components: {
    ButtonPrimary,
    Heading,
    BaseText,
    ButtonIconPrimary,
    MatchMedia
  },
  filters: { money }
})
export default class Footer extends Vue {
  public NebraskaColors = NebraskaColors;
  @Prop({ type: Boolean, default: false })
  public disabled?: boolean;

  @Prop({ type: Boolean, default: false })
  public isLoading!: boolean;

  @Prop({ type: Number, required: true })
  public investmentValue!: number;

  @Prop({ type: String, required: false })
  public errorMessage?: string;

  @Emit()
  public onButtonClick() {}
}
