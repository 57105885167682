enum RecaptchaActions {
  SIGN_UP = 'signup',
  SIGN_IN = 'login',
  RESET_PASSWORD = 'password_update',
  SIGN_UP_EMAIL = 'signup',
  ADVISOR_LIST = 'get_advisor',
  PROSPECT_STATUS = 'get_prospect_status'
}

export default RecaptchaActions;
