export const data = {
  title: 'Bolsa',
  head: 'Compre e venda ações na Bolsa',
  description:
    'Amplie seu portfólio em renda variável e rendimentos de longo prazo.',
  primaryButtonLabel: 'Habilitar Bolsa',
  secondaryButtonLabel: 'Explorar sem habilitar',
  sections: [
    {
      icon: 'BA0100',
      title: 'Compre e venda ações, ETFs, FIIs e BDRs',
      message: 'Diversifique seu patrimônio através de renda variável.'
    },
    {
      icon: 'BB0010',
      title: 'Invista nas maiores empresas',
      message:
        'Quer elevar seus rendimentos sendo sócio de empresas que você admira? Agora você pode!'
    }
  ]
};
