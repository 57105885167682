
import { Component, Vue } from 'vue-property-decorator';
import { NavBar, IconButton } from '@warrenbrasil/nebraska-web';
import { OrderList } from './components/OrderList';

@Component({
  components: {
    NavBar,
    IconButton,
    OrderList
  }
})
export default class WealthOrdersView extends Vue {
  portfolioId: string | undefined = undefined;

  created() {
    this.portfolioId = this.$route.query.portfolioId as string;
  }

  goBack() {
    this.$router.back();
  }
}
