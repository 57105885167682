
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class HandleSection extends Vue {
  @Prop({ type: Boolean, required: true })
  public loading!: boolean;

  @Prop({ type: Boolean, required: true })
  public error!: boolean;
}
