
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { BaseIcon } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: { BaseIcon }
})
export default class SearchInput extends Vue {
  @Prop({ type: String, default: 'Buscar' })
  private placeholder!: string;

  @Prop({ type: String, default: NebraskaColors.textAlpha })
  private inputColor!: string;

  @Prop({ type: String, default: NebraskaColors.textPlaceholder })
  private iconColor!: string;

  @Prop({ type: String })
  private value!: string;

  // tslint:disable-next-line:no-empty
  @Prop({ type: Function, default: () => {} })
  private onEnterPress!: () => {};

  @Prop({ type: Boolean, default: false })
  private isFocus!: boolean;

  @Ref()
  private search!: HTMLInputElement;

  private NebraskaColors = NebraskaColors;
  private setFocus() {
    this.$nextTick(() => this.search.focus());
  }

  private created() {
    if (this.isFocus) {
      this.setFocus();
    }
  }
}
