export enum InstrumentClass {
  Fixedincome = 'FIXEDINCOME',
  Variableincome = 'VARIABLEINCOME',
  Others = 'OTHERS'
}

export enum AllocationType {
  FIXED_INCOME = 'Renda Fixa',
  VARIABLE_INCOME = 'Renda Variável',
  STOCKS = 'Ações',
  OTHERS = 'Outros',
  MULTIMARKET = 'Multimercado'
}

export enum InvestorType {
  NONE = 'NONE',
  COMMON = 'COMMON',
  QUALIFIED = 'QUALIFIED',
  PROFESSIONAL = 'PROFESSIONAL'
}

export enum AllocationBuilderType {
  FIXEDINCOME = 'FIXEDINCOME',
  VARIABLEINCOME = 'VARIABLEINCOME',
  OTHERS = 'OTHERS',
  NOTALLOCATED = 'NOTALLOCATED'
}

export enum EditorActions {
  REMOVE = 'REMOVE',
  ADD = 'ADD',
  UPDATE = 'UPDATE'
}

export enum customerStatus {
  overdue = 'overdue',
  upToDate = 'upToDate'
}

export enum RiskGrade {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export enum PerformanceBenchmark {
  CDI = 'CDI',
  IPCA = 'IPCA',
  PORTFOLIO = 'Retorno Absoluto'
}

export enum BenchmarkPeriod {
  CurrentYear = 'No ano',
  TwoYears = '24 meses',
  OneYear = '12 meses',
  Specific = 'Específico'
}

export enum RiskPortfolioGrades {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export enum NotificationTypes {
  error = 'error',
  success = 'success',
  info = 'info',
  primary = 'primary',
  alert = 'alert'
}

export enum AllocationTypeColor {
  FIXED_INCOME = '#006BD6',
  VARIABLE_INCOME = '#FFB500',
  STOCKS = '#FFB500',
  OTHERS = '#EE2E5D',
  MULTIMARKET = '#EF6696',
  NOT_ALLOCATED = '#B9BAC7'
}

export enum TypeColorClass {
  FIXED_INCOME = 'wrn-color-8',
  VARIABLE_INCOME = 'wrn-color-yellow',
  STOCKS = 'wrn-color-yellow',
  OTHERS = 'wrn-color-main',
  MULTIMARKET = 'wrn-color-2',
  NOT_ALLOCATED = 'wrn-color-gray'
}

export enum EWalletTemplateApplyType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export interface BenchmarkFilterOptions {
  marketIndex: PerformanceBenchmark;
  period: BenchmarkPeriod;
  startDate: Date;
  endDate: Date;
}

export interface Assets {
  name: string;
  total?: number;
  type: InstrumentClass;
}

export interface Summary {
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  assets?: Assets[];
}

export interface ProductAllocationEdit {
  allocation: SubTypeAllocations | InstrumentSummary;
  subType: string;
  allocationLock: Function;
}

export interface BeforeAfterRow {
  label: string;
  value: string;
  isRisk?: boolean;
  isChanged?: boolean;
}

export interface BeforeAfterRows {
  after: BeforeAfterRow[];
  before: BeforeAfterRow[];
}

export interface ConfirmResponse {
  updateValue: SubTypeAllocations;
  remove: boolean;
  update: boolean;
  removeEditor?: boolean;
  add?: boolean;
  simulate?: {
    addProduct: boolean;
    removeProduct: boolean;
    changeAllocation: boolean;
  };
}

export interface ActionProductSimulation {
  plan: PlanFormatted;
  allocations: AllocationsFormatted[];
}

export interface PlanFormatted {
  minimumInitialInvestment: ValueFormatted<number>;
  minimumFinancialMovement: ValueFormatted<number>;
  maxLiquidationDays: ValueFormatted<number>;
  nextInvestment: ValueFormatted<number>;
  risk: ValueFormatted<RiskPortfolioGrades>;
}

export interface ValueFormatted<T> {
  value: T;
  formatted: string;
}

export interface AllocationsFormatted {
  isRoot: boolean;
  id: string;
  name: string;
  type: ValueFormatted<AllocationBuilderType>;
  subType: string;
  value: ValueFormatted<number>;
  isLocked: boolean;
  percent: ValueFormatted<number>;
  apiId: string;
  minimumFinancialMovement: ValueFormatted<number>;
  liquidity: ValueFormatted<number>;
  performance?: ValueFormatted<number>;
  totalBalance?: ValueFormatted<number>;
}

export interface InstrumentSummary {
  instrumentBoxApiId: string;
  category: InstrumentClass;
  subCategory: string;
  instrumentDetailApiId: string;
  name: string;
  minimumFinancialMovement: number;
  minimumInitialInvestment: number;
  riskScore: number;
  daysToExpiration: number;
  riskGrade: RiskGrade;
  liquidity: ValueFormatted<number>;
  profitability: string;
  investorType: InvestorType;
  indexName: string;
  benchmarkFormatted: string;
}

export interface IInstrumentsListSelected {
  products: Product[];
}

export interface Product {
  id: string;
  name: string;
  type: Value;
  risk: Value;
  rentability: string;
  minimumInitialInvestment: Value;
  minimumFinancialMovement: Value;
  expiresIn: string;
  redeemAt: string;
  operationHours: string;
  withdrawPeriod: { label: string; value: string };
}

export interface Value {
  value: string;
  formatted: string;
}

export interface Chart {
  label: string;
  value: number;
}

export interface Allocations {
  allocValue?: any;
  id: string;
  name: string;
  type: string;
  value: number;
  isLocked: boolean;
  percent: number;
  apiId: string;
  boxApiId: string;
}

export interface SubTypeAllocations {
  name?: string;
  type?: ValueFormatted<AllocationBuilderType>;
  allocValue?: ValueFormatted<number>;
  isLocked?: boolean;
  apiId?: string;
  maxPercentage?: ValueFormatted<number>;
}

export interface IInvestmentProfile {
  customer?: string;
  monthlyIncome?: number;
  deposit?: number;
  totalInvested?: number;
  isQualifiedInvestor?: boolean;
  isProfessionalInvestor?: boolean;
  computedRiskTolerance?: number;
  computedProfileType?: string;
  computedCustomerPrediction?: number;
  riskProfile?: string;
  riskToleranceProfile?: string;
  computedRiskToleranceString?: string;
  computedRiskToleranceLabel?: string;
}

export interface ICreatePortfolioBody {
  name?: string;
  goal: string;
  goalAmount?: number;
  monthlyInvestment?: number;
  status: string;
  goalDate?: string;
  initialInvestment: number;
  isEdited: boolean;
  isCustom: boolean;
  isShared: boolean;
  managedPortfolio: string;
  allocations: IAllocation[];
}

export interface IAllocation {
  type?: AllocationType;
  apiId?: string;
  allocValue: number;
  allocations?: IAllocation[];
  productBox?: IAllocation;
  instruments?: IAllocation[];
}
export interface ChartData {
  label: string;
  value: number;
  color?: string;
  customStartAngle?: number;
  customEndAngle?: number;
  displayValue?: string;
}

export interface IPortfolioTemplateAllocations {
  boxId: string;
  description: string;
  instrumentAllocationValue: number;
  instrumentId: string;
  type: string;
}

export interface IPhotoUrls {
  raw: string;
  full: string;
  regular: string;
  small: string;
  thumb: string;
}

export interface ICreatePortfolioTemplate {
  portfolioTemplateAllocations: IPortfolioTemplateAllocations[];
  customer: string;
  name: string;
  initialInvestment: number;
  monthlyInvestment: number;
  applyType: EWalletTemplateApplyType;
  background?: IPhotoUrls;
}

export interface Formatted<T, G = string> {
  value: T;
  formatted: G;
}

export type NumberFormatted = Formatted<number>;

export type StringFormatted = Formatted<string>;

export interface AllocationBySubtype {
  name?: string | null;
  allocations?: Array<Allocations | null> | null;
}

export interface ChartResume {
  label?: string | null;
  value?: number | null;
  subtypes?: Array<AllocationBySubtype | null> | null;
}

export interface SubTypeAllocationsResume {
  name?: string | null;
  type?: StringFormatted | null;
  allocValue?: StringFormatted | null;
  isLocked?: boolean | null;
  apiId?: string | null;
}

export interface SubTypeResume {
  name?: string | null;
  allocations?: Array<SubTypeAllocationsResume | null> | null;
}

export interface ChartResumeFormatted {
  label?: StringFormatted | null;
  allocValue?: StringFormatted | null;
  subType?: Array<SubTypeResume | null> | null;
}
export interface InstrumentRiskDependent {
  apiId?: string | null;
  name?: string | null;
  riskScore?: number | null;
  riskGrade?: RiskGrade | null;
}

export interface InstrumentTermDependent {
  apiId?: string | null;
  name?: string | null;
  documents?: Array<InstrumentDetailContent | null> | null;
}

export interface InstrumentDetailContent {
  title?: string | null;
  description?: string | null;
  url?: string | null;
}

export enum OptionType {
  ManagedPortfolio = 'MANAGED_PORTFOLIO',
  CustomPortfolio = 'CUSTOM_PORTFOLIO'
}

export interface WalletResume {
  goalName?: string | null;
  imageURI?: string | null;
  minInitialInvestment?: NumberFormatted | null;
  minFinancialMovement?: NumberFormatted | null;
  maxLiquidity?: NumberFormatted | null;
  riskType?: StringFormatted | null;
  goalDate?: StringFormatted | null;
  goalAmount?: NumberFormatted | null;
  allocations?: Array<Allocations | null> | null;
  allocationCategories?: Array<Allocations | null> | null;
  chart?: Array<ChartResume | null> | null;
  chartFormatted?: Array<ChartResumeFormatted | null> | null;
  dependentOnRisks?: Array<InstrumentRiskDependent | null> | null;
  requestRisks?: boolean | null;
  dependentOnTerms?: Array<InstrumentTermDependent | null> | null;
  requestTerms?: boolean | null;
  portfolioType?: OptionType | null;
}
