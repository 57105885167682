/**
 * @param value - A number between 0 and 1
 * @param precision - How many decimal places you want (default is 2)
 */
export function round(value: number, precision = 2) {
  const factor = Math.pow(10, precision);
  const tempNumber = value * factor;
  const roundedTempNumber = Math.round(tempNumber);

  return roundedTempNumber / factor;
}

/**
 * @param  {number} value - A number between 0 and 1
 * @param  {number} precision -  How many decimal places
 * @returns number - Returns the percentage number truncated, obeying the precision
 */
export function percentageTruncate(value: number, precision: number): number {
  if (!Number.isFinite(value) || isNaN(value)) return 0;
  return parseFloat((value * 100).toFixed(precision));
}

/**
 * @param  {number} value - A number between 0 and 1
 * @param  {number} precision -  How many decimal places
 * @returns number - Returns the percentage number truncated, obeying the precision
 * If your tests did not passed please read this https://stackoverflow.com/questions/23199909/using-tolocalestring-in-node-js/23200062#23200062
 */
export function percentageChartTruncate(value: number): string {
  const valueMultiplied: number = percentageTruncate(value, 2);
  return simplePercentage(valueMultiplied, 2, 2);
}

/**
 * @param value - A number between 0 and 1
 * @param precision - How many decimal places you want (default is 2)
 * @return Number multiplied by 100 and rounded with precision
 */
export function percentage(value: number, precision?: number) {
  return round(round(value, precision) * 100);
}

/**
 * @param value - A number between 0 and 1
 * @param precisionMin - How many decimal places you want at least (default is 0)
 * @param precisionMax - How many decimal places you want at maximum (default is 2)
 * @param signal - If the final value must show '+' or '-', depending on the sign
 * @return String of a number multiplied by 100, with decimal places and a '%' symbol
 */
export function formattedPercentage(
  value: number,
  precisionMin = 0,
  precisionMax = 2
): string {
  return simplePercentage(value * 100, precisionMin, precisionMax);
}

/**
 * @param value - A number between 0 and 1
 * @param precisionMin - How many decimal places you want at least (default is 0)
 * @param precisionMax - How many decimal places you want at maximum (default is 2)
 * @return String of a number with decimal places, sign and a '%' symbol
 */
export function percentageWithSign(
  value: number,
  precisionMin = 0,
  precisionMax = 2
): string {
  let signChar;
  switch (Math.sign(value)) {
    case -1:
      signChar = '-';
      break;
    case 1:
      signChar = '+';
      break;
    default:
      signChar = '';
  }

  return `${signChar}${simplePercentage(
    Math.abs(value),
    precisionMin,
    precisionMax
  )}`;
}

/**
 * @param value - A number ready for use
 * @param precisionMin - How many decimal places you want at least (default is 0)
 * @param precisionMax - How many decimal places you want at maximum (default is 2)
 * @return String of a number, with decimal places '%' symbol
 */
export function simplePercentage(
  value: number,
  precisionMin = 0,
  precisionMax = 2
): string {
  if (value === null) {
    return '';
  }

  const valueFormatted = value.toLocaleString('pt-br', {
    minimumFractionDigits: precisionMin,
    maximumFractionDigits: precisionMax
  });

  return `${valueFormatted}%`;
}
