
import { Component, Prop, Provide } from 'vue-property-decorator';

import { IWalletCreateProvider } from './types/IWalletCreateProvider';

import ProviderError from './ProviderError.vue';
import WalletProvider from './WalletProvider.vue';
import { getURLParams } from '../helpers';

@Component({
  components: { ProviderError }
})
export default class WalletCreateProvider
  extends WalletProvider
  implements IWalletCreateProvider
{
  @Prop({ type: Boolean, default: false, required: false })
  public isPartner!: boolean;

  @Prop({ type: Boolean, default: false })
  private isTemplate!: boolean;

  @Provide('resetWallet')
  public async resetWallet() {
    if (!this.allocations) return;
    try {
      await this.bff.resetPortfolio();
    } catch {
      this.setError('', true, this.resetWallet);
      return false;
    } finally {
      this.getLastState();
    }
  }

  @Provide('createWallet')
  public async createWallet(name: string) {
    try {
      const result = await this.bff.createWallet(name, this.isTemplate);

      const urlParams = getURLParams(true);
      await this.bff.resetPortfolio();
      if (urlParams?.backTo) {
        let portfolioIdParam = '';
        if (urlParams?.fromCustomerSuggestion)
          portfolioIdParam = `&portfolioId=${result.id}`;
        window.location.replace(
          `${urlParams.backTo}?created=true${portfolioIdParam}`
        );
        return;
      }

      this.$router.replace({
        name: 'wealthWalletCreated',
        params: { id: result.id },
        query: { portfolioname: result.name }
      });
    } catch (error) {
      console.log(error);
    }
  }

  public created() {
    this.getLastState();
  }
}
