
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  NavBar,
  ButtonIconFoundation,
  InitialAvatarIcon,
  Heading,
  BaseText,
  HelperViewStatus,
  ButtonContainer,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { EFeedbackType } from '@/modules/transaction/views/NewTransfer/helpers/types';
@Component({
  components: {
    NavBar,
    ButtonIconFoundation,
    InitialAvatarIcon,
    Heading,
    BaseText,
    HelperViewStatus,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary
  }
})
export default class NewTransferFeedback extends Vue {
  @Prop({ type: String, required: true })
  readonly feedbackType!: EFeedbackType;

  @Emit('reload')
  emitReload() {
    // Em caso de Erro, emite um evento para chamar a rota novamente
  }

  readonly NebraskaColors = NebraskaColors;

  get isWithoutBank() {
    return this.feedbackType === EFeedbackType.WITHOUT_BANK;
  }

  get isGenericError() {
    return this.feedbackType === EFeedbackType.GENERIC_ERROR;
  }

  get handleError() {
    return {
      status: 'alert',
      title: 'Houve um problema ao carregar os dados de conta',
      description: 'Aguarde um momento e tente novamente',
      buttonLabel: 'Tentar novamente'
    };
  }

  get closeButtonColors() {
    return {
      background: NebraskaColors.elementSecondary,
      content: NebraskaColors.elementPrimary,
      alphas: {
        default: 0,
        hover: 0.08,
        active: 0.08
      }
    };
  }

  get buttonPrimaryLabel() {
    return this.isWithoutBank
      ? 'Ir para Dados Cadastrais'
      : 'Voltar para a home';
  }

  buttonAction() {
    return this.isWithoutBank ? this.goUserAccount() : this.goHome();
  }

  goHome() {
    this.$router.push({ name: 'home' });
  }

  goUserAccount() {
    this.$router.push({ name: 'user-account' });
  }

  goBack() {
    this.$router.back();
  }
}
