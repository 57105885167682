import {
  HttpClient,
  BaseHttpClient
} from '@/modules/common/services/http/__deprecated__/base-http-client';
import {
  IPension,
  IPensionLead,
  IPensionContractDetails,
  IPensionSimulationConfig,
  IPensionSimulationData,
  IPensionSimulationResult
} from '@/modules/pension/types';

type ResponseInstrumentDetails =
  | IPensionContractDetails[]
  | { errors: any[]; type: string };

export class PensionAPI extends BaseHttpClient {
  constructor() {
    super(undefined, process.env.VUE_APP_PENSION_API_URL);
  }

  public async getInstrumentDetails(productApiId: string) {
    return this.get<ResponseInstrumentDetails>(
      `/v1/instrument/${productApiId}/details`
    );
  }

  public async getPensionInfos(customerId: string, portfolioId: string) {
    return this.get<IPension>(
      `/v1/customer/${customerId}/pension/${portfolioId}/details`
    );
  }

  public async getSimulatorConfig() {
    return this.get<IPensionSimulationConfig>(
      '/api/v1/pension/simulator/config'
    );
  }

  public async simulate(body: IPensionSimulationData) {
    return this.post<IPensionSimulationResult>(
      '/api/v1/pension/simulator/simulate',
      body
    );
  }

  public async pensionNotify(body: IPensionLead): Promise<{}> {
    return HttpClient.post<any>('/api/v3/notifications/pension', body);
  }
}
