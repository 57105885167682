export interface INotification {
  _id: string;
  type: string;
  title: string;
  body: string;
  createdAt: string;
  isRead: boolean;
  readAt: string;
  customer: string;
  portfolio: string;
  url?: string;
}

export interface INotificationList {
  logs?: INotification[];
  hasEnded?: boolean;
}

export interface INotificationCount {
  unreadLogsCount: number;
  readLogsCount: number;
}

export enum NotificationTypes {
  error = 'error',
  success = 'success',
  info = 'info',
  primary = 'primary',
  alert = 'alert'
}
