
import { Component, Vue } from 'vue-property-decorator';
import { isThemeWarrenManaged } from '@/modules/common/services/theme';

@Component
export default class HomeIllustration extends Vue {
  hasError = false;
  hasLoaded = false;

  get classes() {
    return [
      'home-illustration',
      {
        'home-illustration--partner': this.isThemeWarrenManaged,
        'home-illustration--loaded': this.hasLoaded
      }
    ];
  }

  handleLoad() {
    this.hasError = false;
    this.hasLoaded = true;
  }

  handleError() {
    this.hasError = true;
  }

  private get isThemeWarrenManaged() {
    return isThemeWarrenManaged();
  }
}
