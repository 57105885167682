import { NavigationGuard } from 'vue-router';

import { CustomerModule } from '@/modules/common/store/CustomerModule';

export const beforeEnterIncomeTaxCalculator: NavigationGuard = (
  _to,
  _from,
  next
) => {
  const isCustomerPJ = CustomerModule.isCustomerPJ;
  const isImpersonated = CustomerModule.isImpersonated;

  if (isCustomerPJ || isImpersonated) {
    return next({ name: 'home' });
  }

  next();
};
