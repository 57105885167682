
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseText, InputMoney } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { MatchMedia } from '@/modules/common/components/MatchMedia';

@Component({
  components: { BaseText, MatchMedia, InputMoney }
})
export default class ProductToWithdrawCol extends Vue {
  public readonly NebraskaColors = NebraskaColors;

  @Prop({ type: String, required: false, default: '' })
  readonly labelMobile!: string;

  @Prop({ type: String, required: true })
  readonly value!: string;

  @Prop({ type: Boolean, required: true, default: true })
  readonly available!: boolean;
}
