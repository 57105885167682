export interface ForceDownloadOptions {
  data: BlobPart;
  type: string;
  fileName: string;
}

export const forceFileDownload = (options: ForceDownloadOptions) => {
  const url = window.URL.createObjectURL(
    new Blob([options.data], { type: options.type })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('style', 'display: none');
  link.setAttribute('download', options.fileName);
  document.body.appendChild(link);
  link.click();
};

export const forceDownloadHref = (href: string, fileName: string) => {
  const downloadLink = document.createElement('a');

  downloadLink.href = href;
  downloadLink.download = fileName;
  downloadLink.click();
};
