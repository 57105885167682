import { MoneyCell } from '@/modules/common/components/MoneyCell';
import { DataTableProps } from '@warrenbrasil/nebraska-web';

export interface IDataTableProps {
  name: string;
  subClass: string;
  initialDate: string;
  withdrawPeriod: string;
  risk?: string;
  totalBalance?: string;
  percent: string;
}

export enum ERisk {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

export const columns: DataTableProps.Column[] = [
  {
    label: 'Ativo',
    width: '35%',
    getter: row => ({
      text: row.name,
      description: row.subClass
    })
  },
  {
    label: 'Data de compra',
    width: '5%',
    align: DataTableProps.Align.Right,
    getter: 'initialDate'
  },
  {
    label: 'Liquidez / Vencimento',
    width: '5%',
    align: DataTableProps.Align.Right,
    getter: 'withdrawPeriod'
  },
  {
    label: 'Risco',
    width: '10%',
    align: DataTableProps.Align.Right,
    getter: ({ risk }) => ({
      text: formattedRisk(risk)
    })
  },
  {
    label: 'Saldo total',
    align: DataTableProps.Align.Right,
    component: MoneyCell,
    getter: ({ totalBalance }) => ({
      text: totalBalance
    })
  },
  {
    label: 'Peso',
    width: '12%',
    align: DataTableProps.Align.Right,
    getter: 'percent'
  }
];

function formattedRisk(risk: string) {
  switch (risk) {
    case ERisk.HIGH:
      return 'Alto';
    case ERisk.MEDIUM:
      return 'Médio';
    case ERisk.LOW:
    default:
      return 'Baixo';
  }
}
