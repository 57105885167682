
import { Component, Vue, Prop } from 'vue-property-decorator';

import { InstrumentAvatar } from '@/modules/trade/components/InstrumentAvatar';

import { InstrumentType } from '@/modules/trade/types';
import { getInstrumentSymbolPreffix } from '@/modules/trade/helpers/instruments';

export enum InstrumentNameSizes {
  SMALL = 'small',
  REGULAR = 'regular'
}

@Component({
  components: {
    InstrumentAvatar
  }
})
export default class InstrumentName extends Vue {
  @Prop({ type: String, required: true })
  readonly symbol!: string;

  @Prop({ type: String, required: false })
  readonly title?: string;

  @Prop({ type: String, required: false })
  readonly name?: string;

  @Prop({ type: String, required: false })
  readonly type?: InstrumentType;

  @Prop({ type: String, default: InstrumentNameSizes.REGULAR })
  readonly size!: string;

  @Prop({ type: Boolean, default: false })
  readonly hasEllipsisOnName!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hasEllipsisOnSymbol!: boolean;

  get computedWrapperClass() {
    return ['instrument-name', `instrument-name--${this.size}`];
  }

  get computedTextPrimary() {
    return [
      'instrument-name__text--primary',
      { 'instrument-name__text--ellipsis': this.hasEllipsisOnSymbol }
    ];
  }

  get computedTextSecondary() {
    return [
      'instrument-name__text--secondary',
      { 'instrument-name__text--ellipsis': this.hasEllipsisOnName }
    ];
  }

  get symbolPreffix() {
    return getInstrumentSymbolPreffix(this.symbol);
  }

  get avatarSize() {
    switch (this.size) {
      case InstrumentNameSizes.SMALL:
        return 'extra-small';
      case InstrumentNameSizes.REGULAR:
      default:
        return 'medium';
    }
  }
}
