
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import {
  BaseText,
  BaseIcon,
  InitialAvatarImage,
  InitialAvatarIcon
} from '@warrenbrasil/nebraska-web';
import { InstrumentAvatarFallback } from '@/modules/trade/components/InstrumentAvatarFallback';

import { InstrumentType } from '@/modules/trade/types';
import { getInstrumentSymbolPreffix } from '@/modules/trade/helpers/instruments';
import { InstrumentSelectionOptions } from '../../types';

@Component({
  components: {
    InitialAvatarImage,
    BaseText,
    BaseIcon,
    InstrumentAvatarFallback
  }
})
export default class InstrumentOption extends Vue {
  @Prop({ type: String, required: true })
  readonly symbol!: string;

  @Prop({ type: String, required: false })
  readonly name?: string;

  @Prop({ type: String, required: false })
  readonly type?: InstrumentType;

  @Prop({ type: [String, Number], required: true })
  readonly value!: string | number;

  @Prop({ type: Boolean, required: true })
  readonly isSelected!: boolean;

  @Emit('on-select')
  emitOnSelect() {
    return this.value;
  }

  get optionLabel() {
    let label = this.symbol;
    if (this.name) {
      label += ` · ${this.name}`;
    }

    return label;
  }

  get iconComponent() {
    if (this.value === InstrumentSelectionOptions.All) {
      return {
        component: InitialAvatarIcon,
        props: {
          icon: 'BA0130',
          size: 'extra-small',
          class: ['instrument-option__logo', 'instrument-option__logo--neutral']
        }
      };
    }

    return {
      component: InitialAvatarImage,
      props: {
        image: getInstrumentSymbolPreffix(this.symbol),
        alt: '',
        class: 'instrument-option__logo',
        size: 'extra-small'
      }
    };
  }
}
