import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IPasswordValidationRequest,
  IPasswordValidationResponse,
  ProspectSignUp,
  ProspectPassword,
  Advisor,
  IAdvisors,
  IProspectStatus
} from '../types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/onboarding`
});

export function passwordVerify(
  data: IPasswordValidationRequest
): Promise<IPasswordValidationResponse> {
  return http.post('/account/verify-password', data);
}

export function prospectSignUp(
  data: ProspectSignUp,
  recaptchaToken: string,
  queryParams: Record<string, any>
): Promise<void> {
  const queryString = Object.keys(queryParams)
    .map(
      key =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join('&');
  const url = `/prospect/signup?${queryString}`;

  return http.post(url, data, {
    headers: {
      'recaptcha-token': recaptchaToken
    }
  });
}

export function prospectRegisterPassword(
  data: ProspectPassword,
  recaptchaToken: string
): Promise<void> {
  return http.post('/prospect/password', data, {
    headers: {
      'recaptcha-token': recaptchaToken
    }
  });
}

export function getAdvisor(advisorEmail: string): Promise<Advisor> {
  return http.get(`/advisor?advisor=${advisorEmail}`);
}

export function getAdvisorList(
  recaptchaToken: string,
  search: string,
  page: number
): Promise<IAdvisors> {
  return http.get(`/advisor/filter`, {
    params: { search, page },
    headers: {
      'recaptcha-token': recaptchaToken
    }
  });
}

export function getProspectStatus(
  email: string,
  recaptchaToken: string
): Promise<IProspectStatus> {
  return http.get(`/prospect/prospect-status?email=${email}`, {
    headers: {
      'recaptcha-token': recaptchaToken
    }
  });
}
