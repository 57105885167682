
import { Component, Vue } from 'vue-property-decorator';
import { MultiFactorAuthentication } from './components/MultiFactorAuthentication';

@Component({
  components: {
    MultiFactorAuthentication
  }
})
export default class SecurityView extends Vue {}
