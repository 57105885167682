export const calculateChartHeight = (height: number): string => {
  switch (true) {
    case height >= 1000:
      return '400px';
    case height >= 900:
      return '380px';
    case height >= 721:
      return '280px';
    case height > 650:
      return '190px';
    default:
      return '150px';
  }
};
