import { render, staticRenderFns } from "./WealthContent.vue?vue&type=template&id=4acd00db&scoped=true&"
import script from "./WealthContent.vue?vue&type=script&lang=ts&"
export * from "./WealthContent.vue?vue&type=script&lang=ts&"
import style0 from "./WealthContent.vue?vue&type=style&index=0&id=4acd00db&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4acd00db",
  null
  
)

export default component.exports