var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"subscription-list"},_vm._l((_vm.subscriptionList),function(subscribeItem,index){return _c('router-link',{key:index,staticClass:"subscription-list__link",attrs:{"to":{
      path: 'trade/subscription',
      query: {
        ticker: subscribeItem.ticker,
        isReceipt: subscribeItem.isReceipt
      }
    },"aria-label":`Ver detalhes da subscrição do ativo ${subscribeItem.ticker}`,"data-testid":"subscribe-button"}},[_c('Row',{staticClass:"subscription-list__row",attrs:{"chevron":"","has-interaction":"","left-title":subscribeItem.ticker,"right-title":`${
        subscribeItem.quantityAvailable ||
        subscribeItem.subscribedQuantity ||
        0
      } cota(s)`},scopedSlots:_vm._u([(!subscribeItem.isReceipt)?{key:"bottom-left",fn:function(){return [_vm._v(" Até "+_vm._s(subscribeItem.closingDate)+" ")]},proxy:true}:null],null,true)})],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }