import { PluginObject } from 'vue';
import { getCustomTheme } from '@/modules/common/services/local-storage';
import { setNebraskaScheme } from '@/modules/common/services/theme';

export const nebraskaSchemePlugin: PluginObject<{}> = {
  install() {
    setTimeout(() => {
      const theme = getCustomTheme();
      if (theme && theme.colors) setNebraskaScheme(theme);
    }, 1000);
  }
};
