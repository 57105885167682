import { GtmTracker } from '@/modules/common/services/analytics';
import { Categories } from '@/types/models/Analytics';

const { trackEvent } = GtmTracker;

export function trackWealthTabClick() {
  trackEvent({
    category: 'PEX',
    action: 'Clique na aba Carteiras',
    label: 'Carteiras'
  });
}

export function trackInvitesClick() {
  trackEvent({
    category: 'Referral',
    action: 'Clique em Abrir Invites',
    label: 'Invites'
  });
}

const trackEventReferral = GtmTracker.trackerWithDefaultProps({
  page_screen: 'link_copied_modal',
  digital_experience: 'investor',
  experience_section: Categories.Indication
});

export function trackClickReferral() {
  trackEventReferral({
    event_type: 'click',
    event_name: 'go_indication_area'
  });
}
