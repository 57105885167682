import { render, staticRenderFns } from "./FAQView.vue?vue&type=template&id=8fdd6650&scoped=true&"
import script from "./FAQView.vue?vue&type=script&lang=ts&"
export * from "./FAQView.vue?vue&type=script&lang=ts&"
import style0 from "./FAQView.vue?vue&type=style&index=0&id=8fdd6650&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fdd6650",
  null
  
)

export default component.exports