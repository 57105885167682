
import { Component, Vue, Prop } from 'vue-property-decorator';

import { CloseButton } from '@/modules/common/components/buttons';
import { NavBar, BaseText, BaseModal } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    BaseModal,
    CloseButton,
    NavBar,
    BaseText
  }
})
/**
 * @deprecated - use Nebraska Modal instead
 */
export default class Modal extends Vue {
  @Prop({ type: String, required: true, default: '' })
  public title!: string;

  @Prop({ type: Boolean, default: false })
  public isOpen!: boolean;

  @Prop({ type: Boolean, default: true })
  public hasVerticalShadow!: boolean;

  @Prop({ type: Boolean, default: true })
  public hasHorizontalShadow!: boolean;

  @Prop({ type: Boolean, default: true })
  public isLeftCloseIcon!: boolean;

  private created() {
    console.warn('Using deprecated Modal, please use Nebraska Modal instead.');
  }
}
