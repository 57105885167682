
import { Component, Vue } from 'vue-property-decorator';

import {
  NavBar,
  ContextColorProvider,
  ActionableHelperView,
  RoundedIcon,
  SkeletonLoader
} from '@warrenbrasil/nebraska-web';
import { Hero, HeroContainer } from '@/modules/common/components/Hero';
import { InstrumentList } from '@/modules/trade/components/InstrumentList';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  HighLightsDetail,
  getHighLightsDetail,
  Instrument
} from '@/modules/trade/services/search';

import { trackInstrumentClick, trackSearchHighlightViewLoad } from './trackers';

@Component({
  components: {
    ContextColorProvider,
    HeroContainer,
    ActionableHelperView,
    InstrumentList,
    NavBar,
    Hero,
    RoundedIcon,
    SkeletonLoader
  }
})
export default class SearchHighLightView extends Vue {
  loading = true;
  error = false;
  highlightData: HighLightsDetail | null = null;
  readonly NebraskaColors = NebraskaColors;

  async created() {
    await this.load();
    trackSearchHighlightViewLoad(this.highlightData?.title || '');
  }

  onBackClick() {
    this.$router.back();
  }

  async load() {
    this.loading = true;
    this.error = false;
    try {
      const data = await getHighLightsDetail(this.$route.params.id);
      this.highlightData = data;
    } catch {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  get computedImage() {
    return {
      small: this.highlightData?.image?.small,
      medium: this.highlightData?.image?.medium,
      large: this.highlightData?.image?.large
    };
  }

  onRowClick(instrument: Instrument) {
    trackInstrumentClick(
      this.highlightData!.title,
      instrument.symbol,
      instrument.name!
    );

    this.$router.push({
      name: 'tradeInstrument',
      params: { ticker: instrument.symbol }
    });
  }
}
