
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Row } from '@warrenbrasil/nebraska-web';

import { TransactionBadge } from '@/modules/common/components/TransactionBadge';
import { EventsProvisionedList } from '@/modules/trade/views/TradeView/components/TradeSidebar/types';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    Row,
    TransactionBadge
  }
})
export default class EventsProvisionedListComponent extends Vue {
  @tradePositionsModule.Getter('getEventsProvisionedList')
  readonly eventsProvisioned!: EventsProvisionedList;

  get eventsProvisionedList() {
    return this.eventsProvisioned?.provisionedEvents;
  }
}
