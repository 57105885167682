import {
  FilterData,
  NumberFormatted,
  StringFormatted,
  InstrumentImage
} from '@/modules/trade/types';

export enum MarketTodayFilters {
  HIGH_VOLUME = 'high_volume',
  LOW_VARIATION = 'low_variation',
  HIGH_VARIATION = 'high_variation'
}

export interface marketItems {
  symbol: string;
  image: InstrumentImage | null;
  type: StringFormatted;
  name?: string;
  lastPrice: NumberFormatted;
  dailyPercentualChange?: NumberFormatted;
  volume?: NumberFormatted;
}

export interface IMarketToday {
  date: string;
  items: marketItems[];
  filters: FilterData<MarketTodayFilters>;
  title: string;
  subTitle: string;
}
