
import { Component, Vue } from 'vue-property-decorator';

import {
  ActionableHelperView,
  NavBar,
  Heading,
  Row,
  BaseText,
  ContextColorProvider,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  AccordionWrapper,
  Accordion
} from '@/modules/common/components/Accordion';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import {
  OverviewAPI,
  PositionDetail
} from '@/modules/common/services/http/overview';
import { FeatureFlag } from '@/modules/common/helpers/feature-flag';
import { EIntrumentQuotaSubtype } from '@/types/models/Portfolio';
import { StorageKeys } from '@/modules/common/services/local-storage';
import {
  trackPositionDetailBackClick,
  trackPositionDetailViewLoad
} from './track-events';

@Component({
  components: {
    ContextColorProvider,
    NavBar,
    BackButton,
    LoaderIndicator,
    Heading,
    Row,
    Accordion,
    AccordionWrapper,
    ActionableHelperView,
    BaseText
  },
  filters: {
    money: (number: number, maximumFractionDigits = 2) => {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits
      }).format(number);
    }
  }
})
export default class OverviewPositionDetail extends Vue {
  private NebraskaColors = NebraskaColors;
  private position?: PositionDetail = undefined;
  private productApiId?: string = undefined;
  private isLoading = true;
  private hasError = false;

  private featureFlag = new FeatureFlag();
  private shouldShowNetBalanceAndTax = this.featureFlag.isLocalEnabled(
    'ENABLE_NET_BALANCE_PENSION_EXTRACT_DETAIL_PENSION'
  );

  private async created() {
    await this.loadPosition();
    trackPositionDetailViewLoad();
  }

  private back() {
    this.$router.back();
    trackPositionDetailBackClick();
  }

  public get showContractedRate() {
    return (
      this.position?.investments[0].benchmarkRate ||
      this.position?.investments[0].fixedRate
    );
  }

  private get formatFixedTax() {
    const investment = this.position && this.position.investments[0];
    let parseFixedTax = '';

    if (!investment) return '';

    if (investment.fixedRate)
      parseFixedTax += `${investment.fixedRate.toFixed(2)}%`;

    if (investment.fixedRate && investment.benchmarkRate)
      parseFixedTax += ` + `;

    if (investment.benchmarkRate && investment.benchmarkCode) {
      parseFixedTax += `${investment.benchmarkRate}% do ${investment.benchmarkCode}`;
    }

    return parseFixedTax;
  }

  get hasAnsweredResearch() {
    const hasAnswered = !!localStorage.getItem(
      StorageKeys.HasUsedPositionDetailsButton
    );
    return hasAnswered;
  }

  private hideDetailsCostsAndNetBalance(subtype: number) {
    return (
      !this.shouldShowNetBalanceAndTax &&
      subtype === EIntrumentQuotaSubtype.PENSION
    );
  }

  private async loadPosition() {
    this.isLoading = true;
    this.productApiId = this.$route.params.productApiId;

    try {
      this.position = await OverviewAPI.getPosition(this.productApiId);
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }
}
