import { Vue, Component } from 'vue-property-decorator';

interface ProviderInterface<T> {
  loading: boolean;
  errorsList: Error[];
  item: T | null;
  getError: () => Error | undefined;
  getErrors: () => Error[];
  setIsLoading: (value: boolean) => void;
  setError: (value: Error) => void;
  resetErrors: () => void;
  getItem: () => T | null;
  setItem: (value: T) => void;
  fetch: () => void;
  reset: () => void;
}
@Component
export default class BaseProvider<T = any>
  extends Vue
  implements ProviderInterface<T>
{
  loading = false;
  errorsList: Error[] = [];
  item: T | null = null;

  getError(): Error | undefined {
    const [error] = this.getErrors();
    return error;
  }

  getErrors(): Error[] {
    return this.errorsList;
  }

  getItem(): T | null {
    return this.item;
  }

  fetch(): void {}
  reset(): void {
    this.item = null;
    this.loading = false;
    this.resetErrors();
  }

  setIsLoading(value: boolean) {
    this.loading = value;
  }

  setError(error: Error) {
    if (!this.errorsList) {
      this.errorsList = [];
    }
    this.errorsList.push(error);
  }

  setErrors(errors: Error[]) {
    this.errorsList = [...this.errorsList, ...errors];
  }

  setItem(value: T): void {
    this.item = value;
  }

  resetErrors(): void {
    this.errorsList = [];
  }

  // getters
  public get errors(): Error[] {
    return this.errorsList;
  }

  public get isLoading(): boolean {
    return this.loading;
  }

  public get isEmpty(): boolean {
    return !this.getItem();
  }

  public get hasErrors(): boolean {
    return !!this.errorsList.length;
  }

  public get shouldRenderSlot(): boolean {
    return !this.hasErrors && !this.isLoading && !this.isEmpty;
  }

  public get shouldRenderEmptySlot(): boolean {
    return !this.hasErrors && !this.isLoading && this.isEmpty;
  }
}
