export interface IStep {
  progress: number;
  title: string;
  description?: string;
}

export enum ModalSteps {
  Initial = 'initial',
  Cancel = 'cancel',
  CancelError = 'cancelError'
}
