
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { BankAccountForm } from './components/BankAccountForm';
import { FinancialDataForm } from './components/FinancialDataForm';
import { ICustomer } from '@/types/models';

@Component({ components: { BankAccountForm, FinancialDataForm } })
export default class PersonalBankDataTab extends Vue {
  @Prop({ required: true }) private user!: ICustomer;

  @Emit()
  public validateField() {}
}
