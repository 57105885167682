
import { Component, Ref, Vue } from 'vue-property-decorator';
import { InputText, InputPassword } from '@warrenbrasil/nebraska-web';
import { changeEmailSchema } from './helpers/email-validator';
import { currentPasswordSchema } from '@/modules/account/views/ChangePassword/helpers/password-validator';
import { EStatusType } from '@/modules/account/types';
import { updateEmail } from '@/modules/account/services/account';
import { getCustomer } from '@/modules/common/services/http/account';
import { ValidationError } from 'yup';
import { getString } from '@/modules/common/helpers/resources';
import { UserTemplate } from '@/modules/account/components/UserTemplate';
import { IMFAStatus, hasMFA } from '@/modules/authentication/services/mfa';
import * as trackEvents from './track-events';

@Component({
  components: { UserTemplate, InputText, InputPassword }
})
export default class ChangeEmailView extends Vue {
  @Ref() private userTemplate!: UserTemplate;

  readonly title = getString('user_email_change_button');
  private newEmailValue = '';
  private passwordValue = '';

  private currentEmail = {
    _id: 'current-email',
    label: getString('user_email_current_label'),
    value: ''
  };

  private newEmail = {
    _id: 'new-email',
    label: getString('user_email_new_label'),
    status: this.newEmailValue ? EStatusType.Success : EStatusType.Error,
    error: ''
  };

  private password = {
    _id: 'password',
    label: getString('user_email_password_confirmation'),
    status: this.passwordValue ? EStatusType.Success : EStatusType.Error,
    error: ''
  };

  private get isButtonDisabled() {
    return !(
      this.newEmail.status !== EStatusType.Error &&
      this.password.status !== EStatusType.Error
    );
  }

  async created() {
    const userHasMfaActive: IMFAStatus = await hasMFA();
    if (!userHasMfaActive.enabled) {
      this.goToMFAPage();
      return;
    }
    this.getCustomerValues();
  }

  mounted() {
    trackEvents.trackViewLoad();
  }

  private goToMFAPage() {
    this.$router.push({ name: 'security' });
  }

  private getCustomerValues() {
    getCustomer()
      .then(({ customer }) => (this.currentEmail.value = customer.email))
      .catch(error => {
        this.userTemplate.notificationError(error);
        this.userTemplate.goBack();
      });
  }

  private async handleEmailValidate(value: string) {
    try {
      await changeEmailSchema.validate({ newEmail: value });
      this.setEmailStatus('', EStatusType.Success);
    } catch (error) {
      if (error instanceof ValidationError) {
        this.setEmailStatus(error.errors[0], EStatusType.Error);
      }
    }
  }

  private async handlePasswordValidate(value: string) {
    try {
      await currentPasswordSchema.validate({ password: value });
      this.setPasswordStatus('', EStatusType.Success);
    } catch (error) {
      if (error instanceof ValidationError) {
        this.setPasswordStatus(error.errors[0], EStatusType.Error);
      }
    }
  }

  private setEmailStatus(error: string, status: EStatusType) {
    this.newEmail.error = error;
    this.newEmail.status = status;
  }

  private setPasswordStatus(error: string, status: EStatusType) {
    this.password.error = error;
    this.password.status = status;
  }

  private async submit() {
    trackEvents.trackSaveClick();

    const data = {
      newEmail: this.newEmailValue,
      currentPassword: this.passwordValue
    };

    return updateEmail(data).then(() => 'E-mail enviado para confirmação');
  }
}
