export interface InstrumentValue {
  value: number;
  formatted: string;
}

export interface BalanceValue {
  value: number;
  formatted: string;
}

interface StatusSubscriptionValues {
  title: string;
  titlePage: string;
  iconColor: string;
  textIconColor: string;
  iconName: string;
  description: string;
}

export interface StatusSubscription {
  Register: StatusSubscriptionValues;
  Update: StatusSubscriptionValues;
  Cancel: StatusSubscriptionValues;
  ErrorRegister: StatusSubscriptionValues;
  ErrorUpdate: StatusSubscriptionValues;
  ErrorCancel: StatusSubscriptionValues;
}

export enum SidebarTypesSubscription {
  SubscriptionOrder,
  SubscriptionConfirm,
  SubscriptionStatus
}

export enum SubscriptionActionStatus {
  Register = 'Register',
  Update = 'Update',
  Cancel = 'Cancel',
  ErrorRegister = 'ErrorRegister',
  ErrorUpdate = 'ErrorUpdate',
  ErrorCancel = 'ErrorCancel'
}

export enum SubscriptionTypes {
  SobrasDeSubscricao = 'SobrasDeSubscricao',
  Subscricao = 'Subscricao'
}
