import { GtmTracker } from '@/modules/common/services/analytics';
import { Categories } from '@/types/models/Analytics';

const { trackerWithDefaultProps } = GtmTracker;

const trackEvent = trackerWithDefaultProps({
  category: Categories.PEX
});

const trackAmplitudeEvent = trackerWithDefaultProps({
  page_screen: 'home_portfolio',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackGoToBuilder() {
  trackEvent({
    label: 'Minhas carteiras',
    action: 'WalletCreate'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'my_portfolios',
    event_name: 'create_portfolio'
  });
}

export function trackAllocationEvent(event_target = '') {
  trackEvent({
    label: 'Alocação',
    action: `Clique ${event_target}`
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'allocation',
    event_name: 'detail_allocation',
    event_target
  });
}
