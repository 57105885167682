
import {
  Component,
  Vue,
  Prop,
  Emit,
  InjectReactive
} from 'vue-property-decorator';

import {
  FilterOption,
  BaseText,
  FilterSelect,
  IFilterOption,
  SkeletonLoader,
  Heading,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import {
  HeaderSubtitleLoader,
  HeaderFilterLoader
} from '@/modules/trade/views/TradePositionsView/components/Loaders';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ICashMarketFilters, CashMarketFiltersTypes } from '../../types';
import { NumberFormatted, StringFormatted } from '@/modules/trade/types';

@Component({
  components: {
    BaseText,
    FilterOption,
    FilterSelect,
    SkeletonLoader,
    Heading,
    HeaderSubtitleLoader,
    HeaderFilterLoader,
    ButtonSecondary
  }
})
export default class CashMarketFilters extends Vue {
  @Prop({ type: Object })
  readonly updatedAt!: NumberFormatted;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean;

  @InjectReactive('filters')
  readonly filters!: ICashMarketFilters;

  @Emit('on-filter-change')
  onFilterChange(_filterType: CashMarketFiltersTypes, _filter: IFilterOption) {}

  @Emit('show-allocation')
  emitShowAllocation() {}

  readonly NebraskaColors = NebraskaColors;
  readonly CashMarketFiltersTypes = CashMarketFiltersTypes;

  get updatedAtFormatted() {
    return this.updatedAt?.formatted || '-';
  }

  get hasInstrumentTypesOptions() {
    return this.filters[CashMarketFiltersTypes.Type].available?.length > 0;
  }

  get instrumentTypesOptions(): IFilterOption[] {
    return this.filters[CashMarketFiltersTypes.Type].available.map(
      this.formatFilterOption
    );
  }

  get hasDateOptions() {
    return this.filters[CashMarketFiltersTypes.Date].available?.length > 0;
  }

  get dateOptions(): IFilterOption[] {
    return this.filters[CashMarketFiltersTypes.Date].available.map(
      this.formatFilterOption
    );
  }

  handleEmitOnFilterChange(
    filterKey: CashMarketFiltersTypes,
    filterValue?: string | number
  ) {
    const filter = this.filters[filterKey].available.find(
      ({ value }) => value === filterValue
    ) as StringFormatted;

    this.onFilterChange(filterKey, this.formatFilterOption(filter));
  }

  formatFilterOption(option: StringFormatted): IFilterOption {
    return {
      value: option.value,
      label: option.formatted
    };
  }
}
