
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  ReviewStep,
  Row,
  InitialAvatarImage,
  InitialAvatarIcon
} from '@warrenbrasil/nebraska-web';
import { ENewTransferSteps } from '@/modules/transaction/views/NewTransfer/helpers/types';
import { IBankAccount } from '@/modules/transaction/services/new-transfer';

@Component({
  components: {
    ReviewStep,
    Row,
    InitialAvatarImage,
    InitialAvatarIcon
  }
})
export default class NewTransferBankSelection extends Vue {
  @Prop({ type: Array, required: true })
  readonly bankAccounts!: IBankAccount[];

  @Emit('bank-account-selected')
  emitBankAccount(_bank: IBankAccount) {
    // Atualiza o banco selecionado
  }

  @Emit('change-step')
  emitChangeStep(_step: ENewTransferSteps) {
    // Muda para o step emitido
  }

  handleClickBankAccount(bank: IBankAccount) {
    this.emitBankAccount(bank);
    this.emitChangeStep(ENewTransferSteps.REVIEW);
  }

  addNewAccount() {
    this.$router.push({ name: 'user-account' });
  }

  goBackStep() {
    this.emitChangeStep(ENewTransferSteps.AMOUNT_SELECTION);
  }
}
