
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Row, Heading, BaseText, BaseIcon } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { IAppliedAllocations } from '@/modules/wealth/services/investment/investment-types';
import { moneyFormat } from '@/modules/common/helpers/currency';
import { IPortfolioSummaryDetailsProps } from '@/modules/wallet-builder/src/services/types/models/IBffInvestments';

@Component({
  components: {
    Heading,
    Row,
    BaseText,
    BaseIcon
  }
})
export default class MinimumDepositDetail extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: Object, required: true })
  readonly detailProps!: {
    allocations: IAppliedAllocations;
    minimumDeposit: IPortfolioSummaryDetailsProps;
  };

  public getRowValues(value: number) {
    return moneyFormat(value, false);
  }
}
