import { State } from './types';

export const PensionPositionState: State = {
  instrumentDetails: {
    businessName: '',
    contracts: []
  },

  // Loading state
  loading: {
    instrumentDetails: false
  },

  // Errors state
  errors: {
    instrumentDetails: false
  }
};
