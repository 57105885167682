
import { Component, Vue, Prop, Provide } from 'vue-property-decorator';

import { NotificationTypes } from '../types';

import Bowser from 'bowser';

import { IWalletProvider } from './types/IWalletProvider';

import {
  IProductGetLastState,
  IInstrument,
  IProductsRemoveParam
} from '../services/types/models/IBffInvestments';
import { BffInvestments } from '@/modules/wallet-builder/src/services/bff-investments';

@Component({
  components: {}
})
export default class WalletProvider extends Vue implements IWalletProvider {
  @Prop({ type: Object, required: false, default: null })
  public instrument!: IInstrument | null;

  public allocations: IProductGetLastState[] = [];
  public hasError = false;
  public errorMessage = '';
  public isServerError = false;
  public onRetry?: Function;
  public isMobile = false;
  public productsQt = 0;

  public bff = new BffInvestments();

  public get dataAllocations() {
    return this.allocations || [];
  }

  beforeMount() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (browser.getPlatformType() !== 'desktop') {
      this.isMobile = true;
    }
  }

  public setError(error: string, isServerError: boolean, onRetry?: Function) {
    this.hasError = true;
    this.errorMessage = error;
    this.isServerError = isServerError;
    this.onRetry = onRetry;
  }

  public resetError() {
    this.hasError = false;
    this.errorMessage = '';
    this.isServerError = false;
  }

  @Provide('addProductToWallet') public async addProductToWallet(
    instrument: IInstrument
  ) {
    try {
      if (instrument.apiId) {
        const response = await this.bff.addProductToWallet(instrument.apiId);
        await this.getLastState();
        if (response.canAddInstrument) {
          this.$notify({
            group: 'wallet-edit',
            text: 'Produto adicionado',
            type: NotificationTypes.success
          });
        } else {
          this.$notify({
            group: 'wallet-edit',
            text: 'Produto indisponível',
            type: NotificationTypes.error
          });
        }
      }
    } catch (e) {
      const { error }: any = e;
      if (!error) {
        this.setError('', true, () => this.addProductToWallet(instrument));
        return false;
      }
      this.setError(error.message.formatted, false);
      await this.getLastState();
      return false;
    }
  }

  @Provide('removeProductFromWallet')
  public async removeProductFromWallet({ productIds }: IProductsRemoveParam) {
    try {
      await this.bff.removeProducts({
        productIds
      });
      await this.getLastState();
      this.$notify({
        group: 'wallet-edit',
        text: 'Produto(s) removido(s)',
        type: NotificationTypes.success
      });
    } catch (e) {
      this.setError('', true, () =>
        this.removeProductFromWallet({ productIds })
      );
      await this.getLastState();
      return false;
    }
  }

  public async getLastState(portfolioId?: string) {
    try {
      const { products } = await this.bff.getLastState(portfolioId);
      this.allocations = products;
      this.productsQt = products?.length ?? 0;
    } catch (e) {
      this.setError('', true, () => this.getLastState());
      return false;
    }
  }
}
