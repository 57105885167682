
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { Heading, Row } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Heading,
    Row
  }
})
export default class SubscriptionDetails extends Vue {
  @Prop({ type: String, required: true })
  readonly symbol!: string;

  @Prop({ type: String, required: true })
  readonly baseSymbol!: string;

  @Prop({ type: String, required: true })
  readonly closingDate!: string;

  @Prop({ type: String })
  readonly liquidationDate?: string;

  @Prop({ type: String, required: true })
  readonly actualPrice!: string;

  @Emit('on-symbol-row-click')
  emitOnSymbolRowClick() {
    return this.baseSymbol;
  }

  get liquidationDateParsed() {
    return this.liquidationDate ?? 'Processando...';
  }

  get linkAriaLabel() {
    return `Ver detalhes do ${this.baseSymbol}`;
  }

  get isReceipt() {
    return this.$route.query?.isReceipt === 'true';
  }
}
