import { GtmTracker } from '@/modules/common/services/analytics';

const { trackEvent } = GtmTracker;

export function trackCheckbox() {
  trackEvent({
    category: 'PEX - Carteira Editada',
    action: 'Termos Produtos',
    label: 'Aceite do Termo de Produtos'
  });
}

export function trackCancelled() {
  trackEvent({
    category: 'PEX - Carteira Editada',
    action: 'Termos Produtos - Cancelar',
    label: 'B2C'
  });
}

export function trackAccepted() {
  trackEvent({
    category: 'PEX - Carteira Editada',
    action: 'Termos Produtos - Continuar',
    label: 'B2C'
  });
}

export function trackInvestmentCancelled() {
  trackEvent({
    category: 'PEX',
    action: 'Clique cancelar',
    label: 'Investir por produto - Termos'
  });
}

export function trackInvestmentCheckBox() {
  trackEvent({
    category: 'PEX',
    action: 'Clique aceitar termos',
    label: 'Investir por produto - Termos'
  });
}

export function trackInvestmentAccepted() {
  trackEvent({
    category: 'PEX',
    action: 'Clique continuar',
    label: 'Investir por produto - Termos'
  });
}

export function trackInvestmentBack() {
  trackEvent({
    category: 'PEX',
    action: 'Clique voltar',
    label: 'Investir por produto - Termos'
  });
}

export function trackInvestmentClose() {
  trackEvent({
    category: 'PEX',
    action: 'Clique fechar',
    label: 'Investir por produto - Termos'
  });
}

export function trackBackBtn() {
  trackEvent({
    category: 'PEX',
    action: 'Clique voltar',
    label: 'Termos de produto - Sugestao de investimento'
  });
}

export function trackCloseBtn() {
  trackEvent({
    category: 'PEX',
    action: 'Clique fechar',
    label: 'Termos de produto - Sugestao de investimento'
  });
}

export function trackAcceptedRisks() {
  trackEvent({
    category: 'PEX',
    action: 'Clique concordar',
    label: 'Termos de produto - Sugestao de investimento'
  });
}

export function trackCancelTerms() {
  trackEvent({
    category: 'PEX',
    action: 'Clique cancelar',
    label: 'Termos de produto - Sugestao de investimento'
  });
}

export function trackContinueTerms() {
  trackEvent({
    category: 'PEX',
    action: 'Clique continuar',
    label: 'Termos de produto - Sugestao de investimento'
  });
}
