
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { InitialAvatarIcon, Row, BaseText } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Row,
    InitialAvatarIcon,
    BaseText
  }
})
export default class FooterButton extends Vue {
  @Prop({ type: Boolean, default: true })
  readonly hasInteraction!: boolean;

  @Prop({ type: String, required: true })
  readonly iconName!: string;

  @Prop({ type: String, default: '20' })
  readonly iconSize!: string;

  @Prop({ type: String, default: NebraskaColors.themeOverPrimary })
  readonly iconTextColor!: string;

  @Prop({ type: String, required: true })
  readonly buttonText!: string;

  @Prop({ type: String, default: '' })
  readonly buttonSubText!: string;

  @Emit('row-click')
  public rowClickAction() {}

  readonly NebraskaColors = NebraskaColors;

  get computedIconStyle() {
    return this.hasInteraction
      ? NebraskaColors.themePrimary
      : NebraskaColors.textDisabled;
  }
}
