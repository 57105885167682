import { GtmTracker } from '@/modules/common/services/analytics';
import { Categories } from '@/types/models/Analytics';

export enum TrackEvent {
  BackClick = 'backClick',
  Load = 'load',
  Download = 'download'
}

const trackConsolidatedExtractAmplitudeEvent =
  GtmTracker.trackerWithDefaultProps({
    page_screen: 'consolidated_statement',
    digital_experience: 'investor',
    experience_section: 'menu'
  });

const trackFixedIncomeStatementAmplitudeEvent =
  GtmTracker.trackerWithDefaultProps({
    page_screen: 'fixed_income_statement',
    digital_experience: 'investor',
    experience_section: 'menu'
  });

const trackIncomeStatementAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'income_statement',
  digital_experience: 'investor',
  experience_section: 'menu'
});

export function trackConsolidatedExtractViewLoad() {
  trackConsolidatedExtractAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackFixedIncomeStatementViewLoad() {
  trackFixedIncomeStatementAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackIncomeStatementViewLoad() {
  trackIncomeStatementAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackConsolidatedExtractBackClick() {
  trackConsolidatedExtractAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'back'
  });
}

export function trackFixedIncomeStatementBackClick() {
  trackFixedIncomeStatementAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'back'
  });
}

export function trackIncomeStatementBackClick() {
  trackIncomeStatementAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'back'
  });
}

export function trackConsolidatedExtractDownload(year: number, month: number) {
  GtmTracker.trackEvent({
    category: Categories.Overview,
    label: 'Download de extrato',
    action: 'Clique em mês',
    year,
    month
  });

  trackConsolidatedExtractAmplitudeEvent({
    page_screen_section: 'body',
    event_type: 'click',
    event_name: 'download_extract',
    event_target: `${month}/${year}`
  });
}

export async function trackConsolidatedExtractDownloadError(error: string) {
  GtmTracker.trackEvent({
    category: Categories.Overview,
    label: 'Download de extrato',
    action: 'Clique em mês'
  });

  trackConsolidatedExtractAmplitudeEvent({
    event_type: 'click',
    event_name: 'download_extract_error',
    event_target: error
  });
}

export function trackFixedIncomeStatementDownload(year: number, month: number) {
  GtmTracker.trackEvent({
    category: Categories.Overview,
    label: 'Download de extrato',
    action: 'Clique em mês',
    year,
    month
  });

  trackFixedIncomeStatementAmplitudeEvent({
    page_screen_section: 'body',
    event_type: 'click',
    event_name: 'download_extract',
    event_target: `${month}/${year}`
  });
}

export function trackIncomeStatementDownload(year: number) {
  GtmTracker.trackEvent({
    category: Categories.Overview,
    label: 'Download do informe de rendimentos',
    action: 'Clique em ano',
    year
  });

  trackIncomeStatementAmplitudeEvent({
    event_type: 'click',
    event_name: 'dowload_income_statement',
    event_target: year
  });
}

export async function trackIncomeStatementDownloadError(error: string) {
  GtmTracker.trackEvent({
    category: Categories.Overview,
    label: 'Download do informe de rendimentos',
    action: 'Clique em ano'
  });

  trackIncomeStatementAmplitudeEvent({
    event_type: 'click',
    event_name: 'download_income_statement_error',
    event_target: error
  });
}

export async function trackFixedIncomeStatementDownloadError(error: string) {
  GtmTracker.trackEvent({
    category: Categories.Overview,
    label: 'Download do extrato',
    action: 'Clique em mês'
  });

  trackFixedIncomeStatementAmplitudeEvent({
    event_type: 'click',
    event_name: 'download_fixed_income_statement_error',
    event_target: error
  });
}
