import { GtmTracker } from '@/modules/common/services/analytics';

const { trackerWithDefaultProps } = GtmTracker;

const trackEvent = trackerWithDefaultProps({
  category: 'PEX - Investir Carteira'
});

const trackAmplitudeEvent = trackerWithDefaultProps({
  page_screen: 'portfolio_investment_flow',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackLoadPortfolioInvestment() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackSuggestionChip(event_target: string) {
  trackEvent({
    action: 'Clique Valor Pre Definido',
    label: event_target
  });
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'select_investment_value',
    event_target
  });
}

export function trackInvest(
  portfolioName: string,
  valueToInvest: number,
  investmentMinimumValue: number
) {
  trackEvent({
    action: 'Clique Realizar Investimento',
    label: `${portfolioName} - Sucesso`,
    value: valueToInvest
  });
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'invest_money',
    question: 'Quanto você deseja investir nesta carteira?',
    investment_value: valueToInvest,
    investment_minimum_value: investmentMinimumValue
  });
}

export function trackAccountDetails() {
  trackEvent({
    action: 'Clique Conta Digital',
    label: 'B2C'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'invest_investment_account'
  });
}

export function trackNoMoneyWarning(portfolioName: string) {
  trackEvent({
    action: 'Clique Realizar Investimento',
    label: `${portfolioName} - Sem saldo`
  });
}

export function trackGenerateInvoiceEvent(portfolioName: string) {
  trackEvent({
    action: 'Clique Gerar Boleto',
    label: portfolioName
  });
}

export function trackInputFeedbackMinimumValue(
  portfolioName: string,
  valueToInvest: number,
  minimumValueToDeposit: number
) {
  trackEvent({
    action: 'Clique Realizar Investimento',
    label: `${portfolioName} - Valor menor que 1 real`,
    value: valueToInvest
  });
  trackAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'minimum_portfolio_investment_error',
    event_name: 'view_error',
    investment_minimum_value: minimumValueToDeposit
  });
}

export function trackInputFeedbackNoMoney(
  portfolioName: string,
  valueToInvest: number
) {
  trackEvent({
    action: 'Clique Realizar Investimento',
    label: `${portfolioName} - Saldo insuficiente`,
    value: valueToInvest
  });
  trackAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'unavailable_balance_error',
    event_name: 'view_error'
  });
}

export function trackErrorTransaction() {
  trackAmplitudeEvent({
    page_screen: 'portfolio_investment_request_error',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackErrorTryAgain() {
  trackAmplitudeEvent({
    page_screen: 'portfolio_investment_request_error',
    event_type: 'click',
    event_name: 'try_again'
  });
}

export function trackErrorModalUnavailable() {
  trackAmplitudeEvent({
    page_screen: 'portfolio_investment_instrument_unavailable_error',
    event_type: 'load',
    event_name: 'view_error'
  });
}

export function trackErrorModalScheduled() {
  trackAmplitudeEvent({
    page_screen: 'portfolio_investment_scheduling',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackErrorModalEditadabox() {
  trackAmplitudeEvent({
    page_screen: 'portfolio_eddited_box_investment_request_error',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackInvestmentSchedule() {
  trackAmplitudeEvent({
    page_screen: 'portfolio_investment_scheduling',
    event_type: 'click',
    event_name: 'schedule_investment'
  });
}

export function trackErrorClickOk() {
  trackAmplitudeEvent({
    page_screen: 'portfolio_investment_unavailable_error',
    event_type: 'click',
    event_name: 'ok'
  });
}

export function trackErrorClickGoBack() {
  trackAmplitudeEvent({
    page_screen: 'portfolio_investment_scheduling',
    event_type: 'click',
    event_name: 'go_back'
  });
}
