import { trackAmplitudeEvent } from '@/modules/home/helpers/track-amplitude-event';
import { trackNewsByAction } from '@/modules/home/helpers/tracking';

export function trackNewsClick(newsTitle: string, id: string) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'news',
    event_name: 'select_news',
    event_target: newsTitle
  });

  trackNewsByAction(`Click na news: "${newsTitle}", id: ${id}`);
}

export function trackNewsRemoveClick(newsTitle: string, id: string) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'news',
    event_name: 'remove_news',
    event_target: newsTitle
  });

  trackNewsByAction(`Click para fechar news: "${newsTitle}", id: ${id}`);
}
