import { GtmTracker } from '@/modules/common/services/analytics';

const trackResetPasswordEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'signin_reset_password',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

const trackRecoverPasswordEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'signin_forget_password',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackPageView() {
  trackRecoverPasswordEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackEmailEvent() {
  trackRecoverPasswordEvent({
    event_type: 'blur',
    page_screen_section: 'form',
    event_name: 'fill_email'
  });
}

export function trackRecoverButtonClickEvent() {
  trackRecoverPasswordEvent({
    event_type: 'click',
    page_screen_section: 'form',
    event_name: 'reset_password'
  });
}

export function trackResetPasswordPageViewEvent() {
  trackResetPasswordEvent({
    event_type: 'load',
    event_name: 'view'
  });
}
