
import { Component, Vue, Emit } from 'vue-property-decorator';
import { BaseText, ButtonSecondary, Heading } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    Heading,
    BaseText,
    ButtonSecondary
  }
})
export default class BalancesError extends Vue {
  @Emit('reload')
  reload() {}

  readonly NebraskaColors = NebraskaColors;

  title = 'Houve um problema ao carregar o saldo';
  description = 'Aguarde um momento e tente novamente';
}
