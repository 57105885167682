
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  Modal,
  Heading,
  FilterList,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { IFilters } from '@/modules/transaction/views/Transactions/components/StatementList/types';
import { parseFilterOptions } from '../../helpers';

@Component({
  components: {
    Modal,
    Heading,
    FilterList,
    ButtonPrimary,
    ButtonSecondary
  }
})
export default class ExportModal extends Vue {
  @Prop({ type: Object, required: true })
  readonly filters!: IFilters;

  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Emit('submit')
  submit() {
    return this.filtersValue;
  }

  @Emit('close')
  close() {}

  public filtersValue = {
    datePeriod: 'SEVEN_DAYS',
    movementType: 'ALL'
  };

  get typesOptions() {
    return parseFilterOptions(this.filters.types);
  }

  get periodsOptions() {
    const periodOptions = parseFilterOptions(this.filters.datePeriods);
    const periodOptionsParsed = periodOptions.filter(
      option => option.label !== 'Tudo'
    );
    return periodOptionsParsed;
  }
}
