
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import {
  Heading,
  Row,
  BaseIcon,
  InitialAvatarIcon
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { WealthConfig } from './components/WealthConfig';
import { trackViewHistoricalBalance } from './track-events';
import { IWealthPortfolio } from '@/modules/wealth/services/investment/investment-types';

@Component({
  components: { Heading, Row, BaseIcon, WealthConfig, InitialAvatarIcon }
})
export default class WealthSummary extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: Object, required: false })
  readonly portfolio?: IWealthPortfolio;

  @Prop({ type: Boolean, default: false })
  readonly hideIncomeStory!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showConfig!: boolean;

  @Emit('set-panel')
  private emitSetPanel() {}

  @Emit('tracking')
  private emitTracking() {}

  @Emit('save')
  emitSave() {}

  isConfigOpen = false;

  allocationClick() {
    this.emitTracking();
    this.emitSetPanel();
  }

  pushWealthIncomeHistory(query?: { portfolio: string }) {
    this.$router.push({
      name: 'wealthPerformance',
      query
    });

    trackViewHistoricalBalance();
  }

  goToIncomeHistory() {
    if (this.portfolio) {
      const query = { portfolio: this.portfolio.id };
      this.pushWealthIncomeHistory(query);
      return;
    }
    this.pushWealthIncomeHistory();
  }

  toggleConfig() {
    this.isConfigOpen = !this.isConfigOpen;
  }
}
