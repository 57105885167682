
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { InputText } from '@warrenbrasil/nebraska-web';

enum StatusType {
  Success = 'success',
  Error = 'error'
}

@Component({ components: { InputText } })
export default class InputLabelField extends Vue {
  @Prop({ type: String, required: false }) private error?: string;
  @Prop({ type: String, required: false }) private label?: string;
  @Prop({ type: String, required: false }) private mask?: string;
  @Prop({ type: Boolean, required: false }) private required?: boolean;
  @Prop({ type: String, default: '', required: false }) private value!: string;
  private inputValue!: string;
  private inputStatus!: StatusType;

  @Emit('input')
  private handleInput(value: string) {
    this.inputValue = value;
    if (this.required && !this.inputValue) {
      this.inputStatus = StatusType.Error;
    } else {
      this.inputStatus = StatusType.Success;
    }
  }

  created() {
    this.inputValue = this.value;
    this.inputStatus = this.inputValue ? StatusType.Success : StatusType.Error;
  }
}
