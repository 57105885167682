
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { HelperViewStatus, NavBar } from '@warrenbrasil/nebraska-web';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { RemoveConfirmationModal } from './components/RemoveConfirmationModal';

import { IHandleError } from './types';
import { EButtonLabel } from '@/modules/wealth/views/InvestmentSuggestions/helpers';
import * as trackEvents from '@/modules/wealth/views/InvestmentSuggestions/track-events';

const CustomerModule = namespace('CustomerModule');

@Component({
  components: { HelperViewStatus, NavBar, BackButton, RemoveConfirmationModal }
})
export default class SuggestionsError extends Vue {
  @CustomerModule.Getter('getCustomerId')
  readonly customerId!: string;

  @Prop({ type: Object, required: true })
  readonly handleError!: IHandleError;

  @Prop({ type: String, required: true })
  readonly transactionId!: string;

  @Emit('reload')
  emitReload() {}

  @Emit('rejected')
  emitRejected() {
    return {
      transactionId: this.transactionId,
      status: 'rejected',
      customerId: this.customerId,
      updatedBy: this.customerId
    };
  }

  isRemoveConfirmationModalOpen = false;

  created() {
    trackEvents.trackUnavailableSuggestionLoad(this.handleError.type);
  }

  handleClick() {
    if (this.handleError.buttonLabel === EButtonLabel.REMOVE_SUGGESTION) {
      this.handleRemoveConfirmationModal();
    } else {
      this.emitReload();
    }
  }

  handleRemoveConfirmationModal() {
    this.isRemoveConfirmationModalOpen = !this.isRemoveConfirmationModalOpen;
  }
}
