import { EMarginAllocationSteps } from './types';

import { Balance } from '../components/Balance';
import { Drawdown } from '../components/Drawdown';
import { Review } from '../components/Review';
import { DayTrade } from '../components/DayTrade';
import { TraderShield } from '../components/TraderShield';
import { HandleError } from '../components/HandleError';

export const MarginAllocationComponents = {
  [EMarginAllocationSteps.BALANCE]: Balance,
  [EMarginAllocationSteps.DRAWDOWN]: Drawdown,
  [EMarginAllocationSteps.REVIEW]: Review,
  [EMarginAllocationSteps.DAY_TRADE_LIMITS]: DayTrade,
  [EMarginAllocationSteps.TRADER_SHIELD]: TraderShield,
  [EMarginAllocationSteps.ERROR]: HandleError
};
