
import { Component, Emit, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Row, InitialAvatarIcon } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Row,
    InitialAvatarIcon
  }
})
export default class HelpButton extends Vue {
  get NebraskaColors() {
    return NebraskaColors;
  }

  @Emit('handle-click-help')
  onHandleClick() {}
}
