import { GtmTracker } from '@/modules/common/services/analytics';

const { trackerWithDefaultProps } = GtmTracker;

const trackAmplitudeEvent = trackerWithDefaultProps({
  page_screen: 'investment',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackLoadPortfolioSelection() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackInvestPortfolioClick(event_target: string) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'click_investment_portfolio',
    event_target
  });
}

export function trackCreatePortfolio() {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'create_portfolio'
  });
}
