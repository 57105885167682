import { render, staticRenderFns } from "./ModalPreview.vue?vue&type=template&id=072201c7&scoped=true&"
import script from "./ModalPreview.vue?vue&type=script&lang=ts&"
export * from "./ModalPreview.vue?vue&type=script&lang=ts&"
import style0 from "./ModalPreview.vue?vue&type=style&index=0&id=072201c7&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072201c7",
  null
  
)

export default component.exports