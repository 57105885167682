
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IPensionContractDetails } from '@/modules/pension/types';

import { Heading, Grid } from '@warrenbrasil/nebraska-web';
import { DataLabel } from '../DataLabel';
import { PensionContractAliquotsTable } from '../PensionContractAliquotsTable';

@Component({
  components: {
    DataLabel,
    Grid,
    Heading,
    PensionContractAliquotsTable
  }
})
export default class PensionContractDetails extends Vue {
  readonly gridColumns: number[] = [3, 3, 3, 3];

  @Prop({ required: true, default: () => {} })
  readonly contract!: IPensionContractDetails;

  get contractNumber() {
    return `Certificado nº ${this.contract.contractNumber}`;
  }
}
