
import { Component, Vue } from 'vue-property-decorator';

import {
  ContentDisplay,
  InitialAvatarIcon,
  Heading,
  BaseText
} from '@warrenbrasil/nebraska-web';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { data } from '../../data';
import { ViewStatus } from '../../types';

@Component({
  components: {
    ContentDisplay,
    InitialAvatarIcon,
    Heading,
    BaseText
  }
})
export default class TaxCalculatorContent extends Vue {
  readonly NebraskaColors = NebraskaColors;
  readonly data = data;
  readonly ViewStatus = ViewStatus;
}
