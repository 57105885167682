
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Typeform } from '@/modules/common/components/Typeform';
import { getBrand } from '@/modules/common/services/local-storage';
import { ICustomer } from '@/types/models/Customer';

import {
  FeatureSurvey as IFeatureSurvey,
  PopupOptions,
  HiddenFields
} from '@/modules/common/services/http/feature-survey';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';

import { namespace } from 'vuex-class';
const CustomerModule = namespace('CustomerModule');
const FeatureSurveyModule = namespace('FeatureSurveyModule');

@Component({
  components: {
    Typeform
  }
})
export default class FeatureSurvey extends Vue {
  @CustomerModule.Getter('getCustomer')
  private customer?: ICustomer;

  @CustomerModule.Getter('getCustomerId')
  private customerId?: string;

  @FeatureSurveyModule.Getter('exists') private exists!: boolean;
  @FeatureSurveyModule.Getter('first') private first!: IFeatureSurvey;
  @FeatureSurveyModule.Action('setIsAnswered') private setIsAnswered!: Function;
  @FeatureSurveyModule.Action('remove') private remove!: Function;

  @Prop({ type: String, required: false, default: '' })
  public platformVersion!: string;

  private get hasCSAT(): boolean {
    return this.exists;
  }

  private get current(): IFeatureSurvey {
    return this.first;
  }

  private get popupOptions(): PopupOptions | undefined {
    return this.current?.props?.popupOptions;
  }

  private get hiddenFields(): HiddenFields | undefined {
    if (!this.customer || !this.current) {
      return {};
    }
    const userInfo = this.defaultsFields;
    const hiddenFields = this.current?.props?.hiddenFields || {};
    return { ...userInfo, ...hiddenFields };
  }

  private get defaultsFields() {
    const brand = getBrand();

    return {
      customer_apiid: this.customerId || '',
      partner_apiid: this.customer?.partnerInfos?.customer ?? '',
      brand_apiid: brand?._id ?? '',
      platform: 'web',
      platform_version: this.platformVersion
    };
  }

  private finish() {
    this.setIsAnswered(this.current);
  }

  private handleClose() {
    if (!this.current?.props?.keepAlive) {
      this.remove(this.current);
    }
  }

  private get isEnabled() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.FEATURE_SURVEY);
  }
}
