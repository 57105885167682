
import { Component, Vue } from 'vue-property-decorator';
import { CreateElement } from 'vue';

@Component
export default class AccordionWrapper extends Vue {
  private currentIndex = -1;

  public render(createElement: CreateElement) {
    const children = this.$slots!.default!.filter(
      vNode => vNode.componentOptions
      // eslint-disable-next-line array-callback-return
    ).map((vNode, index) => {
      if (vNode.componentOptions) {
        return createElement(
          vNode.componentOptions.Ctor,
          {
            props: {
              ...vNode.componentOptions.propsData,
              open: index === this.currentIndex
            },
            on: {
              'accordion-changed': () => {
                this.currentIndex = index;
              }
            }
          },
          vNode.componentOptions.children
        );
      }
    });

    return createElement('section', children);
  }
}
