import { render, staticRenderFns } from "./WealthPerformanceAccordions.vue?vue&type=template&id=498dd83a&scoped=true&"
import script from "./WealthPerformanceAccordions.vue?vue&type=script&lang=ts&setup=true&"
export * from "./WealthPerformanceAccordions.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./WealthPerformanceAccordions.vue?vue&type=style&index=0&id=498dd83a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498dd83a",
  null
  
)

export default component.exports