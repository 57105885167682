
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import {
  BaseModal,
  ModalButton,
  NavBar,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

import { CloseButton } from '@/modules/common/components/buttons';
import { ModalContent } from '../ModalContent';
import { ModalStatus } from '../ModalStatus';
import { subscribe, IPlansProductData } from '@/modules/trade/services/store';

import {
  trackRequestTradeMapClick,
  trackCancelTradeMapClick
} from '@/modules/trade/views/TradeView/trackers';

const TRADE_MAP_ID = '5faec8ae0dc35a0017dd6476';

@Component({
  components: {
    BaseModal,
    NavBar,
    CloseButton,
    LoaderIndicator,
    ModalButton,
    ModalContent,
    ModalStatus
  }
})
export default class RequestTradeMapModal extends Vue {
  isLoading = false;
  tradeMapRequested = false;
  requestedError = null;

  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: Array, required: true })
  readonly customerProducts!: IPlansProductData[];

  @Emit('close')
  emitClose() {}

  @Emit('trademap-requested')
  emitTrademapRequested() {}

  beforeUpdate() {
    this.tradeMapRequested = false;
  }

  async requestTradeMap() {
    try {
      trackRequestTradeMapClick();
      this.isLoading = true;
      await subscribe(TRADE_MAP_ID);
    } catch (error) {
      this.requestedError = error.message;
    } finally {
      this.tradeMapRequested = true;
      this.isLoading = false;
    }
  }

  handleCancelClick() {
    trackCancelTradeMapClick();
  }

  closeModal() {
    if (this.tradeMapRequested) this.emitTrademapRequested();
    else this.emitClose();
  }

  get hasTradeMapCustomer() {
    return this.customerProducts.some(product => product.code === 'trademap');
  }

  get title() {
    return this.hasTradeMapCustomer ? 'TradeMap Ativo' : 'Ativar tradeMap';
  }

  get modalStatusProps() {
    const errorRequestingProps = {
      type: 'error',
      title: 'TradeMap não habilitado',
      description: this.requestedError
    };
    const requestSucceedProps = {
      type: 'success',
      title: 'TradeMap ativo!',
      description:
        'Para utilizar, acesse a plataforma TradeMap e selecione Warren na área Corretoras.'
    };
    const tradeMapActiveProps = {
      type: 'success',
      title: 'O TradeMap já está ativo',
      description:
        'Para utilizar, acesse a plataforma TradeMap e selecione Warren na área Corretoras.'
    };

    if (this.requestedError) return errorRequestingProps;
    else if (this.tradeMapRequested) return requestSucceedProps;
    return tradeMapActiveProps;
  }
}
