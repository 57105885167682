
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  PensionInstrumentModule,
  State,
  ActionFetchInstrumentDetails
} from '@/modules/pension/store/pension-instrument';
import { PensionContractDetails } from './components/PensionContractDetails';
import {
  ActionableHelperView,
  IconButton,
  NavBar,
  Heading,
  ContextColorProvider,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

const PensionModule = namespace('PensionInstrumentModule');

@Component({
  components: {
    IconButton,
    NavBar,
    LoaderIndicator,
    Heading,
    ActionableHelperView,
    PensionContractDetails,
    PensionInstrumentModule,
    ContextColorProvider
  }
})
export default class PensionInstrumentDetailsView extends Vue {
  @PensionModule.State('data')
  state!: State;

  @PensionModule.Action('fetchInstrumentDetails')
  private fetchInstrumentDetails!: ActionFetchInstrumentDetails;

  private created() {
    this.loadDetails();
  }

  private loadDetails() {
    const { productApiId } = this.$route.params;
    this.fetchInstrumentDetails(productApiId);
  }

  back() {
    this.$router.back();
  }
}
