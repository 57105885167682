
import { Component, Vue } from 'vue-property-decorator';
import { SkeletonLoader } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    SkeletonLoader
  }
})
export default class BalancesLoader extends Vue {}
