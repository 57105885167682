import { getChatToken } from '@/modules/common/services/http/account';

type CallbackFunction = (arg: string) => void;

/**
 * Fn utilizada para autenticar o usuário ao web widget da Zendesk com um JWT gerado no BE
 */
export async function zendeskAuth() {
  try {
    const { token } = await getChatToken();
    window.zE('messenger', 'loginUser', function (callback: CallbackFunction) {
      callback(token);
    });
  } catch {
    console.error('Não foi possível logar o usuário ao zendesk');
  }
}

export function zendeskEvents() {
  window.zE('messenger:on', 'open', function () {
    zendeskAuth();
  });
}
