import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';
import { InstrumentPriceCell } from '@/modules/trade/components/InstrumentPriceCell';
import { MoneyCell } from '@/modules/common/components/MoneyCell';
import { PositionsColumns } from '@/modules/trade/services/instruments/enums';
import {
  Instrument,
  InstrumentHistorical
} from '@/modules/trade/services/instruments/';
import { CashMarketFiltersDateValues } from '@/modules/trade/views/TradePositionsView/components/CashMarket/types';

export const columns = {
  [CashMarketFiltersDateValues.Today]: [
    {
      label: 'Ativo',
      value: PositionsColumns.name,
      component: InstrumentNameCell,
      getter: (instrument: Instrument) => ({
        symbol: instrument.symbol,
        name: instrument.name,
        image: instrument.image,
        type: instrument.type?.value,
        size: 'small',
        hasEllipsis: true
      })
    },
    {
      label: 'Preço',
      value: PositionsColumns.lastPrice,
      align: 'right',
      component: InstrumentPriceCell,
      getter: (instrument: Instrument) => ({
        price: instrument.lastPrice,
        percentual: instrument.dailyPercentageChange,
        size: 'small',
        align: 'right'
      })
    },
    {
      label: 'Preço médio',
      value: PositionsColumns.averagePrice,
      align: 'right',
      component: MoneyCell,
      getter: (instrument: Instrument) => ({
        text: instrument.averagePrice.formatted,
        align: 'right',
        enableToggleMoney: true
      })
    },
    {
      label: 'Quantidade',
      value: PositionsColumns.quantity,
      align: 'right',
      component: MoneyCell,
      getter: (instrument: Instrument) => ({
        text: instrument.quantity,
        align: 'right',
        enableToggleMoney: true
      })
    },
    {
      label: 'Saldo total',
      value: PositionsColumns.balance,
      align: 'right',
      component: MoneyCell,
      getter: (instrument: Instrument) => ({
        text: instrument.balance.formatted,
        align: 'right',
        enableToggleMoney: true
      })
    },
    {
      type: 'icon',
      getter: (instrument: Instrument) => ({
        icon: 'EA0170',
        ariaLabel: `Ver detalhes do ativo ${instrument.symbol}`
      })
    }
  ],
  [CashMarketFiltersDateValues.Historical]: [
    {
      label: 'Ativo',
      value: PositionsColumns.name,
      component: InstrumentNameCell,
      getter: (instrument: InstrumentHistorical) => ({
        symbol: instrument.symbol,
        name: instrument.name,
        image: instrument.image,
        type: instrument.type?.value,
        size: 'small',
        hasEllipsis: true
      })
    },
    {
      label: 'Preço',
      value: PositionsColumns.lastPrice,
      align: 'right',
      component: InstrumentPriceCell,
      getter: (instrument: InstrumentHistorical) => ({
        price: instrument.lastPrice,
        size: 'small',
        align: 'right'
      })
    },
    {
      label: 'Quantidade',
      value: PositionsColumns.quantity,
      align: 'right',
      component: MoneyCell,
      getter: (instrument: Instrument) => ({
        text: instrument.quantity,
        align: 'right',
        enableToggleMoney: true
      })
    },
    {
      label: 'Rent. no mês',
      value: PositionsColumns.profit,
      align: 'right',
      component: InstrumentPriceCell,
      getter: (instrument: InstrumentHistorical) => ({
        price: instrument.monthlyProfits.amount,
        percentual: instrument.monthlyProfits.percentage,
        size: 'small',
        align: 'right',
        enableToggleMoney: true
      })
    },
    {
      label: 'Rent. do início',
      value: PositionsColumns.profit,
      align: 'right',
      tooltip: {
        title: 'Rentabilidade do início',
        text: 'Os valores de rendimento e rentabilidade são apresentados a partir da data de corte de 03/01/2022.'
      },
      component: InstrumentPriceCell,
      getter: (instrument: InstrumentHistorical) => ({
        price: instrument.inceptionProfits.amount,
        percentual: instrument.inceptionProfits.percentage,
        size: 'small',
        align: 'right',
        enableToggleMoney: true
      })
    },
    {
      label: 'Saldo total',
      value: PositionsColumns.balance,
      align: 'right',
      component: MoneyCell,
      getter: (instrument: Instrument) => ({
        text: instrument.balance.formatted,
        align: 'right',
        enableToggleMoney: true
      })
    },
    {
      type: 'icon',
      getter: (instrument: InstrumentHistorical) => ({
        icon: 'EA0170',
        ariaLabel: `Ver detalhes do ativo ${instrument.symbol}`
      })
    }
  ]
};
