
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { FilterSelect, Link } from '@warrenbrasil/nebraska-web';
import {
  getOrderFilters,
  IFilters,
  IValueFomatted
} from '@/modules/wealth/services/wealth-orders';
import { FiltersLoader } from './components/FiltersLoader';

@Component({
  components: { FilterSelect, Link, FiltersLoader }
})
export default class OrderFilters extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  readonly isWallet!: boolean;

  @Emit('change')
  private emitChange() {
    if (this.isWallet) {
      return {
        filters: {
          direction: this.filtersValue.direction,
          period: this.filtersValue.period
        },
        hasAppliedFilters: this.hasAppliedFilters
      };
    }
    return {
      filters: this.filtersValue,
      hasAppliedFilters: this.hasAppliedFilters
    };
  }

  hasError = false;
  isLoading = false;

  filtersData: IFilters[] = [];

  filtersValue = {
    direction: '',
    period: '',
    portfolioApiId: ''
  };

  get hasAppliedFilters() {
    return Object.values(this.filtersValue).some(value => !!value);
  }

  created() {
    this.getOrderFilters();
  }

  getOptions(filterOptions: IValueFomatted<string>[]) {
    return this.parseFilterOptions(filterOptions);
  }

  shouldShowFilter(filter: string) {
    if (filter === 'portfolioApiId') {
      return !this.isWallet;
    }
    return true;
  }

  getValue(filter: string) {
    switch (filter) {
      case 'direction':
        return this.filtersValue.direction;
      case 'period':
        return this.filtersValue.period;
      case 'portfolioApiId':
        return this.filtersValue.portfolioApiId;
    }
  }

  handleChange(value: string, filter: string) {
    switch (filter) {
      case 'direction':
        this.filtersValue.direction = value;
        break;
      case 'period':
        this.filtersValue.period = value;
        break;
      case 'portfolioApiId':
        this.filtersValue.portfolioApiId = value;
        break;
    }

    this.emitChange();
  }

  parseFilterOptions(filterOptions: IValueFomatted<string>[]) {
    return filterOptions?.map(option => ({
      label: option.formatted,
      value: option.value
    }));
  }

  clearFilters() {
    this.filtersValue = {
      direction: '',
      period: '',
      portfolioApiId: ''
    };

    this.emitChange();
  }

  async getOrderFilters() {
    this.isLoading = true;
    try {
      const data = await getOrderFilters();
      this.filtersData = data.filters;
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }
}
