import { render, staticRenderFns } from "./InstrumentAvatarFallback.vue?vue&type=template&id=b7057810&scoped=true&"
import script from "./InstrumentAvatarFallback.vue?vue&type=script&lang=ts&"
export * from "./InstrumentAvatarFallback.vue?vue&type=script&lang=ts&"
import style0 from "./InstrumentAvatarFallback.vue?vue&type=style&index=0&id=b7057810&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7057810",
  null
  
)

export default component.exports