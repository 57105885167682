import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IGetChart,
  IGetHistorical,
  IPerformanceBenchmark,
  IPerformanceHistorical,
  IPerformancePeriods,
  IResult,
  PerformanceChart
} from '@/modules/investments/types/Performance';

export const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_URL
});

export async function getPerformanceChart(
  params: IGetChart
): Promise<IResult<PerformanceChart>> {
  return await http.get('consolidation/v1/customer/profitability/chart', {
    params
  });
}

export async function getPerformancePeriods(
  params: IGetChart
): Promise<IResult<IPerformancePeriods[]>> {
  return await http.get('consolidation/v1/customer/profitability/periods', {
    params
  });
}

export async function getPerformanceBenchmarks(
  params: IGetChart
): Promise<IResult<IPerformanceBenchmark[]>> {
  return await http.get('consolidation/v1/customer/profitability/benchmarks', {
    params
  });
}

export async function getPerformanceHistorical(
  params: IGetHistorical
): Promise<IResult<IPerformanceHistorical>> {
  return await http.get('consolidation/v1/customer/profitability/historical', {
    params
  });
}
