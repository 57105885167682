
import { Component, Vue, Emit } from 'vue-property-decorator';
import {
  getCustomer,
  resetPassword
} from '@/modules/common/services/http/account';
import { getString } from '@/modules/common/helpers/resources';
import { NotificationTypes } from '@/modules/common/services/http/notification';

@Component({})
export default class ForgotPassword extends Vue {
  readonly notificationGroup = 'user-template-notifications';
  readonly text = getString('common_field_forgot_password');

  @Emit('on-forgot-password')
  emitForgotPassword() {}

  forgotPassword() {
    this.emitForgotPassword();

    getCustomer()
      .then(({ customer }) => resetPassword({ email: customer.email }))
      .then(() =>
        this.$notify({
          group: this.notificationGroup,
          text: getString('login_forgot_password_msg_ok'),
          type: NotificationTypes.success
        })
      )
      .catch(error =>
        this.$notify({
          group: this.notificationGroup,
          text: error.message,
          type: NotificationTypes.error
        })
      );
  }
}
