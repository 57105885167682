
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  NavBar,
  BaseText,
  Row,
  RoundedIcon,
  BaseModal
} from '@warrenbrasil/nebraska-web';
import { ModalFooter } from '@/modules/common/components/__deprecated__/Modal';
import { CloseButton } from '@/modules/common/components/buttons';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { RiskScore } from '@/modules/common/components/RiskScore';
import { Checkbox } from '@/modules/common/components/__deprecated__/Checkbox';
import { ProductDetail } from '@/modules/common/components/ProductDetail';
import * as trackerRiskLevel from './track-events';

@Component({
  components: {
    BaseModal,
    ModalFooter,
    CloseButton,
    BackButton,
    NavBar,
    BaseText,
    Checkbox,
    Row,
    RoundedIcon,
    ProductDetail,
    RiskScore
  }
})
export default class RiskLevelModal extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public isOpen!: boolean;

  @Prop({ type: String, required: false, default: 'Criar Carteira' })
  public rightButtonText!: boolean;

  @Prop({ type: Boolean, default: false })
  public isInvestmentByProduct?: boolean;

  @Prop({ type: Array, required: false })
  public products!: string[];

  @Prop({ type: String, required: false })
  public computedRiskToleranceLabel!: string;

  private NebraskaColors = NebraskaColors;
  private checkboxValue = false;

  private trackCheckbox() {
    trackerRiskLevel.trackCheckbox();
  }

  @Emit('cancelled')
  public close() {
    trackerRiskLevel.trackClose();
  }

  @Emit('accepted')
  public emitAccepted() {
    trackerRiskLevel.trackAccepted();
    if (this.isInvestmentByProduct) {
      trackerRiskLevel.trackAcceptedAmplitude();
    }
  }

  private beforeMount() {
    if (!this.products.length) {
      this.$emit('accepted');
    }
  }

  private openInvestorProfileModal() {
    this.$emit('open-investor-profile');
  }
}
