import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  DefaultHttpClient,
  HttpRequest
} from '@microsoft/signalr';
import { getAccessToken } from '@/modules/common/services/access-token';
import { InvokeData } from './types';

export class HttpClient extends DefaultHttpClient {
  constructor() {
    super(console);
  }

  async send(request: HttpRequest) {
    request.headers = {
      ...request.headers,
      'access-token': getAccessToken() as string
    };
    return super.send(request);
  }
}

export class SignalRService {
  readonly connection!: HubConnection;
  private readonly url = String(process.env.VUE_APP_SIGNALR_URL);
  private readonly httpConnectionOptions: IHttpConnectionOptions = {
    httpClient: new HttpClient()
  };

  private invokeSubscribers: InvokeData[] = [];

  constructor(url?: string, httpConnectionOptions?: IHttpConnectionOptions) {
    if (url) this.url = url;
    if (httpConnectionOptions)
      this.httpConnectionOptions = httpConnectionOptions;
    this.connection = new HubConnectionBuilder()
      .withUrl(this.url, this.httpConnectionOptions)
      .withAutomaticReconnect()
      .build();
  }

  /**
   * Inicia a conexão com o hub e publica todos os invokes que não foram enviados
   * por a conexão não ter sido estabelicida quando invocados
   */
  async startConnection() {
    if (this.connection.state === HubConnectionState.Disconnected) {
      await this.connection.start();
      await this.publishAllInvokeSubscribers();
    }
  }

  /**
   * Finaliza a conexão com o hub e remove todos os invokes que não foram enviados
   * por a conexão não ter sido estabelicida quando invocados
   */
  async stopConnection() {
    this.removeAllInvokeSubscribers();
    if (
      this.connection.state !== HubConnectionState.Disconnected &&
      this.connection.state !== HubConnectionState.Disconnecting
    ) {
      await this.connection.stop();
    }
  }

  /**
   * Remove todos os invokes que estão guardados na fila para serem enviados quando
   * conexão ser estabelicida
   */
  async removeAllInvokeSubscribers() {
    this.invokeSubscribers = [];
  }

  /**
   * Envia toda a lista de invoke pendentes para serem emitidos para o hub
   */
  async publishAllInvokeSubscribers() {
    for (const { hubMethod, args } of this.invokeSubscribers) {
      try {
        await this.connection.invoke(hubMethod, ...args);
      } catch {
        console.error(`Failed trying to invoke hub method "${hubMethod}"`);
      }
    }
    this.removeAllInvokeSubscribers();
  }

  private createInvokeWrapper(hubMethod: string, ...args: any[]) {
    return {
      args,
      hubMethod
    };
  }

  /**
   * Se a conexão com o hub estiver estabelecida será enviado o invoke diretamente
   * para o hub ou então ele será guardado para ser publicado quando a conexão for estabelecida
   */
  async invokeWithSubscription(hubMethod: string, ...args: any[]) {
    if (this.connection.state === HubConnectionState.Connected) {
      await this.connection.invoke(hubMethod, ...args);
    } else {
      const subscriber = this.createInvokeWrapper(hubMethod, ...args);
      this.invokeSubscribers.push(subscriber);
    }
  }
}
