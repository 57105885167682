import { GtmTracker } from '@/modules/common/services/analytics';

const trackSuitabilityProfileEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'suitability_profile_result',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackPageView() {
  trackSuitabilityProfileEvent({
    event_type: 'load',
    event_name: 'view'
  });
  trackSuitabilityProfileEvent({
    event_type: 'load',
    event_name: 'navigation_profile_discovery_finished',
    is_conversion: true
  });
}

const trackUserProfileEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'navigation_profile_discovery_finished',
  digital_experience: 'investor',
  page_screen_section: 'onboarding',
  experience_section: 'onboarding'
});

const trackUserProfileEventError = GtmTracker.trackerWithDefaultProps({
  page_screen: 'navigation_profile_discovery_finished_error',
  digital_experience: 'investor',
  page_screen_section: 'onboarding',
  experience_section: 'onboarding'
});

export function trackUserProfilePageView() {
  trackUserProfileEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackUserProfileCloseButton() {
  trackUserProfileEvent({
    event_type: 'click',
    event_name: 'close'
  });
}

export function trackUserProfileButtonClick(event_name: string) {
  trackUserProfileEvent({
    event_type: 'click',
    event_name
  });
}

export function trackUserProfileErrorView() {
  trackUserProfileEventError({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackUserProfileErrorClick() {
  trackUserProfileEventError({
    event_type: 'click',
    event_name: 'try_again'
  });
}
