var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'template-container',
    {
      'template-container--impersonate': _vm.isImpersonate,
      'template-container--loaded': _vm.addLoadedClass
    }
  ]},[_c('div',{staticClass:"template-container__background-wrapper"},[_c('div',{staticClass:"template-container__background"},[_vm._t("main-background")],2)]),_c('div',{staticClass:"template-container__main"},[_c('RegulatoryUpdateWarning'),_c('div',{staticClass:"template-container__main-content"},[_c('div',{staticClass:"max-width"},[_vm._t("main")],2)])],1),_c('div',{ref:"contentSection",staticClass:"template-container__content"},[_c('div',{staticClass:"max-width"},[_vm._t("content")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }