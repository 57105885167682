
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import {
  ActionableHelperView,
  BaseModal,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

import { RiskLevelModal } from './components/RiskLevelModal';
import { TermsAcceptanceModal } from './components/TermsAcceptanceModal';
import { InvestorProfileModal } from './components/InvestorProfileModal';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { IInvestmentProfile } from '@/types/models';
import {
  IInstrumentValidation,
  portfolioInstrumentValidation,
  instrumentValidation as instrumentValidationService
} from '@/modules/wealth/services/investment';
import { BffInvestments } from '@/modules/wallet-builder/src/services/bff-investments';

@Component({
  components: {
    TermsAcceptanceModal,
    RiskLevelModal,
    InvestorProfileModal,
    LoaderIndicator,
    BaseModal,
    ActionableHelperView
  }
})
export default class RiskValidation extends Vue {
  @Prop({ type: Boolean, default: false, required: false })
  public showRiskValidation!: boolean;

  @Prop({ type: String, required: false, default: 'Criar carteira' })
  public submitButtonText!: string;

  @Prop({ type: Array, required: false, default: () => [] })
  public productsIds?: string[];

  @Prop({ type: String, required: false, default: '' })
  public portfolioId?: string;

  @Prop({ type: Boolean, required: false, default: false })
  public isInvestmentTransaction?: boolean;

  @Prop({ type: Object, required: true })
  public investmentProfile!: IInvestmentProfile;

  @Prop({ type: Boolean, default: false })
  public isInvestmentByProduct?: boolean;

  @Prop({ type: Boolean, default: false })
  public suggestInvestment!: boolean;

  private hasAcceptedTerms = false;

  private products: IInstrumentValidation | null = null;

  private isLoadingProducts = true;

  private isLoadingTerms = true;

  private NebraskaColors = NebraskaColors;

  private viewTerms = true;

  private isOpenInvestorProfileModal = false;

  private hasError = false;

  public bff = new BffInvestments();

  public get isLoading() {
    return this.isLoadingProducts;
  }

  private get shouldShowRiskLevel() {
    if (
      !this.viewTerms &&
      !this.getDependentOnTerms() &&
      !this.isOpenInvestorProfileModal
    ) {
      return this.showRiskValidation;
    }
    if (!this.isOpenInvestorProfileModal) {
      return this.showRiskValidation && this.hasAcceptedTerms;
    }

    return false;
  }

  private get shouldShowRiskTerms() {
    return this.showRiskValidation && !this.hasAcceptedTerms;
  }

  private async onRiskValidationSuccess() {
    const isRiskSignatureSuccess = await this.riskSignature();

    if (!isRiskSignatureSuccess) this.hasError = true;
    else this.$emit('on-risk-validation-success');
  }

  private async onRiskValidationErrorButton() {
    this.hasError = false;
  }

  private showRiskLevel() {
    if (this.viewTerms) {
      this.toggleAcceptTerms();
    } else {
      this.hasAcceptedTerms = !this.hasAcceptedTerms;
      this.onRiskValidationAbort();
    }
  }

  private async acceptTerms() {
    this.hasError = false;
    const termSignature = await this.termSignature();
    if (!termSignature) return (this.hasError = true);
    this.hasAcceptedTerms = true;
  }

  private toggleAcceptTerms() {
    this.hasAcceptedTerms = !this.hasAcceptedTerms;
  }

  private onRiskValidationAbort() {
    this.$emit('on-risk-validation-abort');
  }

  private async mounted() {
    // the terms will never change on user session
    await this.getProducts();
  }

  public async termSignature() {
    try {
      const instrumentIds = this.getDependentOnTerms().map(
        instrument => instrument?.apiId
      );
      if (!instrumentIds?.length) return true;
      await this.bff.termSignature(instrumentIds);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  public async riskSignature() {
    try {
      const instrumentIds = this.getDependentOnRisks().map(
        instrument => instrument?.apiId
      );
      if (!instrumentIds?.length) return true;
      await this.bff.riskSignature(instrumentIds);
      return true;
    } catch (e) {
      return false;
    }
  }

  private async getInstrumentValidation() {
    if (this.isInvestmentTransaction && this.portfolioId) {
      const { instrumentValidation } = await portfolioInstrumentValidation(
        this.portfolioId
      );
      this.products = instrumentValidation as IInstrumentValidation;
    } else if (this.productsIds) {
      const { instrumentValidation } = await instrumentValidationService(
        this.productsIds
      );
      this.products = instrumentValidation as IInstrumentValidation;
    }
  }

  @Watch('showRiskValidation')
  public async getProducts() {
    if (!this.showRiskValidation) {
      return;
    }
    try {
      this.isLoadingProducts = true;
      await this.getInstrumentValidation();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingProducts = false;
    }
  }

  private getDependentOnRisks() {
    return this.products?.dependentOnRisks || [];
  }

  private getDependentOnTerms() {
    return this.products?.dependentOnTerms || [];
  }

  private openInvestorProfileModal() {
    this.isOpenInvestorProfileModal = true;
  }

  private closeInvestorProfileModal() {
    this.isOpenInvestorProfileModal = false;
  }
}
