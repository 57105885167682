
import { Component, Emit, Vue } from 'vue-property-decorator';
import {
  NavBar,
  IconButton,
  ButtonPrimary,
  Footer,
  HelperView
} from '@warrenbrasil/nebraska-web';
import * as trackEvents from './track-events';

@Component({
  components: {
    NavBar,
    IconButton,
    ButtonPrimary,
    Footer,
    HelperView
  }
})
export default class DisconnectDevicesFeedback extends Vue {
  @Emit('previous-step')
  public goToPreviousStep() {
    trackEvents.trackCloseClick();
  }

  public goLogin() {
    trackEvents.trackBackToLogin();
    this.$router.back();
  }

  mounted() {
    trackEvents.trackDisconnectDevicesFeedbackLoad();
  }
}
