import { GtmTracker } from '@/modules/common/services/analytics';

const trackTradeEvent = GtmTracker.trackerWithDefaultProps({
  category: 'Trade'
});

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'search_asset',
  digital_experience: 'investor',
  experience_section: 'broker'
});

export function trackSearchViewLoad() {
  trackAmplitudeEvent({ event_type: 'load', event_name: 'view' });
}

export function trackFilterClick(filter: string) {
  trackTradeEvent({
    action: `Filtrou ${filter}`,
    label: 'Busca de Ativos'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'header',
    event_name: 'select_asset_type',
    instrument_type: filter
  });
}

export function trackHighlightClick(filter: string, highlightTitle: string) {
  trackTradeEvent({
    action: `Acessou Destaque ${highlightTitle}`,
    label: 'Lista de Ativos - Busca'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'highlights',
    event_name: 'select_asset_list',
    list_name: highlightTitle,
    instrument_type: filter
  });
}

export function trackSectionClick(filter: string, sectionTitle: string) {
  trackTradeEvent({
    action: `Acessou Setor ${sectionTitle}`,
    label: 'Lista de Ativos - Busca'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'categories',
    event_name: 'select_asset_category',
    list_name: sectionTitle,
    instrument_type: filter
  });
}

export function trackSearchInstruments(searchText: string) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'search_bar',
    event_name: 'view_search_results',
    search_word: searchText
  });
}

export function trackSearchInstrumentClick(
  instrumentSymbol: string,
  instrumentName: string,
  searchText: string
) {
  trackTradeEvent({
    action: `Clique em ${instrumentSymbol}`,
    label: 'Buscar Ativos'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'search_bar',
    event_name: 'select_asset',
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol,
    search_word: searchText
  });
}
