
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { BaseText, BaseIcon } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'CardsTypeInvestment',
  components: { BaseText, BaseIcon }
})
export default class CardsTypeInvestment extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: String, required: false })
  readonly title?: string;

  @Prop({ type: String, required: false })
  readonly subtitle?: string;

  @Prop({ type: String, required: false })
  readonly icon?: string;

  @Emit('on-button-click')
  onButtonClick() {}
}
