import { render, staticRenderFns } from "./PortfoliosList.vue?vue&type=template&id=f07e4af4&scoped=true&"
import script from "./PortfoliosList.vue?vue&type=script&lang=ts&scoped=true&"
export * from "./PortfoliosList.vue?vue&type=script&lang=ts&scoped=true&"
import style0 from "./PortfoliosList.vue?vue&type=style&index=0&id=f07e4af4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f07e4af4",
  null
  
)

export default component.exports