import { EVariableIncomeWithdrawSteps } from './types';
import { Withdraw } from '../components/Withdraw';
import { Preview } from '../components/Preview';
import { Conclusion } from '../components/Conclusion';

export const VariableIncomeWithdrawComponents = {
  [EVariableIncomeWithdrawSteps.INVESTMENT]: Withdraw,
  [EVariableIncomeWithdrawSteps.PREVIEW]: Preview,
  [EVariableIncomeWithdrawSteps.CONCLUSION]: Conclusion
};
