
import { Component, Vue, Prop, Emit, Inject } from 'vue-property-decorator';
import { ModalFooterWallet } from '@/modules/wallet-builder/src/components/Modals/ModalFooter';
import { NavBar, BaseModal, BaseText } from '@warrenbrasil/nebraska-web';

import { GtmTracker } from '@/modules/common/services/analytics';

@Component({
  components: {
    BaseModal,
    ModalFooterWallet,
    NavBar,
    BaseText
  }
})
export default class PreventUnsavedChangesModal extends Vue {
  @Prop({ type: Boolean, required: true })
  public isOpen!: boolean;

  @Inject('resetWallet')
  private resetWallet!: Function;

  private trackEvent(action: string) {
    GtmTracker.trackEvent({
      category: 'PEX - Carteira Editada',
      action: `Sair ${action}`,
      label: 'B2C'
    });
  }

  @Emit('close-prevent-unsaved-changes-modal')
  public closeModal() {
    this.trackEvent('- Cancelar');
  }

  @Emit('confirm-prevent-unsaved-changes-modal')
  public confirm() {
    this.resetWallet();
    this.trackEvent('- Confirmar');
  }
}
