/**
 * A method to return an asset path from https://github.com/warrenbrasil/warren-assets repo
 * @param {string} assetPath - relative path from asset
 * @returns String with the complete asset path
 */
export const getExternalAssetPath = (assetPath: string) => {
  const amplifyRegex = /^pr-.*amplifyapp.com/g;
  const isLocalHost = window.location.hostname === 'localhost';
  const isAmplifyPreview = amplifyRegex.test(window.location.hostname);

  const urlAssets =
    isLocalHost || isAmplifyPreview
      ? 'https://stg.warren.com.br'
      : window.location.origin;

  return `${urlAssets}/assets/${assetPath}`;
};
