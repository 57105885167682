import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';

import {
  AllocationType,
  Assets,
  Summary,
  InstrumentClass,
  PerformanceBenchmark,
  BenchmarkPeriod,
  BenchmarkFilterOptions
} from '@/modules/wallet-builder/src/types';
import { subDays } from 'date-fns';
import {
  ChartFormatted,
  IGetInstrument,
  IGetProductsPayload,
  IGetProductsResponse,
  IInstrument
} from '../../services/types/models/IBffInvestments';
import { getProducts } from '../../services/bff-investments';
import { getBrand } from '@/modules/common/services/local-storage';

const START_DATE_SUB_DAYS = 365;
const MyAssets = (
  data?: Pick<
    IGetInstrument,
    | 'fixedIncomeTotalRecords'
    | 'variableIncomeTotalRecords'
    | 'otherTotalRecords'
  >
): Assets[] => {
  return [
    {
      name: AllocationType.FIXED_INCOME,
      total: data?.fixedIncomeTotalRecords || 0,
      type: InstrumentClass.Fixedincome
    },
    {
      name: AllocationType.VARIABLE_INCOME,
      total: data?.variableIncomeTotalRecords || 0,
      type: InstrumentClass.Variableincome
    },
    {
      name: AllocationType.OTHERS,
      total: data?.otherTotalRecords || 0,
      type: InstrumentClass.Others
    }
  ];
};

@Component
export class Instruments extends Vue {
  @Prop({ type: Array, required: false, default: () => [] })
  public allocations!: ChartFormatted[];

  list: IInstrument[] = [];
  private summary: Summary = {
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    assets: MyAssets()
  };

  @Watch('allocations')
  private productsAlreadyAdded() {
    this.listInstrumentsFormatted();
  }

  private listInstrumentsFormatted() {
    const apiIds: Array<string> = [];

    this.allocations.forEach(item => {
      apiIds.push(item.id);
    });

    const listFormatted: IInstrument[] = this.list.map(item => {
      return {
        ...item,
        isAdded: apiIds.some((apiId: string) => apiId === item.apiId)
      };
    });

    return listFormatted;
  }

  private isLoadingInstruments = false;
  private errors: any = false;
  private perPage = 10;
  private indexPage = 1;
  public search?: string;
  protected categoryName: InstrumentClass = InstrumentClass.Fixedincome;

  public initialPage = 0;

  private filter: BenchmarkFilterOptions = {
    marketIndex: PerformanceBenchmark.CDI,
    period: BenchmarkPeriod.OneYear,
    startDate: subDays(new Date(), START_DATE_SUB_DAYS),
    endDate: subDays(new Date(), 1)
  };

  public get instruments(): IInstrument[] {
    return this.listInstrumentsFormatted();
  }

  public get instrumentsSummary(): Summary {
    return this.summary;
  }

  public get isLoading(): boolean {
    return this.isLoadingInstruments;
  }

  private setInstruments(value: IInstrument[]) {
    this.list = [...this.list, ...value];
  }

  public setIsLoading(value: boolean) {
    this.isLoadingInstruments = value;
  }

  private resetInstruments() {
    this.list = [];
  }

  private setInstrumentsSummary(value: Summary) {
    this.summary = { ...this.summary, ...value };
  }

  public addInstruments(instruments: IInstrument[]) {
    // should make a try catch here
    this.setInstruments(instruments);
  }

  public addInstrumentsSummary(summary: Summary) {
    // should make a try catch here
    this.setInstrumentsSummary(summary);
  }

  private get maxPerPage() {
    return this.perPage;
  }

  public get hasItems() {
    return !!this.list;
  }

  public resetPages() {
    this.indexPage = 1;
    this.resetInstruments();
  }

  public get tabsList() {
    return this.instrumentsSummary.assets as Assets[];
  }

  private async getInstruments(payload: IGetProductsPayload) {
    try {
      const { products }: IGetProductsResponse = await getProducts(payload);
      return products as IGetInstrument;
    } catch {
      const emptyState: IGetInstrument = {
        fixedIncomeTotalRecords: 0,
        otherTotalRecords: 0,
        pageIndex: 0,
        pageSize: 0,
        totalPages: 0,
        totalRecords: 0,
        variableIncomeTotalRecords: 0,
        instruments: []
      };
      return emptyState;
    }
  }

  public async fetchInstruments(filter?: {
    benchmarkFilter?: BenchmarkFilterOptions;
    filtersParams?: string;
  }) {
    this.isLoadingInstruments = true;
    if (
      filter &&
      filter.benchmarkFilter &&
      filter.benchmarkFilter?.marketIndex
    ) {
      this.filter = filter.benchmarkFilter;
    }
    const brand = getBrand();
    const brandApiId = brand?._id;
    const payload: IGetProductsPayload = {
      pageSize: this.maxPerPage,
      pageIndex: this.indexPage,
      categoryName: this.getCategoryType(this.categoryName),
      brandApiId,
      ...this.filter,
      filters: filter && filter.filtersParams ? filter.filtersParams : ''
    };

    if (this.search) {
      payload.searchName = this.search;
    }

    try {
      const data: IGetInstrument = await this.getInstruments(payload);

      const assets = MyAssets(data);

      const summary: Summary = {
        pageSize: data.pageSize!,
        totalPages: data.totalPages!,
        totalRecords: data.totalRecords!,
        assets
      };

      await this.addInstrumentsSummary(summary);

      if (data) {
        const instrumentsList: IInstrument[] = data.instruments;
        instrumentsList.forEach((item: IInstrument) => {
          item.isAdded = false;
        });
        await this.addInstruments(instrumentsList);
        this.indexPage++;
      }
    } catch (e) {
      this.errors = e;
    } finally {
      this.listInstrumentsFormatted();
      this.isLoadingInstruments = false;
    }
  }

  @Inject('addProductToWallet')
  private addProductToWallet!: Function;

  public handleInstrumentAdd(instrument: IInstrument) {
    instrument.type = this.categoryName;
    return this.addProductToWallet(instrument);
  }

  @Inject('removeProductFromWallet')
  private removeProductFromWallet!: Function;

  public handleInstrumentRemove(instrument: IInstrument) {
    instrument.type = this.categoryName;
    return this.removeProductFromWallet({
      productIds: [instrument.apiId]
    });
  }

  public getCategoryType(categoryName: string) {
    if (categoryName === InstrumentClass.Others) {
      return 'OTHER';
    }
    return categoryName;
  }

  public handleInstrumentsOpen(instrument: IInstrument) {
    this.$emit('instruments-handle-open', { instrument });
  }

  public async handleInstrumentsTabsChange(tab: number) {
    this.categoryName = this.tabsList[tab].type;

    this.resetPages();
  }
}
