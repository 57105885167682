import { httpClient } from '@/modules/common/services/http/http-client';
import {
  ProfitOptions,
  ProfitPreview,
  ProfitSignin,
  SignProfitOption,
  ProfitCancelMenu,
  ProfitCancel
} from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;

export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getProfitOptions(): Promise<ProfitOptions> {
  return httpClientWithDefaultOptions.get('/getProfitOptions');
}

export function getProfitPreview(profitType: number): Promise<ProfitPreview> {
  return httpClientWithDefaultOptions.get('/getProfitPreview', {
    params: { type: profitType }
  });
}

export function signProfitOption(
  profitSigninOptions: ProfitSignin
): Promise<SignProfitOption> {
  return httpClientWithDefaultOptions.post(
    '/signProfitOption',
    profitSigninOptions
  );
}

export function getProfitCancelMenu(): Promise<ProfitCancelMenu> {
  return httpClientWithDefaultOptions.get('/profit/cancel/menu');
}

export function cancelProfit(
  productId: number,
  cancellationReason: string
): Promise<ProfitCancel> {
  return httpClientWithDefaultOptions.post('/profit/cancel', {
    productId,
    cancellationReason
  });
}

export function verifyPassword(password: string): Promise<boolean> {
  return httpClientWithDefaultOptions.post('/verifyPassword', { password });
}
