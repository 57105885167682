
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  ButtonPrimary,
  Footer,
  HelperViewContextual
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    ButtonPrimary,
    HelperViewContextual,
    Footer
  }
})
export default class MFAViewActivated extends Vue {
  private NebraskaColors = NebraskaColors;

  @Prop({ type: String, default: '' })
  private userMFAActivationDate?: string;

  readonly title = 'Verificação em duas etapas ativa.';
  readonly buttonLabel = 'Voltar';

  private get description() {
    return `A verificação em duas etapas foi ativada em ${this.userMFAActivationDate}.`;
  }

  @Emit()
  private goBack() {}
}
