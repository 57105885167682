
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  Modal,
  HelperViewStatus,
  ButtonContainer,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Modal,
    HelperViewStatus,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary
  }
})
export default class RegulatoryUpdateCloseModal extends Vue {
  @Prop({ type: Boolean, required: true })
  public readonly isOpen!: boolean;

  @Emit()
  public toggleModalOpen() {}

  @Emit()
  public exit() {}
}
