
import {
  Vue,
  Component,
  Prop,
  Emit,
  InjectReactive
} from 'vue-property-decorator';
import { InputText, LoaderIndicator } from '@warrenbrasil/nebraska-web';
import { moneyFormat } from '@/modules/common/helpers/currency';

import { SubscriptionOrder } from './components/SubscriptionOrder';
import {
  InstrumentValue,
  BalanceValue,
  SubscriptionActionStatus
} from './types';
import { SubscriptionConfirm } from './components/SubscriptionConfirm';
import { SubscriptionStatus } from './components/SubscriptionStatus';
import { SubscriptionDetail } from './components/SubscriptionDetail';
import {
  createOrderSubscription,
  updateSubscription
} from '@/modules/trade/services/subscription';
import { EventSubscriptionType } from '@/modules/trade/services/subscription/enums';

enum SidebarTypes {
  SubscriptionOrder,
  SubscriptionConfirm,
  SubscriptionStatus
}

enum SubscriptionErros {
  invalidQuantity = 'O valor ultrapassa o limite de quantidade',
  minValueQuantityInvalid = 'O valor é inválido'
}

@Component({
  components: {
    SubscriptionOrder,
    SubscriptionConfirm,
    SubscriptionStatus,
    InputText,
    LoaderIndicator,
    SubscriptionDetail
  }
})
export default class SubscriptionSidebar extends Vue {
  @Prop({ type: Number, required: true })
  public readonly availableQuantity!: number;

  @Prop({ type: Object, required: true })
  public readonly instrumentValue!: InstrumentValue;

  @Prop({
    type: Object,
    default: () => ({
      value: 0,
      formatted: 'R$ 0,00'
    })
  })
  readonly availableBalance!: BalanceValue;

  @Prop({ type: String, required: true })
  readonly statusType!: SubscriptionActionStatus;

  @Prop({ type: String, required: true })
  readonly closingDate!: string;

  @Prop({ type: Boolean, required: true })
  readonly isEditingSubscriptionOrder!: boolean;

  @Prop({ type: Number, required: true })
  readonly step!: number;

  @Prop({ type: String })
  readonly subscriptionType!: string;

  @InjectReactive()
  readonly instrumentType!: string;

  @InjectReactive()
  readonly instrumentName!: string;

  @Prop({ type: Boolean, required: true })
  readonly isActiveSubscribed!: boolean;

  @Prop({ type: Number })
  readonly subscriptionQuantity!: number;

  @Prop({ type: String })
  readonly unconfirmedRequestedQuantity!: string;

  public errorMessage: string | null = null;
  public inputValue!: string | null;
  readonly sidebarTypes = SidebarTypes;
  public isLoading = false;
  public isError = false;

  created() {
    this.inputValue = this.unconfirmedRequestedQuantity || '1';
    this.validateSubscriptionOrder(this.inputValue);
  }

  get totalValueEstimated() {
    // TODO - this conversion is temporary because of a reported problem in inputText
    return Number(this.inputValue) * this.instrumentValue?.value;
  }

  get totalValueEstimatedFormatted() {
    return moneyFormat(this.totalValueEstimated);
  }

  get isFirstAccessSubscriptionInvalid() {
    return this.errorMessage === null;
  }

  get isSubscriptionInvalid() {
    return this.errorMessage !== '' && this.errorMessage !== null;
  }

  get subscriptionStatus() {
    return this.isSubscriptionInvalid ? 'error' : null;
  }

  private validateSubscriptionOrder(quantity: string | null) {
    this.errorMessage = null;

    if (quantity === '0') {
      this.inputValue = null;
      return;
    }

    // TODO - this conversion is temporary because of a reported problem in inputText
    if (this.availableQuantity < Number(quantity)) {
      this.errorMessage = SubscriptionErros.invalidQuantity;
      return;
    }

    if (Number(quantity) < 1) {
      this.errorMessage = SubscriptionErros.minValueQuantityInvalid;
      return;
    }

    this.errorMessage = '';
  }

  public changeOrderStep() {
    this.validateSubscriptionOrder(this.inputValue);

    if (!this.isSubscriptionInvalid)
      this.setStep(SidebarTypes.SubscriptionConfirm);
  }

  public finishSubscription() {
    this.isLoading = true;
    const value = Number(this.inputValue);
    const symbol = this.$route.query.ticker as string;
    const unsubscribedRight =
      this.subscriptionType === EventSubscriptionType.LeftOversSubscription;

    this.setStep(SidebarTypes.SubscriptionStatus);

    createOrderSubscription(symbol, value, unsubscribedRight)
      .then(() => this.setStatusType(SubscriptionActionStatus.Register))
      .catch(() => {
        this.setStatusType(SubscriptionActionStatus.ErrorRegister);
        this.isError = true;
      })
      .finally(() => (this.isLoading = false));
  }

  public finishUpdateSubscription() {
    this.isLoading = true;
    const symbol = this.$route.query.ticker as string;
    const unsubscribedRight =
      this.subscriptionType === EventSubscriptionType.LeftOversSubscription;
    const quantity = Number(this.inputValue);

    this.setStep(SidebarTypes.SubscriptionStatus);

    updateSubscription(symbol, quantity, unsubscribedRight)
      .then(() => this.setStatusType(SubscriptionActionStatus.Update))
      .catch(() => {
        this.setStatusType(SubscriptionActionStatus.ErrorUpdate);
        this.isError = true;
      })
      .finally(() => (this.isLoading = false));
  }

  @Emit('toggle-subscription')
  public cancelModal() {}

  @Emit('set-sidebar-step')
  public setStep(step: number) {
    return step;
  }

  @Emit('set-status-type')
  public setStatusType(type: SubscriptionActionStatus) {
    return type;
  }

  @Emit('cancel-subscription')
  cancelOrder() {}
}
