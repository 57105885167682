import { IProductAllocation } from '@/modules/wealth/services/investment';

export const parseAllocationsToChartData = (data: IProductAllocation[]) => {
  return data.map(allocation => {
    return {
      value: allocation.allocValue.value,
      label: allocation.type,
      color: allocation.color
    };
  });
};
