
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BaseIcon, FilterFoundation } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    BaseIcon,
    FilterFoundation
  }
})
export default class InstrumentFilterSelect extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly active!: boolean;

  @Prop({ type: String, default: 'Todos os ativos' })
  readonly label!: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  get iconName() {
    return this.isOpen ? 'ic_chevron_up' : 'ic_chevron_down';
  }
}
