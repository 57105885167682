import { NavigationGuard } from 'vue-router';
import { PortfolioModule } from '@/store/modules/PortfolioModule';
import { CustomerModule } from '@/modules/common/store/CustomerModule';

export const beforeEnterInvestmentFlow: NavigationGuard = (
  _to,
  _from,
  next
) => {
  const isRegulatoryStatusBlocked = CustomerModule.isRegulatoryStatusBlocked;

  if (isRegulatoryStatusBlocked) {
    return next({ name: 'wealth', query: { regulatoryModal: 'true' } });
  }

  if (PortfolioModule.wasFetchedOnce) return next();

  PortfolioModule.fetchPortfolios();
  next();
};
