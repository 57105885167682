export enum EMfaState {
  Initial,
  WaitingToken,
  ResendToken
}

export interface IMfaAction {
  label: string;
  handler: Function;
  isDisabled?: boolean;
}
