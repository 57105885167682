
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { GtmTracker } from '@/modules/common/services/analytics';
import { IDetailedInstrument } from '@/modules/common/services/http/instrument';

import { Row, BaseText, Heading } from '@warrenbrasil/nebraska-web';

type ProductDetails = Pick<IDetailedInstrument, 'name' | 'apiId'>;

@Component({
  components: { Row, BaseText, Heading }
})
export default class ProductQuotasSection extends Vue {
  @Prop({ type: Object, required: true })
  private productDetails!: ProductDetails;

  private get NebraskaColors() {
    return NebraskaColors;
  }

  private handleRowClick() {
    this.trackRowClick();
    this.redirectToPositionQuotes();
  }

  private trackRowClick() {
    GtmTracker.trackEvent({
      action: 'Clique Ver detalhes',
      category: 'PEX - Detalhes do Produto',
      label: this.productDetails.name
    });
  }

  private redirectToPositionQuotes() {
    this.$router.push({
      name: 'investmentsPositionDetail',
      params: {
        productApiId: this.productDetails.apiId,
        fromProductDetails: String(true)
      }
    });
  }
}
