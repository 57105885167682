
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { InputText } from '@warrenbrasil/nebraska-web';
import { EStatusType } from '@/modules/account/types';
import { getString } from '@/modules/common/helpers/resources';
import { IBankAccount } from '@/types/models';

interface BankDataInput {
  value: string;
  label?: string;
  mask: string;
  status?: EStatusType;
  error: string;
  labelSavings?: string;
  labelCurrent?: string;
}

const emptyBankDataInputState = {
  value: '',
  label: '',
  mask: '',
  status: undefined,
  error: ''
};

@Component({
  components: { InputText }
})
export default class BankData extends Vue {
  @Prop({ default: () => [], required: false }) private bank!: IBankAccount;

  private agency: BankDataInput = { ...emptyBankDataInputState };
  private number: BankDataInput = { ...emptyBankDataInputState };
  private digit: BankDataInput = { ...emptyBankDataInputState };

  private validateValue() {
    this.agency.value = this.agency.value.padStart(4, '0');
    this.agencyValidation();
    this.numberValidation();
    this.digitValidation();
    this.emitUpdatedBankData();
  }

  @Emit('update-bank-data')
  emitUpdatedBankData() {
    return {
      agency: this.agency.value,
      number: this.number.value,
      digit: this.digit.value
    };
  }

  private agencyValidation() {
    const agencyValidated = this.agency.value !== '0000';
    this.agency.error = agencyValidated ? '' : 'A agência deve ser válida';
    this.setStatus(this.agency);
    return agencyValidated;
  }

  private numberValidation() {
    const numberValidated = this.number.value !== '';
    this.number.error = numberValidated ? '' : 'Campo obrigatório';
    this.setStatus(this.number);
    return numberValidated;
  }

  private digitValidation() {
    const digitValidated = this.digit.value !== '';
    this.digit.error = digitValidated ? '' : 'Campo obrigatório';
    this.setStatus(this.digit);
    return digitValidated;
  }

  private setStatus(data: any) {
    data.status =
      data.value && !data.error ? EStatusType.Success : EStatusType.Error;
  }

  created() {
    this.agency = {
      value: this.bank.agency,
      label: getString('common_field_bankaccount_agency_label'),
      mask: '####',
      status: this.bank.agency ? EStatusType.Success : EStatusType.Error,
      error: ''
    };

    this.number = {
      value: this.bank.number,
      labelSavings: getString('common_field_bankaccount_digit_account_label'),
      labelCurrent: getString('common_field_bankaccount_number_label'),
      mask: '############',
      status: this.bank.number ? EStatusType.Success : EStatusType.Error,
      error: ''
    };

    this.digit = {
      value: this.bank.digit,
      label: getString('common_field_bankaccount_digit_label'),
      mask: '#',
      status: this.bank.digit ? EStatusType.Success : EStatusType.Error,
      error: ''
    };
  }
}
