import { httpClient } from '@/modules/common/services/http/http-client';
import { INotificationList, INotificationCount } from './types';

export function getNotifications(): Promise<INotificationList> {
  return httpClient.get(`/api/v2/logs/mine`);
}

export function getNotificationsCount(): Promise<INotificationCount> {
  return httpClient.get(`/api/v2/logs/count`);
}

export function markAsRead(
  notificationIds: string[]
): Promise<INotificationList> {
  return httpClient.put(`/api/v2/logs/read`, {
    ids: notificationIds
  });
}
