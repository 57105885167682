export enum EMethods {
  DEFAULT = 'default',
  SMS = 'sms',
  PUSH = 'push'
}

export enum MFAModules {
  TRADE = 'trade',
  MENU_CHANGE_EMAIL = 'user-email',
  MENU_EDIT_REGISTER = 'user-account'
}
