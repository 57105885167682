
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import noDataChartImagePath from '@/assets/imgs/portfolio/no-data-chart.png';
import noDataChartDarkImagePath from '@/assets/imgs/portfolio/no-data-chart-dark.png';
import { useDashStore } from '@/modules/common/store/DashModule';
import { NoContentTexts } from './constants';

@Component({
  components: { BaseText }
})
export default class ChartNoData extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isVirtual!: boolean;

  @Prop({ type: String, required: true })
  readonly height!: string;

  @Prop({ type: String, required: false })
  readonly text?: string;

  readonly dashModule = useDashStore();
  readonly NebraskaColors = NebraskaColors;

  private get isDarkMode() {
    return this.dashModule.isDarkMode;
  }

  private get getStyle() {
    return {
      '--height': this.height,
      '--no-content-background': this.backgroundStyle
    };
  }

  private get backgroundStyle() {
    return this.isDarkMode
      ? `url(${noDataChartDarkImagePath})`
      : `url(${noDataChartImagePath})`;
  }

  public get noContentText() {
    if (this.text) return this.text;
    return this.isVirtual
      ? NoContentTexts.noContentDataAtAll
      : NoContentTexts.noContentDataForPeriod;
  }
}
