import { render, staticRenderFns } from "./UserTemplate.vue?vue&type=template&id=130ea7cc&scoped=true&"
import script from "./UserTemplate.vue?vue&type=script&lang=ts&"
export * from "./UserTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./UserTemplate.vue?vue&type=style&index=0&id=130ea7cc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "130ea7cc",
  null
  
)

export default component.exports