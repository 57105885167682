import { object, string } from 'yup';
import { isString } from '@/modules/common/helpers/string';
import { YupValidation } from '@/modules/common/helpers/yup-validation';

const schema = object().shape({
  zipcode: string()
    .test({
      test: isString,
      message: 'Informe um CEP válido'
    })
    .test({
      test: item => item.length === 9,
      message: 'Informe um CEP válido'
    })
    .required('Informe o CEP da sua residência'),

  street: string()
    .test({
      test: isString,
      message: 'Informe o endereço da sua residência'
    })
    .required('Informe o endereço da sua residência'),

  number: string()
    .test({
      test: isString,
      message: 'Informe o número da sua residência'
    })
    .required('Informe o número da sua residência'),

  neighborhood: string()
    .test({
      test: isString,
      message: 'Informe o bairro da sua residência'
    })
    .required('Informe o bairro da sua residência'),

  city: string()
    .test({
      test: isString,
      message: 'Informe a cidade da sua residência'
    })
    .required('Informe a cidade da sua residência'),

  state: string()
    .test({
      test: isString,
      message: 'Selecione o estado da sua residência'
    })
    .required('Selecione o estado da sua residência')
});

export default new YupValidation(schema);
