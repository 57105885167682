import { render, staticRenderFns } from "./TemplateStrategy.vue?vue&type=template&id=47f5c260&scoped=true&"
import script from "./TemplateStrategy.vue?vue&type=script&setup=true&lang=ts&"
export * from "./TemplateStrategy.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./TemplateStrategy.vue?vue&type=style&index=0&id=47f5c260&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f5c260",
  null
  
)

export default component.exports