
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  SkeletonLoader,
  BaseIcon,
  BrandImage,
  EBrandImageSchemas,
  EBrandImageLogos
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: { SkeletonLoader, BaseIcon, BrandImage }
})
export default class LoginHero extends Vue {
  @Prop({ type: String })
  readonly brandName?: string;

  @Prop({ type: String, default: 'WARREN' })
  readonly default!: 'WARREN' | 'B2B';

  @Prop({ type: String })
  readonly logo?: string;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: Boolean, required: false })
  readonly horizontalLogo?: boolean;

  readonly NebraskaColors = NebraskaColors;
  readonly EBrandImageLogos = EBrandImageLogos;
  readonly EBrandImageSchemas = EBrandImageSchemas;

  get brandStyle() {
    if (this.isLoading) return null;
    return { opacity: 1 };
  }

  get getBrandName() {
    return this.brandName || '';
  }

  get showFallback() {
    return !this.isLoading && !this.logo && !this.brandName;
  }

  get showBrandLogo() {
    return !this.isLoading && this.logo;
  }

  showBrandName() {
    return !this.logo && this.brandName;
  }
}
