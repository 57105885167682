import { GtmTracker } from '@/modules/common/services/analytics';

const { trackerWithDefaultProps } = GtmTracker;

const getPageScreen = (isEditor: boolean) =>
  isEditor ? 'portfolio_allocation_editor' : 'advanced_portfolio_builder';

const trackAmplitudeEvent = trackerWithDefaultProps({
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackDetailInstrument(
  isEditor: boolean,
  type: string,
  name: string
) {
  trackAmplitudeEvent({
    page_screen: getPageScreen(isEditor),
    event_type: 'click',
    page_screen_section: 'allocation',
    event_name: 'detail_instrument',
    instrument_type: type,
    instrument_name: name
  });
}
