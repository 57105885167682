
import { Component, Prop, Vue } from 'vue-property-decorator';

function isTargetId(target: string | HTMLElement): target is string {
  return typeof target === 'string';
}

@Component
export default class Portal extends Vue {
  @Prop({
    type: [String, Element],
    required: false,
    default: () => document.body
  })
  private target!: string | HTMLElement;

  private container?: HTMLElement | null = null;

  mounted() {
    this.container = isTargetId(this.target)
      ? document.getElementById(this.target)
      : this.target;
    this.container?.append(this.$el);
  }

  beforeDestroy() {
    if (!this.$el.isConnected) return;
    this.container?.removeChild(this.$el);
  }
}
