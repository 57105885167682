
import { Vue, Component, Prop } from 'vue-property-decorator';
import { GtmTracker } from '@/modules/common/services/analytics';
import { BaseIcon, BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    BaseIcon,
    BaseText
  }
})
export default class MobileMenu extends Vue {
  @Prop({ type: Boolean, required: true })
  private isOpen!: boolean;

  private readonly NebraskaColors = NebraskaColors;

  private get trackMenuEvent() {
    return GtmTracker.trackerWithDefaultProps({
      label: 'Produtos',
      category: 'Navigation Mobile'
    });
  }

  private openChat() {
    this.$emit('open-chat');
  }

  private openMenu() {
    this.isOpen = true;
  }

  private closeMenu() {
    this.isOpen = false;
  }
}
