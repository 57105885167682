export enum EFundsSteps {
  PROFILE,
  RISK,
  TEMPLATE,
  CONCLUSION
}

export interface IFundsData {
  profile?: { value: string; formatted: string };
  type: string;
  risk: { id: string; formatted: string; value: number };
}
