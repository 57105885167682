import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IOrderFiltersResponse,
  IOrderListResponse,
  IOrderListRequest,
  IOrderReceipt
} from './types';
import { getCustomerId } from '@/modules/common/services/local-storage';

export const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getOrderFilters(): Promise<IOrderFiltersResponse> {
  const params = { customerId: getCustomerId() };
  return http.get(`/v1/order/filters`, { params });
}

export function getOrderList(
  filters: IOrderListRequest
): Promise<IOrderListResponse> {
  const formattedFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== '')
  );
  const params = {
    customerId: getCustomerId(),
    ...formattedFilters
  };
  return http.get(`/v1/order/list`, { params });
}

export function getOrderReceiptDetails(
  OrderID: string
): Promise<IOrderReceipt> {
  return http.get(`/v2/orders/${OrderID}/receipt`);
}

export function cancelOrder(OrderID: string): Promise<void> {
  return http.put(`/v1/orders/${OrderID}/cancel`);
}
