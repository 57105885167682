
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import {
  BaseModal,
  ModalButton,
  NavBar,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';
import { CloseButton } from '@/modules/common/components/buttons';
import { ModalContent } from '../ModalContent';
import { ModalStatus } from '../ModalStatus';
import {
  HomeBrokerStatus,
  TradeHomeBrokerStatus
} from '@/modules/trade/services/account';
import {
  trackRequestHomeBrokerClick,
  trackCancelHomeBrokerClick
} from '@/modules/trade/views/TradeView/trackers';
import { requestHomeBrokerAccess } from '@/modules/trade/services/home-broker';

enum BrokerSteps {
  request = 1,
  status = 2
}

@Component({
  components: {
    BaseModal,
    NavBar,
    CloseButton,
    LoaderIndicator,
    ModalButton,
    ModalContent,
    ModalStatus
  }
})
export default class RequestBrokerModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: Object, default: null })
  readonly brokerStatus!: HomeBrokerStatus;

  @Emit('close')
  emitCloseModal() {}

  @Emit('update-status')
  emitUpdateStatus() {}

  isLoading = false;
  step = BrokerSteps.request;
  private error = false;

  async requestBroker() {
    trackRequestHomeBrokerClick();
    this.error = false;
    this.isLoading = true;

    try {
      await requestHomeBrokerAccess();
    } catch {
      this.error = true;
    } finally {
      this.isLoading = false;
      this.step = BrokerSteps.status;
    }
  }

  handleCancelClick() {
    trackCancelHomeBrokerClick();
    this.emitCloseModal();
  }

  closeBrokerModal() {
    if (this.isBrokerStatusRequested) this.emitCloseModal();
    else this.emitUpdateStatus();
  }

  get isBrokerStatusRequested() {
    return this.brokerStatus?.status === TradeHomeBrokerStatus.Requested;
  }

  get computedTitle() {
    return this.brokerStatus?.status === TradeHomeBrokerStatus.Requested
      ? 'Home Broker Solicitado'
      : 'Solicitar Home Broker';
  }

  get isHomeBrokerDisabled() {
    return (
      this.step === BrokerSteps.request &&
      this.brokerStatus?.status === TradeHomeBrokerStatus.Disabled
    );
  }

  get modalStatusProps() {
    const infoProps = {
      type: 'info',
      title: 'Quase lá! Estamos preparando o Home Broker para você',
      description: 'Aguarde alguns minutos para começar a investir.'
    };
    const successProps = {
      type: 'success',
      title: 'O Home Broker foi solicitado',
      description: 'Aguarde alguns minutos para começar a investir.'
    };

    return this.isBrokerStatusRequested ? infoProps : successProps;
  }
}
