type Mask = (value: string) => string;

const CHAR_NUMBER = '#';

const isCharNumber = (value: string) => value === CHAR_NUMBER;
const isEmpty = (value: string) => !value;
const isNotNumber = (value: string) => /\D/.test(value);

export function makeMask(pattern = ''): Mask {
  return (value: string) => {
    let result = '';
    let indexValue = 0;
    for (let indexPattern = 0; indexPattern < pattern.length; indexPattern++) {
      const charValue = value[indexValue];
      if (isEmpty(charValue)) return result;
      if (isNotNumber(charValue)) {
        indexValue++;
        indexPattern--;
        continue;
      }
      const charPattern = pattern[indexPattern];
      if (isCharNumber(charPattern)) {
        result += charValue;
        indexValue++;
        continue;
      }
      result += charPattern;
    }
    return result;
  };
}

export const cpfMask = makeMask('###.###.###-##');
export const phoneMask = makeMask('(##) #####-####');
export const zipCodeMask = makeMask('#####-###');
