import { FastRegisterAPI } from '@/modules/onboarding/fast-register/services/api';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

const fastRegisterAPI = new FastRegisterAPI();

export enum PasswordScore {
  VeryWeak,
  Weak,
  Medium,
  Strong,
  VeryStrong
}

export async function verifyPassword(password: string) {
  try {
    const response = await fastRegisterAPI.verifyPassword({
      password
    });

    let progressColor = NebraskaColors.statusInfo;
    let passwordScore = Math.min(response.score * 0.3, 1);
    const passwordMessage = response.tip;
    const isPasswordValid = response.isValid;

    switch (response.score) {
      case PasswordScore.VeryWeak:
        passwordScore = 0.15;
        progressColor = NebraskaColors.statusAlert;
        break;
      case PasswordScore.Weak:
      case PasswordScore.Medium:
        progressColor = NebraskaColors.statusAlert;
        break;
      case PasswordScore.Strong:
      case PasswordScore.VeryStrong:
        progressColor = NebraskaColors.statusPositive;
        break;
    }

    return {
      passwordScore,
      passwordMessage,
      isPasswordValid,
      progressColor
    };
  } catch (error) {
    console.error(error);
  }
}
