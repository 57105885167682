
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  HeadingDisplay,
  SkeletonLoader,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { HeadingDisplayLoader } from '@/modules/trade/components/HeadingDisplayLoader';
import { Money } from '@/modules/common/components/MoneyV2';

import { Balances } from '@/modules/trade/services/account';
import { RequestStatus } from '@/modules/trade/store/trade';
import { useDashStore } from '@/modules/common/store/DashModule';
import { data } from './data';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    HeadingDisplay,
    SkeletonLoader,
    ButtonSecondary,
    HeadingDisplayLoader,
    Money
  }
})
export default class PageTitle extends Vue {
  readonly data = data;
  private readonly dashModule = useDashStore();

  @tradePositionsModule.State('balancesBrokerRequestStatus')
  readonly balancesBrokerRequestStatus!: RequestStatus;

  @tradePositionsModule.State('balances')
  readonly balances?: Balances | null;

  @tradePositionsModule.Action('fetchBalances')
  readonly fetchBalances!: Function;

  readonly RequestStatus = RequestStatus;

  handleRetryClick() {
    this.fetchBalances();
  }

  get showMoney() {
    return this.dashModule;
  }

  get title() {
    return this.balances?.grossBalance?.formatted || '-';
  }

  get subtitle() {
    return this.balances?.grossBalance?.updatedAt?.formatted || '-';
  }
}
