
import { Vue, Prop, Component, Inject } from 'vue-property-decorator';
@Component({})
export default class NavigationPanel extends Vue {
  @Prop({ type: Boolean, default: false })
  public isOpen!: boolean;

  @Inject({
    from: 'setPanel',
    default: () => {}
  })
  private readonly setPanel!: () => {};
}
