
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  Heading,
  BaseText,
  ButtonSecondary,
  MessageFoundation
} from '@warrenbrasil/nebraska-web';

import { AccountStatus } from '@/modules/trade/services/account';
import { RequestStatus } from '@/modules/trade/store/trade';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    MessageFoundation,
    ButtonSecondary,
    BaseText,
    Heading
  }
})
export default class BrokerageFeeWarning extends Vue {
  @tradePositionsModule.State('tradeStatus')
  readonly tradeStatus!: AccountStatus | null;

  @tradePositionsModule.State('accountStatusRequestStatus')
  readonly accountStatusRequestStatus!: RequestStatus;

  readonly NebraskaColors = NebraskaColors;

  get isAccountStatusLoaded() {
    return this.accountStatusRequestStatus === RequestStatus.Default;
  }

  get isBrokerageFeeEnabled() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.ENABLE_BROKERAGE_FEE);
  }

  get showBrokerageFeeWarning() {
    return this.isBrokerageFeeEnabled && this.isAccountStatusLoaded;
  }

  get componentData() {
    return {
      title: this.tradeStatus?.enableBrokerageFee.title ?? '-',
      description: this.tradeStatus?.enableBrokerageFee.text ?? '-',
      button: {
        label: this.tradeStatus?.enableBrokerageFee.buttonLabel ?? '-',
        href: this.tradeStatus?.enableBrokerageFee.link ?? ''
      }
    };
  }
}
