
import { Component, Vue } from 'vue-property-decorator';
import { SpinnerLoader, HelperViewStatus } from '@warrenbrasil/nebraska-web';
import { FadeTransition } from '@/modules/common/components/FadeTransition';
import { SuggestionsView } from './components/SuggestionsView';
import { Notification } from '@/modules/common/components/Notification';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import {
  getInvestmentSuggestions,
  getTransactionSuggestionDetails,
  putInvestmentAgreement,
  IGetInvestmentSuggestionsResponse,
  IGetSuggestionDetailsResponse,
  IGetSuggestionDetailsParams,
  IPutInvestmentAgreementParams
} from '@/modules/wealth/services/investment-suggestions';
import {
  getPortfolioTerm,
  IPortfolioTerms
} from '@/modules/wealth/services/portfolio-terms';
import {
  InvestmentSuggestionsComponents,
  EInvestmentSuggestionsSteps,
  ErrorTypes,
  EButtonLabel
} from './helpers';
import { isBFFFormattedError } from '@/modules/common/helpers/bff';
import * as trackEvents from './track-events';

@Component({
  components: {
    FadeTransition,
    SpinnerLoader,
    HelperViewStatus,
    SuggestionsView,
    Notification
  }
})
export default class InvestmentSuggestionsView extends Vue {
  investmentSuggestions: IGetInvestmentSuggestionsResponse | null = null;
  suggestionDetails: IGetSuggestionDetailsResponse | null = null;
  portfolioTerms: IPortfolioTerms | null = null;
  currentStep = EInvestmentSuggestionsSteps.ERROR;
  currentTransactionId = '';

  isLoading = false;

  handleError = {
    type: '',
    title: '',
    description: '',
    buttonLabel: ''
  };

  get step() {
    return {
      component: InvestmentSuggestionsComponents[this.currentStep]
    };
  }

  created() {
    trackEvents.trackSuggestionsLoad();
    this.getInvestmentSuggestions();
  }

  async getInvestmentSuggestions() {
    this.isLoading = true;
    try {
      this.investmentSuggestions = await getInvestmentSuggestions();
      this.changeStep(EInvestmentSuggestionsSteps.VIEW);
    } catch (e) {
      console.error(e);
      this.handleErrorToGeneric();
      this.changeStep(EInvestmentSuggestionsSteps.ERROR);
    } finally {
      this.isLoading = false;
    }
  }

  async getTransactionSuggestionDetails(params: IGetSuggestionDetailsParams) {
    this.isLoading = true;
    try {
      this.suggestionDetails = await getTransactionSuggestionDetails(params);

      if (this.suggestionDetails.portfolio.managementType === 'Warren') {
        this.portfolioTerms = await getPortfolioTerm(
          this.suggestionDetails.transaction.portfolioApiId,
          this.suggestionDetails.portfolio.isVirtual
        );
      }

      this.changeStep(EInvestmentSuggestionsSteps.REVIEW);
    } catch (e) {
      console.error(e);
      this.handleErrorToGeneric();
      if (isBFFFormattedError(e)) {
        this.handleError = {
          type: e.error.message.value,
          title: e.error.formattedMessage.title,
          description: e.error.formattedMessage.description,
          buttonLabel: this.buttonLabel(e.error.message.value)
        };
      }
      this.changeStep(EInvestmentSuggestionsSteps.ERROR);
    } finally {
      this.isLoading = false;
    }
  }

  async putInvestmentAgreement(params: IPutInvestmentAgreementParams) {
    this.isLoading = true;
    try {
      await putInvestmentAgreement(params);
      if (params.status === 'accepted') {
        this.currentStep = EInvestmentSuggestionsSteps.CONCLUSION;
      } else {
        this.changeStep(EInvestmentSuggestionsSteps.VIEW);
      }
    } catch (e) {
      console.error(e);
      this.handleErrorToGeneric();
      this.changeStep(EInvestmentSuggestionsSteps.ERROR);
    } finally {
      this.isLoading = false;
    }
  }

  suggestionClick(params: IGetSuggestionDetailsParams) {
    this.currentTransactionId = params.transactionId;
    this.getTransactionSuggestionDetails(params);
  }

  async suggestionRejected(params: IPutInvestmentAgreementParams) {
    await this.putInvestmentAgreement(params);
    await this.getInvestmentSuggestions();
    const message = 'Sugestão recusada';
    this.notificationToast(message, NotificationTypes.success);
  }

  async suggestionAccepted(params: IPutInvestmentAgreementParams) {
    trackEvents.trackAcceptInvestmentSuggestion();
    await this.putInvestmentAgreement(params);
  }

  changeStep(step: EInvestmentSuggestionsSteps) {
    if (this.currentStep === EInvestmentSuggestionsSteps.CONCLUSION) {
      this.getInvestmentSuggestions();
    }
    this.currentStep = step;
  }

  handleErrorToGeneric() {
    this.handleError = {
      type: 'Generic',
      title: 'Erro de carregamento',
      description:
        'Não foi possível carregar a lista de sugestões do especialista. Por favor, tente novamente.',
      buttonLabel: EButtonLabel.GENERIC
    };
  }

  notificationToast(message: string, type: NotificationTypes) {
    this.$notify({
      group: 'wrn-notifications',
      text: message,
      type
    });
  }

  private buttonLabel(error: string) {
    if (
      error === ErrorTypes.INCONPATIBLE_INVESTOR_TYPE ||
      error === ErrorTypes.INSUFICIENT_BALANCE ||
      error === ErrorTypes.INVALID_SCHEDULE_DATE
    ) {
      return EButtonLabel.REMOVE_SUGGESTION;
    }
    return EButtonLabel.BACK;
  }
}
