
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import { InstrumentRow } from '../InstrumentRow';
import { Row } from '@warrenbrasil/nebraska-web';

import { Instrument } from '@/modules/trade/services/search';

@Component({
  components: {
    InstrumentRow,
    Row
  }
})
export default class InstrumentList extends Vue {
  @Prop({ type: String, default: '' })
  readonly searchText!: string;

  @Prop({ type: Array, required: true })
  readonly searchItemsData!: Instrument[];

  @Prop({ type: Boolean, default: false })
  readonly hasLength!: boolean;

  @Prop({ type: Function, required: true })
  readonly onRowClick!: (instrument: Instrument) => {};

  @Prop({ type: Boolean, default: true })
  readonly chevron!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly showWatchListButton!: boolean;

  @Emit('on-watchlist-click')
  emitWatchlistClick(instrument: Instrument) {
    return instrument;
  }

  get searchResultsDescription() {
    return this.searchItemsData.length
      ? 'resultado encontrado'
      : 'resultados encontrados';
  }

  handleRowClick(instrument: Instrument) {
    this.onRowClick(instrument);
  }
}
