import { StorageKeys, ICustomTheme, IBrand, IUserB2B } from './types';

export const THEME_KEY: string = StorageKeys.Theme;

export function setCustomTheme(customTheme: ICustomTheme) {
  localStorage.setItem(THEME_KEY, JSON.stringify(customTheme));
}

export function getCustomTheme() {
  const localStorageCustomTheme = localStorage.getItem(THEME_KEY);
  if (!localStorageCustomTheme) return null;

  return JSON.parse(localStorageCustomTheme!) as ICustomTheme;
}

export function getBrand(): IBrand | null {
  const localStorageBrand = localStorage.getItem(StorageKeys.Brand);
  if (!localStorageBrand || localStorageBrand === 'undefined') return null;

  return JSON.parse(localStorageBrand!) as IBrand;
}

export function setUserB2B(userB2B: IUserB2B) {
  localStorage.setItem(StorageKeys.Partner, JSON.stringify(userB2B));
}

export function getUserB2B(): IUserB2B | null {
  const localStorageUser = localStorage.getItem(StorageKeys.Partner);
  if (!localStorageUser) return null;

  return JSON.parse(localStorageUser!) as IUserB2B;
}

export function getCustomerId(): string | null {
  const localStorageUser = localStorage.getItem(StorageKeys.CustomerId);
  return localStorageUser || null;
}
