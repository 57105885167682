import { Component, Vue } from 'vue-property-decorator';

interface Distortion {
  x: number;
  y: number;
}

@Component
export default class CardMovement extends Vue {
  private CARD_PERSPECTIVE = 800;
  private CARD_DISTORTION = 0.03;
  private GLARE_PAN = 0.15;

  private cardReset(): void {
    (this.$el as HTMLElement).style.transform = '';
  }

  private cardMovement(event: MouseEvent): void {
    const elementWidth = this.$el.clientWidth / 2;
    const elementHeight = this.$el.clientHeight / 2;

    // applies movement to cardWrapper
    const distortion = this.transformCard(
      elementWidth,
      elementHeight,
      event.offsetX,
      event.offsetY,
      this.CARD_DISTORTION
    );
    (
      this.$el as HTMLElement
    ).style.transform = `scale(1.05) perspective(${this.CARD_PERSPECTIVE}px) translate3d(0, -2px, 0) rotateX(${distortion.x}deg) rotateY(${distortion.y}deg)`;

    // applies movement to card glare
    const glare: HTMLDivElement | null = this.$el.querySelector('.glare');
    if (glare) {
      const glareDistortion = this.transformGlare(
        elementWidth,
        elementHeight,
        event.offsetX,
        event.offsetY,
        this.GLARE_PAN
      );
      glare.style.transform = `perspective(${this.CARD_PERSPECTIVE}px) translate3d(${glareDistortion.x}%, ${glareDistortion.y}%, 5px)`;
    }
  }

  private transformCard(
    width: number,
    height: number,
    positionX: number,
    positionY: number,
    distortion: number
  ): Distortion {
    const distortionX = (height - positionY) * distortion;
    const distortionY = (width - positionX) * -distortion;

    return {
      x: distortionX,
      y: distortionY
    };
  }

  private transformGlare(
    width: number,
    height: number,
    positionX: number,
    positionY: number,
    distortion: number
  ): Distortion {
    const glareX = (width - positionX) * distortion;
    const glareY = (height - positionY) * distortion;

    return {
      x: glareX,
      y: glareY
    };
  }
}
