import { GtmTracker } from '@/modules/common/services/analytics';
import { Categories } from '@/types/models/Analytics';

const { trackerWithDefaultProps } = GtmTracker;

const trackEvent = trackerWithDefaultProps({
  category: Categories.PEX
});

const trackAmplitudeEvent = trackerWithDefaultProps({
  page_screen: 'home_portfolio',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackViewPortfolio(event_target = '') {
  trackEvent({
    action: 'Clique em card',
    label: 'Minhas carteiras'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'my_portfolios',
    event_name: 'view_my_portfolio',
    event_target
  });
}
