
import { Component, Vue } from 'vue-property-decorator';

import { NavBar, IFilterOption } from '@warrenbrasil/nebraska-web';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { Section } from '@/modules/trade/components/Section';
import { DetailLoader } from './components/DetailLoader';
import { FiltersList } from '@/modules/trade/components/FiltersList';
import { InstrumentList } from '@/modules/trade/components/InstrumentList';

import {
  SectionDetail,
  getSectionDetail,
  Instrument
} from '@/modules/trade/services/search';
import {
  trackSectionViewLoad,
  trackFilterClick,
  trackInstrumentClick
} from './trackers';

@Component({
  components: {
    NavBar,
    BackButton,
    Section,
    FiltersList,
    InstrumentList,
    DetailLoader
  }
})
export default class SearchSectionDetailView extends Vue {
  private sectionDetail!: SectionDetail;
  private loading = true;
  private error = false;

  private handleSelectFilter(filter: IFilterOption) {
    trackFilterClick(this.sectionDetail.title, filter.label);
    this.load(filter.value);
  }

  async load(filter: string) {
    this.loading = true;
    this.error = false;
    try {
      const data = await getSectionDetail(this.$route.params.id, filter);
      this.sectionDetail = data;
    } catch {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  private async created() {
    await this.load('all');
    trackSectionViewLoad(this.sectionDetail?.title || '');
  }

  private onBackClick() {
    this.$router.back();
  }

  private onRowClick(instrument: Instrument) {
    trackInstrumentClick(
      this.sectionDetail.title,
      instrument.symbol,
      instrument.name!
    );

    this.$router.push({
      name: 'tradeInstrument',
      params: { ticker: instrument.symbol }
    });
  }
}
