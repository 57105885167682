import { httpClient } from '@/modules/common/services/http/http-client';
import { IAllocationsByProductResponse } from './types';

export const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getAllocationsByProduct(): Promise<IAllocationsByProductResponse> {
  return http.get('v1/customer/getAllocationsByProduct');
}
