import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IWithdrawOptionsResponse,
  ICreateWithdrawResponse,
  IScheduleWithdrawalResponse
} from './types';

const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/banking/v1/cash`
});

export function getWithdrawOptions(): Promise<IWithdrawOptionsResponse> {
  return http.get('/getWithdrawOptions');
}

export function createWithdrawal(
  bankAccountId: string,
  withdrawAmount: number
): Promise<ICreateWithdrawResponse> {
  return http.post('/createWithdrawal', {
    bankAccountId,
    withdrawAmount
  });
}

export function scheduleWithdrawal(
  bankAccountId: string,
  withdrawAmount: number
): Promise<IScheduleWithdrawalResponse> {
  return http.post('/scheduleWithdrawal', {
    bankAccountId,
    withdrawAmount
  });
}
