
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import {
  SkeletonLoader,
  BaseText,
  Heading,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';

@Component({
  name: 'SectionError',
  components: { SkeletonLoader, BaseText, Heading, ButtonSecondary }
})
export default class SectionError extends Vue {
  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly description!: string;

  @Prop({ type: String, default: 'Tentar novamente' })
  readonly buttonLabel!: string;

  @Emit('on-retry')
  public onRetry() {}
}
