
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { HelperViewStatus } from '@warrenbrasil/nebraska-web';

import * as trackOrderEvents from '@/modules/trade/components/OrderModal/trackers';

@Component({
  components: {
    HelperViewStatus
  }
})
export default class OrderStatus extends Vue {
  @Prop({ type: String, required: true })
  readonly status!: string;

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly description!: string;

  @Prop({ type: String, required: false })
  readonly buttonLabel?: string;

  @Emit('on-button-click')
  emitOnButtonClick() {
    trackOrderEvents.trackOrderDetailStatusCompleteClick();
  }

  created() {
    const text = `${this.title}. ${this.description}`;
    trackOrderEvents.trackOrderDetailStatusLoad(text);
  }
}
