
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GtmTracker } from '@/modules/common/services/analytics';
import { PortfolioModule } from '@/store/modules/PortfolioModule';
import { ECameFromPath } from '@/modules/transaction/views/InvestmentTransaction/types';
import { InvestmentTransactionNavbar } from '@/modules/transaction/views/InvestmentTransaction/components/InvestmentTransactionNavbar';

type UserCameFromPath = {
  conversation: boolean;
  wealthPortfolio: boolean;
  portfoliosToInvest: boolean;
};

@Component({
  name: 'InvestmentTransactionNavbarContainer',
  components: { InvestmentTransactionNavbar }
})
export default class InvestmentTransactionNavbarContainer extends Vue {
  @Prop({ type: String, required: true })
  private portfolioName!: string;

  private get isBackButtonVisible() {
    return (
      this.userRoutePaths.conversation || this.userRoutePaths.portfoliosToInvest
    );
  }

  private get userRoutePaths(): UserCameFromPath {
    return {
      conversation: this.isUserCameFrom(ECameFromPath.Conversation),
      wealthPortfolio: this.isUserCameFrom(ECameFromPath.WealthPortfolio),
      portfoliosToInvest: this.isUserCameFrom(ECameFromPath.PortfoliosToInvest)
    };
  }

  private get closeButtonLabel() {
    return this.userRoutePaths.wealthPortfolio
      ? 'Voltar para sua carteira'
      : 'Voltar para a aba Visão Geral';
  }

  private get trackEvent() {
    return GtmTracker.trackerWithDefaultProps({
      category: 'PEX - Investir Carteira'
    });
  }

  private isUserCameFrom(path: ECameFromPath) {
    return this.$route.query.from === path;
  }

  private handleBackClick() {
    this.trackEvent({ action: 'Clique retornar', label: this.portfolioName });
    this.$router.back();
  }

  private handleCloseClick() {
    const overviewOptions = { name: 'home' };
    const wealthPortfolioOptions = {
      name: 'wealthPortfolio',
      params: { id: this.$route.params.portfolio }
    };

    const routeOptions = this.userRoutePaths.wealthPortfolio
      ? wealthPortfolioOptions
      : overviewOptions;
    this.$router.push(routeOptions);
    PortfolioModule.resetInvestedPortfolios();
  }
}
