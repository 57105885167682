import { httpClient } from '@/modules/common/services/http/http-client';
import { ICustomerBankAccountResponse } from './types';

const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/banking/v1/cash`
});

export function getCustomerBankAccount(): Promise<ICustomerBankAccountResponse> {
  return http.get('/getCustomerBankAccount');
}
