
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  NavBar,
  Heading,
  BaseText,
  Row,
  ActionableHelperView,
  SkeletonLoader,
  ContextColorProvider
} from '@warrenbrasil/nebraska-web';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { Accordion } from '@/modules/common/components/Accordion';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { getString } from '@/modules/common/helpers/resources';
import { getMonthName } from '@/modules/common/helpers/date';
import {
  getFeesByMonth,
  getFeesPercentage,
  IFeePercentage,
  ICostsByMonthResponse
} from '@/modules/cash/services/costs';
import { moneyFormat } from '@/modules/common/helpers/currency';
import { ClientType, ICustomer, PartnerType } from '@/types/models/Customer';
import {
  accountIncomeTaxRules,
  accountIncomeTaxDescription,
  accountIncomeTaxDigital,
  tradeHelperTextWithBrokerageFee,
  tradeHelperTextWithoutBrokerageFee
} from './data';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { IMonthCosts } from './types';

const customerModule = namespace('CustomerModule');

@Component({
  name: 'MonthlyCostsDetails',
  components: {
    NavBar,
    BackButton,
    Heading,
    BaseText,
    ContextColorProvider,
    SkeletonLoader,
    Accordion,
    ActionableHelperView,
    Row
  }
})
export default class MonthlyCostsDetails extends Vue {
  @customerModule.Getter('getCustomer')
  private customer!: ICustomer;

  private year!: string;
  private month!: number;
  private monthCost?: IMonthCosts;
  private taxesPercentage: IFeePercentage[] = [];
  private getString = getString;
  private currOpenedAccordion = -1;
  private isLoading = true;
  private error = false;
  private brandName = '';
  private userType?: ClientType;
  private getMonthName = getMonthName;
  private moneyFormat = moneyFormat;
  brokerageFeeTotal = 0;

  readonly accountIncomeTaxDescription = accountIncomeTaxDescription;
  readonly accountIncomeTaxRules = accountIncomeTaxRules;
  readonly accountIncomeTaxDigital = accountIncomeTaxDigital;
  readonly NebraskaColors = NebraskaColors;

  public created() {
    this.year = this.$route.params.year;
    this.month = parseInt(this.$route.params.month, 10);

    this.setCustomerInfos();
    this.getTaxesPercentage();
    this.getFeesByMonth();
  }

  get isTradeEnabled() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.TRADE_3_0);
  }

  get enableBrokerageFee() {
    return FeatureFlagsModule.isEnabled(
      FeatureFlags.ENABLE_BROKERAGE_FEE_ON_COSTS_PAGE
    );
  }

  get isCrRateCostsEnabled() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.CASH_CR_RATE_COSTS);
  }

  get brokerageFeeTotalFormatted() {
    return moneyFormat(this.brokerageFeeTotal);
  }

  get tradeHelperText() {
    return this.enableBrokerageFee
      ? tradeHelperTextWithBrokerageFee
      : tradeHelperTextWithoutBrokerageFee;
  }

  private setCustomerInfos() {
    this.userType = this.customer.clientType;
    this.brandName = this.getBrandTradeMark() ?? '';
  }

  private getBrandTradeMark() {
    if (
      !this.customer.partnerInfos ||
      this.customer.partnerInfos.type === PartnerType.AAI
    )
      return 'Warren';

    return this.customer.partnerInfos.tradeMark;
  }

  private async getFeesByMonth() {
    this.error = false;
    this.isLoading = true;
    try {
      const data = await getFeesByMonth(Number(this.year), this.month);
      this.brokerageFeeTotal = this.enableBrokerageFee
        ? data.brokerageFee?.total ?? 0
        : 0;
      this.calculateMonthlyCosts(data);
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.error = true;
    }
  }

  private setAccordionOpened(index: number, open: boolean) {
    if (this.currOpenedAccordion !== index && open) {
      this.currOpenedAccordion = index;
    } else {
      this.currOpenedAccordion = -1;
    }
  }

  private async getTaxesPercentage() {
    const taxes = await getFeesPercentage();
    this.taxesPercentage = taxes;
  }

  private calculateMonthlyCosts(monthlyCosts: ICostsByMonthResponse) {
    const cashbackTotal = monthlyCosts.cashback
      ? monthlyCosts.cashback
          .map(c => c.value)
          .reduce((acc, value) => acc + value, 0)
      : 0;
    this.monthCost = {
      administration: monthlyCosts.administration,
      cashback: cashbackTotal,
      cashbackDetailed: {
        products: monthlyCosts.cashback || [],
        total: cashbackTotal
      },
      management: monthlyCosts.management,
      total: this.calculatePositiveCashback(monthlyCosts, cashbackTotal)
    };
  }

  private calculatePositiveCashback(
    costs: ICostsByMonthResponse,
    cashback: number
  ) {
    const management = costs.management || 0;

    return (
      cashback - (costs.administration + management + this.brokerageFeeTotal)
    );
  }

  private getTaxValue(type: 'administration' | 'management') {
    if (!this.taxesPercentage.length) return 0;

    const tax = this.taxesPercentage.find(t => t.type === type);
    const taxValue = (tax ? tax.value * 100 : 0).toFixed(2);

    return parseFloat(taxValue);
  }

  private getTotalCosts() {
    const totalCosts =
      (this.monthCost!.management || 0) + this.monthCost!.administration;
    return moneyFormat(totalCosts);
  }

  private get positiveClass() {
    return this.monthCost!.total > 0 ? { positive: true } : {};
  }

  private get positiveEmoji() {
    return this.monthCost!.total > 0 ? ' 🚀' : '';
  }
}
