
import { Component, Emit, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ButtonPrimary, Footer, HelperView } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    ButtonPrimary,
    HelperView,
    Footer
  }
})
export default class MFAViewActivatedFeedback extends Vue {
  private NebraskaColors = NebraskaColors;

  readonly title = 'Verificação em duas etapas ativada';
  readonly description =
    'Em todo novo login na sua conta vamos solicitar um código de verificação.';

  readonly buttonLabel = 'Ir para a home';

  @Emit()
  private goToHome() {}
}
