import { SuggestedInstrument } from '@/modules/trade/services/search';

export enum dropdownItemTypes {
  section = 'section',
  option = 'option'
}

export interface IDropdownItemBase<T> {
  type: dropdownItemTypes;
  data: T;
}

export type IDropdownOption = IDropdownItemBase<SuggestedInstrument>;

export interface ISectionData {
  title: string;
}

export type IDropdownSection = IDropdownItemBase<ISectionData>;

export type IDropdownItem = IDropdownOption | IDropdownSection;
