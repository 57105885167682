import { defineStore } from 'pinia';
import { isLocalDarkMode, switchDarkMode } from '../../helpers/browser';
import { StorageKeys } from '../../services/local-storage';

export interface IDashModule {
  showMoney: boolean;
  isDarkMode: boolean;
}

export const useDashStore = defineStore('dash', {
  state: (): IDashModule => ({
    showMoney: !(
      localStorage.getItem(StorageKeys.PartnerShowMoney) === 'false'
    ),
    isDarkMode: isLocalDarkMode()
  }),
  actions: {
    toggleShowMoney() {
      this.showMoney = !this.showMoney;
      localStorage.setItem(
        StorageKeys.PartnerShowMoney,
        this.showMoney.toString()
      );
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      switchDarkMode(); // updates on localStorage
    }
  }
});
