
import { Component, Prop, Vue } from 'vue-property-decorator';

import { BaseText, Heading, Row } from '@warrenbrasil/nebraska-web';
import { FirstAccessModal } from '@/modules/trade/views/Subscription/components/FirstAccessModal';
import { TransactionBadge } from '@/modules/common/components/TransactionBadge';
import { HandleSection } from '@/modules/trade/components/HandleSection';

import { SidebarTypes } from '@/modules/trade/views/TradeView/components/TradeSidebar';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { SubscribeStatus } from '@/modules/trade/services/subscription/enums';
import { namespace } from 'vuex-class';
import {
  getFirstAccessModalSubscriptionStatus,
  getSubscriptions,
  ISubscription
} from '@/modules/trade/services/subscription';
import {
  trackSubscriptionActiveHome,
  trackSubscriptionsRequestError,
  trackSubscriptionsRetryClick
} from '@/modules/trade/views/TradeView/trackers';
import { logError } from '@/modules/trade/helpers/logs';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    Heading,
    BaseText,
    HandleSection,
    TransactionBadge,
    FirstAccessModal,
    Row
  }
})
export default class PositionsSubscribe extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: Function })
  readonly setPanel!: (param: SidebarTypes) => void;

  @tradePositionsModule.Mutation('setSubscriptionList')
  readonly setSubscriptionList!: Function;

  public loading = false;
  private error = false;
  private subscribes: ISubscription[] = [];
  public isModalFirstAccessOpen = false;

  private async fetchSubscribes() {
    const subscribesData = await getSubscriptions();
    this.subscribes = subscribesData;
    this.setSubscriptionList(subscribesData);
  }

  private async fetchShouldOpenFirstAccessSubscriptionModal() {
    const isModalFirstAccessOpen =
      await getFirstAccessModalSubscriptionStatus();
    this.isModalFirstAccessOpen = isModalFirstAccessOpen;
  }

  created() {
    this.setupPositionsSubscribeData(true);
  }

  public get showSubscribes() {
    return this.userHasSubscribes || this.error || this.loading;
  }

  public get firstSubscriptionTicker() {
    return this.subscribes[0]?.ticker;
  }

  public get firstSubscriptionIsReceipt() {
    return this.subscribes[0]?.isReceipt;
  }

  public get firstSubscriptionQuantity() {
    return this.subscribes[0]?.quantityAvailable;
  }

  public get hasMoreThanOneSubscription() {
    return this.subscribes.length > 1;
  }

  public get subscriptionsSliced() {
    return this.subscribes.slice(0, 3);
  }

  private get userHasSubscribes() {
    return this.subscribes.length > 0;
  }

  public handleClickSeeAllSubscriptions() {
    this.setPanel(SidebarTypes.SubscriptionsList);
  }

  public closeModalFirstAccess() {
    this.isModalFirstAccessOpen = false;
  }

  public goToSubscriptionTickerPage() {
    if (this.hasMoreThanOneSubscription) {
      this.setPanel(SidebarTypes.SubscriptionsList);
    } else {
      this.changeRouterToSubscriptionActive(
        this.firstSubscriptionTicker,
        this.firstSubscriptionIsReceipt
      );
    }
  }

  public subscriptionAction(
    ticker: string,
    instrumentType: string,
    instrumentName: string,
    isReceipt: boolean
  ) {
    trackSubscriptionActiveHome(instrumentType, instrumentName, ticker);
    this.changeRouterToSubscriptionActive(ticker, isReceipt);
  }

  async handleOnRetry() {
    trackSubscriptionsRetryClick();
    await this.setupPositionsSubscribeData();
  }

  private async setupPositionsSubscribeData(
    isToLoadFirstAcessModalData = false
  ) {
    this.loading = true;
    this.error = false;
    try {
      await this.fetchSubscribes();
      if (isToLoadFirstAcessModalData)
        await this.fetchShouldOpenFirstAccessSubscriptionModal();
    } catch (e) {
      logError(e, 'request_subscription_error');
      trackSubscriptionsRequestError();
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  private changeRouterToSubscriptionActive(
    ticker: string,
    isReceiptActive: boolean
  ) {
    const isReceipt = isReceiptActive.toString();
    this.$router.push({
      path: 'trade/subscription',
      query: { ticker, isReceipt }
    });
  }

  public getStatusFromSubscriptionItem(subscribeItem: ISubscription) {
    const status = subscribeItem.status;
    switch (status) {
      case SubscribeStatus.subscribed:
        return 'accepted';
      case SubscribeStatus.reserved:
        return 'success';
      case SubscribeStatus.unsubscribed:
        return 'rejected';
      default:
        return 'accepted';
    }
  }
}
