
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash-es';
import {
  HeadingDisplay,
  MessageFoundation as Message,
  ButtonContainer,
  ButtonPrimary,
  InputText,
  InputSelect
} from '@warrenbrasil/nebraska-web';
import { State, Address } from '@/modules/account/services/resources/types';
import { YupValidationErrors } from '@/modules/common/helpers/yup-validation';
import yupValidation from './validate';
import { getAddressByZipcode } from '@/modules/account/services/resources';
import { InputStatusType } from '../../types';

@Component({
  components: {
    HeadingDisplay,
    Message,
    ButtonContainer,
    ButtonPrimary,
    InputText,
    InputSelect
  }
})
export default class RegulatoryUpdateAddNewAddress extends Vue {
  @Prop({ type: Boolean, default: false })
  public isButtonLoading!: boolean;

  @Prop({ type: Array, required: true })
  public statesList!: State[];

  @Prop({ type: Object, required: true })
  public errors!: YupValidationErrors;

  @Emit()
  public removeError(_key: string) {}

  @Emit()
  public setErrors(_errors: YupValidationErrors) {}

  @Emit()
  private saveAddressAndGoToNextPage(_newAddress: Address) {}

  public address: Address = {
    zipcode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    state: '',
    city: ''
  };

  public disabled: Record<string, boolean> = {};

  public debounceInputZipcode = debounce(this.inputZipcode, 300);

  public getInputStatus(key: string) {
    return this.errors[key] ? InputStatusType.Error : InputStatusType.Default;
  }

  public getErrorMessage(key: string) {
    return this.errors[key] ? this.errors[key][0] : '';
  }

  private setField(field: keyof Address, value?: string) {
    this.address[field] = value || '';
    this.disabled[field] = !!value && !!value.length;
  }

  private setAddressFromZipcode(address: Partial<Address>) {
    this.setField('street', address.street);
    this.setField('neighborhood', address.neighborhood);
    this.setField('state', address.state);
    this.setField('city', address.city);
    this.setField('number', '');
    this.setField('complement', '');
  }

  private async fetchAddressByZipcode(zipcode: string) {
    try {
      const response = await getAddressByZipcode(zipcode);
      if (!response.zipcode) {
        this.setAddressFromZipcode({});
        return;
      }

      this.setAddressFromZipcode(response);
    } catch (_e) {
      this.setAddressFromZipcode({});
    }
  }

  private inputZipcode(zipcode: string) {
    if (zipcode.length === 9) {
      this.fetchAddressByZipcode(zipcode);
    }
  }

  public async handleButtonClick() {
    await this.validateErrors();

    if (yupValidation.isValid()) {
      this.saveAddressAndGoToNextPage(this.address);
    }
  }

  private async validateErrors() {
    try {
      await yupValidation.validate(this.address);
    } catch (_e) {}

    this.setErrors(yupValidation.getAllErrors());
  }
}
