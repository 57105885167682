
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { Heading, RoundedIcon } from '@warrenbrasil/nebraska-web';
import { SideSheet } from '@/modules/common/components/SideSheet';

@Component({
  components: { Heading, RoundedIcon, SideSheet }
})
export default class SuccessCurtain extends Vue {
  @Prop({ type: Number, default: 1000 })
  public openDelay?: number;

  private isCurtainOpen = true;

  public get NebraskaColors() {
    return NebraskaColors;
  }

  private mounted() {
    setTimeout(this.opened, this.openDelay);
  }

  @Emit()
  private opened() {
    if (this.isCurtainOpen) this.isCurtainOpen = false;
  }
}
