
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IDetailedInstrument } from '@/modules/common/services/http/instrument';

import { Heading } from '@warrenbrasil/nebraska-web';
import { DownloadList } from '@/modules/products/components/DownloadList';
import { ProductQuotasSection } from '@/modules/products/components/ProductQuotasSection';
import { ProductCharacteristics } from '@/modules/products/components/ProductCharacteristics';

@Component({
  components: {
    Heading,
    DownloadList,
    ProductQuotasSection,
    ProductCharacteristics
  }
})
export default class ProductDescriptionDetailed extends Vue {
  @Prop({ type: Boolean, default: false })
  private showProductQuotas!: boolean;

  @Prop({ type: Object, required: true })
  private detailedInstrument!: IDetailedInstrument;

  private get productQuotasDetails() {
    return {
      name: this.detailedInstrument.name,
      apiId: this.detailedInstrument.apiId
    };
  }
}
