import { render, staticRenderFns } from "./CashMarket.vue?vue&type=template&id=23d22b8b&scoped=true&"
import script from "./CashMarket.vue?vue&type=script&lang=ts&"
export * from "./CashMarket.vue?vue&type=script&lang=ts&"
import style0 from "./CashMarket.vue?vue&type=style&index=0&id=23d22b8b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d22b8b",
  null
  
)

export default component.exports