import { GtmTracker } from '@/modules/common/services/analytics';
import {
  IInstrument,
  IInstrumentDetails
} from '@/modules/wallet-builder/src/services/types/models/IBffInvestments';

const { trackerWithDefaultProps } = GtmTracker;

const trackEvent = GtmTracker.trackerWithDefaultProps({
  category: 'PEX - Carteira Editada'
});

const trackAmplitudeEvent = trackerWithDefaultProps({
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

const getPageScreen = (isEditor: boolean) =>
  isEditor ? 'portfolio_allocation_editor' : 'advanced_portfolio_builder';

export function trackSelectInstrumentCategory(
  isEditor: boolean,
  event_target: string
) {
  trackAmplitudeEvent({
    page_screen: getPageScreen(isEditor),
    event_type: 'click',
    page_screen_section: 'header',
    event_name: 'select_instrument_category',
    event_target
  });
}

export function trackInstrumentClicked(
  isEditor: boolean,
  instrument: IInstrument
) {
  trackEvent({
    action: instrument.name || '',
    label: 'Abrir Detalhes Produto'
  });
  trackAmplitudeEvent({
    page_screen: getPageScreen(isEditor),
    event_type: 'click',
    page_screen_section: 'builder_selector',
    event_name: 'detail_instrument',
    instrument_type: instrument.type || '',
    instrument_name: instrument.name || '',
    instrument_risk_level: instrument.riskGrade || ''
  });
}

export function trackCloseInstrumentDetailModal(instrument: IInstrument) {
  trackEvent({
    action: instrument.name || '',
    label: 'Detalhes Produto - Sair'
  });
}

export function trackInstrumentAdd(
  isEditor: boolean,
  isModal: boolean,
  instrument: IInstrument
) {
  const pageScreen = getPageScreen(isEditor);
  const pageScreenModal = isEditor
    ? 'portfolio_allocation_editor_instrument_detail'
    : 'advanced_portfolio_builder_instrument_detail';

  trackEvent({
    action: instrument.name || '',
    label: 'Detalhes Produto - Adicionar Produto'
  });
  trackAmplitudeEvent({
    page_screen: isModal ? pageScreenModal : pageScreen,
    event_type: 'click',
    page_screen_section: 'builder_selector',
    event_name: 'add_instrument',
    instrument_type: instrument.type || '',
    instrument_name: instrument.name || ''
  });
}

export function trackNoResult(isEditor: boolean) {
  trackAmplitudeEvent({
    page_screen: getPageScreen(isEditor),
    event_type: 'load',
    page_screen_section: 'builder_selector',
    event_name: 'advanced_portfolio_result_error'
  });
}

export function trackDownloadButton(isEditor: boolean, computedTitle: string) {
  trackAmplitudeEvent({
    page_screen: getPageScreen(isEditor),
    event_type: 'click',
    page_screen_section: 'builder_instrument_details',
    event_name: 'download_documents',
    event_target: computedTitle
  });
}

export function trackLoadInstrumentDetails(
  isEditor: boolean,
  instrument: IInstrumentDetails
) {
  trackAmplitudeEvent({
    page_screen: getPageScreen(isEditor),
    page_screen_section: 'builder_instrument_details',
    event_type: 'load',
    event_name: 'view',
    instrument_name: instrument.name || '',
    instrument_type: instrument.type || '',
    instrument_risk_level: instrument.riskGrade || '',
    investment_minimum_value:
      instrument.minimumFinancialMovement?.formatted || ''
  });
}

export function trackSearch(isEditor: boolean, search: string) {
  trackAmplitudeEvent({
    page_screen: getPageScreen(isEditor),
    event_type: 'select',
    page_screen_section: 'search_bar',
    event_name: 'search_instrument',
    event_target: search
  });
}
