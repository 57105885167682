
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  BaseText,
  HeadingDisplay,
  ButtonIconContextual,
  SkeletonLoader
} from '@warrenbrasil/nebraska-web';
import { ErrorHandler } from '@/modules/common/components/ErrorHandler';
import { FadeTransition } from '@/modules/common/components/FadeTransition';
import HomeCashActions from './components/HomeCashActions.vue';
import HomeCashSummary from './components/HomeCashSummary.vue';
import { Money } from '@/modules/common/components/Money';
import { TransitModal } from '@/modules/common/components/TransitModal';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  trackReloadBalanceAccountErrorClick,
  trackBalanceAccountErrorLoad
} from './track-events';
import { trackTransactionsPageNavigation } from '@/modules/transaction/helpers/transactions-events';
import { WarrenModules } from '@/types/navigation';
import * as trackEvents from './track-events';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import {
  getCashSummary,
  CashSummaryResponse
} from '@/modules/home/services/bff';

@Component({
  components: {
    FadeTransition,
    HomeCashActions,
    ErrorHandler,
    BaseText,
    HeadingDisplay,
    ButtonIconContextual,
    Money,
    TransitModal,
    SkeletonLoader,
    HomeCashSummary
  }
})
export default class HomeCash extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: Boolean, required: true })
  readonly isOverdue!: boolean;

  @Prop({ type: Boolean, required: false })
  public retry!: boolean;

  newCashData: Reactive<CashSummaryResponse> = null;
  isLoading = false;
  hasError = false;
  showBalanceDetail = false;
  isTransitModalOpen = false;

  get isTransitAmountEnabled() {
    return !FeatureFlagsModule.isEnabled(FeatureFlags.ENABLE_TRANSIT_AMOUNT);
  }

  created() {
    this.fetchNewCash();
  }

  @Watch('retry', { deep: true })
  watchReloadCash() {
    this.fetchNewCash();
    trackReloadBalanceAccountErrorClick();
  }

  async fetchNewCash() {
    this.isLoading = true;
    this.hasError = false;

    try {
      this.newCashData = await getCashSummary();
    } catch {
      this.hasError = true;
      this.$emit('on-error', this.hasError);
      trackBalanceAccountErrorLoad();
    } finally {
      this.isLoading = false;
    }
  }

  get balance() {
    return this.cashSectionSummary.accountAmount;
  }

  get cashSectionSummary() {
    return {
      netBalance: this.getValueWithDefault(
        this.newCashData?.cashSection.summary?.netBalance?.formatted,
        ''
      ),
      accountAmount: this.getValueWithDefault(
        this.newCashData?.cashSection.summary?.accountAmount?.formatted,
        ''
      ),
      totalTransitAmount: this.getValueWithDefault(
        this.newCashData?.cashSection.summary?.totalTransitAmount?.amount
          ?.formatted
      ),
      wealthProjectedAmount: this.getValueWithDefault(
        this.newCashData?.cashSection.summary?.totalTransitAmount?.details
          ?.wealth?.projectedCreditAmount?.formatted
      ),
      tradeTotalValue: this.getValueWithDefault(
        this.newCashData?.cashSection.summary?.totalTransitAmount?.details
          ?.trade?.totalValue?.formatted
      ),
      tradeValues:
        this.newCashData?.cashSection.summary?.totalTransitAmount?.details?.trade?.items?.map(
          item => {
            return {
              label: item.label,
              value: item.value.formatted
            };
          }
        ) || [],
      isNegativeTransitAmount:
        this.newCashData?.cashSection.summary?.totalTransitAmount?.amount
          ?.value! < 0 || false
    };
  }

  getValueWithDefault(value?: string | null, defaultValue = 'R$ 0,00') {
    if (value) return value;
    return defaultValue;
  }

  get hasPositiveBalance() {
    return (
      this.newCashData?.cashSection.summary?.netBalance?.value &&
      this.newCashData.cashSection.summary.netBalance.value > 0
    );
  }

  get hasActiveAccount(): boolean {
    return Boolean(
      this.newCashData?.cashSection.status?.hasInvestmentAccountEnabled
    );
  }

  get processingWarningText() {
    return this.newCashData?.cashSection.status?.processingWarning;
  }

  get cashAccountId() {
    return this.newCashData?.cashSection.status?.investmentAccountId ?? '';
  }

  public redirectToTransactionsPage() {
    this.$router.push({ name: 'transactions' });

    trackTransactionsPageNavigation(WarrenModules.Home);
    trackEvents.trackViewMoreTransactionsEvent();
  }

  handleTransitModal() {
    this.isTransitModalOpen = !this.isTransitModalOpen;
  }
}
