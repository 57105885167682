
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ModalButton, Modal, InputPassword } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Modal,
    ModalButton,
    InputPassword
  }
})
export default class PasswordModal extends Vue {
  @Prop(Boolean)
  private showModal!: boolean;

  private isButtonConfirmDisabled = true;
  private password = '';

  @Watch('password')
  private updateButtonDisabled() {
    this.isButtonConfirmDisabled = this.password === '';
  }

  private submitPassword() {
    this.submit();
  }

  @Emit()
  private submit() {
    return this.password;
  }

  @Emit()
  private close() {}
}
