var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"trading-notes"},[_c(_setup.NavBar,{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c(_setup.BackButton,{on:{"click":_setup.onBack}})]},proxy:true}])}),_c('main',[_c(_setup.HeadingDisplay,{staticClass:"trading-notes__header",attrs:{"title":"Notas de negociação","subtitle":"Visualize suas notas de negociação disponíveis"}}),_c(_setup.FilterSelect,{staticClass:"trading-notes__filter",attrs:{"label":"","options":_setup.filterOptions,"use-option-label":""},on:{"change":_setup.fetchTradingNotes},model:{value:(_setup.filterValue),callback:function ($$v) {_setup.filterValue=$$v},expression:"filterValue"}}),(_setup.isLoading)?_c(_setup.LoaderIndicator,{attrs:{"with-container":""}}):(_setup.hasError || _setup.isEmpty)?_c('div',{staticClass:"trading-notes__feedback"},[_c(_setup.helperViewComponent,_vm._b({tag:"component",on:{"click":_setup.fetchTradingNotes}},'component',_setup.helperViewProps,false))],1):_c('div',_vm._l((_setup.availableYears),function(yearDetails,yearIndex){return _c('section',{key:yearIndex,staticClass:"trading-notes__year-section"},[_c('div',{staticClass:"trading-notes__year-container"},[_c(_setup.BaseText,{staticClass:"trading-notes__year-title",attrs:{"size":"p2","color":_setup.NebraskaColors.elementPrimary}},[_vm._v(" "+_vm._s(yearDetails.year)+" ")]),(_setup.filterValue === _setup.EFilterTypes.VARIABLE_INCOME)?_c(_setup.ButtonSecondary,{attrs:{"icon":"EA0230","label":"Baixar","aria-label":`Baixar aglomerado das notas de ${yearDetails.year}`,"icon-position":"right","loading":_setup.isFetchingYearlyNote[Number(yearDetails.year)]},on:{"click":function($event){return _setup.requestYearlyNote(yearDetails)}}}):_vm._e()],1),_vm._l((yearDetails.months),function(monthDetails,monthIndex){return _c('div',{key:monthIndex,class:[
            'trading-notes__accordion-wrapper',
            {
              'trading-notes__accordion-wrapper--opened':
                _setup.openSectionsMap[monthDetails.monthIndex]
            }
          ]},[_c('div',[_c(_setup.Accordion,{staticClass:"trading-notes__accordion",attrs:{"should-add-text-style":false},on:{"accordion-changed":isOpen =>
                  _setup.handleToggleAccordion(isOpen, monthDetails.monthIndex)},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(_setup.BaseText,{attrs:{"size":"p3","color":_setup.NebraskaColors.elementPrimary}},[_vm._v(" "+_vm._s(monthDetails.month)+" ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"trading-notes__accordion__content"},_vm._l((monthDetails.reports),function(report,reportIndex){return _c(_setup.Row,{key:reportIndex,staticClass:"trading-notes__accordion__content-row",attrs:{"has-interaction":""},on:{"click":function($event){return _setup.onClickDownload(
                      yearIndex,
                      monthIndex,
                      reportIndex,
                      report
                    )}},scopedSlots:_vm._u([{key:"top-left",fn:function(){return [_vm._v(" "+_vm._s(report.title)+" ")]},proxy:true},{key:"top-right",fn:function(){return [(report.isLoading)?_c(_setup.SpinnerLoader,{attrs:{"size":15,"color":_setup.NebraskaColors.elementSecondary}}):_c(_setup.BaseIcon,{attrs:{"icon":"EA0230","aria-label":"Download","colors":_setup.NebraskaColors.elementSecondary}})]},proxy:true}],null,true)})}),1)])],1)])})],2)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }