import { render, staticRenderFns } from "./OrderFilters.vue?vue&type=template&id=fdb9a604&scoped=true&"
import script from "./OrderFilters.vue?vue&type=script&lang=ts&"
export * from "./OrderFilters.vue?vue&type=script&lang=ts&"
import style0 from "./OrderFilters.vue?vue&type=style&index=0&id=fdb9a604&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdb9a604",
  null
  
)

export default component.exports