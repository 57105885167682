import {
  OptionPosition,
  HistoricalOptionPosition,
  ForwardContractPosition
} from '@/modules/trade/services/instruments';
import {
  DerivativesFiltersDateValues,
  DerivativesFiltersTypeValues
} from '../../types';
import { MoneyCell } from '@/modules/common/components/MoneyCell';
import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';
import { InstrumentPriceCell } from '@/modules/trade/components/InstrumentPriceCell';

export const columns = {
  [DerivativesFiltersDateValues.Today]: {
    [DerivativesFiltersTypeValues.Options]: [
      {
        label: 'Ativo',
        component: InstrumentNameCell,
        getter: ({ symbol, name, instrumentType }: OptionPosition) => ({
          symbol,
          name,
          type: instrumentType?.value
        })
      },
      {
        label: 'Tipo',
        getter: ({ optionType }: OptionPosition) => optionType.formatted,
        align: 'right'
      },
      {
        label: 'Quantidade',
        align: 'right',
        component: MoneyCell,
        getter: ({ quantity }: OptionPosition) => ({
          text: quantity,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        })
      },
      {
        label: 'Vencimento',
        getter: ({ dueDate }: OptionPosition) => dueDate.formatted,
        align: 'right'
      },
      {
        label: 'Preço exercício',
        component: MoneyCell,
        getter: ({ strikePrice }: OptionPosition) => ({
          text: strikePrice.formatted,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        }),
        align: 'right'
      },
      {
        label: 'Preço atual',
        component: MoneyCell,
        getter: ({ currentPrice }: OptionPosition) => ({
          text: currentPrice.formatted,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        }),
        align: 'right'
      },
      {
        label: 'Saldo total',
        component: MoneyCell,
        getter: ({ balance }: OptionPosition) => ({
          text: balance.formatted,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        }),
        align: 'right'
      }
    ],
    [DerivativesFiltersTypeValues.Forwards]: [
      {
        label: 'Ativo',
        component: InstrumentNameCell,
        getter: ({
          symbol,
          name,
          instrumentType
        }: ForwardContractPosition) => ({
          symbol,
          name,
          type: instrumentType?.value
        })
      },
      {
        label: 'Quantidade',
        align: 'right',
        component: MoneyCell,
        getter: ({ quantity }: ForwardContractPosition) => ({
          text: quantity,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        })
      },
      {
        label: 'Vencimento',
        getter: ({ dueDate }: ForwardContractPosition) => dueDate.formatted,
        align: 'right'
      },
      {
        label: 'Preço a termo',
        component: MoneyCell,
        getter: ({ forwardPrice }: ForwardContractPosition) => ({
          text: forwardPrice.formatted,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        }),
        align: 'right'
      },
      {
        label: 'Preço atual',
        component: MoneyCell,
        getter: ({ currentPrice }: ForwardContractPosition) => ({
          text: currentPrice.formatted,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        }),
        align: 'right'
      },
      {
        label: 'Valor da posição',
        component: MoneyCell,
        getter: ({ positionPrice }: ForwardContractPosition) => ({
          text: positionPrice.formatted,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        }),
        align: 'right'
      }
    ]
  },
  [DerivativesFiltersDateValues.Historical]: {
    [DerivativesFiltersTypeValues.Options]: [
      {
        label: 'Ativo',
        component: InstrumentNameCell,
        getter: ({
          symbol,
          name,
          instrumentType
        }: HistoricalOptionPosition) => ({
          symbol,
          name,
          type: instrumentType?.value
        })
      },
      {
        label: 'Tipo',
        getter: ({ optionType }: HistoricalOptionPosition) =>
          optionType.formatted,
        align: 'right'
      },
      {
        label: 'Quantidade',
        align: 'right',
        component: MoneyCell,
        getter: ({ quantity }: HistoricalOptionPosition) => ({
          text: quantity,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        })
      },
      {
        label: 'Vencimento',
        getter: ({ dueDate }: HistoricalOptionPosition) => dueDate.formatted,
        align: 'right'
      },
      {
        label: 'Preço exercício',
        component: MoneyCell,
        getter: ({ strikePrice }: HistoricalOptionPosition) => ({
          text: strikePrice.formatted,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        }),
        align: 'right'
      },
      {
        label: 'Resultado',
        align: 'right',
        component: InstrumentPriceCell,
        getter: ({ result }: HistoricalOptionPosition) => ({
          price: result.amount,
          percentual: result.percentage,
          size: 'small',
          align: 'right',
          enableToggleMoney: true
        })
      },
      {
        label: 'Ganho máximo',
        component: MoneyCell,
        getter: ({ maximumGain }: HistoricalOptionPosition) => ({
          text: maximumGain.formatted,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        }),
        align: 'right'
      },
      {
        label: 'Perda máxima',
        component: MoneyCell,
        getter: ({ maximumLoss }: HistoricalOptionPosition) => ({
          text: maximumLoss.formatted,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        }),
        align: 'right'
      },
      {
        label: 'Saldo total',
        component: MoneyCell,
        getter: ({ balance }: HistoricalOptionPosition) => ({
          text: balance.formatted,
          align: 'right',
          enableToggleMoney: true,
          width: '100%'
        }),
        align: 'right'
      }
    ],
    [DerivativesFiltersTypeValues.Forwards]: []
  }
};
