
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  IMFAInfo,
  ILoginData,
  IMFALoginResponse
} from '@/modules/authentication/services/mfa';
import { MFALoginComponents } from './step-components';
import { EMFALoginSteps } from './types';
import { FadeTransition } from '@/modules/common/components/FadeTransition';

@Component({
  components: {
    FadeTransition
  }
})
export default class MFALogin extends Vue {
  @Prop({ type: Object, required: true })
  private infoMfa!: IMFAInfo;

  @Prop({ type: Function, default: () => {} })
  private onMfaLoginSuccess!: (data: IMFALoginResponse) => void;

  @Prop({ type: Object, required: true })
  private loginData!: ILoginData;

  private currentStep = EMFALoginSteps.MULTI_FACTOR_LOGIN;

  get step() {
    return {
      component: MFALoginComponents[this.currentStep]
    };
  }

  public handleNextStep(step?: string) {
    if (step === 'talkWarren') {
      this.currentStep = EMFALoginSteps.TALK_TO_WARREN;
    } else {
      this.currentStep++;
    }
  }

  public handlePreviousStep(step?: string) {
    if (step === 'disconnectDevices') {
      this.currentStep = EMFALoginSteps.DISCONNECT_DEVICES;
    } else {
      this.currentStep--;
    }
  }
}
