import { object, string } from 'yup';
import { getString } from '@/modules/common/helpers/resources';

const MESSAGES = {
  PASSWORD_REQUIRED: getString('common_field_oldpassword_error_required'),
  NEW_PASSWORD_REQUIRED: getString('common_field_newpassword_error_required'),
  CONFIRMATION_REQUIRED: getString(
    'common_field_confirmpassword_error_required'
  ),
  PASSWORD_LENGTH: getString('password_length_requirement'),
  PASSWORD_NUMBER: getString('password_number_character_requirement'),
  PASSWORD_UPPER: getString('password_upper_character_requirement'),
  PASSWORD_LOWER: getString('password_lower_character_requirement'),
  PASSWORD_SPECIAL: getString('password_special_character_requirement')
};

const passwordRegex = /[$&+,:;=?@#|'<>.^*()%!-]/;

export const currentPasswordSchema = object({
  password: string().required(MESSAGES.PASSWORD_REQUIRED)
});

export const newPasswordSchema = object({
  newPassword: string()
    .required(MESSAGES.NEW_PASSWORD_REQUIRED)
    .min(6, MESSAGES.PASSWORD_LENGTH)
    .matches(/\d/, MESSAGES.PASSWORD_NUMBER)
    .matches(/[A-Z]/, MESSAGES.PASSWORD_UPPER)
    .matches(/[a-z]/, MESSAGES.PASSWORD_LOWER)
    .matches(passwordRegex, MESSAGES.PASSWORD_SPECIAL)
});

export const newPasswordConfirmationSchema = object({
  newPasswordConfirmation: string().required(MESSAGES.CONFIRMATION_REQUIRED)
});
