import { object, string } from 'yup';
import { isValid as isValidCPF } from 'cpf';
import { isFullName } from '@/modules/common/helpers/name';
import { YupValidation } from '@/modules/common/helpers/yup-validation';
import { isString } from '@/modules/common/helpers/string';
import { isMarried } from '../../helpers/is-married';

function isValidCnpj(cnpj: string) {
  const patern = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

  return patern.test(cnpj);
}

function isValidRne(rne: string) {
  return rne.length === 9;
}

const schema = object().shape({
  maritalStatus: string()
    .test({
      test: isString,
      message: 'Estado civil é obrigatório.'
    })
    .required('Estado civil é obrigatório.'),

  partnerName: string()
    .nullable()
    .when('maritalStatus', {
      is: isMarried,
      then: string()
        .test({
          test: isFullName,
          message: 'Complete com mais de 1 nome.'
        })
        .required('Nome da(o) companheira(o) é obrigatório.'),
      otherwise: string()
    }),

  partnerCpf: string()
    .nullable()
    .when('maritalStatus', {
      is: (maritalStatus: string) =>
        maritalStatus === 'casado-brasileiro-nato' ||
        maritalStatus === 'casado-brasileiro-naturalizado' ||
        maritalStatus === 'uniao-estavel',
      then: string()
        .test({
          test: isValidCPF,
          message: 'Informe um CPF válido.'
        })
        .required('CPF é obrigatório.'),
      otherwise: string()
    }),

  partnerForeignRne: string()
    .nullable()
    .when('maritalStatus', {
      is: (maritalStatus: string) => maritalStatus === 'casado-estrangeiro',
      then: string()
        .test({
          test: isValidRne,
          message: 'Informe um RNE válido'
        })
        .required('RNE é obrigatório.'),
      otherwise: string()
    }),

  profession: object()
    .test({
      test: ({ cbo, profession, sinacor }) =>
        isString(cbo) && isString(profession) && isString(sinacor),
      message: 'Informe uma ocupação válida.'
    })
    .required('Por favor, selecione sua ocupação.'),

  workOrganization: string()
    .nullable()
    .when(['hasProfession'], {
      is: (hasProfession: boolean) => hasProfession,
      then: string()
        .test({
          test: isString,
          message: 'Informe uma instituição válida.'
        })
        .required('Informe a instuição que você trabalha.'),
      otherwise: string()
    }),

  workOrganizationCnpj: string()
    .nullable()
    .when(['hasProfession'], {
      is: (hasProfession: boolean) => hasProfession,
      then: string()
        .test({
          test: isValidCnpj,
          message: 'Informe um CNPJ válido.'
        })
        .required('Informe o CNPJ da instuição que você trabalha.'),
      otherwise: string()
    })
});

export default new YupValidation(schema);
