
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  Heading,
  FilterSelect,
  HelperViewContextual
} from '@warrenbrasil/nebraska-web';
import { SuggestionCard } from './components/SuggestionCard';
import { IInvestmentSuggestion } from '@/modules/wealth/services/investment-suggestions';

@Component({
  components: {
    Heading,
    FilterSelect,
    HelperViewContextual,
    SuggestionCard
  }
})
export default class SuggestionsSection extends Vue {
  @Prop({ type: Array, required: true })
  private suggestions!: IInvestmentSuggestion[];

  @Emit('suggestion-click')
  emitSuggestionClick() {}

  filteredSuggestions: IInvestmentSuggestion[] = [];
  selectedFilter = '';
  filterOptions = [
    {
      label: 'Todos',
      value: ''
    },
    {
      label: 'Investimento',
      value: 'Investment'
    },
    {
      label: 'Resgate',
      value: 'Redemption'
    }
  ];

  get isDisabled() {
    return this.suggestions.length === 0;
  }

  get isEmpty() {
    return this.filteredSuggestions.length === 0;
  }

  get emptyTexts() {
    switch (this.selectedFilter) {
      case 'Investment':
        return {
          description: 'Não existem sugestões de investimento pendentes'
        };
      case 'Redemption':
        return {
          description: 'Não existem sugestões de resgate pendentes'
        };
      default:
        return {
          description: 'Não existem sugestões pendentes',
          buttonLabel: 'Voltar para a home'
        };
    }
  }

  handleChangeFilter() {
    this.filteredSuggestions = this.suggestions;

    if (this.selectedFilter !== '') {
      this.filteredSuggestions = this.filteredSuggestions.filter(item => {
        return item.type.value === this.selectedFilter;
      });
    }
  }

  goHome() {
    this.$router.push({ name: 'home' });
  }

  mounted() {
    this.filteredSuggestions = this.suggestions;
  }
}
