var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',{attrs:{"is-open":_vm.localOpen,"title":_vm.title},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('SimpleButton',{attrs:{"label":"Cancelar"},on:{"click":_vm.close}}),(!_vm.isNewInvestorAccount)?_c('SimpleButton',{attrs:{"label":"Escolher conta"},on:{"click":_vm.switchProfile}}):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"grid-container"},[_c('p',[_vm._v("Selecione abaixo a conta que deseja visualizar:")]),_c('div',{staticClass:"wrn-p-t-05",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.selectedAccountType = _vm.AccountType.Partner}}},[_c('AccountButton',{class:{
          'selected-account': _vm.selectedAccountType === _vm.AccountType.Partner
        },attrs:{"user-name":_vm.advisorAccountButtonInfo.userName,"account-description":_vm.advisorAccountButtonInfo.accountDescription,"avatar-image-u-r-l":_vm.advisorAccountButtonInfo.avatarImageURL,"icon-name":_vm.advisorAccountButtonInfo.iconName}})],1),(_vm.investorAccountButtonInfo && !_vm.isNewInvestorAccount)?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.selectedAccountType = _vm.AccountType.Investor}}},[_c('AccountButton',{class:{
          'selected-account': _vm.selectedAccountType === _vm.AccountType.Investor
        },attrs:{"user-name":_vm.investorAccountButtonInfo.userName,"account-description":_vm.investorAccountButtonInfo.accountDescription,"icon-letter":_vm.investorAccountButtonInfo.iconLetter}})],1):_vm._e(),(_vm.isNewInvestorAccount)?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.selectedAccountType = _vm.AccountType.Investor;
        _vm.switchProfile();}}},[_c('AccountButton',{class:{
          'selected-account': _vm.selectedAccountType === _vm.AccountType.Investor
        },attrs:{"user-name":"Criar conta como investidor","icon-name":"ic_plus"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }