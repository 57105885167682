
import { Component, Vue } from 'vue-property-decorator';
import {
  Heading,
  DataTable,
  DataTableProps,
  BaseText,
  LoaderIndicator,
  FilterOption
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { StatementAvatarCell } from '@/modules/transaction/views/Transactions/components/StatementAvatarCell';
import { StatementFilters } from './components/StatementFilters';
import { StatementFiltersLoader } from './components/StatementFiltersLoader';
import { StatementError } from '@/modules/transaction/views/Transactions/components/StatementError';
import { StatementEmpty } from '@/modules/transaction/views/Transactions/components/StatementEmpty';
import { ReceiptModal } from '@/modules/transaction/components/ReceiptModal';
import {
  getStatementList,
  IStatementItem,
  IStatementListRequest,
  IStatementPagination,
  IStatementTransaction
} from '@/modules/transaction/services/unified-account';
import { EDirection } from '@/modules/transaction/services/unified-account/enums';
import {
  IStatementTransactions,
  IFilters,
  IFiltersValue,
  ITransactionType
} from './types';
import { mountTable } from './helpers';
import { useDashStore } from '@/modules/common/store/DashModule';
import * as trackEvents from '@/modules/transaction/views/Transactions/track-events';
import { getTransactionsReport } from '@/modules/transaction/services/banking';
import { forceFileDownload } from '@/modules/common/helpers/force-download';

@Component({
  components: {
    Heading,
    DataTable,
    StatementFilters,
    StatementFiltersLoader,
    StatementError,
    StatementEmpty,
    StatementAvatarCell,
    ReceiptModal,
    BaseText,
    LoaderIndicator,
    FilterOption
  }
})
export default class StatementList extends Vue {
  private readonly dashModule = useDashStore();

  private isLoading = false;
  private isScrolling = false;

  private scrollTop = document.documentElement.scrollTop;

  hasAppliedFilters = false;
  selectedTransaction: IStatementTransaction | null = null;
  isReceiptModalOpen = false;
  isEmpty = false;
  hasError = false;
  isFetchingDownload = false;
  selectedFilterType: ITransactionType = ITransactionType.Statement;
  filtersOptions: Reactive<IFilters | undefined> = null;

  upcomingTransfersCounter: number | null = null;
  upcomingTransfers: IStatementTransactions[] | null = null;
  statements: IStatementTransactions[] | null = null;

  pastStatementItems: IStatementItem[] = [];
  pastUpcomingTransfers: IStatementItem[] = [];

  statementPagination: IStatementPagination | null = null;
  upcomingTransfersPagination: IStatementPagination | null = null;

  filters: IStatementListRequest = {
    page: 1
  };

  get ITransactionType() {
    return ITransactionType;
  }

  get hasScrolled() {
    return this.scrollTop >= 100;
  }

  get isDisabled() {
    return this.isLoading || (this.isEmpty && !this.hasAppliedFilters);
  }

  get showMoney() {
    return this.dashModule?.showMoney;
  }

  get computedClass() {
    return this.showMoney ? '' : 'hidden-fields';
  }

  get columns(): DataTableProps.Column[] {
    return [
      {
        width: '4.11%',
        component: StatementAvatarCell,
        getter: ({ icon, direction }) => ({
          iconName: icon,
          type: direction
        })
      },
      {
        label: 'Movimentação',
        width: '46.25%',
        getter: ({ name, description }) => ({
          text: name,
          description
        })
      },
      {
        label: 'Data',
        width: '23.11%',
        align: DataTableProps.Align.Right,
        getter: 'date'
      },
      {
        label: 'Valor',
        width: '23.11%',
        align: DataTableProps.Align.Right,
        getter: ({ value, direction }) => ({
          text: value,
          color: this.getColor(direction)
        })
      },
      {
        width: '3.42%',
        type: 'icon',
        getter: () => ({
          icon: 'EA0170',
          ariaLabel: 'Detalhes da transação'
        })
      }
    ];
  }

  private getColor(type: EDirection) {
    return type === EDirection.Credit
      ? NebraskaColors.monetaryPositive
      : NebraskaColors.textPrimary;
  }

  observerLastRow = (observerContentRow: IntersectionObserver) => {
    setTimeout(() => {
      const rows = document.querySelectorAll('[data-testid="content-row"]');

      if (rows.length === 0) return;

      const lastRow = rows[rows.length - 1];
      observerContentRow.observe(lastRow);
    }, 100);
  };

  async registerObserver(type: ITransactionType) {
    const observerContentRow = new IntersectionObserver(
      async ([lastRow], observer) => {
        if (!lastRow.isIntersecting) return;

        observer.unobserve(lastRow.target);
        type === ITransactionType.Statement
          ? await this.getStatementList(true)
          : await this.fetchUpcomingTransfers(true);
        this.observerLastRow(observerContentRow);
      }
    );

    this.observerLastRow(observerContentRow);
  }

  private treatFetchProperties({
    type,
    scrolling
  }: {
    type: ITransactionType;
    scrolling: boolean;
  }) {
    if (type === this.selectedFilterType) {
      this.hasError = false;
      this.isLoading = true;
      this.isScrolling = scrolling;
    }
  }

  private treatFetchError({ type }: { type: ITransactionType }) {
    if (type === this.selectedFilterType) {
      this.hasError = true;
      this.isScrolling = false;
    }
  }

  private treatFetchLoading({ type }: { type: ITransactionType }) {
    if (type === this.selectedFilterType) {
      this.isLoading = false;
    }
  }

  async getStatementList(scrolling = false) {
    this.treatFetchProperties({ type: ITransactionType.Statement, scrolling });
    try {
      if (this.isScrolling) {
        if (!this.statementPagination?.hasMore) {
          this.isLoading = false;
          this.isScrolling = false;
          return;
        }

        this.filters = {
          ...this.filters,
          page: (this.filters?.page ?? 0) + 1
        };
      }

      const data = await getStatementList(this.filters);

      this.upcomingTransfersCounter = data.upcomingTransfersCount;
      this.statementPagination = data.pagination;
      this.filtersOptions = data.filters;
      this.isEmpty = data.items.length === 0;

      if (this.isScrolling && this.statements) {
        this.pastStatementItems = this.mergeItemsByDate(
          this.pastStatementItems,
          data.items
        );
        this.statements = mountTable(this.pastStatementItems);
        return;
      }

      this.pastStatementItems = data.items;
      this.statements = mountTable(this.pastStatementItems);
    } catch {
      this.treatFetchError({ type: ITransactionType.Statement });
    } finally {
      this.treatFetchLoading({ type: ITransactionType.Statement });
    }
  }

  async fetchUpcomingTransfers(scrolling = false) {
    this.treatFetchProperties({
      type: ITransactionType.FutureTransactions,
      scrolling
    });
    try {
      if (this.isScrolling) {
        if (!this.upcomingTransfersPagination?.hasMore) {
          this.isLoading = false;
          this.isScrolling = false;
          return;
        }

        this.filters = {
          ...this.filters,
          page: (this.filters.page ?? 0) + 1
        };
      }

      const data = await getStatementList(this.filters);
      this.upcomingTransfersPagination = data.pagination;
      this.filtersOptions = data.filters;
      this.isEmpty = data.items.length === 0;

      if (this.isScrolling && this.upcomingTransfers) {
        this.pastUpcomingTransfers = this.mergeItemsByDate(
          this.pastUpcomingTransfers,
          data.items
        );
        this.upcomingTransfers = mountTable(this.pastUpcomingTransfers);
        return;
      }

      this.pastUpcomingTransfers = data.items;
      this.upcomingTransfers = mountTable(this.pastUpcomingTransfers);
    } catch {
      this.treatFetchError({ type: ITransactionType.FutureTransactions });
    } finally {
      this.treatFetchLoading({ type: ITransactionType.FutureTransactions });
    }
  }

  private mergeItemsByDate(
    existingItems: IStatementItem[],
    newItems: IStatementItem[]
  ): IStatementItem[] {
    const mergedItemsMap: { [key: string]: IStatementItem } = {};

    existingItems.forEach(item => {
      if (!mergedItemsMap[item.date]) {
        mergedItemsMap[item.date] = {
          ...item,
          transactions: [...item.transactions]
        };
      } else {
        mergedItemsMap[item.date].transactions.push(...item.transactions);
      }
    });

    newItems.forEach(item => {
      if (!mergedItemsMap[item.date]) {
        mergedItemsMap[item.date] = {
          ...item,
          transactions: [...item.transactions]
        };
      } else {
        mergedItemsMap[item.date].transactions.push(...item.transactions);
      }
    });

    return Object.values(mergedItemsMap);
  }

  loadError() {
    trackEvents.trackTransactionsErrorLoad();
  }

  async handleFiltersChange(data: {
    hasAppliedFilters: boolean;
    filters: IFiltersValue;
  }) {
    this.isEmpty = false;
    this.filters = { ...this.filters, page: 1, ...data.filters };
    this.hasAppliedFilters = data.hasAppliedFilters;
    this.selectedFilterType === ITransactionType.Statement
      ? await this.getStatementList(false)
      : await this.fetchUpcomingTransfers(false);
    await this.registerObserver(this.selectedFilterType);
  }

  handleClick(row: IStatementTransaction) {
    trackEvents.trackSelectMovimentationClick();
    this.changeModalStatus();
    this.selectedTransaction = row;
  }

  changeModalStatus() {
    this.isReceiptModalOpen = !this.isReceiptModalOpen;
  }

  async statementDownload(filters: IFiltersValue) {
    this.isFetchingDownload = true;
    try {
      const data = await getTransactionsReport(filters);
      forceFileDownload({
        data,
        type: 'application/pdf',
        fileName: `Warren_ExtratoConta.pdf`
      });
    } finally {
      this.isFetchingDownload = false;
    }
  }

  private handleScrollTop() {
    this.scrollTop = document.documentElement.scrollTop;
  }

  private async handleFilterOptionClick(type: ITransactionType) {
    this.selectedFilterType = type;

    if (type === ITransactionType.FutureTransactions) {
      this.filters = {
        page: 1,
        isUpcomingTransfers: true
      };
      await this.fetchUpcomingTransfers(false);
    } else {
      delete this.filters.isUpcomingTransfers;
      await this.getStatementList(false);
    }

    this.registerObserver(type);
  }

  get dataTableItems() {
    return this.selectedFilterType === ITransactionType.Statement
      ? this.statements ?? []
      : this.upcomingTransfers ?? [];
  }

  async created() {
    await this.getStatementList(false);
    window.addEventListener('scroll', this.handleScrollTop);

    await this.registerObserver(this.selectedFilterType);
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScrollTop);
  }
}
