import { TooltipModalTypes } from './types';

export const tooltipModalData = {
  [TooltipModalTypes.ProfitAmount]: {
    title: 'Rendimento',
    text: 'Os valores de rendimento e rentabilidade são apresentados a partir da data de corte de 03/01/2022.'
  },
  [TooltipModalTypes.ProfitPercentage]: {
    title: 'Rentabilidade',
    text: 'Os valores de rendimento e rentabilidade são apresentados a partir da data de corte de 03/01/2022.'
  }
};
