
import { Component, Vue } from 'vue-property-decorator';
import {
  DocumentType,
  PensionProvider
} from '@warrenbrasil/pension-types/dist/next';
import { Heading, BaseText } from '@warrenbrasil/nebraska-web';
import { DocumentModule } from '@/modules/pension/store/document';

@Component({
  name: 'StepTerms',
  components: {
    BaseText,
    Heading
  }
})
export default class StepTerms extends Vue {
  get suggestion() {
    return DocumentModule.data.documentSelected;
  }

  get isIcatuProposal() {
    return (
      this.suggestion &&
      this.suggestion.type === DocumentType.Proposal &&
      this.suggestion.entityData &&
      this.suggestion.entityData.provider === PensionProvider.Icatu
    );
  }
}
