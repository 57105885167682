import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IDetailedInstrument,
  IInstrumentsPerformanceComparison,
  ICheckRiskTolerance,
  IGetInstrumentsPerformanceComparison
} from './types';

export async function getSingleInstrument(
  id: string
): Promise<IDetailedInstrument> {
  const { instrument } = await httpClient.get<{
    instrument: IDetailedInstrument;
  }>(`/api/v2/finances/instrument/${id}`);

  return instrument;
}

export async function checkInstrumentRiskTolerance(
  customerId: string,
  instrumentId: string
) {
  return httpClient.get<ICheckRiskTolerance>(
    `api/v1/finances/customer/${customerId}/instrument/${instrumentId}/risk-tolerance`
  );
}

export async function getInstrumentsPerformanceComparison(
  body: IGetInstrumentsPerformanceComparison
) {
  return httpClient.post<IInstrumentsPerformanceComparison>(
    'api/v2/finances/instruments/market-index-comparison',
    body
  );
}

export async function getTaxInformPDF(lastYear: number) {
  /**
   * a new instance of axios was created because it does not overwrite the timeout
   * configuration that is used as a default on our platform (services/api/index.ts)
   */
  return httpClient.get<any>(
    `${process.env.VUE_APP_API_URL}/api/v2/finances/tax-inform/${lastYear}`,
    {
      responseType: 'arraybuffer',
      timeout: 300000, // 5 minutes
      headers: {
        Accept: '*/*'
      }
    }
  );
}
