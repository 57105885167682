
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { BaseText, ButtonSecondary, Heading } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    BaseText,
    ButtonSecondary,
    Heading
  }
})
export default class ChartError extends Vue {
  @Prop({ type: String, required: false })
  readonly description?: string;

  @Prop({ type: Boolean, default: true })
  readonly withFilter!: boolean;

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Emit('on-retry')
  emitOnRetry() {}
}
