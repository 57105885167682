import { EventSource } from 'extended-eventsource';
import { getAccessToken } from '@/modules/common/services/access-token';

export const pixES = () =>
  new EventSource(`${process.env.VUE_APP_SSE_API_URL}/v1/channel/pix`, {
    headers: {
      'access-token': getAccessToken()!
    },
    retry: 3000
  });
