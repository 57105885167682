import { IStatementItem } from '@/modules/transaction/services/unified-account';
import { IStatementTransactions } from '@/modules/transaction/views/Transactions/components/StatementList/types';

export function mountTable(data: IStatementItem[]) {
  let formattedData: IStatementTransactions[] = [];
  data.forEach(month => {
    formattedData.push({
      type: 'subHeading',
      leftText: month.date,
      rightText: month.amount
    });
    formattedData = formattedData.concat(month.transactions);
  });
  return formattedData;
}
