export enum OrderType {
  Buy = 0,
  Sell = 1
}

export enum OrderTypesPrimary {
  Buy = 'compra',
  Sell = 'venda'
}

export enum OrderTypesSecondary {
  Buy = 'Comprar',
  Sell = 'Vender'
}

export enum OrderErrors {
  BuyMessage = 'Você não possui saldo suficiente para esta compra',
  SellMessage = 'Você não possui esta quantidade de ativos',
  LotMaxMessage = 'A quantidade para lotes padrão deve ser múltipla de 100.',
  LotMessage = 'Para quantidades maiores que 99, negocie lotes padrão.',
  FractionalMaxSize = 'Para quantidades menores que 100, negocie ações fracionadas.'
}
