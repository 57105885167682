
import { Component, Vue } from 'vue-property-decorator';
import { ContextMessage, FormField, Input } from '@warrenbrasil/nebraska-web';
import { DocumentModule } from '@/modules/pension/store/document';

@Component({
  name: 'StepMFA',
  components: {
    ContextMessage,
    FormField,
    Input
  }
})
export default class StepMFA extends Vue {
  get state() {
    return DocumentModule.data;
  }

  get errorPasswordIncorrect() {
    return this.isPasswordIncorrect ? 'Senha incorreta, tente de novo.' : '';
  }

  get isPasswordIncorrect() {
    const errors = this.state.errors.acceptDocument || [];

    return !!errors.find((err: string) => err === 'password_not_authorized');
  }

  clearErrors() {
    DocumentModule.clearAcceptErrors();
  }
}
