export const headerTexts = {
  pix: {
    title: 'Deposite via Pix de outra conta que seja sua',
    subtitle:
      'O horário de recebimento é de segunda a sexta, das 8h às 23h59, exceto feriados. Transações fora desse horário serão creditadas no próximo dia útil.'
  },
  ted: {
    title: 'Deposite via TED de outra conta que seja sua',
    subtitle:
      'O horário de recebimento é de segunda a sexta, das 8h às 17h, exceto feriados. Transações fora desse horário serão creditadas no próximo dia útil.'
  }
};

export const helperViewTexts = {
  title: 'Erro de carregamento',
  description:
    'Não foi possível carregar o conteúdo. Por favor, tente novamente.',
  button: 'Tentar novamente'
};
