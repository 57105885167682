
import { Component, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ButtonIconContextual } from '@warrenbrasil/nebraska-web';
import { getMenuItems } from './navigation';
import { NavbarMenu } from './components/NavbarMenu';
import { IconCountBadge } from './components/IconCountBadge';
import { isImpersonate } from '@/modules/common/services/partner';
import { useDashStore } from '@/modules/common/store/DashModule';
import { ShowMoneyToggleButton } from '@/modules/common/components/buttons/ShowMoneyToggleButton';

import { trackWealthTabClick } from './track-events';

const UIModule = namespace('UIModule');
const NotificationsModule = namespace('NotificationsModule');

@Component({
  components: {
    ButtonIconContextual,
    NavbarMenu,
    IconCountBadge,
    ShowMoneyToggleButton
  }
})
export default class AppNavbar extends Vue {
  readonly NebraskaColors = NebraskaColors;
  readonly dashModule = useDashStore();

  @NotificationsModule.Getter('getUnreadNotificationsCount')
  private unreadNotificationsCount!: number;

  @NotificationsModule.Action('fetchNotifications')
  private fetchNotifications!: Function;

  @UIModule.Getter('getIsTransparentNavBar')
  private isTransparent!: boolean;

  @UIModule.Mutation('openLeftSideBar')
  private openLeftSideBar!: () => void;

  @UIModule.Mutation('setNavBarRef')
  private setNavBarRef!: (navBarRef: Vue) => void;

  @UIModule.Getter('getIsOpenWelcomeCurtain')
  private isShowingWelcomeSession!: boolean;

  @Ref()
  private readonly navbarTemplateRef!: Vue;

  get menu() {
    return getMenuItems();
  }

  mounted() {
    this.setNavBarRef(this.navbarTemplateRef);
    this.fetchNotifications();
  }

  get isImpersonateFlag() {
    return isImpersonate();
  }

  public tabSelect(item: string) {
    if (item === 'Carteiras') {
      trackWealthTabClick();
    }
  }
}
