import { IPortfolioBackground } from '@/types/models/Portfolio';

interface IBFFNumberResponse {
  value: number;
  formatted: string;
}

interface IBFFBooleanResponse {
  value: boolean;
  formatted: string;
}
export interface IWithdrawPortfolio {
  name: string;
  isCustom: boolean;
  totalBalance: IBFFNumberResponse;
}

export interface IWithdrawProduct {
  available: IBFFBooleanResponse;
  id: string;
  name?: string;
  minimumFinancialMovement: IBFFNumberResponse;
  minimumBalance: IBFFNumberResponse;
  daysToRedeem: number;
  availableToWithdraw?: boolean;
  totalNetWorthAvailable: IBFFNumberResponse;
  totalGrossWorthAvailable: IBFFNumberResponse;
  valueToInvest?: number;
}

export interface IGetProductsWithdrawResponse {
  allocationsToRedeem: {
    portfolio: IWithdrawPortfolio;
    products: IWithdrawProduct[];
  };
}

export interface IRedemptionPreviewPayload {
  customerId: string;
  portfolioId: string;
  products: {
    instrumentDetailApiId: string;
    amount: number;
  }[];
  validation: boolean;
}

export interface IRedemptionPreviewResponseError {
  error: {
    code: number;
    key: string;
    message: { value: string; formatted: string };
  };
}

export type IRedeemValueDetails = {
  grossValue: IBFFNumberResponse;
  netValue: IBFFNumberResponse;
  ir: IBFFNumberResponse;
  iof: IBFFNumberResponse;
  brandTax: IBFFNumberResponse;
};

export type IRedemptionPreviewProducts = {
  id: string;
  name: string;
  liquidationDate: string;
} & IRedeemValueDetails;

export interface IRedemptionPreviewResponse {
  redemptionPreview: {
    portfolio: {
      name: string;
    };
    products: IRedemptionPreviewProducts[];
    summaryPreview: IRedeemValueDetails;
  };
}

export interface IExecuteRedemptionRequest {
  portfolioId: string;
  customerId: string;
  partnerId?: string;
  products: {
    productApiId: string;
    amount: number;
  }[];
}

export interface IExecuteRedemptionResponse {
  executeRedemption: {
    requestApiId: string;
    status: string;
  };
}

export enum ERedeemOption {
  REDEEM_BY_PORTFOLIO = 'REDEEM_BY_PORTFOLIO',
  REDEEM_BY_PRODUCT = 'REDEEM_BY_PRODUCT'
}

export interface IGetTransactionOptions {
  investment: {
    id: string;
    title: string;
    subTitle: string;
  }[];
  redeem: {
    id: ERedeemOption;
  }[];
}

export interface IGetTransactionOptionsResponse {
  options: IGetTransactionOptions;
  investmentSuggestion: {
    key: 'br.com.warren.ip-pex.enable-investment-suggestion';
    enabled: boolean;
  };
}
export interface IRedemptionProductSuggestion {
  productApiId: string;
  status: string;
  amount: number;
  orderId: number;
  createdAt: string;
  createdAtBrtTimeZone: string;
  modifiedAt: string;
  operatedAt: string;
  expectedFinishAt: string;
}

export interface IRedemptionSuggestionData {
  portfolio: { id: string; name: string };
  createdAt: string;
  transactionId: string;
  products: IRedemptionProductSuggestion[];
}
export interface IGetRedemptionSuggestionsResponse {
  acceptanceListToRedeem: {
    count: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    pageIndex: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
    data: IRedemptionSuggestionData[];
  };
}

export interface IWealthToWithdrawResponse {
  portfolios: {
    data: IWealthToWidthdrawData[];
  };
}

export interface IWealthToWidthdrawData {
  id: string;
  name: string;
  totalBalance: IWealthTotalBalance;
  goal: string;
  background: IPortfolioBackground;
  availableToWithdraw: boolean;
  isCustom: boolean;
  showManagementIcon?: boolean;
  totalGrossWorth: number;
}

interface IWealthTotalBalance {
  value: number;
  formatted: string;
}

export interface IWealthToWithdrawResponseSuggested {
  isWithdrawPending: boolean;
  data: DataWealthToWithdrawResponseSuggested;
}

export interface DataWealthToWithdrawResponseSuggested {
  portfolioName: string;
  isCustom: boolean;
  buttons: Button[];
  currentBalance: Values;
  minimumWithdraw: Values;
  maximumWithdraw: Values;
}

export interface Button {
  type: string;
  value: number;
  formatted: string;
}

export interface IWealthToWithdrawResponseSuggestedPreview {
  grossAmountRequested: Values;
  netValue: Values;
  operationTax: Values;
  incomeTax: Values;
  totalFees: Values;
}

export interface Values {
  formatted: string;
  value: number;
}

export interface IWealthToWithdrawResponseSuggestedSummary {
  from: string;
  to: string;
  amount: Values;
  netValue: Values;
  plannedAllocation: PlannedAllocation[];
}

export interface PlannedAllocation {
  label: string;
  value: Values;
}

export interface ISendPortfolioToWithdrawSuggested {
  portfolioId: string;
  amount: number;
  partnerId?: string;
}

export interface IRedemptionWithdrawResponse {
  error?: {
    code: number;
    key: string;
    message: { value: string; formatted: string };
  };
}
