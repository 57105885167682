import { httpClient } from '@/modules/common/services/http/http-client';
import { MaritalStatus, Profession, State, Address } from './types';

const httpAccountBff = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_ACCOUNT_URL
});

export async function getMaritalStatusList(): Promise<MaritalStatus[]> {
  const maritalStatusListResponse = await httpAccountBff.get(
    'v1/resources/getMaritalStatusList'
  );

  return maritalStatusListResponse.maritalStatusList.map(
    (maritalStatus: { value: string; key: string }) => ({
      label: maritalStatus.value,
      value: maritalStatus.key
    })
  );
}

export function getProfessionList(): Promise<Profession[]> {
  return httpAccountBff.get('v1/resources/getProfessionsList');
}

export async function getStateList(): Promise<State[]> {
  const stateListResponse = await httpAccountBff.get(
    'v1/resources/getBrazilianStateList'
  );

  return stateListResponse.stateList.map(
    (state: { value: string; key: string }) => ({
      label: state.value,
      value: state.key
    })
  );
}

export async function getAddressByZipcode(zipcode: string): Promise<Address> {
  const addressByZipcodeResponse = await httpAccountBff.get(
    'v1/resources/getAddressByZipcode',
    {
      params: { zipcode: zipcode.replace('-', '') }
    }
  );

  return addressByZipcodeResponse.address;
}
