
import { Component, Vue, Prop } from 'vue-property-decorator';
import { RoundedIcon } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

type Size = 'sm' | 'md' | 'lg';

@Component({
  components: {
    RoundedIcon
  }
})
/**
 * @deprecated - use Nebraska button instead
 */
export default class BaseRoundedButton extends Vue {
  @Prop({ type: Boolean, default: false })
  public coloredBackground!: boolean;

  @Prop({ type: String, default: 'md' })
  public size!: Size;

  public alt!: string;

  public icon!: string;

  private created() {
    console.warn(
      'Using deprecated BaseRoundedButton, please use a Nebraska button instead.'
    );
  }

  private get backgroundColor() {
    if (this.coloredBackground) {
      return 'rgba(255, 255, 255, 0.2)';
    } else {
      return NebraskaColors.backgroundHover;
    }
  }

  private get color() {
    return NebraskaColors.elementPrimary;
  }

  private get computedBackgroundSize() {
    switch (this.size) {
      case 'lg':
        return '40';
      case 'sm':
        return '24';
      default:
        return '32';
    }
  }

  private get computedIconSize() {
    switch (this.size) {
      case 'lg':
        return '32';
      case 'sm':
        return '18';
      default:
        return '24';
    }
  }
}
