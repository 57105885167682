export type { APIResponse } from '@/modules/pension/types/APIResponse';
export { APIResponseStatus } from '@/modules/pension/types/APIResponseStatus';
export type { DocumentAcceptRequest } from '@/modules/pension/types/DocumentAcceptRequest';
export type { DocumentRejectRequest } from '@/modules/pension/types/DocumentRejectRequest';
export type { DocumentListFilters } from '@/modules/pension/types/DocumentListFilters';
export { DocumentModalSteps } from '@/modules/pension/types/DocumentModalSteps';
export type { DocumentStoreState } from '@/modules/pension/types/DocumentStoreState';
export type RiskLevel = 'low' | 'medium' | 'high';
export type PensionContractType = 'contract' | 'migration';

export interface IPensionAuthUser {
  apiId: string;
  name: string;
  phone: string;
  email: string;
  monthlyIncome?: number;
}

export interface IPensionLead {
  apiId?: string;
  type: PensionContractType;
  name: string;
  phone: string;
  email: string;
  monthlyIncome?: number;
  simulation?: IPensionSimulationData;
}

export interface IPensionSimulationConfig {
  minAge: number;
  agePlaceholder: number;
  initialInvestment: number;
  monthlyInvestment: number;
  riskLevel: RiskLevel;
}

export interface IPensionSimulationData {
  age: number;
  initialInvestment: number;
  monthlyInvestment: number;
  riskLevel: RiskLevel;
  totalValue: number;
}

export interface IPensionSimulationResult {
  totalValue: number;
  maxAge: number;
  chart: IPensionSimulationChartData[];
}

export interface IPensionSimulationChartData {
  line: string;
  lineType: string;
  fillColor: string;
  lineColor: string;
  data: number[];
}

export interface IPensionBeneficiaries {
  beneficiary: IBeneficiary[];
}
export interface IAliquots {
  value: number;
  grossValue: number;
}

export interface IBeneficiary {
  name: string;
  percentualDistribution: number;
}
export interface ICoverage {
  amountContributionCoverage: number;
  beneficiaries: IBeneficiary[];
}
export interface IPension {
  contractNumber: string;
  insuranceCompany: string;
  productName: string;
  taxType: ETaxType;
  incomeTaxPlan: ETaxPlan;
  aliquots: IAliquots[];
  coverages: ICoverage[];
  status: string;
  lastUpdateStatusAt: Date;
  lastSyncedAt: Date;
}

export enum ETaxType {
  Regressivo = 10,
  Progressivo = 20
}

export enum ETaxPlan {
  PGBL = 0,
  VGBL = 1
}

export interface IPensionContractDetails {
  productApiId: string;
  contractNumber: string;
  insuranceCompanyName: string;
  productName: string;
  taxType: string;
  incomeTaxPlan: string;
  lastSyncedAt: Date;
  totalQuotes: number;
  grossWorth: number;
  netWorth: number;
  emitter: string;
  aliquots: Array<{
    value: number;
    grossValue: number;
    netValue: number;
    quotes: number;
  }>;
  coverages: Array<{
    name: string;
    amountContributionCoverage: number;
    beneficiaries: Array<{
      name: string;
      percentualDistribution: number;
    }>;
  }>;
}
