export enum ETradeChartAxisY {
  Earnings = 'EARNINGS',
  Portfolio = 'PORTFOLIO',
  Projection = 'PROJECTION',
  Planning = 'PLANNING',
  Deposits = 'DEPOSITS',
  DepositsProjection = 'DEPOSITS_PROJECTION',
  TotalBalance = 'TOTAL_BALANCE',
  Savings = 'SAVINGS',
  Ipca = 'IPCA',
  Cdi = 'CDI'
}

export enum ETradeChartAxisX {
  Dates = 'DATES'
}

export interface TradeChartAxisX {
  key: ETradeChartAxisX;
  description: string;
  values: string[];
}

export interface TradeChartAxisY {
  key: ETradeChartAxisY;
  description: string;
  values: Array<{ value: number } | null>;
}

export enum ETradeChartFilter {
  Default = 'DEFAULT',
  ThirtyDays = 'THIRTY_DAYS',
  TwelveMonths = 'TWELVE_MONTHS'
}

export type TradeChartFilter = {
  __typename?: 'TradeChartFilter';
  key: ETradeChartFilter;
  description: string;
};

export enum ETickerPriceHistoryPeriod {
  LastFiveYears = 'LAST_FIVE_YEARS',
  LastYear = 'LAST_YEAR',
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK',
  IntraDay = 'INTRA_DAY'
}
