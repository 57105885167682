
import { Component, Vue } from 'vue-property-decorator';
import {
  DocumentType,
  Portability,
  PortabilityAmountType,
  PortabilitySourceInfo,
  PortabilityTransferInfo
} from '@warrenbrasil/pension-types/dist/next';
import { Heading, Row, ToggleSwitch } from '@warrenbrasil/nebraska-web';
import { moneyFormat } from '@/modules/common/helpers/currency';
import { DocumentModule } from '@/modules/pension/store/document';
import { PensionLabels } from '@/modules/pension/helpers/labels';

@Component({
  name: 'SuggestionPortability',
  components: {
    Row,
    Heading,
    ToggleSwitch
  }
})
export default class SuggestionPortability extends Vue {
  protected PensionLabels = PensionLabels;

  get state() {
    return DocumentModule.data;
  }

  get portability(): Portability {
    const { documentSelected } = this.state;

    if (
      !documentSelected ||
      documentSelected.type !== DocumentType.PortabilityTerm
    ) {
      return {} as Portability;
    }

    return documentSelected.entityData as Portability;
  }

  get origin(): PortabilitySourceInfo {
    return this.portability.origin || {};
  }

  get destination(): PortabilitySourceInfo {
    return this.portability.destination || {};
  }

  get transferInfo(): PortabilityTransferInfo {
    return this.portability.transferInfo || {};
  }

  get transferAmount(): string {
    const { transferInfo } = this.portability;
    const { amountType, amount } = transferInfo;

    return amountType === PortabilityAmountType.Currency
      ? moneyFormat(amount)
      : `${amount} %`;
  }

  openDocumentPdf() {
    DocumentModule.openDocumentPdf();
  }
}
