
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Heading, Row } from '@warrenbrasil/nebraska-web';
import { TransactionBadge } from '@/modules/common/components/TransactionBadge';
import { HandleSection } from '@/modules/trade/components/HandleSection';

import { getEventsProvisionedList } from '@/modules/trade/services/events-provisioned';
import { EventsProvisionedList } from '@/modules/trade/views/TradeView/components/TradeSidebar/types';
import { SidebarTypes } from '@/modules/trade/views/TradeView/components/TradeSidebar';
import {
  trackViewAllEventsProvisionedClick,
  trackProvisionedEventsRequestError,
  trackProvisionedEventsRetryClick
} from '@/modules/trade/views/TradeView/trackers';
import { logError } from '@/modules/trade/helpers/logs';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    Heading,
    HandleSection,
    TransactionBadge,
    Row
  }
})
export default class EventsProvisioned extends Vue {
  @Prop({ type: Function })
  readonly setPanel!: (param: SidebarTypes) => void;

  @tradePositionsModule.Mutation('setEventsProvisionedList')
  readonly setEventsProvisionedList!: Function;

  public loading = false;
  public error = false;
  private eventsProvisioned: EventsProvisionedList =
    {} as EventsProvisionedList;

  private async fetchEventsProvisioned() {
    const eventsProvisioned = await getEventsProvisionedList();
    this.eventsProvisioned = eventsProvisioned;
    this.setEventsProvisionedList(eventsProvisioned);
  }

  created() {
    this.setupEventsProvisionedData();
  }

  public get showEventsProvisioned() {
    return this.hasEventsProvisioned || this.error || this.loading;
  }

  public get eventsProvisionedSliced() {
    return this.eventsProvisioned?.provisionedEvents?.slice(0, 3);
  }

  public get showSeeMoreButton() {
    return this.eventsProvisioned?.provisionedEvents?.length > 3;
  }

  private get hasEventsProvisioned() {
    return this.eventsProvisioned?.provisionedEvents?.length > 0;
  }

  public handleClickSeeAllEventsProvisioned() {
    trackViewAllEventsProvisionedClick();
    this.setPanel(SidebarTypes.EventsProvisionedList);
  }

  async handleOnRetry() {
    trackProvisionedEventsRetryClick();
    await this.setupEventsProvisionedData();
  }

  private async setupEventsProvisionedData() {
    this.loading = true;
    this.error = false;
    try {
      await this.fetchEventsProvisioned();
    } catch (e) {
      logError(e, 'request_provisioned_events_error');
      trackProvisionedEventsRequestError();
      this.error = true;
    } finally {
      this.loading = false;
    }
  }
}
