
import { Vue, Prop, Component } from 'vue-property-decorator';
import { Heading, FilterList, BaseText } from '@warrenbrasil/nebraska-web';
import { FilterCheckboxList } from '../FilterCheckboxList';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  EFilterCategoryComponent,
  IFilterCategory,
  IFilterSubcategory,
  IFilterOptions,
  IFilterQuickOptions
} from '../../../../services/types/models/IFilters';

const FilterComponentMap = {
  [EFilterCategoryComponent.Chip]: FilterList,
  [EFilterCategoryComponent.Checkbox]: FilterCheckboxList
};

@Component({
  components: { Heading, BaseText, FilterList, FilterCheckboxList }
})
export default class FilterCategory extends Vue {
  @Prop({ type: Object, required: true })
  filter!: IFilterCategory;

  @Prop({ type: Boolean, default: false, required: false })
  readonly isSubCategory!: boolean;

  @Prop({
    type: String,
    required: false,
    validator: value =>
      Object.keys(FilterComponentMap).some(component => component === value)
  })
  readonly component!: EFilterCategoryComponent;

  readonly NebraskaColors = NebraskaColors;
  options?: Array<unknown>;

  public created() {
    this.options = this.getFilterOptions(this.filter.filters);
  }

  selectOption(option: IFilterQuickOptions) {
    if (option.checked) {
      if (this.filter.modalSelectedOption && option.checked) {
        this.filter.modalSelectedOption?.push(option.value);
      } else {
        this.filter.modalSelectedOption = [];
        this.filter.modalSelectedOption.push(option.value);
      }
    } else {
      this.filter.modalSelectedOption = this.filter.modalSelectedOption?.filter(
        item => item !== option.value
      );
    }
  }

  isFilterChecked(option: string) {
    return this.filter.modalSelectedOption?.includes(option);
  }

  getCategoryName(filter: IFilterCategory | IFilterSubcategory) {
    return 'categoryName' in filter
      ? filter.categoryName
      : filter.subCategoryName;
  }

  getFilterOptions(filters?: IFilterOptions[]) {
    return filters?.map(filter => ({
      checked: this.isFilterChecked(filter.value),
      label: filter.formatted,
      value: filter.value,
      icon: filter.icon
    }));
  }

  get filterComponent() {
    return FilterComponentMap[this.component];
  }

  get hasSubcategories() {
    return this.$slots['sub-categories'];
  }
}
