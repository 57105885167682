
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { ModalButton, ButtonIconContextual } from '@warrenbrasil/nebraska-web';

@Component({
  components: { ModalButton, ButtonIconContextual }
})
export default class ModalFooterWallet extends Vue {
  @Prop({ type: String, required: true })
  readonly leftButtonLabel!: string;

  @Prop({ type: String, default: 'Fechar' })
  readonly rightButtonLabel!: string;

  @Prop({ type: Boolean, default: false })
  readonly disabledLeftButton!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabledRightButton!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showRightButton!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showLeftButton!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly showLoading!: boolean;

  @Emit('left-button-clicked')
  public leftButtonClicked() {}

  @Emit('right-button-clicked')
  public rightButtonClicked() {}
}
