
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  BaseIcon,
  Heading,
  Row,
  InitialAvatarIcon
} from '@warrenbrasil/nebraska-web';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

export enum ModuleType {
  EXTERNAL,
  INTERNAL,
  LANDING_PAGE
}

export interface Module {
  type: ModuleType;
  icon: string;
  name: string;
  path: string;
  feature?: FeatureFlags;
  id: string;
  trackFunction?: () => void;
  shouldRender?: boolean;
}

const customerModule = namespace('CustomerModule');

@Component({
  components: {
    BaseIcon,
    Heading,
    Row,
    InitialAvatarIcon
  }
})
export default class HomeSecondaryLinks extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: String, required: true })
  private title?: string;

  @Prop({ type: Array, required: true })
  private modules!: Module[];

  @customerModule.State('isImpersonated')
  readonly isImpersonated!: boolean;

  @customerModule.Getter('isCustomerPJ')
  readonly isCustomerPJ!: boolean;

  public get getModules() {
    return this.modules.filter(module => {
      let isFeatureEnabled = true;
      let shouldRender = true;

      if (module.feature)
        isFeatureEnabled = FeatureFlagsModule.isEnabled(module.feature);
      if (typeof module.shouldRender === 'boolean')
        shouldRender = module.shouldRender;

      return isFeatureEnabled && shouldRender && module;
    });
  }

  public openModule(module: Module) {
    if (module.trackFunction) module.trackFunction();

    if (module.type === ModuleType.INTERNAL) {
      this.$router.push(module.path);
    } else if (module.type === ModuleType.LANDING_PAGE) {
      window.open(module.path);
    } else {
      window.location.assign(module.path);
    }
  }
}
