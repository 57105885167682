
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ModalFooter } from '@/modules/common/components/__deprecated__/Modal';
import {
  NavBar,
  BaseText,
  Row,
  BaseIcon,
  BaseModal
} from '@warrenbrasil/nebraska-web';
import { CloseButton } from '@/modules/common/components/buttons';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Checkbox } from '@/modules/common/components/__deprecated__/Checkbox';
import { ProductDetail } from '@/modules/common/components/ProductDetail';
import * as termsTracker from './track-events';
import { InstrumentDetail } from '@/modules/trade/types/instrument';

enum actionClose {
  back = 'back',
  close = 'close',
  cancelled = 'cancelled'
}

@Component({
  components: {
    BaseModal,
    ModalFooter,
    CloseButton,
    NavBar,
    BaseText,
    Checkbox,
    Row,
    BaseIcon,
    ProductDetail,
    BackButton
  }
})
export default class TermsAcceptanceModal extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public isOpen!: boolean;

  @Prop({ type: Array, required: true })
  public products!: InstrumentDetail[];

  @Prop({ type: Boolean, required: false })
  public viewTerms!: boolean;

  @Prop({ type: Boolean, default: false })
  public isInvestmentByProduct!: boolean;

  @Prop({ type: Boolean, default: false })
  public suggestInvestment!: boolean;

  private NebraskaColors = NebraskaColors;
  private checkboxValue = false;

  public get actionClose() {
    return actionClose;
  }

  private goToDocument(path: string) {
    window.open(path, '_blank', 'resizable=yes');
  }

  public handleClickCheckbox() {
    if (!this.isInvestmentByProduct && !this.suggestInvestment) {
      termsTracker.trackCheckbox();
    } else if (this.isInvestmentByProduct) {
      termsTracker.trackInvestmentCheckBox();
    } else if (this.suggestInvestment) {
      termsTracker.trackAcceptedRisks();
    }
  }

  @Emit('cancelled')
  public close(action: string) {
    if (!this.isInvestmentByProduct && !this.suggestInvestment) {
      termsTracker.trackCancelled();
    } else if (this.isInvestmentByProduct) {
      if (action === actionClose.back) termsTracker.trackInvestmentBack();
      if (action === actionClose.close) termsTracker.trackInvestmentClose();
      if (action === actionClose.cancelled)
        termsTracker.trackInvestmentCancelled();
    } else if (this.suggestInvestment) {
      if (action === actionClose.back) termsTracker.trackBackBtn();
      if (action === actionClose.close) termsTracker.trackCloseBtn();
      if (action === actionClose.cancelled) termsTracker.trackCancelTerms();
    }
  }

  @Emit('accepted')
  public emitAccepted() {
    if (!this.isInvestmentByProduct && !this.suggestInvestment) {
      termsTracker.trackAccepted();
    } else if (this.isInvestmentByProduct) {
      termsTracker.trackInvestmentAccepted();
    } else if (this.suggestInvestment) {
      termsTracker.trackContinueTerms();
    }
  }

  private beforeMount() {
    if (!this.products.length) {
      this.$emit('update:viewTerms', false);
      this.$emit('accepted');
    }
  }
}
