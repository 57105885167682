
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  FilterOption,
  IFilterOption
} from '@/modules/common/components/FilterOption';
import { HorizontalSlider, SkeletonLoader } from '@warrenbrasil/nebraska-web';
import { FilterData, StringFormatted } from '@/modules/trade/types';

@Component({
  components: { FilterOption, HorizontalSlider, SkeletonLoader }
})
export default class FiltersList extends Vue {
  @Prop({ type: Object, required: true })
  readonly filters!: FilterData;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  formatFilter(filter: StringFormatted) {
    return {
      value: filter.value,
      label: filter.formatted
    };
  }

  handleOnSelectFilter(option: IFilterOption) {
    this.$emit('select-filter', option);
  }
}
