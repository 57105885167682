
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import { BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Money } from '@/modules/common/components/Money';

import {
  percentageWithSign,
  percentageTruncate
} from '@/modules/common/helpers/percentage';
import { useDashStore } from '@/modules/common/store/DashModule';

@Component({
  components: { BaseText, Money }
})
export default class ProductAllocationCard extends Vue {
  private readonly dashModule = useDashStore();
  public readonly NebraskaColors = NebraskaColors;

  @Prop({ type: String, required: true })
  private productName!: string;

  @Prop({ type: String, required: true })
  private productAllocation!: string;

  @Prop({ type: String, required: false })
  private productTotalBalance?: string;

  @Prop({ type: Number, required: false })
  private productPerformance?: number;

  @Prop({ type: String, required: false })
  private referenceValueDate?: string;

  @Prop({ type: Boolean, default: true })
  private isClickable!: string;

  public formattedPerformancePercentage(value: number): string {
    return percentageWithSign(percentageTruncate(value, 2));
  }

  public getPerformanceTextColor(value: number): NebraskaColors {
    return value >= 0
      ? NebraskaColors.monetaryPositive
      : NebraskaColors.monetaryNegative;
  }

  private get productPerformanceIsNumber() {
    return typeof this.productPerformance === 'number';
  }

  private get shouldShowMoney() {
    return this.dashModule.showMoney;
  }

  get computedClass() {
    return this.isClickable ? '' : 'unclickable-card';
  }

  @Emit()
  private onclick() {
    return () => {};
  }

  emitOnclick() {
    if (!this.isClickable) {
      return;
    }

    this.onclick();
  }
}
