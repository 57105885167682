
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import {
  BaseIcon,
  BaseText,
  Row,
  SkeletonLoader
} from '@warrenbrasil/nebraska-web';

@Component({
  name: 'AccountBalance',
  components: { BaseText, Row, SkeletonLoader, BaseIcon }
})
export default class AccountBalance extends Vue {
  @Prop({ type: Boolean, default: true })
  private isLoading!: boolean;

  @Prop({ type: String, required: false })
  private totalBalance?: string;

  private get rowDescription() {
    return 'Saldo disponível em conta';
  }

  private refreshBalance() {
    this.$emit('refresh-balance');
  }

  private get getIcon() {
    return this.isLoading ? 'ic_settings' : 'ic_redo';
  }

  private get NebraskaColors() {
    return NebraskaColors;
  }
}
