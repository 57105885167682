import { RouterViewWithTransition } from '@/modules/common/components/RouterViewWithTransition';
import InstantLoginView from '@/modules/common/views/InstantLogin/InstantLoginView.vue';
import InstantLoginFlow from '@/modules/common/views/instantLoginFlow/InstantLoginFlow.vue';
import PlatformMaintenance from '@/modules/common/views/PlatformMaintenance/PlatformMaintenance.vue';
import NavigationErrorView from '@/modules/common/views/NavigationError/NavigationErrorView.vue';
import RedirectToMobile from '@/modules/common/views/RedirectToMobile/RedirectToMobile.vue';
import LandingApp from '@/modules/common/views/LandingApp/LandingApp.vue';
import { RouteConfig } from 'vue-router';

export const commonRoutes: RouteConfig[] = [
  {
    name: 'intranet',
    path: '/intranet',
    component: RouterViewWithTransition,
    meta: { requiresAuth: false },
    children: [
      {
        name: 'instantLogin',
        path: 'instant-login/:hash',
        component: InstantLoginView,
        meta: {
          service: 'instantLogin'
        }
      },
      {
        name: 'instantLoginIntranet',
        path: 'instant-login-intranet/:hash',
        component: InstantLoginView,
        meta: {
          service: 'instantLoginIntranet'
        }
      }
    ]
  },
  {
    path: '/instant-login/:hash',
    redirect: { name: 'instantLogin' },
    meta: { requiresAuth: false }
  },
  {
    path: '/instant-login-intranet/:hash',
    redirect: { name: 'instantLoginIntranet' },
    meta: { requiresAuth: false }
  },
  {
    path: '/impersonate-flow',
    meta: { requiresAuth: false },
    component: RouterViewWithTransition,
    children: [
      {
        name: 'impersonateFlow',
        path: '/impersonate-flow/partner/:partnerHash/customer/:customerHash',
        component: InstantLoginFlow
      }
    ]
  },
  {
    name: 'manutencao',
    path: '/manutencao',
    meta: { requiresAuth: false },
    component: PlatformMaintenance
  },
  {
    name: 'navigation-error',
    path: '/navigation-error',
    meta: { requiresAuth: false },
    component: NavigationErrorView
  },
  {
    name: 'landing-app',
    path: '/landing-app',
    meta: { requiresAuth: false },
    component: LandingApp
  },
  {
    name: 'redirect-to-mobile',
    path: '/mobile',
    meta: { requiresAuth: false },
    component: RedirectToMobile
  }
];
