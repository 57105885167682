import { HomeChartDateFiltersKeys } from '@/modules/trade/services/account';

export enum ChartFiltersKeys {
  DATE_FILTER = 'DATE_FILTER',
  BENCHMARK_FILTER = 'BENCHMARK_FILTER'
}

export type ChartFilterValue = HomeChartDateFiltersKeys | string | null;

export type ChartFilterOptions = {
  value: string | number;
  label: string;
  active?: boolean;
  disabled?: boolean;
};

export type ChartFiltersTypes = {
  [keyof in ChartFiltersKeys]: {
    options: ChartFilterOptions[];
    value: ChartFilterValue;
  };
};
