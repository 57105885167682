
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  ReviewStep,
  ButtonContainer,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { PortfolioTermsModal } from '@/modules/common/components/PortfolioTermsModal';
import { RiskValidation } from '@/modules/common/components/RiskValidation';
import { RejectModal } from './components/RejectModal';
import { SuggestionDetails } from '../SuggestionDetails';
import { EInvestmentSuggestionsSteps } from '@/modules/wealth/views/InvestmentSuggestions/helpers';
import { IGetSuggestionDetailsResponse } from '@/modules/wealth/services/investment-suggestions';
import { IInvestmentProfile } from '@/types/models';
import { namespace } from 'vuex-class';
import { getCustomerInvestmentProfile } from '@/modules/common/services/http/account';
import { isImpersonate } from '@/modules/common/services/partner';
import { IPortfolioTerms } from '@/modules/wealth/services/portfolio-terms';
import * as trackEvents from '@/modules/wealth/views/InvestmentSuggestions/track-events';

const CustomerModule = namespace('CustomerModule');

@Component({
  components: {
    ReviewStep,
    SuggestionDetails,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary,
    RiskValidation,
    RejectModal,
    PortfolioTermsModal
  }
})
export default class SuggestionReview extends Vue {
  @CustomerModule.Getter('getCustomerId')
  readonly customerId!: string;

  @Prop({ type: Object, required: true })
  private suggestionDetails!: IGetSuggestionDetailsResponse;

  @Prop({ type: Object })
  private portfolioTerms?: IPortfolioTerms;

  @Emit('change-step')
  emitChangeStep(_step: EInvestmentSuggestionsSteps) {}

  @Emit('accepted')
  emitAccepted() {
    return {
      transactionId: this.suggestionDetails.transaction.transactionId,
      status: 'accepted',
      customerId: this.customerId,
      updatedBy: this.customerId,
      portfolioId: this.suggestionDetails.transaction.portfolioApiId
    };
  }

  @Emit('rejected')
  emitRejected() {
    return {
      transactionId: this.suggestionDetails.transaction.transactionId,
      status: 'rejected',
      customerId: this.customerId,
      updatedBy: this.customerId
    };
  }

  isRejectModalOpen = false;
  showRiskValidation = false;
  showPortfolioTerms = false;
  investmentProfile: IInvestmentProfile = {};

  get title() {
    return this.isInvestment ? 'investimento' : 'resgate';
  }

  get isInvestment() {
    return this.suggestionDetails.transaction.direction === 'Investment';
  }

  get productsIds() {
    return this.suggestionDetails.transaction.products.map(
      item => item.productApiId
    );
  }

  get portfolioId() {
    return this.suggestionDetails.transaction.portfolioApiId;
  }

  get isImpersonateFlag() {
    return isImpersonate();
  }

  async created() {
    await this.getCustomerProfile();
  }

  async getCustomerProfile() {
    const { investmentProfile } = await getCustomerInvestmentProfile(
      this.customerId
    );
    this.investmentProfile = investmentProfile;
  }

  acceptSuggestion() {
    const isAdvancedInvestment =
      this.suggestionDetails.transaction.direction === 'Investment' &&
      this.suggestionDetails.portfolio.managementType === 'Manual';
    if (isAdvancedInvestment) {
      if (
        this.suggestionDetails.agreements.requestTerms ||
        this.suggestionDetails.agreements.requestRisks
      ) {
        this.showRiskValidation = true;
        return;
      }
    }

    const isWarrenManagement =
      this.suggestionDetails.transaction.direction === 'Investment' &&
      this.suggestionDetails.portfolio.managementType === 'Warren';
    if (isWarrenManagement && this.portfolioTerms?.requestRisksTerm) {
      this.showPortfolioTerms = true;
      return;
    }

    this.emitAccepted();
  }

  onRiskValidationFinish() {
    this.emitAccepted();
  }

  onRiskValidationAbort() {
    this.showRiskValidation = false;
  }

  rejectSuggestion() {
    trackEvents.trackRefuseInvestmentSuggestion();
    this.handleRejectModal();
  }

  handleRejectModal() {
    this.isRejectModalOpen = !this.isRejectModalOpen;
  }

  goBack() {
    this.emitChangeStep(EInvestmentSuggestionsSteps.VIEW);
  }
}
