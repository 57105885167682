
import { Component, Vue, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash-es';

import { Instrument, getInstruments } from '@/modules/trade/services/search';
import { StringFormatted, FilterData } from '@/modules/trade/types';
import { IFilterOption } from '@/modules/common/components/FilterOption';

import {
  trackSearchInstruments,
  trackSearchListViewLoad,
  trackFilterClick
} from './trackers';
import {
  NavBar,
  Input,
  ActionableHelperView,
  SkeletonLoader
} from '@warrenbrasil/nebraska-web';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { InstrumentsListContainer } from './components/InstrumentsListContainer';

enum fetchInstrumentsStatus {
  ERROR,
  SUCCESS,
  FETCHING
}

const defaultFilter = { value: 'all', label: 'Todos' };

@Component({
  components: {
    NavBar,
    BackButton,
    Input,
    ActionableHelperView,
    SkeletonLoader,
    InstrumentsListContainer
  }
})
export default class SearchListView extends Vue {
  private searchValue = '';
  private inputValue = '';
  private selectedFilter: IFilterOption = defaultFilter;
  private filters: IFilterOption[] = [];
  private instruments: Instrument[] = [];
  private fetchInstrumentsStatus = fetchInstrumentsStatus;
  private currentInstrumentsStatus: fetchInstrumentsStatus =
    fetchInstrumentsStatus.FETCHING;

  private created() {
    this.setInitialInputValue();
    trackSearchListViewLoad();
  }

  @Watch('searchValue')
  private watchSearchValue() {
    this.getInstruments();
  }

  private onInputChange = debounce(this.handleDebouncedInputChange, 500);

  private handleDebouncedInputChange(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    if (target.value) {
      this.searchValue = target.value;
    }
  }

  private setInitialInputValue() {
    const { ticker } = this.$route.params;
    this.inputValue = ticker;
    this.searchValue = ticker;
  }

  private async getInstruments() {
    try {
      this.currentInstrumentsStatus = fetchInstrumentsStatus.FETCHING;
      const { filters, items } = await getInstruments(
        this.searchValue,
        this.selectedFilter.value
      );

      this.setFiltersData(filters);
      this.instruments = items;

      this.currentInstrumentsStatus = fetchInstrumentsStatus.SUCCESS;
      trackSearchInstruments(this.searchValue, this.selectedFilter.label);
    } catch {
      this.currentInstrumentsStatus = fetchInstrumentsStatus.ERROR;
    }
  }

  private setFiltersData(filters: FilterData) {
    const selectedFilter = filters.available.find(
      filter => filter.value === filters.selected
    );
    if (selectedFilter) {
      this.selectedFilter = this.formatFilter(selectedFilter);
    }

    this.filters = filters.available.map(this.formatFilter);
  }

  private formatFilter(filter: StringFormatted) {
    return {
      value: filter.value,
      label: filter.formatted
    };
  }

  private onFilterSelect(filter: IFilterOption) {
    this.selectedFilter = filter;
    trackFilterClick(filter.label);
    this.getInstruments();
  }

  private onBackClick() {
    this.$router.back();
  }

  private clearSearchValue() {
    this.inputValue = '';
  }

  private get computedHasInputValue() {
    return this.inputValue.length > 0;
  }
}
