export class Download {
  static base64PDF(base64: string, fileName: string) {
    if (!base64) {
      return;
    }

    const link = window.document.createElement('a');
    link.innerHTML = '';
    link.href = `data:application/octet-stream;base64,${base64}`;
    link.download = `${fileName}.pdf`;
    link.target = '_blank';

    window.document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the
    // latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    window.document.body.removeChild(link);
  }
}
