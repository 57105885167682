
import { Component, Vue } from 'vue-property-decorator';
import { SkeletonLoader } from '@warrenbrasil/nebraska-web';
import TwoColumnsContainer from './TwoColumnsContainer.vue';

@Component({
  components: {
    SkeletonLoader,
    TwoColumnsContainer
  }
})
export default class TwoColumnsContainerLoader extends Vue {}
