import { GtmTracker } from '@/modules/common/services/analytics';
import { AccountUpdateTab } from './types';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'register_data',
  digital_experience: 'investor',
  experience_section: 'menu'
});

export function trackViewLoad() {
  trackEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

const TabEventName = {
  [AccountUpdateTab.Personal]: 'select_personal_data',
  [AccountUpdateTab.Contact]: 'select_address_data',
  [AccountUpdateTab.Finance]: 'select_banking_data'
};

export function trackTabClick(tab: AccountUpdateTab, label: string) {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'list_header',
    event_name: TabEventName[tab],
    event_target: label
  });
}

export function trackSaveClick() {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'save',
    event_target: 'Salvar'
  });
}
