
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
/**
 * @deprecated - use Nebraska Grid instead
 */
export default class Columns extends Vue {
  @Prop({ type: Number, default: 2 })
  public columns!: number;

  @Prop({ type: String, default: 'row' })
  public flow!: 'row' | 'column';

  @Prop({ type: Number, default: 0 })
  public rowGap!: number;

  @Prop({ type: Number, default: 48 })
  public columnGap!: number;

  private rowNumber = 0;

  private created() {
    console.warn('Using deprecated Columns, please use Nebraska Grid instead.');
  }

  private mounted() {
    // calculates the number of rows necessary in case of grid-flow is set to 'column'
    this.rowNumber = Math.ceil(this.$children.length / this.columns);
  }

  private get computedStyle() {
    const gridStyles: any = {
      'grid-auto-flow': this.flow,
      'grid-template-columns': `repeat(${this.columns}, 1fr)`,
      'grid-row-gap': `${this.rowGap}px`,
      'grid-column-gap': `${this.columnGap}px`
    };

    if (this.flow === 'column') {
      gridStyles['grid-template-rows'] = `repeat(${this.rowNumber}, auto)`;
    }

    return gridStyles;
  }
}
