
import { Vue, Component, Prop, Emit, Ref } from 'vue-property-decorator';
import {
  NavBar,
  BaseModal,
  BaseText,
  Heading,
  ModalButton
} from '@warrenbrasil/nebraska-web';
import {
  TrackingSubscriptionPopUpActions,
  trackSubscriptionPopUp
} from '../../tracker';
import { FirstAccessDetails } from './components/FirstAcessDetails';
import { Carousel, Slide } from 'vue-carousel';
import { ICarrousel } from './types';
import { CloseButton } from '@/modules/common/components/buttons';

@Component({
  components: {
    BaseModal,
    NavBar,
    BaseText,
    Heading,
    ModalButton,
    Carousel,
    Slide,
    FirstAccessDetails,
    CloseButton
  }
})
export default class FirstAccessModal extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly isOpen!: boolean;

  @Prop({ type: Boolean, required: true })
  public hasMoreData!: boolean;

  @Prop({ type: String })
  readonly ticker!: string;

  @Prop({ type: Number })
  readonly quantity!: number;

  @Ref()
  readonly firstAccessInfo!: ICarrousel;

  public slideRightText = 'Dúvidas';

  @Emit('close-modal')
  public emitClose() {
    trackSubscriptionPopUp(TrackingSubscriptionPopUpActions.close);
  }

  @Emit('reserve-click')
  public emitReserve() {
    trackSubscriptionPopUp(TrackingSubscriptionPopUpActions.reserve);
  }

  public handleSlide() {
    if (this.firstAccessInfo.currentPage === 0) {
      this.slideRightText = 'Fechar';
      this.firstAccessInfo.goToPage(1);
    } else {
      this.emitClose();
    }
  }
}
