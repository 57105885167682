var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('label',{class:[
      'input-wrapper text-p-2',
      { error: _vm.hasError, filled: _vm.isValueHigherThanZero }
    ]},[_c('span',{staticClass:"text-p-1 text-prefix-input"},[_vm._v("R$")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"money",rawName:"v-money",value:(_vm.formatterOptions),expression:"formatterOptions"}],ref:"input",staticClass:"text-p-1",attrs:{"data-testid":"single-input","type":"tel"},domProps:{"value":(_vm.localValue)},on:{"focus":_vm.emitFocusEvent,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.emitEnterKeyEvent.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value}}}),_c('span',{staticClass:"single-input__clean-area"},[(_vm.hasError)?_c('RoundedIcon',{attrs:{"aria-hidden":"true","icon-name":"ic_attention","main-color":_vm.NebraskaColors.elementOnError,"background-size":"18","icon-size":"18px"}}):(_vm.isValueHigherThanZero && !_vm.isDisabled)?_c('button',{staticClass:"icon right-icon-selectable",attrs:{"aria-label":"Limpar valor"},on:{"click":_vm.resetLocalAmount}},[_c('RoundedIcon',{attrs:{"aria-hidden":"true","icon-name":"ic_cancel","main-color":_vm.NebraskaColors.elementPrimary,"background-size":"18","icon-size":"12px"}})],1):_vm._e()],1)]),(_vm.suggestions)?_c('div',{staticClass:"suggestions-list"},_vm._l((_vm.suggestionsFormatted),function(suggestion){return _c('button',{key:suggestion.label,attrs:{"aria-label":`Adicionar ${suggestion.value}`},on:{"click":function($event){return _vm.handleSuggestionClick(suggestion)}}},[_vm._v(" "+_vm._s(suggestion.label)+" ")])}),0):_vm._e(),_c('p',{class:[
      'text-p-5 error-message',
      { 'error-message--visible': _vm.hasError }
    ],attrs:{"aria-hidden":_vm.hasError,"role":"alert"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }