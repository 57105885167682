import { render, staticRenderFns } from "./MinimumDepositDetail.vue?vue&type=template&id=8558a9ae&scoped=true&"
import script from "./MinimumDepositDetail.vue?vue&type=script&lang=ts&"
export * from "./MinimumDepositDetail.vue?vue&type=script&lang=ts&"
import style0 from "./MinimumDepositDetail.vue?vue&type=style&index=0&id=8558a9ae&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8558a9ae",
  null
  
)

export default component.exports