import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';
import { MoneyCell } from '@/modules/common/components/MoneyCell';

import { CustomOperationPosition } from '@/modules/trade/services/instruments/';

export const columns = [
  {
    label: 'Ativo',
    component: InstrumentNameCell,
    getter: (position: CustomOperationPosition) => ({
      symbol: position.symbol,
      name: position.strategy.formatted,
      type: position.instrumentType?.value,
      size: 'small',
      hasEllipsis: true
    })
  },
  {
    label: 'Quantidade',
    align: 'right',
    component: MoneyCell,
    getter: (position: CustomOperationPosition) => ({
      text: position.quantity,
      align: 'right'
    })
  },
  {
    label: 'Preço inicial',
    align: 'right',
    component: MoneyCell,
    getter: (position: CustomOperationPosition) => ({
      text: position.initialPrice.formatted,
      description: position.initialDate.formatted,
      align: 'right',
      enableToggleMoneyOnDescription: false
    })
  },
  {
    label: 'Preço atual',
    align: 'right',
    component: MoneyCell,
    getter: (position: CustomOperationPosition) => ({
      text: position.currentPrice.formatted,
      align: 'right'
    })
  },
  {
    label: 'Resultado total',
    align: 'right',
    component: MoneyCell,
    tooltip: {
      title: 'Resultado total',
      text: 'O resultado total não é líquido de impostos e não representa a saída antecipada da operação. O valor é uma simulação caso a operação encerrasse no momento de visualização.'
    },
    getter: (position: CustomOperationPosition) => ({
      text: position.totalEarnings.formatted,
      align: 'right'
    })
  },
  {
    label: 'Vencimento',
    align: 'right',
    component: MoneyCell,
    getter: (position: CustomOperationPosition) => ({
      text: position.expiryCountdown.formatted,
      description: position.dueDate.formatted,
      align: 'right',
      enableToggleMoneyOnDescription: false,
      enableToggleMoneyOnText: false
    })
  }
];
