
import { Component, Vue, Prop } from 'vue-property-decorator';
import { uniqueId } from 'lodash-es';
import { BaseText, Button } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Button,
    BaseText
  }
})
export default class ActionCard extends Vue {
  @Prop({ type: String, required: true })
  public text!: string;

  @Prop({ type: String, required: true })
  public label!: string;

  private get cardId() {
    return uniqueId('card-action-');
  }
}
