export const talkToWarrenConstants = {
  title: 'Escolha como prefere entrar em contato',
  subtitle:
    'Você pode consultar as perguntas frequentes ou falar com nosso atendimento.',
  email: {
    icon: 'ic_mail',
    title: 'Enviar e-mail',
    subtitle: 'Entre em contato com',
    textLink: 'meajuda@warren.com.br',
    href: 'mailto:meajuda@warren.com.br'
  },
  chat: {
    icon: 'ic_chat',
    title: 'Conversar via chat',
    subtitle: 'Nossa equipe está disponível de 2ª a 6ª, das 8h às 18h.',
    textLink: 'Acessar chat'
  },
  faq: {
    icon: 'ic_help_circle',
    title: 'Consultar Perguntas frequentes',
    textLink: 'Ir para dúvidas',
    href: 'https://faq.warren.com.br'
  }
};
