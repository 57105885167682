
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { Row } from '@warrenbrasil/nebraska-web';
import { InstrumentName } from '../InstrumentName';
import { InstrumentPrice } from '../InstrumentPrice';
import { WatchListButton } from '../WatchListButton';

import { Instrument } from '@/modules/trade/services/search';
import { InstrumentWithWatchlist } from '../../services/instruments';

@Component({
  components: {
    InstrumentName,
    InstrumentPrice,
    Row,
    WatchListButton
  }
})
export default class InstrumentRow extends Vue {
  @Prop({ type: Object, required: true })
  readonly instrument!: Instrument | InstrumentWithWatchlist;

  @Prop({ type: Boolean, default: true })
  readonly hasInteraction!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasChevron!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly showWatchListButton!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hasEllipsis!: boolean;

  @Emit('on-watchlist-click')
  emitWatchlistClick() {}

  get instrumentType() {
    return this.instrument.type?.value;
  }
}
