import {
  EThemeType,
  getCustomTheme,
  ICustomTheme
} from '@/modules/common/services/local-storage';

export const isWhitelabelClient = (theme?: ICustomTheme) => {
  const customTheme = theme || getCustomTheme();
  return customTheme && customTheme.type === EThemeType.BrandManaged;
};

export const customizeTitle = (theme?: ICustomTheme) => {
  const customTheme = theme || getCustomTheme();
  const title = document.querySelector('title');
  if (customTheme && isWhitelabelClient(customTheme)) {
    if (title) {
      title.text = customTheme.brandName!;
    }
  }
};

export const customizeFavicon = (theme?: ICustomTheme) => {
  const customTheme = theme || getCustomTheme();
  if (customTheme && isWhitelabelClient(customTheme)) {
    const favicon = document.querySelector('#favicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = '/v/img/icons/favicon-32x32.png?v=3';
    }
  }
};
