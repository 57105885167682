
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import {
  Modal,
  BaseText,
  Heading,
  Row,
  ButtonPrimary,
  ButtonSecondary,
  InitialAvatarIcon,
  ButtonContainer
} from '@warrenbrasil/nebraska-web';

import { data } from './data';
import {
  trackSNeologicaRegistration,
  TrackingNeologicaRegistrationEvent
} from './tracker';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    Modal,
    BaseText,
    Heading,
    Row,
    ButtonPrimary,
    ButtonSecondary,
    InitialAvatarIcon,
    ButtonContainer
  }
})
export default class FirstAccessUnifiedAccountModal extends Vue {
  @Prop({ required: true })
  readonly isOpen!: boolean | null;

  readonly data = data;
  readonly NebraskaColors = NebraskaColors;

  @Emit('close')
  public emitClose() {
    trackSNeologicaRegistration(TrackingNeologicaRegistrationEvent.close_popup);
  }

  @Emit('explore-without-enable-market')
  exploreWithoutEnableMarket() {
    trackSNeologicaRegistration(
      TrackingNeologicaRegistrationEvent.explore_without_enabling
    );
  }

  @Emit('enable-market')
  enableMarket() {
    trackSNeologicaRegistration(
      TrackingNeologicaRegistrationEvent.enable_broker
    );
  }
}
