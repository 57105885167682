export enum RedeemErrorsTitleMessage {
  SUBMIT_VALIDATION_ERROR = 'Identificamos um problema',
  NO_PRODUCTS_ERROR = 'Não há produtos disponíveis',
  DEFAULT_ERROR = 'Algo deu errado'
}

export enum RedeemErrorsDescriptionMessage {
  SUBMIT_VALIDATION_ERROR = 'Não foi possível avançar com a solicitação deste resgate. Verifique a solicitação e tente novamente.',
  NO_PRODUCTS_ERROR = 'O saldo desta carteira ainda não está convertido em cotas resgatáveis. Tente novamente depois da conclusão deste processo.',
  DEFAULT_ERROR = 'Aguarde alguns instantes e tente novamente'
}

export enum ErrorButton {
  SUBMIT_VALIDATION_ERROR = 'Tentar Novamente',
  NO_PRODUCTS_ERROR = 'Voltar',
  DEFAULT_ERROR = 'Tentar Novamente'
}

export enum ButtonErrorAction {
  SUBMIT_VALIDATION_ERROR = 'clearComponent',
  NO_PRODUCTS_ERROR = 'handleBackNavigation',
  DEFAULT_ERROR = 'validateRedeem'
}

export enum IconError {
  SUBMIT_VALIDATION_ERROR = 'EF0061',
  NO_PRODUCTS_ERROR = 'EF0060',
  DEFAULT_ERROR = 'EF0062'
}

export enum Errors {
  SUBMIT_VALIDATION_ERROR = 'SUBMIT_VALIDATION_ERROR',
  NO_PRODUCTS_ERROR = 'NO_PRODUCTS_ERROR',
  DEFAULT_ERROR = 'DEFAULT_ERROR'
}

export type ValidationError = {
  onPageLoad: boolean;
  submitValidationError: boolean;
  genericSubmitError: boolean;
  type: Errors;
};
