
import {
  Component,
  Vue,
  Prop,
  InjectReactive,
  Ref
} from 'vue-property-decorator';
import { WarrenModules } from '@/types/navigation';
import { Operation, userCanPerform } from '@/modules/common/services/partner';
import { isSuitabilityPending } from '@/router/helpers/shouldRedirectToSuitability';
import { Action, ActionType } from './types';
import {
  trackInvestClick,
  trackDepositClick,
  trackTransferClick
} from '../track-events';
import { ButtonBoxLaunch } from '@warrenbrasil/nebraska-web';

const ITEM_TRANSITION_BASE_DELAY = 400;

@Component({
  components: {
    ButtonBoxLaunch
  }
})
export default class HomeCashActions extends Vue {
  @Prop({ type: Boolean, required: false })
  readonly loading!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  readonly hasPositiveBalance!: boolean;

  @Prop({ type: String, required: true })
  readonly cashAccountId!: string;

  @Prop({ type: Boolean, required: false, default: false })
  readonly disableActions!: boolean;

  @Prop({ type: Boolean, required: true })
  readonly isOverdue!: boolean;

  @InjectReactive('shouldTriggerHomeAnimations')
  public addLoadedClass!: boolean;

  @Ref('actionItemsRef')
  readonly actionItemsRef?: HTMLDivElement[];

  mounted() {
    this.addAnimationDelayOnButtons();
  }

  listItemClasses(isDisable: boolean) {
    return [
      'home-cash-actions__item',
      isDisable && 'home-cash-actions__item--disabled'
    ];
  }

  get actions(): Action[] {
    return [
      {
        label: 'Investir',
        icon: 'BA0020',
        disabled: this.shouldDisableAction(ActionType.Invest),
        handler: this.goToInvestmentFlow,
        primary: true
      },
      {
        label: 'Depositar',
        icon: 'EA0021',
        disabled: this.shouldDisableAction(ActionType.Deposit),
        handler: this.depositAction
      },
      {
        label: 'Transferir',
        icon: 'AA0060',
        disabled: this.shouldDisableAction(ActionType.Transfer),
        handler: this.handleTransferClick
      }
    ];
  }

  private shouldDisableAction(action: ActionType) {
    switch (action) {
      case ActionType.Invest:
        return !this.hasPositiveBalance;
      case ActionType.Deposit:
        return isSuitabilityPending() || !userCanPerform(Operation.Deposit);
      case ActionType.Transfer:
        return (
          !this.hasPositiveBalance ||
          !userCanPerform(Operation.WithdrawToExternalAccount)
        );
      case ActionType.Pay:
      default:
        return false;
    }
  }

  private regulatoryUpdate() {
    this.$router.push({
      name: 'home',
      query: { regulatoryModal: 'true' }
    });
  }

  private goToInvestmentFlow() {
    if (this.isOverdue) return this.regulatoryUpdate();

    this.$router.push({
      name: 'portfoliosToInvest',
      query: { cameFrom: WarrenModules.Home }
    });

    trackInvestClick();
  }

  private depositAction() {
    this.$router.push({ name: 'deposit-pix' });
    trackDepositClick();
  }

  handleTransferClick() {
    trackTransferClick();
    this.goToNewTransferPage();
  }

  private goToNewTransferPage() {
    this.$router.push({ name: 'new-transfer' });
  }

  private addAnimationDelayOnButtons() {
    this.actionItemsRef?.forEach((item, index) => {
      const delay = ITEM_TRANSITION_BASE_DELAY + index * 60;
      item.style.transitionDelay = `${delay}ms`;
    });
  }
}
