
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  NavBar,
  ButtonIconFoundation as ButtonIcon,
  BaseText,
  Row
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RenamePortfolioModal } from './components/RenamePortfolioModal';
import { DeletePortfolioModal } from './components/DeletePortfolioModal';
import {
  NavigationPanelWrapper,
  NavigationPanel
} from '@/modules/common/components/NavigationPanel';
import {
  IReminderModalProps,
  InvestReminderModal
} from './components/InvestReminderModal';
import { SideSheet } from '@/modules/common/components/SideSheet';
import { BaseCard } from '@/modules/common/components/cards/BaseCard';
import { WealthPortfolioBackground } from '@/modules/wealth/components/WealthPortfolioBackground';
import { GoalType } from '@/types/models/Portfolio';
import { update, rename, remove } from '@/modules/wealth/services/portfolio';
import { moneyViewer as money } from '@/modules/common/helpers/currency';
import { IWealthPortfolio } from '@/modules/wallet-builder/src/services/types/models/IBffInvestments';

@Component({
  components: {
    NavBar,
    ButtonIcon,
    BaseText,
    Row,
    RenamePortfolioModal,
    DeletePortfolioModal,
    BaseCard,
    NavigationPanelWrapper,
    NavigationPanel,
    SideSheet,
    WealthPortfolioBackground,
    InvestReminderModal
  }
})
export default class WealthConfig extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: Object, required: true })
  readonly portfolio!: IWealthPortfolio;

  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Emit('close-config')
  emitCloseConfig() {}

  @Emit('save')
  private emitSave() {}

  backgroundImage = '';
  isPortfolioEditOpen = false;
  isDeleteModalOpen = false;
  isNotAllowed = false;
  reminderModalProps = {
    title: '',
    isOpen: false,
    investReminderDay: '',
    investReminder: false,
    monthlyInvestment: 0
  };

  private setModalValues() {
    this.reminderModalProps = {
      investReminderDay: this.portfolio.investReminderDay.toString(),
      investReminder: this.portfolio.investReminder,
      monthlyInvestment: this.portfolio.monthlyInvestmentGoal,
      title: 'Lembrete',
      isOpen: false
    };
  }

  created() {
    this.setModalValues();
  }

  onBackgroundImageChanged(photo: string) {
    this.backgroundImage = photo;
    this.emitCloseConfig();
  }

  get reminderMessageButton() {
    if (!this.portfolio.investReminder) return 'Lembrete desativado';

    return `Todo dia ${this.portfolio.investReminderDay} no valor de ${money(
      this.portfolio.monthlyInvestmentGoal
    )}`;
  }

  get isEmergencyFundPortfolio() {
    return this.portfolio.goal === GoalType.EMERGENCY_FUND;
  }

  get closeButtonColors() {
    return {
      background: NebraskaColors.elementSecondary,
      content: NebraskaColors.elementPrimary,
      alphas: {
        default: 0,
        hover: 0.08,
        active: 0.08
      }
    };
  }

  handleEditPortfolioNameModal() {
    this.isPortfolioEditOpen = !this.isPortfolioEditOpen;
  }

  handleReminderModal() {
    this.reminderModalProps.isOpen = !this.reminderModalProps.isOpen;
  }

  portfolioNameEditClick() {
    this.setModalValues();
    this.handleEditPortfolioNameModal();
  }

  reminderClick() {
    this.setModalValues();
    this.handleReminderModal();
  }

  deleteClick() {
    this.isNotAllowed = false;
    this.handleDeleteModal();
  }

  handleDeleteModal() {
    this.isDeleteModalOpen = !this.isDeleteModalOpen;
  }

  async savePortfolioName(name: string) {
    try {
      await rename(this.portfolio.id, name);
      this.emitSave();
      this.isPortfolioEditOpen = false;
    } catch (error) {
      console.error(error);
    }
  }

  async saveInvestReminder({
    investReminderDay,
    investReminder,
    monthlyInvestment
  }: IReminderModalProps) {
    try {
      await update({
        investReminderDay: Number(investReminderDay),
        _id: this.portfolio.id,
        investReminder,
        skipInvestReminder: false,
        monthlyInvestment,
        initialInvestment: monthlyInvestment
      });
      this.emitSave();
      this.reminderModalProps.isOpen = false;
    } catch (error) {
      console.error(error);
    }
  }

  async deletePortfolio() {
    try {
      await remove(this.portfolio.id);
      this.$router.push({ name: 'wealth' });
    } catch (error) {
      this.isNotAllowed = true;
      console.error(error);
    }
  }
}
