
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { PersonalAddressForm } from './components/PersonalAddressForm';
import { PhoneForm } from './components/PhoneForm';

@Component({
  components: {
    PersonalAddressForm,
    PhoneForm
  }
})
export default class PersonalContactTab extends Vue {
  @Prop({ required: true }) private user!: any;

  @Emit()
  public validateField() {}
}
