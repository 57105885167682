import { RouteConfig } from 'vue-router';
import Performance from '@/modules/investments/views/Performance/Performance.vue';
import {
  Positions,
  PositionDetail
} from '@/modules/investments/views/Positions';
import { StatementDownload } from '@/modules/investments/views/StatementDownload';
import { IncomeReport } from '@/modules/investments/views/IncomeReport';
import { TradingNotes } from '@/modules/investments/views/TradingNotes';

export const investmentsRoutes: RouteConfig[] = [
  {
    name: 'investmentsPerformance',
    path: '/investments/performance',
    component: Performance
  },
  {
    name: 'investmentsPositions',
    path: '/investments/positions',
    component: Positions
  },
  {
    name: 'investmentsPositionDetail',
    path: '/investments/position/:productApiId',
    component: PositionDetail
  },
  {
    name: 'investmentsStatementDownload',
    path: '/investments/statement-download/:statementType',
    component: StatementDownload
  },
  {
    name: 'incomeReport',
    path: '/income-report',
    component: IncomeReport
  },
  {
    name: 'tradingNotes',
    path: '/trading-notes',
    component: TradingNotes
  }
];
