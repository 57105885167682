
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { RadiusPieChart } from '@/modules/common/components/charts/v2/RadiusPie';
import { moneyFormat } from '@/modules/common/helpers/currency';
import { percentageChartTruncate } from '@/modules/common/helpers/percentage';
import { IFilterOption } from '@warrenbrasil/nebraska-web';
import {
  SeriesData,
  PointSeries
} from '@/modules/common/components/charts/v2/RadiusPie/types';
import * as Highcharts from 'highcharts';
import { AssetType } from '@/modules/trade/services/instruments';

@Component({
  name: 'AllocationChart',
  components: {
    RadiusPieChart
  }
})
export default class AllocationChart extends Vue {
  @Prop({ type: Array, required: true })
  readonly seriesData!: SeriesData[];

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly titlePercentage!: string;

  @Prop({ type: String, required: true })
  readonly titleTotalValue!: string;

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean;

  @Prop({ type: Object, required: false })
  readonly selectedAsset!: any;

  @Emit('select-filter')
  emitFilterChangeEvent(assetType: AssetType, options: IFilterOption) {
    return {
      assetType,
      options
    };
  }

  public get seriesDataParsed(): SeriesData[] {
    const selectedAssetType = this.selectedAsset?.assetType?.value;
    const isAllCategoriesFilterSelected = !selectedAssetType;

    const seriesDataParsed = this.seriesData.map(data => {
      const isSelected = data.category === selectedAssetType;

      return {
        ...data,
        selected: isSelected,
        opacity: isAllCategoriesFilterSelected || isSelected ? 1 : 0.3
      };
    });

    return seriesDataParsed;
  }

  public get pointSeries(): PointSeries {
    return {
      events: {
        click: (data: Highcharts.PointClickEventObject) => {
          const isCategorySelected = !data.point?.selected;

          const value = isCategorySelected ? data.point?.category : 'all';
          const label = isCategorySelected ? data.point?.name : 'Todos';

          const total = data.point?.y as number;
          const percentageNumber = Number(data.point?.percentage);
          const percentageParsed = percentageChartTruncate(
            percentageNumber / 100
          );

          const assetType = {
            allocatedTotal: {
              formatted: moneyFormat(total),
              value: total
            },
            assetType: {
              formatted: data.point?.name,
              value: data.point?.category
            },
            percentage: {
              formatted: percentageParsed,
              value: percentageNumber
            }
          } as AssetType;

          this.emitFilterChangeEvent(assetType, {
            value,
            label
          });
        }
      }
    };
  }
}
