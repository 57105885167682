import { Route } from 'vue-router';
import { setDatadogContext } from '@warrenbrasil/web-observability/logs';

export default function setLogContext(to: Route, _from: Route, next: Function) {
  if (to.meta?.context) {
    setDatadogContext(to.meta.context);
  } else {
    setDatadogContext('common');
  }

  next();
}
