import { GtmTracker } from '@/modules/common/services/analytics';

const { trackerWithDefaultProps } = GtmTracker;

const trackAmplitudeEvent = trackerWithDefaultProps({
  page_screen: 'my_portfolio',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackWithdrawPortfolio(event_target = '') {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: 'withdraw',
    event_target
  });
}
