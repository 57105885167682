import { GtmTracker } from '@/modules/common/services/analytics';

export const trackEvent = GtmTracker.trackerWithDefaultProps({
  category: 'duvidas_details',
  label: 'geral'
});

export const trackCloseAlert = () =>
  trackEvent({ action: 'click_fechar_aviso_indisponibilidade' });
export const trackEnterStatusPage = () =>
  trackEvent({ action: 'click_entrar_status_page' });
export const trackHasIncident = () =>
  trackEvent({ action: 'view_aviso_indisponibilidade' });
export const trackEmailContact = () =>
  trackEvent({ action: 'click_entrar_email' });
export const trackChatContact = () =>
  trackEvent({ action: 'click_entrar_chat' });
