import { ICustomer } from '@/types/models';
import { PortfolioInformation } from '@/modules/transaction/services/bff-investment-transaction';

interface IWalletDetailsMinDeposit {
  minimumValueToDeposit: string;
  monthlyInvestment: string;
}

export interface IWalletDetails {
  name: string;
  totalGrossWorth: string;
  minimumDeposit: IWalletDetailsMinDeposit;
}

export interface IBankAccount {
  name: string;
  document: string;
  bankName: string;
  bankCode: string;
  agency: string;
  agencyDigit: string;
  account: string;
  accountDigit: string;
}

export enum ECameFromPath {
  WealthPortfolio = 'wealth-portfolio',
  Conversation = 'conversation',
  PortfoliosToInvest = 'portfolios-to-invest'
}

export type TotalBalance = { value: number; formatted: string };

export interface InvestmentTransactionContext {
  totalBalance: Partial<TotalBalance>;
  portfolioName: string;
  portfolioInformation: PortfolioInformation | null;
  bankAccount: IBankAccount;
  showBankDepositDetails: boolean;
  suggestions: number[];
  investmentAccountId: string;
  isCreatingTransaction: boolean;
  isCreateTransactionFailed: boolean;
  isCreateTransactionSucceeded: boolean;
  isFetchingAccountData: boolean;
  isAccountDataFetchFailed: boolean;
  isLoadingTotalBalance: boolean;
  hasMoneyInAccount: boolean;
  requestRisks: boolean;
  requestTerms: boolean;
  customer: ICustomer;
}
