
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CountBadge } from '@/modules/common/components/badges/CountBadge';

@Component({
  components: { CountBadge }
})
export default class IconCountBadge extends Vue {
  @Prop({ type: String, default: 'sm' })
  public size!: string;

  @Prop({ type: Number, required: true })
  public count!: number;

  @Prop({ type: Boolean, default: true })
  public show!: boolean;

  @Prop({ type: String, required: false })
  public backgroundColor!: string;

  @Prop({ type: String, required: false })
  public textColor!: string;
}
