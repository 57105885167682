
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  ConclusionStep,
  ContentDisplay,
  ListDisplay,
  ButtonPrimary,
  LoaderIndicator,
  HeadingDisplay
} from '@warrenbrasil/nebraska-web';
import { IPortfolioInvestmentSummary } from '@/modules/wealth/store/investments';
import {
  trackLoadSuccess,
  trackCloseButton,
  trackInvestOtherWallet,
  trackBackToWealth
} from './track-events';
import { isImpersonate } from '@/modules/common/services/partner';
import {
  getUserB2B,
  StorageKeys
} from '@/modules/common/services/local-storage';
import { B2bPartnerType } from '@/types/models/Customer/AdvisorProfile';

import { WarrenSession } from '@/modules/common/services/session';

const InvestmentsModule = namespace('InvestmentsModule');

@Component({
  name: 'InvestmentCompleted',
  components: {
    LoaderIndicator,
    ConclusionStep,
    ContentDisplay,
    ListDisplay,
    ButtonPrimary,
    HeadingDisplay
  }
})
export default class InvestmentCompleted extends Vue {
  @InvestmentsModule.Getter('investmentCompletedSummary')
  private investmentSummary!: IPortfolioInvestmentSummary;

  private isLoading = true;

  onBack(backButton = false) {
    if (backButton) {
      trackCloseButton(this.isScheduled);
    } else {
      trackBackToWealth(this.isScheduled);
    }
    this.$router.push({
      name: 'wealthPortfolio',
      params: { id: this.investmentSummary.portfolioApiId }
    });
  }

  onInvestButton() {
    trackInvestOtherWallet(this.isScheduled);
    this.$router.push({ name: 'portfoliosToInvest' });
  }

  get isScheduled() {
    return !!this.$route.query.scheduled;
  }

  get isPartnerManager(): boolean {
    const partner = getUserB2B();
    return partner?.advisorProfile?.type === B2bPartnerType.MANAGER;
  }

  get title() {
    return !this.showSuggestionMessage
      ? this.scheduledTitle
      : 'Sugestão de investimento enviada';
  }

  get scheduledTitle() {
    return this.isScheduled
      ? 'Investimento agendado'
      : 'Investimento solicitado';
  }

  get description() {
    const managerDescription = this.isPartnerManager
      ? null
      : 'A aprovação do seu cliente pela plataforma é necessária para a conclusão do investimento.';
    return !this.showSuggestionMessage
      ? this.scheduledDescription
      : managerDescription;
  }

  get scheduledDescription() {
    return this.isScheduled
      ? WarrenSession.get(StorageKeys.ScheduleInvestmentMessage)
      : 'Em breve você poderá visualizar os rendimentos.';
  }

  get showSuggestionMessage() {
    return isImpersonate();
  }

  get portfolioName() {
    return this.investmentSummary.portfolioName;
  }

  get totalAmount() {
    return this.investmentSummary.total.formatted;
  }

  get products() {
    return this.investmentSummary.products.map(product => ({
      name: product.name,
      value: product.amount.formatted
    }));
  }

  mounted() {
    const messageTracking = this.showSuggestionMessage
      ? 'Sugestão de investimento enviada'
      : 'Investimento concluído';
    trackLoadSuccess(messageTracking, this.isScheduled);

    this.isLoading = false;
  }
}
