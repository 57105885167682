import { DataTableProps } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

const riskColors: { [key: string]: NebraskaColors } = {
  LOW: NebraskaColors.statusPositive,
  MEDIUM: NebraskaColors.statusAlert,
  HIGH: NebraskaColors.statusNegative
};

const productLabel = (products: number) => {
  return products > 1 ? `${products} Produtos` : `${products} Produto`;
};

export const columns = (products: number): DataTableProps.Column[] => {
  return [
    {
      label: 'Risco',
      width: '10%',
      getter: ({ risk }) => ({
        text: risk.label,
        color: riskColors[risk.value]
      })
    },
    {
      label: productLabel(products),
      sort: 'Name',
      width: '25%',
      getter: 'name'
    },
    {
      label: 'Mínimo inicial',
      align: DataTableProps.Align.Right,
      sort: 'MinimumInvestment',
      width: '17%',
      getter: 'minimumInvestment'
    },
    {
      label: 'Preço unitário',
      align: DataTableProps.Align.Right,
      sort: 'PurchaseUnitPrice',
      width: '17%',
      getter: 'unityPrice'
    },
    {
      label: 'Vencimento',
      align: DataTableProps.Align.Right,
      sort: 'MaturityDate',
      width: '17%',
      getter: 'maturityDate'
    },
    {
      label: 'Taxa (a.a)',
      align: DataTableProps.Align.Right,
      width: '15%',
      getter: 'interestRate'
    }
  ];
};
