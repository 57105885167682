import { GtmTracker } from '@/modules/common/services/analytics';

const trackTradeEvent = GtmTracker.trackerWithDefaultProps({
  category: 'Trade'
});

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'my_assets_page',
  digital_experience: 'investor',
  experience_section: 'broker'
});

export function trackPositionsViewLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackInstrumentClick(
  instrumentSymbol: string,
  instrumentName: string,
  instrumentType: string
) {
  trackTradeEvent({
    category: 'Trade',
    action: `Clique em ${instrumentSymbol}`,
    label: 'Meus Ativos'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'select_asset',
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol,
    instrument_type: instrumentType
  });
}

export function trackFilterChange(filterLabel: string) {
  trackTradeEvent({
    action: `Clique filtro ${filterLabel}`,
    label: 'Meus ativos'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'header',
    event_name: 'select_asset_type',
    instrument_type: filterLabel
  });
}
