import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { isOrchestratorActive } from '@/modules/common/store/RegulatoryUpdateRoutesOrchestrator';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { NavigationGuard } from 'vue-router';

export const regulatoryUpdateGuard: NavigationGuard = (_to, _from, next) => {
  const isFeatureFlagEnabled = FeatureFlagsModule.isEnabled(
    FeatureFlags.REGULATORY_UPDATE
  );

  const isRegulatoryUpdateActive = isOrchestratorActive();

  if (!isFeatureFlagEnabled || !isRegulatoryUpdateActive) {
    return next({
      name: 'regulatory-update'
    });
  }

  next();
};
