
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { Modal, ModalButton } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Modal,
    ModalButton
  }
})
export default class OrderErrorModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Emit('close')
  public emitClose() {}
}
