
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

import { InstrumentFilterSelect } from '@/modules/trade/components/InstrumentFilterSelect';
import {
  InstrumentSelectionModal,
  InstrumentSelectionOptions
} from '@/modules/trade/components/InstrumentSelectionModal';

import { InstrumentFilter as IInstrumentFilter } from '@/modules/trade/services/orders';
import { InstrumentFilterStatus } from './types';

@Component({
  components: {
    InstrumentFilterSelect,
    InstrumentSelectionModal
  }
})
export default class InstrumentFilter extends Vue {
  @Prop({ type: Array, required: false })
  readonly options?: IInstrumentFilter[] | null;

  @Prop({ type: String, default: '' })
  readonly value!: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({
    type: String,
    validator: (status: InstrumentFilterStatus) => {
      return Object.values(InstrumentFilterStatus).includes(status);
    },
    default: InstrumentFilterStatus.Default
  })
  readonly status!: InstrumentFilterStatus;

  @Prop({ type: String, required: false })
  readonly resultLabel?: string;

  @Prop({ type: Function, required: false })
  readonly fetchOptions?: (text: string, page: number) => {};

  isSelectionModalOpen = false;
  searchResult: IInstrumentFilter[] = [];

  @Emit('on-select-filter')
  emitOnSelectFilter(_value: string) {}

  @Emit('on-retry')
  emitOnRetry() {}

  @Emit('on-selection-modal-open')
  emitOnSelectionModalOpen() {}

  @Emit('on-search')
  emitOnSearch(_text: string) {}

  @Emit('on-load-more')
  emitOnLoadMore() {}

  @Emit('on-close-modal')
  emitOnCloseModal() {}

  @Emit('on-cancel-modal')
  emitOnCancelModal() {}

  @Watch('options', { immediate: true })
  watchOptions() {
    this.searchResult = this.options || [];
  }

  @Watch('isSelectionModalOpen')
  watchIsSelectionModalOpen(isSelectionModalOpen: boolean) {
    if (isSelectionModalOpen) this.emitOnSelectionModalOpen();
  }

  get filterValue() {
    return this.value || InstrumentSelectionOptions.All;
  }

  get label() {
    return this.value || 'Todos os ativos';
  }

  get active() {
    return !!this.value;
  }

  handleFilterClick() {
    this.isSelectionModalOpen = !this.isSelectionModalOpen;
  }

  handleCancelSelectionModal() {
    this.closeModal();
    this.emitOnCancelModal();
  }

  handleCloseSelectionModal() {
    this.closeModal();
    this.emitOnCloseModal();
  }

  closeModal() {
    this.searchResult = this.options || [];
    this.isSelectionModalOpen = false;
  }

  handleInstrumentSelection(value: string) {
    this.closeModal();
    this.emitOnSelectFilter(value);
  }
}
