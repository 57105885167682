
import { Component, Prop, Vue } from 'vue-property-decorator';

import {
  Row,
  BaseText,
  Checkbox,
  Modal,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';

import { RiskProfile } from '@/modules/trade/services/instruments/types';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { acceptOutsideRiskProfileTerm } from '@/modules/trade/services/instruments';
import {
  trackRedirectToSuitability,
  trackAcceptRiskTerms,
  trackInstrumentRiskModalLoad
} from '@/modules/trade/views/Instrument/trackers';
import { RiskTermsConfirmation, RiskTermsAnswers } from './types';
import { NotificationTypes } from '@/modules/common/services/http/notification';

@Component({
  components: {
    BaseText,
    Row,
    Checkbox,
    Modal,
    ButtonPrimary,
    ButtonSecondary
  }
})
export default class InstrumentRiskModal extends Vue {
  @Prop({ type: Object, required: true })
  readonly riskProfile!: RiskProfile;

  @Prop({ type: String, required: true })
  readonly symbol!: string;

  @Prop({ type: String, required: true })
  readonly instrumentType!: string;

  @Prop({ type: Function, required: true })
  readonly close!: () => void;

  @Prop({ type: Function, required: true })
  readonly confirm!: (riskTerm: RiskTermsConfirmation) => void;

  @Prop({ type: String, required: true })
  readonly orderType!: string;

  hasAcceptedRisk = false;
  loading = false;
  readonly NebraskaColors = NebraskaColors;

  created() {
    trackInstrumentRiskModalLoad(this.orderType);
  }

  get instrumentRiskTolerance() {
    return this.riskProfile.instrumentRiskTolerance?.formatted;
  }

  get customerRiskTolerance() {
    return this.riskProfile.customerRiskTolerance?.formatted;
  }

  handleAnswerRiskTerms(answer: RiskTermsAnswers) {
    this.confirm({ answer });
    this.close();
  }

  handleClose() {
    this.handleAnswerRiskTerms(RiskTermsAnswers.CLOSE_RISK_TERMS);
  }

  goToSuitability() {
    trackRedirectToSuitability(this.symbol);
    this.handleAnswerRiskTerms(RiskTermsAnswers.REDO_SUITABILITY);
  }

  async acceptAndInvest() {
    trackAcceptRiskTerms(this.symbol);
    this.loading = true;
    try {
      await acceptOutsideRiskProfileTerm(this.symbol);

      this.handleAnswerRiskTerms(RiskTermsAnswers.ACCEPT_RISK_TERMS);
    } catch (error) {
      this.$notify({
        group: 'wrn-trade',
        text: 'Não foi possível realizar essa operação, tente novamente mais tarde.',
        type: NotificationTypes.error
      });
    } finally {
      this.loading = false;
    }
  }
}
