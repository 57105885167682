import { BaseHttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { IFeed } from './types';

export class NewsAPI extends BaseHttpClient {
  constructor() {
    super(undefined, process.env.VUE_APP_STORE_API_URL);
  }

  public async getHomeNews(): Promise<IFeed> {
    return this.get(
      `api/v1/store/customer/${CustomerModule.getCustomerId}/news-feed`
    );
  }

  public async trackNewsRead(code: string) {
    return this.put(
      `/api/v1/store/customer/${CustomerModule.getCustomerId}/news/${code}?read=true`
    );
  }

  public async trackNewsDiscard(code: string) {
    return this.put(
      `/api/v1/store/customer/${CustomerModule.getCustomerId}/news/${code}?discard=true`
    );
  }
}
