
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';

import { DropdownOptionItem } from './DropdownOptionItem';
import { DropdownInputValueItem } from './DropdownInputValueItem';
import { DropdownSectionItem } from './DropdownSectionItem';
import { SpinnerLoader } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { DropdownEmptyState } from './DropdownEmptyState';

import { dropdownItemTypes, IDropdownItem } from './types';
import { SuggestedInstrument } from '@/modules/trade/services/search';

@Component({
  components: {
    DropdownOptionItem,
    DropdownInputValueItem,
    DropdownSectionItem,
    SpinnerLoader,
    DropdownEmptyState
  }
})
export default class AutocompleteDropdown extends Vue {
  @Prop({ type: String, required: false })
  private inputValue?: string;

  @Prop({ type: Number, required: true })
  private maxHeight!: number;

  @Prop({ type: Array, required: false })
  private items?: IDropdownItem[];

  @Prop({ type: Boolean, default: false })
  private isLoading!: boolean;

  @Ref('dropdownContainer')
  private dropdownContainer!: HTMLDivElement;

  @Ref('inputValue')
  private inputValueComponent!: Vue;

  @Ref('dropdownItems')
  private dropdownItems?: Vue[];

  private itemsAvailable: IDropdownItem[] = [];

  private isCalculating = true;

  private NebraskaColors = NebraskaColors;

  private itemComponentsByType = {
    [dropdownItemTypes.option]: DropdownOptionItem,
    [dropdownItemTypes.section]: DropdownSectionItem
  };

  @Watch('items', { immediate: true })
  private watchItems(value: IDropdownItem[]) {
    this.itemsAvailable = value;
    if (value?.length > 0) {
      this.isCalculating = true;
      this.$nextTick(() => {
        this.calculateItemsToRender();
      });
    }
  }

  private get isEmpty() {
    return this.items?.length === 0;
  }

  private get hasItemsAvailable() {
    return this.itemsAvailable && this.itemsAvailable.length > 0;
  }

  private handleInputValueClick(inputValue: string) {
    this.$emit('input-value-click', inputValue);
  }

  private handleOptionClick(data: SuggestedInstrument) {
    this.$emit('option-click', data);
  }

  private handleCloseDropdown() {
    this.$emit('close');
  }

  private getAvailableHeight() {
    const windowHeight = window.innerHeight;
    const dropdownContainerRect =
      this.dropdownContainer.getBoundingClientRect();
    const hasSpaceToUseMaxHeight =
      windowHeight >= this.maxHeight + dropdownContainerRect.top;

    if (hasSpaceToUseMaxHeight) {
      return this.maxHeight;
    } else {
      return windowHeight - dropdownContainerRect.top;
    }
  }

  private getInitialHeight() {
    let initialHeight = 0;
    const dropdownContainerStyle = window.getComputedStyle?.(
      this.dropdownContainer
    );
    const dropdownContainerPaddingTop =
      parseFloat(dropdownContainerStyle?.getPropertyValue('padding-top')) || 0;
    const dropdownContainerPaddingBottom =
      parseFloat(dropdownContainerStyle?.getPropertyValue('padding-bottom')) ||
      0;

    if (this.inputValueComponent) {
      const searchedValueElmRect =
        this.inputValueComponent.$el.getBoundingClientRect();
      initialHeight += searchedValueElmRect.height;
    }
    initialHeight +=
      dropdownContainerPaddingTop + dropdownContainerPaddingBottom;

    return initialHeight;
  }

  private calculateItemsToRender() {
    let newItems: IDropdownItem[] = [];
    let currentHeightUsed = this.getInitialHeight();
    const availableHeight = this.getAvailableHeight();

    this.dropdownItems?.forEach((component: Vue, index: number) => {
      const element = component.$el as HTMLDivElement;
      const elementRect = element.getBoundingClientRect();
      const hasHeightToFitItem =
        currentHeightUsed + elementRect.height <= availableHeight;

      if (hasHeightToFitItem) {
        newItems.push(this.items![index]);
      }
      currentHeightUsed += elementRect.height;
    });

    const lastItemType = newItems[newItems.length - 1]?.type;
    if (lastItemType === dropdownItemTypes.section) {
      newItems.pop();
    }
    if (newItems.length < 2 && this.items && this.items.length > 1) {
      newItems = this.items.slice(0, 2);
    }

    this.itemsAvailable = newItems;
    this.isCalculating = false;
  }
}
