
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import {
  BaseIcon,
  FormField,
  Input,
  BaseText
} from '@warrenbrasil/nebraska-web';
import { UserTemplate } from '@/modules/account/components/UserTemplate';

import { getString, getList } from '@/modules/common/helpers/resources';
import { ProfessionList } from './types';

const removeSpecialCharacters = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const removeWordsSeparators = (str: string) => {
  return str.replace(/\s|[-,_;.]/g, '');
};

const filterMatch = (query: string, referenceValue: string) => {
  const nQuery = removeSpecialCharacters(removeWordsSeparators(query));
  const nReference = removeSpecialCharacters(
    removeWordsSeparators(referenceValue)
  );
  const pattern = '.*' + nQuery.split('').join('(.{1,2})?') + '.*';
  const re = new RegExp(pattern, 'i');
  return re.test(nReference);
};

const isDefaultOption = ({ cbo, profession }: ProfessionList) => {
  return cbo === '000000' && profession.toUpperCase().includes('OUTROS');
};

const sortOptions = (options: ProfessionList[]) => {
  return options.sort((a, b) => {
    const aIsBig =
      removeSpecialCharacters(a.profession) >
      removeSpecialCharacters(b.profession);
    return aIsBig ? 1 : -1;
  });
};

@Component({
  components: { BaseIcon, FormField, Input, BaseText, UserTemplate }
})
export default class ProfessionInput extends Vue {
  @Prop({ type: String, required: false, default: '' })
  private error!: string;

  @Prop({ required: true, default: '' })
  private profession!: string;

  @Prop({ required: true, default: '' })
  private cbo!: string;

  @Emit()
  private validateProfession(isValid: boolean) {
    return isValid;
  }

  private label = getString('common_field_profession_label');
  private isOpen = false;
  private query = '';
  private option: ProfessionList = {
    profession: '',
    cbo: ''
  };

  private options: ProfessionList[] = sortOptions(getList('ProfessionList'));

  private created() {
    const isCBOOnProfessionList = this.findProfessionByCBO();
    if (!isCBOOnProfessionList) {
      this.validateProfession(false);
    }
  }

  private get isDisabled() {
    return (
      !this.query ||
      !this.option.profession ||
      this.query !== this.option.profession
    );
  }

  private clear() {
    this.option.profession = '';
    this.option.cbo = '';
    this.query = '';
  }

  private closeClearPanel() {
    this.isOpen = false;
    document.body.style.overflowY = '';
    this.clear();
    this.$emit('on-close-profession-list');
  }

  private openPanel() {
    this.isOpen = true;
    document.body.style.overflowY = 'hidden';
    this.$emit('on-open-profession-list');
  }

  public useOption() {
    const filteredOptions = this.options.filter((option: ProfessionList) =>
      filterMatch(this.query, option.profession)
    );
    if (filteredOptions.length) {
      this.setOption(filteredOptions[0]);
      this.closeClearPanel();
      return;
    }

    this.option = {
      profession: '',
      cbo: ''
    };
  }

  private setOption(option: ProfessionList) {
    this.option.profession = option.profession;
    this.option.cbo = option.cbo;
    this.option.sinacor = option.sinacor;
    this.query = option.profession;
    this.$emit('on-select', this.option);
  }

  private showOption(option: ProfessionList) {
    return (
      isDefaultOption(option) || filterMatch(this.query, option.profession)
    );
  }

  private submit() {
    this.$emit('on-select', this.option);
    this.closeClearPanel();
  }

  private unmounted() {
    this.closeClearPanel();
  }

  private findProfessionByCBO() {
    return this.options.find(option => option.cbo === this.cbo);
  }
}
