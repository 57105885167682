
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SidebarErrorV2 } from '@/modules/trade/components/SidebarError';
import { SidebarLoader } from '../SidebarLoader';

@Component({
  components: {
    SidebarErrorV2,
    SidebarLoader
  }
})
export default class HandleSection extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly loading!: boolean;

  @Prop({ type: Boolean, required: true })
  readonly error!: boolean;

  @Prop({ type: String, required: true })
  readonly sectionTitle!: string;

  @Prop({ type: String, default: 'Aguarde alguns minutos e tente novamente.' })
  readonly errorDescription!: string;

  @Prop({ type: String })
  readonly errorTitle!: string;

  @Prop({ type: Function, default: () => {} })
  refresh!: () => {};
}
