import { GtmTracker } from '@/modules/common/services/analytics';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  digital_experience: 'investor',
  experience_section: 'broker'
});

export function trackOrderDetailLoad(
  orderType: string,
  orderStatus: string,
  caption: string
) {
  trackEvent({
    digital_experience: 'investor',
    experience_section: 'broker',
    event_type: 'load',
    event_name: 'view',
    order_type: orderType,
    order_status: orderStatus,
    event_target: caption
  });
}

export function trackOrderDetailClose() {
  trackEvent({
    page_screen: 'order_detail',
    event_type: 'click',
    event_name: 'close'
  });
}

export function trackOrderDetailEditAveragePrice() {
  trackEvent({
    page_screen: 'order_detail',
    event_type: 'click',
    event_name: 'edit_average_price'
  });
}

export function trackOrderDetailChangeAveragePrice() {
  trackEvent({
    page_screen: 'order_detail',
    event_type: 'click',
    event_name: 'change'
  });
}

export function trackOrderDetailCancelAveragePrice() {
  trackEvent({
    page_screen: 'order_detail',
    event_type: 'click',
    event_name: 'cancel'
  });
}

export function trackOrderDetailStatusLoad(text: string) {
  trackEvent({
    page_screen: 'order_detail',
    event_type: 'load',
    page_screen_section: 'confirmation',
    event_name: 'view',
    event_target: text
  });
}

export function trackOrderDetailStatusCompleteClick() {
  trackEvent({
    page_screen: 'order_detail',
    event_type: 'click',
    page_screen_section: 'confirmation',
    event_name: 'complete'
  });
}

export function trackOrderDetailErrorLoad() {
  trackEvent({
    page_screen: 'order_detail',
    event_type: 'load',
    event_name: 'error_popup_view'
  });
}

export function trackOrderDetailErrorRetryClick() {
  trackEvent({
    page_screen: 'order_detail',
    event_type: 'click',
    event_name: 'try_again'
  });
}
