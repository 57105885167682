
import {
  Component,
  Prop,
  Provide,
  ProvideReactive,
  Vue,
  Watch
} from 'vue-property-decorator';
import { TransfersAPI } from '@/modules/transaction/services/transfer';
import {
  PortfolioInformation,
  BffInvestmentsTransaction
} from '@/modules/transaction/services/bff-investment-transaction';
import { toFixedWithoutRounding } from '@/modules/common/helpers/number';
import {
  IBankAccount,
  InvestmentTransactionContext,
  TotalBalance
} from '../../types';
import { moneyViewer as money } from '@/modules/common/helpers/currency';
import { InvestmentsModule } from '@/modules/wealth/store/investments';
import { WarrenModules } from '@/types/navigation';
import { IInvestmentByPortfolioPayload } from '@/modules/transaction/services/bff-investment-transaction/bff-investment-transaction-types';
import { v4 as uuidv4 } from 'uuid';
import { ICustomer } from '@/types/models';
import { namespace } from 'vuex-class';
import { isImpersonate, getPartner } from '@/modules/common/services/partner';
import { getAccountBalance } from '@/modules/wealth/services/investment';

const customerModule = namespace('CustomerModule');
const invoiceService = new TransfersAPI();
const bffInvestmentsTransaction = new BffInvestmentsTransaction();

@Component({
  // fixes a vue-property-decorator bug -> thread https://github.com/kaorun343/vue-property-decorator/issues/277
  inject: []
})
export default class InvestmentTransactionProvider extends Vue {
  @customerModule.Getter('getCustomer')
  public customer!: ICustomer;

  @Prop({ type: String, required: true })
  private portfolioId!: string;

  @Prop({ type: String, required: false, default: WarrenModules.Wealth })
  private module?: string;

  private totalBalance: Partial<TotalBalance> = {};
  private portfolioInformation: Reactive<PortfolioInformation> = null;
  private portfolioName = '';

  private bankAccount = {} as IBankAccount;
  private showBankDepositDetails = false;
  private suggestions: number[] = [];

  private investmentAccountId = '';
  private requestRisks = false;
  private requestTerms = false;

  private isCreatingTransaction = false;
  private isCreateTransactionSucceeded = false;
  private isCreateTransactionFailed = false;

  private isFetchingAccountData = true;
  private isAccountDataFetchFailed = false;
  private isLoadingTotalBalance = false;

  private get hasMoneyInAccount() {
    const fixedNumber = Number(
      toFixedWithoutRounding(this.totalBalance?.value ?? 0)
    );
    return fixedNumber > 0;
  }

  @ProvideReactive()
  private get context(): InvestmentTransactionContext {
    return {
      totalBalance: this.totalBalance,
      bankAccount: this.bankAccount,
      showBankDepositDetails: this.showBankDepositDetails,
      suggestions: this.suggestions,
      portfolioName: this.portfolioName,
      portfolioInformation: this.portfolioInformation,
      investmentAccountId: this.investmentAccountId,
      isCreatingTransaction: this.isCreatingTransaction,
      isCreateTransactionFailed: this.isCreateTransactionFailed,
      isCreateTransactionSucceeded: this.isCreateTransactionSucceeded,
      isFetchingAccountData: this.isFetchingAccountData,
      isAccountDataFetchFailed: this.isAccountDataFetchFailed,
      isLoadingTotalBalance: this.isLoadingTotalBalance,
      hasMoneyInAccount: this.hasMoneyInAccount,
      requestRisks: this.requestRisks,
      requestTerms: this.requestTerms,
      customer: this.customer
    };
  }

  @Provide()
  private async fetchAccountData() {
    this.isFetchingAccountData = true;
    this.isAccountDataFetchFailed = false;

    try {
      if (this.module === WarrenModules.Wealth) {
        const [
          { instrumentValidation, portfolioInformation, bankAccount },
          accountBalance
        ] = await Promise.all([
          this.fetchValidadionTermsAndRisks(),
          this.fetchAccountBalance()
        ]);

        this.totalBalance = accountBalance.summary.totalBalance;
        this.requestRisks = instrumentValidation.requestRisks;
        this.requestTerms = instrumentValidation.requestTerms;
        this.portfolioInformation = portfolioInformation;
        this.portfolioName = portfolioInformation.name;
        this.bankAccount = bankAccount;
        this.investmentAccountId = this.portfolioId;
      }
    } catch (e) {
      console.error(e);
      this.isAccountDataFetchFailed = true;
    } finally {
      this.isFetchingAccountData = false;
    }
  }

  private redirectToInvestmentByProduct() {
    this.$router.push({
      name: 'wealthProductTransaction',
      params: { id: this.portfolioId }
    });
  }

  private async fetchValidadionTermsAndRisks() {
    const response =
      await bffInvestmentsTransaction.getPortfolioInvestmentInformation(
        this.portfolioId,
        this.customer._id
      );
    response.portfolioInformation.isCustom &&
      this.redirectToInvestmentByProduct();
    return response;
  }

  private fetchAccountBalance() {
    return getAccountBalance(this.customer._id);
  }

  @Provide()
  private async refreshBalance() {
    this.isLoadingTotalBalance = true;
    try {
      if (this.module === WarrenModules.Wealth) {
        const accountBalance = await this.fetchAccountBalance();
        this.totalBalance = accountBalance.summary.totalBalance;
      }
    } catch (err) {
      /* */
    } finally {
      this.isLoadingTotalBalance = false;
    }
  }

  @Provide()
  private resetTransactionState() {
    this.isCreatingTransaction = false;
    this.isCreateTransactionSucceeded = false;
    this.isCreateTransactionFailed = false;
  }

  private setSuggestions(totalBalance: number) {
    const defaultSuggestions = [500, 1000, 2000, 5000, 10000];
    const isBalanceHigherThanValue = (value: number) => totalBalance > value;

    if (totalBalance > 500) {
      const suggestions = defaultSuggestions.filter(isBalanceHigherThanValue);
      this.suggestions = [...suggestions, totalBalance];
    } else if (totalBalance > 0) {
      this.suggestions = [totalBalance];
    } else {
      this.suggestions = [];
    }
  }

  @Provide()
  private openBankDetailsModal() {
    this.showBankDepositDetails = true;
  }

  @Provide()
  private closeBankDetailsModal() {
    this.showBankDepositDetails = false;
  }

  @Provide()
  private async processTransaction(value: number) {
    this.resetTransactionState();
    this.isCreatingTransaction = true;

    try {
      let portfolio;

      if (this.module === WarrenModules.Wealth) {
        portfolio = await this.createWealthTransaction(value);
      } else {
        portfolio = await this.createTransaction(value);
      }

      InvestmentsModule.saveInvestmentCompletedSummary({
        portfolioApiId: this.portfolioId,
        portfolioName: this.portfolioInformation?.name!,
        products: [],
        total: { value, formatted: money(value) }
      });
      InvestmentsModule.saveLastInvestmentPortfolio(portfolio);

      this.isCreateTransactionSucceeded = true;
    } catch {
      this.isCreateTransactionFailed = true;
    } finally {
      this.isCreatingTransaction = false;
    }
  }

  @Watch('totalBalance')
  private handleAccountMoneyUpdate(totalBalance: TotalBalance) {
    const parsedValue = Number(toFixedWithoutRounding(totalBalance.value));
    this.setSuggestions(parsedValue);
  }

  private createTransaction(amount: number) {
    return invoiceService.createTransfer({
      amount,
      toPortfolios: [
        {
          amount,
          portfolio: this.portfolioId,
          portfolioName: this.portfolioInformation?.name!
        }
      ],
      fromPortfolio: this.investmentAccountId,
      fromPortfolioName: 'Conta Digital',
      directDebit: false
    });
  }

  get getPartnerId() {
    return getPartner()?._id || '';
  }

  private createWealthTransaction(amount: number) {
    const payload: IInvestmentByPortfolioPayload = {
      requestApiId: uuidv4(),
      portfolioApiId: this.portfolioId,
      customerApiId: this.customer._id,
      amount
    };

    if (isImpersonate()) {
      payload.partnerApiId = this.getPartnerId;
    }

    return bffInvestmentsTransaction.investmentByPortfolio(payload);
  }
}
