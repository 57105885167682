import { GtmTracker } from '@/modules/common/services/analytics';
import { Categories } from '@/types/models/Analytics';

const { trackerWithDefaultProps } = GtmTracker;

const trackEvent = trackerWithDefaultProps({
  category: Categories.PEX
});

const trackAmplitudeEvent = trackerWithDefaultProps({
  page_screen: 'my_portfolio',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackInvestPortfolio(event_target = '') {
  trackEvent({
    action: 'Clique Investir',
    label: event_target
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: 'invest',
    event_target
  });
}

export function trackWithdrawPortfolio(event_target = '') {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: 'withdraw',
    event_target
  });
}
