export interface IShortcutValue {
  value: number;
  formatted: string;
  type: string;
}

export enum EShortcutType {
  INCREMENT = 'INCREMENT',
  SET = 'SET'
}
