
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';

import { ButtonPrimary } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'Footer',
  components: { ButtonPrimary }
})
export default class Footer extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: Boolean, required: false, default: false })
  public readonly showRightButton!: boolean;

  @Prop({ type: String, required: true })
  public readonly leftButtonText!: string;

  @Prop({ type: String, required: false })
  public readonly rightButtonText!: string;

  @Prop({ type: Boolean, default: false })
  public readonly isLoading!: boolean;

  @Prop({ type: Boolean, default: false })
  public disabled?: boolean;

  @Emit()
  onLeftButtonClick() {}

  @Emit()
  onRightButtonClick() {}
}
