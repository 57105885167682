
import { Component, Vue } from 'vue-property-decorator';
import { DocumentType } from '@warrenbrasil/pension-types/dist/next';
import { DocumentModule } from '@/modules/pension/store/document';
import SuggestionProposal from './SuggestionProposal.vue';
import SuggestionPortability from './SuggestionPortability.vue';

@Component({
  name: 'StepDetails',
  components: {
    SuggestionProposal,
    SuggestionPortability
  }
})
export default class StepDetails extends Vue {
  get suggestion() {
    return DocumentModule.data.documentSelected || { type: 'NONE' };
  }

  get isProposal() {
    return this.suggestion.type === DocumentType.Proposal;
  }

  get isPortability() {
    return this.suggestion.type === DocumentType.PortabilityTerm;
  }
}
