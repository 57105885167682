
import { Component, Vue } from 'vue-property-decorator';
import {
  NavBar,
  Heading,
  Row,
  ContextColorProvider,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

import {
  OverviewAPI,
  InvestmentSummary,
  Position
} from '@/modules/common/services/http/overview';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { EIntrumentQuotaSubtype } from '@/types/models/Portfolio';
import { forceFileDownload } from '@/modules/common/helpers/force-download';
import {
  trackPositionBackClick,
  trackPositionDownloadClick,
  trackPositionViewLoad,
  trackPositionDetailClick
} from './track-events';

@Component({
  components: {
    ContextColorProvider,
    NavBar,
    BackButton,
    LoaderIndicator,
    Heading,
    Row
  }
})
export default class OverviewPositions extends Vue {
  private NebraskaColors = NebraskaColors;
  private summary?: InvestmentSummary = undefined;
  private isLoading = true;
  private hasError = false;

  private getPositionDetailsRoute(position: Position) {
    const route = {
      name: 'investmentsPositionDetail',
      params: {
        productApiId: position.productApiId
      }
    };

    if (position.subtype === EIntrumentQuotaSubtype.PENSION) {
      route.name = 'pensionInstrumentDetails';
    }
    return route;
  }

  private goToPositionDetail(position: string) {
    trackPositionDetailClick(position);
  }

  private async mounted() {
    await this.loadSummary();
    trackPositionViewLoad();
  }

  private back() {
    trackPositionBackClick();
    this.$router.back();
  }

  private async loadSummary() {
    try {
      this.summary = await OverviewAPI.getInvestmentSummary();
    } catch (error) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  private async printReport() {
    try {
      const report = await OverviewAPI.getFinancesReport();
      trackPositionDownloadClick();
      forceFileDownload({
        data: report,
        type: 'application/pdf',
        fileName: 'ExtratoDetalhado.pdf'
      });
    } catch (error) {}
  }
}
