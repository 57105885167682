import { httpClient } from '@/modules/common/services/http/http-client';

interface IAddress {
  zipcode: string;
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  message?: string;
}

export function getAddressByZipCode(zipcode: string) {
  return httpClient.get<IAddress>(`/api/v2/account/zipcode/${zipcode}`);
}
