
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class GenericCard extends Vue {
  @Prop({ type: String, required: true })
  private thumbnail!: string;

  @Prop({ type: String, required: true })
  private title!: string;

  @Prop({ type: String, required: true })
  private description!: string;

  private get computedThumbnail() {
    const url =
      this.thumbnail ||
      'https://images.unsplash.com/photo-1495020689067-958852a7765e?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8cmVhZGluZyUyMG5ld3N8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&w=1000&q=80';
    return { backgroundImage: `url('${url}')` };
  }
}
