import { DataTableProps } from '@warrenbrasil/nebraska-web';
import { OrdersAvatarCell } from '@/modules/wealth/views/WealthOrders/components/OrdersAvatarCell';

enum TagStatus {
  CANCELED = 'Cancelado',
  CONCLUDED = 'Concluído',
  REQUESTING = 'Solicitando',
  QUOTING = 'Cotizando',
  QUOTED = 'Cotizado',
  PROCESSING = 'Processando',
  SCHEDULED = 'Agendado'
}

export const columns: DataTableProps.Column[] = [
  {
    width: '4.11%',
    component: OrdersAvatarCell,
    getter: ({ icon }) => ({
      iconName: icon.iconName
    })
  },
  {
    label: 'Movimentação',
    width: '25.62%',
    getter: ({ title, portfolioName, productName }) => ({
      text: title,
      description: portfolioName,
      subtitle: productName
    })
  },
  {
    label: 'Data de solicitação',
    width: '18.85%',
    align: DataTableProps.Align.Right,
    getter: ({ createdAt }) => ({
      text: createdAt.formatted
    })
  },
  {
    label: 'Data de conclusão',
    width: '18.85%',
    align: DataTableProps.Align.Center,
    getter: ({ expectedFinishAt }) => ({
      text: expectedFinishAt.formatted ?? '-'
    })
  },
  {
    label: 'Valor',
    width: '18.85%',
    align: DataTableProps.Align.Right,
    getter: ({ amount }) => ({
      text: amount.formatted
    })
  },
  {
    label: 'Status',
    width: '10.28%',
    align: DataTableProps.Align.Right,
    getter: ({ status }) => ({
      tag: getTag(status)
    })
  },
  {
    width: '3.42%',
    type: 'icon',
    getter: () => ({
      icon: 'EA0170',
      ariaLabel: 'Detalhes da transação'
    })
  }
];

function getTag(status: string) {
  const tagInfo = [
    TagStatus.REQUESTING,
    TagStatus.QUOTING,
    TagStatus.QUOTED,
    TagStatus.PROCESSING,
    TagStatus.SCHEDULED
  ];
  return {
    text: status,
    error: status === TagStatus.CANCELED,
    success: status === TagStatus.CONCLUDED,
    info: tagInfo.some(value => value === status)
  };
}
