
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SimpleButton } from '@/modules/common/components/__deprecated__/SimpleButton';

@Component({
  components: { SimpleButton }
})
export default class ModalFooter extends Vue {
  @Prop({ type: String, required: true })
  public leftButtonLabel!: string;

  @Prop({ type: String, default: 'FECHAR' })
  public rightButtonLabel!: string;

  @Prop({ type: Boolean, default: false })
  public hideRightButton!: boolean;

  @Prop({ type: Boolean, default: false })
  public disabledRightButton!: boolean;

  @Prop({ type: Boolean, default: false })
  public disabledLeftButton!: boolean;

  private emitLeftButtonClickedEvent() {
    /**
     * Left button clicked event.
     * @type {void}
     */
    this.$emit('left-button-clicked');
  }

  private emitRightButtonClickedEvent() {
    /**
     * Right button clicked event.
     * @type {void}
     */
    this.$emit('right-button-clicked');
  }
}
