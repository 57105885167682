import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IStatementListResponse,
  IStatementListRequest,
  IBalancesResponse,
  IVoucherResponse,
  IVoucherRequest
} from './types';

const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/banking`
});

const consolidationHttp = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/consolidation`
});

export function getStatementList(
  filters: IStatementListRequest
): Promise<IStatementListResponse> {
  return http.get('/v2/statement/list', { params: filters });
}

export function getBalances(): Promise<IBalancesResponse> {
  return consolidationHttp.get('/v1/balance');
}

export function getVoucher(
  transactionId: string,
  filters?: IVoucherRequest,
  isUpcomingTransfer?: boolean
): Promise<IVoucherResponse> {
  const params = { ...filters };
  if (isUpcomingTransfer !== false) {
    params.isUpcomingTransfer = isUpcomingTransfer;
  }
  return http.get(`/v1/statement/voucher/${transactionId}`, { params });
}

export function getUpcomingTransfers(
  filters?: IStatementListRequest
): Promise<IStatementListResponse> {
  return http.get('/v1/statement/upcomingTransfers', { params: filters });
}
