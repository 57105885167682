
import { Component, Prop, Vue } from 'vue-property-decorator';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RoundedIcon } from '@warrenbrasil/nebraska-web';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

interface TypeformPopup {
  open: () => {};
  close: () => {};
}

@Component({ components: { RoundedIcon } })
export default class Typeform extends Vue {
  @Prop({ type: String, required: true })
  readonly formId!: string;

  @Prop({ type: Object, required: false, default: () => ({}) })
  readonly hiddenFields!: { [key: string]: string };

  @Prop({ type: Object, required: false, default: () => ({}) })
  readonly popupOptions!: { [key: string]: unknown };

  @Prop({ type: Boolean, required: false, default: true })
  readonly useFeatureFlag!: boolean;

  NebraskaColors = NebraskaColors;

  private isLoading = false;

  private isOpened = false;

  private popup?: TypeformPopup;

  private isTypeformEnabled = false;

  created() {
    this.checkFeatureFlag();
    if (this.isTypeformEnabled) this.activateTypeform();
  }

  destroyed() {
    this.popup?.close();
  }

  checkFeatureFlag() {
    this.isTypeformEnabled = this.useFeatureFlag
      ? FeatureFlagsModule.isEnabled(FeatureFlags.PARTNER_TYPEFORM)
      : true;
  }

  activateTypeform() {
    if (!window.typeformEmbed) {
      this.includeTypeformScript();
    } else {
      this.createPopup();
    }
  }

  includeTypeformScript() {
    const script = document.createElement('script');

    script.setAttribute('id', 'typef_orm_share');
    script.setAttribute('src', 'https://embed.typeform.com/embed.js');
    script.setAttribute('type', 'text/javascript');
    script.onload = () => this.createPopup();

    document.body.appendChild(script);
  }

  createPopup() {
    const defaultOptions = {
      mode: 'popover',
      autoOpen: true,
      hideHeaders: true,
      hideFooter: true,
      onClose: () => this.popupClosed(),
      onReady: () => this.popupReady(),
      onSubmit: () => this.formSubmitted()
    };
    const options = {
      ...defaultOptions,
      ...this.popupOptions
    };

    const url = `https://form.typeform.com/to/${
      this.formId
    }${this.mountHiddenFieldsParams()}`;

    this.isLoading = options.autoOpen;
    this.popup = window.typeformEmbed.makePopup(url, options);
  }

  openPopup() {
    this.isLoading = !this.isOpened;
    this.$emit('handle-typeform-open');
    this.popup?.open();
  }

  popupClosed() {
    if (this.isTypeformEnabled) {
      this.$emit('handle-typeform-close');
    }
    this.isOpened = false;
  }

  popupReady() {
    window.setTimeout(() => {
      this.isLoading = false;
      this.isOpened = true;
    }, 1000);
  }

  formSubmitted() {
    window.setTimeout(() => {
      this.$emit('handle-typeform-submit', this.formId);
      this.isTypeformEnabled = false;
      this.popup?.close();
    }, 2000);
  }

  mountHiddenFieldsParams() {
    let str = '#';
    if (Object.keys(this.hiddenFields).length === 0) return '';

    for (const key in this.hiddenFields) {
      if (str !== '#') str += '&';
      str += `${key}=${encodeURIComponent(this.hiddenFields[key])}`;
    }

    return str;
  }

  get iconName() {
    if (this.isLoading) return 'ic_settings';
    return this.isOpened ? 'ic_cancel' : 'ic_chat';
  }

  get iconClasses() {
    return {
      'form-button': true,
      'form-button--spinning': this.isLoading
    };
  }
}
