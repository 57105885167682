
import { Component, Vue, Provide } from 'vue-property-decorator';
import { FadeTransition } from '@/modules/common/components/FadeTransition';

@Component({
  components: { FadeTransition }
})
export default class RouterViewWithTransition extends Vue {
  boolean = false;

  get key() {
    return `${this.$route.path}/${String(this.boolean)}`;
  }

  /**
   * This method is used to reload the current route.
   * It triggers a change in the key prop, which forces the router-view to reload.
   * With this method we dont need to refresh the entire page to reload a route.
   */
  @Provide()
  reloadCurrentRoute() {
    this.boolean = !this.boolean;
  }
}
