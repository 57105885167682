import { GtmTracker } from '@/modules/common/services/analytics';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  category: 'OV | Carteiras sugeridas goal based'
});

export function trackCloseModal() {
  trackEvent({
    action: 'Clique',
    label: 'Icone fechar modal Warren Equals B2C'
  });
}

export function trackGoToPortfolio() {
  trackEvent({
    action: 'Clique',
    label: 'Botão investir agora Warren Equals B2C'
  });
}

export function trackGoToNext(page: number) {
  trackEvent({
    action: 'Exibição',
    label: `Modal ${page} Warren Equals B2C`
  });
}

export function trackTooltipHover() {
  trackEvent({
    action: 'Clique',
    label: 'Icone ver detalhes Warren Equals B2C'
  });
}
