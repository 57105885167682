
import { Component, Vue } from 'vue-property-decorator';
import { Row, Heading, BaseText, BaseIcon } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    Heading,
    Row,
    BaseText,
    BaseIcon
  }
})
export default class EarningDetail extends Vue {
  readonly nebraska = NebraskaColors;
}
