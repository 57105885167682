import { EVariableIncomeAcquisitionSteps } from './types';
import { Template } from '../components/Template';
import { Investment } from '../components/Investment';
import { Preview } from '../components/Preview';
import { Conclusion } from '../components/Conclusion';

export const VariableIncomeAcquisitionComponents = {
  [EVariableIncomeAcquisitionSteps.TEMPLATE]: Template,
  [EVariableIncomeAcquisitionSteps.INVESTMENT]: Investment,
  [EVariableIncomeAcquisitionSteps.PREVIEW]: Preview,
  [EVariableIncomeAcquisitionSteps.CONCLUSION]: Conclusion
};
