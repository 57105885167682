
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ISectionData } from '../types';

@Component({})
export default class DropdownSectionItem extends Vue {
  @Prop({ type: Object, required: true })
  private data!: ISectionData;
}
