
import { Component, Vue, Prop } from 'vue-property-decorator';

import { GtmTracker } from '@/modules/common/services/analytics';
import { BaseIcon, BaseText } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    BaseIcon,
    BaseText
  }
})
export default class Dropdown extends Vue {
  @Prop({ type: String, required: true })
  private itemName!: string;

  @Prop({ type: String })
  private url!: string;

  private trackMenuEvent(action: string) {
    this.$emit('open-chat');
    GtmTracker.trackEvent({
      label: 'Produtos',
      category: 'Navigation Desktop',
      action
    });
  }
}
