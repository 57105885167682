class WarrenSession {
  private localStorage = window.localStorage || {};
  private sessionStorage = window.sessionStorage || {};
  private incognitoStorage: any = {};

  public isEmpty(key: string) {
    return !this.get(key);
  }

  public remove(key: string) {
    if (this.localStorage) {
      this.localStorage.removeItem(key);
      this.sessionStorage.removeItem(key);
    } else {
      delete this.localStorage[key];
      delete this.sessionStorage[key];
      delete this.incognitoStorage[key];
    }
  }

  public get(key: string) {
    if (this.incognitoStorage[key]) return this.incognitoStorage[key];

    if (this.localStorage) {
      if (key in this.sessionStorage) {
        return this.sessionStorage.getItem(key);
      } else {
        return this.localStorage.getItem(key);
      }
    } else {
      return this.localStorage[key];
    }
  }

  public set(key: string, value: string, temporary?: boolean) {
    if (this.localStorage) {
      try {
        if (temporary) {
          this.sessionStorage.setItem(key, value);
        } else {
          this.localStorage.setItem(key, value);
        }
      } catch (err) {
        this.incognitoStorage[key] = value;
      }
    } else {
      window.localStorage.setItem(key, value);
    }
  }

  public clean(preserveTokens?: string[]) {
    let currentIndex = 0;

    while (this.localStorage.key(currentIndex) !== null) {
      const itemKey = this.localStorage.key(currentIndex) || '';
      if (!preserveTokens?.includes(itemKey)) localStorage.removeItem(itemKey);
      else {
        currentIndex++;
      }
    }
    sessionStorage.clear();
  }
}

export default new WarrenSession();
