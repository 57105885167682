
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IFilterOption } from './types';

@Component
export default class FilterOption extends Vue {
  @Prop({ type: Boolean, default: false })
  private active!: boolean;

  @Prop({ type: Object, required: true })
  private option!: IFilterOption;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  private handleOnClick() {
    this.$emit('on-click', this.option);
  }

  private get computedClass() {
    return {
      filter_option: true,
      'filter_option--active': this.active
    };
  }
}
