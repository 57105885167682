import { ENewTransferSteps } from './types';
import { NewTransferAmountSelection } from '../components/NewTransferAmountSelection';
import { NewTransferBankSelection } from '../components/NewTransferBankSelection';
import { NewTransferReview } from '../components/NewTransferReview';
import { NewTransferConclusion } from '../components/NewTransferConclusion';
import { NewTransferFeedback } from '../components/NewTransferFeedback';

export const NewTransferComponents = {
  [ENewTransferSteps.AMOUNT_SELECTION]: NewTransferAmountSelection,
  [ENewTransferSteps.BANK_SELECTION]: NewTransferBankSelection,
  [ENewTransferSteps.REVIEW]: NewTransferReview,
  [ENewTransferSteps.CONCLUSION]: NewTransferConclusion,
  [ENewTransferSteps.FEEDBACK]: NewTransferFeedback
};
