
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BaseCell, ChipStatus } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    BaseCell,
    ChipStatus
  }
})
export default class OrderStatusCell extends Vue {
  @Prop({ type: String, required: true })
  readonly status!: string;

  @Prop({ type: String, required: true })
  readonly label!: string;
}
