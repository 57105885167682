type ApiInputError = {
  message: {
    value: string;
    formatted: string;
  };
};

export default function parseApiErrorToScreenError(
  apiErrors: ApiInputError[]
): Record<string, string[]> {
  const screenErrors: Record<string, string[]> = {};

  apiErrors.forEach(error => {
    screenErrors[error.message.value] = [error.message.formatted];
  });

  return screenErrors;
}
