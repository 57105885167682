import {
  InstrumentSummary,
  SubTypeAllocations,
  Allocations
} from '@/modules/wallet-builder/src/types';

enum InstrumentClass {
  Fixedincome = 'FIXEDINCOME',
  Variableincome = 'VARIABLEINCOME',
  Others = 'OTHERS'
}

interface ProductAllocationEdit {
  allocation: SubTypeAllocations | InstrumentSummary;
  subType: string;
  allocationLock: Function;
}
interface Assets {
  name: string;
  total?: number;
  type: InstrumentClass;
  color: string;
}

export type { Assets, Allocations, SubTypeAllocations, ProductAllocationEdit };
