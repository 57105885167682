
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  NavBar,
  BaseText,
  Row,
  BaseModal,
  ScrollShadow
} from '@warrenbrasil/nebraska-web';
import { CloseButton } from '@/modules/common/components/buttons';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RiskScore } from '@/modules/common/components/RiskScore';

enum RiskProfileEnum {
  PASSIVE = 'PASSIVE',
  MODERATED = 'MODERATED',
  AGGRESSIVE = 'AGGRESSIVE'
}

@Component({
  components: {
    BaseModal,
    CloseButton,
    BackButton,
    NavBar,
    BaseText,
    ScrollShadow,
    RiskScore,
    Row
  }
})
export default class InvestorProfileModal extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public isOpen!: boolean;

  @Prop({
    type: String,
    required: false
  })
  public computedRiskToleranceString!: RiskProfileEnum;

  private NebraskaColors = NebraskaColors;

  private dataRiskProfile = {
    PASSIVE: { text: 'Conservador', valueText: '1.66', valueRiskLevel: 1.66 },
    MODERATED: { text: 'Moderado', valueText: '3.33', valueRiskLevel: 3.33 },
    AGGRESSIVE: { text: 'Arrojado', valueText: '5.00', valueRiskLevel: 5 }
  };

  private get riskProfile() {
    return this.dataRiskProfile[this.computedRiskToleranceString];
  }

  private closeInvestorProfileModal() {
    this.$emit('close-investor-profile');
  }
}
