import { DataTableProps } from '@warrenbrasil/nebraska-web';
import { MoneyCell } from '@/modules/common/components/MoneyCell';
import { OrdersAvatarCell } from '@/modules/wealth/views/WealthOrders/components/OrdersAvatarCell';
import { TagStatus } from '@/modules/wealth/views/WealthPortfolioV2/components/Orders/types';

export const columns: DataTableProps.Column[] = [
  {
    width: '6%',
    component: OrdersAvatarCell,
    getter: ({ icon }) => ({
      iconName: icon.iconName
    })
  },
  {
    label: 'Descrição',
    width: '31%',
    getter: ({ title, portfolioName, productName }) => ({
      text: title,
      description: portfolioName,
      subtitle: productName
    })
  },
  {
    component: MoneyCell,
    label: 'Valor / Solicitação',
    align: DataTableProps.Align.Right,
    getter: ({ createdAt, amount }) => ({
      text: amount.formatted,
      description: createdAt.formatted,
      enableToggleMoneyOnDescription: false
    })
  },
  {
    label: 'Conclusão',
    width: '20%',
    align: DataTableProps.Align.Center,
    getter: ({ expectedFinishAt }) => ({
      text: expectedFinishAt.formatted ?? '-'
    })
  },
  {
    label: 'Status',
    width: '20%',
    align: DataTableProps.Align.Right,
    getter: ({ status }) => ({
      tag: getTag(status)
    })
  }
];

function getTag(status: string) {
  const tagInfo = [
    TagStatus.REQUESTING,
    TagStatus.QUOTING,
    TagStatus.QUOTED,
    TagStatus.PROCESSING,
    TagStatus.SCHEDULED
  ];
  return {
    text: status,
    error: status === TagStatus.CANCELED,
    success: status === TagStatus.CONCLUDED,
    info: tagInfo.some(value => value === status)
  };
}
