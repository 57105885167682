import { GtmTracker } from '@/modules/common/services/analytics';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'costs',
  digital_experience: 'investor',
  experience_section: 'menu'
});

export function trackViewLoad() {
  trackEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackNavbarClose() {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'close'
  });
}

export function trackMoreInfosClick(taxTitle: string) {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'tax',
    event_name: 'know_more',
    event_target: taxTitle
  });
}

export function trackTaxKnowMore(title: string) {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'tax',
    event_name: 'know_more',
    event_target: title
  });
}

export function trackCashbackKnowMore(title: string) {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'tax',
    event_name: 'cashback',
    event_target: title
  });
}

export function trackYearSelection(year: number) {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'costs',
    event_name: 'select_year',
    event_target: year
  });
}

export function trackMonthSelection(month: number) {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'costs',
    event_name: 'select_month',
    event_target: month
  });
}

export function trackFAQClick() {
  trackEvent({
    event_type: 'click',
    event_name: 'view_doubts'
  });
}
