import { render, staticRenderFns } from "./SubscriptionList.vue?vue&type=template&id=5352bf38&scoped=true&"
import script from "./SubscriptionList.vue?vue&type=script&lang=ts&"
export * from "./SubscriptionList.vue?vue&type=script&lang=ts&"
import style0 from "./SubscriptionList.vue?vue&type=style&index=0&id=5352bf38&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5352bf38",
  null
  
)

export default component.exports