import { RouteConfig } from 'vue-router';
import { Home } from '@/modules/home/views/Home';
import { beforeEnterHome } from './navigation-guards/beforeEnterHome';

export const homeRoutes: RouteConfig[] = [
  {
    name: 'home',
    path: '/home',
    alias: ['/overview', '/cash'],
    beforeEnter: beforeEnterHome,
    component: Home,
    meta: {
      isHomePage: true,
      context: 'cash'
    }
  }
];
