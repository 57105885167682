
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { Row, BaseText } from '@warrenbrasil/nebraska-web';

@Component({
  components: { Row, BaseText }
})
export default class ProductCharacteristicsItem extends Vue {
  @Prop({ type: String, required: true })
  private title!: string;

  private get NebraskaColors() {
    return NebraskaColors;
  }
}
