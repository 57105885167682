import { render, staticRenderFns } from "./AvailableWithdrawal.vue?vue&type=template&id=474f5e22&scoped=true&"
import script from "./AvailableWithdrawal.vue?vue&type=script&lang=ts&"
export * from "./AvailableWithdrawal.vue?vue&type=script&lang=ts&"
import style0 from "./AvailableWithdrawal.vue?vue&type=style&index=0&id=474f5e22&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474f5e22",
  null
  
)

export default component.exports