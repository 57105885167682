import {
  createTrackerContext,
  withTrackerContext
} from '@warrenbrasil/web-analytics';
import { trackerContextProps } from './tracker-context';

const appTrackContext = createTrackerContext(trackerContextProps());
export const updateAppContextProps = () =>
  appTrackContext.setProps(trackerContextProps());
export const GtmTracker = withTrackerContext(appTrackContext);
