
import { Component, Ref, Vue } from 'vue-property-decorator';
import { throttle } from 'lodash-es';

import { isImpersonate } from '@/modules/common/services/partner';

@Component
export default class HeroContainer extends Vue {
  @Ref()
  private readonly navbarRef!: HTMLDivElement;

  @Ref()
  private readonly contentRef!: HTMLDivElement;

  private isNavbarTransparent = true;
  private removeOnScrollListener!: () => void;

  mounted() {
    const handleOnScroll = throttle(this.handleNavbarTransparency, 100);
    window.addEventListener('scroll', handleOnScroll);

    this.removeOnScrollListener = () =>
      window.removeEventListener('scroll', handleOnScroll);
  }

  destroyed() {
    this.removeOnScrollListener();
  }

  private handleNavbarTransparency() {
    const navbarHeight = this.navbarRef.getBoundingClientRect().height;
    const contentTop = this.contentRef.getBoundingClientRect().top;
    const isContentAtTop = contentTop >= navbarHeight;
    const shouldChangeNavbarColor = this.isNavbarTransparent !== isContentAtTop;

    if (shouldChangeNavbarColor) {
      this.isNavbarTransparent = isContentAtTop;
    }
  }

  get isImpersonateFlag() {
    return isImpersonate();
  }
}
