import { Component, Vue } from 'vue-property-decorator';

import {
  ETradeChartAxisY,
  TradeChartAxisX,
  TradeChartAxisY,
  TradeChartFilter
} from '@/modules/trade/types/';
import { ILegend } from '@/modules/common/components/charts/LegendChart';

interface LineChartSeries {
  name: string;
  data?: (number | null)[];
}

@Component
export default class LineChartV2Mixin extends Vue {
  get heightChart() {
    const height = window.innerHeight;
    if (height >= 1000) return '400px';
    else if (height >= 900) return '380px';
    else if (height >= 721) return '280px';
    else if (height > 650) return '190px';
    else return '150px';
  }

  public buildCategories(
    filters: TradeChartFilter[],
    selectedFilter: number
  ): ILegend[] {
    return filters.map((filter, index) => ({
      description: filter.description,
      key: filter.key,
      active: index === selectedFilter
    }));
  }

  public buildValues(
    axisY: TradeChartAxisY[],
    valueType: string
  ): LineChartSeries[] {
    const typeValues = axisY.filter(value => value.description === valueType);
    const data = typeValues[0]?.values.map(value =>
      value ? value.value : null
    );

    return [
      {
        name: typeValues[0].description,
        data
      }
    ];
  }

  public buildYields(axisY: TradeChartAxisY[]): LineChartSeries[] {
    const portfolio = axisY.filter(
      value =>
        value.description === 'portfolio' ||
        value.key === ('CLOSE' as ETradeChartAxisY) // THIS AS ETradeChartAxisY WILL BE REMOVED IN NEXT PR INTEGRATED WITH BFF
    );
    const data = portfolio[0].values.map(value => (value ? value.value : null));

    return [
      {
        name: portfolio[0].description,
        data
      }
    ];
  }

  public buildLabels(axisX: TradeChartAxisX[]): string[] {
    return axisX[0].values;
  }
}
