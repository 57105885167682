
import { Component, Vue, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  BaseModal,
  NavBar,
  BaseText,
  ButtonIconSecondary,
  Heading
} from '@warrenbrasil/nebraska-web';
import { Tooltip } from '@/modules/common/components/Tooltip';
import { ModalFooter } from '@/modules/common/components/__deprecated__/Modal';
import { Carousel, Slide } from 'vue-carousel';
import {
  getCustomerExperience,
  updateCustomerExperience
} from '@/modules/wealth/services/investment';
import {
  trackCloseModal,
  trackGoToNext,
  trackGoToPortfolio,
  trackTooltipHover
} from './track-events';

interface IWarrenEquals {
  imageAsset: string;
  titleTemplate: string;
  contentTemplate: string;
}

type ICarousel = Vue & {
  navigateTo: number;
};

const CustomerModule = namespace('CustomerModule');

@Component({
  components: {
    BaseModal,
    NavBar,
    ModalFooter,
    Carousel,
    Slide,
    BaseText,
    Tooltip,
    ButtonIconSecondary,
    Heading
  }
})
export default class WarrenEqualsModal extends Vue {
  @Ref()
  private carousel!: ICarousel;

  @CustomerModule.Getter('getCustomerId')
  private customerId!: string;

  private showModal = false;

  private portfolioId = '';

  private readonly experienceName: string =
    'br.com.warren.oi-odv.campaign-warren-equals';

  private currentSlide = 0;

  private NebraskaColors = NebraskaColors;

  async mounted() {
    await this.getCustomerExperience();
  }

  private async getCustomerExperience() {
    try {
      const { getExperiences } = await getCustomerExperience(
        this.customerId || ''
      );

      getExperiences.data.forEach(item => {
        if (item.experience.name === this.experienceName) {
          this.portfolioId = item.additionalInformation?.portfolioId || '';
          this.showModal = !item.isUsed;
        }
      });
    } catch {
      this.showModal = false;
    }
  }

  private async updateCustomerExperience() {
    try {
      await updateCustomerExperience({
        name: this.experienceName,
        customerId: this.customerId || ''
      });

      this.goToPortfolio();
    } catch (error) {
      this.showModal = false;
      console.error(error);
    }
  }

  public get warrenEqualsData(): IWarrenEquals[] {
    return [
      {
        imageAsset: 'banner-1.png',
        titleTemplate: 'Agora você também investe no fundo Warren Equals',
        contentTemplate:
          'Recentemente, as carteiras administradas pela gestão Warren foram atualizadas e agora contam com um investimento no fundo <a href="https://lp.warren.com.br/hubfs/Gestao/Laminas/Warren_Equals.pdf" target="_blank"><u><b>Warren Equals</b></u></a>, que rentabilizou 11,67% nos últimos 12 meses.'
      },
      {
        imageAsset: 'banner-2.png',
        titleTemplate: 'Diversificação e performance para sua carteira',
        contentTemplate:
          'O Warren Equals investe em empresas destacadas por suas políticas de equidade de gênero e com mulheres em cargos de liderança. Confira aqui os <a href="https://warren.com.br/magazine/warren-equals-performance-2022/" target="_blank"><u><b>resultados do fundo.</b></u></a>'
      },
      {
        imageAsset: 'banner-3.png',
        titleTemplate: 'O fundo de ações dedicado à equidade de gênero',
        contentTemplate:
          'A partir de agora, todos os novos aportes na sua carteira contam com um percentual investido no fundo de forma automática. Bora investir com propósito?'
      }
    ];
  }

  private closeModal() {
    this.showModal = false;
    trackCloseModal();
  }

  goToPortfolio() {
    this.$router.push({
      name: 'investment-transaction',
      params: { portfolio: this.portfolioId }
    });
    trackGoToPortfolio();
  }

  pageChange(current: number) {
    this.currentSlide = current;
  }

  nextStep() {
    if (this.currentSlide < 2) {
      this.carousel.navigateTo = this.currentSlide + 1;
      trackGoToNext(this.carousel.navigateTo + 1);
      return;
    }
    this.updateCustomerExperience();
  }

  handleTooltipHover() {
    trackTooltipHover();
  }
}
