
import { Vue, Component, Provide } from 'vue-property-decorator';
import NavigationPanel from '../../NavigationPanel.vue';
import { CreateElement } from 'vue';

@Component({
  components: { NavigationPanel }
})
export default class NavigationPanelWrapper extends Vue {
  private currentIndex = 0;

  public render(createElement: CreateElement) {
    const children = this.$slots!.default!.filter(
      vNode => vNode.componentOptions
      // eslint-disable-next-line array-callback-return
    ).map((vNode, index) => {
      if (vNode && vNode.componentOptions && vNode.data) {
        return createElement(
          vNode.componentOptions.Ctor,
          {
            scopedSlots: vNode.data.scopedSlots,
            props: {
              ...vNode.componentOptions.propsData,
              isOpen: index === this.currentIndex
            },
            on: {
              'panel-close': () => {
                this.currentIndex = index;
              },
              'panel-open': (selectedIndex: number) => {
                this.currentIndex = selectedIndex;
              }
            }
          },
          vNode.componentOptions.children
        );
      }
    });

    return createElement('section', children);
  }

  @Provide('setPanel')
  private setPanel(currentIndex: number) {
    this.currentIndex = currentIndex;
  }
}
