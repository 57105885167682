
import { Component, Vue } from 'vue-property-decorator';
import { HelperView } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  Document,
  DocumentType,
  Proposal
} from '@warrenbrasil/pension-types/dist/next';
import { DocumentModule } from '@/modules/pension/store/document';

@Component({
  name: 'StepSuccess',
  components: {
    HelperView
  }
})
export default class StepSuccess extends Vue {
  protected NebraskaColors = NebraskaColors;

  get suggestion() {
    return (DocumentModule.data.documentAccepted || {}) as Document;
  }

  get proposal(): Proposal {
    return (DocumentModule.data.documentAccepted?.entityData || {}) as Proposal;
  }

  get title() {
    if (this.isProposal) {
      return `Proposta de Previdência aceita!`;
    }

    if (this.isPortability) {
      return `Proposta de Portabilidade aceita!`;
    }

    return ``;
  }

  get description() {
    if (this.isProposal) {
      const { proposalNumber, portfolioName } = this.proposal;
      return `Agora, esta proposta nº ${proposalNumber} seguirá para implantação do plano. Você poderá acompanhar o progresso pela Carteira "${portfolioName}" que criaremos a qualquer momento.`;
    }

    if (this.isPortability) {
      return `Agora, esta portabilidade seguirá para a implantação e o valor será creditado automaticamente no seu plano em breve. Você pode acompanhar o progresso pela Carteira a qualquer momento.`;
    }

    return ``;
  }

  get isProposal() {
    return this.suggestion.type === DocumentType.Proposal;
  }

  get isPortability() {
    return this.suggestion.type === DocumentType.PortabilityTerm;
  }
}
