import { render, staticRenderFns } from "./BankData.vue?vue&type=template&id=34fc594a&scoped=true&"
import script from "./BankData.vue?vue&type=script&lang=ts&"
export * from "./BankData.vue?vue&type=script&lang=ts&"
import style0 from "./BankData.vue?vue&type=style&index=0&id=34fc594a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34fc594a",
  null
  
)

export default component.exports