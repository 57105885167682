
import { Vue, Component } from 'vue-property-decorator';
import {
  ActionableHelperView,
  SpinnerLoader
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    ActionableHelperView,
    SpinnerLoader
  }
})
export default class NavigationErrorView extends Vue {
  isRedirecting = false;

  private get previousRoute() {
    return this.$route.query.previousRoute as string;
  }

  private resetIsRedirectingFlag() {
    this.isRedirecting = false;
  }

  redirectToPreviousPage() {
    this.isRedirecting = true;
    this.$router.replace(
      { path: this.previousRoute },
      this.resetIsRedirectingFlag,
      this.resetIsRedirectingFlag
    );
  }
}
