import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { ILastRedeemInfoPayload } from './redeem-types';

function getLastRedeemFromLocalStorage(): ILastRedeemInfoPayload | undefined {
  const data = localStorage.getItem(StorageKeys.LastRedeemInformation);
  return data ? JSON.parse(data) : undefined;
}

@Module({
  namespaced: true,
  name: 'RedeemModule'
})
export class RedeemStoreModule extends VuexModule {
  lastRedeemInfo?: ILastRedeemInfoPayload = getLastRedeemFromLocalStorage();

  public get lastRedeemData(): ILastRedeemInfoPayload | undefined {
    return this.lastRedeemInfo;
  }

  @Mutation
  saveLastRedeemInformation(data: ILastRedeemInfoPayload) {
    localStorage.setItem(
      StorageKeys.LastRedeemInformation,
      JSON.stringify(data)
    );
    this.lastRedeemInfo = data;
  }

  @Mutation
  clearRedeemInformation() {
    localStorage.removeItem(StorageKeys.LastRedeemInformation);
    this.lastRedeemInfo = undefined;
  }
}
