
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';

import { HelperViewContextual } from '@warrenbrasil/nebraska-web';

import * as trackOrdersEvents from '@/modules/trade/views/Orders/trackers';

@Component({
  components: {
    HelperViewContextual
  }
})
export default class OrdersTableEmptyState extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly hasFilterSelected!: boolean;

  @Emit('on-clear-filters')
  emitOnClearFilters() {}

  created() {
    trackOrdersEvents.trackOrdersViewEmptyStateLoad();
  }

  get emptyStateHelperViewProps() {
    if (this.hasFilterSelected) {
      return {
        icon: 'BB0040',
        type: 'info',
        title: 'Nenhuma ordem encontrada',
        description: 'Tente alterar os filtros',
        buttonLabel: 'Limpar filtros'
      };
    }

    return {
      icon: 'BB0040',
      type: 'info',
      description: 'Você não possui ordens'
    };
  }
}
