
import { debounce } from 'lodash-es';
import { Component, Vue } from 'vue-property-decorator';

import {
  Row,
  HelperView,
  SkeletonLoader,
  ActionableHelperView,
  Input
} from '@warrenbrasil/nebraska-web';
import { HelpCenterNavBar } from './components/HelpCenterNavBar';
import { HelpCenterContentSection } from './components/HelpCenterContentSection';
import { HelpCenterIncidentAlert } from './components/HelpCenterIncidentAlert';
import { HelpCenterFooter } from './components/HelpCenterFooter';
import { isImpersonate } from '@/modules/common/services/partner';

import {
  searchArticles,
  getSections,
  IHelpCenterArticle,
  IHelpCenterSection
} from '@/modules/help-center/services/help-center';
import {
  getStatus,
  IIncidentStatus,
  EStatusTypes
} from '@/modules/help-center/services/incident-status';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

@Component({
  components: {
    Input,
    HelperView,
    SkeletonLoader,
    HelpCenterFooter,
    HelpCenterNavBar,
    HelpCenterContentSection,
    HelpCenterIncidentAlert,
    ActionableHelperView,
    Row
  }
})
export default class HelpCenterView extends Vue {
  isLoading = true;
  isFetchSectionsFailed = false;
  isFetchArticlesFailed = false;

  isImpersonate = false;
  sections: IHelpCenterSection[] = [];

  incidentData: IIncidentStatus = {} as IIncidentStatus;
  private incidentAlertCanBeRendered = true;

  isSearching = false;
  searchInput = '';
  searchResult: IHelpCenterArticle[] = [];

  onInputChange = debounce(this.searchArticles, 500);

  private mounted() {
    this.isImpersonate = isImpersonate();

    this.fetchSections();
    this.fetchIncidents();
  }

  get isSearchResultEmpty() {
    return this.searchResult.length === 0;
  }

  private fetchIncidents() {
    getStatus()
      .then(incidentResponse => (this.incidentData = incidentResponse))
      .catch(() => {});
  }

  async fetchArticles() {
    this.isLoading = true;
    this.isFetchArticlesFailed = false;

    try {
      const { articles } = await searchArticles(this.searchInput);
      this.searchResult = articles;
    } catch (error) {
      this.isFetchArticlesFailed = true;
      throw new Error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchSections() {
    this.isLoading = true;
    this.isFetchSectionsFailed = false;

    try {
      const { sections } = await getSections();
      this.sections = sections;
    } catch (error) {
      this.isFetchSectionsFailed = true;
      throw new Error(error);
    } finally {
      this.isLoading = false;
    }
  }

  private searchArticles() {
    this.isSearching = Boolean(this.searchInput);

    if (!this.isSearchInputEmpty) this.fetchArticles();
  }

  clearSearch() {
    this.searchResult = [];
    this.isSearching = false;
    this.searchInput = '';
  }

  get renderIncidentAlert() {
    return this.isStatusIncident && this.incidentAlertCanBeRendered;
  }

  private get isStatusIncident() {
    return this.incidentData.status === EStatusTypes.Incident;
  }

  get isSearchInputEmpty() {
    return this.searchInput === '';
  }

  get showHelpCenterFooter() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.CS_CHAT);
  }

  handleCloseIncidentAlert() {
    this.incidentAlertCanBeRendered = false;
  }

  openArticle(article: IHelpCenterArticle) {
    this.$router.push({
      name: 'help-center-article',
      params: { articleId: String(article.id) }
    });
  }
}
