import {
  getUserB2B,
  IUserB2B,
  StorageKeys
} from '@/modules/common/services/local-storage';
import { PartnerType, IPartnerInfo } from '@/types/models/Customer';

export enum Operation {
  AdvancedBuilder = 'advanced_builder',
  Deposit = 'deposits',
  DepositByProduct = 'deposit_by_product',
  Edit = 'edit',
  ShowTermsAndRisks = 'show_terms_and_risks',
  Transfer = 'transfer',
  Withdraw = 'withdrawals',
  WidthdrawPortfolio = 'withdraw_portfolio',
  WithdrawToExternalAccount = 'withdraw_to_any',
  EditWallet = 'edit_wallet'
}

const blockRules = {
  [Operation.AdvancedBuilder]: [PartnerType.Viewer, PartnerType.Planner],
  [Operation.Deposit]: [PartnerType.Viewer],
  [Operation.DepositByProduct]: [PartnerType.Viewer, PartnerType.Planner],
  [Operation.Edit]: [PartnerType.Viewer, PartnerType.Planner],
  [Operation.ShowTermsAndRisks]: [
    PartnerType.AAI,
    PartnerType.CFP,
    PartnerType.Planner,
    PartnerType.Viewer
  ],
  [Operation.Transfer]: [PartnerType.Viewer],
  [Operation.Withdraw]: [PartnerType.Viewer, PartnerType.Planner],
  [Operation.WidthdrawPortfolio]: [PartnerType.Viewer, PartnerType.Planner],
  [Operation.WithdrawToExternalAccount]: [
    PartnerType.Viewer,
    PartnerType.Planner
  ],
  [Operation.EditWallet]: [PartnerType.Viewer, PartnerType.Planner]
};

export function isImpersonate(): boolean {
  return (
    !!window.isImpersonateWindow &&
    !!localStorage.getItem(StorageKeys.ImpersonateToken) &&
    !!localStorage.getItem(StorageKeys.Partner)
  );
}

export function getPartner(): IUserB2B | null {
  return getUserB2B();
}

export function userCanPerform(operation: Operation): boolean {
  if (!Object.values(Operation).includes(operation)) return true;
  if (!isImpersonate()) return true;

  const partner = getPartner()!;
  const partnerType = partner.advisorProfile!.type! as unknown as PartnerType;

  return !blockRules[operation].includes(partnerType);
}

export function logoutImpersonate() {
  const partner = getPartner()!;
  const preserveTokens = [
    StorageKeys.AccessToken,
    StorageKeys.Partner,
    StorageKeys.Theme,
    StorageKeys.WhitelabelTheme,
    StorageKeys.LastPartnerDashRoute,
    StorageKeys.PartnerShowMoney,
    StorageKeys.PartnerDashNewsInformation,
    StorageKeys.PartnerProfileNewsInformation,
    StorageKeys.PartnerStatementNewsInformation,
    StorageKeys.PartnerPortfolioNewsInformation,
    StorageKeys.CustomerId,
    StorageKeys.Brand,
    StorageKeys.Features,
    StorageKeys.IsBrandOwner,
    StorageKeys.PartnerFeatures
  ].join();

  const url = `/app/#/signout?preserveTokens=${preserveTokens}&close=true`;

  localStorage.setItem(StorageKeys.CustomerId, partner._id);
  window.location.assign(url);
}

export function getBrandInitial(
  partnerInfo?: Pick<IPartnerInfo, 'tradeMark'> | null
) {
  return partnerInfo?.tradeMark ? partnerInfo.tradeMark[0].toUpperCase() : '';
}

export function isWarrenManaged() {
  const brandInfo = JSON.parse(localStorage.getItem(StorageKeys.Brand) || '{}');
  return !brandInfo?.isBrandManaged;
}
