
import { Component, Vue, Prop, Ref, Watch, Emit } from 'vue-property-decorator';
import { VMoney } from 'v-money';

import { SingleInput } from '@warrenbrasil/nebraska-web';
import { OrderDetailsContent } from '../OrderDetails/components/OrderDetailsContent';
import { OrderDetailsHeader } from '../OrderDetails/components/OrderDetailsHeader';

import { currencyToNumber } from '@/modules/common/helpers/currency';
import { OrderModalButtonSlots } from '@/modules/trade/components/OrderModal/enums';
import {
  OrderDetailsResponse,
  OrderContent,
  OrderContentFieldTypes
} from '@/modules/trade/services/orders';

@Component({
  components: {
    OrderDetailsContent,
    OrderDetailsHeader,
    SingleInput
  }
})
export default class PortabilityPriceChange extends Vue {
  @Prop({ type: Object, required: true })
  readonly order!: OrderDetailsResponse;

  @Prop({ type: String, required: true })
  readonly averagePrice!: string;

  @Ref('singleInput')
  readonly singleInput!: Vue;

  averagePriceHelperText = '';

  @Emit('change-button-status')
  emitChangeButtonsDisabledStatus(
    _slot: OrderModalButtonSlots,
    _disabled: boolean
  ) {}

  @Emit('change-average-price')
  emitChangeAveragePrice(_averagePrice: string) {}

  @Watch('averagePrice', { immediate: true })
  watchAveragePrice(averagePrice: string) {
    const averagePriceNumber = currencyToNumber(averagePrice);

    if (averagePriceNumber <= 0) {
      this.setAveragePriceHelperText('Insira um valor maior que R$ 0,00');
    } else if (averagePriceNumber > 1000000000) {
      this.setAveragePriceHelperText(
        'Insira um valor menor que R$ 1.000.000.000,00'
      );
    } else {
      this.setAveragePriceHelperText('');
    }
  }

  mounted() {
    const textarea = this.singleInput.$el.querySelector(
      'textarea'
    ) as HTMLElement;
    VMoney(textarea, { value: this.formatterOptions });
  }

  get instrumentType() {
    return this.order.instrument.type?.value;
  }

  get singleInputStatus() {
    return this.averagePriceHelperText ? 'error' : 'default';
  }

  get contents(): OrderContent[] {
    return [
      {
        fields: [
          {
            label: 'Novo preço médio',
            value: {
              value: currencyToNumber(this.averagePrice),
              formatted: `R$ ${this.averagePrice}`
            },
            type: OrderContentFieldTypes.text
          },
          ...this.order.content[0].fields
        ],
        caption:
          'Esta alteração impactará na visualização da posição deste ativo, além de remover o preço anterior sugerido pela Warren.'
      }
    ];
  }

  get formatterOptions() {
    return {
      decimal: ',',
      thousands: '.',
      prefix: '',
      precision: 2
    };
  }

  clearAveragePrice() {
    this.emitChangeAveragePrice('0');
  }

  handleChangeAveragePrice(event: Event) {
    const target = event.target as HTMLInputElement;
    this.emitChangeAveragePrice(target.value);
  }

  setAveragePriceHelperText(helperText: string) {
    this.averagePriceHelperText = helperText;
    this.emitChangeButtonsDisabledStatus(
      OrderModalButtonSlots.ButtonSecondary,
      !!helperText
    );
  }
}
