
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { BaseIcon, BaseText } from '@warrenbrasil/nebraska-web';

@Component({
  components: { BaseIcon, BaseText }
})
export default class DownloadButton extends Vue {
  @Prop({ type: String, required: true })
  readonly url!: string;

  @Prop({ type: String })
  readonly title!: string;

  public get NebraskaColors() {
    return NebraskaColors;
  }

  public get computedTitle() {
    return this.title ? this.title : this.url.split('/').pop();
  }

  @Emit('track-download-button')
  trackDownloadButton() {
    return this.computedTitle;
  }
}
