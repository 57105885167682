
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { BaseIcon } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    BaseIcon
  }
})
export default class RiskScore extends Vue {
  @Prop({ type: Number, required: true })
  public riskLevel!: number;

  private get risks() {
    if (this.isDecimalNumber) return this.riskLevel;
    else return parseInt(String(this.riskLevel), 10);
  }

  private get isDecimalNumber() {
    return Number.isInteger(this.riskLevel);
  }
}
