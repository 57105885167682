import { TooltipTypes } from './types';

export const tooltipsData = {
  [TooltipTypes.OperationFinancialVariation]: {
    title: 'Contribuição da operação',
    description:
      'Contribuição da operação personalizada sem considerar a valorização do ativo base e considerando que o vencimento fosse hoje.'
  },
  [TooltipTypes.InstrumentFinancialVariation]: {
    title: 'Variação da ação no período',
    description:
      'Valorização do ativo base desde o início, sem considerar a contribuição da operação personalizada.'
  },
  [TooltipTypes.TotalEarnings]: {
    title: 'Resultado total',
    description:
      'O resultado total não é líquido de impostos e não representa a saída antecipada da operação. O valor é uma simulação caso a operação encerrasse no momento de visualização.'
  }
};
