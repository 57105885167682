import { FeatureFlagService } from '@/modules/common/services/http/feature-flag';
import { FeatureFlags } from '@/types/models/FeatureFlags';

export class FeatureFlag<T extends string = string> {
  public async isEnabled(feature: T): Promise<boolean> {
    const features = await FeatureFlagService.getFeatures();
    return features.includes(feature) || this.isEnabledByABTest(feature);
  }

  public isLocalEnabled(feature: T): boolean {
    const featuresStorage = localStorage.getItem('warren.features');
    const features = featuresStorage ? JSON.parse(featuresStorage) : [];

    return features.includes(feature) || this.isEnabledByABTest(feature);
  }

  private isEnabledByABTest(feature: T): boolean {
    const featuresEnabledByABTest = window.warrenExperiments?.features || [];

    return featuresEnabledByABTest.includes(feature);
  }
}

export const featureFlag = new FeatureFlag<FeatureFlags>();
