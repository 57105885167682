
import { Component, Prop, Vue } from 'vue-property-decorator';

import { getString } from '@/modules/common/helpers/resources';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Notification } from '@/modules/common/components/Notification';
import {
  BaseIcon,
  ButtonPrimary,
  IconButton,
  Footer,
  NavBar
} from '@warrenbrasil/nebraska-web';
import scrollToElement from 'scroll-to-element';

@Component({
  components: {
    BaseIcon,
    ButtonPrimary,
    IconButton,
    Footer,
    NavBar,
    Notification
  }
})
export default class UserTemplate extends Vue {
  @Prop({ default: false, required: false }) private isDisabled!: boolean;
  @Prop({ default: true, required: false }) private isForm!: boolean;
  @Prop({ required: false, default: undefined }) private onBack?: Function;
  @Prop({ required: true, default: '' }) private title!: string;
  @Prop({ required: true, default: async () => undefined })
  private submit!: () => Promise<string | undefined>;

  @Prop({ default: 'Enviar', required: false }) private submitName!: string;

  public goBack() {
    if (!this.onBack) this.$router.push({ name: 'home' });
    else this.onBack();
  }

  public notificationError(
    error?: string | { description: string; message: string }
  ) {
    let text = 'Não foi possível prosseguir';
    if (typeof error === 'string') text = error;
    if (typeof error === 'object' && error.message) {
      text = getString(error.message) || error.description || error.message;
    }
    this.$notify({
      group: this.notificationGroup,
      text,
      type: NotificationTypes.error
    });
  }

  public notificationSuccess(text = 'Ação realizada com sucesso') {
    this.$notify({
      group: this.notificationGroup,
      text,
      type: NotificationTypes.success
    });
  }

  private NebraskaColors = NebraskaColors;
  private notificationGroup = 'user-template-notifications';
  private isProcessing = false;

  private buttonSubmit() {
    if (this.isForm) return null;
    return this.submit();
  }

  private async formSubmit() {
    if (!this.isForm) return null;
    if (this.isDisabled)
      return this.notificationError(
        'Verifique se o preenchimento dos campos está correto'
      );
    this.isProcessing = true;
    return this.submit()
      .then(successNotificationText =>
        this.notificationSuccess(successNotificationText)
      )
      .catch(error => this.notificationError(error))
      .finally(() => {
        this.isProcessing = false;
        scrollToElement(this.$el, { offset: 0, duration: 300 });
      });
  }
}
