import { render, staticRenderFns } from "./FeedbackModal.vue?vue&type=template&id=46fa7048&scoped=true&"
import script from "./FeedbackModal.vue?vue&type=script&lang=ts&setup=true&"
export * from "./FeedbackModal.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./FeedbackModal.vue?vue&type=style&index=0&id=46fa7048&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46fa7048",
  null
  
)

export default component.exports