
import { Component, Vue } from 'vue-property-decorator';
import {
  SkeletonLoader,
  Row,
  Heading,
  NavBar
} from '@warrenbrasil/nebraska-web';
import {
  AccordionWrapper,
  Accordion
} from '@/modules/common/components/Accordion';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { getFaq, IFaq } from '@/modules/help-center/services/faq';
import {
  isImpersonate,
  isWarrenManaged
} from '@/modules/common/services/partner';

interface IModule {
  type: string;
  name: string;
  questions: IFaq[];
}

@Component({
  components: {
    AccordionWrapper,
    SkeletonLoader,
    BackButton,
    Accordion,
    Heading,
    NavBar,
    Row
  }
})
export default class FAQView extends Vue {
  isLoading = true;
  isImpersonate = false;
  private modules: IModule[] = [
    {
      type: 'overview',
      name: 'Visão Geral',
      questions: []
    },
    {
      type: 'cash',
      name: 'Conta',
      questions: []
    },
    {
      type: 'portfolio_history',
      name: 'Carteiras',
      questions: []
    },
    {
      type: 'trade',
      name: 'Trade',
      questions: []
    }
  ];

  private async created() {
    this.isImpersonate = isImpersonate();
    const resp: any = await Promise.all(
      this.modules.map((module: IModule) => getFaq(module.type).catch(e => e))
    );
    this.modules.forEach((module: IModule, index: number) => {
      module.questions = this.filterQuestions(resp[index].questions || []);
    });
    this.isLoading = false;
  }

  onBackClick() {
    // @ts-ignore
    const backTo = this.$router.history.current.query.module || 'home';
    this.$router.push({ path: backTo });
  }

  private filterQuestions(questions: IFaq[]) {
    return isWarrenManaged()
      ? questions
      : questions.filter(
          faq => !faq.title.includes('Quanto custa investir com a Warren?')
        );
  }

  get validModules() {
    return this.modules.filter(module => module.questions.length > 0);
  }
}
