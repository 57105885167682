import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEventTransactions = GtmTracker.trackerWithDefaultProps({
  page_screen: 'all_movimentations',
  digital_experience: 'investor',
  experience_section: 'home'
});

export function trackTransactionsLoad() {
  trackAmplitudeEventTransactions({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackHideEquityClick() {
  trackAmplitudeEventTransactions({
    event_type: 'click',
    event_name: 'hide_equity'
  });
}

export function trackSeeAllFutureMovimentationsClick() {
  trackAmplitudeEventTransactions({
    event_type: 'click',
    event_name: 'see_all_future_movimentations',
    event_target: 'Ver todos'
  });
}

export function trackSelectDirectionClick(target: string) {
  trackAmplitudeEventTransactions({
    event_type: 'click',
    event_name: 'select_movimentation_direction',
    event_target: target
  });
}

export function trackSelectPeriodClick(target: string) {
  trackAmplitudeEventTransactions({
    event_type: 'click',
    event_name: 'select_movimentation_period',
    event_target: target
  });
}

export function trackSelectTypeClick(target: string) {
  trackAmplitudeEventTransactions({
    event_type: 'click',
    event_name: 'select_movimentation_type',
    event_target: target
  });
}

export function trackSelectMovimentationClick() {
  trackAmplitudeEventTransactions({
    event_type: 'click',
    event_name: 'select_movimentation'
  });
}

const trackAmplitudeEventFutureTransactionsError =
  GtmTracker.trackerWithDefaultProps({
    page_screen: 'load_future_movimentation_error',
    digital_experience: 'investor',
    experience_section: 'home'
  });

const trackAmplitudeEventTransactionsError = GtmTracker.trackerWithDefaultProps(
  {
    page_screen: 'load_movimentation_error',
    digital_experience: 'investor',
    experience_section: 'home'
  }
);

export function trackFutureTransactionsErrorLoad() {
  trackAmplitudeEventFutureTransactionsError({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackTransactionsErrorLoad() {
  trackAmplitudeEventTransactionsError({
    event_type: 'load',
    event_name: 'view'
  });
}
