
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
/**
 * @deprecated - use Nebraska NavBar instead
 */
export default class NavBar extends Vue {
  /** Color to be rendered as background */
  @Prop({ type: String, required: false })
  private background?: string;

  @Prop({ type: String, required: false })
  private contentZIndex?: string;

  @Prop({ type: Object, default: () => ({}) })
  private containerStyle!: any;

  private created() {
    console.warn(
      'Using deprecated NavBar, please use Nebraska NavBar instead.'
    );
  }

  private get computedSlotContainerStyle() {
    return {
      'z-index': this.contentZIndex
    };
  }

  private get computedClassName() {
    let backgroundClass = 'custom-nav-bar';
    if (this.background) {
      if (this.background === 'transparent') {
        backgroundClass = 'trasparent';
      } else {
        backgroundClass = `wrn-bg-${this.background}`;
      }
    }

    return ['wrn-navbar', backgroundClass];
  }
}
