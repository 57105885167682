
import { Component, Vue } from 'vue-property-decorator';
import { HelperView } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  Document,
  DocumentType,
  Proposal
} from '@warrenbrasil/pension-types/dist/next';
import { DocumentModule } from '@/modules/pension/store/document';

@Component({
  name: 'StepRejection',
  components: {
    HelperView
  }
})
export default class StepRejection extends Vue {
  protected NebraskaColors = NebraskaColors;

  get suggestion() {
    return (DocumentModule.data.documentRejected || {}) as Document;
  }

  get proposal(): Proposal {
    return (DocumentModule.data.documentRejected?.entityData || {}) as Proposal;
  }

  get title() {
    const text =
      (this.isProposal && 'Previdência') ||
      (this.isPortability && 'Portabilidade');
    return text ? `Proposta de ${text} recusada` : '';
  }

  get description() {
    if (this.isProposal) {
      const { proposalNumber } = this.proposal;
      return `Tudo bem, já cancelamos esta proposta nº ${proposalNumber}. Você pode solicitar um novo plano de previdência mais adequado a qualquer momento. Estaremos por aqui com você!`;
    }

    if (this.isPortability) {
      return `Tudo bem, já cancelamos esta proposta de portabilidade. Você pode solicitar uma nova movimentação como esta a qualquer momento. Estaremos por aqui com você!`;
    }

    return ``;
  }

  get isProposal() {
    return this.suggestion.type === DocumentType.Proposal;
  }

  get isPortability() {
    return this.suggestion.type === DocumentType.PortabilityTerm;
  }
}
