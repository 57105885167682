
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component
export default class ProgressiveImage extends Vue {
  @Prop({ type: String, default: '' })
  readonly image!: string;

  @Prop({ type: String, default: '' })
  readonly placeholder!: string;

  @Prop({ type: String, default: '' })
  readonly caption!: string;

  @Prop({ type: String, default: NebraskaColors.backgroundTertiary })
  readonly background!: string;

  public isLoaded = false;

  @Watch('placeholder')
  loadNewImage() {
    this.isLoaded = false;
  }

  @Emit()
  imageLoaded() {
    this.isLoaded = true;
  }

  get computedImageWrapperStyle() {
    return {
      background: this.background
    };
  }
}
