export enum PopupMode {
  FULL = 'drawer_right',
  POPOVER = 'popover'
}

export interface HiddenFields {
  [key: string]: string;
}

export interface PopupOptions {
  mode?: PopupMode;
  autoOpen?: boolean;
  hideHeaders?: boolean;
  hideFooter?: boolean;
}

export interface OptionsProps {
  hiddenFields?: HiddenFields;
  popupOptions?: PopupOptions;
  keepAlive?: boolean;
}

export interface FeatureSurvey {
  name: CSAT;
  answered?: boolean;
  formId?: string;
  props?: OptionsProps;
}

export enum CSAT {
  NEW_BUILDER = 'new-builder',
  NEW_BUILDER_V1 = 'web-v1'
}
export interface ICSATResponse {
  name: string;
  formId: string;
  title: string;
  subtitle: string;
  button: string;
  active: boolean;
  url: string;
}

export interface IgetCsatResponse {
  getCsat: ICSATResponse;
}
