
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Tag } from '@warrenbrasil/nebraska-web';

enum EnumRiskGrade {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

@Component({
  components: { Tag }
})
export default class CardProduct extends Vue {
  @Prop({
    type: String,
    validator(riskGrade: EnumRiskGrade) {
      return riskGrade === EnumRiskGrade[riskGrade];
    },
    required: false
  })
  public riskGrade!: EnumRiskGrade;

  private mapLevelRisk = {
    LOW: { success: true, text: 'Risco Baixo' },
    MEDIUM: { alert: true, text: 'Risco Médio' },
    HIGH: { error: true, text: 'Risco Alto' }
  };

  private get riskLevel() {
    return this.mapLevelRisk[this.riskGrade];
  }
}
