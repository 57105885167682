import { IFilterOption } from '@warrenbrasil/nebraska-web';

export enum FetchOrdersStatus {
  Default = 'default',
  Loading = 'loading',
  Error = 'error'
}

export enum OrdersFiltersKeys {
  Type = 'Type'
}

export interface IOrdersFilters {
  [OrdersFiltersKeys.Type]: {
    value: string;
    options: IFilterOption[];
  };
}
