import {
  getCustomerId,
  getBrand,
  getUserB2B
} from '@/modules/common/services/local-storage';

type CustomerProps = {
  brand_api_id: string;
  partner_api_id: string;
  customer_api_id: string;
};

export function customerProps(): Partial<CustomerProps> {
  /* eslint-disable camelcase */
  const brand_api_id = getBrand()?._id;
  const partner_api_id = getUserB2B()?._id;
  const customer_api_id = getCustomerId() ?? undefined;

  return { brand_api_id, partner_api_id, customer_api_id };
}
