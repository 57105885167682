
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RoundedIcon, NavBar } from '@warrenbrasil/nebraska-web';
import { DetailsContainer } from '@/modules/products/containers';

@Component({
  components: {
    DetailsContainer,
    RoundedIcon,
    NavBar
  }
})
export default class ProductsDetailsContainer extends Vue {
  @Prop({ type: String })
  private apiId?: string;

  private NebraskaColors = NebraskaColors;

  public goBack() {
    this.$router.push({ name: 'products' });
  }
}
