
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';

import { FilterSelect } from '@warrenbrasil/nebraska-web';

import { FilterValue, FilterFormatted } from '@/modules/trade/services/orders';

@Component({
  components: {
    FilterSelect
  }
})
export default class DateFilter extends Vue {
  @Prop({ type: Array, default: [] })
  readonly options!: FilterFormatted[];

  @Prop({ type: String, required: false })
  readonly value?: FilterValue | null;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Emit('on-select-filter')
  emitOnSelectFilter(_value?: FilterValue) {}

  get filterValue() {
    return this.value || '';
  }

  get formattedOptions() {
    return this.options.map(this.formatOption);
  }

  formatOption(option: FilterFormatted) {
    return {
      label: option.formatted,
      value: option.value
    };
  }
}
