import { ChartFilterValue } from './components/ChartFilters';
import { HomeChartDateFiltersKeys } from '@/modules/trade/services/account';

export function getAxisXLabelDateFormatByFilter(filter: ChartFilterValue) {
  switch (filter) {
    case HomeChartDateFiltersKeys.OneDay:
      return 'HH:mm';
    case HomeChartDateFiltersKeys.SevenDays:
    case HomeChartDateFiltersKeys.ThirtyDays:
      return 'DD/MMM';
    default:
      return 'MMM/YY';
  }
}
