
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ModalButton, Modal, InputPassword } from '@warrenbrasil/nebraska-web';

@Component({ components: { InputPassword, Modal, ModalButton } })
export default class PasswordDialog extends Vue {
  @Prop({ required: true, type: Function }) private cancel!: (
    msg: string
  ) => string;

  @Prop({ required: true, type: Function }) private close!: () => void;
  @Prop({ required: true, type: Function }) private confirm!: (
    password: string
  ) => string;

  private isButtonConfirmDisabled = true;
  private password = '';

  @Watch('password')
  private updateButton() {
    this.isButtonConfirmDisabled = this.password === '';
  }

  private handleConfirm() {
    this.close();
    return this.confirm(this.password);
  }

  private handleCancel() {
    this.close();
    return this.cancel('Nenhuma alteração foi salva');
  }
}
