
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Section } from '@/modules/trade/components/Section';
import { HighLights, Sections } from '@/modules/trade/services/search';
import { GenericCard } from '../GenericCard';
import { Row, Grid } from '@warrenbrasil/nebraska-web';
import {
  trackHighlightClick,
  trackSectionClick
} from '@/modules/trade/views/SearchView/trackers';
import { StringFormatted } from '@/modules/trade/types';

@Component({
  components: {
    Section,
    GenericCard,
    Row,
    Grid
  }
})
export default class SectionsList extends Vue {
  @Prop({ type: Object, required: false })
  private highlights!: HighLights;

  @Prop({ type: Object, required: false })
  private sectionTypes!: Sections;

  @Prop({ type: Object, required: true })
  readonly filter!: StringFormatted;

  private onTypeClick(id: string, title: string) {
    trackSectionClick(this.filter.formatted, title);
    this.$router.push({ name: 'tradeSectionDetail', params: { id } });
  }

  private onCardClick(id: string, title: string) {
    trackHighlightClick(this.filter.formatted, title);
    this.$router.push({ name: 'tradeHighLightDetail', params: { id } });
  }

  // wait until api returns value
  private getVariationColor(value: number) {
    return {
      'text-p-3': true,
      'search-types__text--positive': value > 0,
      'search-types__text--negative': value < 0
    };
  }
}
