
import { Component, Vue } from 'vue-property-decorator';
import { SkeletonLoader, Row } from '@warrenbrasil/nebraska-web';
import { NavBar } from '@/modules/common/components/__deprecated__/NavBar';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import {
  getSection,
  IHelpCenterArticle,
  IHelpCenterSection
} from '@/modules/help-center/services/help-center';
import { isImpersonate } from '@/modules/common/services/partner';

@Component({
  components: {
    SkeletonLoader,
    BackButton,
    NavBar,
    Row
  }
})
export default class HelpCenterSectionView extends Vue {
  isLoading = true;
  isImpersonate = false;
  section: IHelpCenterSection | null = null;

  private async mounted() {
    this.isImpersonate = isImpersonate();

    const { section } = await getSection(this.$route.params.id);
    this.section = section;
    this.isLoading = false;
  }

  openArticle(article: IHelpCenterArticle) {
    this.$router.push({
      name: 'help-center-article',
      params: { articleId: String(article.id) }
    });
  }
}
