import { httpClient } from '@/modules/common/services/http/http-client';
import { IWealthActionButtons } from './wealth-actions-types';
import { getCustomerId } from '@/modules/common/services/local-storage';

export const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getWealthActionButtons(): Promise<IWealthActionButtons> {
  return http.get(`/v1/customer/${getCustomerId()}/getWealthButtons`);
}
