export function isValidPhone(phoneNumber = '') {
  const phoneTest = phoneNumber.match(/(\d{11})/g);

  return !!(phoneTest && phoneTest.length);
}

export function formatPhone(phoneNumber = '') {
  if (!phoneNumber) return phoneNumber;

  const template = '($1) $2-$3';
  const firstHalfLength = phoneNumber.length > 10 ? 5 : 4;
  const regex = `^([0-9*#+]{2})([0-9*#+]{${firstHalfLength}})([0-9*#+]{4})$`;
  const formattedPhone = phoneNumber.replace(new RegExp(regex, 'i'), template);

  return formattedPhone;
}
