import { GtmTracker } from '@/modules/common/services/analytics';
import { Categories } from '@/types/models/Analytics';
import { WarrenModules } from '@/types/navigation';

function getCategory(module: WarrenModules) {
  switch (module) {
    case WarrenModules.Home:
      return Categories.Home;
    case WarrenModules.Overview:
      return Categories.Overview;
    case WarrenModules.Cash:
      return Categories.Cash;
    case WarrenModules.Wealth:
      return Categories.Wealth;
    case WarrenModules.Trade:
      return Categories.Trade;
    default:
      return Categories.Overview;
  }
}

export const trackTransactionsEvent = GtmTracker.trackerWithDefaultProps({
  label: 'Últimas Movimentações'
});

export function trackTransactionsPageNavigation(module: WarrenModules) {
  trackTransactionsEvent({
    category: getCategory(module),
    action: 'Clique em ver mais'
  });
}
