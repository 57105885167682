export interface WootricSettings {
  email: string;
  external_id: string;
  created_at: number;
  account_token: string;
  properties: {
    apiId: string;
    name: string;
    profession: string;
    age: number;
    birthDate: string;
    ageRange: string;
    sex: string;
    state: string;
    totalBalance: number;
    totalBalanceRange: string;
    patrimony: number;
    patrimonyRange: string;
    computedProfileType: string;
  };
}

export enum WootricActions {
  Stop = 'stop',
  Run = 'run',
  Version = 'version'
}
