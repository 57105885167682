import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';

import { PortfolioSectionType } from '@/modules/wealth/services/portfolio';
import { AllocationCell } from './components/AllocationCell';

export function formattedColumns(data: PortfolioSectionType) {
  const formattedData: unknown[] = [];

  const defaultColumn = {
    label: data.title,
    component: InstrumentNameCell,
    width: '30%',
    getter: (item: { symbol: string; name: string }) => ({
      symbol: item.symbol,
      name: item.name || '-',
      size: 'small',
      hasEllipsis: true
    })
  };

  formattedData.push(defaultColumn);

  data.rows[0].items.forEach((row, index) => {
    const column = {
      component: AllocationCell,
      width: `${70 / data.rows[0].items.length}%`,
      align: 'right',
      label: row.title,
      getter: `value-${index}`
    };

    formattedData.push(column);
  });

  return formattedData;
}

export function formattedDataTable(data: PortfolioSectionType) {
  const formattedData: unknown[] = [];

  data.rows.forEach(row => {
    let data = {
      symbol: row.symbol,
      name: row.name
    };

    row.items.forEach((item, index) => {
      data = {
        ...data,
        [`value-${index}`]: item
      };
    });

    formattedData.push(data);
  });

  return formattedData;
}
