
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { BaseIcon, BaseText } from '@warrenbrasil/nebraska-web';

@Component({
  components: { BaseIcon, BaseText }
})
export default class DownloadButton extends Vue {
  @Prop({ type: String, required: true })
  public url!: string;

  @Prop({ type: String })
  public title!: string;

  private get NebraskaColors() {
    return NebraskaColors;
  }

  private get computedTitle() {
    return this.title ? this.title : this.url.split('/').pop();
  }
}
