
import { Component, Vue, Emit } from 'vue-property-decorator';
import { Notification } from '@/modules/common/components/Notification';
import { deleteAccount } from '@/modules/common/services/http/account';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { ModalButton, Modal } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Modal,
    ModalButton,
    Notification
  }
})
export default class DeleteAccount extends Vue {
  @Emit()
  private close() {}

  private async deleteConfirm() {
    deleteAccount().then(
      () => {
        this.$notify({
          group: 'wrn-notification-delete-account',
          text: 'Conta deletada com sucesso!',
          type: NotificationTypes.success
        });
        setTimeout(() => {
          this.$router.push({ name: 'signin' });
        }, 1000);
      },
      err => {
        this.$notify({
          group: 'wrn-notification-delete-account',
          text: err.message,
          type: NotificationTypes.error
        });
      }
    );
  }
}
