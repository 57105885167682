import { EFundsSteps } from './types';

import { Profile } from '@/modules/catalog/views/FundsAcquisition/components/Profile';
import { Risk } from '@/modules/catalog/views/FundsAcquisition/components/Risk';
import { Template } from '@/modules/catalog/views/FundsAcquisition/components/Template';
import { Conclusion } from '@/modules/catalog/views/FundsAcquisition/components/Conclusion';

export const FundsBuilderComponents = {
  [EFundsSteps.PROFILE]: Profile,
  [EFundsSteps.RISK]: Risk,
  [EFundsSteps.TEMPLATE]: Template,
  [EFundsSteps.CONCLUSION]: Conclusion
};
