import { httpClient } from '@/modules/common/services/http/http-client';
import { IPixInfo } from './types';

export const bffInvestments = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BANKING_API_URL
});

export function getPixInfo(): Promise<IPixInfo> {
  return bffInvestments.get(`/v1/cash/pixInfo`);
}
