import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'signin_verify_two_steps',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackMultiFactorLoginLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackFillToken() {
  trackAmplitudeEvent({
    event_type: 'blur',
    event_name: 'fill_token',
    page_screen_section: 'body'
  });
}

export function trackSendBySMSClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'receive_token',
    page_screen_section: 'body',
    event_target: 'Receber por SMS'
  });
}

export function trackSendByPushClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'resend',
    page_screen_section: 'body',
    event_target: 'Receber por notificação'
  });
}

export function trackWithoutPhoneClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'continue_without_phone',
    page_screen_section: 'body',
    event_target: 'Estou sem celular'
  });
}
