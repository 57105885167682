
import { Component, Vue, Prop } from 'vue-property-decorator';

import {
  InitialAvatarIcon,
  InitialAvatarChar
} from '@warrenbrasil/nebraska-web';

import { InstrumentType } from '@/modules/trade/types';

@Component({
  components: {
    InitialAvatarChar,
    InitialAvatarIcon
  }
})
export default class InstrumentAvatarFallback extends Vue {
  @Prop({ type: String, required: false })
  readonly instrumentType?: InstrumentType;

  @Prop({ type: String, required: true })
  readonly instrumentSymbol!: string;

  @Prop({ type: String, default: 'medium' })
  readonly size!: string;

  readonly InstrumentType = InstrumentType;

  get symbolInitial() {
    return this.instrumentSymbol[0];
  }
}
