
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';

import { RoundedIcon, BaseText, Heading } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { NewsAPI } from '@/modules/home/services/news';
import { GtmTracker } from '@/modules/common/services/analytics';

const newsApi = new NewsAPI();

@Component({
  components: {
    RoundedIcon,
    BaseText,
    Heading
  }
})
export default class CardNewsContent extends Vue {
  private NebraskaColors = NebraskaColors;

  @Prop({ type: Object, required: true })
  private data!: any;

  public get computedBackground() {
    if (this.data.dashboardBackground) {
      return {
        backgroundImage: `url('${this.data.dashboardBackground}')`
      };
    } else {
      // Temporary default image
      return {
        backgroundImage: `url('https://images.unsplash.com/photo-1495020689067-958852a7765e?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8cmVhZGluZyUyMG5ld3N8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&w=1000&q=80')`
      };
    }
  }

  public get hasNewsUrl() {
    if (this.data.url)
      return {
        cursor: 'pointer'
      };

    return null;
  }

  @Emit('close')
  public dismissNews(code: string) {
    this.trackDismissNewsEvent(code);
    newsApi.trackNewsDiscard(code);
  }

  @Emit('open')
  public openNews(code: string, url: string) {
    if (!url) return;

    this.trackOpenNewsEvent(code);
    window.open(url);
    newsApi.trackNewsRead(code);
  }

  private get tracker() {
    return GtmTracker.trackerWithDefaultProps({
      category: 'Warren News'
    });
  }

  private trackOpenNewsEvent(code: string) {
    this.tracker({
      action: 'Open - News',
      label: code
    });
  }

  private trackDismissNewsEvent(code: string) {
    this.tracker({
      action: 'Dismiss - News',
      label: code
    });
  }

  @Emit()
  private close() {}
}
