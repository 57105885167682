import { GtmTracker } from '@/modules/common/services/analytics';

const trackSignInEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'signin',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackPageView() {
  trackSignInEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackForgotPasswordEvent() {
  trackSignInEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'forget_password'
  });
}

export function trackEmailEvent() {
  trackSignInEvent({
    event_type: 'blur',
    page_screen_section: 'form',
    event_name: 'fill_email'
  });
}

export function trackSendEmailClick() {
  trackSignInEvent({
    event_type: 'click',
    event_name: 'send_email'
  });
}

export function trackPasswordEvent() {
  trackSignInEvent({
    event_type: 'blur',
    page_screen_section: 'form',
    event_name: 'fill_password'
  });
}

export function trackSendPasswordClick() {
  trackSignInEvent({
    event_type: 'click',
    event_name: 'send_password'
  });
}

export function trackSignupLink() {
  trackSignInEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'signup'
  });
}

export function trackLogin() {
  GtmTracker.trackEvent({
    category: 'account',
    action: 'Login',
    label: window.location.href
  });
}
