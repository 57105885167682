import { httpClient } from '@/modules/common/services/http/http-client';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;
export const httpClientBffTrade = httpClient.withOptions({ baseURL });

export function postHomeBrokerToken(customerId: string) {
  return httpClient.post<{ token: string }>(
    `api/v1/trade/customers/${customerId}/generate-hb-token`
  );
}

export function requestHomeBrokerAccess(): Promise<void> {
  return httpClientBffTrade.get('/requestHomeBrokerAccess');
}

export function getHomeBrokerUrl(): Promise<string> {
  return httpClientBffTrade.get('/getHomeBrokerUrl');
}
