import { render, staticRenderFns } from "./PositionDetail.vue?vue&type=template&id=13ad025b&scoped=true&"
import script from "./PositionDetail.vue?vue&type=script&lang=ts&"
export * from "./PositionDetail.vue?vue&type=script&lang=ts&"
import style0 from "./PositionDetail.vue?vue&type=style&index=0&id=13ad025b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ad025b",
  null
  
)

export default component.exports