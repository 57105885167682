
import {
  Component,
  Vue,
  Prop,
  InjectReactive,
  Emit
} from 'vue-property-decorator';
import { PropType } from 'vue';

import { HeadingDisplay } from '@warrenbrasil/nebraska-web';
import { Money } from '@/modules/common/components/Money';

import { HomeBalanceSummaryLoader } from './components/HomeBalanceSummaryLoader';
import { SectionError } from '@/modules/common/components/SectionError';
import { BalancesResponse } from '../../services/bff';
import { HomeCash } from '@/modules/home/components/HomeCash';

@Component({
  components: {
    HeadingDisplay,
    Money,
    HomeBalanceSummaryLoader,
    SectionError,
    HomeCash
  }
})
export default class HomeBalanceSummary extends Vue {
  @Prop({ type: Object as PropType<BalancesResponse> })
  readonly balanceDetails?: BalancesResponse;

  @Prop({ type: Boolean, required: true })
  readonly loading!: boolean;

  @Prop({ type: Boolean, required: true })
  readonly hasError!: boolean;

  @InjectReactive('shouldTriggerHomeAnimations')
  readonly addLoadedClass!: boolean;

  @Prop({ type: Boolean, required: true })
  readonly isOverdue!: boolean;

  retry = false;

  hasHomeCashError = false;

  @Emit('on-retry')
  public onRetry() {
    this.retry = !this.retry;
    this.hasHomeCashError = false;
  }

  get total() {
    return this.balanceDetails?.totalBalance.value;
  }

  get totalUpdatedAt() {
    return this.balanceDetails?.balancesLastUpdatedAtFormatted ?? '';
  }

  handleHomeCashError(isError: boolean) {
    this.hasHomeCashError = isError;
  }
}
