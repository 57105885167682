
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Filters from './Filters.vue';
import { SkeletonLoader } from '@warrenbrasil/nebraska-web';
import {
  IFilterCategory,
  IFilterCategories,
  EFilterCategoriesTypes,
  IFilterType,
  IFilterSubcategory
} from '../../services/types/models/IFilters';
import { getInstrumentsFilters } from '@/modules/wallet-builder/src/services/bff-investments';
import {
  trackApplyFilters,
  trackQuickFilters,
  trackClearFilters,
  trackLoadModal,
  trackSelectedFilter
} from './track-events';

const FilterModule = namespace('FilterModule');

@Component({
  components: {
    Filters,
    SkeletonLoader
  }
})
export default class FiltersContainer extends Vue {
  @Prop({ type: Number, required: true })
  public currentTab!: number;

  @FilterModule.Action('addFilterType')
  private addFilterType!: Function;

  @FilterModule.Getter('filters')
  private filters!: IFilterCategories;

  private errors: Error | null = null;

  public formattedFilters: Array<IFilterCategory> = [];

  public numberAppliedFilters = 0;

  public isLoadingFilters = true;

  async mounted() {
    try {
      const [fixedFilters, variableFilters, otherFilters] = await Promise.all([
        getInstrumentsFilters({
          category: EFilterCategoriesTypes[EFilterCategoriesTypes.FIXEDINCOME]
        }),
        getInstrumentsFilters({
          category:
            EFilterCategoriesTypes[EFilterCategoriesTypes.VARIABLEINCOME]
        }),
        getInstrumentsFilters({
          category: EFilterCategoriesTypes[EFilterCategoriesTypes.OTHER]
        })
      ]);

      this.setQuickFiltersVariable(fixedFilters);
      this.setQuickFiltersVariable(variableFilters);
      this.setQuickFiltersVariable(otherFilters);

      this.addFilterType({
        filterType: fixedFilters,
        categoryType: EFilterCategoriesTypes[EFilterCategoriesTypes.FIXEDINCOME]
      });
      this.addFilterType({
        filterType: variableFilters,
        categoryType:
          EFilterCategoriesTypes[EFilterCategoriesTypes.VARIABLEINCOME]
      });
      this.addFilterType({
        filterType: otherFilters,
        categoryType: EFilterCategoriesTypes[EFilterCategoriesTypes.OTHER]
      });

      this.updateFormattedFilters();
      this.isLoadingFilters = false;
    } catch (e) {
      this.errors = e as Error;
    }
  }

  public applyFilters() {
    for (const category of this.formattedFilters) {
      if (category.filters && category.modalSelectedOption) {
        const length = category.modalSelectedOption?.length;
        category.applied = length > 0;
        category.selectedOption = category.modalSelectedOption;
        if (category.isQuickFilter) {
          category.quickSelectedOption =
            category.modalSelectedOption.toString();
        }
      } else if (category.subCategories) {
        for (const subCategory of category.subCategories) {
          if (
            subCategory.modalSelectedOption &&
            subCategory.modalSelectedOption?.length > 0
          )
            subCategory.applied = true;
          subCategory.selectedOption = subCategory.modalSelectedOption;
        }
      }
    }

    const filterCategory: IFilterType = {
      categories: this.formattedFilters
    };

    this.addFilterType({
      filterType: filterCategory,
      categoryType: this.tab
    });

    this.updateFormattedFilters();
    this.countAppliedFilters();
    this.trackAppliedFilters();
    trackApplyFilters(this.tab);

    this.$emit('fetch-products');
  }

  public applyQuickFilters(filter: IFilterCategory) {
    if (filter.quickSelectedOption) {
      filter.selectedOption = [filter.quickSelectedOption];
      filter.modalSelectedOption = [filter.quickSelectedOption];
    }

    this.formattedFilters[0] = filter;

    this.addFilterType({
      filterType: { categories: this.formattedFilters },
      categoryType: this.tab
    });

    const filterValue =
      filter.filters?.find(e => e.value === (filter.selectedOption || ''))
        ?.formatted || '';

    this.updateFormattedFilters();
    this.countAppliedFilters();
    this.trackAppliedFilters();
    trackQuickFilters(this.tab, filter.categoryName, filterValue);
    trackApplyFilters(this.tab);

    this.$emit('fetch-products');
  }

  public clearFilters() {
    const filterCategory: IFilterType = {
      categories: this.formattedFilters
    };

    this.addFilterType({
      filterType: filterCategory,
      categoryType: this.tab
    });

    this.updateFormattedFilters();
    this.countAppliedFilters();
    this.trackAppliedFilters();
    trackClearFilters(this.tab);

    this.$emit('fetch-products');
  }

  public countAppliedFilters() {
    let appliedFilters = 0;

    for (const filterOption of this.formattedFilters) {
      if (filterOption.subCategories) {
        for (const subcategoriesOption of filterOption.subCategories) {
          if (subcategoriesOption.applied) appliedFilters += 1;
        }
      } else {
        if (
          filterOption.applied &&
          filterOption.component === 'checkbox' &&
          filterOption.selectedOption
        ) {
          appliedFilters += filterOption.selectedOption?.length;
        } else if (filterOption.applied) appliedFilters += 1;
      }
    }

    this.numberAppliedFilters = appliedFilters;
  }

  private trackAppliedFilters() {
    for (const filterOption of this.formattedFilters) {
      if (filterOption.subCategories) {
        for (const subcategoriesOption of filterOption.subCategories) {
          if (subcategoriesOption.applied) {
            this.trackSelectedFilter(
              subcategoriesOption,
              subcategoriesOption.selectedOption || ''
            );
          }
        }
      } else {
        if (
          filterOption.applied &&
          filterOption.component === 'checkbox' &&
          filterOption.selectedOption
        ) {
          for (const selectedOption of filterOption.selectedOption) {
            this.trackSelectedFilter(filterOption, selectedOption);
          }
        } else if (filterOption.applied) {
          this.trackSelectedFilter(
            filterOption,
            filterOption.selectedOption || ''
          );
        }
      }
    }
  }

  private trackSelectedFilter(
    filterOption: IFilterCategory | IFilterSubcategory,
    value: string | string[]
  ) {
    const filterName = filterOption.filterName || '';
    const filterValue = filterOption.filters?.find(
      e => e.value === value
    )?.formatted;
    if (filterValue) {
      trackSelectedFilter(this.tab, filterName, filterValue);
    }
  }

  openedFilterModal() {
    trackLoadModal(this.tab);
  }

  setQuickFiltersVariable(filters: IFilterType) {
    for (const filter of filters.categories) {
      if (filter.isQuickFilter) {
        filter.quickSelectedOption = '';
      }
    }
  }

  public updateFormattedFilters() {
    this.formattedFilters = (this.filters as any)[this.tab].categories;
    this.$forceUpdate();
  }

  get tab() {
    return EFilterCategoriesTypes[this.currentTab];
  }

  @Watch('currentTab')
  updateCurrentTab() {
    this.updateFormattedFilters();
    this.countAppliedFilters();
    this.trackAppliedFilters();
  }
}
