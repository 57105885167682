import { IWalletInstrument } from '@warrenbrasil/wallet-builder-sdk/lib/types';

export enum GoalType {
  FREE = 'free',
  TARGET = 'target',
  EMERGENCY = 'emergency',
  MONTHLY_INCOME = 'monthly_income',
  INVESTMENT_ACCOUNT = 'investment_account',
  PRIVATE_PENSION = 'private_pension',
  EMERGENCY_FUND = 'emergency_fund'
}

export enum EIntrumentQuotaSubtype {
  PENSION = 120
}

export enum RiskLevel {
  VERY_LOW = 'veryLow',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  VERY_HIGH = 'veryHigh'
}

export interface ICosts {
  name: string;
  value: number;
}

export enum PortfolioTransactionStatus {
  Pending = 'pending',
  Processing = 'processing'
}

export enum PortfolioCategories {
  VariableIncome = 'VariableIncome',
  WarrenManagement = 'WarrenManagement',
  Custom = 'Custom'
}

export interface IPortfolioList {
  id: string;
  image: string;
  name: string;
  showManagementIcon: boolean;
  status?: string;
  type: PortfolioCategories;
  value: { value: number; formatted: string };
}

export interface IPortfolio {
  _id: string;
  name: string;
  customer: string;
  status: string;

  wantsLiquidity: boolean;
  avoidVariableIncome: boolean;
  isVirtual: boolean;
  isCustom: boolean;
  isOnTrack: boolean;
  canBeDeleted: boolean;
  canCreateNewTransaction: boolean;
  canInvestInPortfolio: boolean;

  isShared: boolean;
  isEdited?: boolean;
  firstEditedAt?: Date;
  lastEditedAt?: Date;
  editedCount?: number;
  createdAt?: Date;
  updatedAt?: Date;

  group?: any;

  isCustomerAwareOfOwnRisk: boolean;
  riskLevel?: RiskLevel;

  investReminder: boolean;
  skipInvestReminder: boolean;
  investReminderDay: number;

  background: IPortfolioBackground;

  depositStatus: string;
  invoiceStatus: string;
  withdrawStatus: PortfolioTransactionStatus;
  transferStatus: PortfolioTransactionStatus;
  debitTransferStatus: PortfolioTransactionStatus;
  creditTransferStatus: PortfolioTransactionStatus;
  elliotTransferStatus: string;

  goal: GoalType;
  goalAmount?: number;
  goalMonthlyIncome?: number;
  goalDate: Date;
  goalPercentage: number;
  initialInvestment: number;
  monthlyInvestment: number;
  lastUpdatedAt: Date;
  totalBalance: number;
  pendingInAmount: number;
  pendingOutAmount: number;
  lockedBalance: number;
  availableBalance: number;
  netBalance: number;
  incomeTax: number;
  transactionTax: number;
  totalEarnings: number;
  totalEarningsPercentage: number;
  performancePercentage: number;
  currentEarnings: number;
  futureBalance: number;
  portfolioTransactionsSummary: ICorePortfolioTransactionsSummary;
  financialOperationTax?: number;
  grossEarnings?: number;
  netEarnings: number;
  allocations?: IAllocations;

  rebalance?: { status: string };

  grossWorth?: number;
  totalGrossWorth?: number;
  totalGrossEarnings?: number;
  totalNetEarnings?: number;
  totalNetWorth?: number;
  accountAvailableBalance?: number;
  costs: ICosts[];

  privatePension?: {
    proposalNumber: string;
    contractNumber: string;
    status: string;
    lastStatusUpdateAt: Date;
  };

  managedPortfolio?: string;
  portfolioCategory: PortfolioCategories;
  showManagementIcon?: boolean;
}

export type IInvestedPortfolio = Pick<IPortfolioList, 'id'>;

export interface IPortfolioBackground {
  raw: string;
  regular: string;
  full: string;
  small: string;
  thumb: string;
}

export interface ITransactionSummary {
  totalPending: number;
  totalProcessing: number;
  totalAccepted: number;
  hasPending: boolean;
  hasProcessing: boolean;
  hasAccepted: boolean;
}
export interface ICorePortfolioTransactionsSummary {
  transfers: ITransactionSummary;
  deposits: ITransactionSummary;
  withdrawals: ITransactionSummary;
  invoices: ITransactionSummary;
  totalOpenTransactions: number;
}

/**
 * ALLOCATIONS
 */

export type IAllocations = IBoxType[];

export enum InvestorType {
  NONE = 'NONE',
  COMMON = 'COMMON',
  QUALIFIED = 'QUALIFIED',
  PROFESSIONAL = 'PROFESSIONAL'
}

export interface IWalletCustomInstrument extends IWalletInstrument {
  productBoxId: string;
  allocValue: number;
}

export interface IInstrument {
  apiId: string;
  name?: string;
  type: AllocationType;
  allocValue: number;
  isAvailable: boolean;
  isMandatory: boolean;
  minPercentage: number;
  availabilityReason: string;
  indexBasedInterest: boolean;
  minimumInitialInvestment: number;
  minimumFinancialMovement: number;
  interestRate: number;
  indexName?: string;
  riskRate?: number;
  expiresIn?: Date;
  symbol: string;
  description?: string;
  emitterName?: string;
  liquidityInfo?: string;
  performance?: number;
  daysToLiquidation?: number;
  daysToExpiration?: number;
  daysToGracePeriod?: number;
  isLocked?: boolean;
  productBoxId: string;
  boxName?: string;
  actionStatus?: string;
  assetName?: string;
  investorType?: InvestorType;
  riskScore?: number;
  subtype?: number;
}

export interface IProductBox {
  apiId: string;
  name: string;
  type: AllocationType;
  description?: string;
  tooltip?: string;
  interestRate?: number;
  minInterestRate?: number;
  maxInterestRate?: number;
  avgRiskRate?: number;
  isAvailable: boolean;
  isMandatory: boolean;
  availabilityReason?: string;
  minPercentage: number;
  instruments: IInstrument[];
}

export interface IInstrumentBox {
  allocValue: number;
  productBox: IProductBox;
}

export interface IBoxType {
  type: AllocationType;
  allocValue: number;
  allocations: IInstrumentBox[];
  isLocked?: boolean;
  isAvailable?: boolean;
}

export enum AllocationType {
  FIXED_INCOME = 'Renda Fixa',
  VARIABLE_INCOME = 'Renda Variável',
  STOCKS = 'Ações',
  OTHERS = 'Outros',
  MULTIMARKET = 'Multimercado'
}
