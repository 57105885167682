import { RouteConfig } from 'vue-router';

import { cashRoutes } from '@/modules/cash/routes';
import { homeRoutes } from '@/modules/home/routes';
import { wealthRoutes } from '@/modules/wealth/routes';
import { tradeRoutes } from '@/modules/trade/routes';
import { pensionRoutes } from '@/modules/pension/routes';
import { accountRoutes } from '@/modules/account/routes';
import { onboardingRoutes } from '@/modules/onboarding/routes';
import { transactionsRoutes } from '@/modules/transaction/routes';
import { helpCenterRoutes } from '@/modules/help-center/routes';
import { productsRoutes } from '@/modules/products/routes';
import { authenticationRoutes } from '@/modules/authentication/routes';
import { investmentsRoutes } from '@/modules/investments/routes';
import { commonRoutes } from '@/modules/common/routes';
import { catalogRoutes } from '@/modules/catalog/routes';
import { flatten } from 'lodash-es';

const joinRoutes = (...routes: RouteConfig[][]) => flatten(routes);

const entryPointRoutes: RouteConfig[] = [
  {
    // Keeps compatibility with deprecated v3 route naming
    name: 'v3',
    path: '/v3*',
    redirect: to => {
      return { path: to.params.pathMatch || '/', query: to.query };
    }
  },
  {
    path: '/',
    redirect: { name: 'home' }
  }
];

export const modules: RouteConfig[] = joinRoutes(
  entryPointRoutes,
  homeRoutes,
  cashRoutes,
  wealthRoutes,
  tradeRoutes,
  transactionsRoutes,
  helpCenterRoutes,
  pensionRoutes,
  cashRoutes,
  accountRoutes,
  onboardingRoutes,
  productsRoutes,
  authenticationRoutes,
  investmentsRoutes,
  commonRoutes,
  catalogRoutes
);
