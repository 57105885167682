
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InitialAvatarIcon, BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: { InitialAvatarIcon, BaseText }
})
export default class StatementEmpty extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly hasAppliedFilters!: boolean;

  readonly NebraskaColors = NebraskaColors;

  get title() {
    return this.hasAppliedFilters
      ? 'Nenhum resultado encontrado'
      : 'Você ainda não possui movimentações';
  }
}
