
import { Component, Vue } from 'vue-property-decorator';
import { Heading } from '@warrenbrasil/nebraska-web';
import { IFeedItem } from '@/modules/home/services/news';
import { trackNewsClick, trackNewsRemoveClick } from './track-events';
import { DocumentModule } from '@/modules/pension/store/document';
import OverviewInbox from '../OverviewInbox/OverviewInbox.vue';

@Component({
  components: {
    Heading,
    OverviewInbox
  }
})
export default class HomeNews extends Vue {
  created() {
    this.loadPensionSuggestions();
  }

  trackView(news: IFeedItem) {
    trackNewsClick(news.dashboardTitle, news.id);
  }

  trackRemove(news: IFeedItem) {
    trackNewsRemoveClick(news.dashboardTitle, news.id);
  }

  private loadPensionSuggestions() {
    DocumentModule.fetchDocuments();
  }
}
