
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Row } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Row
  }
})
export default class DetailsRow extends Vue {
  @Prop({ required: true, type: String })
  private label!: string;

  @Prop({ required: false, type: String, default: '' })
  private value!: string;
}
