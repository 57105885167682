
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  SkeletonLoader,
  Heading
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Assets, InstrumentSummary } from '../../types';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll.vue';
import AssetInfo from '../AssetInfo/AssetInfo.vue';
import FiltersContainer from '../Filters/FiltersContainer.vue';

import {
  EFilterCategoriesTypes,
  IFilterCategories,
  IFilterCategory
} from '../../services/types/models/IFilters';

const FilterModule = namespace('FilterModule');

@Component({
  components: {
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    InfiniteScroll,
    SkeletonLoader,
    Heading,
    AssetInfo,
    FiltersContainer
  }
})
export default class InstrumentsList extends Vue {
  @Prop({ type: Array, required: true })
  public instruments!: InstrumentSummary[];

  @Prop({ type: Array, required: true, default: [] })
  public assets!: Assets[];

  @Prop({ type: Boolean, required: true, default: false })
  public isLoading!: boolean;

  @Prop({ type: Number, required: true, default: 10 })
  public perPage!: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  public addProduct?: boolean;

  @Prop({ type: String, required: false })
  public selectedInstrumentDetailId?: string;

  @Prop({ type: Boolean, required: false, default: false })
  public isScrollable?: boolean;

  @Prop({ type: Number, default: 0 })
  public initialPage!: number;

  @FilterModule.Getter('isSearchingProducts')
  private isSearchingProducts!: boolean;

  @FilterModule.Getter('filters')
  private filters!: IFilterCategories;

  @Emit('show-empty-message')
  private showEmptyMessage() {}

  private NebraskaColors = NebraskaColors;

  public currentTabIndex = 0;

  public changeCurrentTab(newValue: number, forceUpdate?: boolean): void {
    if (newValue === this.currentTabIndex && !forceUpdate) {
      return;
    }
    this.currentTabIndex = newValue;
    this.$emit('instruments-handle-tab-change', this.currentTabIndex);
  }

  public get tabsList() {
    return this.assets;
  }

  private get currentAsset() {
    return this.tabsList[this.currentTabIndex];
  }

  private getAppliedFilters() {
    if ((this.filters as any)[this.tab]) {
      const appliedfilters: Array<IFilterCategory> = (this.filters as any)[
        this.tab
      ].categories;

      if (appliedfilters) {
        let filtersString = '';
        for (const filterCategory of appliedfilters) {
          if (filterCategory.applied) {
            if (typeof filterCategory.selectedOption === 'string')
              filtersString += filterCategory.selectedOption + '&';
            else {
              if (filterCategory.selectedOption) {
                for (const option of filterCategory.selectedOption) {
                  filtersString += option + '&';
                }
              }
            }
          } else if (filterCategory.subCategories) {
            for (const subcategoryFilter of filterCategory.subCategories) {
              if (subcategoryFilter.applied) {
                filtersString += subcategoryFilter.selectedOption + '&';
              }
            }
          }
        }
        return filtersString;
      }
    }

    return '';
  }

  fetch(isScrolling?: boolean) {
    if (!isScrolling) {
      this.changeCurrentTab(this.currentTabIndex, true);
      const instrumentList = document.getElementById('instrument-list');
      if (instrumentList !== null) {
        instrumentList.scrollTop = 0;
      }
    }
    this.$emit('instruments-list-fetch-more', {
      filtersParams: this.getAppliedFilters()
    });
  }

  private canShowSubCategory(
    instrument: InstrumentSummary,
    index: number
  ): boolean {
    const isFirst = (index: number) => {
      return index === 0;
    };

    const previousInstrument = (index: number) => {
      return this.instruments[index - 1];
    };

    if (isFirst(index)) {
      return true;
    }

    return previousInstrument(index).subCategory !== instrument.subCategory;
  }

  get tab() {
    return EFilterCategoriesTypes[this.currentTabIndex];
  }
}
