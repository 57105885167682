
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RouteMixin } from '@/modules/common/mixins/route';

import { ProductsMenu } from '@/modules/products/components/ProductsMenu';
import InstrumentsContainer from '@/modules/wallet-builder/src/components/Instruments/InstrumentsContainer.vue';
import { ActionCard } from './components/ActionCard';
import { Heading, BaseText } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    ActionCard,
    BaseText,
    Heading,
    InstrumentsContainer,
    ProductsMenu
  },
  mixins: [RouteMixin]
})
export default class ProductsContainer extends Vue {
  private readonly NebraskaColors = NebraskaColors;

  @Prop({ type: String })
  private instrumentId?: string;

  public handleProductClick(apiId: string) {
    this.$router.push({ name: 'products-details', params: { apiId } });
  }

  public openDocument() {
    window.open('https://warren.com.br/blog/carteira-por-conta-propria');
  }
}
