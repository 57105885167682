
import { Vue, Component } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  Heading,
  BaseText,
  Grid,
  RoundedIcon
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Heading,
    BaseText,
    RoundedIcon,
    Grid
  }
})
export default class FirstAccessDetails extends Vue {
  get NebraskaColors() {
    return NebraskaColors;
  }
}
