
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { Heading } from '@warrenbrasil/nebraska-web';
import { Portal } from '@/modules/common/components/Portal';
import {
  addBodyScroll,
  toggleBodyScroll
} from '@/modules/common/helpers/browser';

@Component({
  name: 'CoverSheet',
  components: { Heading, Portal }
})
export default class CoverSheet extends Vue {
  @Prop({ type: String, required: true })
  private title!: string;

  @Prop({ type: Boolean, default: false, required: true })
  private show!: boolean;

  @Ref()
  private containerRef!: HTMLDivElement;

  mounted() {
    this.containerRef.onanimationstart = this.onAnimationStart;
    this.containerRef.onanimationend = this.onAnimationEnd;
  }

  private onAnimationStart(event: AnimationEvent) {
    if (event.target !== this.containerRef) return;
    toggleBodyScroll();
  }

  private onAnimationEnd() {
    addBodyScroll();
    this.$emit('on-hide');
  }

  destroyed() {
    this.onAnimationEnd();
  }
}
