
import { Vue, Prop, Component, Emit } from 'vue-property-decorator';
import { BaseIcon, Checkbox } from '@warrenbrasil/nebraska-web';
import { IFilterQuickOptions } from '../../../../services/types/models/IFilters';

@Component({
  components: { Checkbox, BaseIcon }
})
export default class FilterCheckboxList extends Vue {
  @Prop({ type: Array, required: true })
  public options!: IFilterQuickOptions[];

  @Emit('select-option')
  selectOption(option: IFilterQuickOptions) {
    this.$forceUpdate();
    return option;
  }

  public getIconBackgroudColor(backgroundColor?: string) {
    return {
      'icon-red': backgroundColor === '#FFF2F5',
      'icon-blue': backgroundColor === '#E0F1FF',
      'icon-green': backgroundColor === '#D6FFE0'
    };
  }
}
