import {
  httpClient,
  HttpClient
} from '@/modules/common/services/http/http-client';

export function FeatureFlag(httpClient: HttpClient) {
  function getFeatures<T = string>(): Promise<T[]> {
    return httpClient.get<T[]>('/api/v1/account/features');
  }

  return { getFeatures };
}

export const FeatureFlagService = FeatureFlag(httpClient);
