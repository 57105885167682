import { EVariableIncomeInvestmentSteps } from './types';
import { Investment } from '../components/Investment';
import { Preview } from '../components/Preview';
import { Conclusion } from '../components/Conclusion';

export const VariableIncomeInvestmentComponents = {
  [EVariableIncomeInvestmentSteps.INVESTMENT]: Investment,
  [EVariableIncomeInvestmentSteps.PREVIEW]: Preview,
  [EVariableIncomeInvestmentSteps.CONCLUSION]: Conclusion
};
