
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SearchInput } from '@/modules/common/components/SearchInput';
import {
  getBackgroundImages,
  putBackgroundImage,
  IPhotos
} from '@/modules/wealth/services/portfolio';
import { BaseCard } from '@/modules/common/components/cards/BaseCard';
import { InfiniteScroll } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    SearchInput,
    BaseCard,
    InfiniteScroll
  }
})
export default class WealthPortfolioBackground extends Vue {
  @Prop({ type: String })
  public portfolioId!: string;

  @Prop({ type: Boolean, default: true })
  public instantSave!: boolean;

  private search = '';
  private images: IPhotos[] = [];
  private page = 1;

  private async getBackgroundImages(restartPage: boolean) {
    if (restartPage) {
      this.images = [];
      this.page = 1;
    }
    const queryInfo = {
      query: this.search,
      page: this.page++,
      perPage: 10
    };
    const { photos } = await getBackgroundImages(queryInfo);
    photos.forEach(photo => this.images.push(photo));
  }

  private async putBackgroundImage(photo: IPhotos) {
    if (this.instantSave) {
      await putBackgroundImage(this.portfolioId, photo.urls);
    }
    this.$emit(
      'background-image-changed',
      this.instantSave ? photo.urls.thumb : photo
    );
  }
}
