export enum MyAccountAction {
  ChangeEmail = 'change_email',
  ChangePassword = 'change_password',
  EditRegister = 'edit_register',
  InitialRegister = 'initial_register',
  InvestmentProfile = 'investment_profile',
  MfaValidationThenChangeEmail = 'mfa_validation_then_change_email',
  MfaValidationThenEditRegister = 'mfa_validation_then_edit_register',
  RegulatoryUpdate = 'regulatory_update',
  Terms = 'terms',
  DeleteAccount = 'delete_account'
}

export interface IMyAccountItem {
  title: string;
  action: MyAccountAction;
  chip?: string;
}

export interface IMyAccountButton {
  label: string;
  action: MyAccountAction;
}

export interface IMyAccountMenu {
  title: string;
  rows: IMyAccountItem[];
  button: IMyAccountButton;
  accountInformation?: IAccountInformation[];
}

export interface IAccountInformation {
  title: string;
  description: string;
  infos?: {
    name: string;
    email: string;
    phone: string;
  };
}
