
import { Vue, Component } from 'vue-property-decorator';

import { Heading, BaseText, Grid } from '@warrenbrasil/nebraska-web';
import { FiltersList } from '@/modules/trade/components/FiltersList';
import { InstrumentRow } from '@/modules/trade/components/InstrumentRow';
import { MainError } from '@/modules/trade/components/MainError';
import { MainLoading } from '@/modules/trade/components/MainLoading';

import {
  getMarketToday,
  IMarketToday,
  marketItems,
  MarketTodayFilters
} from '@/modules/trade/services/market-today';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { MarketTodayFilter } from './types';
import {
  trackMarketTodayClick,
  trackMarketTodayFilter,
  trackMarketHighlightsRequestError,
  trackMarketHighlightsRetryClick
} from '@/modules/trade/views/TradeView/trackers';
import { logError } from '@/modules/trade/helpers/logs';

@Component({
  components: {
    Heading,
    BaseText,
    Grid,
    InstrumentRow,
    FiltersList,
    MainLoading,
    MainError
  }
})
export default class MarketToday extends Vue {
  marketData!: IMarketToday;
  isLoading = true;
  isError = false;
  filter: MarketTodayFilters = MarketTodayFilters.HIGH_VOLUME;
  readonly NebraskaColors = NebraskaColors;

  created() {
    this.fetchMarketToday();
  }

  onInstrumentClick(instrument: marketItems) {
    trackMarketTodayClick(
      instrument.symbol,
      instrument.name,
      instrument.type.value
    );
    this.$router.push({
      name: 'tradeInstrument',
      params: { ticker: instrument.symbol }
    });
  }

  async handleOnRetry() {
    trackMarketHighlightsRetryClick();
    await this.fetchMarketToday();
  }

  private async fetchMarketToday() {
    this.isLoading = true;
    this.isError = false;
    getMarketToday(this.filter)
      .then((data: IMarketToday) => {
        this.marketData = data;
        this.filter = data.filters.selected;
      })
      .catch(e => {
        logError(e, 'request_market_highlights_error');
        trackMarketHighlightsRequestError();
        this.isError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  handleOnSelectFilter(filter: MarketTodayFilter) {
    trackMarketTodayFilter(filter.value);
    this.filter = filter.value;
    this.fetchMarketToday();
  }
}
