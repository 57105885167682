
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { BaseIcon, BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: { BaseIcon, BaseText }
})
export default class Accordion extends Vue {
  @Prop({ type: String, required: false })
  public subtitle?: string;

  @Prop({ type: String, required: false })
  public title?: string;

  @Prop({ type: Boolean, default: false })
  public open?: boolean;

  @Prop({ type: Boolean, default: false })
  public isPrimary!: boolean;

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean;

  @Prop({ type: Boolean, default: true })
  public shouldAddTextStyle!: boolean;

  private isOpen = false;
  private NebraskaColors = NebraskaColors;

  private get computedTextClass() {
    if (this.isPrimary) {
      return 'text-p-3 wrn-p-b-1 wrn-accordion-text_primary';
    } else {
      return 'text-p-3 wrn-p-b-1 wrn-accordion-text';
    }
  }

  @Watch('open', { immediate: true })
  private changeStatus(value: boolean) {
    this.isOpen = value;
  }

  @Emit()
  private accordionChanged() {
    if (this.disabled) return;
    this.isOpen = !this.isOpen;

    return this.isOpen;
  }

  get iconColor() {
    return this.disabled
      ? NebraskaColors.elementDisabled
      : NebraskaColors.elementPrimary;
  }
}
