import { GtmTracker } from '@/modules/common/services/analytics';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'regulatory_update_verify_password',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackView() {
  trackEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackContinue() {
  trackEvent({
    event_type: 'click',
    event_name: 'continue'
  });
}

export function trackCancel() {
  trackEvent({
    event_type: 'click',
    event_name: 'cancel'
  });
}

export function trackError(event_target: string) {
  trackEvent({
    event_type: 'load',
    event_name: 'field_error',
    event_target
  });
}
