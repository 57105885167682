import { IBankAccount, ICustomer } from '@/types/models';
import { HttpClient } from '@warrenbrasil/web-http';
import { httpClient } from '../http-client';
import {
  IRegisterBankData,
  CustomerResponse,
  ICustomerTypeformResponse
} from './types';

export function CustomerService(httpClient: HttpClient) {
  function register(data = {}) {
    return httpClient.put<ICustomer>('/api/v3/account/update', data);
  }

  function registerBankAccount(data: IRegisterBankData) {
    return httpClient.post<IBankAccount>('/api/v2/bank-accounts/create', data);
  }

  function checkAccount(email: string) {
    return httpClient.post('/api/v2/account/setup/request', { email });
  }

  function getCustomer() {
    return httpClient.get<CustomerResponse>('/api/v2/account/me');
  }

  function getCustomerAnswers(
    formId: string,
    customerId: string
  ): Promise<ICustomerTypeformResponse[]> {
    return httpClient.get(
      `/api/v1/account/feedback/${formId}/customer/${customerId}/answers`
    );
  }

  return {
    register,
    registerBankAccount,
    checkAccount,
    getCustomer,
    getCustomerAnswers
  };
}

export const customerAPI = CustomerService(httpClient);
