import { httpClient } from '@/modules/common/services/http/http-client';
import { CSAT, IgetCsatResponse } from './types';

export const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getCSAT(name: CSAT): Promise<IgetCsatResponse> {
  return http.get(`/v1/getCSAT/${name}`);
}
