import {
  getCustomTheme,
  ICustomTheme
} from '@/modules/common/services/local-storage';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

export function cssVariableToHexadecimal(
  variable: NebraskaColors,
  removeAlpha = false,
  root?: HTMLElement
) {
  const regex = /\(([^)]+)\)/;
  const results = variable.match(regex);

  if (results) {
    const hex = getComputedStyle(root || document.documentElement)
      .getPropertyValue(results[1])
      .trim();

    return removeAlpha ? hex.substr(0, 7) : hex;
  }
}

export const isThemeWhitelabel = (theme?: ICustomTheme) => {
  return theme && theme.name !== 'WarrenPink' && theme.name !== 'WarrenBlack';
};

export const isThemeWarrenManaged = (
  theme: ICustomTheme | null = getCustomTheme()
) => {
  return theme && theme.type === 'WarrenManaged';
};
