
import { Component, Prop, Vue } from 'vue-property-decorator';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component
export default class Hero extends Vue {
  @Prop({ type: String })
  readonly imageSmall?: string;

  @Prop({ type: String })
  readonly imageMedium?: string;

  @Prop({ type: String })
  readonly imageLarge?: string;

  @Prop({ type: Boolean, default: false })
  readonly showOverlay!: boolean;

  @Prop({ type: String, default: '80vh' })
  readonly height!: string;

  @Prop({ type: String, default: NebraskaColors.themePrimary })
  readonly bgColor!: string;

  private hasError = false;

  private handleLoad() {
    this.hasError = false;
  }

  private handleError() {
    this.hasError = true;
  }

  public get style() {
    return {
      height: this.height,
      background: this.bgColor
    };
  }

  public get computedClassName() {
    return ['hero', { 'hero-overlay': this.showOverlay }];
  }
}
