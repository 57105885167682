
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import {
  Select,
  FilterOption,
  SpinnerLoader
} from '@warrenbrasil/nebraska-web';
import { MatchMedia } from '@/modules/common/components/MatchMedia';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { IOption } from './types';

@Component({
  name: 'ChartFilters',
  components: { Select, FilterOption, MatchMedia, SpinnerLoader }
})
export default class ChartFilters extends Vue {
  @Prop({ type: Array, required: false })
  readonly filters?: IOption[];

  @Prop({ type: Array, required: false })
  readonly benchmarkSelectOptions?: IOption[];

  @Prop({ type: Array, required: false })
  readonly chartSelectOptions?: IOption[];

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  readonly NebraskaColors = NebraskaColors;

  public selectedDateFilter: string | IOption = '';

  private created() {
    if (this.filters) {
      this.selectedDateFilter =
        this.checkSelectedFilter(this.filters)?.value || '';
    }
  }

  private get getSelectedDateFilter() {
    return (
      this.filters &&
      this.filters.find(
        (filter: IOption) => filter.value === this.selectedDateFilter
      )
    );
  }

  public checkSelectedFilter(filterOptions: IOption[]): IOption {
    const activeValue = filterOptions.find(
      (value: IOption) => value.active === true
    );
    return activeValue || filterOptions[0];
  }

  @Emit('tracking')
  private tracking(action: string, label: string) {
    return { action, label };
  }

  @Emit('on-select-filter')
  public onSelectFilter(filter: string | IOption) {
    this.selectedDateFilter = (filter as IOption).value || (filter as string);
    this.tracking('select-filter', this.selectedDateFilter);
    return this.selectedDateFilter;
  }

  @Emit('on-select-benchmark')
  public onSelectBenchmark({ value }: IOption): string {
    return value;
  }

  @Emit('on-select-chart')
  public onSelectChart({ value }: IOption): string {
    this.tracking('select-chart-type', value);
    return value;
  }
}
