
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import { BaseText, LoaderIndicator } from '@warrenbrasil/nebraska-web';

import { throttle } from 'lodash-es';

@Component({
  components: { BaseText, LoaderIndicator }
})
export default class InfiniteScroll extends Vue {
  @Prop({ required: true, type: Function })
  public request!: () => Promise<any>;

  @Prop({ type: Number, default: 10 })
  public maxPerPage!: number;

  @Prop({ type: String, default: 'Nenhum item encontrado!' })
  public notFoundItemsMessage!: string;

  @Prop({ type: Boolean, default: false })
  public whitelabel!: boolean;

  @Prop({ type: String })
  public loaderColor?: string;

  @Prop({ type: String })
  public loaderBackground?: string;

  @Prop({ type: Function })
  public actionFilter?: (items: any) => any[];

  @Prop({ type: Array, default: () => [] })
  public itemList!: any[];

  @Prop({ type: Number, default: 0 })
  public initialPage!: number;

  @Prop({ type: Boolean, default: true, required: false })
  public allowShowLoader?: boolean;

  @Prop({ type: Boolean, default: false, required: false })
  public isFetching?: boolean;

  @Prop({ type: Boolean, default: false, required: false })
  public includeIndex?: boolean;

  @Ref('infiniteScrollTrigger')
  private readonly infiniteScrollTrigger!: HTMLDivElement;

  private showLoader = false;

  private payloadItems: any[] = [];

  private currentPage: number = this.initialPage;

  private scrollPercentage = 0;

  private removeOnScrollListener!: () => void;

  @Watch('initialPage')
  private updateInitialPage() {
    if (this.initialPage === 1) {
      this.currentPage = this.initialPage;
    }
  }

  @Watch('canShowEmptyMessage')
  private watchCanShowEmptyMessage(val: boolean) {
    if (val) {
      this.$emit('show-empty-message');
    }
  }

  @Watch('scrollPercentage')
  private async watchScrollPercentage(val: number) {
    if (val > 0.85 && !this.showLoader) {
      await this.handleLoad();
    }
  }

  get items() {
    let items = [...this.itemList, ...this.payloadItems];
    const mapProductList = new Map(items.map(m => [m.name, m])).values();
    items = [...mapProductList];

    return this.actionFilter ? this.actionFilter(items) : items;
  }

  public async beforeMount() {
    this.currentPage = 0;
    await this.handleLoad();

    const scrollLabelElement = document.getElementById('instrument-list');

    if (scrollLabelElement && !this.isProductsRoute()) {
      const handleOnScroll = throttle(() => {
        const percentScrolled =
          scrollLabelElement.scrollTop /
          (scrollLabelElement.scrollHeight - scrollLabelElement.offsetHeight);
        this.scrollPercentage = percentScrolled;
      }, 1000);
      scrollLabelElement.addEventListener('scroll', handleOnScroll);

      this.removeOnScrollListener = () =>
        scrollLabelElement.removeEventListener('scroll', handleOnScroll);
    } else {
      const handleOnScroll = throttle(() => {
        const documentElement = document.documentElement;
        const body = document.body;
        const scrollTop = 'scrollTop';
        const scrollHeight = 'scrollHeight';
        const percentScrolled =
          (documentElement[scrollTop] || body[scrollTop]) /
          ((documentElement[scrollHeight] || body[scrollHeight]) -
            documentElement.clientHeight);
        this.scrollPercentage = percentScrolled;
      }, 1000);
      document.addEventListener('scroll', handleOnScroll);

      this.removeOnScrollListener = () =>
        document.removeEventListener('scroll', handleOnScroll);
    }
  }

  destroyed() {
    this.removeOnScrollListener();
  }

  isProductsRoute() {
    return this.$route.name === 'products';
  }

  private async handleLoad() {
    this.showLoader = true;
    const response = await this.request();

    if (response) {
      this.payloadItems = [...this.payloadItems, ...response];
    }

    this.showLoader = false;
    this.currentPage++;
  }

  private get canShowEmptyMessage() {
    if (this.isFetching) {
      return false;
    }

    return this.items.length === 0 && !this.showLoader;
  }

  private get canShowItems() {
    if (this.isFetching && this.allowShowLoader && this.showLoader) {
      return false;
    }

    return this.items.length;
  }
}
