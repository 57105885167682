
import { Component, Vue } from 'vue-property-decorator';
import {
  Document,
  DocumentType,
  Proposal
} from '@warrenbrasil/pension-types/dist/next';
import { Heading, Row } from '@warrenbrasil/nebraska-web';
import { DocumentModule } from '@/modules/pension/store/document';

@Component({
  name: 'StepDetails',
  components: {
    Row,
    Heading
  }
})
export default class StepList extends Vue {
  get state() {
    return DocumentModule.data;
  }

  get proposals() {
    return this.state.documents.filter(
      doc => doc.type === DocumentType.Proposal
    );
  }

  get portabilities() {
    return this.state.documents.filter(
      doc => doc.type === DocumentType.PortabilityTerm
    );
  }

  getProposalTitle(document: Document) {
    const proposal = document.entityData as Proposal;

    return proposal.portfolioName || 'Previdência Privada';
  }

  getProposalSubtitle(document: Document) {
    const proposal = document.entityData as Proposal;

    return `Proposta nº ${proposal.proposalNumber}`;
  }

  async selectDocument(document: Document) {
    await DocumentModule.selectDocument(document);
  }
}
