
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Row, Heading, BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { IPortfolioTransactionsSummary } from '@/modules/wealth/services/investment/investment-types';
import { moneyFormat } from '@/modules/common/helpers/currency';

@Component({
  components: {
    Heading,
    Row,
    BaseText
  }
})
export default class TransactionDetail extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: Object, required: true })
  readonly detailProps!: IPortfolioTransactionsSummary;

  public getRowValues(value: number) {
    return moneyFormat(value, false);
  }
}
