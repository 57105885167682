import { StringFormatted } from '@/modules/trade/types';

export enum DerivativesFiltersDateValues {
  Today = 'Today',
  Historical = 'Historical'
}

export enum DerivativesFiltersTypes {
  Date = 'Date',
  Type = 'Type'
}

export enum DerivativesFiltersTypeValues {
  Options = 'Options',
  Forwards = 'Forwards'
}

export interface IDerivativesFilters {
  [DerivativesFiltersTypes.Date]: {
    selected: DerivativesFiltersDateValues;
    available: StringFormatted[];
  };
  [DerivativesFiltersTypes.Type]: {
    selected: DerivativesFiltersTypeValues;
    available: StringFormatted[];
  };
}
