
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { getCustomer } from '@/modules/common/services/http/account';
import { FeatureFlagService } from '@/modules/common/services/http/feature-flag';
import { ICustomer } from '@/types/models';

import { LoaderIndicator } from '@warrenbrasil/nebraska-web';
import { UserProfile } from '../../components/UserProfile';
import { trackPageView } from '../../helpers/track-events';

const customerModule = namespace('CustomerModule');

@Component({
  components: {
    LoaderIndicator,
    UserProfile
  }
})
export default class SuitabilityProfile extends Vue {
  @customerModule.State('customer')
  public customer?: ICustomer;

  @customerModule.Mutation('setCustomer')
  private setCustomer!: (customer: ICustomer) => ICustomer;

  public loadingCustomer = true;
  public hasRenderedProfile = false;
  private startedWithoutUser = false;

  async created() {
    this.startedWithoutUser = !this.customer;

    if (this.startedWithoutUser) {
      await this.getUser();
    }

    this.loadingCustomer = false;
  }

  public profileRendered() {
    this.hasRenderedProfile = true;
  }

  mounted() {
    trackPageView();
  }

  private async getUser() {
    try {
      const { customer } = await getCustomer();
      const features = await FeatureFlagService.getFeatures();
      localStorage.setItem('warren.features', JSON.stringify(features));
      this.setCustomer(customer);
    } catch {
      this.customer = undefined;
    }
  }

  public get isUserB2B() {
    return (
      this.startedWithoutUser ||
      (this.customer &&
        (this.customer.clientType.whitelabel ||
          this.customer.clientType.partner))
    );
  }
}
