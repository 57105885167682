import { IPortfolioSummary } from '../services/investment';

export enum DetailType {
  Transaction = 'TransactionDetail',
  Earning = 'EarningDetail',
  Redeem = 'RedeemDetail',
  MinimumDeposit = 'MinimumDeposit',
  MinimumRedemption = 'MinimumRedemption',
  Strategy = 'Strategy',
  Mandate = 'MandateDetail',
  Cost = 'CostDetail'
}

export interface IDetailModalProps {
  title: string;
  type: string;
  details?: {
    description?: string[];
    allocations?: {
      portfolioLiquidity?: string;
      portfolioSummary?: IPortfolioSummary;
    };
    redemption?: {
      description: string[];
    };
  };
}
