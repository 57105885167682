import { GtmTracker } from '@/modules/common/services/analytics';

const trackTradeEvent = GtmTracker.trackerWithDefaultProps({
  category: 'Trade'
});

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'extracts_and_trading_notes',
  digital_experience: 'investor',
  experience_section: 'broker'
});

export function trackDailyNoteDownload(date: string) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'trading_notes',
    event_name: 'download_daily_trading_note',
    event_target: date
  });
  trackTradeEvent({
    action: `Clique ${date}`,
    label: 'Notas de negociação'
  });
}

export function trackYearlyNoteDownload(year: string) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'trading_notes',
    event_name: 'download_yearly_trading_notes',
    event_target: year
  });
  trackTradeEvent({
    action: `Clique Baixar ${year}`,
    label: 'Notas de negociação'
  });
}
