
import { Component, Vue, Prop } from 'vue-property-decorator';
import { BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { PortfolioProductsListWithDetails } from '../PortfolioProductsListWithDetails';
import { DonutChart } from '@/modules/common/components/charts/DonutChart';
import { parseAllocationsToChartData } from './helpers/helpers';
import { IProductAllocationsResponse } from '@/modules/wealth/services/investment';

@Component({
  components: { BaseText, PortfolioProductsListWithDetails, DonutChart }
})
export default class AllocationDetail extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: Object, required: true })
  readonly productAllocations!: IProductAllocationsResponse;

  get chartAllocations() {
    return parseAllocationsToChartData(this.productAllocations.allocations);
  }

  get updatedAt() {
    return this.productAllocations.lastUpdateAt;
  }
}
