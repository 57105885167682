var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"brokerage-notes",attrs:{"data-testid":"brokerage-notes"}},[(_setup.isLoading)?[_c(_setup.SkeletonLoader,{staticClass:"brokerage-notes__loader brokerage-notes__wrapper"},_vm._l((6),function(index){return _c('div',{key:index,staticClass:"brokerage-notes__loader__row"})}),0)]:(_setup.hasError)?_c('div',{staticClass:"brokerage-notes__wrapper"},[_c(_setup.HelperViewStatus,{staticClass:"brokerage-notes__helper",attrs:{"title":"Não foi possível carregar as suas notas de negociação","description":"Aguarde um momento e tente novamente","button-label":"Tentar novamente","status":"alert"},on:{"click":_setup.getBrokeragesNotes}})],1):(!_setup.hasBrokerageNotes)?_c('div',{staticClass:"brokerage-notes__wrapper"},[_c(_setup.HelperViewContextual,{staticClass:"brokerage-notes__helper",attrs:{"icon":"ED0030","description":"Você não possui notas de negociação","context":"neutral"}})],1):_vm._l((_setup.months),function(month){return _c('div',{key:month.label,class:[
        'brokerage-notes__accordion-wrapper',
        {
          'brokerage-notes__accordion-wrapper--opened':
            _setup.openedAccordionMonthsMap[month.label]
        }
      ]},[_c(_setup.Accordion,{staticClass:"brokerage-notes__accordion",attrs:{"title":month.label},on:{"accordion-changed":isOpen => _setup.handleToggleAccordion(isOpen, month.label)}},[(month.brokerageNotes.length === 0)?_c(_setup.BaseText,{attrs:{"color":_setup.NebraskaColors.elementSecondary}},[_vm._v(" Você não possui notas de negociação para este período ")]):_vm._l((month.brokerageNotes),function(brokerageNote){return _c(_setup.BrokerageNoteRow,{key:brokerageNote.date.value,attrs:{"brokerage-note":brokerageNote,"disabled":_setup.isDownloadingBrokerageNote,"loading":_setup.currBrokerageNoteDownloading === brokerageNote.date.value},on:{"on-click":function($event){return _setup.handleDownloadBrokerageNote(brokerageNote)}}})})],2)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }