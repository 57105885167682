import { StorageKeys } from '@/modules/common/services/local-storage';
import { isImpersonate } from '@/modules/common/services/partner';

export const getAccessToken = (tokenStorageKey?: StorageKeys) => {
  let tokenKey: StorageKeys;

  if (tokenStorageKey) {
    tokenKey = tokenStorageKey;
  } else if (isImpersonate()) {
    tokenKey = StorageKeys.ImpersonateToken;
  } else {
    tokenKey = StorageKeys.AccessToken;
  }

  return localStorage.getItem(tokenKey);
};

export const hasAccessToken = () => !!getAccessToken();
