export enum SubscribeStatus {
  unsubscribed = 'Não Subscrito',
  reserved = 'Reservado',
  subscribed = 'Subscrito'
}

export enum EventSubscriptionType {
  LeftOversSubscription = 'SobrasDeSubscricao',
  Subscription = 'Subscricao'
}
