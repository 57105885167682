import { render, staticRenderFns } from "./ProductsContainer.vue?vue&type=template&id=8c25c34e&scoped=true&"
import script from "./ProductsContainer.vue?vue&type=script&lang=ts&"
export * from "./ProductsContainer.vue?vue&type=script&lang=ts&"
import style0 from "./ProductsContainer.vue?vue&type=style&index=0&id=8c25c34e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c25c34e",
  null
  
)

export default component.exports