
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { FilterSelect, SpinnerLoader } from '@warrenbrasil/nebraska-web';
import { Money } from '@/modules/common/components/MoneyV2';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ChartFiltersTypes, ChartFiltersKeys } from './types';

@Component({
  components: {
    FilterSelect,
    SpinnerLoader,
    Money
  }
})
export default class ChartFilters extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: Object, required: true })
  readonly filters!: ChartFiltersTypes;

  readonly NebraskaColors = NebraskaColors;
  readonly ChartFiltersKeys = ChartFiltersKeys;

  @Emit('on-select-filter')
  emitOnSelectFilter(_filterKey: ChartFiltersKeys, _value: string | number) {}

  get hasBenchmarkFilters() {
    return this.filters[ChartFiltersKeys.BENCHMARK_FILTER].options.length > 0;
  }

  get hasDateFilters() {
    return this.filters[ChartFiltersKeys.DATE_FILTER].options.length > 0;
  }
}
