
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BaseCell } from '@warrenbrasil/nebraska-web';
import { InstrumentName } from '@/modules/trade/components/InstrumentName';

import { InstrumentType } from '@/modules/trade/types';

@Component({
  components: {
    BaseCell,
    InstrumentName
  }
})
export default class InstrumentNameCell extends Vue {
  @Prop({ type: String, required: true })
  readonly symbol!: string;

  @Prop({ type: String, required: false })
  readonly title?: string;

  @Prop({ type: String, required: false })
  readonly name?: string;

  @Prop({ type: String, required: false })
  readonly type?: InstrumentType;
}
