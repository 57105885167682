
import { Component, Vue, Prop, Ref, Watch, Emit } from 'vue-property-decorator';
import { VMoney } from 'v-money';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { toFixedWithoutRounding } from '@/modules/common/helpers/number';
import {
  formatCurrency,
  currencyToNumber
} from '@/modules/common/helpers/currency';

import { RoundedIcon } from '@warrenbrasil/nebraska-web';

interface SuggestionFormatted {
  value: number;
  label: string;
  isTotal: boolean;
}

@Component({
  inheritAttrs: false,
  name: 'SingleInput',
  components: { RoundedIcon },
  directives: { money: VMoney }
})
export default class SingleInput extends Vue {
  @Prop({ type: Number, default: 0 })
  private value!: number;

  @Prop({ type: Boolean, required: false })
  private suggestionAtFirst?: boolean;

  @Prop({ type: String, required: false })
  private suggestionLabel?: string;

  @Prop({ type: Array, default: () => [] })
  private suggestions!: number[];

  @Prop({ type: String, default: 'Ops, aconteceu um problema' })
  private errorMessage!: string;

  @Prop({ type: Boolean, default: false })
  private hasError!: boolean;

  @Prop({ type: Boolean, default: true })
  private autofocus!: boolean;

  @Prop({ type: Boolean, default: false })
  private isDisabled!: boolean;

  @Ref()
  private readonly input!: HTMLInputElement;

  @Watch('localValue', { immediate: true })
  handleLocalValueChange() {
    this.preventNegativeLocalValue();
    this.emitInputEvent();
  }

  private localValue = toFixedWithoutRounding(this.value, 2, true);

  private get isValueHigherThanZero() {
    return this.value > 0;
  }

  private get localValueAsNumber() {
    return currencyToNumber(this.localValue);
  }

  get suggestionsFormatted(): SuggestionFormatted[] {
    return this.suggestions.map((suggestion, index) => {
      const suggestedItem = this.suggestionAtFirst
        ? index === 0
        : this.suggestions.length - 1 === index;

      const label = suggestedItem
        ? this.suggestionLabel || 'Total'
        : this.formatSuggestion(suggestion);

      return { label, value: suggestion, isTotal: suggestedItem };
    });
  }

  mounted() {
    this.autofocus && this.input.focus();
  }

  @Emit('input')
  private emitInputEvent() {
    return this.localValueAsNumber;
  }

  @Emit('enter-key-event')
  public emitEnterKeyEvent() {}

  @Emit('focus')
  private emitFocusEvent() {}

  @Emit('suggestion-click')
  private emitSuggestionEvent(suggestionLabel: string) {
    return suggestionLabel;
  }

  private preventNegativeLocalValue() {
    this.localValue = this.localValue.replace('-', '');
  }

  private handleSuggestionClick(suggestion: SuggestionFormatted) {
    const { value, label, isTotal } = suggestion;

    isTotal ? this.setLocalAmount(value) : this.increaseLocalAmount(value);
    this.emitSuggestionEvent(label);
  }

  private setLocalAmount(newAmount: number) {
    this.localValue = toFixedWithoutRounding(newAmount, 2, true);
  }

  private increaseLocalAmount(newAmount: number) {
    const total = this.value + newAmount;
    this.setLocalAmount(total);
  }

  public resetLocalAmount() {
    this.localValue = '0';
  }

  private get NebraskaColors() {
    return NebraskaColors;
  }

  private formatSuggestion(originalNumber: number) {
    const formattedNumber = formatCurrency(originalNumber);
    const [number] = formattedNumber.split(',');

    return `+ ${number}`;
  }

  private get formatterOptions() {
    return {
      decimal: ',',
      thousands: '.',
      prefix: '',
      precision: 2
    };
  }
}
