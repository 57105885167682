import { GtmTracker } from '@/modules/common/services/analytics';

import { FilterFormatted } from '@/modules/trade/services/orders';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  digital_experience: 'investor',
  experience_section: 'broker'
});

export function trackOrdersViewLoad() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackSelectOrdersTableFilter(
  selectedFilter: string,
  filterOptions: FilterFormatted[]
) {
  const options = filterOptions.map(option => option.formatted).join(',');

  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'click',
    page_screen_section: 'header',
    event_name: 'select_filter',
    event_target: selectedFilter,
    filter_options: options
  });
}

export function trackOrdersTableRowClick(
  orderType: string,
  orderStatus: string
) {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'click',
    page_screen_section: 'orders_table',
    event_name: 'select_order',
    order_type: orderType,
    order_status: orderStatus
  });
}

export function trackOrdersViewBackClick() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'click',
    event_name: 'back'
  });
}

export function trackOrdersViewRequestError() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'load',
    page_screen_section: 'orders_table',
    event_name: 'error_view'
  });
}

export function trackOrdersViewErrorRetryClick() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'click',
    page_screen_section: 'orders_table',
    event_name: 'try_again'
  });
}

export function trackOrdersViewEmptyStateLoad() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'load',
    page_screen_section: 'orders_table',
    event_name: 'no_result_view'
  });
}

export function trackOrdersViewEmptyStateClearFiltersClick() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'click',
    page_screen_section: 'orders_table',
    event_name: 'try_again'
  });
}

export function trackInstrumentSelectionModalLoad() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'load',
    page_screen_section: 'filter_asset_selection',
    event_name: 'view'
  });
}

export function trackInstrumentSelectionApplyClick() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'click',
    page_screen_section: 'filter_asset_selection',
    event_name: 'apply'
  });
}

export function trackInstrumentSelectionCancelClick() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'click',
    page_screen_section: 'filter_asset_selection',
    event_name: 'cancel'
  });
}

export function trackInstrumentSelectionClose() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'click',
    page_screen_section: 'filter_asset_selection',
    event_name: 'close'
  });
}

export function trackInstrumentSelectionErrorLoad() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'load',
    page_screen_section: 'filter_asset_selection',
    event_name: 'error_asset_selection'
  });
}

export function trackInstrumentSelectionErrorRetryClick() {
  trackEvent({
    page_screen: 'broker_orders',
    event_type: 'click',
    page_screen_section: 'filter_asset_selection',
    event_name: 'try_again'
  });
}
