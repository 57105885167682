
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { moneyViewer as money } from '@/modules/common/helpers/currency';
import { BaseCard } from '@/modules/common/components/cards/BaseCard';
import { ProgressiveImage } from '@/modules/common/components/ProgressiveImage';
import { RouteMixin } from '@/modules/common/mixins/route';
import {
  BaseText,
  Heading,
  ChipStatus,
  ChipContext
} from '@warrenbrasil/nebraska-web';
import { useDashStore } from '@/modules/common/store/DashModule';

@Component({
  filters: { money },
  components: {
    BaseCard,
    ProgressiveImage,
    BaseText,
    Heading,
    ChipStatus,
    ChipContext
  },
  mixins: [RouteMixin]
})
export default class PortfolioCard extends Vue {
  private readonly dashModule = useDashStore();

  @Prop({ type: String, required: true })
  private id!: string;

  @Prop({ type: String, required: true })
  private name!: string;

  @Prop({ type: String, required: true })
  private balance!: string;

  @Prop({ type: Boolean, default: false })
  private showManagementIcon!: boolean;

  @Prop({ type: String, default: '' })
  private tagStatus!: string;

  @Prop({ type: String, required: true })
  private backgroundThumb!: string;

  @Prop({ type: String, required: true })
  private backgroundSmall!: string;

  @Prop({ type: String, default: 'wealthPortfolio' })
  private goTo!: string;

  @Prop({ type: Boolean, required: false })
  private disabled!: boolean;

  @Prop({ type: Boolean, default: false, required: false })
  private renderAsButton!: boolean;

  @Emit()
  private click() {
    return this.id;
  }

  hasHover = false;

  get NebraskaColors() {
    return NebraskaColors;
  }

  handleEnter() {
    this.hasHover = true;
  }

  handleLeave() {
    this.hasHover = false;
  }

  private get computedLink() {
    if (this.renderAsButton) return null;
    // @ts-ignore
    return this.getHref(this.goTo, { id: this.id });
  }

  private get computedPortfolioName(): string {
    let name = this.name;

    if (name.length > 140) {
      name = `${name.substring(0, 140)}...`;
    }

    return name;
  }

  private get hasBackground(): boolean {
    return !!this.backgroundSmall;
  }

  private get shouldShowMoney() {
    return this.dashModule.showMoney;
  }
}
