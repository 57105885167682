import { differenceBy, uniqBy } from 'lodash-es';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import { IInvestedPortfolio, IPortfolioList } from '@/types/models/Portfolio';
import { getAllPortfolios } from '@/modules/wealth/services/portfolio';

@Module({
  namespaced: true,
  name: 'PortfolioModule'
})
export class PortfolioStoreModule extends VuexModule {
  wasFetchedOnce = false;
  isFetching = false;
  isFetchFailed = false;
  portfolios: IPortfolioList[] = [];
  investedPortfolios: IInvestedPortfolio[] = [];

  get portfoliosAvailableToInvest(): IPortfolioList[] {
    const propNameToCompare = 'id';
    return differenceBy(
      this.portfolios,
      this.investedPortfolios,
      propNameToCompare
    );
  }

  get hasPortfolioToInvest() {
    return this.portfoliosAvailableToInvest.length > 0;
  }

  get getPortfolioById() {
    return (portfolioId: string): IPortfolioList | undefined => {
      return this.portfolios.find(({ id }) => id === portfolioId);
    };
  }

  @Mutation
  private setPortfolios(portfolios: IPortfolioList[]) {
    this.portfolios = portfolios;
  }

  @Mutation
  private setInvestedPortfolios(portfolios: IInvestedPortfolio[]) {
    this.investedPortfolios = portfolios;
  }

  @Mutation
  private setIsFetching(status: boolean) {
    this.isFetching = status;
  }

  @Mutation
  private setIsFetchFailed(status: boolean) {
    this.isFetchFailed = status;
  }

  @Mutation
  private setWasFetchedOnce(status = true) {
    this.wasFetchedOnce = status;
  }

  @Action
  addInvestedPortfolio(targetId: string) {
    const propNameToCompare = 'id';
    const portfolios = uniqBy(
      [...this.investedPortfolios, { id: targetId }],
      propNameToCompare
    );

    this.context.commit('setInvestedPortfolios', portfolios);
  }

  @Action
  resetInvestedPortfolios() {
    this.context.commit('setInvestedPortfolios', []);
  }

  @Action
  async fetchPortfolios() {
    this.context.commit('setIsFetching', true);
    this.context.commit('setIsFetchFailed', false);
    try {
      const portfolios = await getAllPortfolios();

      this.context.commit('setPortfolios', portfolios);
      this.context.commit('setWasFetchedOnce');
    } catch {
      this.context.commit('setIsFetchFailed', true);
    } finally {
      this.context.commit('setIsFetching', false);
    }
  }
}
