import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IGetInvestmentSuggestionsResponse,
  IGetSuggestionDetailsResponse,
  IGetSuggestionDetailsParams,
  IPutInvestmentAgreementResponse,
  IPutInvestmentAgreementParams
} from './types';
import { getCustomerId } from '@/modules/common/services/local-storage';

export const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getInvestmentSuggestions(): Promise<IGetInvestmentSuggestionsResponse> {
  const customerId = getCustomerId();
  return http.get('/v2/getInvestmentSuggestions', {
    params: { customerId }
  });
}

export function getTransactionSuggestionDetails(
  params: IGetSuggestionDetailsParams
): Promise<IGetSuggestionDetailsResponse> {
  return http.get('/v2/getTransactionSuggestionDetails', {
    params
  });
}

export function putInvestmentAgreement(
  params: IPutInvestmentAgreementParams
): Promise<IPutInvestmentAgreementResponse> {
  return http.put('/v1/investmentAgreement', params);
}
