import Big from 'big.js';

/**
 * @description Utilizado para calcular o rendimento, também pode ser chamado de ganho, de uma posição. A fórmula para isso é:
 *
 * (Preço atual do ativo - Preço média de posição) * Quantidade
 *
 * Com isso podemos pegar o valor do preço atual do ativo que pode vir de um socket em tempo real e pegar as informações
 * estáticas que podem vir de uma chamada http e atualizar o rendimento da posição baseado no valor do preço atual que é atualizado
 * em tempo real, por exemplo(Dentro do [] se encontra o nome de onde poderia vir esses dados):
 *
 * (Preço atual do ativo[socket] - Preço média de posição[bff]) * Quantidade[bff]
 *
 * Essa função utiliza o big.js para garantir precisão com valores float.
 *
 * @param {number} priceValue - Preço atual do ativo
 * @param {number} averagePriceValue - Preço médio da posição
 * @param {number} quantityValue - Quantidade
 * @return {number} Número float com precisão, mas pode estar arredondado
 */
export function calculateProfitPrice(
  priceValue: number,
  averagePriceValue: number,
  quantityValue: number
) {
  const price = new Big(priceValue);
  const averagePrice = new Big(averagePriceValue);
  const quantity = new Big(quantityValue);

  const newProfitPrice = price.minus(averagePrice).mul(quantity).toNumber();

  return newProfitPrice;
}
