
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Heading, HelperView } from '@warrenbrasil/nebraska-web';
import { IResumeCardItem, ResumeCard } from '../../components/ResumeCard';
import { Footer } from '../../components/FooterBtnContainer';
import { InvestmentTransactionNavbar } from '@/modules/transaction/views/InvestmentTransaction/components/InvestmentTransactionNavbar';
import {
  BffWithdraw,
  IExecuteRedemptionRequest
} from '../../services/withdraw';
import { namespace } from 'vuex-class';
import { ILastRedeemInfoPayload } from '../../store/redeem/redeem-types';
import {
  isWarrenManaged,
  isImpersonate
} from '@/modules/common/services/partner';
import { getBrand, getUserB2B } from '@/modules/common/services/local-storage';
import {
  trackViewLoad,
  trackRedeemSummaryGrossValue,
  trackRedeemSummaryNetValue,
  trackErrorView,
  trackErrorHandler,
  trackConfirmRedeem
} from './track-events';

const customerModule = namespace('CustomerModule');
const redeemModule = namespace('RedeemModule');

@Component({
  components: {
    Heading,
    InvestmentTransactionNavbar,
    ResumeCard,
    Footer,
    HelperView
  }
})
export default class WealthProductWithdrawReview extends Vue {
  @customerModule.Getter('getCustomerId')
  private customerId?: string;

  @redeemModule.Getter('lastRedeemData')
  private lastRedeemPortfolioInfo!: ILastRedeemInfoPayload;

  @Prop({ type: String, required: true })
  private readonly portfolioId!: string;

  public isLoadingWithdraw = false;
  public errorExecuteWithdraw = false;
  private readonly bff = new BffWithdraw();

  mounted() {
    trackViewLoad();
    trackRedeemSummaryGrossValue(this.summaryPreview!.grossValue.value);
    trackRedeemSummaryNetValue(this.summaryPreview!.netValue.value);
  }

  public async executeWithdraw() {
    if (this.errorExecuteWithdraw) {
      trackErrorHandler();
    } else {
      trackConfirmRedeem();
    }

    this.isLoadingWithdraw = true;
    this.errorExecuteWithdraw = false;
    try {
      const { executeRedemption } = await this.bff.executeRedemption(
        this.executePayload
      );
      this.$router.push({
        name: 'wealthProductWithdrawalSuccess',
        params: { id: this.portfolioPreview.id },
        query: { transactionId: executeRedemption.requestApiId }
      });
    } catch (err) {
      this.errorExecuteWithdraw = true;
      trackErrorView();
    } finally {
      this.isLoadingWithdraw = false;
    }
  }

  public onBackClick() {
    this.$router.back();
  }

  get executePayload() {
    let payload: IExecuteRedemptionRequest = {
      portfolioId: this.portfolioId,
      customerId: this.customerId || '',
      products: this.lastRedeemPortfolioInfo.products.map(item => ({
        productApiId: item.id,
        amount: item.amount
      }))
    };
    if (isImpersonate()) {
      payload = {
        ...payload,
        partnerId: this.partnerId
      };
    }
    return payload;
  }

  get partnerId() {
    const partner = getUserB2B();
    return partner?._id || '';
  }

  get isButtonDisabled() {
    return this.isLoadingWithdraw;
  }

  get showSubmitButton() {
    return !this.errorExecuteWithdraw;
  }

  get showErrorFeedback() {
    return this.errorExecuteWithdraw;
  }

  get productsPreview() {
    return this.lastRedeemPortfolioInfo.products;
  }

  get portfolioPreview() {
    return this.lastRedeemPortfolioInfo.portfolio;
  }

  get summaryPreview() {
    return this.lastRedeemPortfolioInfo.summaryPreview;
  }

  get brandName() {
    if (isWarrenManaged()) {
      return 'Warren';
    }
    const brand = getBrand();
    return brand ? brand.name : 'Warren';
  }

  get getSummaryFormatted(): IResumeCardItem[] {
    return [
      {
        title: 'Resumo',
        nestedItems: [
          {
            label: 'Total bruto solicitado',
            value: this.summaryPreview!.grossValue.formatted
          },
          {
            label: 'IOF',
            value: this.summaryPreview!.iof.formatted
          },
          {
            label: 'IR',
            value: this.summaryPreview!.ir.formatted
          },
          {
            label: 'Taxa de Gestão',
            value: this.summaryPreview!.brandTax.formatted
          }
        ],
        total: {
          label: 'Total líquido estimado',
          value: this.summaryPreview!.netValue.formatted
        }
      }
    ];
  }

  get getMovimentationFormatted(): IResumeCardItem[] {
    return [
      {
        title: 'Movimentação',
        nestedItems: [
          { label: 'Origem', value: this.portfolioPreview!.name },
          { label: 'Destino', value: 'Conta Digital' }
        ]
      }
    ];
  }

  get getProductsFormatted(): IResumeCardItem[] {
    return this.productsPreview!.map(product => ({
      title: product.name,
      nestedItems: [
        {
          label: 'Liquidação',
          value: product.liquidationDate
        },
        { label: 'Valor bruto', value: product.grossValue.formatted },
        { label: 'IOF', value: product.iof.formatted },
        { label: 'IR', value: product.ir.formatted },
        { label: 'Taxa de Gestão', value: product.brandTax.formatted },
        { label: 'Valor líquido estimado', value: product.netValue.formatted }
      ]
    }));
  }
}
