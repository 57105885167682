import { IStatementTransaction } from '@/modules/transaction/services/unified-account/types';

export interface IStatementTransactions extends Partial<IStatementTransaction> {
  type?: string;
  leftText?: string;
  rightText?: string;
}

export interface IFilters {
  datePeriods: IFilterItem[];
  modules: IFilterItem[];
  types: IFilterItem[];
}

export interface IFilterItem {
  default?: boolean;
  description: string;
  key: string;
  isActive?: boolean;
}

export interface IFiltersValue {
  period: string;
  module: string;
  movementType: string;
}

export interface IReceiptModalData {
  title: string;
  head: string;
  subtitle: string;
  buttonLabel?: string;
  href?: string;
  items: IReceiptItem[];
}

export interface IReceiptItem {
  title: string;
  value: string;
}

export enum ITransactionType {
  Statement = 'statement',
  FutureTransactions = 'future_transactions'
}
