import { StringFormatted } from '@/modules/trade/types';

export enum StockLendingFiltersTypes {
  Type = 'Type'
}

export interface IStockLendingFilters {
  [StockLendingFiltersTypes.Type]: {
    selected: string | null;
    available: StringFormatted[];
  };
}
