import { httpClient } from '@/modules/common/services/http/http-client';
import {
  RLPPageData,
  UpdateRLPStatusValues,
  UpdateRLPStatusResponse
} from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getRLPStatus(): Promise<RLPPageData> {
  return httpClientWithDefaultOptions.get('/getRLPStatus');
}

export function updateRLPStatus(
  status: UpdateRLPStatusValues
): Promise<UpdateRLPStatusResponse> {
  return httpClientWithDefaultOptions.put(
    '/updateRLPStatus',
    {},
    {
      params: { status }
    }
  );
}
