
import { Component, Vue } from 'vue-property-decorator';

import { Row, BaseIcon } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    Row,
    BaseIcon
  }
})
export default class DropdownEmptyState extends Vue {
  private NebraskaColors = NebraskaColors;
}
