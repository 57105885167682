import { render, staticRenderFns } from "./RetailLiquidityProvider.vue?vue&type=template&id=38073cde&scoped=true&"
import script from "./RetailLiquidityProvider.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RetailLiquidityProvider.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38073cde",
  null
  
)

export default component.exports