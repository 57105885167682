import { AllocationType } from '@/types/models/Portfolio';

export enum AllocationTypeColor {
  FIXED_INCOME = '#006BD6',
  VARIABLE_INCOME = '#FFB500',
  STOCKS = '#FFB500',
  OTHERS = '#EE2E5D',
  MULTIMARKET = '#EF6696',
  NOT_ALLOCATED = '#B9BAC7'
}

export enum TypeColorClass {
  FIXED_INCOME = 'wrn-color-8',
  VARIABLE_INCOME = 'wrn-color-yellow',
  STOCKS = 'wrn-color-yellow',
  OTHERS = 'wrn-color-main',
  MULTIMARKET = 'wrn-color-2',
  NOT_ALLOCATED = 'wrn-color-gray'
}

export const getColorByType = (type: AllocationType) => {
  const types = Object.values(AllocationType);
  const typesKeys = Object.keys(AllocationType);

  const lowerCaseTypes: { [prop: string]: string } = {};

  types.forEach((item, index) => {
    lowerCaseTypes[item.toLowerCase()] = typesKeys[index];
  });

  const typeValue: any = lowerCaseTypes[type.toLowerCase()];

  return {
    // @ts-ignore
    colorClass: TypeColorClass[typeValue],
    // @ts-ignore
    colorHex: AllocationTypeColor[typeValue]
  };
};
