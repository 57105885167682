
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { DataTable, HelperViewStatus } from '@warrenbrasil/nebraska-web';
import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';
import { OrderStatusCell } from './components/OrderStatusCell';
import { OrdersTableEmptyState } from './components/OrdersTableEmptyState';

import { Order } from '@/modules/trade/services/orders';
import { FetchingOrdersStatus } from '@/modules/trade/views/Orders/types';
import * as trackOrdersEvents from '@/modules/trade/views/Orders/trackers';

@Component({
  components: {
    DataTable,
    OrdersTableEmptyState,
    HelperViewStatus
  }
})
export default class OrdersTable extends Vue {
  @Prop({ type: Array, required: true })
  readonly orders!: Order[];

  @Prop({
    type: String,
    validator(statusName: FetchingOrdersStatus) {
      const validStatus = Object.values(FetchingOrdersStatus);
      return validStatus.includes(statusName);
    },
    required: true
  })
  readonly status!: FetchingOrdersStatus;

  @Prop({ type: Number, required: true })
  readonly itemsPerPage!: number;

  @Prop({ type: Boolean, required: true })
  readonly hasFilterSelected!: boolean;

  @Emit('on-order-click')
  emitOnOrderClick(order: Order) {
    trackOrdersEvents.trackOrdersTableRowClick(
      order.type.formatted,
      order.status.formatted
    );
  }

  @Emit('on-retry')
  emitOnRetry() {
    trackOrdersEvents.trackOrdersViewErrorRetryClick();
  }

  @Emit('on-clear-filters')
  emitOnClearFilters() {
    trackOrdersEvents.trackOrdersViewEmptyStateClearFiltersClick();
  }

  readonly FetchingOrdersStatus = FetchingOrdersStatus;

  readonly columns = [
    {
      align: 'left',
      component: InstrumentNameCell,
      getter: (order: Order) => ({
        title: order.description,
        symbol: order.instrument.symbol,
        name: order.instrument.name,
        type: order.instrument.type?.value
      }),
      label: 'Descrição',
      width: '19%'
    },
    {
      align: 'right',
      getter: (order: Order) => String(order.quantity.formatted),
      label: 'Quantidade',
      width: '8%'
    },
    {
      align: 'right',
      getter: (order: Order) => order.pricePerShare.formatted,
      label: 'Preço (R$)',
      width: '10%'
    },
    {
      align: 'right',
      getter: (order: Order) => order.movementTotalPrice.formatted,
      label: 'Valor da movimentação',
      width: '18%'
    },
    {
      align: 'right',
      getter: (order: Order) => order.executedAt.long,
      label: 'Data de lançamento',
      width: '15%'
    },
    {
      align: 'right',
      getter: (order: Order) => order.liquidatedAt.long,
      label: 'Data de liquidação',
      width: '15%'
    },
    {
      align: 'right',
      component: OrderStatusCell,
      getter: (order: Order) => ({
        status: order.status.value,
        label: order.status.formatted
      }),
      label: 'Status',
      width: '12%'
    },
    {
      type: 'icon',
      getter: (order: Order) => ({
        icon: 'EA0170',
        ariaLabel: `Detalhes da ordem de ${order.description}`
      }),
      width: '3%'
    }
  ];
}
