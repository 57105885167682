import { enums, lists, models } from '@warrenbrasil/resources';
import Strings from '@warrenbrasil/dictionary/build/values/resources.json';
import { vsprintf } from 'sprintf-js';

// Definindo tipo genérico para o JSON de strings
const strings = Strings as Record<string, string>;

export function getString(key: string, stringArgs?: any[]) {
  let treatedString = strings[key];

  if (treatedString) {
    treatedString = treatedString.replace('%%', '%');
  }

  // Todos os argumentos ( como %d, %e ) são tratados com esse array de argumentos opcional
  if (stringArgs) {
    treatedString = vsprintf(treatedString, stringArgs);
  }

  return treatedString;
}

export function getEnum(key: string) {
  return enums[key];
}

export function getEnumOptions(key: string) {
  try {
    return Object.entries(getEnum(key)).map(([value, label]) => ({
      label,
      value
    }));
  } catch (error) {
    return [];
  }
}

export function getList(key: string) {
  return lists[key];
}

export function getModel(key: string) {
  return models[key];
}
