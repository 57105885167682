
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash-es';
import { Row, BaseText, InputMoney } from '@warrenbrasil/nebraska-web';
import { MatchMedia } from '@/modules/common/components/MatchMedia';

@Component({
  components: { Row, BaseText, MatchMedia, InputMoney }
})
export default class ProductToInvestRow extends Vue {
  @Prop({ type: String, required: false })
  readonly errorMessage?: string;

  @Prop({ type: Boolean, required: false, default: true })
  readonly isAvailable?: boolean;

  @Prop({ type: String, required: true })
  readonly minToInvest!: string;

  @Prop({ type: String, required: true })
  readonly productName!: string;

  @Prop({ type: String, required: true })
  readonly productId!: string;

  public valueToInvest = 0;

  public debouncedUpdateValue = debounce(this.updateValue, 500);

  public get inputValue() {
    return this.valueToInvest > 0 ? this.valueToInvest : undefined;
  }

  @Emit()
  updateValue(value: number) {
    this.valueToInvest = value;
    return { id: this.productId, value: this.valueToInvest };
  }
}
