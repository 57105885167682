
import { Component, Vue, Prop, InjectReactive } from 'vue-property-decorator';

import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';
import { InstrumentPriceCell } from '@/modules/trade/components/InstrumentPriceCell';

import { HelperViewContextual, DataTable } from '@warrenbrasil/nebraska-web';
import {
  Instrument,
  InstrumentHistorical
} from '@/modules/trade/services/instruments';
import { trackInstrumentClick } from '@/modules/trade/views/TradePositionsView/trackers';
import { columns } from './columns';
import {
  ICashMarketFilters,
  CashMarketFiltersDateValues,
  CashMarketFiltersTypes
} from '../../types';

@Component({
  components: {
    DataTable,
    InstrumentNameCell,
    InstrumentPriceCell,
    HelperViewContextual
  }
})
export default class InstrumentsTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly items!: Instrument[] | InstrumentHistorical[];

  @Prop({ type: Number, required: true })
  readonly itemsPerPage!: number;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @InjectReactive('filters')
  readonly filters!: ICashMarketFilters;

  readonly CashMarketFiltersTypes = CashMarketFiltersTypes;

  get tables() {
    return [
      {
        type: CashMarketFiltersDateValues.Today,
        columns: columns[CashMarketFiltersDateValues.Today]
      },
      {
        type: CashMarketFiltersDateValues.Historical,
        columns: columns[CashMarketFiltersDateValues.Historical]
      }
    ];
  }

  get tableCurrentStatus() {
    return this.isLoading ? 'loading' : 'default';
  }

  handleOnRowClick(instrument: Instrument) {
    trackInstrumentClick(
      instrument.symbol,
      instrument.name || '',
      instrument.type?.formatted || ''
    );
    this.$router.push({
      name: 'tradeInstrument',
      params: { ticker: instrument.symbol }
    });
  }
}
