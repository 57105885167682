
import { Component, Vue, Prop } from 'vue-property-decorator';

import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';
import { InstrumentPriceCell } from '@/modules/trade/components/InstrumentPriceCell';

import { HelperViewContextual, DataTable } from '@warrenbrasil/nebraska-web';
import { StockLendingPosition } from '@/modules/trade/services/instruments';
import { columns } from './columns';

@Component({
  components: {
    DataTable,
    InstrumentNameCell,
    InstrumentPriceCell,
    HelperViewContextual
  }
})
export default class StockLendingTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly items!: StockLendingPosition[];

  @Prop({ type: Number, required: true })
  readonly itemsPerPage!: number;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  readonly columns = columns;

  get tableCurrentStatus() {
    return this.isLoading ? 'loading' : 'default';
  }

  handleOnRowClick({ symbol }: StockLendingPosition) {
    this.$router.push({
      name: 'tradeInstrument',
      params: { ticker: symbol }
    });
  }
}
