
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BaseText, BaseIcon } from '@warrenbrasil/nebraska-web';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    BaseIcon,
    BaseText
  }
})
export default class DropdownInputValueItem extends Vue {
  @Prop({ type: String, required: true })
  private inputValue!: string;

  private NebraskaColors = NebraskaColors;

  private handleClick() {
    this.$emit('input-value-click', this.inputValue);
  }
}
