import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import {
  INotification,
  getNotificationsCount,
  getNotifications,
  markAsRead
} from '@/modules/common/services/http/notification';

export interface NotificationsModuleType {
  getUnreadNotificationsCount: number;
  getUnreadNotifications: INotification[];
  fetchNotificationsCount: () => Promise<void>;
  fetchNotifications: () => Promise<void>;
}

@Module({
  namespaced: true,
  name: 'NotificationsModule'
})
export class NotificationsStoreModule
  extends VuexModule
  implements NotificationsModuleType
{
  private unreadNotificationsCount = 0;
  private unreadNotifications: INotification[] = [];

  public get getUnreadNotificationsCount(): number {
    return this.unreadNotificationsCount;
  }

  public get getUnreadNotifications(): INotification[] {
    return this.unreadNotifications;
  }

  @Mutation
  private setUnreadNotificationsCount(value: number) {
    this.unreadNotificationsCount = value;
  }

  @Mutation
  private setUnreadNotifications(value: INotification[]) {
    this.unreadNotifications = value;
  }

  @Action
  public async fetchNotificationsCount() {
    const { unreadLogsCount } = await getNotificationsCount();
    this.context.commit('setUnreadNotificationsCount', unreadLogsCount);
  }

  @Action
  public async fetchNotifications() {
    let logs: INotification[] = [];

    try {
      const data = await getNotifications();
      logs = data.logs as INotification[];
    } catch (err) {
      console.error(err);
    }
    this.context.commit('setUnreadNotifications', logs);
  }

  @Action
  public async markNotificationsHasRead(ids: string[]) {
    await markAsRead(ids)
      .then(() => {
        this.fetchNotifications();
        this.fetchNotificationsCount();
      })
      .catch(err => {
        console.error(err);
      });
  }
}
