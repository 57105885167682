
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Heading } from '@warrenbrasil/nebraska-web';
import { Columns } from '@/modules/common/components/__deprecated__/Columns';
import { PortfolioCard } from '../PortfolioCard';
import { PortfolioCategories } from '@/types/models';
import { IPortfolioList } from '@/types/models/Portfolio';
import { trackViewPortfolio } from './track-events';

@Component({
  components: { Columns, Heading, PortfolioCard }
})
export default class PortfoliosList extends Vue {
  @Prop({ type: Array, required: true })
  private portfolios!: IPortfolioList[];

  private trackEvent(portfolioName: string) {
    trackViewPortfolio(portfolioName);
  }

  getLocation(portfolio: IPortfolioList) {
    if (portfolio.type === PortfolioCategories.VariableIncome) {
      return {
        name: 'wealthIndexPortfolio',
        params: { portfolioId: portfolio.id }
      };
    } else {
      return { name: 'wealthPortfolio', params: { id: portfolio.id } };
    }
  }
}
