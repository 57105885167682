import { NumberFormatted, StringFormatted } from '@/modules/trade/types/bff';

export interface MarketData {
  ticker: string;
  price?: NumberFormatted;
  date?: StringFormatted;
  bestPriceBuy?: NumberFormatted;
  bestPriceSell?: NumberFormatted;
  dailyVariation?: NumberFormatted;
  high?: NumberFormatted;
  low?: NumberFormatted;
  open?: NumberFormatted;
  close?: NumberFormatted;
  volume?: NumberFormatted;
  totalBalance?: NumberFormatted;
  totalEarnings?: NumberFormatted;
}

export interface IInstrumentStatus {
  value: ETradeMarketDataSymbolStatus;
  message: string;
}

export enum ETradeMarketDataSymbolStatus {
  Opened = 'Opened',
  Suspended = 'Suspended',
  Frozen = 'Frozen',
  Inhibited = 'Inhibited',
  Auctioned = 'Auctioned',
  OpenReprogramed = 'OpenReprogramed',
  Closed = 'Closed',
  Unknown = 'Unknown',
  NightProcess = 'NightProcess',
  Preparation = 'Preparation',
  PreClosing = 'PreClosing',
  Promoter = 'Promoter',
  EODConsulting = 'EODConsulting',
  PreOpening = 'PreOpening',
  AfterMarket = 'AfterMarket',
  Trading = 'Trading',
  Impedido = 'Impedido',
  Bovespa = 'Bovespa',
  Interrupted = 'Interrupted',
  None = 'None'
}

export interface InvokeData {
  args: any[];
  hubMethod: string;
}
