import { Route } from 'vue-router';

interface Position {
  x: number;
  y: number;
}

const defaultPosition: Position = {
  x: 0,
  y: 0
};

export const scrollBehavior = (
  position: null | void | Position,
  to?: Route
) => {
  if (to?.meta?.scroll === false) return;
  return position ?? defaultPosition;
};
