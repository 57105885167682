export enum TrackSubscriptionPageActions {
  reserve_asset = 'reserve_asset',
  confirm_asset_reservation = 'confirm_asset_reservation',
  change_asset_reservation = 'change_asset_reservation',
  cancel_asset_reservation = 'cancel_asset_reservation'
}

export enum TrackingCancelReserveModalActions {
  keep_reservation = 'keep_reservation',
  confirm_cancel_reservation = 'confirm_cancel_reservation'
}

export enum TrackingSubscriptionPopUpActions {
  reserve = 'reserve',
  close = 'close'
}
