import { render, staticRenderFns } from "./StrategyDetail.vue?vue&type=template&id=6a36b492&scoped=true&"
import script from "./StrategyDetail.vue?vue&type=script&lang=ts&"
export * from "./StrategyDetail.vue?vue&type=script&lang=ts&"
import style0 from "./StrategyDetail.vue?vue&type=style&index=0&id=6a36b492&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a36b492",
  null
  
)

export default component.exports