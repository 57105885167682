
import { moneyFormat } from '@/modules/common/helpers/currency';
import { Component, Vue, Prop, InjectReactive } from 'vue-property-decorator';
import { BaseText, Row } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { TransactionBadge } from '@/modules/common/components/TransactionBadge';
import { trackHelpSubscriptionPage } from '@/modules/trade/views/Subscription/tracker';
import { HelpButton } from '@/modules/trade/components/HelpButton';
import { InstrumentValue } from '../../types';
import { SubscribeStatus } from '@/modules/trade/services/subscription/enums';

@Component({
  components: {
    HelpButton,
    BaseText,
    Row,
    TransactionBadge
  }
})
export default class SubscriptionDetail extends Vue {
  @InjectReactive()
  readonly subscriptionStatus!: SubscribeStatus;

  @InjectReactive()
  readonly subscriptionStatusMessage!: string;

  @Prop({ type: Number, default: 0 })
  readonly subscriptionQuantity!: number;

  @Prop({ type: Number, default: 0 })
  readonly availableQuantity!: number;

  @Prop({ type: Object, required: true })
  readonly instrumentValue!: InstrumentValue;

  get NebraskaColors() {
    return NebraskaColors;
  }

  get SubscribeStatus() {
    return SubscribeStatus;
  }

  get totalValue() {
    const subscribedTotalValue = moneyFormat(
      this.subscriptionQuantity * this.instrumentValue.value
    );
    const unsubscribedTotalValue = moneyFormat(
      this.availableQuantity * this.instrumentValue.value
    );
    return this.isActiveNotSubscribed
      ? unsubscribedTotalValue
      : subscribedTotalValue;
  }

  get isActiveNotSubscribed() {
    return this.subscriptionStatus === SubscribeStatus.unsubscribed;
  }

  get isActiveSubscribed() {
    return this.subscriptionStatus === SubscribeStatus.subscribed;
  }

  public trackSubscriptionOrderHelpButton() {
    trackHelpSubscriptionPage();
  }
}
