import { BaseHttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';
import { Transfer, CreateTransfer } from './transfer-types';

export default class TransfersAPI extends BaseHttpClient {
  public getTransfer(id: string): Promise<Transfer> {
    return this.get<Transfer>(`/api/v2/transfers/${id}`);
  }

  public cancelTransfer(id: string) {
    return this.put(`/api/v2/transfers/${id}/cancel`);
  }

  public createTransfer(data: CreateTransfer) {
    return this.post<Transfer>('/api/v2/transfers/create', data);
  }
}
