
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import {
  SkeletonLoader,
  HelperViewStatus,
  HeadingDisplay,
  BaseText
} from '@warrenbrasil/nebraska-web';
import { Money } from '@/modules/common/components/Money';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ErrorHandler } from '@/modules/common/components/ErrorHandler';

@Component({
  components: {
    SkeletonLoader,
    HelperViewStatus,
    ErrorHandler,
    HeadingDisplay,
    BaseText,
    Money
  }
})
export default class WealthHeading extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: String, required: true })
  readonly head!: string;

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly subtitle!: string;

  @Prop({ type: String, default: 'medium' })
  readonly size!: string;

  @Prop({ type: Boolean, default: false })
  readonly hasError!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Emit('retry')
  emitRetry() {}
}
