
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { NavBar, RoundedIcon } from '@warrenbrasil/nebraska-web';

@Component({
  name: 'InvestmentTransactionNavbar',
  components: { NavBar, RoundedIcon }
})
export default class InvestmentTransactionNavbar extends Vue {
  @Prop({ type: String, default: 'Investir' })
  private title!: string;

  @Prop({ type: Boolean, default: false })
  private isBackButtonVisible!: boolean;

  @Prop({ type: Boolean, default: true })
  private isCloseButtonVisible!: boolean;

  @Prop({ type: String, required: true })
  private closeButtonAriaLabel!: string;

  private get NebraskaColors() {
    return NebraskaColors;
  }

  private emitBackEvent() {
    this.$emit('on-back');
  }

  private emitCloseEvent() {
    this.$emit('on-close');
  }
}
