
import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator';
import { debounce, throttle } from 'lodash-es';
import * as vClickOutside from 'v-click-outside-x';

import { Input } from '@warrenbrasil/nebraska-web';
import { Dropdown, IDropdownItem, dropdownItemTypes } from '../Dropdown';

import {
  getSuggestedInstruments,
  SuggestedInstrument,
  ISuggestedInstruments
} from '@/modules/trade/services/search';
import {
  trackSearchInstruments,
  trackSearchInstrumentClick
} from '@/modules/trade/views/SearchView/trackers';

@Component({
  components: {
    Input,
    Dropdown
  },
  directives: { ClickOutside: vClickOutside.directive }
})
export default class AutoComplete extends Vue {
  @Prop({ type: Array, required: true })
  private recentInstruments!: IDropdownItem[];

  @Ref()
  private autoComplete!: HTMLDivElement;

  @Ref()
  private inputSearch!: Vue;

  private inputValue = '';
  private searchValue = '';
  private showDropdown = false;
  private searchResults: IDropdownItem[] | undefined = [];
  private searchError = false;
  private searchLoading = false;
  private currentItemIndex = -1;

  @Watch('inputValue')
  private watchInputValue(inputValue: string) {
    if (inputValue.length > 0) {
      this.showDropdown = true;
    }
    if (inputValue.length < 3) {
      this.clearSearchResults();
    }
  }

  private clearSearchResults() {
    this.searchResults = undefined;
  }

  private onInputChange = debounce(this.fetchSuggestedInstruments, 500);

  private async fetchSuggestedInstruments() {
    if (this.inputValue.length >= 3) {
      this.searchLoading = true;
      try {
        const instrumentTypes = await getSuggestedInstruments(this.inputValue);
        this.searchResults =
          this.getFormattedSuggestedInstruments(instrumentTypes);
      } catch {
        this.searchError = true;
      } finally {
        this.searchLoading = false;
        this.setInitialItemIndex();
      }
    }
  }

  private getFormattedSuggestedInstruments(
    instrumentsByType: ISuggestedInstruments[]
  ) {
    let items: IDropdownItem[] = [];

    instrumentsByType.forEach(instrumentType => {
      const section = {
        type: dropdownItemTypes.section,
        data: {
          ...instrumentType
        }
      };
      const instruments = instrumentType.instruments.map(instrument => ({
        type: dropdownItemTypes.option,
        data: {
          ...instrument
        }
      }));
      items = [...items, section, ...instruments];
    });

    return items;
  }

  private onSearchFocus() {
    this.showDropdown = true;
  }

  private focusInput() {
    this.inputSearch.$el.querySelector('input')?.focus();
    this.setInitialItemIndex();
  }

  private keyboardNavigationThrottled = throttle(
    this.handleKeyboardNavigation,
    100
  );

  private getInputValueEl() {
    const inputValueEl = this.autoComplete.querySelector(
      '#dropdown-input-value'
    ) as HTMLButtonElement | undefined;

    return inputValueEl;
  }

  private focusFirstDropdownItem() {
    const inputValueEl = this.getInputValueEl();
    const firstOptionEl = this.autoComplete.querySelector(
      '#dropdown-option'
    ) as HTMLButtonElement | undefined;

    if (inputValueEl) {
      inputValueEl?.focus();
      this.setInitialItemIndex();
    } else {
      firstOptionEl?.focus();
      this.currentItemIndex = 0;
    }
  }

  private handleKeyboardNavigation(event: KeyboardEvent) {
    // eslint-disable-next-line no-undef
    const elements = this.autoComplete.querySelectorAll(
      '#dropdown-option'
    ) as NodeListOf<HTMLElement>;

    if (event.code === 'ArrowDown') {
      const nextElement = elements[this.currentItemIndex + 1];

      if (nextElement) {
        nextElement.focus();
        this.currentItemIndex++;
      } else {
        this.focusFirstDropdownItem();
      }
    } else if (event.code === 'ArrowUp') {
      const previousElement = elements[this.currentItemIndex - 1];
      const inputValueEl = this.getInputValueEl();

      if (previousElement) {
        previousElement.focus();
        this.currentItemIndex--;
      } else if (this.currentItemIndex !== -1 && inputValueEl) {
        inputValueEl.focus();
        this.setInitialItemIndex();
      } else {
        this.focusInput();
        this.setInitialItemIndex();
      }
    }
  }

  private closeDropdown() {
    this.showDropdown = false;
    this.inputValue = '';
    this.setInitialItemIndex();
  }

  private setInitialItemIndex() {
    this.currentItemIndex = -1;
  }

  private goToSearchList() {
    trackSearchInstruments(this.inputValue);
    this.$router.push({
      name: 'tradeSearchList',
      params: { ticker: this.inputValue }
    });
  }

  private clearInput() {
    this.inputValue = '';
  }

  private get isRecentsDropdown() {
    return this.inputValue === '' && this.recentInstruments.length !== 0;
  }

  private onDropdownOptionClick(instrument: SuggestedInstrument) {
    trackSearchInstrumentClick(
      instrument.symbol,
      instrument.description,
      this.inputValue
    );
    this.$router.push({
      name: 'tradeInstrument',
      params: { ticker: instrument.symbol }
    });
  }

  private get computedHasInputValue() {
    return this.inputValue.length > 0;
  }
}
