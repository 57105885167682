
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { HelperView } from '@warrenbrasil/nebraska-web';
import { OrderStatus } from '@/modules/trade/types';

@Component({
  components: {
    HelperView
  }
})
export default class StatusIntrumentV2 extends Vue {
  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly description!: string;

  @Prop({ type: String, required: true })
  readonly orderStatus!: OrderStatus;

  @Emit('click')
  public click() {}

  get icon() {
    return this.orderStatus === OrderStatus.Filled ? 'EF0070' : 'EF0060';
  }

  get type() {
    switch (this.orderStatus) {
      case OrderStatus.Working:
        return 'alert';
      case OrderStatus.Rejected:
        return 'negative';
      case OrderStatus.Filled:
        return 'positive';
      default:
        return 'negative';
    }
  }
}
