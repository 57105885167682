
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { BaseText } from '@warrenbrasil/nebraska-web';
import { IStep } from './stepper-types';

@Component({
  components: { BaseText }
})
export default class Stepper extends Vue {
  public readonly NebraskaColors = NebraskaColors;
  @Prop({ type: Array, required: true })
  public readonly steps!: IStep[];
}
