import EmailFormStep from '../components/EmailFormStep/EmailFormStep.vue';
import PasswordCreation from '../components/PasswordCreation/PasswordCreation.vue';
import PasswordSignIn from '../components/PasswordSignIn/PasswordSignIn.vue';
import { SignInFormSteps } from '../types';

export const SignInFormStepComponents = {
  [SignInFormSteps.Email]: EmailFormStep,
  [SignInFormSteps.PasswordSignIn]: PasswordSignIn,
  [SignInFormSteps.PasswordCreation]: PasswordCreation
};
