
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { InstrumentRow } from '@/modules/trade/components/InstrumentRow';
import { InfiniteScroll } from '@warrenbrasil/nebraska-web';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import {
  getWatchlist,
  InstrumentWithWatchlist
} from '@/modules/trade/services/instruments';
import { trackInstrumentWatchlistClick } from '@/modules/trade/views/TradeView/trackers';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    InfiniteScroll,
    InstrumentRow
  }
})
export default class WatchListInfiniteScroll extends Vue {
  @tradePositionsModule.Getter('getWatchlist')
  readonly watchlistItems!: InstrumentWithWatchlist[];

  private page = 1;
  readonly NebraskaColors = NebraskaColors;

  async fetchWatchlist() {
    try {
      this.page++;

      const data = await getWatchlist({
        page: this.page,
        itemsPerPage: 5
      });

      return data?.items || [];
    } catch (error) {
      console.error(error);
    }
  }

  onInstrumentClick(instrument: InstrumentWithWatchlist) {
    trackInstrumentWatchlistClick(instrument.symbol, instrument.name || '');
    this.$router.push({
      name: 'tradeInstrument',
      params: { ticker: instrument.symbol }
    });
  }
}
