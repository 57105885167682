
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SkeletonLoader } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    SkeletonLoader
  }
})
export default class MainLoading extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isRightLoading!: boolean;
}
