
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash-es';
import { Row, BaseText, InputMoney, Heading } from '@warrenbrasil/nebraska-web';
import { MatchMedia } from '@/modules/common/components/MatchMedia';
import { IWithdrawProduct } from '@/modules/wealth/services/withdraw';
import { ProductToWithdrawCol } from '../ProductToWithdrawCol';

@Component({
  components: {
    Row,
    Heading,
    BaseText,
    MatchMedia,
    InputMoney,
    ProductToWithdrawCol
  }
})
export default class ProductToWithdrawRow extends Vue {
  @Prop({ type: String, required: false })
  readonly errorMessage?: string;

  @Prop({ type: Object, required: true })
  readonly product!: IWithdrawProduct;

  public valueToInvest = 0;

  public debouncedUpdateValue = debounce(this.updateValue, 500);

  public get inputValue() {
    if (this.product.valueToInvest) return this.product.valueToInvest;
    return this.valueToInvest > 0 ? this.valueToInvest : undefined;
  }

  private get totalGrossWorthAvailable() {
    return this.product.totalGrossWorthAvailable.formatted || ' - ';
  }

  private get minimumFinancialMovement() {
    return this.product.minimumFinancialMovement.formatted || ' - ';
  }

  private get minimumBalance() {
    return this.product.minimumBalance.formatted || ' - ';
  }

  private get daysToRedeem() {
    return `${this.product.daysToRedeem} dias úteis` || ' - ';
  }

  @Emit()
  updateValue(value: number) {
    this.valueToInvest = value;
    return { ...this.product, valueToInvest: this.valueToInvest };
  }
}
