import { EObjectiveSteps } from './types';
import { ObjectiveName } from '@/modules/catalog/views/FundsAcquisition/components/ObjectiveName';
import { Period } from '@/modules/catalog/views/FundsAcquisition/components/Period';
import { Profile } from '@/modules/catalog/views/FundsAcquisition/components/Profile';
import { Risk } from '@/modules/catalog/views/FundsAcquisition/components/Risk';
import { Preview } from '@/modules/catalog/views/FundsAcquisition/components/Preview';
import { Template } from '@/modules/catalog/views/FundsAcquisition/components/Template';
import { Conclusion } from '@/modules/catalog/views/FundsAcquisition/components/Conclusion';

export const ObjectiveBuilderComponents = {
  [EObjectiveSteps.NAME]: ObjectiveName,
  [EObjectiveSteps.PERIOD]: Period,
  [EObjectiveSteps.PROFILE]: Profile,
  [EObjectiveSteps.RISK]: Risk,
  [EObjectiveSteps.PREVIEW]: Preview,
  [EObjectiveSteps.TEMPLATE]: Template,
  [EObjectiveSteps.CONCLUSION]: Conclusion
};
