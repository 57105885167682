
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  BaseText,
  Checkbox,
  Modal,
  ModalButton
} from '@warrenbrasil/nebraska-web';
import { getString } from '@/modules/common/helpers/resources';

@Component({ components: { BaseText, Checkbox, Modal, ModalButton } })
export default class QualifiedInvestor extends Vue {
  @Prop({ type: Boolean, default: false, required: false })
  private value!: boolean;

  private initialValue = false;
  private isChecked = false;
  private modalIsOpen = false;
  private modalTitle = getString('common_field_qualifiedinvestor_dialog_title');
  private text = getString('common_field_qualifiedinvestor_label');

  private changeValue() {
    this.$emit('input', this.isChecked);
  }

  private cancel() {
    this.closeModal();
  }

  private openModal() {
    if (this.initialValue) this.modalIsOpen = true;
  }

  private closeModal() {
    this.modalIsOpen = false;
  }

  private confirmation() {
    this.closeModal();
    this.openZendeskChat();
  }

  private openZendeskChat() {
    window.openChat();
  }

  created() {
    this.initialValue = this.value;
    this.isChecked = this.value;
  }
}
