
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import {
  BaseIcon,
  BaseText,
  Row,
  SkeletonLoader,
  ButtonSecondary,
  ContextColorProvider
} from '@warrenbrasil/nebraska-web';

@Component({
  name: 'WalletBalance',
  components: {
    BaseText,
    Row,
    SkeletonLoader,
    BaseIcon,
    ButtonSecondary,
    ContextColorProvider
  }
})
export default class WalletBalance extends Vue {
  @Prop({ type: Boolean, default: true })
  public readonly isLoading!: boolean;

  @Prop({ type: Boolean, default: true })
  public readonly showTotalBtn!: boolean;

  @Prop({ type: String, required: false })
  public readonly totalBalance?: string;

  public get rowDescription() {
    return 'Total bruto disponível';
  }

  @Emit()
  public selectTotal() {}

  public get NebraskaColors() {
    return NebraskaColors;
  }
}
