import { GtmTracker } from '@/modules/common/services/analytics';

const trackTradeEvent = GtmTracker.trackerWithDefaultProps({
  category: 'Trade'
});

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'search_asset_results',
  digital_experience: 'investor',
  experience_section: 'broker'
});

export function trackFilterClick(filter: string) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'header',
    event_name: 'select_filter',
    event_target: filter
  });
}

export function trackSearchListViewLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackInstrumentClick(
  instrumentSymbol: string,
  instrumentName: string
) {
  trackTradeEvent({
    action: `Clique em ${instrumentSymbol}`,
    label: 'Busca de Ativos'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'select_asset',
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol
  });
}

export function trackSearchInstruments(searchText: string, filter: string) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'search_bar',
    event_name: 'view_search_results',
    event_target: filter,
    search_word: searchText
  });
}

export function trackWatchlistButtonClick(
  instrumentSymbol: string,
  instrumentName: string
) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'save_favorite',
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol
  });
}
