import { GtmTracker } from '@/modules/common/services/analytics';

export const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'home_overview',
  digital_experience: 'investor',
  experience_section: 'home'
});

export const trackOnboardingAmplitudeEvent = GtmTracker.trackerWithDefaultProps(
  {
    page_screen: 'home_overview',
    digital_experience: 'investor',
    experience_section: 'onboarding'
  }
);
