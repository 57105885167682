
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseText, RadioButton } from '@warrenbrasil/nebraska-web';

type IValue = boolean | string | number;

@Component({ components: { BaseText, RadioButton } })
export default class CheckboxRadio extends Vue {
  @Prop({ default: () => ['Sim', 'Não'], required: false, type: Array })
  private labels!: string[];

  @Prop({ required: true, type: String }) private name!: string;
  @Prop({ required: false, default: undefined, type: String })
  private title?: string;

  @Prop({ default: false, required: false, type: [Boolean, String, Number] })
  private value!: IValue;

  @Prop({ default: () => [true, false], required: false, type: Array })
  private values!: IValue[];

  private isChecked: IValue = false;

  private get options() {
    return this.values.map((option, i) => ({ option, label: this.labels[i] }));
  }

  private handleSelect(value: IValue) {
    this.$emit('input', value);
  }

  created() {
    this.isChecked = this.value;
  }
}
