var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',{staticClass:"allocation-chart-modal",attrs:{"is-open":_vm.isOpen,"title":"","size":"md"},on:{"close":_vm.emitClose}},[(_vm.loading)?_c('section',{staticClass:"allocation-chart-modal__section"},[_c('HeadingDisplayLoader',{staticClass:"allocation-chart-modal__loader__heading",attrs:{"size":"extra-small","has-head":false}}),_c('SkeletonLoader',[_c('div',{staticClass:"allocation-chart-modal__loader__chart"}),_vm._l((5),function(number){return _c('div',{key:number,staticClass:"allocation-chart-modal__loader__row"})})],2)],1):_c('section',{staticClass:"allocation-chart-modal__section"},[_c('HeadingDisplay',{attrs:{"title":"Alocação em Bolsa","subtitle":_vm.updatedAt ? _vm.updatedAt.formatted : '-',"size":"extra-small"}}),_c('AllocationChart',{staticClass:"allocation-chart-modal__chart",attrs:{"series-data":_vm.seriesDataPieChart,"title":_vm.titleChart,"title-percentage":_vm.titlePercentage,"title-total-value":_vm.titleTotalValue,"error":_vm.error,"selected-asset":_vm.selectedAsset},on:{"select-filter":_vm.selectAssetType}}),(_vm.chartDataAssetTypes && !_vm.error && _vm.hasChartData)?_c('div',{staticClass:"allocation-chart-modal__list"},_vm._l((_vm.chartDataAssetTypes),function(asset,index){return _c('Row',{key:index,staticClass:"allocation-chart-modal__list__item",attrs:{"data-testid":`asset-item-${asset.assetType.value}`,"has-interaction":""},on:{"click":function($event){return _vm.selectAssetType({
            assetType: asset,
            options: {
              label: asset.assetType.formatted,
              value: asset.assetType.value
            }
          })}},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c('div',{staticClass:"allocation-chart-modal__item__status"},[_c('BulletAsset',{staticClass:"allocation-chart-modal__item__status__bullet",attrs:{"color":asset.color || _vm.getChartCategoryColor(asset.assetType.value)}}),_c('BaseText',{staticClass:"allocation-chart-modal__item__status__title",attrs:{"as":"p","size":"lg"}},[_vm._v(" "+_vm._s(asset.assetType.formatted)+" ")])],1)]},proxy:true},{key:"top-right",fn:function(){return [_c('Money',{staticClass:"allocation-chart-modal__list__money",attrs:{"width":"40px"}},[_vm._v(_vm._s(asset.percentage.formatted))])]},proxy:true},{key:"bottom-right",fn:function(){return [_c('Money',{staticClass:"allocation-chart-modal__list__money",attrs:{"width":"110px"}},[_vm._v(_vm._s(asset.allocatedTotal.formatted))])]},proxy:true}],null,true)})}),1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }