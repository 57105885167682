import { GtmTracker } from '@/modules/common/services/analytics';

const trackPositionAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'positions',
  digital_experience: 'investor',
  experience_section: 'menu'
});

const trackPositionDetailAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'detail_positions',
  digital_experience: 'investor',
  experience_section: 'menu'
});

export function trackPositionViewLoad() {
  trackPositionAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackPositionBackClick() {
  trackPositionAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'back'
  });
}

export function trackPositionDownloadClick() {
  trackPositionAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'download_positions'
  });
}

export function trackPositionDetailClick(name: string) {
  trackPositionDetailAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'detail_positions',
    event_target: name
  });
}

export function trackPositionDetailViewLoad() {
  trackPositionDetailAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackPositionDetailBackClick() {
  trackPositionDetailAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'back'
  });
}

export function trackPositionDetailDownloadClick() {
  trackPositionDetailAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'download_positions'
  });
}
