
import { Component, Vue, Prop } from 'vue-property-decorator';

import { HeadingDisplay } from '@warrenbrasil/nebraska-web';
import { InstrumentAvatarFallback } from '@/modules/trade/components/InstrumentAvatarFallback';

import { getInstrumentSymbolPreffix } from '@/modules/trade/helpers/instruments';

@Component({
  components: {
    InstrumentAvatarFallback,
    HeadingDisplay
  }
})
export default class OrderDetailsHeader extends Vue {
  @Prop({ type: String, required: false })
  readonly subtitle?: string;

  @Prop({ type: String, required: false })
  readonly head?: string;

  @Prop({ type: String, required: true })
  readonly symbol!: string;

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: false })
  readonly instrumentType?: string;

  get instrumentSymbolPreffix() {
    return getInstrumentSymbolPreffix(this.symbol);
  }
}
