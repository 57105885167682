
import { InputText } from '@warrenbrasil/nebraska-web';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { zipCodeMask } from '@/modules/common/helpers/masks';

enum StatusType {
  Success = 'success',
  Error = 'error',
  Default = 'default'
}

@Component({ components: { InputText } })
export default class ZipCodeInput extends Vue {
  @Prop({ default: '', type: String }) private value!: string;
  @Prop({ default: '', type: String }) public label!: string;
  @Prop({ default: '', type: String }) public helperText!: string;
  @Prop({ default: false, type: Boolean }) public disabled!: boolean;
  @Prop({ default: '', type: String }) readonly statusOverride!: string;
  @Prop({ default: '', type: String }) readonly id!: string;
  @Prop({ default: '', type: String }) readonly name!: string;

  public inputValue = '';
  public status: StatusType = StatusType.Default;

  get computedStatus() {
    return this.statusOverride || this.status;
  }

  get inputId() {
    return this.id || 'zipcode';
  }

  get inputName() {
    return this.name || 'zipcode';
  }

  private handleInput() {
    if (!this.value) {
      this.status = StatusType.Error;
    } else {
      this.status = StatusType.Success;
    }
    this.$emit('masked-input', this.inputValue);
    this.$emit('input', this.inputValue.replace(/\D/g, ''));
  }

  created() {
    this.inputValue = zipCodeMask(this.value);
    this.status = this.value ? StatusType.Success : StatusType.Error;
  }
}
