import { httpClient } from '@/modules/common/services/http/http-client';
import { IPlansProduct } from './types';

const baseURL = `${process.env.VUE_APP_STORE_API_URL}/api/v1/store/customer/`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

const customerId = localStorage.getItem('warren.customerId') || '';

export function subscribe(
  planId: string,
  cardToken?: string
): Promise<unknown> {
  return httpClientWithDefaultOptions.post(
    `${customerId}/plans/${planId}/subscribe`,
    {
      creditCardHash: cardToken
    }
  );
}

export function getProductByCustomer(): Promise<IPlansProduct> {
  return httpClientWithDefaultOptions.get(`${customerId}/products`);
}
