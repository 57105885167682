import { httpClient } from '@/modules/common/services/http/http-client';

import {
  IMFAStatus,
  IMFAEnableRequest,
  IMFAEnableResponse,
  IMFALoginRequest,
  IMFALoginResponse,
  ILoginOTPRequest,
  ILoginOTPResponse,
  IOTPResponse,
  IDisconnectDevicesRequest,
  IDisconnectDevicesResponse
} from './types';

const baseURL = '/api/v1/account/security';

export function hasMFA(): Promise<IMFAStatus> {
  return httpClient.get(`${baseURL}/mfa/status`);
}

export function enableMFA(
  data: IMFAEnableRequest
): Promise<IMFAEnableResponse> {
  return httpClient.post(`${baseURL}/mfa/enable`, data);
}

export function loginMFA(data: IMFALoginRequest): Promise<IMFALoginResponse> {
  return httpClient.post(`${baseURL}/mfa-session/login`, data);
}

export function requestLoginOTPToken(
  data: ILoginOTPRequest
): Promise<ILoginOTPResponse> {
  return httpClient.post(`${baseURL}/mfa-session/otp`, data);
}

export function requestOTPToken(method?: string): Promise<IOTPResponse> {
  return httpClient.post(`${baseURL}/mfa/otp`, { method });
}

export function disconnectDevices(
  data: IDisconnectDevicesRequest
): Promise<IDisconnectDevicesResponse> {
  return httpClient.post(`${baseURL}/disable-devices`, data);
}
