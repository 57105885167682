
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { BaseText, InputText, InputSelect } from '@warrenbrasil/nebraska-web';
import { getString, getEnumOptions } from '@/modules/common/helpers/resources';
import { getAddressByZipCode } from '@/modules/common/services/http/maps';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { APIAddress, Errors } from './types';
import { EStatusType } from '@/modules/account/types/EStatusType';
import {
  addressFormProperties,
  addressFormText,
  zipCodeError,
  schema
} from './helpers';
import { ValidationError } from 'yup';
import { isEmpty } from 'lodash-es';
import { ZipCodeInput } from '@/modules/common/components/ZipCodeInput';

@Component({ components: { BaseText, InputText, InputSelect, ZipCodeInput } })
export default class PersonalAddressForm extends Vue {
  @Prop({ required: true }) private user!: any;

  addressForm = addressFormProperties;
  addressFormText = addressFormText;

  private errors: Errors<string> = {};
  private zipCodeError = zipCodeError;

  private getString = getString;
  private getEnumOptions = getEnumOptions;

  private isLoadingZipCode = false;
  private isDisabledCityAndState = false;

  @Emit('validate-field')
  private validateAddressField() {
    return { field: 'address', isValid: this.verifyEmptyErrors() };
  }

  private mounted() {
    if (this.user.address.zipcode) {
      this.setCityAndStateDisabledStatus(true);
    }
  }

  private verifyEmptyErrors(): boolean {
    return isEmpty(this.errors);
  }

  public validate(path: string) {
    return schema
      .validateAt(path, this.user.address, { abortEarly: false })
      .then(() => {
        this.cleanError(path);
      })
      .catch(this.setErrors)
      .finally(() => this.validateAddressField());
  }

  private setErrors(errors: ValidationError) {
    errors.inner.forEach(error => {
      this.errors = { ...this.errors, [error.path]: error.message };
    });
  }

  private cleanError(path: string) {
    Vue.delete(this.errors, path);
  }

  private get inputStatus() {
    const hasError = (field: string) =>
      this.errors[field] ? EStatusType.Error : EStatusType.Success;
    return {
      zipcode: hasError(this.addressForm.zipcode.path),
      street: hasError(this.addressForm.street.path),
      number: hasError(this.addressForm.number.path),
      neighborhood: hasError(this.addressForm.neighborhood.path),
      city: hasError(this.addressForm.city.path),
      state: hasError(this.addressForm.state.path)
    };
  }

  private setLoadingZipCode(isLoading: boolean) {
    this.isLoadingZipCode = isLoading;
  }

  private async findZipCode() {
    if (this.user.address.zipcode.length === 8 && !this.isLoadingZipCode)
      await this.getZipCode();
  }

  private async getZipCode() {
    this.setLoadingZipCode(true);
    try {
      const addressData = await getAddressByZipCode(this.user.address.zipcode);
      await this.checkZipCodeResponse(addressData);
      this.setAddressValues(addressData);
      this.setCityAndStateDisabledStatus(true);
    } catch {
      this.notifyZipCodeError();
      this.cleanAddressValues();
      this.setCityAndStateDisabledStatus(false);
    } finally {
      this.validateAllFields();
      this.setLoadingZipCode(false);
    }
  }

  private notifyZipCodeError() {
    this.$notify({
      group: 'user-template-notifications',
      text: getString('common_field_address_zipcode_error_invalid'),
      type: NotificationTypes.error
    });
  }

  private async checkZipCodeResponse(addressData: APIAddress) {
    if (JSON.stringify(addressData) === JSON.stringify(this.zipCodeError))
      throw new Error();
  }

  private setAddressValues(response: APIAddress) {
    this.user.address.street = response.street;
    this.user.address.neighborhood = response.neighborhood;
    this.user.address.city = response.city;
    this.user.address.state = response.state;
  }

  private cleanAddressValues(emptyValue = '') {
    this.user.address.street = emptyValue;
    this.user.address.neighborhood = emptyValue;
    this.user.address.city = emptyValue;
    this.user.address.state = emptyValue;
  }

  private validateAllFields() {
    this.validate(this.addressForm.street.path);
    this.validate(this.addressForm.neighborhood.path);
    this.validate(this.addressForm.city.path);
    this.validate(this.addressForm.state.path);
  }

  private setCityAndStateDisabledStatus(isDisabled: boolean) {
    this.isDisabledCityAndState = isDisabled;
  }
}
