import { GtmTracker } from '@/modules/common/services/analytics';
import { OrderType } from './components/InstrumentSidebar/components/Order';
import { ETradeDirection } from '@/modules/trade/types/instrument';

const trackTradeEvent = GtmTracker.trackerWithDefaultProps({
  category: 'Trade'
});

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'asset_page',
  digital_experience: 'investor',
  experience_section: 'broker'
});

export function trackInstrumentViewLoad(
  instrumentSymbol: string,
  instrumentName: string,
  instrumentType: string
) {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view',
    instrument_symbol: instrumentSymbol,
    instrument_type: instrumentType,
    instrument_name: instrumentName
  });
}

export function trackOpenRiskModal(instrumentSymbol: string) {
  trackTradeEvent({
    label: instrumentSymbol,
    action: 'Tentou Fazer Operação Desenquadrada'
  });
}

export function trackChartFilterClick(
  filter: string,
  instrumentSymbol: string
) {
  trackTradeEvent({
    action: filter,
    label: `${instrumentSymbol} - Filtro de datas`
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'asset_dashboard',
    event_name: 'select_filter',
    event_target: filter
  });
}

export function trackOrderOperationClick(
  isFractional: boolean,
  orderType: OrderType,
  orderValue: number,
  instrumentSymbol: string,
  instrumentName: string,
  instrumentType: string
) {
  const market = isFractional ? 'fractionary' : 'asset';

  trackTradeEvent({
    action: orderType === OrderType.Buy ? 'Comprar agora' : 'Vender',
    label: instrumentSymbol,
    value: orderValue
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'negociation',
    event_name:
      orderType === OrderType.Buy ? `buy_${market}` : `sell_${market}`,
    instrument_type: instrumentType,
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol
  });
}

export function trackWatchlistButtonClick(
  instrumentSymbol: string,
  instrumentName: string,
  instrumentType: string
) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'header',
    event_name: 'save_favorite',
    instrument_type: instrumentName,
    instrument_name: instrumentType,
    instrument_symbol: instrumentSymbol
  });
}

export function trackOrderClick(orderDescription: string) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'orders',
    event_name: 'select_order',
    event_target: orderDescription
  });
}

export function trackViewAllOrdersClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'orders',
    event_name: 'view_all'
  });
}

export function trackOrderConfirmationClick(
  orderType: OrderType,
  instrumentSymbol: string,
  instrumentName: string,
  instrumentType: string
) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'confirm_order',
    event_name: orderType === OrderType.Buy ? 'buy_now' : 'sell_now',
    instrument_type: instrumentType,
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol
  });
}

export function trackInstrumentRiskModalLoad(orderType: string) {
  trackAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'investment_profile',
    event_name: 'view',
    event_target: orderType
  });
}

export function trackRedirectToSuitability(instrumentSymbol: string) {
  trackTradeEvent({
    label: instrumentSymbol,
    action: 'Clicou em Refazer Perfil do Investidor'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'investment_profile',
    event_name: 'update_investment_profile'
  });
}

export function trackAcceptRiskTerms(instrumentSymbol: string) {
  trackTradeEvent({
    label: instrumentSymbol,
    action: 'Aceitou Termo de Desenquadramento'
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'investment_profile',
    event_name: 'accept_and_invest'
  });
}

export function trackChangeOrderTabClick(
  orderDirection: ETradeDirection,
  instrumentSymbol: string
) {
  trackTradeEvent({
    label: instrumentSymbol,
    action:
      orderDirection === ETradeDirection.Buy ? 'Aba Comprar' : 'Aba Vender'
  });
}

export function trackCustomOperationRowClick(
  instrumentSymbol: string,
  instrumentName = '',
  instrumentType = ''
) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'custom_operations',
    event_name: 'select_custom_operation',
    instrument_type: instrumentType,
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol
  });
}

export function trackCustomOperationsSelectChange(optionLabel: string) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'custom_operations',
    event_name: 'select_sort',
    event_target: optionLabel
  });
}

export function trackCustomOperationsRetryClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'custom_operations',
    event_name: 'try_again'
  });
}
