
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  Modal,
  BaseText,
  ButtonContainer,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import * as trackEvents from '@/modules/wealth/views/InvestmentSuggestions/track-events';

@Component({
  components: {
    Modal,
    BaseText,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary
  }
})
export default class RejectModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Watch('isOpen', { immediate: true })
  public async trackRefusalLoad(isOpen: boolean) {
    if (isOpen) {
      trackEvents.trackSuggestionRefusalLoad();
    }
  }

  @Emit('close')
  emitClose() {
    trackEvents.trackCancelSuggestionRefusal();
  }

  @Emit('rejected')
  emitRejected() {
    trackEvents.trackConfirmSuggestionRefusal();
  }
}
