
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Row } from '@warrenbrasil/nebraska-web';
import { WealthStatementRow } from './components/WealthStatementRow';
import { OrderModal } from '@/modules/wealth/views/WealthPortfolioV2/components/Orders/components/OrderModal';

import { IOrders } from '@/modules/wealth/services/wealth-orders';

@Component({
  components: {
    Row,
    OrderModal,
    WealthStatementRow
  }
})
export default class WealthStatementsList extends Vue {
  @Prop({ type: Array })
  readonly statements!: IOrders[];

  @Prop({ type: String, required: false })
  public portfolioId?: string;

  selectedStatement: IOrders | null = null;
  isOrderModalOpen = false;

  handleStatementClick(statement: IOrders) {
    this.selectedStatement = statement;
    this.isOrderModalOpen = true;
  }

  handleCloseModal() {
    this.isOrderModalOpen = false;
  }

  goToWealthOrdersPage() {
    const query: Record<string, string> = {};

    if (this.portfolioId) {
      query.portfolioId = this.portfolioId;
    }

    return this.$router.push({ name: 'wealthOrders', query });
  }

  get selectedStatementId() {
    return this.selectedStatement?.apiId;
  }
}
