import { BaseHttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';
import {
  IPortfolioInvestmentInformationResponse,
  IInvestmentByPortfolioPayload,
  IInvestmentByPortfolioResponse
} from './bff-investment-transaction-types';

export class BffInvestmentsTransaction extends BaseHttpClient {
  constructor() {
    super(undefined, process.env.VUE_APP_BFF_INVESTMENTS_URL);
  }

  public getPortfolioInvestmentInformation(
    portfolioId: string,
    customerId: string
  ): Promise<IPortfolioInvestmentInformationResponse> {
    return this.get<IPortfolioInvestmentInformationResponse>(
      `/v1/portfolioInvestmentInformation?portfolioId=${portfolioId}&customerId=${customerId}`
    );
  }

  public investmentByPortfolio(
    payload: IInvestmentByPortfolioPayload
  ): Promise<IInvestmentByPortfolioResponse> {
    return this.post<IInvestmentByPortfolioResponse>(
      '/v1/investmentByPortfolio',
      payload
    );
  }
}
