
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  BaseText,
  Heading,
  SkeletonLoader,
  HelperView
} from '@warrenbrasil/nebraska-web';
import { ProductToWithdrawRow } from './components/ProductToWithdrawRow';
import { Notification } from '@/modules/common/components/Notification';
import { MatchMedia } from '@/modules/common/components/MatchMedia';
import { ProductsToWithdrawHeader } from './components/ProductsToWithdrawHeader';
import { InvestmentTransactionNavbar } from '@/modules/transaction/views/InvestmentTransaction/components/InvestmentTransactionNavbar';
import { Footer } from './components/Footer';
import { WalletBalance } from './components/WalletBalance';
import { ProductsTableSkeleton } from './components/ProductsTableSkeleton';
import {
  BffWithdraw,
  IRedemptionPreviewProducts,
  IRedemptionPreviewResponse,
  IRedemptionPreviewResponseError,
  IWithdrawPortfolio,
  IWithdrawProduct
} from '@/modules/wealth/services/withdraw';
import { ILastRedeemInfoPayload } from '../../store/redeem/redeem-types';
import {
  trackSelectTotalWithdrawal,
  trackViewLoad,
  trackProductUptadeValue,
  trackContinueToReviewPage
} from './track-events';
import {
  RedeemErrorsTitleMessage,
  RedeemErrorsDescriptionMessage,
  Errors,
  ErrorButton,
  IconError,
  ValidationError,
  ButtonErrorAction
} from './types';
import { isBFFError } from '@/modules/common/helpers/bff';

const customerModule = namespace('CustomerModule');
const redeemModule = namespace('RedeemModule');

@Component({
  components: {
    ProductsToWithdrawHeader,
    Footer,
    ProductToWithdrawRow,
    InvestmentTransactionNavbar,
    MatchMedia,
    Heading,
    BaseText,
    WalletBalance,
    ProductsTableSkeleton,
    SkeletonLoader,
    HelperView,
    Notification
  }
})
export default class WealthProductWithdraw extends Vue {
  @customerModule.Getter('getCustomerId')
  private customerId?: string;

  @redeemModule.Mutation('saveLastRedeemInformation')
  readonly saveLastRedeemInformation!: (
    response: ILastRedeemInfoPayload
  ) => void;

  @Prop({ type: String, required: true })
  public readonly portfolioId!: string;

  public readonly NebraskaColors = NebraskaColors;
  private readonly bffWithdraw = new BffWithdraw();
  public products: IWithdrawProduct[] = [];
  public portfolioDetails: IWithdrawPortfolio = {
    name: '',
    isCustom: false,
    totalBalance: { formatted: '', value: 0 }
  };

  public isLoadingProducts = true;
  public isLoadingRedeemValidation = false;
  public error: ValidationError = {
    onPageLoad: false,
    submitValidationError: false,
    genericSubmitError: false,
    type: Errors.DEFAULT_ERROR
  };

  public submitError = {
    disableButton: false,
    message: ''
  };

  async created() {
    await this.getProductsFromWallet();
    trackViewLoad();
  }

  public redirectToTransactionWithdraw() {
    this.$router.push({
      name: 'wealthPortfolioWithdrawal',
      params: { portfolioId: this.portfolioId }
    });
  }

  public async getProductsFromWallet() {
    this.error.onPageLoad = false;
    this.isLoadingProducts = true;
    try {
      const { allocationsToRedeem } =
        await this.bffWithdraw.getProductsToWithdrawFromPortfolio(
          this.portfolioId
        );

      this.products = allocationsToRedeem.products;
      this.portfolioDetails = allocationsToRedeem.portfolio;
      !allocationsToRedeem.portfolio.isCustom &&
        this.redirectToTransactionWithdraw();
    } catch (error) {
      if (isBFFError(error) && error.error.code === 404) {
        this.error.type = Errors.NO_PRODUCTS_ERROR;
      }
      this.error.onPageLoad = true;
    } finally {
      this.isLoadingProducts = false;
    }
  }

  public callActionAgain() {
    if (this.error.onPageLoad && this.error.type !== Errors.NO_PRODUCTS_ERROR) {
      this.getProductsFromWallet();
    } else {
      const callback = ButtonErrorAction[this.error.type];
      this[callback]();
    }
  }

  public clearComponent() {
    this.products = [];
    this.error.submitValidationError = false;
    this.error.type = Errors.DEFAULT_ERROR;
    this.getProductsFromWallet();
  }

  public async validateRedeem() {
    if (!this.shouldValidateRedeem) return;
    this.error.genericSubmitError = false;
    this.error.submitValidationError = false;
    this.error.type = Errors.DEFAULT_ERROR;
    this.isLoadingRedeemValidation = true;
    try {
      const response = await this.bffWithdraw.redemptionPreview({
        customerId: this.customerId || '',
        portfolioId: this.portfolioId,
        products: this.validationProductsPayload,
        validation: true
      });
      this.handleRedeemValidationResponse(response);
    } catch (error) {
      const typedError = error as IRedemptionPreviewResponseError;
      if (typedError?.error && typedError.error.code === 400) {
        this.error.submitValidationError = true;
        this.error.type = Errors.SUBMIT_VALIDATION_ERROR;
      } else {
        this.error.genericSubmitError = true;
      }
    } finally {
      this.isLoadingRedeemValidation = false;
      trackContinueToReviewPage(this.redeemTotalValue);
      this.trackEachProduct(this.products);
    }
  }

  private trackEachProduct(products: IWithdrawProduct[]) {
    products.forEach(product => {
      if (product?.valueToInvest && product?.valueToInvest > 0) {
        trackProductUptadeValue(product);
      }
    });
  }

  private handleRedeemValidationResponse(response: IRedemptionPreviewResponse) {
    const { redemptionPreview } = response;
    const formatProduct = (product: IRedemptionPreviewProducts) => ({
      ...product,
      amount: this.validationProductsPayload.find(
        prod => prod.instrumentDetailApiId === product.id
      )!.amount
    });
    this.saveLastRedeemInformation({
      portfolio: {
        id: this.portfolioId,
        name: redemptionPreview.portfolio.name
      },
      summaryPreview: redemptionPreview.summaryPreview,
      products: redemptionPreview.products.map(formatProduct)
    });
    this.$router.push({ name: 'wealthProductWithdrawalReview' });
  }

  get shouldValidateRedeem() {
    return (
      this.validationProductsPayload.length && !this.isLoadingRedeemValidation
    );
  }

  get validationProductsPayload() {
    return this.products
      .filter(prod => prod.valueToInvest)
      .map(prod => ({
        instrumentDetailApiId: prod.id,
        amount: prod.valueToInvest || 0
      }));
  }

  public selectTotalValue() {
    this.products = this.products.map(prod => {
      return prod.available.value
        ? {
            ...prod,
            valueToInvest: Number(
              prod.totalGrossWorthAvailable.value.toFixed(2)
            ),
            available: prod.available.formatted
              ? { ...prod.available, formatted: '' }
              : prod.available
          }
        : prod;
    });
    this.submitError = {
      disableButton: false,
      message: ''
    };

    trackSelectTotalWithdrawal(this.portfolioDetails.totalBalance.value);
  }

  updateProductValue(updatedProduct: IWithdrawProduct) {
    this.products = this.products.map(product => {
      if (product.id === updatedProduct.id) {
        const available = this.getProductErrors(
          product,
          updatedProduct.valueToInvest!
        );
        return {
          ...updatedProduct,
          available
        };
      }
      return product;
    });
  }

  getProductErrors(product: IWithdrawProduct, newValue: number) {
    let available = { value: true, formatted: '' };

    if (newValue === 0) {
      this.submitError = { disableButton: false, message: '' };
      return available;
    }

    available = this.validateMinimumBalance(product, newValue);
    available = !available.formatted
      ? this.validateMinimumWithdraw(product, newValue)
      : available;

    this.submitError = available.formatted
      ? { disableButton: true, message: '' }
      : { disableButton: false, message: '' };
    return available;
  }

  handleBackNavigation() {
    this.$router.back();
  }

  private validateMinimumBalance(product: IWithdrawProduct, newValue: number) {
    let available = { value: true, formatted: '' };
    if (product.minimumBalance) {
      const valueAvailable =
        product.totalGrossWorthAvailable.value - product.minimumBalance.value;
      const isTotal = product.totalGrossWorthAvailable.value === newValue;
      available =
        !isTotal && valueAvailable < newValue
          ? {
              value: true,
              formatted:
                'É preciso respeitar o mínimo de permanência do produto. Diminua o valor escolhido ou faça o resgate total.'
            }
          : available;
    }
    return available;
  }

  private validateMinimumWithdraw(product: IWithdrawProduct, newValue: number) {
    let available = { value: true, formatted: '' };
    if (product.minimumFinancialMovement) {
      available =
        product.minimumFinancialMovement.value > newValue &&
        product.totalGrossWorthAvailable.value !== newValue
          ? {
              value: true,
              formatted: `Escolha um valor maior que o mínimo de movimentação do produto`
            }
          : available;
    }
    return available;
  }

  public get walletBalanceFormatted() {
    return this.portfolioDetails?.totalBalance.formatted || 'R$ 0,00';
  }

  public get redeemTotalValue() {
    return this.products.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.valueToInvest || 0);
    }, 0);
  }

  public get showHelperViewFeedback() {
    return Object.values(this.error).some(hasError => hasError === true);
  }

  public get showFooter() {
    const boolProperties = JSON.parse(JSON.stringify(this.error));
    delete boolProperties.type;
    return Object.values(boolProperties).every(hasError => !hasError);
  }

  public get errorTitle() {
    return RedeemErrorsTitleMessage[this.error.type];
  }

  public get errorDescription() {
    return RedeemErrorsDescriptionMessage[this.error.type];
  }

  public get errorButton() {
    return ErrorButton[this.error.type];
  }

  public get iconButton() {
    return IconError[this.error.type];
  }

  public get isButtonDisabled() {
    if (!this.products.length) return true;
    return this.submitError.disableButton || this.hasAnyErrorInProductsList;
  }

  get hasAnyErrorInProductsList() {
    const hasProductAvailable = !!this.products.filter(p => p.available.value)
      .length;
    const hasAnyErrorInAvailableProducts = (p: IWithdrawProduct) =>
      p.available.value && p.available.formatted;
    const hasAnyProductError = hasProductAvailable
      ? this.products.some(hasAnyErrorInAvailableProducts)
      : true;
    return hasAnyProductError;
  }
}
