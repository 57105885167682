import { ProductAllocation } from '../services/types/models/IBffInvestments';
import {
  Allocations,
  InstrumentClass,
  Assets,
  AllocationType,
  AllocationTypeColor,
  TypeColorClass
} from '../types';
import moment from 'moment-timezone';
interface IAllocations extends Allocations {
  allocValue: number;
}

export interface IAllocation {
  // if is a category has type
  type?: string;
  apiId?: string;
  allocValue: number;
  // if is a instrument it does not have allocations
  allocations?: IAllocation[];
  // if it is a box it has instruments
  productBox?: IAllocation;
  instruments?: IAllocations[];
}

const categories: Assets[] = [
  {
    name: 'Renda Fixa',
    type: InstrumentClass.Fixedincome
  },
  {
    name: 'Renda Variável',
    type: InstrumentClass.Variableincome
  },
  {
    name: 'Outros',
    type: InstrumentClass.Others
  }
];

const getTypeName = (type: string): string => {
  const mappedType = categories.find(a => a.type === type);
  if (mappedType) {
    return mappedType.name;
  }

  return type;
};

const getCategory = (portfolioBody: IAllocation[], instrumentType: string) => {
  return portfolioBody.find((a: IAllocation) => a.type === instrumentType);
};

export function mappedAllocations(instruments?: ProductAllocation[]) {
  const createPortfolioBody: IAllocation[] = [];
  if (!instruments) return;
  for (const instrument of instruments) {
    instrument.type.value = getTypeName(instrument.type.value);
    if (!getCategory(createPortfolioBody, instrument.type.value)) {
      createPortfolioBody.push({
        type: instrument.type.value,
        allocValue: 0,
        allocations: []
      });
    }
    const category = getCategory(createPortfolioBody, instrument.type.value)!;
    category.allocValue += instrument.value ? instrument.value.value : 0;
    let productBox;
    productBox = category.allocations!.find(
      allocation => allocation.productBox!.apiId === instrument.productBox
    );

    if (!productBox) {
      category.allocations!.push({
        allocValue: 0,
        productBox: {
          apiId: instrument.productBox!,
          instruments: [],
          allocValue: 0
        }
      });
    }
    productBox = category.allocations!.find(
      allocation => allocation.productBox!.apiId === instrument.productBox
    )!;
    productBox.allocValue += instrument.value ? instrument.value.value : 0;
    productBox.productBox!.apiId = instrument.productBox!;
    productBox.productBox!.type = instrument.type.value!;

    if (
      !productBox.productBox!.instruments!.find(
        i => i.apiId === instrument.apiId
      )
    ) {
      productBox.productBox!.instruments!.push({
        allocValue: instrument.value ? instrument.value.value : 0,
        apiId: instrument.apiId,
        boxApiId: instrument.productBox,
        type: instrument.type.value,
        value: instrument.value!.value * 100
      } as IAllocations);
    }
  }

  return createPortfolioBody;
}

export function dateViewer(date: string, format = 'DD/MM/YYYY', isUTC = true) {
  if (isUTC) {
    return moment.utc(new Date(date)).locale('pt-br').format(format);
  }

  return moment.tz(date, 'America/Sao_Paulo').locale('pt-br').format(format);
}

export function moneyViewer(money: number, showMoney = true) {
  return showMoney ? moneyFormat(money, false) : '💰 💰 💰';
}

export function moneyFormat(value: number, showPositiveSign = false): string {
  let signalChar = '';
  const fixedValue = Number(toFixedWithoutRounding(value));
  const formattedValue = formatCurrency(fixedValue).replace('-', '');

  if (Math.sign(fixedValue) === -1) {
    signalChar = '-';
  } else if (Math.sign(fixedValue) === 1 && showPositiveSign) {
    signalChar = '+';
  }

  return `${signalChar}R$ ${formattedValue}`;
}

export function formatCurrency(money: number, showSignal?: boolean) {
  if (!Number.isFinite(money) || isNaN(money)) money = 0;

  const signal = showSignal && money > 0 ? '+' : '';
  const fixedValue = toFixedWithoutRounding(money);
  const parsedValue = fixedValue
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1_')
    .replace(/\./g, ',')
    .replace(/_/g, '.');

  return `${signal}${parsedValue}`;
}

export function toFixedWithoutRounding(
  value: number,
  decimals = 2,
  withComma = false
): string {
  const [number, numberDecimals = ''] = (value || 0).toString().split('.');

  const truncatedDecimals = numberDecimals.slice(0, decimals);
  const formattedDecimals = truncatedDecimals.padEnd(decimals, '0');

  return [number, formattedDecimals].join(withComma ? ',' : '.');
}

export const getColorByType = (type: AllocationType) => {
  const types = Object.values(AllocationType);
  const typesKeys = Object.keys(AllocationType);

  const lowerCaseTypes: { [prop: string]: string } = {};

  types.forEach((item, index) => {
    lowerCaseTypes[item.toLowerCase()] = typesKeys[index];
  });

  const typeValue: string = lowerCaseTypes[type.toLowerCase()];

  return {
    // @ts-ignore
    colorClass: TypeColorClass[typeValue],
    // @ts-ignore
    colorHex: AllocationTypeColor[typeValue]
  };
};

export function percentageChartTruncate(value: number): string {
  const valueMultiplied: number = percentageTruncate(value, 2);
  return simplePercentage(valueMultiplied, 2, 2);
}

export function percentageTruncate(value: number, precision: number): number {
  if (!Number.isFinite(value) || isNaN(value)) return 0;
  return parseFloat((value * 100).toFixed(precision));
}

export function simplePercentage(
  value: number,
  precisionMin = 0,
  precisionMax = 2
): string {
  if (value === null) {
    return '';
  }

  const valueFormatted = value.toLocaleString('pt-br', {
    minimumFractionDigits: precisionMin,
    maximumFractionDigits: precisionMax
  });

  return `${valueFormatted} %`;
}

export function getURLParams<ExpectedParams>(
  decode = false
): ExpectedParams | any {
  if (window.location.href.split('?').length === 1) {
    return false;
  }

  const urlRawParameters = window.location.href.split('?')[1].split('&');
  const paramObject: any = {};

  urlRawParameters.forEach(rawParam => {
    const splitParameter = rawParam.split('=');
    paramObject[splitParameter[0]] = decode
      ? decodeURIComponent(splitParameter[1])
      : splitParameter[1];
  });

  return paramObject as ExpectedParams;
}
