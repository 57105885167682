import router from '@/router';
import { RegulatoryUpdateNavigationAction } from '@/modules/account/views/RegulatoryUpdate/types';
import { FeatureFlagsModule } from '../FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { StorageKeys } from '@/modules/common/services/local-storage';
export interface IRegulatoryUpdateRoutesOrchestrator {
  nextRoutes: string[];
  currentRoute: number;
}

function getMapActionToRoute() {
  return {
    [RegulatoryUpdateNavigationAction.RegulatoryRegisterUpdate]:
      FeatureFlagsModule.isEnabled(FeatureFlags.REGULATORY_UPDATE)
        ? 'regulatoryUpdatePersonalData'
        : 'user-account',
    [RegulatoryUpdateNavigationAction.Suitability]: 'suitability',
    [RegulatoryUpdateNavigationAction.Home]: 'home',
    [RegulatoryUpdateNavigationAction.Facematch]: 'faceMatchIdentification',
    [RegulatoryUpdateNavigationAction.Password]:
      'regulatoryUpdateVerifyPassword',
    [RegulatoryUpdateNavigationAction.Success]: 'regulatoryUpdateSuccess',
    [RegulatoryUpdateNavigationAction.Security]: 'security',
    [RegulatoryUpdateNavigationAction.RegulatoryTerms]: 'regulatoryTerms',
    [RegulatoryUpdateNavigationAction.PersonalRegisterUpdate]:
      'personalRegisterUpdate'
  };
}

export function getRegulatoryUpdateRoutes(): IRegulatoryUpdateRoutesOrchestrator {
  return JSON.parse(
    localStorage.getItem(StorageKeys.RegulatoryUpdateRoutes) || '{}'
  );
}

function setRegulatoryUpdateRoutes(nextRoutes: string[], currentRoute: number) {
  localStorage.setItem(
    StorageKeys.RegulatoryUpdateRoutes,
    JSON.stringify({
      nextRoutes,
      currentRoute
    })
  );
}

export function saveNextRoutes(routes: RegulatoryUpdateNavigationAction[]) {
  setRegulatoryUpdateRoutes(
    routes.map(route => getMapActionToRoute()[route]),
    -1
  );
}

export function goToNextRoute() {
  const { currentRoute, nextRoutes } = getRegulatoryUpdateRoutes();
  const route = nextRoutes[currentRoute + 1];

  if (route) {
    setRegulatoryUpdateRoutes(nextRoutes, currentRoute + 1);
    router.push({ name: route });
  }
}

export function goToPreviousRoute() {
  const { currentRoute, nextRoutes } = getRegulatoryUpdateRoutes();

  setRegulatoryUpdateRoutes(nextRoutes, currentRoute - 1);
  router.back();
}

export function clearNextRoutes() {
  setRegulatoryUpdateRoutes([], -1);
}

export function isOrchestratorActive(): boolean {
  const { currentRoute, nextRoutes } = getRegulatoryUpdateRoutes();
  return (
    !!nextRoutes && !!nextRoutes.length && currentRoute < nextRoutes.length
  );
}
