
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';

import highcharts, {
  Options,
  TooltipFormatterContextObject,
  Chart as ChartType,
  SeriesOptionsType,
  AnimationOptionsObject,
  AxisLabelsFormatterContextObject
} from 'highcharts';
import { Chart } from 'highcharts-vue';
import moment from 'moment-timezone';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { SpinnerLoader, BaseText } from '@warrenbrasil/nebraska-web';

import { simplePercentage } from '@/modules/common/helpers/percentage';
import { cssVariableToHexadecimal } from '@/modules/common/services/theme';
import {
  formatCurrencyWithoutRounding,
  moneyViewer as money
} from '@/modules/common/helpers/currency';
import { getGradientWithEasing, animationsOptions } from './line-chart-config';
import { ISeries, FillColorLinearGradient } from './types';

@Component({
  components: {
    Highcharts: Chart,
    SpinnerLoader,
    BaseText
  }
})
export default class LineChartV2 extends Vue {
  private timer: ReturnType<typeof setTimeout> | null = null;
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: Object, required: true })
  readonly series!: ISeries;

  @Prop({ type: Object, required: false })
  readonly benchmark?: ISeries;

  @Prop({ type: Array, required: true })
  readonly labels!: string[];

  @Prop({ type: String, required: true })
  readonly height!: string;

  @Prop({ type: String, default: 'DD/MMM' })
  readonly AxisXLabelDateFormat!: string;

  @Prop({ type: String, default: 'DD MMMM YYYY' })
  readonly tooltipDateFormat!: string;

  @Prop({ type: Boolean, default: true })
  readonly showAxisXLabels!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showBackgroundGradient!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly formatTextToPercent!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showTooltipComparatorValue!: boolean;

  @Prop({ type: Number, default: 1 })
  readonly benchmarkLineWidth!: number;

  @Prop({ type: Array, default: () => [0] })
  readonly visibleTooltipsIndex!: number[];

  @Prop({ type: String, required: false })
  readonly tooltipFooterText?: string;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: String, required: false })
  readonly noDataMessage?: string;

  @Ref() readonly graph!: HTMLElement;

  @Ref() readonly highchartsRef!: any;

  @Watch('series', { deep: true })
  private watchSeries(newSeries: ISeries) {
    this.setNewDataToChart(newSeries, this.labels, this.benchmark);
  }

  @Watch('isLoading', { deep: true })
  private watchIsLoading() {
    this.setNewDataToChart(this.series, this.labels, this.benchmark);
  }

  chartOptions: Options = {};

  public created() {
    // Previne o gráfico ser iniciado com a altura padrão do highcharts e causar um "flick"
    // quando a altura correta é setada no mounted
    this.chartOptions = {
      chart: {
        height: this.height
      }
    };
  }

  public mounted() {
    this.setChartOptions();
  }

  public beforeDestroy() {
    if (this.timer) clearTimeout(this.timer);
  }

  public get isEmpty() {
    return this.series.data.length === 0;
  }

  private get chart() {
    return this.highchartsRef.chart as ChartType;
  }

  private onAnimationStart() {
    if (this.timer) clearTimeout(this.timer);
    this.$emit('on-animation-state-change', true);
  }

  // TO-DO: Procurar uma maneira melhor de obter a informação que a animação terminou
  private onAnimationFinish(duration: number) {
    this.timer = setTimeout(() => {
      this.$emit('on-animation-state-change', false);
    }, duration);
  }

  private getMainSeriesOptions(
    series: ISeries,
    animationOptions: AnimationOptionsObject
  ) {
    const color = cssVariableToHexadecimal(
      NebraskaColors.themePrimary,
      false,
      this.graph
    )!;
    const minimumSeriesValue = Math.min(...series.data);
    const threshold =
      minimumSeriesValue < 0 ? minimumSeriesValue - 5 : minimumSeriesValue;
    const seriesGradient = getGradientWithEasing(color);

    let fillColor = {
      stops: seriesGradient
    };

    if (this.showBackgroundGradient) {
      fillColor = {
        ...fillColor,
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        }
      } as FillColorLinearGradient;
    }

    return {
      type: 'area',
      name: this.series.name,
      color: NebraskaColors.themePrimary,
      lineWidth: 3,
      threshold,
      marker: {
        enabled: series?.data?.length === 1,
        symbol: 'circle',
        radius: 6,
        states: {
          hover: {
            lineWidthPlus: 0,
            radiusPlus: 0
          }
        },
        lineColor: undefined
      },
      animation: animationOptions,
      fillColor,
      data: this.isLoading ? [] : series.data
    };
  }

  private getBenchmarkSeriesOptions(
    series: ISeries,
    animationOptions: AnimationOptionsObject
  ) {
    return {
      type: 'line',
      name: this.benchmark!.name,
      lineWidth: this.benchmarkLineWidth,
      marker: {
        enabled: series?.data?.length === 1,
        symbol: 'circle',
        radius: 4,
        states: {
          hover: {
            lineWidthPlus: 0,
            radiusPlus: 0
          }
        },
        lineColor: undefined
      },
      animation: animationOptions,
      color: NebraskaColors.elementSecondary,
      data: this.isLoading ? [] : series.data
    };
  }

  private addSeries(newMainSeriesData: ISeries, newBenchmarkData?: ISeries) {
    const mainSeriesAnimationOptions = animationsOptions.mainSeriesTransition;
    const benchmarkSeriesAnimationOptions =
      animationsOptions.benchmarkSeriesTransition;
    const animationDuration = mainSeriesAnimationOptions.duration;

    const mainSeriesOptions = this.getMainSeriesOptions(
      newMainSeriesData,
      mainSeriesAnimationOptions
    ) as SeriesOptionsType;
    this.chart.addSeries(mainSeriesOptions);

    if (newBenchmarkData) {
      const benchMarkSeriesOptions = this.getBenchmarkSeriesOptions(
        newBenchmarkData,
        benchmarkSeriesAnimationOptions
      ) as SeriesOptionsType;
      this.chart.addSeries(benchMarkSeriesOptions);
    }

    return animationDuration;
  }

  private setNewDataToChart(
    newMainSeriesData: ISeries,
    newCategories: string[],
    newBenchmarkData?: ISeries
  ) {
    this.onAnimationStart();

    const chartSeries = [...this.chart.series];
    chartSeries.forEach(series => series.remove(false));

    const animationDuration = this.addSeries(
      newMainSeriesData,
      newBenchmarkData
    );

    this.chart.xAxis[0].update({
      tickInterval: newCategories.length
    });
    this.chart.xAxis[0].setCategories(newCategories);

    this.onAnimationFinish(animationDuration);
  }

  private setupGridLine() {
    // @ts-ignore
    highcharts.wrap(highcharts.Tick.prototype, 'render', function (p, ...args) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      p.call(self, ...args);
      const gridLine = self.gridLine;
      if (!self.axis.horiz && gridLine) {
        if (self.pos === 0) {
          gridLine.attr({
            fill: 'none',
            stroke: NebraskaColors.dividerPrimary,
            'stroke-width': 2,
            'stroke-dasharray': 0
          });
        }
      }
    });
  }

  public setChartOptions() {
    this.onAnimationStart();
    this.setupGridLine();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const lineChartRef = this;
    const mainSeriesAnimationOptions =
      animationsOptions.mainSeriesInitialRender;
    const benchmarkSeriesAnimationOptions =
      animationsOptions.benchmarkSeriesInitialRender;
    let animationDuration = mainSeriesAnimationOptions.duration;
    const mainSeriesOptions = this.getMainSeriesOptions(
      this.series,
      mainSeriesAnimationOptions
    ) as SeriesOptionsType;
    const series = [mainSeriesOptions];

    if (this.benchmark) {
      animationDuration =
        benchmarkSeriesAnimationOptions.duration +
        benchmarkSeriesAnimationOptions.defer;
      const benchMarkSeriesOptions = this.getBenchmarkSeriesOptions(
        this.benchmark,
        benchmarkSeriesAnimationOptions
      ) as SeriesOptionsType;
      series.push(benchMarkSeriesOptions);
    }

    this.chartOptions = {
      chart: {
        height: this.height,
        backgroundColor: NebraskaColors.backgroundSecondary,
        marginTop: 15
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: this.labels,
        startOnTick: true,
        endOnTick: true,
        labels: {
          enabled: this.showAxisXLabels,
          formatter: (self: any) => {
            const labels = lineChartRef.labels;
            const dateFormat = lineChartRef.AxisXLabelDateFormat;

            if (self.isFirst || self.isLast) {
              const labelIndex = self.isFirst ? 0 : labels.length - 1;
              return moment(labels[labelIndex])
                .locale('pt-br')
                .format(dateFormat);
            }
            return '';
          },
          style: {
            color: NebraskaColors.textSecondary,
            fontSize: '14px'
          },
          autoRotationLimit: 0,
          y: 30
        },
        tickInterval: this.labels.length,
        lineColor: NebraskaColors.dividerPrimary,
        lineWidth: 1,
        tickColor: NebraskaColors.dividerOpacity,
        crosshair: {
          width: 1.5,
          color: NebraskaColors.dividerPrimary
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          formatter: (context: AxisLabelsFormatterContextObject) => {
            return lineChartRef.formatTextToPercent
              ? `${context.value} %`
              : formatCurrencyWithoutRounding(context.value);
          },
          style: {
            color: NebraskaColors.textSecondary,
            fontSize: '12px'
          },
          align: 'right',
          reserveSpace: true,
          x: -40,
          y: -4
        },
        tickAmount: 6,
        gridLineColor: NebraskaColors.dividerPrimary,
        gridLineWidth: 1,
        gridLineDashStyle: 'Dash',
        opposite: true
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        shared: true,
        useHTML: true,
        formatter: function () {
          const self = this as unknown as TooltipFormatterContextObject;
          const points = self.points || [];
          const textPrimaryColor = NebraskaColors.elementPrimary;
          const textSecondaryColor = NebraskaColors.elementSecondary;
          const dateFormat = lineChartRef.tooltipDateFormat;
          const formatTextToPercent = lineChartRef.formatTextToPercent;
          const visibleTooltipsIndex = lineChartRef.visibleTooltipsIndex;
          const showTooltipComparatorValue =
            lineChartRef.showTooltipComparatorValue;
          const tooltipFooterText = lineChartRef.tooltipFooterText;
          const dateValue = self.x;
          const formattedDate = moment
            .utc(dateValue || Date.now())
            .locale('pt-br')
            .format(dateFormat);

          const dateHTML = `<div style="color: ${textPrimaryColor}; font-size: 13px; font-weight: bold; margin-bottom: 8px;">
              ${formattedDate}
            </div>`;

          const footerHTML = tooltipFooterText
            ? `
            <div style="display: flex; padding-top: 8px;">
              <div style="white-space: normal; flex-grow: 1; width: 0; font-size: 12px; font-weight: regular; color: ${textSecondaryColor};">
                ${tooltipFooterText}
              </div>
            </div>`
            : '';

          const pointsHTML = points.map(function (
            tooltipContext: any,
            index: number
          ) {
            const color = tooltipContext.series.color;
            const name = tooltipContext.series.name;

            const shouldShowTooltip = visibleTooltipsIndex.some(
              tooltipIndex => tooltipIndex === index
            );
            const getFormatTextToPercent = formatTextToPercent
              ? simplePercentage(tooltipContext.y, 2)
              : money(tooltipContext.y);

            const isBenchmarkSeries = index === 1;
            const pointIndex = tooltipContext.point.index;
            const comparatorValue = isBenchmarkSeries
              ? lineChartRef.benchmark?.comparator?.[pointIndex]
              : lineChartRef.series?.comparator?.[pointIndex];
            const hasAnyComparatorValue =
              lineChartRef.benchmark?.comparator?.[pointIndex] ||
              lineChartRef.series?.comparator?.[pointIndex];

            return shouldShowTooltip
              ? `
                <tr style="color: ${textPrimaryColor};">
                  <td style="padding: 0; padding-right: 8px; padding-bottom: 4px;">
                    <div style="border-radius: 2px; height: 12px; width: 4px; background-color: ${color};" />
                  </td>
                  <td style="padding: 0; padding-right: 20px; padding-bottom: 4px; font-size: 13px; font-weight: 400;">${name}</td>
                  <td style="color:${textPrimaryColor}; padding: 0; padding-bottom: 4px; font-size: 13px; font-weight: 400; text-align: right; min-width: 60px">${getFormatTextToPercent}</td>
                  ${
                    showTooltipComparatorValue && hasAnyComparatorValue
                      ? `<td style="color: ${textSecondaryColor}; padding: 0; padding-left: 8px; padding-bottom: 4px; font-size: 13px; font-weight: regular; text-align: right;">
                        ${comparatorValue || ''}
                      </td>`
                      : ''
                  }
                </tr>
              `
              : '';
          });

          const benchmarkHTML = points.map(function (
            tooltipContext: any,
            index: number
          ) {
            const pointIndex = tooltipContext.point.index;

            const benchmarkComparison =
              lineChartRef.series?.benchmarkComparison?.[pointIndex];
            const shouldShowTooltip = visibleTooltipsIndex.some(
              tooltipIndex => tooltipIndex === index
            );

            if (shouldShowTooltip && benchmarkComparison) {
              const benchmarkColor =
                benchmarkComparison.value >= 0
                  ? NebraskaColors.statusPositive
                  : NebraskaColors.statusNegative;
              return `
                      <div style="background-color: ${NebraskaColors.backgroundTertiary}; color: ${NebraskaColors.elementPrimary}; font-family: 'Warren Text', 'Source Sans Pro', sans-serif; font-size: 13px; margin: 0; padding: 12px 18px; width: 100%">
                        <div style="display: flex; justify-content: space-between;">
                          <span style="color: ${NebraskaColors.elementSecondary};">${benchmarkComparison.label}</span>
                          <span style="color: ${benchmarkColor};">${benchmarkComparison.formatted}</span>
                        </div>
                      </div>
              `;
            }
            return '';
          });

          return `
          <div style="padding: 16px;">
            ${dateHTML}
            <div style="display: inline-block; margin-bottom: -6px; position: relative;">
              <table>
                <tbody>
                  ${pointsHTML.join('')}
                </tbody>
              </table>
              ${footerHTML}
            </div>
          </div>
          ${benchmarkHTML[0]}
          `;
        },
        shadow: false,
        borderWidth: 1,
        borderRadius: 16,
        padding: 0,
        borderColor: NebraskaColors.dividerPrimary,
        backgroundColor: NebraskaColors.backgroundSecondary,
        outside: false,
        style: {
          fontSize: '14px'
        }
      },
      plotOptions: {
        area: {
          marker: {
            radius: 4,
            fillColor: NebraskaColors.themePrimary
          },
          negativeFillColor: 'transparent'
        },
        series: {
          states: {
            hover: {
              enabled: true,
              lineWidthPlus: 0,
              halo: null
            }
          }
        }
      },
      series
    };

    this.onAnimationFinish(animationDuration);
  }
}
