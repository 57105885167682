import { GtmTracker } from '@/modules/common/services/analytics';
import { TrackingNeologicaRegistrationEvent } from './enums';

const trackNeologicaRegistrationEvent = GtmTracker.trackerWithDefaultProps({
  digital_experience: 'investor',
  experience_section: 'broker',
  event_type: 'click',
  page_screen: 'home_broker',
  page_screen_section: 'nelogica_registration_popup'
});

export function trackSNeologicaRegistration(
  event_name: TrackingNeologicaRegistrationEvent
) {
  trackNeologicaRegistrationEvent({
    event_name
  });
}
