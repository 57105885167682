import { render, staticRenderFns } from "./InitialFormStep.vue?vue&type=template&id=f7b5b24a&scoped=true&"
import script from "./InitialFormStep.vue?vue&type=script&setup=true&lang=ts&"
export * from "./InitialFormStep.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./InitialFormStep.vue?vue&type=style&index=0&id=f7b5b24a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7b5b24a",
  null
  
)

export default component.exports