
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Row, Heading } from '@warrenbrasil/nebraska-web';
import DownloadDocument from '@/modules/wallet-builder/src/components/DownloadDocument/DownloadDocument.vue';
import TagRiskLevel from '@/modules/wallet-builder/src/components/TagRiskLevel/TagRiskLevel.vue';
import { moneyViewer } from '@/modules/common/helpers/currency';
import { formattedPercentage } from '@/modules/common/helpers/percentage';
import { DetailsRow } from '@/modules/products/components/DetailsRow';
import { IInstrumentDetails } from '@/modules/wallet-builder/src/services/types/models/IBffInvestments';

@Component({
  components: {
    Heading,
    Row,
    DownloadDocument,
    TagRiskLevel,
    DetailsRow
  },
  filters: {
    money: (value: number) => {
      return moneyViewer(value, true);
    },
    percentage: (value: number, precisionMin: number) => {
      return formattedPercentage(value, precisionMin);
    }
  }
})
export default class Details extends Vue {
  @Prop({ required: true, type: Object })
  private details!: IInstrumentDetails;
}
