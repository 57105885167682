
import { Component, Prop, Vue } from 'vue-property-decorator';

import { HelperView, RoundedIcon } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

enum modalStatusTypes {
  Error = 'error',
  Info = 'info',
  Success = 'success'
}

@Component({
  components: {
    HelperView,
    RoundedIcon
  }
})
export default class ModalStatus extends Vue {
  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly description!: string;

  @Prop({ type: String, required: true })
  readonly type!: modalStatusTypes;

  get icon() {
    const icons = {
      [modalStatusTypes.Error]: 'ic_attention',
      [modalStatusTypes.Success]: 'ic_check_simple',
      [modalStatusTypes.Info]: 'ic_progress_clock'
    };

    return icons[this.type];
  }

  get mainColor() {
    const colors = {
      [modalStatusTypes.Error]: NebraskaColors.statusAlertBackground,
      [modalStatusTypes.Success]: NebraskaColors.statusPositiveBackground,
      [modalStatusTypes.Info]: NebraskaColors.statusInfoBackground
    };

    return colors[this.type];
  }

  get textColor() {
    const colors = {
      [modalStatusTypes.Error]: NebraskaColors.statusAlert,
      [modalStatusTypes.Success]: NebraskaColors.statusPositive,
      [modalStatusTypes.Info]: NebraskaColors.statusInfo
    };

    return colors[this.type];
  }
}
