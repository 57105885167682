
import { Component, Vue } from 'vue-property-decorator';

import { NavBar, RoundedIcon } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'HelpCenterNavBar',
  components: { NavBar, RoundedIcon }
})
export default class HelpCenterNavBar extends Vue {
  onBackClick() {
    this.$router.back();
  }

  get NebraskaColors() {
    return NebraskaColors;
  }
}
