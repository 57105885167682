export interface IFilterQuickOptions {
  value: string;
  label: string;
  checked?: boolean;
  icon: ISelectFilterIcon;
}

export interface ISelectFilterIcon {
  backgroundColor: string;
  iconColor: string;
  iconName: string;
}

export interface IFilterOptions {
  value: string;
  formatted: string;
  applied?: boolean;
  icon?: ISelectFilterIcon;
}

export interface IFilterSubcategory {
  subCategoryName: string;
  filterName?: string;
  component: string;
  filters: Array<IFilterOptions>;
  applied?: boolean;
  selectedOption?: Array<string>;
  modalSelectedOption?: Array<string>;
}

export enum EFilterCategoryComponent {
  Chip = 'chip',
  Checkbox = 'checkbox'
}

export interface IFilterCategory {
  numberAppliedFilters?: number;
  categoryName: string;
  filterName?: string;
  component?: EFilterCategoryComponent;
  isQuickFilter: boolean;
  options?: Array<IFilterQuickOptions>;
  filters?: Array<IFilterOptions>;
  subCategories?: Array<IFilterSubcategory>;
  quickSelectedOption?: string;
  selectedOption?: Array<string>;
  modalSelectedOption?: Array<string>;
  applied?: boolean;
  multipleSelection?: boolean;
}

export interface IFilterType {
  categories: Array<IFilterCategory>;
}

export interface IGetInstrumentFilterPayload {
  category: string;
}

export type IFilterCategories = {
  [type in EFilterCategoriesTypes]?: IFilterType;
};

export enum EFilterCategoriesTypes {
  FIXEDINCOME,
  VARIABLEINCOME,
  OTHER
}
