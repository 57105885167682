export interface IAdvisorProfile {
  customer?: string;
  status?: string;
  attachements?: Attachements;
  partnerTerm?: string;
  domain?: string;
  companyName?: string;
  tradeMark?: string;
  cnpj?: string;
  partnerId?: string;
  type?: B2bPartnerType;
  phone?: string;
  isParent?: boolean;
  parent?: boolean;
  isValid?: boolean;
  isVerified?: boolean;
  verifiedAt?: Date;
}

export enum B2bPartnerType {
  CFP = 'cfp',
  MANAGER = 'manager',
  VIEWER = 'viewer',
  PLANNER = 'planner',
  AAI = 'aai'
}

export interface Attachements {
  partnerId: string;
  socialContract: string;
  consultantDirectorId: string;
  complienceDirectorId: string;
  managerDirectorId: string;
}
