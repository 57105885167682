
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { HelperView, Row, SkeletonLoader } from '@warrenbrasil/nebraska-web';
import { RouteMixin } from '@/modules/common/mixins/route';
import { CostsSection } from '../CostsSection';

import { moneyFormat as money } from '@/modules/common/helpers/currency';
import { getMonthName } from '@/modules/common/helpers/date';
import { getFeesByYear, ICostsByMonth } from '@/modules/cash/services/costs';
import { YearOption } from '@/modules/cash/views/Costs/types';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { trackMonthSelection } from '@/modules/cash/views/Costs/track-events';
import { IMonthlyCostsItem } from './types';

@Component({
  name: 'MonthlyCostsList',
  components: {
    HelperView,
    CostsSection,
    Row,
    SkeletonLoader
  },
  filters: { money },
  mixins: [RouteMixin]
})
export default class MonthlyCostsList extends Vue {
  @Prop({ type: Array, required: false, default: () => [] })
  public years!: YearOption[];

  @Prop({ type: Boolean, required: false, default: false })
  public containerIsLoading!: boolean;

  private isError = false;
  private isLoading = true;
  private monthlyCosts: IMonthlyCostsItem[] = [];
  private getMonthName = getMonthName;
  private year = -1;
  private showSection = true;

  mounted() {
    this.setDefaultYear();
  }

  get enableBrokerageFee() {
    return FeatureFlagsModule.isEnabled(
      FeatureFlags.ENABLE_BROKERAGE_FEE_ON_COSTS_PAGE
    );
  }

  public goToMonthDetailsView(month: number) {
    trackMonthSelection(month);

    this.$router.push({
      name: 'costsMonthlyCostsDetails',
      params: { year: String(this.year), month: String(month) }
    });
  }

  @Watch('years')
  public setDefaultYear() {
    if (this.years.length > 0) this.year = this.years[0].value;
  }

  @Watch('year')
  public async getYearCosts() {
    this.isError = false;
    this.isLoading = true;

    try {
      const data = await getFeesByYear(this.year);
      this.calculateMonthlyCosts(data.taxesByYear);
    } catch {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  public setYearOption(year: number) {
    if (year !== this.year) this.year = year;
  }

  public reload() {
    this.getYearCosts();
  }

  private calculateMonthlyCosts(monthlyCosts: ICostsByMonth[]) {
    if (monthlyCosts.length === 0) {
      this.monthlyCosts = [];
      if (this.years.length === 1) this.showSection = false;
    } else {
      this.monthlyCosts = monthlyCosts.map(
        (monthCosts): IMonthlyCostsItem => ({
          month: monthCosts.month,
          total: this.calculateTotal(monthCosts)
        })
      );
    }
  }

  private calculateTotal(costs: ICostsByMonth) {
    const management = costs.management || 0;
    const brokerageFee = this.enableBrokerageFee
      ? costs.brokerageFee?.total ?? 0
      : 0;
    const positiveCashback =
      costs.cashback - (costs.administration + management + brokerageFee);

    return Math.round(positiveCashback * 100) / 100 || 0;
  }

  private getMonthCostClass(value: number) {
    return value && value > 0 ? { 'costs-list__positive-value': true } : {};
  }

  private get isLoadingState() {
    return this.isLoading || this.containerIsLoading;
  }
}
