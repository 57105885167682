
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  ContextColorProvider,
  NavBar,
  Button
} from '@warrenbrasil/nebraska-web';
import { logoutImpersonate } from '@/modules/common/services/partner';
import { Categories } from '@/types/models/Analytics';
import { ICustomer } from '@/types/models';
import { GtmTracker } from '@/modules/common/services/analytics';
import { getURLParams } from '@/modules/wallet-builder/src/helpers';

const customerModule = namespace('CustomerModule');

@Component({
  components: {
    ContextColorProvider,
    NavBar,
    Button
  }
})
export default class ImpersonateHeader extends Vue {
  @customerModule.Getter('getCustomer')
  private customer?: ICustomer;

  private leaveImpersonate() {
    this.trackLeaveClick();
    const urlParams = getURLParams(true);

    if (urlParams?.backTo) {
      window.location.replace(urlParams.backTo);
      return;
    }

    logoutImpersonate();
  }

  private trackLeaveClick() {
    GtmTracker.trackEvent({
      action: 'click_cancelar',
      category: Categories.ClientDetails,
      label: ''
    });
  }
}
