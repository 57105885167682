import { moneyFormat } from '@/modules/common/helpers/currency';

export interface ITicker {
  ticker: string;
  margin: number;
  unit: string;
  unitValue: number;
  unitSize: number;
  maxExposeAllowed: number;
}

export interface IFormattedRow {
  ticker: string;
  maxExpose: string;
  variation?: string;
}

export function formattedData(data: ITicker[], balance: number, drawdown = 0) {
  const formattedData: IFormattedRow[] = [];

  data.forEach(item => {
    let expose = maxExpose(balance, item);
    const variation = maxVariation(balance, drawdown, item);

    if (expose > item.maxExposeAllowed) {
      expose = item.maxExposeAllowed;
    }

    const row = {
      ticker: item.ticker,
      maxExpose: formatExpose(expose, item),
      variation: formatVariation(variation, item)
    };

    formattedData.push(row);
  });

  return formattedData;
}

function maxExpose(balance: number, ticker: ITicker) {
  return ticker.unit === 'pts'
    ? Math.floor(balance / ticker.margin)
    : balance * ticker.margin;
}

function maxVariation(balance: number, drawdown: number, ticker: ITicker) {
  let expose = maxExpose(balance, ticker);

  if (expose === 0) {
    return 0;
  }

  if (expose > ticker.maxExposeAllowed) {
    expose = ticker.maxExposeAllowed;
  }

  return ticker.unit === 'pts'
    ? Math.round(drawdown / (expose * ticker.unitValue) / ticker.unitSize) *
        ticker.unitSize
    : drawdown / (expose * 0.01);
}

function formatExpose(value: number, ticker: ITicker) {
  return ticker.unit === 'pts' ? String(value) : moneyFormat(value);
}

function formatVariation(value: number, ticker: ITicker) {
  return ticker.unit === 'pts' ? `${value} pts` : `${value.toFixed(2)}%`;
}
