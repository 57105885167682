
import { Component, Vue } from 'vue-property-decorator';
import { PortfolioModule } from '@/store/modules/PortfolioModule';

import {
  Heading,
  ActionableHelperView,
  ContextColorProvider,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';
import { PortfoliosToInvestNavbar } from './components/PortfoliosToInvestNavbar';
import { PortfoliosToInvestPortfolioList } from './components/PortfoliosToInvestPortfolioList';
import { WarrenModules } from '@/types/navigation';
import { trackHomeByAction } from '@/modules/home/helpers/tracking';
import {
  trackCreatePortfolio,
  trackInvestPortfolioClick,
  trackLoadPortfolioSelection
} from './track-events';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

import {
  getPortfoliosToInvest,
  IPortfolio,
  ICustomerUpToDateStatus
} from '@/modules/wealth/services/investment';

@Component({
  name: 'PortfoliosToInvest',
  components: {
    PortfoliosToInvestNavbar,
    PortfoliosToInvestPortfolioList,
    Heading,
    ActionableHelperView,
    ContextColorProvider,
    ButtonPrimary
  }
})
export default class PortfoliosToInvest extends Vue {
  created() {
    this.fetchPortfolios();
    trackLoadPortfolioSelection();
  }

  hasError = false;
  isLoading = false;
  portfolios: IPortfolio[] = [];

  private get cameFromRoute() {
    return this.$route.query.cameFrom as WarrenModules;
  }

  get shouldShowCreateWalletButton() {
    return !FeatureFlagsModule.isEnabled(FeatureFlags.DISABLE_WARREN_BUILDER);
  }

  private async fetchPortfolios() {
    try {
      this.hasError = false;
      this.isLoading = true;
      const response: ICustomerUpToDateStatus = await getPortfoliosToInvest();
      this.portfolios = response.portfolios.data;
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  handleCloseClick() {
    const redirectTo = this.cameFromRoute || WarrenModules.Wealth;

    PortfolioModule.resetInvestedPortfolios();
    this.$router.replace({ name: redirectTo });
  }

  goToWalletCreationFlow() {
    if (this.cameFromRoute === WarrenModules.Home)
      trackHomeByAction('Investir \\ Criar nova carteira');
    trackCreatePortfolio();

    this.$router.push({ name: 'catalog' });
  }

  trackPortfolioClick(portfolioName: string) {
    trackInvestPortfolioClick(portfolioName);
  }
}
