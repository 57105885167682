import { trackAccountByAction } from '@/modules/home/helpers/tracking';
import { trackAmplitudeEvent } from '@/modules/home/helpers/track-amplitude-event';

export function trackInvestClick() {
  trackAccountByAction('Investir');
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'invest',
    event_target: 'Investir',
    experience_section: 'portfolio'
  });
}

export function trackDepositClick() {
  trackAccountByAction('Depositar');
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'deposit',
    event_target: 'Depositar'
  });
}

export function trackTransferClick() {
  trackAccountByAction('Transferir');
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'transfer',
    event_target: 'Transferir'
  });
}

export function trackReloadBalanceAccountErrorClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'try_again_balance_account_error',
    event_target: 'Tentar novamente'
  });
}

export function trackBalanceAccountErrorLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view_balance_account_error'
  });
}

export function trackViewMoreTransactionsEvent() {
  trackAmplitudeEvent({
    page_screen_section: 'header',
    event_type: 'click',
    event_name: 'view_more_transactions'
  });
}
