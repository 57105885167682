
import { Component, Vue } from 'vue-property-decorator';
import {
  Heading,
  BaseText,
  Row,
  SkeletonLoader,
  BaseIcon,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { moneyViewer } from '@/modules/common/helpers/currency';
import { getCashback } from '@/modules/cash/services/costs';
import { trackCashbackKnowMore } from '@/modules/cash/views/Costs/track-events';

@Component({
  name: 'CashbackSection',
  components: {
    BaseText,
    Heading,
    Row,
    SkeletonLoader,
    ButtonSecondary,
    BaseIcon
  }
})
export default class CashbackSection extends Vue {
  readonly NebraskaColors = NebraskaColors;

  error = true;
  cashbackValue = '';
  isLoading = false;

  mounted() {
    this.getCashback();
  }

  async getCashback() {
    this.error = false;
    this.isLoading = true;

    try {
      const { cashbackAmount } = await getCashback();
      this.cashbackValue = moneyViewer(cashbackAmount);
    } catch {
      this.error = true;
    } finally {
      this.isLoading = false;
    }
  }

  showMoreDetails() {
    trackCashbackKnowMore('Saiba Mais');
    this.$router.push({ name: 'costsLearnMore' });
  }
}
