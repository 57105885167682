import { ChartDateFilters } from '../ChartFilters';
import { ETickerPriceHistoryPeriod } from '@/modules/trade/types';

export function getAxisXLabelDateFormatByFilter(
  filter: ChartDateFilters | ETickerPriceHistoryPeriod | null | string
) {
  switch (filter) {
    case ChartDateFilters.OneDay:
      return 'HH:mm';
    case ChartDateFilters.SevenDays:
    case ChartDateFilters.ThirtyDays:
    case ETickerPriceHistoryPeriod.LastMonth:
      return 'DD/MMM';
    default:
      return 'MMM/YY';
  }
}
