
import {
  Vue,
  Component,
  Prop,
  Emit,
  InjectReactive
} from 'vue-property-decorator';
import { Row, BaseText, ButtonPrimary } from '@warrenbrasil/nebraska-web';
import {
  TrackSubscriptionPageActions,
  trackReserveSubscriptionPageActions,
  trackHelpSubscriptionPage
} from '@/modules/trade/views/Subscription/tracker';
import { HelpButton } from '@/modules/trade/components/HelpButton';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { SubscriptionOrderContainer } from '../SubscriptionOrderContainer';

@Component({
  components: {
    Row,
    BaseText,
    ButtonPrimary,
    SubscriptionOrderContainer,
    BackButton,
    HelpButton
  }
})
export default class SubscriptionConfirm extends Vue {
  @Prop({ type: String, required: true })
  readonly instrumentQuantity!: string;

  @Prop({ type: Number, required: true })
  readonly availableQuantity!: number;

  @Prop({ type: String, required: true })
  readonly totalValueEstimated!: string;

  @Prop({ type: String, required: true })
  readonly instrumentValue!: string;

  @Prop({ type: String, required: true })
  readonly closingDate!: string;

  @Prop({ type: Boolean, required: true })
  readonly isEditingSubscriptionOrder!: boolean;

  @Prop({ type: String, required: true })
  readonly availableBalanceUser!: string;

  @InjectReactive()
  readonly instrumentType!: string;

  @InjectReactive()
  readonly instrumentName!: string;

  public emitEventSubscriptionOrder() {
    const ticker = this.$route.query.ticker as string;
    trackReserveSubscriptionPageActions({
      instrument_type: this.instrumentType,
      instrument_name: this.instrumentName,
      instrument_symbol: ticker,
      subscrition_action: TrackSubscriptionPageActions.confirm_asset_reservation
    });
    if (this.isEditingSubscriptionOrder) this.emitUpdateOrder();
    else this.emitRegisterOrder();
  }

  public trackSubscriptionOrderHelpButton() {
    trackHelpSubscriptionPage();
  }

  @Emit('update-order')
  emitUpdateOrder() {}

  @Emit('success-order')
  emitRegisterOrder() {}

  get NebraskaColors() {
    return NebraskaColors;
  }

  @Emit('back-step-order')
  public onBackClick() {}
}
