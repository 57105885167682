
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PropType } from 'vue';

export enum Device {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop'
}

const DeviceMediaQuery = {
  [Device.Mobile]: '(max-width: 480px)',
  [Device.Tablet]: '(min-width: 481px) and (max-width: 768px)',
  [Device.Desktop]: '(min-width: 769px)'
};

@Component
export default class MatchMedia extends Vue {
  @Prop({ type: String, required: false })
  private query?: string;

  @Prop({
    type: String as PropType<Device>,
    required: false,
    validator: (value: string) => {
      return Object.values(Device).some(device => device === value);
    }
  })
  private device!: Device;

  private mediaQuery?: MediaQueryList;
  private matches = false;

  private addEventListener() {
    if (this.mediaQuery?.addEventListener) {
      this.mediaQuery.addEventListener('change', this.handleChange);
    } else {
      this.mediaQuery?.addListener(this.handleChange);
    }
  }

  private removeEventListener() {
    if (this.mediaQuery?.removeEventListener) {
      this.mediaQuery.removeEventListener('change', this.handleChange);
    } else {
      this.mediaQuery?.removeListener(this.handleChange);
    }
  }

  private created() {
    const query = this.query || DeviceMediaQuery[this.device];

    if (!query) {
      console.error('MatchMedia: you must pass a query prop or a device prop');
      return;
    }

    this.mediaQuery = window.matchMedia(query);
    this.matches = this.mediaQuery.matches;

    this.addEventListener();
  }

  private handleChange(event: MediaQueryListEvent) {
    this.matches = event.matches;
  }

  private destroyed() {
    this.removeEventListener();
  }

  private get useFallback() {
    return !!this.$slots?.fallback;
  }
}
