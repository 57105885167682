
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  inheritAttrs: false
})
export default class OrderInput extends Vue {
  @Prop({ type: Number })
  private value!: number;

  /**
   *  Input size. Possible values:
   *  `xl, sm, lg`
   */
  @Prop({ type: String, default: '' })
  private size!: string;

  private NebraskaColors = NebraskaColors;

  private get computedClassName() {
    const size = this.size;
    const computedClass = ['wrn-input'];

    if (size) {
      computedClass.push(`wrn-input-${size}`);
    }

    return computedClass;
  }
}
