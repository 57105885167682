import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEventSuggestions = GtmTracker.trackerWithDefaultProps({
  page_screen: 'portfolio_investment_suggestion',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackSuggestionsLoad() {
  trackAmplitudeEventSuggestions({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackAcceptInvestmentSuggestion() {
  trackAmplitudeEventSuggestions({
    event_type: 'click',
    event_name: 'accept_investment_suggestion',
    event_target: 'Aceitar'
  });
}

export function trackRefuseInvestmentSuggestion() {
  trackAmplitudeEventSuggestions({
    event_type: 'click',
    event_name: 'refuse_investment_suggestion',
    event_target: 'Recusar'
  });
}

const trackAmplitudeEventSuggestionRefusal = GtmTracker.trackerWithDefaultProps(
  {
    page_screen: 'portfolio_investment_suggestion_refusal',
    digital_experience: 'investor',
    experience_section: 'portfolio'
  }
);

export function trackSuggestionRefusalLoad() {
  trackAmplitudeEventSuggestionRefusal({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackCancelSuggestionRefusal() {
  trackAmplitudeEventSuggestionRefusal({
    event_type: 'click',
    event_name: 'cancel_investment_suggestion_refusal',
    event_target: 'Voltar'
  });
}

export function trackConfirmSuggestionRefusal() {
  trackAmplitudeEventSuggestionRefusal({
    event_type: 'click',
    event_name: 'confirm_investment_suggestion_refusal',
    event_target: 'Recusar sugestão'
  });
}

const trackAmplitudeUnavailableSuggestion = GtmTracker.trackerWithDefaultProps({
  page_screen: 'portfolio_investment_unavailable_suggestion',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackUnavailableSuggestionLoad(target: string) {
  trackAmplitudeUnavailableSuggestion({
    event_type: 'load',
    event_name: 'view',
    event_target: target
  });
}
