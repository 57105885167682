
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  SkeletonLoader,
  ActionableHelperView,
  HelperView
} from '@warrenbrasil/nebraska-web';
import { GtmTracker } from '@/modules/common/services/analytics';

import { Details } from '@/modules/products/components/Details';
import { ProductsDetailsProvider } from '../providers';
import { IInstrumentDetails } from '@/modules/wallet-builder/src/services/types/models/IBffInvestments';

@Component({
  components: {
    Details,
    ProductsDetailsProvider,
    SkeletonLoader,
    ActionableHelperView,
    HelperView
  }
})
export default class DetailsContainer extends Vue {
  @Prop({ type: String })
  private apiId?: string;

  private NebraskaColors = NebraskaColors;

  private handleSuccess(instrument: IInstrumentDetails) {
    this.trackEvent(instrument.name!, 'Abrir Detalhes Produto');
  }

  private trackEvent(label: string, action: string) {
    GtmTracker.trackEvent({
      category: 'Warren',
      action,
      label
    });
  }
}
