import { hasAccessToken } from '@/modules/common/services/access-token';
import { NavigationGuard } from 'vue-router';

export const authGuard: NavigationGuard = (to, _from, next) => {
  const isPublic = to.matched.some(
    record => record.meta.requiresAuth === false
  );
  const requiresAuth = !isPublic && !hasAccessToken();
  requiresAuth
    ? next({ path: '/login', query: { redirect: to.fullPath } })
    : next();
};
