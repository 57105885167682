
import { Vue, Prop, Component } from 'vue-property-decorator';

import { FilterCategory } from '../FilterCategory';
import { IFilterCategory } from '../../../../services/types/models/IFilters';

@Component({
  name: 'FilterCategoryList',
  components: { FilterCategory, FilterCategoryList }
})
export default class FilterCategoryList extends Vue {
  @Prop({ type: Array, required: true })
  public filters!: IFilterCategory[];

  @Prop({ type: Boolean, default: false })
  public isSubCategory!: boolean;

  hasSubCategories(filter: IFilterCategory) {
    return 'subCategories' in filter;
  }
}
