import { httpClient } from '@/modules/common/services/http/http-client';
import type { SignatureTerms } from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1/terms`;

export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function signature(instrumentId: string): Promise<SignatureTerms> {
  return httpClientWithDefaultOptions.post('/signature', { instrumentId });
}
