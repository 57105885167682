import { CashMarketFiltersDateValues } from './types';

export const PositionsDateFilterOptions = [
  {
    value: CashMarketFiltersDateValues.Today,
    formatted: 'Posição'
  },
  {
    value: CashMarketFiltersDateValues.Historical,
    formatted: 'Rentabilidade'
  }
];
