import { RouteConfig } from 'vue-router';

import { beforeEnterIncomeTaxCalculator } from './navigation-guards/income-tax-calculator';

import ChangePasswordView from '@/modules/account/views/ChangePassword/ChangePasswordView.vue';
import ChangeEmailView from '@/modules/account/views/ChangeEmail/ChangeEmailView.vue';
import UpdateUserAccountView from '@/modules/account/views/UpdateUserAccount/UpdateUserAccountView.vue';
import { IncomeTaxCalculator } from '@/modules/account/views/IncomeTaxCalculator';
import { regulatoryUpdateRoutes } from '../views/RegulatoryUpdate/routes';

export const accountRoutes: RouteConfig[] = [
  ...regulatoryUpdateRoutes,
  {
    name: 'user-email',
    path: '/user/email',
    component: ChangeEmailView,
    meta: {
      context: 'account'
    }
  },
  {
    name: 'user-password',
    path: '/user/password',
    component: ChangePasswordView,
    meta: {
      context: 'account'
    }
  },
  {
    name: 'user-account',
    path: '/user/account',
    component: UpdateUserAccountView,
    meta: {
      context: 'account'
    }
  },
  {
    name: 'incomeTaxCalculator',
    path: '/user/income-tax-calculator',
    component: IncomeTaxCalculator,
    beforeEnter: beforeEnterIncomeTaxCalculator,
    meta: {
      context: 'account'
    }
  }
];
