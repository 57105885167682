
import { Component, Watch, Prop, Vue, Emit } from 'vue-property-decorator';
import { IRecaptcha } from './';
import { showRecaptchaBadge, hideRecaptchaBadge } from './helpers';

@Component
export default class RecaptchaV2 extends Vue implements IRecaptcha {
  @Prop({ type: Boolean, default: false })
  public challenge!: boolean;

  @Prop({ type: Boolean, default: false })
  public reset!: boolean;

  private siteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
  private instance!: any;

  @Emit('token')
  emitToken(_token: string) {}

  public loadRecaptcha() {
    const options = {
      sitekey: this.siteKey,
      size: 'invisible',
      callback: this.emitToken
    };

    const checkingInterval = setInterval(() => {
      if (window.grecaptcha && this.$refs.recaptcha) {
        this.instance = window.grecaptcha.render(
          this.$refs.recaptcha as HTMLElement,
          options
        );

        clearInterval(checkingInterval);
      }
    }, 500);
  }

  @Watch('challenge', { deep: true })
  public onChallenge() {
    window.grecaptcha.execute(this.instance);
  }

  @Watch('reset', { deep: true })
  public onReset() {
    window.grecaptcha.reset(this.instance);
  }

  private mounted() {
    this.loadRecaptcha();
    showRecaptchaBadge();
  }

  private beforeDestroy() {
    hideRecaptchaBadge();
  }
}
