import { EventKnownProperties } from '@warrenbrasil/web-analytics';
import _Vue from 'vue';

export function AnalyticsPlugin(Vue: typeof _Vue): void {
  Vue.prototype.$analytics = warrenAnalyticsObject;
}
export class WarrenAnalytics {
  private hasGoogleTagManager = window.dataLayer;

  public track(event: EventKnownProperties | any) {
    try {
      this.fetchAnalyticsFrameworks();
      window.dataLayer.push(event);
    } catch (error) {
      console.error(error);
    }
  }

  private fetchAnalyticsFrameworks() {
    if (!this.hasGoogleTagManager && window.dataLayer) {
      this.hasGoogleTagManager = window.dataLayer;
    }
  }
}

export const warrenAnalyticsObject = new WarrenAnalytics();
