import {
  DerivativesFiltersDateValues,
  DerivativesFiltersTypeValues
} from './types';

export const PositionsDateFilterOptions = [
  {
    value: DerivativesFiltersDateValues.Today,
    formatted: 'Posição'
  },
  {
    value: DerivativesFiltersDateValues.Historical,
    formatted: 'Rentabilidade'
  }
];

export const PositionsTypeFilterOptions = [
  {
    value: DerivativesFiltersTypeValues.Options,
    formatted: 'Opções'
  },
  {
    value: DerivativesFiltersTypeValues.Forwards,
    formatted: 'Termos'
  }
];
