
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Row, BaseText } from '@warrenbrasil/nebraska-web';

@Component({
  components: { Row, BaseText }
})
export default class MenuItem extends Vue {
  @Prop({ type: String })
  private href!: string;

  @Prop({ type: Boolean })
  private chevron!: boolean;

  @Prop({ type: String })
  private icon!: string;

  private get computedAs() {
    if (this.href) {
      return 'a';
    }

    return 'button';
  }
}
