
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Money } from '@/modules/common/components/MoneyV2';

import { NumberFormatted } from '@/modules/trade/types';

export enum InstrumentPriceSizes {
  SMALL = 'small',
  REGULAR = 'regular'
}

@Component({
  components: {
    Money
  }
})
export default class InstrumentPrice extends Vue {
  @Prop({ type: Object, required: true })
  readonly price!: NumberFormatted;

  @Prop({ type: String, default: '120px' })
  readonly priceWidth!: string;

  @Prop({ type: Object, required: false })
  readonly percentual?: NumberFormatted;

  @Prop({ type: String, default: '80px' })
  readonly percentualWidth!: string;

  @Prop({ type: String, default: InstrumentPriceSizes.REGULAR })
  readonly size!: InstrumentPriceSizes;

  @Prop({ type: Boolean, default: false })
  readonly enableToggleMoney!: boolean;

  @Prop({ type: String, default: 'left' })
  readonly align!: string;

  get computedClass() {
    return ['instrument-price', `instrument-price--${this.size}`];
  }

  get textComponent() {
    return this.enableToggleMoney ? 'Money' : 'span';
  }

  get computedPercentualClass() {
    return [
      'instrument-price__text--secondary',
      {
        'instrument-price__text--positive':
          this.percentual && this.percentual.value > 0,
        'instrument-price__text--negative':
          this.percentual && this.percentual.value < 0
      }
    ];
  }

  get computedMoneyClass() {
    return [
      'instrument-price__money',
      {
        'instrument-price__money--left': this.align === 'left',
        'instrument-price__money--right': this.align === 'right'
      }
    ];
  }
}
