import {
  parseColorTokens,
  writeNewStyleSheet,
  ColorMode,
  ColorToken
} from '@warrenbrasil/nebraska-tokens-web';
import { ICustomTheme } from '@/modules/common/services/local-storage';

export const createParsedTokens = (theme: ICustomTheme): ColorToken[] => {
  const { light: lightColors, dark: darkColors } = theme.colors;

  const light: ColorMode = {
    brand: {
      primary: lightColors.accountPrimary,
      overPrimary: lightColors.accountOverPrimary
    },
    cash: {
      primary: lightColors.cashPrimary,
      overPrimary: lightColors.cashOverPrimary
    },
    trade: {
      primary: lightColors.tradingPrimary,
      overPrimary: lightColors.tradingOverPrimary
    },
    wealth: {
      primary: lightColors.wealthPrimary,
      overPrimary: lightColors.wealthOverPrimary
    }
  };

  const dark: ColorMode = {
    brand: {
      primary: darkColors.accountPrimary,
      overPrimary: darkColors.accountOverPrimary
    },
    cash: {
      primary: darkColors.cashPrimary,
      overPrimary: darkColors.cashOverPrimary
    },
    trade: {
      primary: darkColors.tradingPrimary,
      overPrimary: darkColors.tradingOverPrimary
    },
    wealth: {
      primary: darkColors.wealthPrimary,
      overPrimary: darkColors.wealthOverPrimary
    }
  };

  return parseColorTokens({ light, dark });
};

export const setNebraskaScheme = (theme: ICustomTheme) => {
  const newTokens: ColorToken[] = [];

  // we only set new tokens if the theme is not managed by Warren
  if (theme.type !== 'WarrenManaged') {
    newTokens.push(...createParsedTokens(theme));
  }

  writeNewStyleSheet(newTokens);
};
