
import { Component, Prop, Emit } from 'vue-property-decorator';
import {
  HeadingDisplay,
  ContentDisplay,
  ButtonContainer,
  ButtonPrimary,
  ButtonSecondary,
  HelperViewStatus,
  Modal,
  ListDisplay,
  SpinnerLoader
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RouteMixin } from '@/modules/common/mixins/route';
import { mixins } from 'vue-class-component';
import { IWealthToWithdrawResponseSuggestedPreview } from '@/modules/wealth/services/withdraw';

@Component({
  components: {
    HeadingDisplay,
    ContentDisplay,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary,
    HelperViewStatus,
    Modal,
    ListDisplay,
    SpinnerLoader
  }
})
export default class ModalPreview extends mixins(RouteMixin) {
  @Prop({ type: Object, required: true })
  public readonly wealthPortfolioWithdrawPreviewData!: IWealthToWithdrawResponseSuggestedPreview;

  @Emit('on-close')
  private emitOnClose() {}

  @Emit('on-next-click')
  private emitOnNextClick() {}

  readonly NebraskaColors = NebraskaColors;

  public handleBackClick() {
    this.emitOnClose();
  }

  public handleNextClick() {
    this.emitOnNextClick();
  }
}
