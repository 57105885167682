import { httpClient } from '@/modules/common/services/http/http-client';

import {
  IDashStatus,
  Balances,
  AccountStatus,
  GetHomeChartResponse,
  HomeChartDateFiltersKeys
} from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getDashStatus(): Promise<IDashStatus> {
  return httpClientWithDefaultOptions.get(`/dashStatus`);
}

export function getBalances(): Promise<Balances> {
  return httpClientWithDefaultOptions.get('/balances');
}

export async function getChart(
  filter: HomeChartDateFiltersKeys | null | string,
  benchmark: string | null
): Promise<GetHomeChartResponse> {
  return httpClientWithDefaultOptions.get('/homeChart', {
    params: {
      filter,
      benchmarkFilter: benchmark
    }
  });
}

export function getAccountStatus(): Promise<AccountStatus> {
  return httpClientWithDefaultOptions.get('/accountStatus');
}
