
import { mixins } from 'vue-class-component';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

import {
  LineChart,
  getAxisXLabelDateFormatByFilter
} from '@/modules/common/components/charts/v2/LineChart';
import { FilterOption } from '@warrenbrasil/nebraska-web';
import { ChartFilters } from '@/modules/common/components/charts/v2/ChartFilters';
import { ChartFooter } from '@/modules/common/components/charts/v2/ChartFooter';
import { MainLoading } from '@/modules/trade/components/MainLoading';
import { MainError } from '@/modules/trade/components/MainError';

import {
  getInstrumentChart,
  InstrumentChartAxisY,
  InstrumentChartFilters
} from '@/modules/trade/services/instruments';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { isSameUTCDate } from '@/modules/common/helpers/date';
import { LineChartV2Mixin } from '@/modules/trade/mixins/line-chart-v2';
import {
  ETickerPriceHistoryPeriod,
  NumberFormatted,
  StringFormatted
} from '@/modules/trade/types';

interface LineChartSeries {
  name: string;
  data: (number | null)[];
}

@Component({
  components: {
    LineChart,
    ChartFilters,
    ChartFooter,
    FilterOption,
    MainError,
    MainLoading
  }
})
export default class InstrumentLineChart extends mixins(LineChartV2Mixin) {
  selectedFilter: ETickerPriceHistoryPeriod | string | null = null;
  filters: InstrumentChartFilters[] = [];
  isChartAnimationInProgress = false;
  priceAxisY: InstrumentChartAxisY | null = null;
  loading = true;
  error = false;
  readonly NebraskaColors = NebraskaColors;
  labels: string[] = [];
  series: LineChartSeries[] = [];

  @Prop({ type: String, required: true })
  readonly ticker!: string;

  @Prop({ type: Object, required: true })
  readonly latestPrice!: NumberFormatted;

  @Prop({ type: Object, required: false })
  readonly latestPriceDate?: StringFormatted;

  @Ref() readonly lineChartRef!: any;

  // Receives a new price in real time and updates the latest point on chart if it`s the same date
  @Watch('latestPrice', { immediate: true })
  updateLatestPrice() {
    const lastLabelIndex = this.labels.length - 1;
    const lastLabelDateStr = this.labels[lastLabelIndex];
    const lastestPriceDateStr = this.latestPriceDate?.value;

    if (
      this.latestPrice &&
      lastLabelDateStr &&
      lastestPriceDateStr &&
      isSameUTCDate(lastLabelDateStr, lastestPriceDateStr)
    ) {
      this.lineChartRef?.$refs?.highchartsRef?.chart.series[0].data[
        lastLabelIndex
      ]?.update(this.latestPrice.value);
    }
  }

  created() {
    this.fetchChartData();
  }

  get isFiltersLoading() {
    return this.loading || this.isChartAnimationInProgress;
  }

  get formattedFilters() {
    return this.filters?.map(filter => ({
      label: filter.formatted,
      value: filter.value,
      active: filter.value === this.selectedFilter,
      disabled: this.isFiltersLoading
        ? !(filter.value === this.selectedFilter)
        : false
    }));
  }

  get shouldRenderChart() {
    return !this.error && this.series.length > 0;
  }

  get mainSeries() {
    return this.series[0];
  }

  get benchmarkData() {
    return this.series[1];
  }

  get labelDateFormat() {
    return getAxisXLabelDateFormatByFilter(this.selectedFilter);
  }

  get startDate() {
    return this.labels[0] || '-';
  }

  get endDate() {
    return this.labels[this.labels.length - 1] || '-';
  }

  public async onSelectFilter(
    filter: ETickerPriceHistoryPeriod
  ): Promise<void> {
    this.selectedFilter = filter;
    await this.fetchChartData();
    this.updateLatestPrice();
  }

  public async refreshChart() {
    await this.fetchChartData();
  }

  public handleOnAnimationStateChange(isAnimationInProgress: boolean) {
    this.isChartAnimationInProgress = isAnimationInProgress;
  }

  private buildSeries(): LineChartSeries[] {
    if (!this.priceAxisY) return [];

    const priceValues = this.priceAxisY.values.map(value =>
      value ? value.value : null
    );
    const series = [
      {
        name: 'Preço',
        data: priceValues
      }
    ];

    return series;
  }

  async getChartFromBFF() {
    const data = await getInstrumentChart(this.ticker, this.selectedFilter);

    this.selectedFilter =
      data.chart.periodFilters.find(filter => filter.selected)?.value || null;
    this.priceAxisY = data.chart.axisY[0];
    this.series = this.buildSeries();
    this.filters = data.chart.periodFilters;
    this.labels = data.chart.axisX[0].values.map(date => date.value);
  }

  private async fetchChartData() {
    this.loading = true;
    this.error = false;

    try {
      await this.getChartFromBFF();
    } catch {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }
}
