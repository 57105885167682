export const EXPIRATION_FIXED_INCOME_SUB_TYPE = 112;

export function liquidationOrExpiration(
  subtype: number,
  daysToExpiration: number,
  daysToLiquidation: number
) {
  // if CDB, returns the expiration time (formatted)
  // else, simply returns the number of days to liquidation
  if (subtype === EXPIRATION_FIXED_INCOME_SUB_TYPE && daysToExpiration) {
    const months = daysToExpiration / 30;
    let expireLabel;

    if (months % 12 === 0) {
      const years = months / 12;
      expireLabel = `${years} ano${years === 1 ? '' : 's'}`;
    } else {
      expireLabel = `${months} meses`;
    }

    return expireLabel;
  } else {
    return `D+${daysToLiquidation}`;
  }
}
