
import { Component, Vue } from 'vue-property-decorator';

import {
  NavBar,
  ActionableHelperView,
  IFilterOption
} from '@warrenbrasil/nebraska-web';
import { AutoComplete } from './components/AutoComplete';
import { Loading } from './components/Loading';
import { SectionsList } from './components/SectionsList';
import { FiltersList } from '@/modules/trade/components/FiltersList';
import { BackButton } from '@/modules/common/components/buttons/BackButton';

import {
  PageContent,
  Instrument,
  getSearchPageContent
} from '@/modules/trade/services/search';
import { IDropdownItem, dropdownItemTypes } from './components/Dropdown';
import { trackFilterClick, trackSearchViewLoad } from './trackers';

const defaultFilter = {
  formatted: 'Ações',
  value: 'stock'
};

@Component({
  components: {
    NavBar,
    BackButton,
    AutoComplete,
    Loading,
    ActionableHelperView,
    FiltersList,
    SectionsList
  }
})
export default class SearchView extends Vue {
  private loading = true;
  private error = false;
  private pageContentData!: PageContent;
  private recentInstruments: IDropdownItem[] = [];
  private currentFilter = defaultFilter;

  private handleSelectFilter(filter: IFilterOption) {
    trackFilterClick(filter.label);
    this.currentFilter = {
      formatted: filter.label,
      value: filter.value
    };
    this.getSearchRequest();
  }

  private getSearchRequest() {
    const filter = this.currentFilter.value;
    this.loading = true;
    this.error = false;
    getSearchPageContent(filter)
      .then(searchData => {
        this.pageContentData = searchData;
        this.currentFilter = searchData.filters.available.find(
          filter => filter.value === searchData.filters.selected
        )!;
        this.recentInstruments = this.getFormattedRecentInstruments(
          this.pageContentData.recentSearches
        );
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private getFormattedRecentInstruments(recentSearches: Instrument[]) {
    let dropdownItems: IDropdownItem[] = recentSearches.map(instrument => ({
      type: dropdownItemTypes.option,
      data: { ...instrument }
    }));

    if (dropdownItems.length > 0) {
      dropdownItems = [
        { type: dropdownItemTypes.section, data: { title: 'Recentes' } },
        ...dropdownItems
      ];
    }

    return dropdownItems;
  }

  private created() {
    this.getSearchRequest();
    trackSearchViewLoad();
  }

  private onBackClick() {
    this.$router.push({ name: 'trade' });
  }
}
