
import { Component, Vue } from 'vue-property-decorator';

import { Feature } from '@/modules/common/components/Feature';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { OrdersSection } from './components/OrdersSection';

@Component({
  components: {
    Feature,
    OrdersSection
  }
})
export default class InstrumentDetails extends Vue {
  get FeatureFlags() {
    return FeatureFlags;
  }
}
