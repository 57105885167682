
import { Vue, Component } from 'vue-property-decorator';

import {
  HelperViewContextual,
  HelperViewStatus
} from '@warrenbrasil/nebraska-web';

import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

@Component({
  components: {
    HelperViewContextual,
    HelperViewStatus
  }
})
export default class PlatformMaintenance extends Vue {
  private created() {
    if (!this.isPlatformMaintenance) this.redirectToOverview();
  }

  get isPlatformMaintenance() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.PLATFORM_MAINTENANCE);
  }

  get helperViewData() {
    return {
      title: 'Plataforma em manutenção',
      status: 'alert',
      description:
        'Estamos passando por uma manutenção na nossa plataforma, mas não se preocupe, logo estaremos de volta.'
    };
  }

  redirectToOverview() {
    window.location.href = '/app/#/v3';
  }
}
