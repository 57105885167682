import { GtmTracker } from '@/modules/common/services/analytics';

const trackTradeEvent = GtmTracker.trackerWithDefaultProps({
  category: 'Trade'
});

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'asset_list',
  digital_experience: 'investor',
  experience_section: 'broker'
});

export function trackSearchHighlightViewLoad(highlightTitle: string) {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view',
    list_name: highlightTitle
  });
}

export function trackInstrumentClick(
  highlightTitle: string,
  instrumentSymbol: string,
  instrumentName: string
) {
  trackTradeEvent({
    action: `Clique em ${instrumentSymbol}`,
    label: `Lista Destaque ${highlightTitle}`
  });
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'select_asset',
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol,
    list_name: highlightTitle
  });
}
