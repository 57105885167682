export enum InputStatusType {
  Default = 'default',
  Error = 'error'
}

export interface FormField {
  value: string;
  status: InputStatusType;
  helperText: string;
}
