
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Heading, BaseText } from '@warrenbrasil/nebraska-web';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { Assets } from '../../types';

@Component({
  components: {
    Heading,
    BaseText
  }
})
export default class AssetInfo extends Vue {
  public NebraskaColors = NebraskaColors;

  @Prop({ type: Object, required: true })
  public asset!: Assets;

  @Prop({ type: String, required: false, default: '0' })
  public percent!: string;
}
