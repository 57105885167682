var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"portfolio-card",attrs:{"data-testid":"portfolio-card"}},[_c(_vm.renderAsButton ? 'button' : 'a',{tag:"component",staticClass:"portfolio-card__wrapper",attrs:{"data-testid":"portfolio-item","data-e2e":"portfolio-item","href":_vm.computedLink,"disabled":_vm.disabled},on:{"click":_vm.click,"mouseenter":_vm.handleEnter,"mouseleave":_vm.handleLeave}},[_c('div',{class:{
        'portfolio-card__base--disabled': _vm.disabled,
        'portfolio-card__base': true
      }},[(_vm.hasBackground)?_c('ProgressiveImage',{class:{
          'portfolio-card__base__image': true,
          'portfolio-card__base__image--disabled': _vm.disabled
        },attrs:{"placeholder":_vm.backgroundThumb,"image":_vm.backgroundSmall}}):_c('div',{staticClass:"portfolio-card__base__placeholder"}),_c('div',{staticClass:"portfolio-card__base__content"},[_c('div',[_c('ul',{staticClass:"portfolio-card__base__tags-list"},[(_vm.showManagementIcon)?_c('li',[_c('ChipContext',{class:{
                  'portfolio-card__base__chip-context': true,
                  'portfolio-card__base__chip-context--hover': _vm.hasHover,
                  'portfolio-card__base__chip-context--disabled': _vm.disabled
                },attrs:{"label":_vm.hasHover ? 'Gestão Warren' : '',"icon":"G0010","context":"brand"}})],1):_vm._e(),(_vm.tagStatus)?_c('li',[_c('ChipStatus',{class:{
                  'portfolio-card__base__chip-status': true,
                  'portfolio-card__base__chip-status--disabled': _vm.disabled
                },attrs:{"label":_vm.tagStatus,"status":"information"}})],1):_vm._e()])]),_c('div',[_c('Heading',{staticClass:"portfolio-card__base__title",attrs:{"as":"p","level":3,"data-e2e":"portfolio-name","title":_vm.name}},[_vm._v(" "+_vm._s(_vm.computedPortfolioName)+" ")]),(_vm.shouldShowMoney)?_c('BaseText',{attrs:{"size":"sm","color":_vm.NebraskaColors.textOverImage}},[_vm._v(" "+_vm._s(_vm.balance)+" ")]):_vm._e()],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }