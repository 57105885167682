
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { IDetailedInstrument } from '@/modules/common/services/http/instrument';
import {
  EXPIRATION_FIXED_INCOME_SUB_TYPE,
  liquidationOrExpiration
} from '@/modules/common/helpers/liquidity';
import { InvestorType } from '@/types/models/Portfolio';
import { formattedPercentage } from '@/modules/common/helpers/percentage';
import { moneyViewer } from '@/modules/common/helpers/currency';

import { ProductCharacteristicsItem } from './components/ProductCharacteristicsItem';
import { RiskScore } from '@/modules/common/components/RiskScore';

@Component({
  components: { RiskScore, ProductCharacteristicsItem }
})
export default class ProductCharacteristics extends Vue {
  @Prop({ type: Object, required: true })
  private detailedInstrument!: IDetailedInstrument;

  private get NebraskaColors() {
    return NebraskaColors;
  }

  private get fixedIncomeSubType() {
    return EXPIRATION_FIXED_INCOME_SUB_TYPE;
  }

  private get incomeSectionTitle() {
    return this.detailedInstrument.subtype !== this.fixedIncomeSubType
      ? 'Rendimento últimos 12 meses'
      : 'Rentabilidade';
  }

  private get showExpirationSection() {
    const { daysToExpiration, subtype } = this.detailedInstrument;
    return daysToExpiration && subtype === this.fixedIncomeSubType;
  }

  private get isProductQualified() {
    return this.detailedInstrument?.investorType === InvestorType.QUALIFIED;
  }

  private get totalRedeemTime() {
    const { daysToExpiration, daysToLiquidation } = this.detailedInstrument;
    return daysToExpiration + daysToLiquidation;
  }

  private get isProductRestrictedToProfessionals() {
    return this.detailedInstrument?.investorType === InvestorType.PROFESSIONAL;
  }

  private get hasRiskScore() {
    return this.detailedInstrument.riskScore !== 0;
  }

  private get daysToExpirationFormatted() {
    return liquidationOrExpiration(
      this.detailedInstrument.subtype,
      this.detailedInstrument.daysToExpiration,
      this.detailedInstrument.daysToLiquidation
    );
  }

  private formatPercentage = formattedPercentage;
  private formatMoney = moneyViewer;
}
