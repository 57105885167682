
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavBar, ButtonIconContextual } from '@warrenbrasil/nebraska-web';
import { BackButton } from '@/modules/common/components/buttons/BackButton';

import InstrumentsContainer from '../components/Instruments/InstrumentsContainer.vue';
import CheckoutBar from '@/modules/common/components/CheckoutBar/CheckoutBar.vue';
import PreventUnsavedChangesModal from '../components/PreventUnsavedChangesModal/PreventUnsavedChangesModal.vue';
import WalletCreateProvider from '../providers/WalletCreateProvider.vue';
import WalletEditProvider from '../providers/WalletEditProvider.vue';
import SelectedInstrumentsReview from '../components/Instruments/SelectedInstrumentsReview.vue';
import { Notification } from '@/modules/common/components/Notification';
import { EFilterCategoriesTypes } from '../services/types/models/IFilters';

const FilterModule = namespace('FilterModule');

@Component({
  components: {
    InstrumentsContainer,
    NavBar,
    ButtonIconContextual,
    PreventUnsavedChangesModal,
    WalletCreateProvider,
    WalletEditProvider,
    Notification,
    CheckoutBar,
    SelectedInstrumentsReview,
    BackButton
  }
})
export default class Builder extends Vue {
  @Prop({ type: String })
  public instrumentId?: string;

  @Prop({ type: Boolean, default: false })
  private isTemplate!: boolean;

  @Prop({ type: String })
  public portfolioId?: string;

  @Prop({ type: Boolean, default: false, required: false })
  public isPartner!: boolean;

  public showWalletContent = false;

  public isOpenPreventUnsavedChangesModal = false;

  public WalletProviderComponent?: WalletCreateProvider | WalletEditProvider;

  public isEditor = false;

  public isDeleteMode = false;

  public productSelectedToRemove = { name: '', type: '' };

  @FilterModule.Action('addFilterType')
  private addFilterType!: Function;

  public checkoutSummary(productsQt: number) {
    if (productsQt === 0) {
      return `Nenhum produto selecionado`;
    } else if (productsQt === 1) {
      return `${productsQt} produto selecionado`;
    } else {
      return `${productsQt} produtos selecionados`;
    }
  }

  beforeMount() {
    this.clearFilters();
    if (this.portfolioId) {
      this.isEditor = true;
      this.WalletProviderComponent =
        WalletEditProvider as unknown as WalletEditProvider;
    } else {
      this.isEditor = false;
      this.WalletProviderComponent =
        WalletCreateProvider as unknown as WalletCreateProvider;
    }
  }

  public handleDeleteMode() {
    this.isDeleteMode = !this.isDeleteMode;
  }

  public clearFilters() {
    this.addFilterType({
      filterType: {},
      categoryType: EFilterCategoriesTypes[EFilterCategoriesTypes.FIXEDINCOME]
    });

    this.addFilterType({
      filterType: {},
      categoryType:
        EFilterCategoriesTypes[EFilterCategoriesTypes.VARIABLEINCOME]
    });

    this.addFilterType({
      filterType: {},
      categoryType: EFilterCategoriesTypes[EFilterCategoriesTypes.OTHER]
    });
  }

  public shouldGoBack() {
    this.clearFilters();
    this.isOpenPreventUnsavedChangesModal = true;
  }

  public closePreventUnsavedChangesModal() {
    this.isOpenPreventUnsavedChangesModal = false;
  }

  public confirmPreventUnsavedChangesModal() {
    this.$router.back();
  }

  public get getNavBarTitle() {
    if (this.isDeleteMode) return 'Remover produtos';
    if (this.showWalletContent) return '';

    return this.isEditor ? 'Editar Alocação' : 'Nova Carteira';
  }

  public toggleShowWalletContent() {
    this.isDeleteMode = false;
    this.showWalletContent = !this.showWalletContent;
  }

  public handleInstrumentSelect(apiId: string) {
    const { query, name } = this.$route;

    if (apiId) {
      this.$router.replace({
        name: name!,
        query: { ...query, instrumentId: apiId }
      });
    } else {
      const { instrumentId, ...otherParams } = query;
      if (instrumentId) {
        this.$router.replace({
          name: name!,
          query: Object.assign({}, otherParams)
        });
      }
    }
  }
}
