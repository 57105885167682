
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  HeadingDisplay,
  ContentDisplay,
  MessageFoundation as Message,
  ListDisplay,
  ButtonContainer,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    HeadingDisplay,
    ContentDisplay,
    Message,
    ListDisplay,
    ButtonContainer,
    ButtonPrimary
  }
})
export default class RegulatoryUpdateAddressSummary extends Vue {
  @Prop({ type: String, required: true })
  public zipcode!: string;

  @Prop({ type: String, required: true })
  public street!: string;

  @Prop({ type: [String, Number], default: '' })
  public number!: string;

  @Prop({ type: String, default: '' })
  public neighborhood!: string;

  @Prop({ type: String, required: true })
  public city!: string;

  @Prop({ type: String, required: true })
  public state!: string;

  @Prop({ type: String, default: '' })
  public complement!: string;

  @Prop({ type: Boolean, default: false })
  public isButtonLoading!: boolean;

  @Emit()
  public goToAddNewAddress() {}

  @Emit()
  public saveAddressAndGoToNextPage() {}

  public goToNextPage() {
    this.saveAddressAndGoToNextPage();
  }

  public get addressTitle() {
    if (!!this.number && this.number.length) {
      return `${this.street}, ${this.number}`;
    }

    return this.street;
  }

  public get cityAndState() {
    return `${this.city} - ${this.state}`;
  }
}
