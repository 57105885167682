
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash-es';

import { InstrumentOption } from './components/InstrumentOption';
import {
  Modal,
  InputText,
  ButtonPrimary,
  ButtonSecondary,
  HelperViewStatus,
  SkeletonLoader,
  InfiniteScroll,
  BaseText
} from '@warrenbrasil/nebraska-web';

import { InstrumentFilter } from '@/modules/trade/services/orders';
import { InstrumentSelectionOptions } from './types';
import {
  addBodyScrollWithScrollTo,
  removeBodyScroll
} from '@/modules/common/helpers/browser';
import { InstrumentFilterStatus } from '@/modules/trade/components/InstrumentFilter/types';

@Component({
  components: {
    BaseText,
    Modal,
    ButtonPrimary,
    ButtonSecondary,
    InputText,
    InstrumentOption,
    HelperViewStatus,
    SkeletonLoader,
    InfiniteScroll
  }
})
export default class InstrumentSelectionModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: Array, required: true })
  readonly instruments!: InstrumentFilter[];

  @Prop({ type: [String, Number], required: true })
  readonly selectedOption!: string | number;

  @Prop({
    type: String,
    validator: (status: InstrumentFilterStatus) => {
      return Object.values(InstrumentFilterStatus).includes(status);
    },
    default: InstrumentFilterStatus.Default
  })
  readonly status!: InstrumentFilterStatus;

  @Prop({ type: String, required: false })
  readonly resultLabel?: string;

  @Prop({ type: Function, required: false })
  readonly fetchOptions?: (text: string, page: number) => {};

  currentSelectedOption: string | number = '';
  handleOnInputDebounced = debounce(this.handleOnInput, 600);
  value = '';
  prevValue = '';
  currentPage = 1;
  itemsPerPage = 10;

  readonly InstrumentSelectionOptions = InstrumentSelectionOptions;
  readonly InstrumentFilterStatus = InstrumentFilterStatus;

  @Emit('close')
  emitClose() {}

  @Emit('cancel')
  emitCancel() {}

  @Emit('on-instrument-selection')
  emitOnInstrumentSelection() {
    return this.currentSelectedOption;
  }

  @Emit('on-search')
  emitOnSearch(_text: string) {}

  @Emit('on-retry')
  emitOnRetry() {}

  @Emit('on-load-more')
  emitOnLoadMore() {}

  @Watch('selectedOption', { immediate: true })
  watchSelectedOption(selectedOption: string | number) {
    this.currentSelectedOption = selectedOption;
  }

  @Watch('isOpen', { immediate: true })
  watchIsOpen(isOpen: boolean) {
    if (isOpen) {
      removeBodyScroll();
    } else {
      this.value = '';
      this.currentSelectedOption = this.selectedOption;
      addBodyScrollWithScrollTo();
    }
  }

  get fixedFiltersClass() {
    return {
      'instrument-modal__fixed-filters': true,
      'instrument-modal__fixed-filters--bottom-spacing': !this.resultLabel
    };
  }

  get isFilterButtonEnabled() {
    return (
      this.selectedOption !== this.currentSelectedOption &&
      !this.isLoadingStatus
    );
  }

  get inputTrailingIcon() {
    return this.value.length > 0 ? 'ic_clear' : '';
  }

  get isAllInstrumentOptionSelected() {
    return this.currentSelectedOption === InstrumentSelectionOptions.All;
  }

  get isLoadingStatus() {
    return this.status === InstrumentFilterStatus.Loading;
  }

  get isErrorStatus() {
    return this.status === InstrumentFilterStatus.Error;
  }

  get isDefaultStatus() {
    return this.status === InstrumentFilterStatus.Default;
  }

  async handleFetchMoreOptions() {
    const isSearchStringSameAsPrevious = this.value === this.prevValue;
    if (isSearchStringSameAsPrevious) {
      this.currentPage++;
    } else {
      this.currentPage = 2;
    }

    const prevFetchedPage = this.currentPage - 1;
    const hasMoreItemsToFetch =
      this.instruments.length >= prevFetchedPage * this.itemsPerPage;
    this.prevValue = this.value;

    if (!hasMoreItemsToFetch) {
      return;
    }

    if (this.fetchOptions)
      await this.fetchOptions(this.value, this.currentPage);
  }

  handleOnSelect(value: string | number) {
    this.currentSelectedOption = value;
  }

  handleOnInput(value: string) {
    this.emitOnSearch(value);
  }

  clearInputText() {
    this.value = '';
    this.emitOnSearch(this.value);
  }
}
