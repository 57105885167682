
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getString } from '@/modules/common/helpers/resources';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { BaseText } from '@warrenbrasil/nebraska-web';

@Component({
  components: { BaseText }
})
export default class UnderApprovalNotification extends Vue {
  @Prop({ type: Boolean, default: false, required: true })
  private isRegisterRejected!: boolean;

  @Prop({ type: Boolean, default: false, required: true })
  private hasRegulationUpdate!: boolean;

  private getString = getString;

  get NebraskaColors() {
    return NebraskaColors;
  }
}
