
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BaseCell, BaseText } from '@warrenbrasil/nebraska-web';
import { Money } from '@/modules/common/components/MoneyV2';

import {
  NebraskaSizes,
  NebraskaColors
} from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    BaseCell,
    Money,
    BaseText
  }
})
export default class MoneyCell extends Vue {
  @Prop({ type: String, default: '100%' })
  readonly width!: string;

  @Prop({ type: String, default: NebraskaSizes.Radius.x100 })
  readonly borderRadius!: string;

  @Prop({ type: [String, Number], required: true })
  readonly text!: string | number;

  @Prop({ type: [String, Number], required: false })
  readonly description?: string | number;

  @Prop({ type: String, default: 'left' })
  readonly align!: string;

  @Prop({ type: Boolean, default: true })
  readonly enableToggleMoneyOnText!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly enableToggleMoneyOnDescription!: boolean;

  readonly NebraskaColors = NebraskaColors;

  get textComponent() {
    return this.enableToggleMoneyOnText ? Money : 'span';
  }

  get descriptionComponent() {
    return this.enableToggleMoneyOnDescription ? Money : 'span';
  }
}
