import { httpClient } from '@/modules/common/services/http/http-client';
import { getCustomerId } from '@/modules/common/services/local-storage';
import {
  IFirstPortfolioAgeResponse,
  ISavingFeesResponse,
  ICostsByYear,
  ICostsByMonthResponse,
  IFeePercentage,
  ICashbackResponse
} from './types';

export function getFirstPortfolioAge(): Promise<IFirstPortfolioAgeResponse> {
  return httpClient.get(
    `api/v1/portfolios/firstPortfolioAge/customer/${getCustomerId()}`
  );
}

export function getSavingFees(): Promise<ISavingFeesResponse> {
  return httpClient.get(`api/v2/costs/customer/${getCustomerId()}/savingsFees`);
}

export function getFeesByYear(year: number): Promise<ICostsByYear> {
  return httpClient.get(
    `api/v1/costs/customer/${getCustomerId()}/year/${year}`
  );
}

export function getFeesByMonth(
  year: number,
  month: number
): Promise<ICostsByMonthResponse> {
  return httpClient.get(
    `api/v2/costs/customer/${getCustomerId()}/year/${year}/month/${month}`
  );
}

export function getFeesPercentage(): Promise<IFeePercentage[]> {
  return httpClient.get(`api/v1/costs/customer/${getCustomerId()}/taxes`);
}

export function getCashback(): Promise<ICashbackResponse> {
  return httpClient.get(`api/v2/costs/customer/${getCustomerId()}/cashback`);
}
