
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import {
  InputStep,
  SingleInput,
  ShortcutValue,
  BaseText,
  ButtonContainer,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';
import { VMoney } from 'v-money';
import { currencyToNumber } from '@/modules/common/helpers/currency';
import {
  EStatusType,
  ENewTransferSteps
} from '@/modules/transaction/views/NewTransfer/helpers';
import {
  IValueFormatted,
  IWithdrawSuggestion
} from '@/modules/transaction/services/new-transfer';

@Component({
  components: {
    InputStep,
    SingleInput,
    ShortcutValue,
    BaseText,
    ButtonContainer,
    ButtonPrimary
  }
})
export default class NewTransferAmountSelection extends Vue {
  @Ref('singleInput')
  readonly singleInput!: Vue;

  @Prop({ type: String, required: true })
  readonly amountValue!: string;

  @Prop({ type: Object, required: true })
  readonly balanceAvailable!: IValueFormatted;

  @Prop({ type: Array, required: true })
  readonly withdrawSuggestions!: IWithdrawSuggestion[];

  @Emit('change-amount-value')
  emitChangeAmountValue(_amountValue: string) {
    // Atualiza o valor da transferência com o valor emitido
  }

  @Emit('change-step')
  emitChangeStep(_step: ENewTransferSteps) {
    // Muda para o step emitido
  }

  @Watch('amountValue', { immediate: true })
  watchAmountValue(amountValue: string) {
    const amountValueNumber = currencyToNumber(amountValue);
    if (amountValueNumber <= 0) {
      this.isDisabled = true;
      this.setAmountValueHelperText('');
    } else if (amountValueNumber > this.balanceAvailable.value) {
      this.isDisabled = true;
      this.setAmountValueHelperText('Você não possui saldo suficiente.');
    } else {
      this.isDisabled = false;
      this.setAmountValueHelperText('');
    }
  }

  amountValueHelperText = '';
  isDisabled = true;

  get formatterOptions() {
    return {
      decimal: ',',
      thousands: '.',
      prefix: '',
      precision: 2
    };
  }

  get value() {
    return this.amountValue;
  }

  get singleInputStatus() {
    return this.amountValueHelperText ? EStatusType.ERROR : EStatusType.DEFAULT;
  }

  mounted() {
    const textarea = this.singleInput.$el.querySelector(
      'textarea'
    ) as HTMLElement;
    VMoney(textarea, { value: this.formatterOptions });
  }

  shortcutClick(option: IWithdrawSuggestion) {
    const options = { minimumFractionDigits: 2 };
    const numberFormat = new Intl.NumberFormat('pt-BR', options);

    if (option.type === 'SET') {
      this.emitChangeAmountValue(
        numberFormat.format(Math.floor(option.value * 100) / 100)
      );
    } else {
      const formatted =
        parseFloat(option.value.toFixed(2)) +
        currencyToNumber(this.amountValue);
      this.emitChangeAmountValue(numberFormat.format(formatted));
    }
  }

  setAmountValueHelperText(helperText: string) {
    this.amountValueHelperText = helperText;
  }

  handleChangeAmountValue(event: Event) {
    const target = event.target as HTMLInputElement;
    this.emitChangeAmountValue(target.value);
  }

  clearAmountValue() {
    this.emitChangeAmountValue('');
  }

  goBack() {
    this.$router.back();
  }

  changeStep() {
    this.emitChangeStep(ENewTransferSteps.BANK_SELECTION);
  }
}
