
import { Component, Vue, Prop } from 'vue-property-decorator';
import { useDashStore } from '../../store/DashModule';

@Component
export default class Money extends Vue {
  private readonly dashModule = useDashStore();

  @Prop({ type: Boolean, default: undefined })
  public show?: boolean;

  private get computedClasses() {
    return ['money-text', { 'money-text--hidden': this.isHidden }];
  }

  private get isHidden() {
    const isControlled = this.show !== undefined && this.show !== null;
    return isControlled ? !this.show : !this.dashModule?.showMoney;
  }
}
