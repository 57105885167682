export interface SignInForm {
  email: string;
  password: string;
  token: string;
}

export enum SignInFormSteps {
  Email = 'email',
  PasswordSignIn = 'password_signin',
  PasswordCreation = 'password_creation'
}

export enum ForgotPasswordModalSteps {
  Input = 'input',
  Success = 'success'
}

export interface YupError {
  key: string;
  value: string;
}

export interface ConfirmResponse {
  acceptedTerms: boolean;
}
