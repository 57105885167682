import { NavigationGuard } from 'vue-router';

import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';

export const redeemByProductsGuard: NavigationGuard = (_to, _from, next) => {
  const isFeatureFlagEnabled = FeatureFlagsModule.isEnabled(
    FeatureFlags.WALLET_WITHDRAW_BY_PRODUCT
  );

  if (!isFeatureFlagEnabled) {
    return next({
      name: 'wealthPortfolio',
      params: {
        id: _to.params.id
      }
    });
  }

  next();
};
