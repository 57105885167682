
import { Component, Vue } from 'vue-property-decorator';

import { ButtonIconContextual } from '@warrenbrasil/nebraska-web';

import { useDashStore } from '@/modules/common/store/DashModule';

@Component({
  components: { ButtonIconContextual }
})
export default class ShowMoneyToggleButton extends Vue {
  readonly dashModule = useDashStore();

  get computedToggleIcon() {
    return this.dashModule?.showMoney ? 'EE0130' : 'EE0131';
  }

  get computedToggleLabel() {
    return this.dashModule?.showMoney ? 'Esconder valores' : 'Mostrar valores';
  }

  public toggleClick() {
    this.dashModule.toggleShowMoney();
  }
}
