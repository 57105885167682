
import { Component, Vue } from 'vue-property-decorator';
import { NavBar, BaseModal } from '@warrenbrasil/nebraska-web';
import { CloseButton } from '@/modules/common/components/buttons';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { DocumentModule } from '@/modules/pension/store/document';
import ModalFooter from './ModalFooter.vue';
import StepDetails from './StepDetails.vue';
import StepList from './StepList.vue';
import StepMFA from './StepMFA.vue';
import StepSuccess from './StepSuccess.vue';
import StepRejection from './StepRejection.vue';
import StepTerms from './StepTerms.vue';

@Component({
  name: 'ModalSuggestions',
  components: {
    ModalFooter,
    StepDetails,
    StepList,
    StepMFA,
    StepRejection,
    StepSuccess,
    StepTerms,
    BaseModal,
    NavBar,
    BackButton,
    CloseButton
  }
})
export default class ModalSuggestions extends Vue {
  get isOpen() {
    return DocumentModule.data.modal.open;
  }

  get stepsInfo() {
    return DocumentModule.modalStepsInfo;
  }

  get shouldShowBackButton() {
    return DocumentModule.shouldShowModalBackButton;
  }

  get shouldShowFooter() {
    const { is } = this.stepsInfo;
    return is.details || is.mfa || is.success || is.rejection;
  }

  back() {
    DocumentModule.modalNavigateBack();
  }

  close() {
    DocumentModule.modalClose();
  }
}
