
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import HPie from 'highcharts/modules/variable-pie';

import {
  NebraskaColors,
  NebraskaSizes
} from '@warrenbrasil/nebraska-tokens-web';
import {
  SeriesData,
  PointSeries
} from '@/modules/common/components/charts/v2/RadiusPie/types';
import { useDashStore } from '@/modules/common/store/DashModule';

HPie(Highcharts);

@Component({
  name: 'RadiusPieChart',
  components: {
    Highcharts: Chart
  }
})
export default class RadiusPieChart extends Vue {
  @Prop({ type: Number, required: false })
  readonly width?: number;

  @Prop({ type: Number, required: false })
  readonly height?: number;

  @Prop({ type: Array, required: true })
  readonly seriesData!: SeriesData[];

  @Prop({ type: Object, required: true })
  readonly pointSeries!: PointSeries;

  @Prop({ type: Boolean, default: true })
  readonly tooltipEnabled!: boolean;

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly titlePercentage!: string;

  @Prop({ type: String, required: true })
  readonly emptyStateText!: string;

  @Prop({ type: String, required: true })
  readonly titleTotalValue!: string;

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly enableToggleMoney!: boolean;

  private readonly dashModule = useDashStore();

  get shouldShowMoney() {
    return this.enableToggleMoney ? this.dashModule.showMoney : true;
  }

  get errorText() {
    return `
      <div class="text-p-5" :style="color: ${NebraskaColors.textSecondary}">
        Erro ao carregar os dados.
      </div>
    `;
  }

  get emptyText() {
    return `
      <div class="text-p-5" :style="color: ${NebraskaColors.textSecondary}">
        ${this.emptyStateText}
      </div>
    `;
  }

  get defaultText() {
    return `
      <div>
        <p class="text-p-5" style="font-weight: bold; text-align: center;">${
          this.title
        }</p>
        <p class="text-p-1" style="font-weight: bold; text-align: center;">
          ${
            this.shouldShowMoney
              ? this.titlePercentage
              : `<span style="display: block; margin: 0 auto; width: 150px; background-color: ${NebraskaColors.elementSecondary}; border-radius: ${NebraskaSizes.Radius.x100};">&#x200b;</span>`
          }
        </p>
        <p class="text-p-5" style="color: ${
          NebraskaColors.textSecondary
        }; text-align: center;">
          ${
            this.shouldShowMoney
              ? this.titleTotalValue
              : `<span style="display: block; margin: 0 auto; width: 90px; background-color: ${NebraskaColors.elementSecondary}; border-radius: ${NebraskaSizes.Radius.x100};">&#x200b;</span>`
          }
        </p>
      </div>
    `;
  }

  get text() {
    if (this.error) return this.errorText;
    if (this.seriesData.length === 0) return this.emptyText;
    return this.defaultText;
  }

  get emptySeriesData() {
    return [
      {
        name: 'Erro',
        z: 1,
        y: 1000,
        color: NebraskaColors.elementDisabled
      } as Highcharts.PointOptionsObject
    ];
  }

  public get chartOptions(): Highcharts.Options {
    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'variablepie',
        backgroundColor: NebraskaColors.backgroundSecondary,
        height: this.height,
        width: this.width,
        margin: 0
      },
      credits: {
        enabled: false
      },
      title: {
        text: this.text,
        verticalAlign: 'middle',
        align: 'center',
        useHTML: true,
        style: {
          color: NebraskaColors.textPrimary
        }
      },
      plotOptions: {
        variablepie: {
          cursor: 'pointer',
          allowPointSelect: true,
          borderColor: NebraskaColors.backgroundSecondary,
          dataLabels: {
            enabled: false
          },
          states: {
            hover: {
              enabled: true,
              halo: {
                size: 0
              }
            },
            inactive: {
              opacity: 0.3
            }
          }
        }
      },
      series: [
        {
          type: 'variablepie',
          minPointSize: 0,
          slicedOffset: 0,
          size: '100%',
          innerSize: '90%',
          zMin: 0,
          data:
            this.error || this.seriesData.length === 0
              ? this.emptySeriesData
              : this.seriesData,
          point: this.pointSeries
        }
      ]
    };

    if (!this.tooltipEnabled) {
      chartOptions.tooltip = {
        enabled: false
      };
    }

    return chartOptions;
  }
}
