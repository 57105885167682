
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Heading } from '@warrenbrasil/nebraska-web';
import { DownloadButton } from '@/modules/common/components/buttons';

type DownloadItem = {
  title: string;
  description: string;
  url: string;
};

@Component({
  components: { Heading, DownloadButton }
})
export default class DownloadList extends Vue {
  @Prop({ type: Array, required: true })
  public list!: DownloadItem[];

  @Prop({ type: String })
  public title?: string;
}
