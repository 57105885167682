
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  HeadingDisplay,
  Row,
  InitialAvatarIcon,
  ButtonContainer,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';
import {
  RegulatoryUpdateNavigation,
  RegulatoryUpdateSteps
} from '@/modules/account/views/RegulatoryUpdate/types';
import { saveNextRoutes } from '@/modules/common/store/RegulatoryUpdateRoutesOrchestrator';

@Component({
  components: {
    HeadingDisplay,
    Row,
    InitialAvatarIcon,
    ButtonContainer,
    ButtonPrimary
  }
})
export default class RegulatoryUpdateAnnouncementPF extends Vue {
  @Prop({ type: String, default: '' })
  public title!: string;

  @Prop({ type: String, default: '' })
  public description!: string;

  @Prop({ type: Array, default: () => [] })
  public steps!: RegulatoryUpdateSteps[];

  @Prop({ type: Object, default: () => ({}) })
  public navigation!: RegulatoryUpdateNavigation;

  @Emit()
  public goToNextPage() {}

  mounted() {
    if (this.navigation.actions) {
      saveNextRoutes(this.navigation.actions);
    }
  }
}
