
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import { AvailableInvest } from './components/AvailableInvest';
import { LiquidateNextDays } from './components/LiquidateNextDays';
import { CompositionSummary } from './components/CompositionSummary';
import { AvailableWithdrawal } from './components/AvailableWithdrawal';
import { SidebarTypes } from '@/modules/trade/views/TradeView/components/TradeSidebar';
import { SummaryTypes } from './types';
import {
  trackAvailableBalanceLiquidateDetails,
  trackLiquidateDateClick
} from '@/modules/trade/views/TradeView/trackers';
import { Balances, ProjectedBalances } from '@/modules/trade/services/account';

@Component({
  components: {
    AvailableInvest,
    LiquidateNextDays,
    CompositionSummary,
    AvailableWithdrawal
  }
})
export default class SummaryList extends Vue {
  @Prop({ type: Object, required: true })
  readonly balances!: Balances;

  @Prop({ type: Number, required: false })
  readonly initialType?: SummaryTypes;

  readonly sidebarTypes = SidebarTypes;
  readonly summaryTypes = SummaryTypes;

  public summaryStep = SummaryTypes.AvaliableInvested;
  public liquidateData: ProjectedBalances = {} as ProjectedBalances;

  @Emit('set-panel')
  public emitSetPanel(type: SidebarTypes) {
    return type;
  }

  created() {
    if (this.initialType) this.setSummaryStep(this.initialType);
  }

  public setSummaryStep(step: SummaryTypes) {
    this.summaryStep = step;
  }

  public setComposition(liquidateItem: ProjectedBalances) {
    trackLiquidateDateClick(liquidateItem.label);
    if (this.initialType === SummaryTypes.AvaliableInvested) {
      trackAvailableBalanceLiquidateDetails(liquidateItem.label);
    }
    this.liquidateData = liquidateItem;
    this.summaryStep = SummaryTypes.Composition;
  }
}
