export const data = {
  title: 'Calculadora Automática de IR',
  description:
    'Agora, na Warren você tem acesso integrado à calculadora automática de imposto de renda para ativos negociados na bolsa de valores.',
  cards: [
    {
      title: 'Aprove a integração com a Velotax',
      description:
        'Ao continuar, nós criaremos uma conta para você dentro da plataforma da nossa parceira, Velotax. Lá você terá acesso à ferramenta para calcular seu imposto de renda e gerar DARF automaticamente.'
    },
    {
      title: 'Conecte sua conta B3 Investidor',
      description:
        'Siga o tutorial apresentado pela Velotax no primeiro acesso para conectar suas informações da B3 com a calculadora automática.'
    },
    {
      title: 'Calcule os impostos automaticamente',
      description:
        'Após o cálculo, caso queira gerar a DARF utilizar a ferramenta para calcular seus impostos, será cobrada uma mensalidade de R$19,90 para clientes Warren diretamente pela parceira Velotax.'
    }
  ],
  disclaimer:
    'Ao aprovar a integração, você concorda em compartilhar as informações e dados pessoais da sua conta B3 com a Velotax para calcular o seu imposto de renda e gerar DARF automaticamente, nos termos da política de privacidade da Warren e da Velotax.',
  error: {
    title: 'Desculpe, não conseguimos carregar as suas informações',
    description: 'Aguarde um momento e tente novamente',
    buttonLabel: 'Tentar novamente'
  }
};
