import { httpClient } from '@/modules/common/services/http/http-client';
import { IMarketToday } from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getMarketToday(filter: string): Promise<IMarketToday> {
  return httpClientWithDefaultOptions.get('/marketHighlights', {
    params: { filter }
  });
}
