import { GtmTracker } from '@/modules/common/services/analytics';
import { IWithdrawProduct } from '../../services/withdraw';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'portfolio_instrument_withdrawal_flow',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackViewLoad() {
  trackEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackSelectTotalWithdrawal(value: number) {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'select_total_withdrawal',
    withdrawal_value: value
  });
}

export function trackProductUptadeValue(product: IWithdrawProduct) {
  trackEvent({
    event_type: 'select',
    page_screen_section: 'portfolios',
    event_name: 'instrument_withdrawal_value',
    instrument_name: product.name!,
    withdrawal_available_value: product.totalGrossWorthAvailable.value,
    withdrawal_minimum_value: product.minimumBalance.value,
    withdrawal_days_value: product.daysToRedeem,
    withdrawal_value: product.valueToInvest!,
    instrument_id: product.id
  });
}

export function trackContinueToReviewPage(value: number) {
  trackEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'withdraw_money',
    withdrawal_value: value
  });
}
