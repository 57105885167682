import { InstrumentPositionFilterTypes } from './types';

export const InstrumentPositionFilters = [
  {
    label: 'Posição',
    value: InstrumentPositionFilterTypes.Today
  },
  {
    label: 'Rentabilidade',
    value: InstrumentPositionFilterTypes.Historical
  }
];
