
import { Component, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  HelperViewContextual,
  HelperViewStatus,
  IconButton,
  BaseIcon,
  BaseText,
  SpinnerLoader,
  Heading,
  NavBar,
  Row,
  Modal,
  ModalButton,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { IStatementFilter } from '@/modules/common/services/http/overview';
import { forceFileDownload } from '@/modules/common/helpers/force-download';
import { StatementType } from '@/modules/investments/types/statementType';
import {
  getStatementInformations,
  PageInformations
} from './statement-informations';
import { TranckEvents, getStatementTrackers } from './statement-tracking';

@Component({
  components: {
    HelperViewContextual,
    HelperViewStatus,
    NavBar,
    Heading,
    Row,
    LoaderIndicator,
    BaseText,
    SpinnerLoader,
    BaseIcon,
    IconButton,
    Modal,
    ModalButton
  }
})
export default class StatementDownloadView extends Vue {
  NebraskaColors = NebraskaColors;
  statements: IStatementFilter[] | [] = [];
  isLoading = true;
  hasError = false;
  hasDownloadError = false;
  errorMessage = '';
  hasYearsAndMonths = false;
  errorMessageTitle = '';
  errorMessageSubTitle = '';
  errorMessageIcon = '';
  errorMessageType = '';
  errorMessageButton = '';
  errorMessageButtonLoading = false;
  errorMessageButtonDisabled = false;
  year = 0;
  month = 0;

  created() {
    this.loadData();
  }

  mounted() {
    this.statementTrackers.sendLoadTrack();
  }

  private get statementType() {
    return this.$route.params.statementType as StatementType;
  }

  get pageInformations(): PageInformations {
    return getStatementInformations(this.statementType);
  }

  get statementTrackers(): TranckEvents {
    return getStatementTrackers(this.statementType);
  }

  get hasErrorIcon() {
    return this.errorMessageIcon !== undefined;
  }

  async loadData() {
    try {
      this.hasError = false;
      this.isLoading = true;
      const statements = await this.pageInformations.getFilters(
        this.statementType
      );
      if (statements[0].months) {
        this.statements = this.parseYearsAndMonths(statements);
        this.hasYearsAndMonths = true;
      } else {
        this.statements = statements;
      }
    } catch (error) {
      const { title, subTitle, icon, type, buttonLabel } =
        await this.pageInformations.getDefaultErrorMessage(error);
      this.errorMessageTitle = title;
      this.errorMessageSubTitle = subTitle;
      this.errorMessageIcon = icon;
      this.errorMessageButton = buttonLabel;
      this.errorMessageType = type;
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  async handleHelperViewClick() {
    this.errorMessageButtonLoading = true;
    this.errorMessageButton === 'Voltar'
      ? this.onBackClick()
      : await this.onClickDownload(this.year, this.month);
    this.errorMessageButtonLoading = false;
  }

  reloadPage() {
    return this.$router.go(0);
  }

  private parseYearsAndMonths(data: IStatementFilter[]) {
    return data.map(statement => ({
      ...statement,
      months: statement.months.map(month => ({
        ...month,
        isLoading: false
      }))
    }));
  }

  private get customer() {
    return CustomerModule.getCustomer!;
  }

  onBackClick() {
    if (this.hasDownloadError) this.hasDownloadError = false;
    else {
      this.statementTrackers.sendBackTrack();
      this.$router.push({ name: 'home' });
    }
  }

  async onClickDownload(year: number, month: number) {
    try {
      const date = month > 0 ? `${year}-${month}` : String(year);
      this.year = year;
      this.month = month;

      this.toggleItemLoading(year, month);
      const statement = await this.pageInformations.getStatement(
        this.customer._id,
        date,
        month,
        year
      );
      this.hasDownloadError = false;
      this.statementTrackers.sendDownloadTrack(year, month);

      forceFileDownload({
        data: statement,
        type: 'application/pdf',
        fileName: `${this.pageInformations.fileName}-${date}.pdf`
      });
    } catch (err) {
      const { title, subTitle, icon, type, buttonLabel } =
        await this.pageInformations.getErrorMessage(err);
      this.errorMessageTitle = title;
      this.errorMessageSubTitle = subTitle;
      this.errorMessageIcon = icon;
      this.errorMessageType = type;
      this.errorMessageButton = buttonLabel;

      this.hasDownloadError = true;
    } finally {
      this.toggleItemLoading(year, month);
    }
  }

  private toggleItemLoading(year: number, month: number) {
    const statementYearIndex = this.statements.findIndex(
      statement => statement.year === year
    );
    if (this.statements[0].months) {
      this.statements[statementYearIndex].months.forEach(statementMonth => {
        if (statementMonth.value === month) {
          statementMonth.isLoading = !statementMonth.isLoading;
        }
      });
    } else {
      this.statements.forEach(statement => {
        if (statement.year === year) {
          statement.isLoading = !statement.isLoading;
        }
      });
    }
  }

  closeErrorModal() {
    this.hasDownloadError = false;
  }
}
