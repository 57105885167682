
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CountBadge extends Vue {
  @Prop({ type: String, default: 'md' })
  public size!: string;

  @Prop({ type: Number, required: true })
  public count!: number;

  @Prop({ type: String, required: false })
  public backgroundColor!: string;

  @Prop({ type: String, required: false })
  public textColor!: string;

  private get computedStyle() {
    const classes = [];

    switch (this.size) {
      case 'sm':
        classes.push('text-p-6', 'size-sm');
        break;
      default:
        classes.push('text-p-5', 'size-md');
    }

    return classes;
  }

  private get customBackgroundColor() {
    return this.backgroundColor && { 'background-color': this.backgroundColor };
  }

  private get customTextColor() {
    return this.textColor && { color: this.textColor };
  }
}
