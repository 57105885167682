import { VueConstructor } from 'vue';
import Notification from 'vue-notification';

/**
 * This is a workaround to be able to use vue-notification with composition api.
 * As it is not possible to use the plugin in a composition api component, the library is created to use with Vue 2 and don`t have a compatible version with Vue 3.
 * When migrating to Vue 3 we need to remove this workaround and use a compatible library directly.
 */
const fakeVueConstructor = {
  component: () => {},
  prototype: {}
} as unknown as VueConstructor;

// @ts-ignore
Notification.installed = false;

Notification.install(fakeVueConstructor, 'notify');

// @ts-ignore
Notification.installed = false;

export const notify = fakeVueConstructor.notify;
