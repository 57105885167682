
import { Component, Prop, Emit, InjectReactive } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { InstrumentChart } from './components/InstrumentChart';
import { InstrumentSidebar } from '../InstrumentSidebar';
import { TwoColumnsContainer } from '@/modules/common/components/TwoColumnsContainer';
import { InstrumentAbout } from './components/InstrumentAbout';
import { TradeInstrumentPositionPrice } from '@/modules/trade/types/instrument';
import { InstrumentPosition } from './components/InstrumentPosition';

import {
  IInstrumentAbout,
  InstrumentStatus
} from '@/modules/trade/services/instruments';
import { IInstrumentStatus } from '@/modules/trade/services/signalr';
import { RouteMixin } from '@/modules/common/mixins/route';
import { NumberFormatted, StringFormatted } from '@/modules/trade/types';
import { AccountStatus } from '@/modules/trade/services/account';

@Component({
  components: {
    InstrumentAbout,
    InstrumentChart,
    InstrumentSidebar,
    InstrumentPosition,
    TwoColumnsContainer
  }
})
export default class InstrumentContent extends mixins(RouteMixin) {
  @Prop({ type: Object, required: true })
  readonly latestPrice!: NumberFormatted;

  @Prop({ type: Object, required: true })
  readonly latestPriceDate!: StringFormatted;

  @Prop({ type: Object, required: true })
  readonly balanceAvailable!: NumberFormatted;

  @Prop({ type: Object, required: true })
  readonly status!: AccountStatus;

  @Prop({ type: Number, required: true })
  readonly bestOfferBuy!: number;

  @Prop({ type: Number, required: true })
  readonly bestOfferSell!: number;

  @Prop({ type: Object, required: false })
  readonly position!: TradeInstrumentPositionPrice;

  @Prop({ type: Boolean, required: true })
  readonly canOperate!: boolean;

  @Prop({ type: Object })
  readonly instrumentStatus!: IInstrumentStatus;

  @Prop({ type: Object, required: false })
  readonly instrumentAbout!: IInstrumentAbout | Object;

  @Prop({ type: Object, required: true })
  readonly instrument!: InstrumentStatus;

  @Prop({ type: String, default: '' })
  readonly description!: string;

  private orderId = '';

  @InjectReactive('ticker')
  readonly ticker!: string;

  @Emit('on-accept-risk-terms')
  emitAcceptRiskTerms() {}

  @Emit('open-enable-broker-account-modal')
  emitOpenEnableBrokerAccountModal() {}

  private get computedSidebarClass() {
    return this.hasSidebarFixed ? 'instrument-content__sidebar-fixed' : '';
  }

  private get hasSidebarFixed() {
    return !this.position && this.hasInstrumentAbout;
  }

  private get hasInstrumentAbout() {
    return Object.keys(this.instrumentAbout).length > 0;
  }

  private get isMarketAvailable() {
    return this.status?.marketAvailable?.isMarketAvailable;
  }

  private get marketAvailableText() {
    return this.status?.marketAvailable?.innerDescription;
  }

  private refreshInstruments() {
    this.$emit('refresh-instruments');
  }
}
