
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WarrenModules } from '@/types/navigation';
import { InvestmentTransactionProvider } from './components/InvestmentTransactionProvider';
import { WealthTransaction } from './components/WealthTransaction';

@Component({
  components: { InvestmentTransactionProvider, WealthTransaction }
})
export default class InvestmentTransactionView extends Vue {
  @Prop({ type: String, required: true })
  private portfolio!: string;

  private get getModule() {
    const moduleFromQuery = this.$route.query.module as WarrenModules;
    return moduleFromQuery || WarrenModules.Wealth;
  }
}
