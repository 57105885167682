
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BaseCell } from '@warrenbrasil/nebraska-web';
import { InstrumentPrice } from '@/modules/trade/components/InstrumentPrice';

import { NumberFormatted } from '@/modules/trade/types';

@Component({
  components: {
    BaseCell,
    InstrumentPrice
  }
})
export default class InstrumentPriceCell extends Vue {
  @Prop({ type: Object, required: true })
  readonly price!: NumberFormatted;

  @Prop({ type: Object, required: false })
  readonly percentual?: NumberFormatted;

  @Prop({ type: String, default: 'left' })
  readonly align!: string;

  @Prop({ type: Boolean, default: false })
  readonly enableToggleMoney!: boolean;
}
