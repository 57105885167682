import RegulatoryUpdateTemplate from '../views/RegulatoryUpdateTemplate/RegulatoryUpdateTemplate.vue';
import RegulatoryUpdateAnnouncement from '../views/RegulatoryUpdateAnnouncement/RegulatoryUpdateAnnouncement.vue';
import RegulatoryUpdatePersonalData from '../views/RegulatoryUpdatePersonalData/RegulatoryUpdatePersonalData.vue';
import RegulatoryUpdateAddress from '../views/RegulatoryUpdateAddress/RegulatoryUpdateAddress.vue';
import RegulatoryUpdateInvestmentProfile from '../views/RegulatoryUpdateInvestmentProfile/RegulatoryUpdateInvestmentProfile.vue';
import RegulatoryUpdateVerifyPassword from '../views/RegulatoryUpdateVerifyPassword/RegulatoryUpdateVerifyPassword.vue';
import RegulatoryUpdateSuccess from '../views/RegulatoryUpdateSuccess/RegulatoryUpdateSuccess.vue';
import RegulatoryUpdateAcceptTerms from '../views/RegulatoryUpdateAcceptTerms/RegulatoryUpdateAcceptTerms.vue';
import RegulatoryUpdatePersonalRegister from '../views/RegulatoryUpdatePersonalRegister/RegulatoryUpdatePersonalRegister.vue';
import { regulatoryUpdateGuard } from '../helpers/regulatory-update-guard';

export const regulatoryUpdateRoutes = [
  {
    name: 'regulatoryUpdateSuccess',
    path: '/regulatory-update/success',
    meta: { context: 'account' },
    beforeEnter: regulatoryUpdateGuard,
    component: RegulatoryUpdateSuccess
  },
  {
    name: 'regulatoryTerms',
    path: '/regulatory-update/terms',
    meta: { context: 'account' },
    beforeEnter: regulatoryUpdateGuard,
    component: RegulatoryUpdateAcceptTerms
  },
  {
    name: 'personalRegisterUpdate',
    path: '/regulatory-update/personal-register',
    meta: { context: 'account' },
    beforeEnter: regulatoryUpdateGuard,
    component: RegulatoryUpdatePersonalRegister
  },
  {
    name: 'regulatory-update',
    path: '/regulatory-update',
    component: RegulatoryUpdateTemplate,
    meta: { context: 'account' },
    redirect: () => '/regulatory-update/announcement',
    children: [
      {
        name: 'regulatoryUpdateAnnouncement',
        path: 'announcement',
        component: RegulatoryUpdateAnnouncement
      },
      {
        name: 'regulatoryUpdatePersonalData',
        path: 'personal-data',
        beforeEnter: regulatoryUpdateGuard,
        component: RegulatoryUpdatePersonalData
      },
      {
        name: 'regulatoryUpdateAddress',
        path: 'address',
        beforeEnter: regulatoryUpdateGuard,
        component: RegulatoryUpdateAddress
      },
      {
        name: 'regulatoryUpdateInvestmentProfile',
        path: 'investment-profile',
        beforeEnter: regulatoryUpdateGuard,
        component: RegulatoryUpdateInvestmentProfile
      },
      {
        name: 'regulatoryUpdateVerifyPassword',
        path: 'verify-password',
        beforeEnter: regulatoryUpdateGuard,
        component: RegulatoryUpdateVerifyPassword
      }
    ]
  }
];
