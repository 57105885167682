import { BaseHttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';
import { IMFALoginResponse } from '@/modules/authentication/services/mfa';
import {
  IFastRegisterLeadRequest,
  IFastRegisterLeadResponse,
  IFastRegisterSignUpRequest,
  IPasswordValidationRequest,
  IPasswordValidationResponse
} from '../types';

export class FastRegisterAPI extends BaseHttpClient {
  public registerFastRegisterLead(
    data: IFastRegisterLeadRequest
  ): Promise<IFastRegisterLeadResponse> {
    return this.post('/api/v3/account/signup', data);
  }

  public signUpFastRegisterLead(
    data: IFastRegisterSignUpRequest
  ): Promise<IMFALoginResponse> {
    return this.put('/api/v3/account/signup', data);
  }

  public verifyPassword(
    data: IPasswordValidationRequest
  ): Promise<IPasswordValidationResponse> {
    return this.post('/api/v3/account/password/verify', data);
  }
}
