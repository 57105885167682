
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NotificationItem } from './components/NotificationItem';
import { INotification } from '@/modules/common/services/http/notification';
import { getString } from '@/modules/common/helpers/resources';
import { NotificationsModule } from '@/store/modules/NotificationsModule';

@Component({
  components: { NotificationItem }
})
export default class NotificationList extends Vue {
  @Prop({ type: Array })
  private notificationData!: INotification[];

  private getString = getString;

  private destroyed() {
    const ids = this.notificationData
      .filter(notification => !notification.isRead)
      .map(notification => notification._id);

    if (ids.length > 0) {
      NotificationsModule.markNotificationsHasRead(ids);
    }
  }
}
