
import {
  Vue,
  Component,
  Emit,
  Prop,
  InjectReactive
} from 'vue-property-decorator';
import {
  trackHelpSubscriptionPage,
  trackReserveSubscriptionPageActions,
  TrackSubscriptionPageActions
} from '@/modules/trade/views/Subscription/tracker';
import {
  BaseText,
  Row,
  ButtonPrimary,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { SubscriptionOrderContainer } from '../SubscriptionOrderContainer';
import { SubscriptionTypes } from '../../types';
import { HelpButton } from '@/modules/trade/components/HelpButton';

@Component({
  components: {
    HelpButton,
    BaseText,
    Row,
    ButtonPrimary,
    ButtonSecondary,
    SubscriptionOrderContainer
  }
})
export default class SubscriptionOrder extends Vue {
  @Prop({ type: Number, required: true })
  readonly availableQuantity!: number;

  @Prop({ type: String, required: true })
  readonly instrumentValue!: string;

  @Prop({ type: String, required: true })
  readonly availableBalanceUser!: string;

  @Prop({ type: String, required: true })
  readonly totalValueEstimated!: string;

  @Prop({ type: String, required: true })
  readonly subscriptionType!: string;

  @Prop({ type: Boolean, required: true })
  readonly isSubscriptionInvalid!: boolean;

  @Prop({ type: Boolean, required: true })
  readonly isEditingSubscriptionOrder!: boolean;

  @InjectReactive()
  readonly instrumentType!: string;

  @InjectReactive()
  readonly instrumentName!: string;

  get NebraskaColors() {
    return NebraskaColors;
  }

  get subscriptionHelperClass() {
    return this.isEditingSubscriptionOrder
      ? 'subscription-order__help--editing'
      : 'subscription-order__help';
  }

  get buttonPrimaryLabel() {
    return this.isEditingSubscriptionOrder ? 'Alterar reserva' : 'Reservar';
  }

  get isLeftOversSubscription() {
    return this.subscriptionType === SubscriptionTypes.SobrasDeSubscricao;
  }

  get isReserveButtonDisabled() {
    return this.isSubscriptionInvalid || this.isLeftOversSubscription;
  }

  handleClickActionSubscription() {
    if (this.isEditingSubscriptionOrder) this.emitChangeSubscriptionOrder();
    else this.emitConfirmOrder();
  }

  trackSubscriptionOrderHelpButton() {
    trackHelpSubscriptionPage();
  }

  @Emit('confirm-order')
  public emitConfirmOrder() {
    this.trackSubscriptionPageAction(
      TrackSubscriptionPageActions.reserve_asset
    );
  }

  @Emit('change-order')
  emitChangeSubscriptionOrder() {
    this.trackSubscriptionPageAction(
      TrackSubscriptionPageActions.change_asset_reservation
    );
  }

  @Emit('cancel-order')
  emitCancelSubscriptionOrder() {
    this.trackSubscriptionPageAction(
      TrackSubscriptionPageActions.cancel_asset_reservation
    );
  }

  private trackSubscriptionPageAction(action: TrackSubscriptionPageActions) {
    const ticker = this.$route.query.ticker as string;
    trackReserveSubscriptionPageActions({
      instrument_type: this.instrumentType,
      instrument_name: this.instrumentName,
      instrument_symbol: ticker,
      subscrition_action: action
    });
  }
}
