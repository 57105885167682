import { GtmTracker } from '@/modules/common/services/analytics';

const { trackEvent } = GtmTracker;

function trackEventBase(action = '', label = '') {
  trackEvent({
    category: 'PEX - Carteira Editada',
    action: `Nivel de Risco${action}`,
    label
  });
}

export function trackCheckbox() {
  trackEventBase(undefined, 'Aceite Risco');
}

export function trackClose() {
  trackEventBase(' - Cancelar', 'B2C');
}

export function trackAccepted() {
  trackEventBase(' - Criar Carteira', 'B2C');
}

const trackEventAmplitude = GtmTracker.trackerWithDefaultProps({
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackAcceptedAmplitude() {
  trackEventAmplitude({
    page_screen: 'portfolio_investment_flow_instrument_investment_risk',
    event_type: 'click',
    page_screen_section: 'risk_agreement',
    event_name: 'agree',
    event_target: 'Continuar'
  });
}
