
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getString } from '@/modules/common/helpers/resources';
import { isValid as isValidCPF } from 'cpf';
import { InputText } from '@warrenbrasil/nebraska-web';

enum StatusType {
  Success = 'success',
  Error = 'error'
}

@Component({ components: { InputText } })
export default class CPFInput extends Vue {
  @Prop({ type: String, required: false }) private name?: string;
  @Prop({ type: [String, Number], default: '' }) private value!:
    | string
    | number;

  private error = '';
  private status: string = this.value ? StatusType.Success : StatusType.Error;
  private label = getString('common_field_bankaccount_cpf_label');
  private mask = '###.###.###-##';

  private emitError() {
    this.$emit('error', this.error);
  }

  private setAndEmitError(errorString: string) {
    this.error = errorString;
    this.status = StatusType.Error;
    this.emitError();
  }

  private handleInput(value: string) {
    this.$emit('input', value);
    if (!value) {
      this.setAndEmitError(getString('common_field_cpf_error_required'));
    } else if (!isValidCPF(value)) {
      this.setAndEmitError(getString('common_field_cpf_error_invalid'));
    } else {
      this.error = '';
      this.status = StatusType.Success;
    }
  }
}
