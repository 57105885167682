import { FilterFormatted } from '@/modules/trade/services/orders';

export enum FetchingOrdersStatus {
  Loading = 'loading',
  Error = 'error',
  Default = 'default'
}

export enum OrderFiltersKeys {
  Status = 'status',
  Type = 'type',
  Date = 'dateFilter',
  Instrument = 'instrument'
}

export type Filters = {
  [key in OrderFiltersKeys]: {
    options?: FilterFormatted[];
    value: string | null;
  };
};

export enum OrderFiltersQueryParamsKeys {
  Instrument = 'instrument',
  Date = 'date',
  Status = 'status',
  Type = 'type'
}
