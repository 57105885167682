
import { Component, ProvideReactive, Vue } from 'vue-property-decorator';

import { AllocationChartModal } from './components/AllocationChartModal';
import { Pagination } from '@/modules/common/components/Pagination';
import { InstrumentsTable } from './components/InstrumentsTable';
import { CashMarketFilters } from './components/CashMarketFilters';
import {
  BaseText,
  HelperViewStatus,
  IFilterOption
} from '@warrenbrasil/nebraska-web';

import {
  getPositionsView,
  getPositionsHistoricalView,
  Instrument,
  PositionsChart,
  InstrumentHistorical
} from '@/modules/trade/services/instruments';
import {
  ICashMarketFilters,
  CashMarketFiltersTypes,
  CashMarketFiltersDateValues
} from './types';
import { NumberFormatted, StringFormatted } from '@/modules/trade/types';
import { PositionsDateFilterOptions } from './data';
import {
  trackFilterChange,
  trackPositionsViewLoad
} from '@/modules/trade/views/TradePositionsView/trackers';
import { isRequestError } from '@warrenbrasil/web-http';
import { format } from 'date-fns';

@Component({
  components: {
    AllocationChartModal,
    InstrumentsTable,
    CashMarketFilters,
    BaseText,
    HelperViewStatus,
    Pagination
  }
})
export default class CashMarket extends Vue {
  readonly CashMarketFiltersTypes = CashMarketFiltersTypes;
  currentPage = 1;
  instruments: Instrument[] | InstrumentHistorical[] = [];
  isLoading = true;
  itemsPerPage = 10;
  totalPages = 1;
  error = false;
  abortSignal: AbortController | null = null;
  chartData: PositionsChart | null = null;
  updatedAt: NumberFormatted = {
    value: 0,
    formatted: 'Atualizado hoje, às --:--'
  };

  isAllocationModalOpen = false;

  @ProvideReactive()
  filters: ICashMarketFilters = {
    [CashMarketFiltersTypes.Date]: {
      selected: CashMarketFiltersDateValues.Today,
      available: PositionsDateFilterOptions
    },
    [CashMarketFiltersTypes.Type]: {
      selected: 'all',
      available: []
    }
  };

  created() {
    this.getPositions();
    trackPositionsViewLoad();
  }

  get hasInstruments() {
    return this.instruments.length > 0;
  }

  get hasFilters() {
    return this.filters[CashMarketFiltersTypes.Type].available?.length > 0;
  }

  async getPositions() {
    try {
      this.isLoading = true;
      this.error = false;
      if (this.abortSignal) this.abortSignal.abort();
      this.abortSignal = new AbortController();

      const params = {
        type: this.filters[CashMarketFiltersTypes.Type].selected || 'all',
        page: this.currentPage,
        itemsPerPage: this.itemsPerPage
      };

      const data =
        this.filters[CashMarketFiltersTypes.Date].selected ===
        CashMarketFiltersDateValues.Today
          ? await getPositionsView(params, this.abortSignal.signal)
          : await getPositionsHistoricalView(params, this.abortSignal.signal);

      const { paginatedPositions, chart, updatedAt } = data;

      this.chartData = chart;
      this.changeFilterAvailableOptions(
        CashMarketFiltersTypes.Type,
        paginatedPositions.filters.available
      );
      this.instruments = paginatedPositions.items;
      this.totalPages = paginatedPositions.pagination.totalPages;
      this.currentPage = paginatedPositions.pagination.page;
      this.itemsPerPage = paginatedPositions.pagination.itemsPerPage;
      this.setUpdatedAt(updatedAt);
    } catch (e) {
      if (isRequestError(e) && e.type === 'canceled') return;

      this.error = true;
    } finally {
      this.isLoading = false;
    }
  }

  private setUpdatedAt(updatedAt?: NumberFormatted | null) {
    if (updatedAt) {
      this.updatedAt = updatedAt;
    } else {
      const dateNow = new Date(Date.now());
      this.updatedAt = {
        value: dateNow.getTime(),
        formatted: `Atualizado hoje, às ${format(dateNow, 'HH:mm')}`
      };
    }
  }

  private setInitialPageValue() {
    this.currentPage = 1;
  }

  async handleOnSelectFilter(
    filterType: CashMarketFiltersTypes,
    filter: IFilterOption
  ) {
    if (filterType === CashMarketFiltersTypes.Type)
      trackFilterChange(filter.label);

    this.filters[filterType].selected = filter.value;
    this.setInitialPageValue();

    await this.getPositions();
  }

  convertStringFormattedToFilterOption(
    stringFormatted: StringFormatted
  ): IFilterOption {
    return {
      label: stringFormatted.formatted,
      value: stringFormatted.value
    };
  }

  changeFilterAvailableOptions(
    filterType: CashMarketFiltersTypes,
    options: StringFormatted[]
  ) {
    this.filters[filterType].available = options;
  }

  async handleOnSelectPage(nextPage: number) {
    this.currentPage = nextPage;

    await this.getPositions();
  }

  toggleAllocationModal() {
    this.isAllocationModalOpen = !this.isAllocationModalOpen;
  }
}
