
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
/**
 * @deprecated - use Nebraska button instead
 */
export default class SimpleButton extends Vue {
  @Prop({ type: String })
  public label!: string;

  private created() {
    console.warn(
      'Using deprecated SimpleButton, please use Nebraska ModalButton instead.'
    );
  }
}
