
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { Heading } from '@warrenbrasil/nebraska-web';
import { ErrorHandler } from '@/modules/common/components/ErrorHandler';

@Component({
  components: {
    ErrorHandler,
    Heading
  }
})
export default class SidebarErrorV2 extends Vue {
  @Prop({ type: String, required: true })
  readonly sectionTitle!: string;

  @Prop({ type: String, required: true })
  readonly errorTitle!: string;

  @Prop({ type: String, required: true })
  readonly errorDescription!: string;

  @Emit('retry')
  emitRetry() {}
}
