import { render, staticRenderFns } from "./ModalFooterWallet.vue?vue&type=template&id=434b9412&scoped=true&"
import script from "./ModalFooterWallet.vue?vue&type=script&lang=ts&"
export * from "./ModalFooterWallet.vue?vue&type=script&lang=ts&"
import style0 from "./ModalFooterWallet.vue?vue&type=style&index=0&id=434b9412&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "434b9412",
  null
  
)

export default component.exports