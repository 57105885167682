
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ConclusionStep,
  ContentDisplay,
  HeadingDisplay,
  ListDisplay,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';
import { ETransferType } from '@/modules/transaction/views/NewTransfer/helpers/types';
import { IBankAccount } from '@/modules/transaction/services/new-transfer';
import * as trackEvents from '@/modules/transaction/views/NewTransfer/track-events';

@Component({
  components: {
    ConclusionStep,
    ContentDisplay,
    HeadingDisplay,
    ListDisplay,
    ButtonPrimary
  }
})
export default class NewTransferConclusion extends Vue {
  @Prop({ type: String, required: true })
  readonly transferType!: ETransferType;

  @Prop({ type: String, required: true })
  readonly amountValue!: string;

  @Prop({ type: Object, required: true })
  readonly selectedBankAccount!: IBankAccount;

  @Prop({ type: String, required: false })
  readonly scheduleDescription?: string;

  get conclusionTitle() {
    return this.transferType === ETransferType.SCHEDULE
      ? 'Transferência agendada'
      : 'Transferência solicitada';
  }

  get conclusionSubtitle() {
    return this.transferType === ETransferType.SCHEDULE
      ? this.scheduleDescription
      : '';
  }

  goHome() {
    trackEvents.trackScheduleTransferSuccessDetailsBackClick();
    this.$router.push({ name: 'home' });
  }

  startAnimation() {
    if (this.transferType === ETransferType.SCHEDULE)
      trackEvents.trackScheduleTransferSuccessLoad();
  }
}
