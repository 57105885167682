import { GtmTracker } from '@/modules/common/services/analytics';

const { trackEvent, trackerWithDefaultProps } = GtmTracker;

const trackAmplitudeEvent = trackerWithDefaultProps({
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

const getPageScreen = (isEditor: boolean) =>
  isEditor ? 'portfolio_allocation_editor' : 'advanced_portfolio_builder';

export function trackLoadAdvancedPortfolioBuilder(
  isEditor: boolean,
  event_target: string
) {
  trackAmplitudeEvent({
    page_screen: getPageScreen(isEditor),
    event_type: 'load',
    event_name: 'view',
    event_target
  });
}

export function trackCreatedSuccessLoad() {
  trackAmplitudeEvent({
    page_screen: 'advanced_portfolio_builder_success',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackCreatedSuccessClose() {
  trackEvent({
    category: 'PEX - Carteira Editada',
    action: 'Carteira Criada - Sair',
    label: 'B2C'
  });
}

export function trackCreatedSuccessInvest() {
  trackEvent({
    category: 'PEX - Carteira Editada',
    action: 'Carteira Criada - Criar Depósito',
    label: 'B2C'
  });

  trackAmplitudeEvent({
    page_screen: 'advanced_portfolio_builder_success',
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'invest'
  });
}

export function trackEditedSuccessLoad() {
  trackAmplitudeEvent({
    page_screen: 'portfolio_allocation_editor',
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackEditedSuccessClose() {
  trackEvent({
    category: 'PEX',
    action: 'Clique Editar - Sucesso',
    label: 'Sair'
  });
}

export function trackEditedSuccessInvest() {
  trackEvent({
    category: 'PEX',
    action: 'Clique Editar - Sucesso',
    label: 'Investir'
  });

  trackAmplitudeEvent({
    page_screen: 'portfolio_allocation_editor',
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'invest'
  });
}
