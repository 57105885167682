var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(
      _vm.instrumentType === _vm.InstrumentType.BDR ||
      _vm.instrumentType === _vm.InstrumentType.GlobalAssets
    )?_c('InitialAvatarIcon',{staticClass:"instrument-avatar__icon",attrs:{"size":_vm.size,"icon":"CA0030"}}):(
      _vm.instrumentType === _vm.InstrumentType.FII ||
      _vm.instrumentType === _vm.InstrumentType.RealStateAssets
    )?_c('InitialAvatarIcon',{staticClass:"instrument-avatar__icon",attrs:{"size":_vm.size,"icon":"CA0020"}}):_c('InitialAvatarChar',{attrs:{"size":_vm.size,"char":_vm.symbolInitial}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }