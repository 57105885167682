
import { Component, Vue, Prop } from 'vue-property-decorator';

import { HeadingDisplay } from '@warrenbrasil/nebraska-web';

import { InstrumentLineChart } from './components/InstrumentLineChart';
import { NumberFormatted, StringFormatted } from '@/modules/trade/types';

@Component({
  components: {
    InstrumentLineChart,
    HeadingDisplay
  }
})
export default class InstrumentChart extends Vue {
  @Prop({ type: String, required: true })
  readonly ticker!: string;

  @Prop({ type: Object, required: true })
  readonly latestPrice!: NumberFormatted;

  @Prop({ type: Object, required: true })
  readonly latestPriceDate!: StringFormatted;

  get latestPriceFormatted() {
    return this.latestPrice.formatted;
  }

  get latestPriceDateFormatted() {
    return this.latestPriceDate.formatted;
  }
}
