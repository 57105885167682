import moment from 'moment-timezone';

export function dateViewer(date: string, format = 'DD/MM/YYYY', isUTC = true) {
  if (isUTC) {
    return moment.utc(new Date(date)).locale('pt-br').format(format);
  }

  return moment.tz(date, 'America/Sao_Paulo').locale('pt-br').format(format);
}

export function formatDate(
  date: string,
  currentFormat = 'DD/MM/YYYY',
  resultFormat = 'YYYY-MM-DD'
) {
  if (!date) return '';

  return moment(date, currentFormat).format(resultFormat);
}

export function isValidDate(dateString = '') {
  const birthdayDate = new Date(dateString);
  const limitDate = new Date('01/01/1900');

  const minimumAgeDate = new Date();
  minimumAgeDate.setFullYear(minimumAgeDate.getFullYear());

  const maximumAgeDate = new Date();
  maximumAgeDate.setFullYear(maximumAgeDate.getFullYear() - 115);

  return (
    !isNaN(birthdayDate.valueOf()) &&
    birthdayDate.getTime() > limitDate.getTime() &&
    birthdayDate.getTime() < minimumAgeDate.getTime() &&
    birthdayDate.getTime() > maximumAgeDate.getTime()
  );
}

export function getMonthName(month: number) {
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];

  return monthNames[month - 1] ? monthNames[month - 1] : '';
}

/**
 * @param date (Formato: "YYYY/MM/DDTHH:mm:ss")
 * @return ex: 06 de Jun de 2000 às 23h05
 */

export function getDateInFull(date: string) {
  if (!date) return '-';

  const t = date.match(/(\d{4})\D(\d{2})\D(\d{2})\D(\d{2}:\d{2})/);

  if (!t) return '';

  return `${t[3]} de ${getMonthName(+t[2]).substring(0, 3)} de ${
    t[1]
  } às ${t[4].replace(':', 'h')}`;
}

/**
 * @param date (Formato: "YYYY/MM/DDTHH:mm:ss")
 * @return ex: 06/08/2020 às 23:05
 */

export function getTrasactionDate(date: string, isOperated: boolean) {
  if (!date) return '-';

  const t = date.match(/(\d{4})\D(\d{2})\D(\d{2})\D(\d{2}:\d{2})/);

  if (!t) return '';

  if (!isOperated) return `${t[3]}/${t[2]}/${t[1]} às ${t[4]}`;

  return `${t[3]}/${t[2]}/${t[1]}`;
}

export function isSameUTCDate(dateStr1: string, dateStr2: string) {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  return (
    date1.getUTCDate() === date2.getUTCDate() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCFullYear() === date2.getUTCFullYear()
  );
}
