export enum ENewTransferSteps {
  AMOUNT_SELECTION,
  BANK_SELECTION,
  REVIEW,
  CONCLUSION,
  FEEDBACK
}

export enum EStatusType {
  DEFAULT = 'default',
  ERROR = 'error'
}

export enum ETransferType {
  CREATE = 'create',
  SCHEDULE = 'schedule'
}

export enum EFeedbackType {
  GENERIC_ERROR = 'genericError',
  WITHOUT_BANK = 'withoutBank'
}
