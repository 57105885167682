
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ContentDisplay, ListDisplay } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    ContentDisplay,
    ListDisplay
  }
})
export default class PartnerCard extends Vue {
  @Prop({ type: String, required: true })
  private partnerName!: string;
}
