import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';
import { MoneyCell } from '@/modules/common/components/MoneyCell';

import { StockLendingPosition } from '@/modules/trade/services/instruments/';

export const columns = [
  {
    label: 'Ativo',
    component: InstrumentNameCell,
    getter: (position: StockLendingPosition) => ({
      symbol: position.symbol,
      name: position.name,
      type: position.type?.value,
      size: 'small',
      hasEllipsis: true
    })
  },
  {
    label: 'Tipo',
    align: 'right',
    getter: (position: StockLendingPosition) => ({
      text: position.side.formatted
    })
  },
  {
    label: 'Qtd. inicial',
    align: 'right',
    component: MoneyCell,
    getter: (position: StockLendingPosition) => ({
      text: String(position.originalQuantity),
      align: 'right',
      enableToggleMoney: true
    })
  },
  {
    label: 'Qtd. atual',
    align: 'right',
    component: MoneyCell,
    getter: (position: StockLendingPosition) => ({
      text: String(position.currentQuantity),
      align: 'right',
      enableToggleMoney: true
    })
  },
  {
    label: 'Preço base',
    align: 'right',
    component: MoneyCell,
    getter: (position: StockLendingPosition) => ({
      text: position.basePrice.formatted,
      align: 'right',
      enableToggleMoney: true
    })
  },
  {
    label: 'Taxa ao ano',
    align: 'right',
    getter: (position: StockLendingPosition) => ({
      text: position.fee.formatted
    })
  },
  {
    label: 'Data inicial',
    align: 'right',
    getter: (position: StockLendingPosition) => ({
      text: position.initialDate.formatted
    })
  },
  {
    label: 'Vencimento',
    align: 'right',
    getter: (position: StockLendingPosition) => ({
      text: position.expirationDate.formatted
    })
  }
];
