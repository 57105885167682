
import { Component, Vue } from 'vue-property-decorator';
import {
  HelperViewContextual,
  MessageFoundation as Message
} from '@warrenbrasil/nebraska-web';
import { isWarrenManaged } from '@/modules/common/services/partner';

@Component({
  components: {
    HelperViewContextual,
    Message
  }
})
export default class RegulatoryUpdateAnnouncementPJ extends Vue {
  public get helperViewContext(): string {
    if (isWarrenManaged()) {
      return 'brand';
    }
    return 'neutral';
  }
}
