import { GtmTracker } from '@/modules/common/services/analytics';

const trackEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'portfolio_instrument_withdrawal_request_success',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackViewLoad() {
  trackEvent({
    event_type: 'load',
    event_name: 'view',
    page_screen_section: 'acceptence_requested'
  });
}

export function trackBackToPortfoliosView() {
  trackEvent({
    event_type: 'click',
    event_name: 'go_back_to_my_portfolio'
  });
}
