
import { Component, Emit, Vue } from 'vue-property-decorator';
import {
  NavBar,
  IconButton,
  Heading,
  BaseText,
  BaseIcon,
  Link
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { talkToWarrenConstants } from './text-constants';
import * as trackEvents from './track-events';

@Component({
  components: {
    NavBar,
    IconButton,
    Heading,
    BaseText,
    BaseIcon,
    Link
  }
})
export default class TalkToWarren extends Vue {
  private NebraskaColors = NebraskaColors;
  private TalkToWarrenTexts = talkToWarrenConstants;

  @Emit('previous-step')
  public goToPreviousStep(step?: string) {
    trackEvents.trackGoBackClick();
    return step;
  }

  mounted() {
    trackEvents.trackTalkToWarrenLoad();
  }

  public handleEmailClick() {
    trackEvents.trackSendEmailClick();
    this.openEmailClient();
  }

  public goToFaq() {
    trackEvents.trackGoFaqClick();
    window.open(this.TalkToWarrenTexts.faq.href, '_blank');
  }

  public openZendeskChat() {
    trackEvents.trackOpenChatClick();
    window.openChat();
  }

  private openEmailClient() {
    window.location.href = this.TalkToWarrenTexts.email.href;
  }
}
