
import { Component, Prop, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash-es';
import { namespace } from 'vuex-class';

import InstrumentsSearch from './InstrumentsSearch/InstrumentsSearch.vue';
import InstrumentsList from './InstrumentsList.vue';
import InstrumentDetails from './InstrumentDetails/InstrumentDetails.vue';
import { Instruments } from './Instruments';
import { BenchmarkFilterOptions } from '@/modules/wallet-builder/src/types';
import CardInstrument from './CardInstrument.vue';

import {
  IInstrument,
  IInstrumentDetails
} from '../../services/types/models/IBffInvestments';
import {
  trackCloseInstrumentDetailModal,
  trackInstrumentAdd,
  trackInstrumentClicked,
  trackNoResult,
  trackSelectInstrumentCategory,
  trackDownloadButton,
  trackLoadInstrumentDetails,
  trackSearch
} from './track-events';
import { trackLoadAdvancedPortfolioBuilder } from '../../views/track-events';

const FilterModule = namespace('FilterModule');

@Component({
  components: {
    InstrumentsSearch,
    InstrumentsList,
    InstrumentDetails,
    CardInstrument
  }
})
export default class InstrumentsContainer extends Instruments {
  @Prop({ type: Boolean, required: false, default: true })
  public allowSearch?: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  public addProduct?: boolean;

  @Prop({ type: String, default: '' })
  public selectedInstrumentDetailId!: string;

  @Prop({ type: Boolean, default: false })
  public isScrollable!: boolean;

  @Prop({ type: Boolean, default: false })
  public isMobile!: boolean;

  @Prop({ type: Number, default: 0 })
  public productsQt!: number;

  @Prop({ type: Boolean, default: false })
  public isEditor!: boolean;

  @FilterModule.Action('setSearchingProducts')
  private setSearchingProducts!: Function;

  private showInstrumentModal = false;

  private onInputChange = debounce(this.searchInstruments, 500);

  private isInstrumentAdded = false;

  public isAddingOrRemovingProduct = '';

  @Watch('benchmarkFilter')
  private handleBenchmarkFilterChange(benchmarkFilter: BenchmarkFilterOptions) {
    this.resetPages();
    this.fetchInstruments({ benchmarkFilter });
  }

  private updated() {
    if (this.selectedInstrumentDetailId) {
      this.openInstrumentDetailModal(this.selectedInstrumentDetailId);
    }
  }

  private async searchInstruments(search: string) {
    search.length > 0
      ? this.setSearchingProducts(true)
      : this.setSearchingProducts(false);
    this.initialPage = 1;
    this.search = search;
    this.resetPages();
    await this.fetchInstruments();
    this.initialPage = 0;
    trackSearch(this.isEditor, search);
  }

  private openInstrumentDetailModal(apiId: string) {
    this.setCurrentInstrument(apiId);
    this.showInstrumentModal = true;
  }

  private closeInstrumentDetailModal(instrument: IInstrument) {
    trackCloseInstrumentDetailModal(instrument);
    this.setCurrentInstrument('');
    this.showInstrumentModal = false;
  }

  private setCurrentInstrument(apiId: string) {
    this.$emit('handle-instrument-select', apiId);
  }

  private showWalletContent() {
    this.$emit('show-wallet-content');
  }

  private async builderInstrumentAdd(instrument: IInstrument) {
    await this.handleInstrumentAdd(instrument);
    this.setCurrentInstrument('');
    this.showInstrumentModal = false;
  }

  private async builderInstrumentRemove(instrument: IInstrument) {
    await this.handleInstrumentRemove(instrument);
    this.setCurrentInstrument('');
    this.showInstrumentModal = false;
  }

  private async instrumentAdd(instrument: IInstrument) {
    try {
      this.isAddingOrRemovingProduct = instrument.apiId!;
      await this.builderInstrumentAdd(instrument);
    } finally {
      this.isAddingOrRemovingProduct = '';
    }
  }

  private async instrumentRemove(instrument: IInstrument) {
    try {
      this.isAddingOrRemovingProduct = instrument.apiId!;
      await this.builderInstrumentRemove(instrument);
    } finally {
      this.isAddingOrRemovingProduct = '';
    }
  }

  handleClearSearchInput() {
    this.searchInstruments('');
  }

  private async handleInstrumentAddClicked(instrument: IInstrument) {
    trackInstrumentAdd(this.isEditor, false, instrument);
    this.instrumentAdd(instrument);
  }

  private async handleInstrumentAddModalClicked(instrument: IInstrument) {
    trackInstrumentAdd(this.isEditor, true, instrument);
    this.instrumentAdd(instrument);
  }

  private async handleInstrumentRemoveModalClicked(instrument: IInstrument) {
    this.instrumentRemove(instrument);
  }

  private handleInstrumentsClicked(instrument: IInstrument) {
    trackInstrumentClicked(this.isEditor, instrument);
    this.isInstrumentAdded = instrument.isAdded || false;
    this.openInstrumentDetailModal(instrument.apiId!);
  }

  showEmptyMessage() {
    trackNoResult(this.isEditor);
  }

  trackDownloadButton(computedTitle: string) {
    trackDownloadButton(this.isEditor, computedTitle);
  }

  trackLoadInstrumentDetails(instrument: IInstrumentDetails) {
    trackLoadInstrumentDetails(this.isEditor, instrument);
  }

  handleTabsChange(tab: number) {
    trackSelectInstrumentCategory(this.isEditor, this.tabsList[tab].name);
    this.handleInstrumentsTabsChange(tab);
  }

  mounted() {
    trackLoadAdvancedPortfolioBuilder(this.isEditor, this.tabsList[0].name);
    trackSelectInstrumentCategory(this.isEditor, this.tabsList[0].name);
  }
}
