
import { Component, Vue, Emit } from 'vue-property-decorator';

import { NavBar, BaseModal, LoaderIndicator } from '@warrenbrasil/nebraska-web';

import { getString } from '@/modules/common/helpers/resources';
import { Notification } from '@/modules/common/components/Notification';
import { ModalFooter } from '@/modules/common/components/__deprecated__/Modal';
import {
  instantLogin,
  instantLoginIntranet,
  instantLoginUnblockHash,
  InstantLoginResponse,
  getThemeByParam
} from '@/modules/common/services/http/account';
import { setLoggedUser } from './helpers/setLoggedUser';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { WarrenSession } from '@/modules/common/services/session';
import {
  setCustomTheme,
  StorageKeys
} from '@/modules/common/services/local-storage';
import { setNebraskaScheme } from '../../services/theme';

enum InstantLoginServices {
  InstantLogin = 'instantLogin',
  InstantLoginIntranet = 'instantLoginIntranet'
}

@Component({
  components: {
    LoaderIndicator,
    Notification,
    NavBar,
    BaseModal,
    ModalFooter
  }
})
export default class InstantLoginView extends Vue {
  private service = '';
  private backTo = '';
  private hash = '';
  private isImpersonateByIntranet = '';
  private loading = false;
  private alreadyTriedToUnblock = false;
  private notificationGroup = 'wrn-notification-instant-login';
  private shouldShowModal = false;
  private messages = {
    unblockTitle: getString('dialog_unblock_title'),
    unblockText: getString('dialog_unblock_text'),
    unblockSuccess: getString('dialog_unblock_success')
  };

  private mounted() {
    this.hash = this.$route.params.hash;
    this.backTo = (this.$route.query.backTo as string) || 'v3';
    this.isImpersonateByIntranet =
      (this.$route.query.isImpersonateByIntranet as string) || 'false';

    if (!this.hash) {
      this.goToSignin();
    }

    this.instantLogin();
  }

  private instantLogin() {
    const instantLoginService = this.getInstantLoginService();
    const onSuccess = this.instantLoginSuccess;
    const onError = this.instantLoginError;

    return instantLoginService({ hash: this.hash })
      .then(onSuccess)
      .catch(onError);
  }

  private getInstantLoginService() {
    this.service =
      (this.$route.meta?.service as InstantLoginServices) ||
      InstantLoginServices.InstantLogin;
    return this.service === InstantLoginServices.InstantLogin
      ? instantLogin
      : instantLoginIntranet;
  }

  private async instantLoginSuccess(data: InstantLoginResponse) {
    setLoggedUser(data, data.accessToken);
    WarrenSession.set(
      StorageKeys.isImpersonateByIntranet,
      this.isImpersonateByIntranet
    );

    await Promise.all([
      this.handleFetchCustomer(),
      FeatureFlagsModule.fetchFeatureFlags()
    ]);
    this.goToPath();
  }

  private async handleFetchCustomer() {
    await CustomerModule.fetchCustomer();
    await this.loadTheme();
  }

  private loadTheme = async () => {
    const email = CustomerModule.getCustomer?.email;
    if (!email) return;
    const theme = await getThemeByParam('email', email!);
    setNebraskaScheme(theme);
    setCustomTheme(theme);
  };

  private instantLoginError(err: Error) {
    this.$notify({
      group: this.notificationGroup,
      text: getString(err.message) || err.message,
      type: NotificationTypes.error
    });

    if (
      err &&
      err!.message === 'error_account_blocked' &&
      !this.alreadyTriedToUnblock
    ) {
      this.alreadyTriedToUnblock = true;
      this.shouldShowModal = true;
    } else {
      this.goToSignin();
    }
  }

  private tryUnblock() {
    this.loading = true;
    this.close();

    const onSuccess = this.unblockHashSuccess;

    instantLoginUnblockHash({ hash: this.hash, type: 'instant-login' })
      .then(onSuccess)
      .catch(() => this.goToSignin());
  }

  private unblockHashSuccess() {
    this.$notify({
      group: this.notificationGroup,
      text: this.messages.unblockSuccess,
      type: NotificationTypes.success
    });

    this.instantLogin();
  }

  private goToSignin() {
    setTimeout(() => {
      this.$router.push({ name: 'signin', query: this.$route.query });
    }, 2000);
  }

  private goToPath() {
    const regexStartWithApp = /^\/app\//gm;
    const isExternalRoute = regexStartWithApp.exec(this.backTo);

    if (isExternalRoute) {
      window.location.assign(this.backTo);

      return;
    }

    this.$router.push({ name: this.backTo });
  }

  @Emit('close')
  private close() {}
}
