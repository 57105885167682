import { render, staticRenderFns } from "./AdvisorStep.vue?vue&type=template&id=4abf8caf&scoped=true&"
import script from "./AdvisorStep.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AdvisorStep.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AdvisorStep.vue?vue&type=style&index=0&id=4abf8caf&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4abf8caf",
  null
  
)

export default component.exports