import store from '@/store';
import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators';
import { cloneDeep } from 'lodash-es';
import { PensionAPI } from '@/modules/pension/services/pension';
import { PensionPositionState as InitalState } from './state';
import {
  State,
  Actions,
  PayloadErrors,
  PayloadLoading,
  PayloadState,
  PayloadInstrumentContracts,
  PayloadInstrumentBusinessName
} from './types';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'PensionInstrumentModule',
  store
})
export class VuexPensionInstrumentModule extends VuexModule implements Actions {
  // Module state
  public data: State = cloneDeep(InitalState);

  /**
   * Set entire state object.
   */
  @Action({ rawError: true })
  public setState(data: State) {
    this.context.commit('SET_STATE', { data });
  }

  /**
   * Generic loading state setter.
   */
  @Action({ rawError: true })
  public setLoading({ state, key }: PayloadLoading) {
    this.context.commit('SET_LOADING', { state, key });
  }

  /**
   * Attempt to fetch given instrument details.
   */
  @Action({ rawError: true })
  public async fetchInstrumentDetails(productApiId: string) {
    try {
      this.setLoading({ state: true, key: 'instrumentDetails' });
      const api = new PensionAPI();
      const data = await api.getInstrumentDetails(productApiId);

      // There are errors in response
      if ('type' in data && data.type === 'error') {
        this.context.commit('SET_ERRORS', {
          state: data.errors,
          key: 'instrumentDetails'
        });
        return;
      }

      // There are contracts, response ok
      if (Array.isArray(data)) {
        const name = data[0]?.productName || '';
        this.context.commit('SET_INSTRUMENT_CONTRACTS', { contracts: data });
        this.context.commit('SET_INSTRUMENT_BUSINESS_NAME', { name });
      }
    } catch (errors) {
      this.context.commit('SET_ERRORS', {
        state: errors,
        key: 'instrumentDetails'
      });
    } finally {
      this.setLoading({ state: false, key: 'instrumentDetails' });
    }
  }

  @Mutation
  public SET_STATE({ data }: PayloadState) {
    this.data = cloneDeep(data);
  }

  @Mutation
  public SET_INSTRUMENT_CONTRACTS({ contracts }: PayloadInstrumentContracts) {
    this.data.instrumentDetails.contracts = cloneDeep(contracts);
  }

  @Mutation
  public SET_INSTRUMENT_BUSINESS_NAME({ name }: PayloadInstrumentBusinessName) {
    this.data.instrumentDetails.businessName = name;
  }

  @Mutation
  public SET_LOADING({ state, key }: PayloadLoading) {
    this.data.loading[key] = state;
  }

  @Mutation
  public SET_ERRORS({ state, key }: PayloadErrors) {
    this.data.errors[key] = state;
  }
}

export const PensionInstrumentState = InitalState;

export const PensionInstrumentModule = getModule(VuexPensionInstrumentModule);
