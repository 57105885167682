
import { Component, Vue, Prop } from 'vue-property-decorator';

import { ProductDetails } from '@/modules/products/components/ProductDetails';
import { PortfolioProductsList } from '@/modules/wealth/components/PortfolioProductsList';
import { IProductAllocation } from '@/modules/wealth/services/investment';

@Component({
  components: { PortfolioProductsList, ProductDetails }
})
export default class PortfolioProductsListWithDetails extends Vue {
  @Prop({ type: Array, required: true })
  readonly allocations!: IProductAllocation[];

  isModalOpen = false;

  private productApiId = '';

  get instrument() {
    return { apiId: this.productApiId };
  }

  openModal(productApiId: string) {
    this.productApiId = productApiId;
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }
}
