export const SEND_TOKEN_ERROR =
  'Não foi possível enviar a mensagem, tente novamente.';
export const SEND_TOKEN_SUCCESS = 'SMS com código enviado para (**) ****-';
export const SEND_TOKEN_SUCCESS_PUSH =
  'Enviamos um código para seu dispositivo';
export const WRONG_CODE_ERROR = 'Código expirado ou inválido.';
export const LOADING_ERROR = 'Erro ao carregar a tela.';
export const MFA_ACTIVATED = 'Verificação em duas etapas ativada com sucesso!';
export const MFA_ACTIVATION_ERROR =
  'Não foi possível ativar a verificação em duas etapas.';
export const MFA_LOGIN_ERROR =
  'Não foi possível fazer o login, tente novamente';
export const DISCONNECT_DEVICES_ERROR = 'Usuário não encontrado';
