
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ButtonPrimary } from '@warrenbrasil/nebraska-web';

@Component({
  inheritAttrs: false,
  components: { ButtonPrimary }
})
export default class WealthChartAction extends Vue {
  @Prop({ type: String })
  private label?: string;

  @Prop({ type: Boolean, required: false })
  private disabled!: boolean;
}
