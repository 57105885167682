import {
  createHttpClient,
  HttpClientRequestOptions,
  isRequestError
} from '@warrenbrasil/web-http';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { getAccessToken } from '@/modules/common/services/access-token';
import { isObject } from 'lodash-es';

export function setup(tokenKey?: StorageKeys, disableHandleErrors = false) {
  let tokenStorageKey: StorageKeys;
  const httpClient = createHttpClient({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 30000,
    retries: 1
  });
  setTokenStorage();
  updateInterceptors();

  function setTokenStorage() {
    if (tokenKey) tokenStorageKey = tokenKey;
  }

  function updateInterceptors() {
    httpClient.applyRequestInterceptors({
      onSuccess: setHeaders
    });
    httpClient.applyResponseInterceptors({
      onError: handleError
    });
  }

  function setHeaders(config: HttpClientRequestOptions) {
    const headers = { ...config.headers };

    config.headers = {
      'Access-Token': getAccessToken(tokenStorageKey) || '',
      ...headers
    };

    return config;
  }

  function handleError(error: object): Promise<object> {
    if (isRequestCanceled(error)) {
      return Promise.reject(error);
    }

    if (isRequestError(error) && error.response) {
      const { data } = error.response;

      if (isForHandlingError(data)) {
        removeToken();
        handleRedirectToSignin();
      }

      if (data) return Promise.reject(data);
    }

    return Promise.reject(error);
  }

  function isRequestCanceled(error: object): boolean {
    return isRequestError(error) && error.type === 'canceled';
  }

  function isForHandlingError(data: object): boolean {
    return (
      verifyErrorType(data) &&
      data.suggestedAction === 'discardAccessToken' &&
      !disableHandleErrors
    );
  }

  type loginError = { suggestedAction: string };

  function verifyErrorType(data: object): data is loginError {
    return (
      isObject(data) &&
      Object.prototype.hasOwnProperty.call(data, 'suggestedAction')
    );
  }

  function removeToken() {
    localStorage.removeItem(StorageKeys.AccessToken);
    localStorage.removeItem(StorageKeys.CustomReferralId);
  }

  function handleRedirectToSignin() {
    if (!window.location.hash.includes('signin')) {
      window.location.hash = `#/signin?redirect=${encodeURIComponent(
        window.location.hash.replace('#', '')
      )}`;
    }
  }

  return httpClient;
}

export const httpClient = setup();
export const httpClientWithParams = (
  tokenKey?: StorageKeys,
  disableHandleErrors?: boolean
) => setup(tokenKey, disableHandleErrors);
