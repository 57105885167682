
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { NavBar as CustomNavBar } from '@/modules/common/components/__deprecated__/NavBar';
import { NavBar, BaseModal, LoaderIndicator } from '@warrenbrasil/nebraska-web';
import { CloseButton } from '@/modules/common/components/buttons';
import { SimpleButton } from '@/modules/common/components/__deprecated__/SimpleButton';
import {
  Modal,
  ModalFooter
} from '@/modules/common/components/__deprecated__/Modal';
import { ProductDescriptionDetailed } from '@/modules/products/components/ProductDescriptionDetailed';

import {
  getSingleInstrument,
  checkInstrumentRiskTolerance,
  IDetailedInstrument
} from '@/modules/common/services/http/instrument';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { setUserQualification } from '@/modules/products/services/user-account';
import { moneyViewer } from '@/modules/common/helpers/currency';

import { IInstrument, InvestorType } from '@/types/models/Portfolio';
import { CustomerModule } from '@/modules/common/store/CustomerModule';

@Component({
  components: {
    LoaderIndicator,
    NavBar,
    CustomNavBar,
    BaseModal,
    ModalFooter,
    CloseButton,
    Modal,
    SimpleButton,
    ProductDescriptionDetailed
  },
  filters: {
    money: (value: any) => {
      return moneyViewer(value, true);
    }
  }
})
export default class ProductDetails extends Vue {
  @Prop({ type: Object })
  public instrument!: IInstrument;

  @Prop({ type: Boolean, default: false })
  public isOpen!: boolean;

  @Prop({ type: Boolean })
  public isUnavailableInstrument!: boolean;

  @Prop({ type: String, default: 'FECHAR' })
  public leftButtonLabel!: string;

  @Prop({ type: String, default: 'Ok' })
  public rightButtonLabel!: string;

  @Prop({ type: Boolean, default: false })
  public hideRightButton!: boolean;

  @Prop({ type: Boolean, default: false })
  public ignoreProductRisks!: boolean;

  @Prop({ type: Boolean, default: false })
  public showProductQuotas!: boolean;

  public NebraskaColors = NebraskaColors;
  public isLoading = true;
  public detailedInstrument: IDetailedInstrument | any = null;

  private qualifiedInvestorModal = false;
  private customerIsQualifiedInvestor = false;
  private declareQualifiedCheckboxValue = false;
  private professionalInvestorModal = false;
  private riskScoreModal = false;
  private customerId = '';

  private get customer() {
    return CustomerModule.getCustomer;
  }

  private get isProductQualified() {
    return (
      this.detailedInstrument &&
      this.detailedInstrument.investorType === InvestorType.QUALIFIED
    );
  }

  private get isProductRestrictedToProfessionals() {
    return (
      this.detailedInstrument &&
      this.detailedInstrument.investorType === InvestorType.PROFESSIONAL
    );
  }

  @Watch('isOpen')
  private async getInstrument() {
    if (this.isOpen) {
      this.isLoading = true;

      if (!this.customer) {
        await CustomerModule.fetchCustomer();
      }

      this.detailedInstrument = await getSingleInstrument(
        this.instrument.apiId
      );
      if (!this.ignoreProductRisks)
        this.customerIsQualifiedInvestor =
          this.getCustomerIsQualifiedInvestor();

      this.isLoading = false;
    }
  }

  private getCustomerIsQualifiedInvestor() {
    this.customerId = this.customer!._id;

    return this.customer?.investmentProfile
      ? !!this.customer.investmentProfile.isQualifiedInvestor
      : false;
  }

  private closeQualifiedModal() {
    this.qualifiedInvestorModal = false;
    this.declareQualifiedCheckboxValue = false;
  }

  private closeProfessionalInvestorModal() {
    this.professionalInvestorModal = false;
  }

  private closeRiskScoreModal() {
    this.riskScoreModal = false;
  }

  private confirmRiskScoreModal() {
    this.riskScoreModal = false;
    this.rightButtonClick();
  }

  private get isAvailable() {
    if (!this.detailedInstrument) return false;
    if (this.detailedInstrument.actionStatus === 'BLOCKADD') return false;

    return this.detailedInstrument.isAvailable && !this.isUnavailableInstrument;
  }

  private rightButtonClick() {
    if (this.ignoreProductRisks) return this.rightButtonClicked();

    if (this.isProductQualified && !this.customerIsQualifiedInvestor) {
      this.qualifiedInvestorModal = true;
    } else if (this.isProductRestrictedToProfessionals) {
      this.professionalInvestorModal = true;
    } else if (!this.isCostumerRiskToleranceCompatible()) {
      this.riskScoreModal = true;
    } else {
      this.rightButtonClicked();
    }
  }

  private async isCostumerRiskToleranceCompatible() {
    return (
      await checkInstrumentRiskTolerance(this.customerId, this.instrument.apiId)
    ).isWithinRiskScore;
  }

  private async confirmQualifiedInvestor() {
    await setUserQualification();
    this.closeQualifiedModal();
    this.rightButtonClicked();
  }

  private rightButtonClicked() {
    this.$emit('right-button-clicked', this.instrument);
    this.$emit('close');
  }

  private get textStyle() {
    return { color: NebraskaColors.textSecondary, fontSize: '16px' };
  }
}
