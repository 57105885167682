import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEventNewTransfer = GtmTracker.trackerWithDefaultProps({
  page_screen: 'new_transfer',
  digital_experience: 'investor',
  experience_section: 'home'
});

export function trackNewTransferLoad() {
  trackAmplitudeEventNewTransfer({
    event_type: 'load',
    event_name: 'load_page'
  });
}

export function trackCreateNewTransfer() {
  trackAmplitudeEventNewTransfer({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'create_new_transfer'
  });
}

const trackAmplitudeEventSchedulePopup = GtmTracker.trackerWithDefaultProps({
  page_screen: 'schedule_transfer_popup',
  digital_experience: 'investor',
  experience_section: 'home'
});

export function trackScheduleTransferPopupLoad() {
  trackAmplitudeEventSchedulePopup({
    event_type: 'load',
    event_name: 'schedule_transfer_invalid_time_warning'
  });
}

export function trackScheduleTransferPopupClick() {
  trackAmplitudeEventSchedulePopup({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'schedule_invalid_time_click_schedule'
  });
}

export function trackScheduleDismissTransferClick() {
  trackAmplitudeEventSchedulePopup({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'schedule_invalid_time_click_dismiss'
  });
}

const trackAmplitudeEventTransferSuccess = GtmTracker.trackerWithDefaultProps({
  page_screen: 'schedule_transfer_success',
  digital_experience: 'investor',
  experience_section: 'home'
});

export function trackScheduleTransferSuccessLoad() {
  trackAmplitudeEventTransferSuccess({
    event_type: 'load',
    event_name: 'schedule_transfer_success_details_page'
  });
}

export function trackScheduleTransferSuccessDetailsBackClick() {
  trackAmplitudeEventTransferSuccess({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'success_back_home_click'
  });
}

const trackAmplitudeEventTransferError = GtmTracker.trackerWithDefaultProps({
  page_screen: 'transfer_error',
  digital_experience: 'investor',
  experience_section: 'home'
});

export function trackTransferErrorLoad() {
  trackAmplitudeEventTransferError({
    event_type: 'load',
    event_name: 'transfer_error_page'
  });
}
