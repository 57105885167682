
import { Component, Vue } from 'vue-property-decorator';

import {
  ButtonContainer,
  ButtonPrimary,
  ButtonIconContextual,
  HelperViewStatus,
  NavBar
} from '@warrenbrasil/nebraska-web';
import { TaxCalculatorContent } from './components/TaxCalculatorContent';
import { TaxCalculatorLoader } from './components/TaxCalculatorLoader';
import { FadeTransition } from '@/modules/common/components/FadeTransition';

import {
  checkIfHasAccountOnIncomeTaxCalculatorProvider,
  generateAccessLinkForIncomeTaxCalculatorProvider
} from '@/modules/account/services/account';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { ViewStatus } from './types';
import { data } from './data';

@Component({
  components: {
    ButtonContainer,
    ButtonPrimary,
    ButtonIconContextual,
    FadeTransition,
    HelperViewStatus,
    NavBar,
    TaxCalculatorContent,
    TaxCalculatorLoader
  }
})
export default class IncomeTaxCalculator extends Vue {
  readonly ViewStatus = ViewStatus;
  readonly data = data;

  isButtonLoading = false;
  currentViewStatus = ViewStatus.Loading;
  hasAccountOnIncomeTaxCalculatorProvider = false;

  created() {
    this.fetchViewData();
  }

  get shouldGoBack() {
    return !!this.$route.query.goBack;
  }

  get buttonLabel() {
    return this.hasAccountOnIncomeTaxCalculatorProvider
      ? 'Acessar Velotax'
      : 'Aprovar integração com a Velotax';
  }

  handleCloseButton() {
    this.shouldGoBack
      ? this.$router.back()
      : this.$router.replace({ name: 'home' });
  }

  async handleButtonAction() {
    try {
      this.isButtonLoading = true;

      const { accessLink } =
        await generateAccessLinkForIncomeTaxCalculatorProvider();

      window.open(accessLink, '_blank');
    } catch {
      const errorMsg = this.hasAccountOnIncomeTaxCalculatorProvider
        ? 'Ocorreu algum problema ao tentar acessar a sua conta na Velotax, tente novamente mais tarde.'
        : 'Ocorreu algum problema ao criar sua conta na Velotax, tente novamente mais tarde.';

      this.$notify({
        group: 'wrn-notifications',
        text: errorMsg,
        type: NotificationTypes.error
      });
    } finally {
      this.isButtonLoading = false;
    }
  }

  async fetchViewData() {
    try {
      this.currentViewStatus = ViewStatus.Loading;

      const { hasAccountOnProvider } =
        await checkIfHasAccountOnIncomeTaxCalculatorProvider();

      this.hasAccountOnIncomeTaxCalculatorProvider = hasAccountOnProvider;

      this.currentViewStatus = ViewStatus.Default;
    } catch {
      this.currentViewStatus = ViewStatus.Error;
    }
  }
}
