
import { Component, Vue } from 'vue-property-decorator';
import { NavBar, Heading } from '@warrenbrasil/nebraska-web';

import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { getString } from '@/modules/common/helpers/resources';

@Component({
  name: 'CostsMoreDetails',
  components: {
    Heading,
    NavBar,
    BackButton
  }
})
export default class CostsMoreDetails extends Vue {
  private getString = getString;
}
