var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('MatchMedia',{attrs:{"device":"desktop"},scopedSlots:_vm._u([{key:"default",fn:function({ matches: isDesktop }){return [_c('div',{class:[
      'product-to-invest__content',
      {
        'product-to-invest__content-grid': isDesktop,
        'product-to-invest__content-responsive': !isDesktop
      }
    ],attrs:{"aria-disabled":!_vm.isAvailable}},[_c('div',{staticClass:"product-to-invest__info-name"},[_c('BaseText',{staticClass:"product-to-invest__title",attrs:{"size":isDesktop ? 'md' : 'xl'}},[_vm._v(" "+_vm._s(_vm.productName)+" ")]),_c('BaseText',{staticClass:"product-to-invest__error-message",attrs:{"size":"sm"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1),(_vm.isAvailable)?_c('div',{class:{ 'product-to-invest__content-min-invest': !isDesktop }},[(!isDesktop)?_c('BaseText',{staticClass:"product-to-invest__text-min-invest",attrs:{"size":"sm"}},[_vm._v(" Investimento mínimo ")]):_vm._e(),_c('BaseText',{staticClass:"product-to-invest__text-min-invest",attrs:{"size":"sm"}},[_vm._v(" "+_vm._s(_vm.minToInvest)+" ")])],1):_vm._e(),(_vm.isAvailable)?_c('InputMoney',{staticClass:"product-to-invest__content-input",attrs:{"placeholder":"R$ 0,00","value":_vm.inputValue},on:{"input":_vm.debouncedUpdateValue}}):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }