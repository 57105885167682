
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { BaseText, Row, RoundedIcon } from '@warrenbrasil/nebraska-web';

@Component({
  components: { RoundedIcon, Row, BaseText }
})
export default class AccountButton extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: String, required: false })
  public iconLetter!: string;

  @Prop({ type: String, required: false })
  public iconName!: string;

  @Prop({ type: String, required: false })
  public avatarImageURL!: string;

  @Prop({ type: String, required: true })
  public userName!: string;

  @Prop({ type: String, required: false })
  public accountDescription!: string;

  get showAccountButtonInfo() {
    return this.avatarImageURL || this.iconLetter || this.iconName;
  }
}
