
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Button } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Button
  }
})
export default class SidebarError extends Vue {
  @Prop({ type: String, required: true })
  private title!: string;

  @Prop({ type: String, required: true })
  private description!: string;
}
