
import { Component, Vue } from 'vue-property-decorator';
import { SpinnerLoader } from '@warrenbrasil/nebraska-web';
import { FadeTransition } from '@/modules/common/components/FadeTransition';
import { NewTransferComponents } from './helpers/step-components';
import { ENewTransferSteps, EFeedbackType, ETransferType } from './helpers';
import {
  getWithdrawOptions,
  IWithdrawOptionsResponse,
  IBankAccount,
  IWithdrawSuggestion
} from '@/modules/transaction/services/new-transfer';
import * as trackEvents from './track-events';

@Component({ components: { SpinnerLoader, FadeTransition } })
export default class NewTransfer extends Vue {
  isLoading = false;
  amountValue = '';
  balanceAvailable = {};
  selectedBankAccount: IBankAccount | null = null;
  bankAccounts: IBankAccount[] = [];
  withdrawSuggestions: IWithdrawSuggestion[] = [];
  feedbackType = EFeedbackType.GENERIC_ERROR;
  transferType = ETransferType.CREATE;
  scheduleDescription = '';

  get step() {
    return {
      component: NewTransferComponents[this.currentStep]
    };
  }

  private currentStep = ENewTransferSteps.AMOUNT_SELECTION;

  mounted() {
    trackEvents.trackNewTransferLoad();
    this.getWithdrawOptions();
  }

  async getWithdrawOptions() {
    this.isLoading = true;
    try {
      const response = await getWithdrawOptions();
      this.updateCurrentStep(ENewTransferSteps.AMOUNT_SELECTION);
      if (response.bankAccounts.data.length === 0) {
        this.feedbackType = EFeedbackType.WITHOUT_BANK;
        this.updateCurrentStep(ENewTransferSteps.FEEDBACK);
      } else {
        this.updateWithdrawData(response);
      }
    } catch (e) {
      console.error(e);
      this.updateCurrentStep(ENewTransferSteps.FEEDBACK);
      this.feedbackType = EFeedbackType.GENERIC_ERROR;
    } finally {
      this.isLoading = false;
    }
  }

  updateWithdrawData(response: IWithdrawOptionsResponse) {
    this.bankAccounts = response.bankAccounts.data;
    this.balanceAvailable = response.withdrawBalance.availableBalance;
    this.withdrawSuggestions = response.withdrawBalance.withdrawSuggestions;
  }

  changeAmountValue(value: string) {
    this.amountValue = value;
  }

  bankAccountSelected(bank: IBankAccount) {
    this.selectedBankAccount = bank;
  }

  updateCurrentStep(step: ENewTransferSteps) {
    this.currentStep = step;
  }

  changeStep(
    step: ENewTransferSteps,
    transferType = ETransferType.CREATE,
    scheduleDescription = ''
  ) {
    this.scheduleDescription = scheduleDescription;
    this.transferType = transferType;
    let receivedStep = step;
    if (this.currentStep === ENewTransferSteps.AMOUNT_SELECTION) {
      receivedStep = this.selectedBankAccount
        ? ENewTransferSteps.REVIEW
        : ENewTransferSteps.BANK_SELECTION;
    }
    this.updateCurrentStep(receivedStep);
  }
}
