import { NavigationGuard } from 'vue-router';

import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';

export const beforeEnterTradeSubscription: NavigationGuard = (
  _to,
  _from,
  next
) => {
  const isFeatureFlagEnabled = FeatureFlagsModule.isEnabled(
    FeatureFlags.TRADE_ALLOCATION_SUBSCRIBES
  );

  if (!isFeatureFlagEnabled) {
    return next({ name: 'trade' });
  }

  next();
};
