import { GtmTracker } from '@/modules/common/services/analytics';

const { trackerWithDefaultProps } = GtmTracker;

const trackEvent = trackerWithDefaultProps({
  page_screen: 'modal_intruments_filter',
  digital_experience: 'investor',
  experience_section: 'portfolio'
});

export function trackLoadModal(pageScreenSection: string) {
  trackEvent({
    page_screen_section: pageScreenSection,
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackSelectedFilter(
  pageScreenSection: string,
  filterName: string,
  filterValue: string
) {
  trackEvent({
    page_screen_section: pageScreenSection,
    event_type: 'select',
    event_name: 'selected_filter',
    filter_name: filterName,
    filter_type: filterValue
  });
}

export function trackQuickFilters(
  pageScreenSection: string,
  filterName: string,
  filterValue: string
) {
  trackEvent({
    page_screen_section: pageScreenSection,
    event_type: 'click',
    event_name: 'apply_filter',
    filter_name: filterName,
    filter_type: filterValue
  });
}

export function trackApplyFilters(pageScreenSection: string) {
  trackEvent({
    page_screen_section: pageScreenSection,
    event_type: 'click',
    event_name: 'apply_all_filters'
  });
}

export function trackClearFilters(pageScreenSection: string) {
  trackEvent({
    page_screen_section: pageScreenSection,
    event_type: 'click',
    event_name: 'clear_filters'
  });
}
