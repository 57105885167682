import { httpClient } from '@/modules/common/services/http/http-client';
import { AvailableBalanceData } from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function checkUserBrokerAccount(): Promise<boolean> {
  return httpClientWithDefaultOptions.get('/userHasBrokerAccount');
}

export function enableUserBrokerAccount(): Promise<void> {
  return httpClientWithDefaultOptions.post('/createNelogicaAccount');
}

export function getUserAvailableBalance(): Promise<AvailableBalanceData> {
  return httpClientWithDefaultOptions.get('/balance');
}
