import { httpClient } from '@/modules/common/services/http/http-client';
import {
  ISuggestedInstruments,
  PageContent,
  SectionDetail,
  HighLightsDetail,
  SearchInstrumentsData
} from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/v1`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getSuggestedInstruments(
  symbol: string
): Promise<ISuggestedInstruments[]> {
  return httpClientWithDefaultOptions.get('/searchSuggestedInstruments', {
    params: { query: symbol }
  });
}

export function getSearchPageContent(filter: string): Promise<PageContent> {
  return httpClientWithDefaultOptions.get('/loadSearchPageContent', {
    params: { filter }
  });
}

export function getSectionDetail(
  sectionId: string,
  filter: string
): Promise<SectionDetail> {
  return httpClientWithDefaultOptions.get(`/loadSectorList/${sectionId}`, {
    params: { filter }
  });
}

export function getHighLightsDetail(id: string): Promise<HighLightsDetail> {
  return httpClientWithDefaultOptions.get(`/loadHighlightCard/${id}`);
}

export function getInstruments(
  query: string,
  filter: string | number
): Promise<SearchInstrumentsData> {
  return httpClientWithDefaultOptions.get(`/searchInstruments`, {
    params: {
      query,
      filter
    }
  });
}
