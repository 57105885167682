import { NavigationGuard, Route } from 'vue-router';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';

enum RouteEnum {
  INSTANT_LOGIN = 'instantLogin',
  MAINTENANCE = 'manutencao',
  SIGNIN = 'signin',
  INSTANT_LOGIN_INTRANET = 'instantLoginIntranet'
}

const routesToIgnore: RouteEnum[] = [
  RouteEnum.INSTANT_LOGIN,
  RouteEnum.MAINTENANCE,
  RouteEnum.SIGNIN,
  RouteEnum.INSTANT_LOGIN_INTRANET
];

export const platformMaintenance: NavigationGuard = (
  to: Route,
  _from: Route,
  next: Function
) => {
  const { PLATFORM_MAINTENANCE } = FeatureFlags;
  const isMaintenanceActivated =
    FeatureFlagsModule.isEnabled(PLATFORM_MAINTENANCE);

  const shouldRedirectToMaintenance =
    !routesToIgnore.includes(to.name as RouteEnum) && isMaintenanceActivated;

  shouldRedirectToMaintenance ? next({ name: 'manutencao' }) : next();
};
