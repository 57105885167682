import { IUserProfile } from '../types';
import {
  HttpClient,
  httpClient
} from '@/modules/common/services/http/http-client';

const baseURL = `${process.env.VUE_APP_BFF_URL}/account/v1`;
export const httpClientWithDefaultOptions: HttpClient = httpClient.withOptions({
  baseURL
});

export function getMyUserProfile(id: string): Promise<IUserProfile> {
  return httpClientWithDefaultOptions.get<IUserProfile>(
    `/suitability/getCustomerInvestorProfile?customerId=${id}`
  );
}
