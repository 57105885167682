/**
 * Remove accents/diacritics in a string and trim
 */
export function normalize(value: string) {
  return String(value)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim();
}

/**
 * Match if stringToCompare includes stringToSearch ignoring case sensitve and accents
 */
export function matchSearch(stringToCompare: string, stringToSearch: string) {
  return normalize(stringToCompare).includes(normalize(stringToSearch));
}

export const isString = (value: any): value is string =>
  typeof value === 'string' && value.length > 0;

export function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,+\\^$|#\s]/g, '\\$&');
}
