import { defineStore } from 'pinia';

export interface ISigninModule {
  logo: string;
  brandName: string;
  isWarrenManaged: boolean;
}

export const useSigninStore = defineStore('signin', {
  state: (): ISigninModule => ({
    logo: '',
    brandName: '',
    isWarrenManaged: true
  }),
  actions: {
    setLogo(logo: string) {
      this.logo = logo;
    },
    setBrandName(brandName: string) {
      this.brandName = brandName;
    },
    setIsWarrenManaged(isWarrenManaged: boolean) {
      this.isWarrenManaged = isWarrenManaged;
    }
  }
});
