import { GtmTracker } from '@/modules/common/services/analytics';
import { StatementType } from '@/modules/investments/types/statementType';

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'open_menu',
  digital_experience: 'investor',
  experience_section: 'menu'
});

export function trackMenuOpening() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'view'
  });
}

export function trackMyInvestmentsClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'select_my_investments',
    event_target: 'Extratos e relatórios'
  });
}

export function trackMyInvestmentsBack() {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'back'
  });
}

const statementTypes = new Map<StatementType, string>([
  [StatementType.ConsolidatedStatement, 'Extrato consolidado'],
  [StatementType.FixedIncomeStatement, 'Extrato de renda fixa'],
  [StatementType.IncomeStatement, 'Informe de rendimentos']
]);

export function trackStatementClick(statementType: StatementType) {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: `select_${statementType}`,
    event_target: statementTypes.get(statementType) || ''
  });
}

export function trackPositionsClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'select_positions',
    event_target: 'Posições'
  });
}

export function trackTradeNotesClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'select_trade_notes',
    event_target: 'Notas de Negociação'
  });
}

export function trackCostsClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'select_costs',
    event_target: 'Custos'
  });
}

export function trackMyAccountClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'select_my_account',
    event_target: 'Minha Conta'
  });
}

export function trackAccountRegisterSubmenu() {
  trackAmplitudeEvent({
    page_screen_section: 'my_account',
    event_name: 'select_register_data',
    event_target: 'Dados Cadastrais'
  });
}

export function trackDeleteAccountSubmenu() {
  trackAmplitudeEvent({
    page_screen_section: 'my_account',
    event_name: 'delete_account',
    event_target: 'Encerrar Conta'
  });
}

export function trackInvestmentProfileSubmenu() {
  trackAmplitudeEvent({
    page_screen_section: 'my_account',
    event_name: 'select_investment_profile',
    event_target: 'Meu Perfil de Investimento'
  });
}

export function trackChangeEmailSubmenu() {
  trackAmplitudeEvent({
    page_screen_section: 'my_account',
    event_name: 'select_change_email',
    event_target: 'Trocar e-mail'
  });
}

export function trackChangePasswordSubmenu() {
  trackAmplitudeEvent({
    page_screen_section: 'my_account',
    event_name: 'select_change_password',
    event_target: 'Trocar senha'
  });
}

export function trackViewTermsSubmenu() {
  trackAmplitudeEvent({
    page_screen_section: 'my_account',
    event_name: 'view_terms',
    event_target: 'Termos'
  });
}

export function trackSecurityLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackSecurityBackClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'back',
    page_screen_section: 'header'
  });
}

export function trackFaceMatchClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'click_document',
    page_screen_section: 'security'
  });
}

export function trackMFAClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'click_mfa',
    page_screen_section: 'security'
  });
}

export function trackReportMenuClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'select_reports',
    event_target: 'Relatórios'
  });
}

export function trackAllocationClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'select_report_asset_allocation',
    event_target: 'Alocação',
    page_screen_section: 'reports'
  });
}

export function trackAnalysisClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'select_report_warren_analisa',
    event_target: 'Análise',
    page_screen_section: 'reports'
  });
}

export function trackAssetsManagementClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    event_name: 'select_report_nossos_fundos',
    event_target: 'Asset Management',
    page_screen_section: 'reports'
  });
}

export function trackLogoutClick() {
  trackAmplitudeEvent({
    event_name: 'logout',
    event_type: 'click'
  });
}
