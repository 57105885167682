import {
  StringFormatted,
  InstrumentType,
  PaginationData
} from '@/modules/trade/types';

export interface AssetDetailsDescription {
  value: string;
  type: string;
}

export interface PortabilityAssetDetails {
  instrument: {
    symbol: string;
    type: InstrumentType;
  };
  description: AssetDetailsDescription;
}

export interface PortabilityDetails {
  id: string;
  title: string;
  enabledMFA: boolean;
  expiration: StringFormatted;
  custodian: StringFormatted;
  assets: PortabilityAssetDetails[];
}

export interface PortabilityRequestDetails {
  label: string;
  value: string;
}

export interface PortabilityRequest {
  id: string;
  direction: string;
  title: string;
  custodian: {
    name: string;
    code: string;
  };
  details: PortabilityRequestDetails[];
  actionLabel: string;
}

export interface PortabilityRequestsResponse {
  items: PortabilityRequest[];
  pagination: PaginationData;
}

export interface PortabilityRequestConclusionResponse {
  status: string;
  title: string;
  description: string;
  portability: {
    title: string;
    custodian: StringFormatted;
    details: PortabilityRequestDetails[];
  };
}

export enum PortabilityRequestStatus {
  accept = 'accept',
  reject = 'reject'
}
