
import { Component, Watch, Prop, Vue, Emit } from 'vue-property-decorator';
import { IRecaptcha } from './';
import { showRecaptchaBadge, hideRecaptchaBadge } from './helpers';

@Component
export default class RecaptchaEnterprise extends Vue implements IRecaptcha {
  private isLoading = false;
  private isRunningChallenge = false;

  @Prop({ type: Boolean, default: false })
  public challenge!: boolean;

  @Prop({ type: Boolean, default: false })
  public reset!: boolean;

  @Watch('reset', { deep: true })
  public onReset() {}

  private checkRecaptchaLoaded() {
    setTimeout(() => {
      if (this.isLoading) {
        console.error(
          '[Recaptcha Enterprise] Recaptcha is taking more than 5 seconds to load'
        );
      } else if (this.isRunningChallenge) {
        console.error(
          '[Recaptcha Enterprise] Recaptcha is taking more than 5 seconds to run challenge'
        );
      }
    }, 5000);
  }

  @Watch('challenge', { deep: true })
  public onChallenge(action = '') {
    this.isLoading = true;
    this.isRunningChallenge = true;
    this.checkRecaptchaLoaded();

    window.grecaptcha.enterprise.ready(async () => {
      this.isLoading = false;
      const token = await window.grecaptcha.enterprise.execute(
        process.env.VUE_APP_RECAPTCHA_ENTERPRISE_SITE_KEY,
        {
          action
        }
      );
      this.isRunningChallenge = false;

      this.emitToken(token);
    });
  }

  @Emit('token')
  emitToken(_token: string) {}

  private mounted() {
    showRecaptchaBadge();
  }

  private beforeDestroy() {
    hideRecaptchaBadge();
  }
}
