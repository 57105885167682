import { EventProperties } from '@warrenbrasil/web-analytics';
import { pickBy } from 'lodash-es';
import { customerProps } from './customer-props';

export function trackerContextProps(): EventProperties {
  const properties = {
    ...customerProps()
  };

  const sanitizedProperties = pickBy(properties, Boolean) as EventProperties;
  return sanitizedProperties;
}
