
import { Component, Vue } from 'vue-property-decorator';
import {
  Instrument,
  DocumentType,
  Proposal,
  ProposalPensionInfo,
  PensionPaymentType
} from '@warrenbrasil/pension-types/dist/next';
import { Heading, Row, ToggleSwitch } from '@warrenbrasil/nebraska-web';
import { DocumentModule } from '@/modules/pension/store/document';
import { moneyFormat } from '@/modules/common/helpers/currency';
import { simplePercentage } from '@/modules/common/helpers/percentage';
import { DocumentModalSteps } from '@/modules/pension/types';
import { PensionLabels } from '@/modules/pension/helpers/labels';

@Component({
  name: 'SuggestionProposal',
  components: {
    Row,
    Heading,
    ToggleSwitch
  }
})
export default class SuggestionProposal extends Vue {
  protected PensionLabels = PensionLabels;

  get state() {
    return DocumentModule.data;
  }

  get proposal(): Proposal {
    const { documentSelected } = this.state;

    if (!documentSelected || documentSelected.type !== DocumentType.Proposal) {
      return {} as Proposal;
    }

    return documentSelected.entityData as Proposal;
  }

  get pensionInfo(): ProposalPensionInfo {
    return this.proposal.pensionInfo;
  }

  get instrument(): Instrument {
    return this.proposal.pensionInfo.instruments[0] || {};
  }

  get isDebitPayment() {
    return (
      this.pensionInfo.paymentInfo.type === PensionPaymentType.BankAccountDebit
    );
  }

  get contributionAmounts() {
    const { initialAmount, monthlyAmount } = this.pensionInfo.paymentInfo;

    return {
      initialAmount: moneyFormat(initialAmount),
      monthlyAmount: moneyFormat(monthlyAmount)
    };
  }

  formatPercentage(percentage: number) {
    return simplePercentage(percentage);
  }

  redirectToTerms() {
    DocumentModule.modalNavigateTo(DocumentModalSteps.Terms);
    const modal = document.querySelector(
      '.pension-modal-suggestions .content'
    ) as HTMLDivElement;

    if (modal) {
      modal.scrollTo({ top: 0 });
    }
  }

  openDocumentPdf() {
    DocumentModule.openDocumentPdf();
  }
}
