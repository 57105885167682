import { RouteConfig } from 'vue-router';

import FAQView from '@/modules/help-center/views/Faq/FAQView.vue';
import HelpCenterView from '@/modules/help-center/views/HelpCenter/HelpCenterView.vue';
import HelpCenterSectionView from '@/modules/help-center/views/HelpCenterSection/HelpCenterSectionView.vue';
import HelpCenterArticleView from '@/modules/help-center/views/HelpCenterArticle/HelpCenterArticleView.vue';

export const helpCenterRoutes: RouteConfig[] = [
  {
    name: 'help',
    path: '/help',
    component: FAQView,
    meta: {
      context: 'common'
    }
  },
  {
    name: 'help-center',
    path: '/help-center',
    alias: '/user/costs/faq',
    component: HelpCenterView,
    meta: {
      scroll: false,
      context: 'common'
    }
  },
  {
    name: 'help-center-article',
    path: '/help-center/article/:articleId',
    component: HelpCenterArticleView,
    meta: {
      context: 'common'
    }
  },
  {
    name: 'help-center-section',
    path: '/help-center/section/:id',
    component: HelpCenterSectionView,
    meta: {
      context: 'common'
    }
  }
];
