
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ConclusionStep, ButtonPrimary } from '@warrenbrasil/nebraska-web';
import { SuggestionDetails } from '../SuggestionDetails';
import { EInvestmentSuggestionsSteps } from '@/modules/wealth/views/InvestmentSuggestions/helpers';
import { IGetSuggestionDetailsResponse } from '@/modules/wealth/services/investment-suggestions';

@Component({
  components: {
    ConclusionStep,
    SuggestionDetails,
    ButtonPrimary
  }
})
export default class SuggestionConclusion extends Vue {
  @Prop({ type: Object, required: true })
  private suggestionDetails!: IGetSuggestionDetailsResponse;

  @Emit('change-step')
  emitChangeStep(_step: EInvestmentSuggestionsSteps) {}

  get isInvestment() {
    return this.suggestionDetails.transaction.direction === 'Investment';
  }

  get title() {
    return this.isInvestment ? 'Investimento' : 'Resgate';
  }

  goBack() {
    this.emitChangeStep(EInvestmentSuggestionsSteps.VIEW);
  }
}
