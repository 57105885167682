
import { Component, Vue } from 'vue-property-decorator';
import {
  ButtonPrimary,
  ButtonSecondary,
  BaseIcon
} from '@warrenbrasil/nebraska-web';
import { DocumentModule } from '@/modules/pension/store/document';
import { DocumentModalSteps } from '@/modules/pension/types';

@Component({
  name: 'ModalFooter',
  components: {
    ButtonPrimary,
    ButtonSecondary,
    BaseIcon
  }
})
export default class ModalFooter extends Vue {
  get steps() {
    return DocumentModule.modalStepsInfo;
  }

  get disabled() {
    const { acceptData } = DocumentModule.data;

    return {
      mfa: !acceptData.password || acceptData.password === '',
      accept: !acceptData.terms || !acceptData.documentPDF
    };
  }

  get loading() {
    return {
      mfa: DocumentModule.data.loading.acceptDocument,
      accept: false
    };
  }

  handleAccept() {
    DocumentModule.modalNavigateTo(DocumentModalSteps.MFA);
  }

  handleMFA() {
    DocumentModule.acceptDocument();
  }

  handleSuccess() {
    DocumentModule.modalClose();
  }

  handleReject() {
    DocumentModule.rejectDocument();
  }
}
