import { IAllocationItem } from '@/modules/catalog/views/FundsAcquisition/components/Template/types';

export const columns = [
  {
    label: 'Ativo',
    width: '70%',
    getter: (item: IAllocationItem) => ({
      text: item.title,
      subtitle: item.subtitle
    })
  },
  {
    label: 'Risco',
    width: '15%',
    align: 'right',
    getter: (item: IAllocationItem) => item.risk
  },
  {
    label: 'Peso',
    width: '15%',
    align: 'right',
    getter: (item: IAllocationItem) => item.weight
  }
];
