import { StatementType } from '@/modules/investments/types/statementType';
import {
  trackConsolidatedExtractBackClick,
  trackConsolidatedExtractDownload,
  trackConsolidatedExtractViewLoad,
  trackFixedIncomeStatementBackClick,
  trackFixedIncomeStatementDownload,
  trackFixedIncomeStatementViewLoad,
  trackIncomeStatementBackClick,
  trackIncomeStatementDownload,
  trackIncomeStatementViewLoad
} from './track-events';

export interface TranckEvents {
  sendLoadTrack: Function;
  sendBackTrack: Function;
  sendDownloadTrack: Function;
}

const consolidatedStatement: TranckEvents = {
  sendLoadTrack: trackConsolidatedExtractViewLoad,
  sendBackTrack: trackConsolidatedExtractBackClick,
  sendDownloadTrack: trackConsolidatedExtractDownload
};

const fixedIncomeStatement: TranckEvents = {
  sendLoadTrack: trackFixedIncomeStatementViewLoad,
  sendBackTrack: trackFixedIncomeStatementBackClick,
  sendDownloadTrack: trackFixedIncomeStatementDownload
};

const incomeStatement: TranckEvents = {
  sendLoadTrack: trackIncomeStatementViewLoad,
  sendBackTrack: trackIncomeStatementBackClick,
  sendDownloadTrack: trackIncomeStatementDownload
};

const statementTypes = new Map<StatementType, TranckEvents>([
  [StatementType.ConsolidatedStatement, consolidatedStatement],
  [StatementType.FixedIncomeStatement, fixedIncomeStatement],
  [StatementType.IncomeStatement, incomeStatement]
]);

export const getStatementTrackers = (statementType: StatementType) => {
  const statement = statementTypes.get(statementType);

  return statement || consolidatedStatement;
};
