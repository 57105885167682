
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { HandleSection } from '@/modules/trade/components/HandleSection';
import { HomeBrokerButton } from '../HomeBrokerButton';
import { Heading } from '@warrenbrasil/nebraska-web';

import {
  AccountStatus,
  HomeBrokerStatus
} from '@/modules/trade/services/account';
import { RequestStatus } from '@/modules/trade/store/trade';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    HomeBrokerButton,
    HandleSection,
    Heading
  }
})
export default class HomeBrokerSection extends Vue {
  @Prop({ type: Object, required: false })
  readonly homeBrokerStatus?: HomeBrokerStatus;

  @Prop({ type: Object, required: false })
  readonly tradeStatus?: AccountStatus;

  @Emit('open-request-home-broker-modal')
  emitOpenRequestHomeBrokerModal() {}

  @Emit('open-home-broker')
  emitOpenHomeBroker() {}

  @tradePositionsModule.State('accountStatusRequestStatus')
  readonly accountStatusRequestStatus!: RequestStatus;

  @tradePositionsModule.Action('fetchStatus')
  readonly fetchStatus!: Function;

  get isLoading() {
    return this.accountStatusRequestStatus === RequestStatus.Loading;
  }

  get isError() {
    return this.accountStatusRequestStatus === RequestStatus.Error;
  }
}
